import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { servicesKeys } from './servicesKeys';
import { SERVICE } from './types';

interface ServiceDisconnectResponse {
  success: boolean;
  message: string;
}

export const disconnectServices = async (
  service: SERVICE
): Promise<ServiceDisconnectResponse> => {
  const response = await EXPRESS_API.get(`/${service}/disconnect`);
  return response.data;
};

export const useServicesDisconnectQuery = (service: SERVICE) => {
  return useQuery(
    servicesKeys.disconnect(),
    () => disconnectServices(service),
    {
      enabled: false,
    }
  );
};
