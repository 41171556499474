import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { leadOpportunitiesKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { LeadOpportunitiesBaseParams } from './types';

export interface LeadOpportunitiesStatsParams
  extends LeadOpportunitiesBaseParams {}

interface LeadOpportunitiesStatsResponse {
  count: number;
  recorded: number;
  sent: number;
  views: number;
}

const getLeadOpportunutiesStats = async ({
  params,
}: {
  params?: LeadOpportunitiesStatsParams;
}): Promise<LeadOpportunitiesStatsResponse> => {
  const response = await EXPRESS_API.get(`/lead-opportunities/stats`, {
    params: params,
  });
  return response.data;
};

export const useLeadOpportunutiesStatsQuery = ({
  params,
}: {
  params?: LeadOpportunitiesStatsParams;
}) => {
  const { showError } = useToastError();

  return useQuery(
    leadOpportunitiesKeys.stats(params),
    () => getLeadOpportunutiesStats({ params }),
    {
      onError: showError,
      refetchOnMount: true,
    }
  );
};
