import { FormikSwitchField } from 'lib/components/formik/FormikSwitchField';
import React from 'react';
import {
  FieldLabel,
  FormProps,
  getInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';

export const AutominerSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;
  return (
    <Section>
      <SectionTitle>Autominer</SectionTitle>
      <RowWrapper>
        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='autominerEnabled'
          label='Enable Autominer'
          onChange={() => {
            setFieldValue('autominerEnabled', !values.autominerEnabled);
          }}
        />

        {!!values.autominerEnabled && (
          <Row>
            <FieldLabel>Autominer Group ID</FieldLabel>
            {getInputField('autominerGroupId', false, false, isSubmitting)}
          </Row>
        )}
      </RowWrapper>
    </Section>
  );
};
