import * as React from 'react';
import styled from 'styled-components/macro';
import { TextInput } from 'lib/components';
import { SuperAdminReseller } from 'lib/context';
import { ErrorMessage, Field, FieldProps, Form } from 'formik';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { FormikSwitchField } from 'lib/components/formik/FormikSwitchField';

export type OptionalError = {
  password?: string;
};

interface FormProps {
  isSubmitting?: boolean;
  setFieldValue: (field: string, value: number | string | boolean) => void;
  values: SuperAdminReseller;
  reseller: SuperAdminReseller;
  onFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
  dirty: boolean;
  setReseller: (values: SuperAdminReseller) => void;
  isEditView: boolean;
  isUsernameValid?: boolean;
  optionalError: OptionalError;
  businessName?: string;
  customerId?: number;
}

const FlexForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .upgrade-button {
    display: flex;
    margin-left: auto;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FieldContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  .user-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #4e5461;
  }
  .error {
    color: red;
    font-size: 14px;
    padding: 0.1rem;
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  padding: 0.1rem;
`;

const FieldLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 115px;
  color: rgba(146, 151, 162, 1);
  margin-right: 16px;
`;

const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  width: 100%;
  max-width: 200px;
  line-height: 24px;
`;

const RowWrapper = styled.div`
  width: 100%;
  div:first-child,
  p:first-child {
    padding-top: 0px;
  }
`;

const Section = styled.div`
  display: flex;
  max-width: 700px;
`;

const EyeField = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const PasswordContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 690px;
`;

export const ResellerDetails = (props: FormProps) => {
  const {
    isSubmitting,
    values,
    reseller,
    dirty,
    onFormDirty,
    setReseller,
    isEditView,
    optionalError,
    setFieldValue,
  } = props;
  const [passwordView, setPasswordView] = React.useState<'password' | 'text'>(
    'password'
  );
  const [passwordErrorHide, setPasswordErrorHide] = React.useState(true);

  React.useEffect(() => {
    setReseller({ ...reseller, ...values });
  }, [values]);

  React.useEffect(() => {
    onFormDirty(dirty);
  }, [dirty]);

  React.useEffect(() => {
    setReseller({ ...reseller });
  }, []);

  const getInputField = (
    name: string,
    required: boolean,
    readOnly: boolean,
    type: string = 'text',
    disabled: boolean = false,
    background: string = '#fff'
  ) => {
    return (
      <Field type={type} name={name} disabled={disabled}>
        {({ field }: FieldProps) => {
          return (
            <FieldContainer>
              {name !== 'password' && (
                <TextInput
                  {...field}
                  type={type}
                  autoComplete='new-password'
                  required={required}
                  readOnly={isSubmitting || readOnly}
                  disabled={disabled}
                  onKeyUp={() => {}}
                  background={background}
                />
              )}
              {name !== 'password' && (
                <ErrorMessage name={name} component={ErrorMsg} />
              )}
              {name === 'password' && (
                <PasswordContainer>
                  <TextInput
                    {...field}
                    type={passwordView}
                    placeholder='Password'
                    required={true}
                    autoFocus={false}
                    disabled={isSubmitting}
                    onKeyUp={() => {
                      setPasswordErrorHide(true);
                      if (values.password && values.password.length < 8) {
                        setPasswordErrorHide(false);
                      }
                    }}
                  />
                  {optionalError.password &&
                    optionalError.password.length &&
                    !passwordErrorHide && (
                      <p className='error'>{optionalError.password}</p>
                    )}
                  <EyeField
                    onClick={() => {
                      setPasswordView(
                        passwordView == 'password' ? 'text' : 'password'
                      );
                    }}
                  >
                    {passwordView == 'password' ? <IoMdEyeOff /> : <IoMdEye />}
                  </EyeField>
                </PasswordContainer>
              )}
            </FieldContainer>
          );
        }}
      </Field>
    );
  };

  const getResellerDetailsSection = () => {
    return (
      <Section id={'resellerDetailsSection'}>
        <SectionTitle>Reseller Details</SectionTitle>
        <RowWrapper>
          {isEditView && (
            <Row>
              <FieldLabel>ResellerId</FieldLabel>
              {getInputField('resellerId', true, false, '', true, '#F6F7F9')}
            </Row>
          )}
          <Row>
            <FieldLabel>Reseller Name</FieldLabel>
            {getInputField('resellerName', true, false)}
          </Row>
          <Row>
            <FieldLabel>URL</FieldLabel>
            {getInputField('url', true, false)}
          </Row>
          <Row>
            <FieldLabel>Phone</FieldLabel>
            {getInputField('phoneNumber', true, false)}
          </Row>
          {isEditView && (
            <Row>
              <FieldLabel>Playback URL</FieldLabel>
              {getInputField('playbackUrl', true, false, '', true, '#F6F7F9')}
            </Row>
          )}
          {isEditView && (
            <FormikSwitchField
              wrapper={{ margin: '0 0 16px 0' }}
              name='resellerReportingEnabled'
              label='Reseller Reporting'
              onChange={() => {
                setFieldValue(
                  'resellerReportingEnabled',
                  values.resellerReportingEnabled ? 0 : 1
                );
              }}
              readOnly={isSubmitting}
            />
          )}

          <FormikSwitchField
            wrapper={{ margin: '0 0 16px 0' }}
            name='dealer'
            label='Dealer'
            onChange={() => {
              setFieldValue('dealer', values.dealer ? 0 : 1);
            }}
            readOnly={isSubmitting}
          />
          {isEditView && (
            <FormikSwitchField
              wrapper={{ margin: '0 0 16px 0' }}
              name='active'
              label='Active'
              onChange={() => {
                setFieldValue('active', values.active ? 0 : 1);
              }}
              readOnly={isSubmitting}
            />
          )}
        </RowWrapper>
      </Section>
    );
  };

  return <FlexForm>{getResellerDetailsSection()}</FlexForm>;
};
