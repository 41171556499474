import { Formik, FormikHelpers, FormikValues } from 'formik';
import { TextInput, Dropdown } from 'lib/components';
import { ChatsModal } from 'lib/components/modal/chatModal/ChatsModal';
import { TextEditor } from 'lib/components/textEditorAutomotive';
import React, { useState } from 'react';
import { MdSend } from 'react-icons/md';
import { InputField } from '../../components/InputField';
import { IFormProps } from '../../conversations.types';
import { FormStyles } from '../../styles/UsersConversationsLayout';
import { emailEditorToolbar } from './constants';
import { useSendEmailMutation } from 'lib/api/conversations/sendEmailMessage';
import { deleteHTMLTags } from '../../utils/helper';
import { AttachmentField } from '../../components/AttachmentField';
import { useSendConversationAttachment } from 'lib/api/conversations/sendAttachemnt';
import { Gap } from 'lib/components/styles/layout';
import { DisabledWrapper } from '../../styles/form';
import * as Yup from 'yup';
import { LaunchPopup } from 'lib/components/magic/popups/LaunchPopup';
import { CovideoMagicButton, PROMPT_TYPE } from 'react-covideo-ai-assist';
import { useAuth } from 'lib/context';
import { getCurrentToken } from 'configs/api/token';
import { Button } from 'react-covideo-common';
import { getDefaultPromptUseCase } from 'lib/utils/functions';
import { APP_ENVIROMENT } from 'configs/api/types';

const EmailSchemaValidation = Yup.object().shape({
  attachment: Yup.mixed().test('fileSize', 'File size is too large', value => {
    if (!value) return true; // Allow empty file (optional file)
    return value.size <= 25 * 1024 * 1024; // 25 MB in bytes
  }),
});

interface IEmailForm extends IFormProps {
  copiedMessage: string | null;
}

export const EmailForm = ({
  singleConversation,
  setModals,
  modals,
  dropdownValue,
  onDropdownChangeHandler,
  isLoadingConversation,
  items,
  copiedMessage,
}: IEmailForm) => {
  const { userData } = useAuth();
  // use key for re-render TextEditor component
  const [key, setKey] = useState('');
  const { mutateAsync: sendEmail, isLoading: isSendingEmail } =
    useSendEmailMutation(setKey);
  const { mutateAsync: createAttachment, isLoading: isCreatingAttachment } =
    useSendConversationAttachment();
  const INITIAL_TEXT_VALUE =
    copiedMessage === null ? '' : `<p>${copiedMessage}</p>`;

  const recipientDisplayName = [
    singleConversation?.contact?.firstName,
    singleConversation?.contact?.lastName,
  ]
    .filter(Boolean)
    .join(' ');

  const submitForm = async (
    values: FormikValues,
    formik: FormikHelpers<any>
  ) => {
    //check if attachment exist
    const attachment =
      values.attachment === null
        ? undefined
        : await createAttachment(values.attachment);

    // if attachemnt exist create
    const transformAttachment =
      attachment === undefined
        ? undefined
        : {
            filename: attachment.name,
            type: attachment.type,
            url: attachment.url,
          };

    sendEmail({
      conversationId: values.conversationId,
      text: values.text,
      formik,
      subject: values.subject,
      attachment: transformAttachment,
    });
  };
  const defaultPromptUseCase = getDefaultPromptUseCase(userData.isAutomotive);

  if (isLoadingConversation || !singleConversation) {
    return null;
  }

  const isLoading = isSendingEmail || isCreatingAttachment;

  return (
    <Formik
      initialValues={{
        subject: '',
        text: INITIAL_TEXT_VALUE,
        conversationId: singleConversation.conversationId,
        attachment: null,
      }}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={EmailSchemaValidation}
    >
      {({ setFieldValue, submitForm, values, errors }) => (
        <FormStyles style={{ flexDirection: 'column' }}>
          <InputField
            style={{ margin: 0, width: '100%', maxWidth: '100%' }}
            placeholder='Subject'
            name='subject'
            as={TextInput}
            type='text'
            disabled={isLoading}
            onDrop={(event: React.DragEvent) => {
              event.preventDefault();
            }}
          />
          <DisabledWrapper isDisabled={isLoading}>
            <TextEditor
              key={key}
              height={'160px'}
              onTextEditorChange={text => setFieldValue('text', text)}
              initialContent={values.text}
              placeholder={'Enter your message...'}
              toolbar={emailEditorToolbar}
              hideAddVariableButton={true}
            />
          </DisabledWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <DisabledWrapper
              isDisabled={isLoading}
              style={{ display: 'flex', gap: '16px' }}
            >
              <Dropdown
                options={items}
                value={dropdownValue}
                onChange={onDropdownChangeHandler}
                creatable={false}
                width={100}
                menuPlacement='top'
                extendStyles={{ control: { minHeight: 42 } }}
                isSearchable={false}
                disabled={isLoading}
              />
              <AttachmentField
                onAttachmentChange={attachment =>
                  setFieldValue('attachment', attachment)
                }
                value={values.attachment}
                error={!!errors?.attachment}
              />
            </DisabledWrapper>
            <Gap justifyContent='flex-end' gap='8px'>
              <CovideoMagicButton
                userInput={values.text}
                defaultPromptType={PROMPT_TYPE.EMAIL}
                defaultRecipient={recipientDisplayName}
                defaultPromptUseCase={defaultPromptUseCase}
                handleSubmitGeneratedContent={(generatedContent: string) => {
                  setFieldValue('text', generatedContent);
                  setKey(prev => prev + 1);
                }}
                variant='gradient'
                hoverPopup={<LaunchPopup />}
                token={getCurrentToken().token as string}
                userData={{
                  customer: {
                    business: userData.customer.business,
                    hasCovideoMagic:
                      userData?.customer?.hasCovideoMagic?.toString() === '1',
                    markVideosAsSent: userData.customer.markVideosAsSent,
                  },
                  firstName: userData.firstName,
                  isAutomotiveSalesRole: userData.isAutomotiveSalesRole,
                  isAutomotiveServiceRole: userData.isAutomotiveServiceRole,
                  isIMSEnabled: userData.isIMSEnabled,
                  isAutomotive: userData.isAutomotive,
                  isCompanyAdmin: userData.isCompanyAdmin,
                }}
                aiAssistTheme={'branding'}
                apiKey={process.env.REACT_APP_APIKEY}
                env={
                  (process.env.REACT_APP_ENV as APP_ENVIROMENT) ||
                  APP_ENVIROMENT.PRODUCTION
                }
              />

              <Button
                type='submit'
                text={isLoading ? 'Sending' : 'Send Email'}
                icon={<MdSend size={20} />}
                onClick={submitForm}
                disabled={
                  isLoading ||
                  !values.subject ||
                  !deleteHTMLTags(values.text).trim() ||
                  !!errors?.attachment
                }
              />
            </Gap>
          </div>
          {modals === 'chooseFile' && (
            <ChatsModal
              handleModalClose={() => {
                setModals(false);
              }}
              onSelect={(item: any, itemType: string) => {
                if (itemType === 'video') {
                  return setFieldValue('text', item.url);
                }
                if (itemType === 'file' || itemType === 'board') {
                  return setFieldValue('text', item.shortlink);
                }
              }}
            />
          )}
        </FormStyles>
      )}
    </Formik>
  );
};
