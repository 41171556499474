import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

export type PaymentCardType = {
  cardNumber: string;
  expMonth: string;
  expYear: string;
  cvc: string | number;
  billingCountry: string;
  billingPostalCode: string;
  billingAddress?: string;
  billingCity?: string;
  billingState?: string;
  fullName: string;
};

const addNewCard = async (data: PaymentCardType) => {
  const response = await EXPRESS_API.post('/register/payment/methods', data);
  return response.data;
};

export const useAddCustomerCardMutation = () => {
  const { showError } = useToastError();

  return useMutation(addNewCard, {
    onSuccess: () => {
      successToast({ title: 'Card added successfully.' });
    },
    onError: (err: AxiosError) => showError(err),
  });
};
