import React, { ChangeEvent } from 'react';
import { CustomSwitchField } from './types';
import { BaseSwitch } from './base';

interface SwitchFieldProps extends CustomSwitchField {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const SwitchField = ({
  onChange,
  wrapper,
  ...props
}: SwitchFieldProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) {
      return;
    }

    onChange(e);
  };
  return (
    <BaseSwitch
      {...props}
      wrapper={wrapper}
      label={props.label}
      onChange={handleChange}
    />
  );
};
