import * as React from 'react';
import styled from 'styled-components/macro';
import { debounce } from 'lodash';
import emptyDesign from 'assets/icons/design/emptyDesignBg.svg';
import { IoMdEye, IoMdUnlock } from 'react-icons/io';

import { theme } from 'lib/style';
import {
  SquareListItem,
  Search,
  ListDropdownMenu,
  LoadingIndicator,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';

import {
  setDefaultLandingPage,
  getLandingPages,
  getLandingPagePreview,
  createLandingPage,
} from 'lib/api/designApi';
import {
  GetLandingPagesResponse,
  LandingPage,
  SetDefaultLandingPageResponse,
} from 'lib/api';
import { useHistory } from 'react-router-dom';
import { ModalLandingPageDesignAddOrEdit } from '../landingPageBuilder/components/modal/ModalLandingPageDesignAddOrEdit';
import { ModalLandingPageSelectStyle } from '../landingPageBuilder/components/modal/ModalLandingPageSelectStyle';
import { useLandingPageBuilderContext } from '../landingPageBuilder/context';
import {
  MdContentCopy,
  MdDeleteForever,
  MdDriveFileRenameOutline,
  MdEdit,
} from 'react-icons/md';
import { defaultStyleProperties } from '../landingPageBuilder/components/Constants';
import { useAuth } from 'lib/context';
import { ModalDeleteLandingPage } from '../landingPageBuilder/components/modal/ModalDeleteLandingPage';
import { GetLPBStats } from 'lib/api/addonsApi';
import { ModalRenameLandingPage } from './ModalRenameLandingPage';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { HeaderWrapper, MainWrapper, Gap } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { errorToast } from 'lib/components/toasts/error';
import { useQueryClient } from 'react-query';
import { TEMPLATE_QUERY } from 'lib/const/SendAndShare';
import { successToast } from 'lib/components/toasts/success';
import { LabelSwitch } from 'app/pages/guides/components/LabelSwitch';
import { Button } from 'react-covideo-common';
import { useGetOverlays } from 'lib/api/overlays/getOverlaysQuery';
import { WEBSITE_OVERLAY_ID } from 'lib/const/templates';
import { useToastError } from 'lib/hooks';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { PaginationWrapper } from 'app/pages/admin/index.styled';

interface ListLandingPage extends LandingPage {
  isDefault?: boolean;
}

interface DelayedSearchParams {
  start: number;
  limit: number;
  search: string;
  setItems: React.Dispatch<React.SetStateAction<ListLandingPage[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalItems: React.Dispatch<React.SetStateAction<number>>;
  showUserCustomLandingPages: boolean;
}

const UpgradeArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 967px;
  background: ${({ theme }) => theme.colors.secondary[5]};
  margin-top: 32px;
  padding: 24px;
  justify-content: space-between;

  ${theme.mediaQueryMinWidth.md} {
    width: 620px;
    div {
      &:nth-child(1) {
        width: auto;
      }
    }
  }

  @media (min-width: 1423px) {
    &:first-of-type(div) {
      width: 100%;
    }
    width: 967px;
  }

  ${theme.mediaQueryMinWidth.xxlg} {
    width: 1315px;
  }
`;

const UpgradeMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 691px;
  padding-right: 24px;
`;

const UpgradeAction = styled.div`
  display: flex;
  align-items: center;
`;

const UpgradeTitle = styled.div`
  color: ${({ theme }) => theme.colors.primary[100]};
  font-weight: 900;
`;

const UpgradeBody = styled.div`
  margin-top: 12px;
  font-size: 14px;
`;

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-sizing: border-box;
`;
const EmptyListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${emptyDesign});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 100%;
  height: 430px;
  padding-right: 32px;
`;

const EmptyTitle = styled.p`
  font-size: 18px;

  font-weight: 700;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  cursor: pointer;
  &:hover {
    background: ${theme.palette.blue05};
    border-radius: 5px;
  }
`;

const delayedSearch = debounce(async (params: DelayedSearchParams) => {
  const {
    search,
    start,
    limit,
    setItems,
    setLoading,
    setTotalItems,
    showUserCustomLandingPages,
  } = params;
  setLoading(true);
  const res = await getLandingPages({
    start,
    limit,
    search,
    showUserCustomLandingPages,
  }).catch(err => err);
  if (res instanceof Error) {
    setItems([]);
    setLoading(false);
    return;
  }
  const {
    templates: landingPages,
    default: defaultLandingPage,
    count,
  } = res as GetLandingPagesResponse;
  const listItems = landingPages.map(t => {
    const listItem = { ...t, isDefault: false };
    if (defaultLandingPage && listItem.id === defaultLandingPage.id) {
      listItem.isDefault = true;
    } else {
      listItem.isDefault = false;
    }
    return listItem;
  });

  const currentDefault = { ...defaultLandingPage, isDefault: true };

  const sortedItems = listItems.sort((a, b) => {
    if (a.id < 0) {
      return -1;
    }

    if (b.id < 0) {
      return 1;
    }

    if (a.isDefault) {
      return -1;
    }

    if (!a.isDefault === !b.isDefault) {
      return 0;
    }

    return 0;
  });

  if (
    start === 0 &&
    !search &&
    currentDefault.id &&
    !sortedItems.find(i => i.id === currentDefault.id)
  ) {
    sortedItems.splice(1, 0, currentDefault);
  }

  setTotalItems(count);

  setItems(sortedItems);
  setLoading(false);
}, 500);

interface ModalWithData {
  name: 'layout' | 'style' | 'none';
  type: 'add' | 'edit' | 'none';
}

const LandingPages = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [limit, setLimit] = React.useState(12);
  const [page, setPage] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState<string | undefined>(undefined);
  const [items, setItems] = React.useState<ListLandingPage[]>([]);
  const [selectedLandingPage, setSelectedLandingPage] = React.useState(null);
  const [menuOpenedForLandingPage, setMenuOpenedForLandingPage] =
    React.useState('');

  const queryClient = useQueryClient();
  const { showError } = useToastError();

  const [modalWithData, setModalWithData] = React.useState<ModalWithData>({
    name: 'none',
    type: 'none',
  });

  const [showDeleteLandingPageModal, setShowDeleteLandingPageModal] =
    React.useState<boolean>(false);

  const [hasLandingPage, setHasLandingPage] = React.useState<boolean>(false);
  const [showRenameLandingPageModal, setShowRenameLandingPageModal] =
    React.useState(false);

  const {
    setIsEnabled,
    setSelectedStyle,
    setSelectedLayout,
    setSelectedLayoutData,
    setIsFullScreen,
    setPageBackgroundColor,
    setPageBackgroundImageUrl,
    setPageTitle,
    setPageId,
    setIsShared,
    setIsDefault,
    setAllowDuplicates,
    setDesignId,
    setSelectedStyleProperties,
    setDefaultCta,
    setDefaultSocialLinks,
    setElementToEdit,
    setElementIndex,
    setElementSectionKey,
    setShowSaveDesignModal,
    setIsUpdateMode,
    setIsImageUploadInProgress,
    setPageBackgroundPosition,
    setPageBackgroundSize,
  } = useLandingPageBuilderContext();

  const resetLandingPageBuilder = () => {
    setIsEnabled(false);
    setSelectedStyle('');
    setSelectedLayout('');
    setSelectedLayoutData({});
    setIsFullScreen(false);
    setPageBackgroundColor(null);
    setPageBackgroundImageUrl(null);
    setPageTitle('');
    setPageId(null);
    setDesignId(null);
    setAllowDuplicates(false);
    setIsDefault(false);
    setIsShared(false);
    setSelectedStyleProperties({});
    setElementToEdit(null);
    setElementIndex('');
    setElementSectionKey('');
    setDefaultCta(null);
    setDefaultSocialLinks(null);
    setIsUpdateMode(false);
    setIsFullScreen(false);
    setShowSaveDesignModal(false);
    setIsImageUploadInProgress(false);
    setPageBackgroundPosition('left top');
    setPageBackgroundSize('cover');
  };

  const { userData } = useAuth();

  const [showUserCustomLandingPages, setShowUserCustomLandingPages] =
    React.useState(false);

  const { isLoading: isLoadingOverlays, data: overlays } = useGetOverlays({
    start: 0,
    limit: 1,
    search: '',
  });

  const allowDefaultWebsiteOverlay = isLoadingOverlays || !overlays?.default;

  const setAsDefault = async (id: number): Promise<void> => {
    try {
      const response: SetDefaultLandingPageResponse =
        await setDefaultLandingPage(id);
      if (response.newDefaultLandingPageId) {
        const newDefaultId = response.newDefaultLandingPageId;
        const listItems = items.map(t => {
          const listItem = { ...t, isDefault: false };
          if (listItem.id === newDefaultId) {
            listItem.isDefault = true;
          } else {
            listItem.isDefault = false;
          }
          return listItem;
        });
        setItems(listItems);
      }

      queryClient.invalidateQueries([TEMPLATE_QUERY]);
    } catch (error) {
      showError(
        error || 'Something went wrong setting Landing Page as default'
      );
    }
  };
  React.useEffect(() => {
    const isLandingToggleVisible = localStorage.getItem(
      // eslint-disable-next-line no-useless-concat
      userData.userId + '.' + 'isLandingToggleVisible'
    );
    if (!isLandingToggleVisible) {
      setShowUserCustomLandingPages(false);
    } else {
      const defaultValue = 'false';
      const setIsVisible = defaultValue !== isLandingToggleVisible;
      setShowUserCustomLandingPages(setIsVisible);
    }
  }, [userData]);

  const onRightButtonClick = async (id: number) => {
    let url = '/design/overlay-urls';
    if (id === WEBSITE_OVERLAY_ID) {
      history.push(url);
    } else {
      const res = await getLandingPagePreview(id).catch(err => err);
      if (res instanceof Error) {
        errorToast({ title: 'Landing Pages preview is currently unavailable' });
        return;
      }
      if (!res.success) {
        errorToast({ title: 'This preview is currently unavailable' });
        return;
      }

      const a = document.createElement('a');
      a.href = res.url + '?nn=1&sample=1';
      a.rel = 'noopener noreferrer';
      a.target = '_blank';
      a.click();
    }
  };

  const onCloseModal = () => {
    setModalWithData({ name: 'none', type: 'none' });
  };

  React.useEffect(() => {
    delayedSearch({
      start: page * limit,
      limit,
      search: search || '',
      setItems,
      setLoading,
      setTotalItems,
      showUserCustomLandingPages,
    });
  }, [page, limit]);

  React.useEffect(() => {
    if (typeof search !== 'undefined') {
      if (page > 0) {
        setPage(0);
      }
      delayedSearch({
        start: 0,
        limit,
        search: search || '',
        setItems,
        setLoading,
        setTotalItems,
        showUserCustomLandingPages,
      });
    }
  }, [search]);

  React.useEffect(() => {
    if (page === 0) {
      delayedSearch({
        start: 0,
        limit,
        search: search || '',
        setItems,
        setLoading,
        setTotalItems,
        showUserCustomLandingPages,
      });
    }
    setPage(0);
  }, [showUserCustomLandingPages]);

  React.useEffect(() => {
    delayedSearch({
      start: page * limit,
      limit,
      search: search || '',
      setItems,
      setLoading,
      setTotalItems,
      showUserCustomLandingPages,
    });
  }, [showDeleteLandingPageModal]);

  React.useEffect(() => {
    resetLandingPageBuilder();
  }, []);

  React.useEffect(() => {
    GetLPBStats()
      .then(data => {
        setHasLandingPage(!!data.activated);
      })
      .catch(() => {
        setHasLandingPage(false);
      });
  }, []);

  function onContinueButtonClick() {
    setModalWithData({ name: 'style', type: 'add' });
  }

  const openLandingPageRoute = () => {
    history.push('/design/landing-page-builder');
  };

  const duplicateLandingPage = async (design: any) => {
    const newLandingPage = { ...design.landingPage };
    newLandingPage.isDefault = 0;
    newLandingPage.isShared = 0;
    newLandingPage.userId = Number(userData.userId);
    delete newLandingPage['id'];
    delete newLandingPage['designId'];
    newLandingPage.title += ' (1)';

    try {
      await createLandingPage(newLandingPage);

      successToast({ title: 'Duplicate Landing Page created.' });
      delayedSearch({
        start: page * limit,
        limit,
        search: search || '',
        setItems,
        setLoading,
        setTotalItems,
        showUserCustomLandingPages,
      });

      queryClient.invalidateQueries([TEMPLATE_QUERY]);
    } catch (error) {
      showError(error || 'Error duplicating landing page');
    }
  };

  const getActionItemsForDropdown: any = (design: any) => {
    const isOwner =
      Number(design.landingPage.userId) === Number(userData.userId);
    const landingPage = design.landingPage;

    if (!landingPage) {
      return <></>;
    }
    let styleProperties = defaultStyleProperties[landingPage.styleName] || {};

    const actionItems = [];

    if (design.typeId !== 20) {
      actionItems.push({
        title: 'Preview',
        icon: <IoMdEye size={20} />,
        onClick: () => {
          onRightButtonClick(design.id);
        },
      });
    }

    if (isOwner) {
      actionItems.push({
        title: 'Edit',
        icon: <MdEdit size={20} />,
        onClick: () => {
          setSelectedLayoutData(landingPage.pageData.selectedLayoutData || {});
          setSelectedLayout(landingPage.layoutName);
          setSelectedStyle(landingPage.styleName);
          setPageBackgroundColor(
            landingPage.pageData?.pageDetails?.backgroundColor
          );
          setPageBackgroundImageUrl(
            landingPage.pageData?.pageDetails?.backgroundImageUrl
          );
          setPageTitle(landingPage.title);
          setIsEnabled(true);
          setPageId(landingPage.id);
          setDesignId(design.id);
          setAllowDuplicates(landingPage.allowDuplicates);
          setIsShared(landingPage.isShared);
          setIsDefault(landingPage.isDefault);
          setSelectedStyleProperties(styleProperties);
          setIsUpdateMode(true);
          openLandingPageRoute();
          setPageBackgroundSize(
            landingPage.pageData?.pageDetails?.pageBackgroundSize
          );
          setPageBackgroundPosition(
            landingPage.pageData?.pageDetails?.pageBackgroundPosition
          );
        },
      });
    }

    actionItems.push({
      title: 'Duplicate',
      icon: <MdContentCopy size={20} />,
      onClick: () => {
        duplicateLandingPage(design);
      },
    });

    if (isOwner) {
      actionItems.push({
        title: 'Rename',
        icon: <MdDriveFileRenameOutline width={20} height={20} />,
        onClick: () => {
          setSelectedLandingPage(design);
          setShowRenameLandingPageModal(true);
        },
      });
    }

    if (isOwner && !design.isDefault && design.typeId !== 20) {
      actionItems.push({
        title: 'Delete',
        icon: <MdDeleteForever color={'red'} width={20} height={20} />,
        onClick: () => {
          setSelectedLandingPage(design);
          setShowDeleteLandingPageModal(true);
        },
      });
    }

    return actionItems;
  };
  function getDropdownMenuForCustomLandingPage(design: any) {
    const id = design.id;
    const actions = getActionItemsForDropdown(design);
    return (
      <>
        <ButtonWrapper>
          <BsThreeDotsVertical
            onClick={() =>
              setMenuOpenedForLandingPage(
                menuOpenedForLandingPage !== id ? id : ''
              )
            }
            color={theme.palette.blue80}
            style={{ cursor: 'pointer' }}
          />
        </ButtonWrapper>

        <ListDropdownMenu
          isMenuOpen={menuOpenedForLandingPage === id}
          items={actions}
          id={id}
          setIsMenuOpen={() => setMenuOpenedForLandingPage('')}
        />
      </>
    );
  }

  function getRowButtons(item: ListLandingPage): React.ReactNode[] {
    let buttons: any = [];
    if (item.typeId !== 19 && item.typeId !== 20) {
      buttons =
        item.id === WEBSITE_OVERLAY_ID
          ? [
              <Button
                text={'Manage URLs'}
                onClick={() => onRightButtonClick(item.id)}
              />,
            ]
          : [
              <Button
                variant='secondary'
                icon={<IoMdEye size={24} />}
                onClick={() => onRightButtonClick(item.id)}
              />,
            ];
    }

    if ((item.typeId == 19 || item.typeId == 20) && item.landingPage) {
      const items = getDropdownMenuForCustomLandingPage(item);
      buttons.push(items);
    }

    return buttons;
  }

  const onRenameModalClose = () => {
    setShowDeleteLandingPageModal(false);
    setSelectedLandingPage(null);
    delayedSearch({
      start: page * limit,
      limit,
      search: search || '',
      setItems,
      setLoading,
      setTotalItems,
      showUserCustomLandingPages,
    });
  };

  const domain = window.location.hostname;

  const isCovideoDomain =
    domain.includes('covideo.com') || domain.includes('localhost');

  const onPaginationChange = async ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    if (page !== newPage || limit !== newSize) {
      setPage(newPage);
      setLimit(newSize);
    }
  };

  console.log(limit);

  return (
    <>
      <MainWrapper resetPadding={true}>
        <HelpDesk name={EHelpDesk.LANDING_PAGES} />
        <HeaderWrapper>
          <Gap gap='12px'>
            <Heading>Landing Pages</Heading>
            <Search
              width='260px'
              placeholder={'Search Landing Pages...'}
              onSearch={setSearch}
              onChange={e => setSearch(e.target.value)}
              disabled={loading}
            />
          </Gap>
          {hasLandingPage && (
            <Gap gap='12px'>
              <LabelSwitch
                text='Only show My Custom Landing Pages'
                onTextClick={() => {
                  setShowUserCustomLandingPages(!showUserCustomLandingPages);
                }}
                handleToggle={() => {
                  localStorage.setItem(
                    // eslint-disable-next-line no-useless-concat
                    userData.userId + '.' + 'isLandingToggleVisible',
                    JSON?.stringify(!showUserCustomLandingPages)
                  );
                  setShowUserCustomLandingPages(!showUserCustomLandingPages);
                }}
                switchId={'switch-lp'}
                isOn={showUserCustomLandingPages}
              />
              <Button
                text={'Add New Design'}
                onClick={() =>
                  setModalWithData({ name: 'layout', type: 'add' })
                }
              />
            </Gap>
          )}
        </HeaderWrapper>
        {isCovideoDomain && !hasLandingPage && (
          <UpgradeArea>
            <UpgradeMessage>
              <UpgradeTitle>Design custom landing pages yourself!</UpgradeTitle>
              <UpgradeBody>
                Choose your layout and style, design CTA buttons, customize your
                video player, change fonts and colors, and freely change the
                order of elements.
              </UpgradeBody>
            </UpgradeMessage>
            <UpgradeAction>
              <Button
                text={
                  userData.access === '3' || userData.access === '4'
                    ? 'Upgrade Today'
                    : 'Learn More'
                }
                icon={<IoMdUnlock />}
                onClick={() => {
                  history.push('/profile/add-ons/lpb/manage');
                }}
              />
            </UpgradeAction>
          </UpgradeArea>
        )}
        {loading ? (
          <div>
            <LoadingIndicator isLoading={true} />
          </div>
        ) : (
          <>
            <TableContextProvider
              total={totalItems}
              initPage={page}
              initSize={limit}
              onChange={onPaginationChange}
            >
              <FlexContainer>
                {items.length > 0 ? (
                  items.map((item, index) => {
                    const isWebsiteOverlay = item.id === WEBSITE_OVERLAY_ID;
                    const thumbnailURL = isWebsiteOverlay
                      ? ''
                      : item.landingPage?.thumbnail || item.thumbnailURL;
                    return (
                      <SquareListItem
                        key={index}
                        isDefault={item.isDefault}
                        noThumbnail={isWebsiteOverlay}
                        thumbnailSource={thumbnailURL}
                        title={item.title}
                        onLeftButtonPress={() => setAsDefault(item.id)}
                        optionButtonsRight={getRowButtons(item)}
                        disableLeftClick={
                          isWebsiteOverlay && allowDefaultWebsiteOverlay
                        }
                      />
                    );
                  })
                ) : (
                  <EmptyListContainer>
                    <EmptyTitle>
                      {totalItems === 0 && 'No landing pages found.'}
                    </EmptyTitle>
                  </EmptyListContainer>
                )}
              </FlexContainer>

              <PaginationWrapper>
                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew text='Show rows:' />
                </TableFooter>
              </PaginationWrapper>
            </TableContextProvider>
          </>
        )}
      </MainWrapper>
      {selectedLandingPage != null && showRenameLandingPageModal && (
        <ModalRenameLandingPage
          design={selectedLandingPage}
          modalLoading={false}
          onClose={onRenameModalClose}
        />
      )}
      {selectedLandingPage != null && showDeleteLandingPageModal === true ? (
        <ModalDeleteLandingPage
          selectedLandingPage={selectedLandingPage}
          modalLoading={false}
          onClose={() => {
            setShowDeleteLandingPageModal(false);
            setSelectedLandingPage(null);
          }}
        />
      ) : (
        <></>
      )}
      {modalWithData.name === 'layout' ? (
        <ModalLandingPageDesignAddOrEdit
          type={'add'}
          modalLoading={false}
          onClose={() => {
            onCloseModal();
            setSelectedLayout('');
          }}
          onContinueButtonClick={onContinueButtonClick}
        />
      ) : modalWithData.name === 'style' ? (
        <ModalLandingPageSelectStyle
          type={'add'}
          modalLoading={false}
          onClose={() => {
            onCloseModal();
            setSelectedStyle('');
          }}
          onMainButtonClick={() => {
            setIsEnabled(true);
            onCloseModal();
            openLandingPageRoute();
            history.push('/design/landing-page-builder');
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default LandingPages;
// Methods
