import { UsernameExistsParams } from './types';
import { ManagedUsersParams } from './useManagedUsersQuery';

export const MANAGED_USERS_KEY = 'MANAGED_USERS';
export const usersKeys = {
  user: () => ['USER'] as const,
  customer_user: () => ['USER_CUSTOMER'] as const,
  username_exists: (params: UsernameExistsParams) =>
    ['USERNAME_EXISTS', params] as const,
  managed_users: (params: ManagedUsersParams) =>
    [MANAGED_USERS_KEY, params] as const,

  user_profile: (userId: string) => ['USER', userId] as const,
  user_userneme: () => ['USER', 'USERNAME'] as const,
};
