import { useQuery } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { leadGroupsKeys } from './leadGroupsKeys';

export interface ILeads {
  leadId: number;
  customerId: number;
  integrationDealerId: string;
  integrationContactId: string;
  dealerSocketEventId: null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  state: string;
  city: string;
  zip: string;
  address: string;
  addedOn: string;
  source: string;
  emailPreferred: number;
  smsPreferred: number;
  sold: number;
  leadType: null;
  leadStatus: null;
  leadStatusType: null;
  salesRep: null;
  salesRepVinSolutionsUserId: number;
  bdAgentVinSolutionsUserId: number;
  views: number;
  sends: number;
  createdAt: string;
}

export interface IGroups {
  leadGroupId: number;
  userId: number;
  name: string;
  leads: ILeads[];
}

interface ILeadGroupsResponse {
  count: 0;
  groups: IGroups[];
}

interface IParams {
  start: number;
  limit: number;
  page: number;
  search: string;
}

const getLeadGroups = async (props: IParams): Promise<ILeadGroupsResponse> => {
  const params = {
    limit: props.limit,
    search: props.search,
    start: props.start,
  };

  return (
    await EXPRESS_API.get(`/lead-groups`, {
      params,
    })
  ).data;
};

export const useLeadGroupsQuery = (params: any, enabled: boolean = true) => {
  return useQuery(
    leadGroupsKeys.list({ page: params.page, search: params.search || '' }),
    () => getLeadGroups(params),
    {
      refetchOnMount: true,
      staleTime: 0,
      enabled,
      onError: () => {
        errorToast({
          title:
            "We're currently experiencing difficulties fetching lead groups",
        });
      },
    }
  );
};
