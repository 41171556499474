import React, { useState } from 'react';
import { LoginAsModal } from 'app/pages/reports/components';
import { MdLaunch } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { useToastError } from 'lib/hooks';
import { ModalPrompt } from 'lib/components/modal';
import { generateLoginAsDetails } from 'lib/utils/superAdmin';
import { useLoginAsUser } from 'lib/api/users/useLoginasUser';

type Props = {
  userId: number;
  username: string;
  dirty?: boolean;
  onSave?: (reloadUserOnSave?: boolean) => Promise<boolean>;
};

export const LoginAsButton = ({ userId, username, onSave, dirty }: Props) => {
  const { showError } = useToastError();
  const { mutateAsync: loginAsUser, isLoading: loginAsLoading } =
    useLoginAsUser();
  const [showLoginAsModal, setShowLoginAsModal] = useState(false);
  const [showLoginAsPrompt, setShowLoginAsPrompt] = useState(false);

  const handleLoginAsClick = async () => {
    if (!!onSave && dirty) {
      setShowLoginAsPrompt(true);
      return;
    }
    handleLoginAs();
  };

  const handleLoginAs = async () => {
    if (loginAsLoading) {
      return;
    }
    const { timestamp, hexKey } = generateLoginAsDetails(userId, username);
    try {
      await loginAsUser({ userId: userId.toString(), timestamp, key: hexKey });
      setShowLoginAsModal(true);
    } catch (error) {
      showError(error);
    }
  };

  const handleLoginAsSaveChanges = async () => {
    if (!onSave) {
      return;
    }
    setShowLoginAsPrompt(false);
    try {
      // save without reload to loginAs right after save
      const saveSuccessfull = await onSave();
      if (saveSuccessfull) {
        handleLoginAs();
      }
    } catch (error) {
      showError(error);
    }
  };

  const handleLoginAsProceedWithoutSaving = async () => {
    setShowLoginAsPrompt(false);
    handleLoginAs();
  };

  return (
    <>
      <Button
        text='Log In As User'
        variant='primary'
        icon={<MdLaunch size={18} />}
        onClick={() => handleLoginAsClick()}
        disabled={loginAsLoading}
      />

      {showLoginAsPrompt && (
        <ModalPrompt
          title={'Before logging in...'}
          content={<>You have unsaved changes on this page.</>}
          secondaryButtonText={'Proceed Without Saving'}
          handleSecondarySubmit={handleLoginAsProceedWithoutSaving}
          primaryButtonText={'Save Changes'}
          handleSubmit={handleLoginAsSaveChanges}
          handleModalClose={() => setShowLoginAsPrompt(false)}
        />
      )}

      {showLoginAsModal && (
        <LoginAsModal
          handleModalClose={() => {
            setShowLoginAsModal(false);
            window.location.reload();
          }}
        />
      )}
    </>
  );
};
