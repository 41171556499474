import React from 'react';
import { useField } from 'formik';
import { ValueType } from 'react-select';
import { FormikErrorMessage } from './FormikErrorMessage';
import { FormikLabel } from './FormikLabel';
import { Dropdown } from 'lib/components';
import { Flex, FlexProps } from '../styles/layout';
import { CSSProperties } from 'styled-components/macro';
interface Option {
  value: string | number;
  label: string;
  __isNew__?: string;
}
export type OptionValue = ValueType<Option, false>;

interface CustomDropdownProps {
  name: string;
  label: string;
  isRequired: boolean;
  options: Option[];
  disabled: boolean;
  onChange?: (option: OptionValue) => void;
  extendStyles?: {
    errorWrapper?: CSSProperties;
    labelStyles?: CSSProperties;
    inputWrapper?: FlexProps;
    mainWrapper?: FlexProps;
  };
  info?: string | React.ReactNode;
  creatable?: boolean;
  placeholder?: string;
}

export const FormikDropdownField = ({
  label,
  isRequired,
  options,
  disabled,
  onChange,
  extendStyles,
  info,
  creatable = false,
  placeholder = 'Select...',
  ...props
}: CustomDropdownProps) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (option: OptionValue) => {
    if (!!onChange) {
      onChange(option);
      return;
    }

    helpers.setValue(option ? option.value : '');
  };

  return (
    <Flex {...extendStyles?.mainWrapper} gap='4px'>
      <FormikLabel
        label={label}
        isRequired={isRequired}
        disabled={!!disabled}
        info={info}
        labelStyles={extendStyles?.labelStyles}
      />
      <Flex {...extendStyles?.inputWrapper} gap='4px'>
        <Dropdown
          disabled={disabled}
          value={options.find(option => option.value === field.value) || null}
          onChange={handleChange}
          placeholder={placeholder}
          onMenuOpen={() => helpers.setTouched(true)}
          options={options}
          menuPortalTarget={document.body}
          inputId={props.name}
          creatable={creatable}
        />
        <FormikErrorMessage meta={meta} />
      </Flex>
    </Flex>
  );
};
