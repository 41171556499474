import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components/macro';
import { last } from 'lodash';
import { CheckboxInput, Dropdown } from 'lib/components';
import { Flex } from 'lib/components/styles/layout';

const StyledParagraph = styled.p<{ disabled?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral[60]};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

const OptionLabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black[100]};
  margin-left: 8px;
  cursor: pointer;
  width: 350px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
`;

const MenuHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black[100]};
  padding: 8px 24px;
  border-bottom: 1px solid #eeeff2;
  cursor: pointer;
`;

const truncateText = (text: string, maxLength: number = 15): string =>
  text.trim().length > maxLength
    ? `${text.trim().substring(0, maxLength)}...`
    : text;

const MenuList = (props: any) => {
  return (
    <components.MenuList {...props}>
      {props?.selectProps?.headerOption && (
        <MenuHeader
          onClick={() => {
            props.selectProps.onChange([props?.selectProps?.headerOption]);
          }}
        >
          {props?.selectProps?.headerOption?.label}
        </MenuHeader>
      )}
      {props.children}
    </components.MenuList>
  );
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <OptionWrapper>
          <CheckboxInput
            checked={props.isSelected}
            onChange={() => null}
            onClick={(event: React.MouseEvent<HTMLLabelElement>) => {
              event.preventDefault();
            }}
          />
          <OptionLabel title={props.label}>{props.label}</OptionLabel>
        </OptionWrapper>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, ...props }: any) => {
  const values = props.getValue();

  // to enable closing on outside click
  const selectOptionsAndInput = React.Children.map(children, child => {
    if (child && [components.SingleValue].indexOf(child.type) === -1) {
      return child;
    }
    return null;
  });

  let searchInput = last(selectOptionsAndInput);

  let text = 'All Items';

  if (props?.selectProps?.headerOption?.label) {
    text = truncateText(props?.selectProps?.headerOption?.label, 25);
  }

  if (values.length) {
    text = truncateText(values[0].label, 25);
  }

  if (values.length > 1) {
    text = `${values.length} items`;
  }

  return (
    <components.ValueContainer {...props}>
      <div>{text}</div>
      {searchInput}
    </components.ValueContainer>
  );
};

interface IProps {
  onChange: (values: { value: string; label: string }[]) => void;
  options: { value: string; label: string }[];
  selectInputWidth?: string;
  showMenu?: boolean;
  setShowMenu?: (arg: boolean) => void;
  menuPortalTarget?: HTMLElement | null;
  menuShouldBlockScroll?: boolean;
  placeholder?: string;
  value: { value: string; label: string }[] | null;
  headerOption?: { value: string; label: string };
  label?: string;
  disabled?: boolean;
}

export const MultipleDropdownWithCheckbox = ({
  onChange,
  options,
  menuPortalTarget = document.body,
  menuShouldBlockScroll = true,
  placeholder = 'Filters',
  value,
  headerOption,
  label,
  disabled,
}: IProps) => {
  const onChangeDepartments = (value: { value: string; label: string }[]) => {
    onChange(value);
  };

  return (
    <Flex width='auto'>
      {label && <StyledParagraph>{label}</StyledParagraph>}
      <Dropdown
        placeholder={placeholder}
        options={options}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuShouldBlockScroll={menuShouldBlockScroll}
        menuPortalTarget={menuPortalTarget}
        menuPosition='absolute'
        customComponents={{
          Option,
          ValueContainer,
          IndicatorSeparator: () => null,
          MenuList,
        }}
        onChange={value => onChangeDepartments(value)}
        allowSelectAll={true}
        value={value}
        creatable={false}
        disabled={disabled}
        headerOption={headerOption}
      />
    </Flex>
  );
};
