import { SVGProps } from 'react';
import { ReactComponent as ChaptersIcon } from './chapters.svg';

import { ReactComponent as EmailShareIcon } from './emailShare.svg';
import { ReactComponent as GuideIcon } from './guide.svg';
import { ReactComponent as ServicesIcon } from './services.svg';
import { ReactComponent as SnippetsIcon } from './snippets.svg';
import { ReactComponent as VirtualBgIcon } from './virtualBackground.svg';
// annotations
import { ReactComponent as AnnotationsIcon } from './annotations/annotations.svg';
import { ReactComponent as TopLeftIcon } from './annotations/topLeft.svg';
import { ReactComponent as TopRightIcon } from './annotations/topRight.svg';
import { ReactComponent as BottomCenterIcon } from './annotations/bottomCenter.svg';
import { ReactComponent as BottomLeftIcon } from './annotations/bottomLeft.svg';
import { ReactComponent as BottomRightIcon } from './annotations/bottomRight.svg';
// onbarding
import { ReactComponent as OutlookIcon } from './onboarding/outlook.svg';
import { ReactComponent as ChromeSquareIcon } from './onboarding/chrome.svg';
import { ReactComponent as CovideoIcon } from './onboarding/covideo.svg';
import { ReactComponent as NoEmailSvg } from './onboarding/noEmail.svg';

export type IconName =
  | 'annotations'
  | 'chapters'
  | 'chrome'
  | 'email-share'
  | 'guide'
  | 'outlook'
  | 'services'
  | 'snippets'
  | 'virtual-bg'
  | 'top-left'
  | 'top-right'
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'no-email'
  | 'covideo';

type IconConfig = {
  component: React.FunctionComponent<SVGProps<SVGSVGElement>>;
};

export const svgIconsConfig: Record<IconName, IconConfig> = {
  annotations: { component: AnnotationsIcon },
  chapters: { component: ChaptersIcon },
  chrome: { component: ChromeSquareIcon },
  'email-share': { component: EmailShareIcon },
  guide: { component: GuideIcon },
  outlook: { component: OutlookIcon },
  services: { component: ServicesIcon },
  snippets: { component: SnippetsIcon },
  'virtual-bg': { component: VirtualBgIcon },
  'top-left': { component: TopLeftIcon },
  'top-right': { component: TopRightIcon },
  'bottom-center': { component: BottomCenterIcon },
  'bottom-left': { component: BottomLeftIcon },
  'bottom-right': { component: BottomRightIcon },
  covideo: { component: CovideoIcon },
  'no-email': { component: NoEmailSvg },
};
