import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { debounce } from 'lodash';
import { MdModeEdit, MdDeleteForever } from 'react-icons/md';
import { IoMdEye } from 'react-icons/io';
import { MoonLoader } from 'react-spinners';
import emptyDesign from 'assets/icons/design/emptyDesignBg.svg';
import Preview from './Preview';

import { theme } from 'lib/style';
import { SquareListItem, Search, ModalDelete } from 'lib/components';

import Pagination from '../common/Pagination';
import {
  getEmailThumbnails,
  setEmailThumbnailAsDefault,
  deleteEmailIcon,
  createEmailThumbnailRecord,
  editEmailIcon,
} from 'lib/api/designApi';
import {
  GetEmailThumbnailsResponse,
  EmailIconDefaultType,
  ListThumbnail,
  SetEmailIconDefaultResponse,
  CreateThumbnailRecordParams,
  UpdateThumbnailRecordParams,
} from 'lib/api';
import { MdFileUpload } from 'react-icons/md';
import { ModalAddEditThumbnail } from 'lib/components/modal';
import { HeaderWrapper, MainWrapper, Gap } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';
import { useToastError } from 'lib/hooks';

interface ModalState {
  mode: 'add' | 'delete' | 'edit' | 'preview' | 'none';
  data?: ListThumbnail;
}

interface DelayedSearchParams {
  start: number;
  limit: number;
  search: string;
  setItems: React.Dispatch<React.SetStateAction<ListThumbnail[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
  setTotalItems: React.Dispatch<React.SetStateAction<number>>;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  box-sizing: border-box;
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
  gap: 24px;
`;

const LoaderWrapper = styled.div`
  position: fixed;
  height: 50vh;
  width: 50vw;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${emptyDesign});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 100%;
  height: 430px;
`;

const EmptyTitle = styled.p`
  font-size: 18px;

  font-weight: 700;
  margin-bottom: 32px;
`;
// Fetch method

const delayedSearch = debounce(async (params: DelayedSearchParams) => {
  const {
    search,
    start,
    limit,
    setItems,
    setLoading,
    setLastPage,
    setTotalItems,
  } = params;
  setLoading(true);
  try {
    const res: GetEmailThumbnailsResponse = await getEmailThumbnails({
      start,
      limit,
      search,
    });
    const {
      icons,
      templates: tps,
      iconsCount,
      templatesCount,
      default: dft,
    } = res as GetEmailThumbnailsResponse;

    const mappedTemplates =
      tps.length > 0
        ? tps.map(template => {
            const item = {
              id: template.id,
              title: template.title,
              thumbnail: template.thumbnail,
              type: 'templates' as EmailIconDefaultType,
              isDefault: false,
            };
            if (
              dft !== undefined &&
              dft.id === item.id &&
              dft.type === 'templates'
            ) {
              item.isDefault = true;
            }
            return item;
          })
        : [];

    const mappedIcons =
      icons.length > 0
        ? icons.map(icon => {
            const item = {
              id: icon.id,
              title: icon.title,
              thumbnail: icon.thumbnail,
              type: 'icons' as EmailIconDefaultType,
              isDefault: false,
            };

            if (dft && dft.id === item.id && dft.type === 'icons') {
              item.isDefault = true;
            }
            return item;
          })
        : [];

    const listItems = mappedTemplates.concat(mappedIcons);

    if (listItems.length > 0) {
      const defaultItem = { ...dft, isDefault: true };

      const sortedItems = listItems.sort((a, b) => {
        if (a.isDefault) return -1;
        if (!a.isDefault === !b.isDefault) return 0;
        return 1;
      });

      if (
        defaultItem &&
        defaultItem.id &&
        start === 0 &&
        !search &&
        !sortedItems.find(
          i => i.id === defaultItem.id && i.type === defaultItem.type
        )
      ) {
        const d = { ...defaultItem, isDefault: true };
        sortedItems.splice(0, 0, d);
      }

      if (!search) {
        const totalCount = iconsCount + templatesCount;
        setTotalItems(totalCount);
        setLastPage(Math.ceil(totalCount / limit));
      } else {
        setTotalItems(sortedItems.length);
        setLastPage(Math.ceil(sortedItems.length / limit));
      }

      setItems(sortedItems);
    } else {
      setTotalItems(0);
      setItems([]);
    }
    setLoading(false);
  } catch (error) {
    setItems([]);
    setLoading(false);
  }
}, 500);

// Components

const Thumbnails = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [limit, setLimit] = React.useState(12);
  const [page, setPage] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [lastPage, setLastPage] = React.useState(1);
  const [search, setSearch] = React.useState<string | undefined>(undefined);
  const [items, setItems] = React.useState<ListThumbnail[]>([]);
  const [modalState, setModalState] = React.useState<ModalState>({
    mode: 'none',
  });
  const themes = useTheme();
  const { showError } = useToastError();
  const deleteThumbnail = async (id: number) => {
    setLoading(true);
    try {
      await deleteEmailIcon(id);
      const filteredItems = items.filter(i => i.id !== id);
      setModalState({ mode: 'none', data: undefined });
      setItems(filteredItems);
      setLoading(false);
      successToast({
        title: 'Thumbnail deleted successfully.',
      });
    } catch (error) {
      setLoading(false);
      showError(
        error ||
          'Something went wrong deleting your Thumbnail, try again later!'
      );
    }
  };

  const handleEdit = async (p: UpdateThumbnailRecordParams) => {
    setLoading(true);
    try {
      await editEmailIcon(p);
      setModalState({ mode: 'none', data: undefined });
      if (page > 0 && page !== lastPage) {
        setPage(0);
      }
      delayedSearch({
        start: 0,
        limit,
        search: search || '',
        setItems,
        setLoading,
        setLastPage,
        setTotalItems,
      });
    } catch (error) {
      setLoading(false);
      showError(
        error || 'Something went wrong updating your Thumbnail, try again!'
      );
    }
  };

  const handleAddNew = async (params: CreateThumbnailRecordParams) => {
    setLoading(true);
    try {
      await createEmailThumbnailRecord(params);
      setModalState({ mode: 'none', data: undefined });
      if (page > 0 && page !== lastPage) {
        setPage(0);
      }
      delayedSearch({
        start: 0,
        limit,
        search: search || '',
        setItems,
        setLoading,
        setLastPage,
        setTotalItems,
      });
    } catch (error) {
      setLoading(false);
      showError(
        error || 'Something went wrong creating your Thumbnail, try again!'
      );
    }
  };

  const setAsDefault = async (
    id: number,
    type: EmailIconDefaultType
  ): Promise<void> => {
    try {
      const response: SetEmailIconDefaultResponse =
        await setEmailThumbnailAsDefault({ id, type });
      if (response.defaultsData) {
        const { defaultsData } = response;
        const listItems = items.map(t => {
          const el = { ...t, isDefault: false };
          if (el.id === defaultsData.id && el.type === defaultsData.type) {
            el.isDefault = true;
          } else {
            el.isDefault = false;
          }
          return el;
        });
        setItems(listItems);
      }
    } catch (error) {
      showError(error || 'Something went wrong setting Thumbnail as default');
    }
  };

  React.useEffect(() => {
    delayedSearch({
      start: page * limit,
      limit,
      search: search || '',
      setItems,
      setLoading,
      setLastPage,
      setTotalItems,
    });
  }, [page, limit]);

  React.useEffect(() => {
    if (typeof search !== 'undefined') {
      if (page > 0) {
        setPage(0);
      }
      delayedSearch({
        start: 0,
        limit,
        search: search || '',
        setItems,
        setLoading,
        setLastPage,
        setTotalItems,
      });
    }
  }, [search]);

  return (
    <MainWrapper resetPadding={false}>
      {modalState.mode === 'preview' && modalState.data && (
        <Preview
          title={modalState.data.title}
          thumbnailSource={modalState.data.thumbnail}
          onClose={() => setModalState({ mode: 'none', data: undefined })}
        />
      )}
      {modalState.mode === 'add' && (
        <ModalAddEditThumbnail
          loading={loading}
          mode={modalState.mode}
          onAction={handleAddNew}
          onDelete={data => setModalState({ mode: 'delete', data })}
          onClose={() => setModalState({ mode: 'none', data: undefined })}
        />
      )}
      {modalState.mode === 'edit' && modalState.data && (
        <ModalAddEditThumbnail
          loading={loading}
          mode={modalState.mode}
          data={modalState.data}
          onAction={handleEdit}
          onDelete={data => setModalState({ mode: 'delete', data })}
          onClose={() => setModalState({ mode: 'none', data: undefined })}
        />
      )}
      {modalState.mode === 'delete' && modalState.data && (
        <ModalDelete
          disabled={false}
          title={'Delete Thumbnail?'}
          orangeButtonText={'No, Keep Thumbnail'}
          whiteButtonText={'Yes, Delete'}
          text={`Are you sure you want to delete ${modalState.data?.title}? This action can't be undone.`}
          text1={''}
          hasConfirm={false}
          onClickOrangeButton={() =>
            setModalState({ mode: 'none', data: undefined })
          }
          onClickWhiteButton={() =>
            modalState.data && deleteThumbnail(modalState.data.id)
          }
          handleModalClose={() =>
            setModalState({ mode: 'none', data: undefined })
          }
        />
      )}
      <HeaderWrapper>
        <Gap>
          <Heading>Thumbnails</Heading>
          <Search
            width={'250px'}
            placeholder={'Search Thumbnails...'}
            onSearch={setSearch}
            onChange={e => setSearch(e.target.value)}
          />
        </Gap>
        <Button
          icon={<MdFileUpload />}
          text={'Upload Thumbnail'}
          disabled={loading}
          onClick={() => setModalState({ mode: 'add', data: undefined })}
        />
      </HeaderWrapper>
      <Content>
        {loading && (
          <LoaderWrapper>
            <MoonLoader
              size={64}
              color={themes.colors.primary[100]}
              loading={true}
            />
          </LoaderWrapper>
        )}
        {items.length > 0
          ? items.map((item, index) => {
              const thumbnailURL = getPreviewSourceURL(item.thumbnail);
              const { id, type } = item;
              return (
                <SquareListItem
                  key={index}
                  isDefault={item.isDefault}
                  noThumbnail={false}
                  thumbnailSource={thumbnailURL}
                  title={item.title}
                  onLeftButtonPress={() => setAsDefault(id, type)}
                  optionButtonsRight={getRowButtons(
                    { ...item, thumbnail: thumbnailURL },
                    setModalState
                  )}
                />
              );
            })
          : !loading && (
              <EmptyListContainer>
                <EmptyTitle>
                  {totalItems === 0 && 'No thumbnails found.'}
                </EmptyTitle>
              </EmptyListContainer>
            )}
      </Content>
      {!loading && (
        <Pagination
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          totalCount={totalItems}
          currentCount={items.length}
          lastPage={lastPage}
        />
      )}
    </MainWrapper>
  );
};

export default Thumbnails;

// Methods

function getPreviewSourceURL(thumbnail: string = '') {
  const isLegacyURL = thumbnail.startsWith('user_logos');
  if (isLegacyURL) {
    return `https://www.covideo.com/${thumbnail}`;
  }
  const isFullURL = thumbnail.startsWith('https');
  if (isFullURL) {
    return thumbnail;
  }
  // added condition to check if thumbnail exists or not SUS-648
  if (thumbnail) {
    return `https://www.covideo.com/EmailTemplateThumbs/${thumbnail}`;
  }
  return '';
}

function getRowButtons(
  item: ListThumbnail,
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>
): React.ReactNode[] {
  let buttons = [
    <Button
      variant='secondary'
      icon={<MdModeEdit size={24} />}
      onClick={() => setModalState({ mode: 'edit', data: item })}
    />,
    <Button
      variant='destructive'
      icon={<MdDeleteForever size={24} />}
      disabled={item.isDefault}
      onClick={() => setModalState({ mode: 'delete', data: item })}
    />,
    <Button
      variant='secondary'
      icon={<IoMdEye size={24} />}
      onClick={() => setModalState({ mode: 'preview', data: item })}
    />,
  ];

  if (item.type === 'templates') {
    buttons = buttons.slice(2, 3);
  }

  return buttons;
}
