import { useQuery } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { leadGroupsKeys } from './leadGroupsKeys';
import { ILeads } from './useLeadGroupsQuery';

export interface IGroups {
  leadGroupId: number;
  userId: number;
  name: string;
  leads: ILeads[];
}

const getSingleLeadGroup = async (leadGroupId: number): Promise<IGroups> => {
  return (await EXPRESS_API.get(`/lead-groups/${leadGroupId}`)).data;
};

export const useSingleLeadGroupQuery = (leadGroupId: number) => {
  return useQuery(
    leadGroupsKeys.single_group(leadGroupId),
    () => getSingleLeadGroup(leadGroupId),
    {
      refetchOnMount: true,
      onError: () => {
        errorToast({
          title:
            "We're currently experiencing difficulties fetching lead group",
        });
      },
    }
  );
};
