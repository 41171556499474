import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled, { useTheme } from 'styled-components/macro';
import { Form, Formik } from 'formik';
import { theme } from 'lib/style';
import { Modal, ButtonPillSwitch, ModalDelete } from 'lib/components';
import { useAuth } from 'lib/context';
import { FormColorPicker } from './FormColorPicker';
import { VideoPlayer } from '../../../../videoPlayer';
import { OpacitySlider } from './OpacitySlider';
import { positionsWithIcons } from '../constants/positions';
import {
  borderWidthValues,
  fontSizes,
  opacityValues,
} from '../constants/style';
import {
  calculateImageDimensions,
  capitalize,
  getImageRatio,
  determineAnnotationText,
  determineAnnotationValue,
  prepareAnnotation,
  calculatePercentageOnChange,
  calculateDimensionsOnChange,
} from '../util/functions';
import {
  annotationsSchema,
  annotationTypes,
  linkTypes,
  typeValues,
} from '../constants/types';
import { annotationSteps, initialTemplate } from '../constants/templates';
import { Label } from './Label';
import { FormSelect } from './FormSelect';
import { TextInput } from './TextInput';
import { ModalTemplateName } from './ModalTemplateName';
import Switch from 'app/pages/design/landingPageBuilder/components/Switch';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { AccessRole } from 'lib/const';
import { defaultAnnotation } from '../VideoAnnotations';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { useCreateAnnotationMutation } from 'lib/api/annotations/createAnnotation';
import { useCreateTemplateMutation } from 'lib/api/annotations/createTemplate';
import { useEditTemplateMutation } from 'lib/api/annotations/editTemplate';
import { useEditAnnotationMutation } from 'lib/api/annotations/editAnnotation';
import { IoMdClose, IoMdLink } from 'react-icons/io';
import { MdImage, MdOutlineAutorenew } from 'react-icons/md';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';

const Header = styled.div`
  display: flex;
  padding: 32px;
  align-items: center;
`;

const HeaderTitle = styled.div`
  font-size: ${theme.fontSizes.lg};
  height: 24px;
  ${theme.fontBold700}
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
  margin-right: 32px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  padding: 0 32px;
  display: flex;
  min-width: 1000px;
  min-height: 400px;

  ${theme.mediaQueryMaxWidth.md} {
    flex-direction: column;
    min-width: 100%;
    width: 100vw;
    box-sizing: border-box;
    grid-gap: 20px;
  }
`;

const StyleStep = styled.div<{ width?: string }>`
  width: 50%;
  padding: 0 16px;
  .infoField {
    font-size: 10px;
  }
  > div {
    ${props => (props.width ? `max-width: ${props.width}px;` : '')}
  }
  ${theme.mediaQueryMaxWidth.md} {
    width: 100%;
    box-sizing: border-box;

    > div {
      width: 100%;
    }
  }
`;

const VideoPlayerWrapper = styled.div`
  width: 100%;
  height: 280px;
`;

const FormGroupWrapper = styled.div`
  margin-top: 16px;
`;

const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ImageFileWrapper = styled.div`
  width: 45%;
  margin-right: 48px;
`;

const ImageFile = styled.label<{ imageFile?: string }>`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 38px;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  > svg {
    position: absolute;
    top: 8px;
    right: 10px;
  }
  &::after {
    position: absolute;
    top: 0;
    z-index: 3;
    display: block;
    height: 43px;
    line-height: 43px;
    color: ${theme.palette.label};
    z-index: 3;
    display: block;
    content: ${props => `"${props.imageFile}"`};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
    width: 120px;
  }
`;

const FileInput = styled.input`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 45px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
`;

const Footer = styled.div`
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SwitchToClassicContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  .react-switch-checkbox {
    margin-left: 14px;
  }
`;

const FooterOptions = styled.div`
  display: flex;
`;

export const ModalAutomotiveVideoAnnotations = ({
  videoDetails,
  videoDuration,
  selectedAnnotation,
  handleModalClose,
  setOpenClassicEditor,
  setSelectedAnnotation,
}: any) => {
  const step = annotationSteps.STYLE;
  const { colors } = useCovideoTheme();
  const activeImagePosition = positionsWithIcons.filter(
    position => position.for === annotationTypes.ANNOTATION_IMAGE
  )[0];
  const activePosition = positionsWithIcons.filter(
    position => position.for === annotationTypes.ANNOTATION_TEXT
  )[0];

  const { userData } = useAuth();
  let imageRatio = getImageRatio(selectedAnnotation);
  const isAdmin = userData.access === AccessRole.ADMIN;

  const selectedTemplate = {
    ...initialTemplate,
    ...selectedAnnotation,
    imageRatio,
    useImagePercentage: !!selectedAnnotation.imageWidthPercentage,
  };

  const { mutateAsync: createAnnotation } = useCreateAnnotationMutation();
  const { mutateAsync: editAnnotation } = useEditAnnotationMutation();
  const { mutateAsync: createTemplate } = useCreateTemplateMutation();
  const { mutateAsync: editTemplate } = useEditTemplateMutation();

  const [showTemplateNameModal, setShowTemplateNameModal] =
    React.useState(false);
  const [showTemplateUpdateModal, setShowTemplateUpdateModal] =
    React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<File>();
  const [showSaveBeforeExitModal, setShowSaveBeforeExitModal] =
    React.useState(false);
  const [isToggleOn, setIsToggleOn] = React.useState(
    !!selectedTemplate.customerId
  );

  const [shouldReverseColors, setShouldReverseColors] = React.useState(false);
  const [backgroundColor, setBackgroundColor] = React.useState(
    selectedTemplate.backgroundColor
  );
  const [textColor, setTextColor] = React.useState(selectedTemplate.textColor);
  const [_, setImageDimensionsOnUpload] = React.useState({
    width: 0,
    height: 0,
  });

  const videoRef = React.createRef<HTMLVideoElement>();
  const time = [0, videoDuration];

  const windowWidth = useWindowSize().width;

  const onModalClose = (dirty: boolean = true) => {
    if (dirty && selectedAnnotation.annotationId) {
      return setShowSaveBeforeExitModal(true);
    }

    return handleModalClose();
  };

  const escPress = (key: string) => {
    if (key === 'Escape') {
      onModalClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));
    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  let modalTitle = 'New Annotation';
  if (selectedTemplate.annotationId) {
    modalTitle = 'Edit Annotation';
  }

  React.useEffect(() => {
    if (shouldReverseColors) {
      const bkg = backgroundColor;
      setBackgroundColor(textColor);
      setTextColor(bkg);
      setShouldReverseColors(false);
    }
  }, [shouldReverseColors]);

  const URLFields = ({ option, values, label, setFieldValue }: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormSelect
          label={label || ''}
          showLabel={label ? true : false}
          placeholder='Type'
          name='linkType'
          values={values}
          width='30%'
          info={['', 'https://', 'mailto:', 'tel:']}
          isAutomotive={true}
          onChange={() => {
            if (values.linkType !== 'nourl')
              setFieldValue('isLinkEnabled', true);
            else setFieldValue('isLinkEnabled', false);
          }}
        />
        {option.linkType === 'No URL' && (
          <TextInput
            name='nourl'
            type='text'
            disabled
            showLabel={label ? true : false}
            width='65%'
            isAutomotive={true}
          ></TextInput>
        )}
        {option.linkType === 'Web' && (
          <TextInput
            name='url'
            type='text'
            width='65%'
            placeholder='www.domain.com'
            showLabel={label ? true : false}
            isAutomotive={true}
          />
        )}
        {option.linkType === 'Email' && (
          <TextInput
            name='email'
            type='text'
            width='65%'
            placeholder='test@domain.com'
            showLabel={label ? true : false}
            isAutomotive={true}
          />
        )}
        {option.linkType === 'Phone' && (
          <TextInput
            name='phone'
            type='text'
            width='65%'
            showLabel={label ? true : false}
            isAutomotive={true}
          />
        )}
      </div>
    );
  };
  const handleSwitchEditor = (values: any) => {
    if (!isToggleOn) {
      let textValue = '';
      if (values.text) textValue += values.text;
      if (values.text2) textValue += ' ' + values.text2;
      setSelectedAnnotation({
        ...values,
        text: textValue,
        text2: '',
        useClassicEditor: true,
      });
      setOpenClassicEditor(true);
    }
  };

  const themes = useTheme(); /* SUS-885 change */

  const validateAnnotationType = (values: { type?: string; text?: string }) => {
    const errors: { text?: string } = {};
    if (values.type === 'text') {
      if (!values.text) {
        errors.text = `Annotation text field is required`;
      }
    }
    if (values.type === 'image') {
      return {};
    }
    return errors;
  };

  return (
    <Modal style={{ position: 'fixed' }}>
      <div>
        <Formik
          initialValues={{
            ...selectedTemplate,
          }}
          enableReinitialize={true}
          validate={values => validateAnnotationType(values)}
          validationSchema={annotationsSchema}
          onSubmit={async data => {
            if (showTemplateNameModal) {
              return;
            }
            const isCompanyAnnotation = isAdmin && isToggleOn;
            const annotationData = await prepareAnnotation({
              data: { ...data, isCompanyAnnotation: isAdmin && isToggleOn },
              time,
              selectedImage,
              userData,
            });

            if (annotationData.annotationId) {
              await editAnnotation({
                data: annotationData,
                videoId: videoDetails.id,
                annotationId: annotationData.annotationId,
              });
              setSelectedAnnotation(defaultAnnotation);
              setOpenClassicEditor(false);
            } else {
              await createAnnotation({
                videoId: videoDetails.id,
                data: {
                  ...annotationData,
                  ...(isCompanyAnnotation && {
                    customerId: userData.customerId,
                  }),
                },
              });

              setOpenClassicEditor(false); // reset default editor
            }
            handleModalClose();
          }}
        >
          {({
            submitForm,
            isSubmitting,
            values,
            setFieldValue,
            dirty,
          }: any) => {
            if (!values.position)
              setFieldValue('position', initialTemplate.position);
            const isAnnotationValid =
              (values.type === annotationTypes.ANNOTATION_TEXT &&
                !!values.text) ||
              (values.type === annotationTypes.ANNOTATION_IMAGE &&
                !!values.imageUrl);

            return (
              <Form>
                <Header>
                  <HeaderTitle>{modalTitle}</HeaderTitle>
                  {step === annotationSteps.STYLE &&
                    !selectedTemplate.customerId && (
                      <ButtonPillSwitch
                        defaultValue={determineAnnotationText(values.type)}
                        values={typeValues.map(type => type.text)}
                        onChange={newValue => {
                          const annotationType =
                            determineAnnotationValue(newValue);
                          setFieldValue('type', annotationType);
                          // reset alignment
                          const newPosition = positionsWithIcons.filter(
                            position => position.for === annotationType
                          )[0];
                          setFieldValue('position', newPosition.value);
                          if (annotationType == 'text') setIsToggleOn(false);
                        }}
                      />
                    )}
                  <CloseButtonWrap title={'Close video annotations'}>
                    <IoMdClose
                      size={24}
                      onClick={() => onModalClose(dirty)}
                      color={theme.palette.label}
                    />
                  </CloseButtonWrap>
                </Header>
                <Content>
                  {step === annotationSteps.STYLE && (
                    <>
                      <StyleStep>
                        {values.type === annotationTypes.ANNOTATION_TEXT && (
                          <div style={{ rowGap: '8px', display: 'grid' }}>
                            <TextInput
                              name='text'
                              placeholder='Line 1 Text'
                              showLabel={false}
                              limit={45}
                            ></TextInput>
                            <TextInput
                              name='text2'
                              placeholder='Line 2 Text'
                              showLabel={false}
                              limit={45}
                            ></TextInput>
                            <URLFields
                              values={linkTypes}
                              option={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        )}

                        <FormGroupWrapper>
                          <VideoPlayerWrapper>
                            <VideoPlayer
                              playerBackgroundColor={
                                videoDetails.playerBackgroundColor
                              }
                              videoId={videoDetails.id}
                              videoSource={videoDetails.videoSource}
                              playButtonPosition={
                                videoDetails.playButtonPosition
                              }
                              playerIconsColor={
                                videoDetails.playerIconsAndTextColor
                              }
                              thumbnail={videoDetails.personalThumbnail}
                              videoRef={videoRef}
                              previewAnnotation={{
                                ...values,
                                startTime: time[0],
                                endTime: time[1],
                                annotationId: uuidv4(),
                              }}
                              height='280px'
                              preventWideSize={true}
                            />
                          </VideoPlayerWrapper>
                        </FormGroupWrapper>
                      </StyleStep>

                      <StyleStep>
                        <div>
                          {values.type === annotationTypes.ANNOTATION_TEXT && (
                            <>
                              <FormGroup
                                style={{
                                  alignItems: 'flex-end',
                                  marginTop: '0',
                                }}
                              >
                                <FormColorPicker
                                  label='Text color'
                                  name='textColor'
                                  width='48%'
                                  menuPlacement={
                                    windowWidth <= theme.screenSizes.md
                                      ? 'top'
                                      : 'bottom'
                                  }
                                  defaultColor={textColor}
                                  newValue={textColor}
                                  setNewValue={setTextColor}
                                  manual={true}
                                  isClearable={false}
                                  isAutomotive={true}
                                />
                                <FaArrowRightArrowLeft
                                  onClick={() => setShouldReverseColors(true)}
                                  size={10}
                                  style={{ marginBottom: '12px' }}
                                  color={theme.palette.blue60}
                                />
                                <FormColorPicker
                                  manual={true}
                                  label='Background color'
                                  name='backgroundColor'
                                  width='48%'
                                  menuPlacement={
                                    windowWidth <= theme.screenSizes.md
                                      ? 'top'
                                      : 'bottom'
                                  }
                                  newValue={backgroundColor}
                                  setNewValue={setBackgroundColor}
                                  defaultColor={backgroundColor}
                                  isClearable={false}
                                  isAutomotive={true}
                                />
                              </FormGroup>

                              <FormGroupWrapper>
                                <FormGroup>
                                  <FormSelect
                                    label='Border width'
                                    placeholder='None'
                                    name='borderWidth'
                                    values={borderWidthValues}
                                    width='48%'
                                    menuPlacement={
                                      windowWidth <= theme.screenSizes.md
                                        ? 'top'
                                        : 'bottom'
                                    }
                                    isAutomotive={true}
                                  />
                                  <FormColorPicker
                                    label='Border color'
                                    name='borderColor'
                                    width='48%'
                                    isClearable={false}
                                    defaultColor={initialTemplate.borderColor}
                                    isDisabled={!values.borderColor}
                                    menuPlacement={
                                      windowWidth <= theme.screenSizes.md
                                        ? 'top'
                                        : 'bottom'
                                    }
                                    isAutomotive={true}
                                  />
                                </FormGroup>
                              </FormGroupWrapper>

                              <FormGroupWrapper>
                                <FormGroup>
                                  <div>
                                    <Label isAutomotive={true}>Text Size</Label>
                                    <ButtonPillSwitch
                                      defaultValue={capitalize(
                                        fontSizes.filter(item =>
                                          selectedTemplate.fontSize
                                            ? item.range.includes(
                                                selectedTemplate.fontSize
                                              )
                                            : item.default === true
                                        )[0].name
                                      )}
                                      values={fontSizes.map(item =>
                                        capitalize(item.name)
                                      )}
                                      onChange={val => {
                                        const fontObject = fontSizes.find(
                                          font =>
                                            font.name === val.toLowerCase()
                                        );
                                        if (!fontObject) return;
                                        setFieldValue(
                                          'fontSize',
                                          fontObject.value
                                        );
                                      }}
                                      pillStyle={{ width: '87px' }}
                                      textStyle={{ width: '87px' }}
                                    />
                                  </div>
                                </FormGroup>
                              </FormGroupWrapper>

                              <FormGroupWrapper>
                                <FormGroup
                                  style={{
                                    justifyContent: 'left',
                                    marginTop: '0px',
                                    gap: '5px',
                                  }}
                                >
                                  <FormSelect
                                    label='Text Position'
                                    placeholder=''
                                    isAutomotive={true}
                                    name='position'
                                    mapValues={false}
                                    defaultValue={activePosition}
                                    values={positionsWithIcons
                                      .filter(
                                        position =>
                                          position.for ===
                                          annotationTypes.ANNOTATION_TEXT
                                      )
                                      .map(position => {
                                        return {
                                          value: position.value,
                                          label: position.value,
                                          icon: position.icon,
                                        };
                                      })}
                                    width='48%'
                                    menuPlacement={
                                      windowWidth <= theme.screenSizes.md
                                        ? 'top'
                                        : 'bottom'
                                    }
                                    hasIcons={true}
                                  />
                                </FormGroup>
                              </FormGroupWrapper>
                            </>
                          )}

                          {values.type === annotationTypes.ANNOTATION_IMAGE && (
                            <>
                              <FormGroup
                                style={{
                                  justifyContent: 'left',
                                  marginBottom: '16px',
                                }}
                              >
                                <ImageFileWrapper>
                                  <Label isAutomotive={true}>
                                    <div>Logo Image</div>
                                  </Label>
                                  <ImageFile
                                    imageFile={
                                      values.imageName || 'Choose file...'
                                    }
                                  >
                                    <FileInput
                                      accept='image/x-png,image/gif,image/jpeg'
                                      type='file'
                                      onChange={async (
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (
                                          event.currentTarget &&
                                          event.currentTarget.files &&
                                          event.currentTarget.files.length
                                        ) {
                                          const imageData =
                                            event.currentTarget.files[0];

                                          setSelectedImage(imageData);

                                          setFieldValue(
                                            'imageName',
                                            imageData.name
                                          );

                                          const reader = new FileReader();

                                          reader.onload = function (file: any) {
                                            const image = new Image();
                                            image.src = file.target.result;

                                            image.onload = function () {
                                              // @ts-ignore
                                              const imageWidth = this.width;
                                              // @ts-ignore
                                              const imageHeight = this.height;
                                              const { width, height, ratio } =
                                                calculateImageDimensions(
                                                  imageWidth,
                                                  imageHeight
                                                );
                                              setImageDimensionsOnUpload({
                                                width,
                                                height,
                                              });
                                              setFieldValue(
                                                'imageWidth',
                                                width
                                              );
                                              setFieldValue(
                                                'imageHeight',
                                                height
                                              );
                                              setFieldValue(
                                                'imageRatio',
                                                ratio
                                              );
                                              setFieldValue(
                                                'imageUrl',
                                                reader.result
                                              );
                                            };
                                          };

                                          reader.readAsDataURL(imageData);
                                        }
                                      }}
                                    />
                                    <MdImage
                                      size='20px'
                                      color={theme.palette.blue40}
                                    />
                                  </ImageFile>
                                </ImageFileWrapper>

                                <FormSelect
                                  label='Logo Position'
                                  placeholder={''}
                                  name='position'
                                  isAutomotive={true}
                                  mapValues={false}
                                  values={positionsWithIcons
                                    .filter(
                                      position =>
                                        position.for ===
                                        annotationTypes.ANNOTATION_IMAGE
                                    )
                                    .map(position => {
                                      return {
                                        value: position.value,
                                        label: position.value,
                                        icon: position.icon,
                                      };
                                    })}
                                  width='48%'
                                  menuPlacement='bottom'
                                  hasIcons={true}
                                  defaultValue={activeImagePosition}
                                />
                              </FormGroup>

                              <FormGroupWrapper>
                                <Label style={{ alignItems: 'center' }}>
                                  {/* SUS-885 change */}
                                  <Switch
                                    id={'switch-reactions'}
                                    isOn={values.useImagePercentage}
                                    onColor={themes.colors.primary[100]}
                                    handleToggle={() => {
                                      const useImagePercentage =
                                        !values.useImagePercentage;

                                      setFieldValue(
                                        'useImagePercentage',
                                        useImagePercentage
                                      );
                                      if (useImagePercentage) {
                                        setFieldValue(
                                          'imageWidthPercentage',
                                          selectedAnnotation.imageWidthPercentage ||
                                            '50%'
                                        );
                                        setFieldValue(
                                          'imageHeightPercentage',
                                          selectedAnnotation.imageHeightPercentage ||
                                            'auto'
                                        );
                                      } else {
                                        setFieldValue(
                                          'imageWidthPercentage',
                                          ''
                                        );
                                        setFieldValue(
                                          'imageHeightPercentage',
                                          ''
                                        );
                                      }
                                    }}
                                  />
                                  <span style={{ marginLeft: '12px' }}>
                                    Use width percentage
                                  </span>
                                </Label>
                              </FormGroupWrapper>
                              <FormGroup style={{ marginBottom: '16px' }}>
                                {values.useImagePercentage && (
                                  <FormSelect
                                    label='Image width percentage'
                                    placeholder='Image percentage'
                                    name='imageWidthPercentage'
                                    values={opacityValues}
                                    width='100%'
                                    isAutomotive={true}
                                    onChange={(percentage: any) => {
                                      calculatePercentageOnChange(
                                        percentage,
                                        values.imageRatio,
                                        setFieldValue
                                      );
                                    }}
                                  />
                                )}
                                {!values.useImagePercentage && (
                                  <>
                                    <TextInput
                                      label='Width'
                                      name='imageWidth'
                                      type='number'
                                      width='45%'
                                      isAutomotive={true}
                                      onKeyUp={() =>
                                        calculateDimensionsOnChange(
                                          values.imageWidth,
                                          0,
                                          values.imageRatio,
                                          setFieldValue
                                        )
                                      }
                                      onMouseUp={() =>
                                        calculateDimensionsOnChange(
                                          values.imageWidth,
                                          0,
                                          values.imageRatio,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <IoMdLink
                                      color={theme.palette.black_1_100}
                                      style={{
                                        alignSelf: 'flex-end',
                                        marginBottom: '8px',
                                      }}
                                    />
                                    <TextInput
                                      label='Height'
                                      name='imageHeight'
                                      type='number'
                                      width='45%'
                                      isAutomotive={true}
                                      onKeyUp={() =>
                                        calculateDimensionsOnChange(
                                          0,
                                          values.imageHeight,
                                          values.imageRatio,
                                          setFieldValue
                                        )
                                      }
                                      onMouseUp={() =>
                                        calculateDimensionsOnChange(
                                          0,
                                          values.imageHeight,
                                          values.imageRatio,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </FormGroup>

                              <URLFields
                                values={linkTypes}
                                option={values}
                                label={'Logo URL'}
                                setFieldValue={setFieldValue}
                              />
                              <FormGroup>
                                <div>
                                  <Label isAutomotive={true}>
                                    Logo Opacity
                                  </Label>
                                  <OpacitySlider
                                    domain={[0, 100]}
                                    initialValue={
                                      selectedTemplate?.imageOpacity * 100 || 50
                                    }
                                    onUpdate={val =>
                                      setFieldValue(
                                        'imageOpacity',
                                        (val[0] / 100).toString()
                                      )
                                    }
                                  ></OpacitySlider>
                                </div>
                              </FormGroup>
                            </>
                          )}
                        </div>
                      </StyleStep>
                    </>
                  )}
                </Content>
                {step === annotationSteps.STYLE && (
                  <Footer>
                    <SwitchToClassicContainer
                      style={{
                        ...(isToggleOn && {
                          color: theme.palette.gray40,
                          cursor: 'initial',
                        }),
                      }}
                      onClick={() => {
                        handleSwitchEditor(values);
                      }}
                    >
                      <MdOutlineAutorenew
                        style={{ marginRight: '5px' }}
                        color={
                          isToggleOn
                            ? theme.palette.gray40
                            : colors.primary[100]
                        }
                      />
                      Switch to classic editor
                    </SwitchToClassicContainer>
                    <FooterOptions>
                      {values.type === annotationTypes.ANNOTATION_IMAGE &&
                        isAdmin && (
                          <ToggleContainer>
                            Apply for all users within company
                            <Switch
                              id={'switch-only-my-c'}
                              isOn={isToggleOn}
                              onColor={colors.primary[100]}
                              handleToggle={() => {
                                setIsToggleOn(!isToggleOn);
                              }}
                              disabled={!!selectedTemplate.annotationId}
                            />
                          </ToggleContainer>
                        )}

                      <Button
                        text={
                          selectedTemplate.annotationId
                            ? 'Save Changes'
                            : 'Add Annotation'
                        }
                        disabled={isSubmitting || !isAnnotationValid}
                        type='submit'
                      />
                    </FooterOptions>
                  </Footer>
                )}

                {showTemplateNameModal && (
                  <ModalTemplateName
                    onSubmit={async title => {
                      title = title.trim();
                      if (!title) {
                        return;
                      }
                      const annotationData = await prepareAnnotation({
                        data: { ...values, title },
                        time,
                        selectedImage,
                        userData,
                      });
                      createTemplate(annotationData);
                      handleModalClose();
                      submitForm();
                    }}
                    handleModalClose={() => setShowTemplateNameModal(false)}
                  />
                )}
                {showTemplateUpdateModal && (
                  <ModalDelete
                    whiteButtonText='No, Cancel'
                    orangeButtonText='Yes, Update'
                    title='Update Existing Template?'
                    text1={
                      <p>
                        You’re going to update Annotation Template{' '}
                        <strong>‘{values.title}’</strong>.
                      </p>
                    }
                    text={`This action can’t be undone.`}
                    handleModalClose={() => setShowTemplateUpdateModal(false)}
                    onClickWhiteButton={() => setShowTemplateUpdateModal(false)}
                    onClickOrangeButton={async () => {
                      const annotationData = await prepareAnnotation({
                        data: values,
                        time,
                        selectedImage,
                        userData,
                      });
                      editTemplate({
                        ...annotationData,
                        templateId: values.templateId,
                      });
                      setShowTemplateUpdateModal(false);
                      handleModalClose();
                    }}
                  />
                )}

                {showSaveBeforeExitModal && (
                  <ModalDelete
                    whiteButtonText='Leave Without Saving'
                    orangeButtonText='Yes, Save'
                    title='Save before exit?'
                    text={`You have unsaved changes.`}
                    handleModalClose={() => setShowSaveBeforeExitModal(false)}
                    onClickWhiteButton={() => {
                      setShowSaveBeforeExitModal(false);
                      handleModalClose();
                    }}
                    onClickOrangeButton={async () => {
                      const annotationData = await prepareAnnotation({
                        data: values,
                        time,
                        userData,
                        selectedImage,
                      });

                      await editAnnotation({
                        data: annotationData,
                        videoId: videoDetails.id,
                        annotationId: annotationData.annotationId,
                      });

                      setOpenClassicEditor(false);
                      setSelectedAnnotation(defaultAnnotation);
                      setShowSaveBeforeExitModal(false);
                      handleModalClose();
                    }}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
