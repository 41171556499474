import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from 'lib/context';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { UserForm } from './UserForm';
import { NAPLETON_AUTOMOTIVE_RESELLER_ID } from 'lib/const/SuperAdminConstants';
import {
  generatePrettyPassword,
  hasEmail2FieldAccess,
} from 'lib/utils/functions';
import { IFetchUsersProfileResponse } from 'lib/api/users/useUsersProfileQuery';
import { ACCESS_TYPE } from './utils';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import { AutomotiveRole } from 'lib/const';
import { ManageUsersHeader } from './MangeUsersHeader';
import { ManagedDepartment } from 'lib/api/departments/types';
import {
  GetCtaSetResponse,
  GetLandingPagesResponse,
  GetWebsiteOverlaysResponse,
} from 'lib/api';
import { useCreateUserMutation } from 'lib/api/users/useCreateUserMutation';
import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
import { useEditUserMutation } from 'lib/api/users/useEditUserMutation';
import { LoadingIndicator } from 'lib/components';
import { useIsFetching } from 'react-query';
import { usersKeys } from 'lib/api/users/queryKeys';

const usernameRegex = /^[a-zA-Z0-9@._+-]+$/;
const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
const phoneMinLength = 7;

const validationSchema = Yup.object({
  firstName: Yup.string()
    .required('First name is required')
    .max(100, 'First name cannot be more than 100 characters.'),
  lastName: Yup.string().when(['resellerId'], (resellerId: number) => {
    if (resellerId === NAPLETON_AUTOMOTIVE_RESELLER_ID) {
      return Yup.string()
        .nullable()
        .notRequired()
        .min(2, 'Last name is too small')
        .max(100, 'Last name cannot be more than 100 characters.');
    }
    return Yup.string()
      .min(2, 'Last name is too small')
      .max(100, 'Last name cannot be more than 100 characters.')
      .required('Last name is required');
  }),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  email2: Yup.string().nullable().notRequired().email('Invalid email format'),
  username: Yup.string()
    .matches(
      usernameRegex,
      'Username can only contain letters, numbers, and special characters (@-_+.) without spaces'
    )
    .required('Username is required'),
  phone1: Yup.string()
    .nullable()
    .notRequired()
    .test(
      'is-valid-phone',
      `Phone number must be at least ${phoneMinLength} characters`,
      value => {
        if (value) {
          return value.length >= phoneMinLength;
        }
        return true; // Skip validation if  empty
      }
    ),

  phone2: Yup.string()
    .nullable()
    .notRequired()
    .test(
      'is-valid-phone',
      `Phone number must be at least ${phoneMinLength} characters`,
      value => {
        if (value) {
          return value.length >= phoneMinLength;
        }
        return true; // Skip validation if empty
      }
    ),
  cellPhone: Yup.string()
    .nullable()
    .notRequired()
    .test(
      'is-valid-cell-phone',
      `Cell phone number must be at least ${phoneMinLength} characters`,
      value => {
        if (value) {
          return value.length >= phoneMinLength;
        }
        return true; // Skip validation is empty
      }
    ),
  password: Yup.string().when('isEditing', {
    is: false,
    then: Yup.string()
      .required('Password is required')
      .matches(
        passwordRegex,
        'Password must be at least 8 characters long, contain one uppercase letter, one number, and one special character (!@#$%^&*)'
      ),
    otherwise: Yup.string()
      .nullable()
      .notRequired()
      .test(
        'is-valid-password',
        'Password must be at least 8 characters long, contain one uppercase letter, one number, and one special character (!@#$%^&*)',
        value => {
          if (value) {
            return passwordRegex.test(value);
          }
          return true;
        }
      ),
  }),
});

export default validationSchema;

export interface UserFormikValues {
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  cellPhone: string;
  access: number;
  automotiveRole?: string | null;
  vdpEnabled: boolean;
  active: number;
  title: string;
  username: string;
  email2?: string;
  departmentId: number | null;
  phone2: string;
  password: string;
  manualPassword: number;
  designId: number | null;
  ctaSetId: number | null;
  overlayUrlId: number | null;
  nmls: string;
  isEditing: boolean;
}

export const UserInfoTab = ({
  currentUser,
  children,
  departments,
  templates,
  websiteOverlays,
  linksetsData,
  isExceeded,
}: {
  currentUser: IFetchUsersProfileResponse | undefined;
  children: React.ReactChild;
  departments?: ManagedDepartment[];
  templates?: GetLandingPagesResponse;
  websiteOverlays?: GetWebsiteOverlaysResponse;
  linksetsData?: GetCtaSetResponse;
  isExceeded: boolean;
}) => {
  const { id } = useParams() as { id: string | undefined };

  const {
    invalidateUser,
    userData: { customerId, resellerId, isAutomotive, userId },
  } = useAuth();
  const hasEmail2Field = hasEmail2FieldAccess(
    resellerId?.toString(),
    customerId?.toString()
  );

  const isFetchingUsers = useIsFetching({
    queryKey: usersKeys.user_profile(id || ''),
  });

  const { mutateAsync: createUserMutation, isLoading: isCreatingUser } =
    useCreateUserMutation();
  const { mutateAsync: editUserMutation, isLoading: isEditingUser } =
    useEditUserMutation();

  const history = useHistory();

  const [apiError, setApiError] = useState<string | null>(null);

  const validate = async (values: {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    email2?: string | null;
  }) => {
    const errors: Record<string, string> = {};

    try {
      // Validate the entire object, you can pass the whole `values` object here
      await validationSchema.validate(values, { abortEarly: false });
    } catch (yupError: any) {
      yupError.inner.forEach((err: any) => {
        errors[err.path] = err.message;
      });
    }

    // Run API validation (e.g., for username)
    if (apiError) {
      errors.username = apiError; // Include API error in Formik's errors
    }

    return errors;
  };

  const departmentsOptions =
    departments?.map(dept => ({
      label: dept.name,
      value: dept.departmentId,
    })) || [];

  const landingPageOptions =
    templates?.templates.map(template => ({
      value: template.id,
      label: template.title,
    })) || [];

  const websiteOverlaysOptions =
    websiteOverlays?.websiteUrls?.map(template => ({
      value: template.id,
      label: template.title,
    })) || [];

  const ctaSetOptions =
    linksetsData?.linksets
      .filter(cta => cta.company !== 0)
      ?.map(cta => {
        return { value: cta.id, label: cta.title };
      }) || [];

  const defaultCtaSetId =
    linksetsData?.linksets
      .filter(cta => cta.company !== 0)
      ?.find(cta => cta.defaultLinks === 1)?.id || null;

  const onSubmitFormHandler = async (values: UserFormikValues) => {
    const {
      isEditing,
      password,
      email2,
      departmentId,
      overlayUrlId,
      ctaSetId,
      designId,
      automotiveRole,
      ...rest
    } = values;

    const commonData = {
      ...rest,
      ...(isAutomotive && { automotiveRole }),
      ...(departmentId && { departmentIds: [`${departmentId}`] }),
      ...(hasEmail2Field && { email2 }),
    };

    if (isEditing && id) {
      await editUserMutation({
        data: {
          ...rest,
          // sending password only if exist
          ...(password ? { newPassword: password } : {}),
          ...commonData,
        },
        changeOrigin: CHANGE_ORIGIN.manageUsers,
        userId: id,
      });

      if (id === userId) {
        invalidateUser();
      }
      return;
    }

    await createUserMutation({
      data: {
        ...rest,
        ...commonData,
        // password should exist
        password,
        // sending this only on creating
        overlayUrlId,
        ctaSetId,
        designId,
        jobTitle: values.title,
      },
      changeOrigin: CHANGE_ORIGIN.manageUsers,
    });
  };

  if (!!isCreatingUser || !!isEditingUser || !!isFetchingUsers) {
    return (
      <div>
        <LoadingIndicator isLoading={true} />
      </div>
    );
  }

  const INITIAL_VALUES: UserFormikValues = {
    manualPassword: 0,
    designId: templates?.default?.id || null,
    overlayUrlId: websiteOverlays?.default?.id || null,
    ctaSetId: defaultCtaSetId,
    firstName: id ? currentUser?.firstName ?? '' : '',
    lastName: id ? currentUser?.lastName ?? '' : '',
    email: id ? currentUser?.email ?? '' : '',
    phone1: id ? currentUser?.phone1 ?? '' : '',
    cellPhone: id ? currentUser?.cellPhone ?? '' : '',
    access: id
      ? currentUser?.access ?? ACCESS_TYPE.STANDARD
      : isExceeded
        ? ACCESS_TYPE.SUPERVISOR
        : ACCESS_TYPE.STANDARD,

    ...(isAutomotive
      ? {
          automotiveRole: id
            ? currentUser?.automotiveRole ?? null
            : AutomotiveRole.SALESPERSON,
        }
      : {}),

    vdpEnabled: id ? !!currentUser?.vdpEnabled ?? false : false,
    // when creating active is always 1 check number of active users api
    active: id ? currentUser?.verified ?? 1 : 1,
    title: id ? currentUser?.title ?? '' : '',
    username: id ? currentUser?.username ?? '' : '',

    //check can we exclude this field on load if not exist
    ...(hasEmail2Field ? { email2: id ? currentUser?.email2 ?? '' : '' } : {}),

    departmentId: id ? currentUser?.Dept ?? null : null,
    phone2: id ? currentUser?.phone2 ?? '' : '',
    nmls: id ? currentUser?.nmls ?? '' : '',

    // Password is required only when creating a new user. In edit mode, it's optional unless modified.
    password: id ? '' : generatePrettyPassword(),
    isEditing: !!id,
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmitFormHandler}
      validate={validate}
      validateOnMount
    >
      {({ handleSubmit, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <ManageUsersHeader
            currentUser={currentUser}
            isExceeded={isExceeded}
          />
          {children}

          <UserForm
            departmentsOptions={departmentsOptions}
            landingPageOptions={landingPageOptions}
            websiteOverlaysOptions={websiteOverlaysOptions}
            ctaSetOptions={ctaSetOptions}
            setApiError={setApiError}
            hasEmail2Field={hasEmail2Field}
            isExceeded={isExceeded}
          />
          <RouteLeavingGuard
            when={dirty}
            stay={true}
            navigate={path => history.push(path)}
            shouldBlockNavigation={() => {
              return true;
            }}
            title='Leave without saving changes?'
            text='Your updates will not be saved. This action can’t be undone.'
            confirmButtonText='Continue'
            discardButtonText='Leave'
          />
        </Form>
      )}
    </Formik>
  );
};
