import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { CheckboxInput } from 'lib/components';
import {
  Gap,
  MAIN_WRAPPER_MAX_WIDTH,
  MAIN_WRAPPER_MAX_WIDTH_LG,
  MAIN_WRAPPER_MAX_WIDTH_XL,
} from 'lib/components/styles/layout';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { Button } from 'react-covideo-common';
import {
  MdDeleteForever,
  MdFileDownload,
  MdFolder,
  MdGroupRemove,
} from 'react-icons/md';

const SelectionContainer = styled.div`
  display: none;
  position: fixed;
  left: 0px;
  right: 0;
  top: 65px;
  background: white;
  height: 40px;
  z-index: 10;
  justify-content: 'space-between';
  align-items: center;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px #f7f8fa;
  ${theme.mediaQueryMinWidth.sm} {
    display: flex;
  }

  padding: 12px 0 12px 208px;
`;

const LayoutOption = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 12px;
  max-width: ${MAIN_WRAPPER_MAX_WIDTH};

  @media (min-width: 1280px) {
    max-width: ${MAIN_WRAPPER_MAX_WIDTH_LG};
  }
  @media (min-width: 1640px) {
    max-width: ${MAIN_WRAPPER_MAX_WIDTH_XL};
  }
`;

interface FixedHeaderProps {
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  groupsArray: number[];
  onDeleteGroupHandler?: () => void;
  handleExportBatchClick?: () => void;
  addToGroupHandler?: () => void;
  removeFromGroupHandler?: () => void;
  label: string;
}

export const FixedCounterHeader: React.FC<FixedHeaderProps> = ({
  selectedIds,
  groupsArray,
  setSelectedIds,
  onDeleteGroupHandler,
  handleExportBatchClick,
  addToGroupHandler,
  removeFromGroupHandler,
  label,
}) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const { checked } = event.target;
    if (checked) {
      setSelectedIds(groupsArray.map(lead => lead));
    } else {
      setSelectedIds([]);
    }
  };

  return (
    <>
      {!!selectedIds.length && (
        <SelectionContainer>
          <LayoutOption>
            <Gap gap='48px' width='100%' justifyContent='space-between'>
              <Gap>
                <CheckboxInput
                  blueCheck={true}
                  checkGroupIndicator={
                    selectedIds.length > 0 &&
                    groupsArray.length !== selectedIds.length
                  }
                  checked={groupsArray.length === selectedIds.length}
                  ignoreGrayForAllSelect={true}
                  grayCheck={true}
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                  }}
                  onChange={handleCheckboxChange}
                />
                <Gap gap='4px'>
                  <ParagraphSmall>{label}</ParagraphSmall>
                  <b>{selectedIds.length}</b>
                </Gap>
              </Gap>
              <Gap>
                {onDeleteGroupHandler && (
                  <Button
                    variant='destructive'
                    icon={<MdDeleteForever size={20} />}
                    onClick={onDeleteGroupHandler}
                    text='Delete'
                  />
                )}

                {addToGroupHandler && (
                  <Button
                    onClick={addToGroupHandler}
                    text='Add to group'
                    icon={<MdFolder size={20} />}
                  />
                )}
                {handleExportBatchClick && (
                  <Button
                    onClick={handleExportBatchClick}
                    text='Export'
                    variant='secondary'
                    icon={<MdFileDownload size={20} />}
                  />
                )}
                {removeFromGroupHandler && (
                  <Button
                    onClick={removeFromGroupHandler}
                    variant='destructive'
                    text='Remove from group'
                    icon={<MdGroupRemove size={20} />}
                  />
                )}
              </Gap>
            </Gap>
          </LayoutOption>
        </SelectionContainer>
      )}
    </>
  );
};
