import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useHistory, withRouter } from 'react-router-dom';

import { theme } from 'lib/style';
import { ICombinedUserData } from 'lib/context';
import { useAuth } from '../../../lib/context';
import { FILE_EXTENSION, CONTENT_TYPE } from 'lib/hooks/useMediaRecorder';
import { putVideoTags } from 'lib/api/videoTagsApi';
import { VideoPages } from 'lib/const';
import { checkIfFreemiumAndInvalidateForCheckList } from 'lib/utils/functions';
import { useQueryClient } from 'react-query';
import { useCreateVideoMutation } from 'lib/api/videos/useCreateVideoMutation';
import { useAwsSignedUrlQuery } from 'lib/api/aws/useAwsSignedUrlQuery';
import {
  DEFAULT_UPLOAD_ID,
  DEFAULT_UPLOAD_PROGRESS,
  useS3UploadMutation,
} from 'lib/api/aws/useS3UploadMutation';
import { useEncodeVideoMutation } from 'lib/api/videos/useEncodeVideoMutation';
import { useCreateVideoRequestMutation } from 'lib/api/videoRequests/useCreateVideoRequestMutation';
import { putVideoAttributes } from 'lib/api/videoAttributes/useEditVideoAttributeByVideoId';
import _ from 'lodash';
import { ProgressCircle } from '../recordv1/ProgressCircle';

export const FlexCenter = styled.div`
  margin-top: 16px;
  background-color: #f6f7f9;
  border-radius: 4px;
  padding: 32px;
  height: 168px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  color: ${theme.palette.blackRgb75};
  margin: 0;
  width: 100%;
  margin-bottom: 32px;
  min-height: 190px;
  position: relative;
`;
type ProgressBarProps = {
  color: string;
};
const ProgressBar = styled.progress<ProgressBarProps>`
  z-index: 1;
  margin-top: -14.5px;
  width: 100%;
  background-color: ${theme.palette.lightgray};
  border: none;
  border-radius: 15px;
  height: 4px;
  &::-webkit-progress-bar {
    margin: 0 auto;
    background-color: ${theme.palette.lightgray};
    border-radius: 15px;
    height: 4px;
  }
  &::-moz-progress-bar {
    margin: 0 auto;
    background-color: ${({ color }) => color};
    border-radius: 15px;
    height: 4px;
  }
  &::-webkit-progress-value {
    float: left;
    height: 4px;
    margin: 0px -10px 0 0;
    background: ${({ color }) => color};
    border-radius: 15px;
  }
`;
const ProgressText = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #4e5461;
  margin-top: 32px;
`;

enum VIEW {
  UPLOADING = 1,
  CREATE = 2,
  ENCODE = 3,
}
const generateName = (fileExt: string, userData: ICombinedUserData) => {
  const randomSuffix = (Math.random() + 1).toString(36).substring(2);
  const fileName = `${userData.userId}_${userData.customerId}_${randomSuffix}.${fileExt}`;
  return fileName;
};

const toMBString = (loaded: number, total: number) => {
  const loadedMB = (loaded / 1000).toFixed(2);
  const totalMB = (total / 1000).toFixed(2);
  return `${loadedMB} MB/${totalMB} MB`;
};

export const Upload = withRouter((props: any) => {
  const {
    file,
    fileName,
    videoTags,
    videoAttributesValues,
    videoRequestData,
    continueToSendShare = false,
    isFreemium = false,
    selectedFolder = '',
    selectedFolderId = -2,
  } = props;

  const [progressState, setProgress] = useState(VIEW.UPLOADING);
  const history = useHistory();
  const queryClient = useQueryClient();

  const { userData } = useAuth();
  const themes = useTheme();

  if (!file.name) {
    file.name = generateName(FILE_EXTENSION, userData);
  }

  const isAwsSignedUrlEnabled = !!fileName;
  const {
    data: presignedUrl,
    isLoading: isLoadingUrl,
    error: urlError,
  } = useAwsSignedUrlQuery(
    {
      fileName: file?.name || '',
      type: file?.type || '',
    },
    isAwsSignedUrlEnabled
  );

  const {
    mutateAsync: uploadVideoToS3,
    isLoading: isUploading,
    data: uploadData,
    error: uploadError,
    progress: uploadToS3Progress,
  } = useS3UploadMutation();

  const uploadProgress =
    uploadToS3Progress[DEFAULT_UPLOAD_ID] || DEFAULT_UPLOAD_PROGRESS;

  const {
    mutateAsync: createVideo,
    isLoading: isCreating,
    error: createError,
    progress: createProgress,
    data: videoData,
  } = useCreateVideoMutation();
  const {
    mutateAsync: encodeVideo,
    data: encodeData,
    progress: encodeProgress,
  } = useEncodeVideoMutation();
  const { mutateAsync: createVideoRequestMutation } =
    useCreateVideoRequestMutation();

  React.useEffect(() => {
    if (!presignedUrl) {
      return;
    }
    if (!isLoadingUrl && !urlError && file) {
      setProgress(VIEW.UPLOADING);
      uploadVideoToS3({
        url: presignedUrl.url,
        file,
        contentType: CONTENT_TYPE,
      });
    }
  }, [presignedUrl]);

  React.useEffect(() => {
    if (!isUploading && !uploadError && uploadData && file) {
      setProgress(VIEW.CREATE);
      createVideo({
        filename: file.name,
        title: fileName,
        folder: selectedFolder,
        isFreemium,
      });
    }
  }, [isUploading, uploadData]);

  React.useEffect(() => {
    if (!isCreating && !createError && videoData && file) {
      setProgress(VIEW.ENCODE);
      encodeVideo({
        videoId: videoData.id,
        customerId: userData.customerId,
      });
    }
  }, [isCreating, videoData]);

  React.useEffect(() => {
    if (encodeData && videoData) {
      Promise.all([
        updateVideoTags(),
        updateVideoAttributes(),
        addVideoRequest(),
      ]).then(result => {
        const videoRequestId = result[2];
        // invalidate onboarding query
        checkIfFreemiumAndInvalidateForCheckList(userData, queryClient);
        if (continueToSendShare && videoRequestId) {
          history.push(
            `/home/${VideoPages.SEND_AND_SHARE}/${videoData.id}/${
              isFreemium ? '?freemium=true' : ''
            }`
          );
          return;
        }
        history.push(`/home?folderId=${selectedFolderId}&page=0`);
      });
    }
  }, [isCreating, encodeData]);

  const updateVideoTags = async () => {
    if (!videoData) {
      return;
    }
    if (!videoTags || !videoTags.length) {
      return;
    }
    await putVideoTags(videoData.id, videoTags).catch(() => {});
  };

  const addVideoRequest = async () => {
    if (videoData) {
      if (!userData.isAutomotive) return 0;
      const videoRequest = {
        videoId: parseInt(videoData.id),
        ...videoRequestData,
      };
      const result = await createVideoRequestMutation({ data: videoRequest });
      return result.videoRequestId;
    }
  };

  const updateVideoAttributes = async () => {
    if (
      !videoData ||
      !videoAttributesValues ||
      _.isEmpty(videoAttributesValues)
    ) {
      return;
    }

    let attributesToKeep = videoAttributesValues;

    // remove values that are not video attributes
    if (!!attributesToKeep['covideoName'])
      delete attributesToKeep['covideoName'];
    if (!!attributesToKeep['folder']) delete attributesToKeep['folder'];

    const attributes = Object.keys(attributesToKeep).map((key: string) => {
      const splitValues = key.split('-');
      const attributeId = splitValues[1];
      return {
        attributeId,
        value: attributesToKeep[key],
        videoId: parseInt(videoData.id),
      };
    });

    await putVideoAttributes({
      videoId: videoData.id,
      data: attributes,
    }).catch(err => err);
  };

  const getPercentage = () => {
    if (!progressState) {
      return 0;
    }
    if (progressState == VIEW.UPLOADING) {
      return (uploadProgress.percentage * 50) / 100;
    }
    if (progressState == VIEW.CREATE) {
      return 50 + (createProgress.percentage || 0);
    }
    return 100;
  };

  return (
    <ContentBody>
      <FlexCenter>
        <ProgressContainer>
          <ProgressCircle progress={uploadProgress} progressType='upload' />
          <ProgressCircle progress={createProgress} progressType='create' />
          <ProgressCircle progress={encodeProgress} progressType='encode' />
        </ProgressContainer>
        <ProgressBar
          value={getPercentage()}
          max={100}
          color={themes.colors.primary[100]}
        />
        <ProgressText>
          {toMBString(uploadProgress.loaded, uploadProgress.total)}
        </ProgressText>
      </FlexCenter>
    </ContentBody>
  );
});
