import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

interface IUpdateLeadParams {
  leads: number[];
  leadGroupId: number;
}

const deleteLeadsFromGroup = async (params: IUpdateLeadParams) => {
  const { leadGroupId, leads } = params;
  return await EXPRESS_API.delete(`/lead-groups/${leadGroupId}/lead`, {
    data: leads,
  });
};

export const useDeleteLeadsFromGroupMutation = () => {
  const { showError } = useToastError();
  return useMutation(deleteLeadsFromGroup, {
    onSuccess: (_, variables) => {
      const groupCount = variables.leads.length;
      const message =
        groupCount === 1
          ? 'Customer has been deleted!'
          : `${groupCount} customers have been deleted!`;
      successToast({ title: message });
    },
    onError: error =>
      showError(error, 'An error occured while trying to remove the customer!'),
  });
};
