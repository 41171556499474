import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { NewModal, TextInput } from 'lib/components';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';
import { InformationBox } from 'lib/components/InformationBox';
import { Gap, Stack } from 'lib/components/styles/layout';
import { UpdateUsersFastPasswordRequest } from 'lib/context';
import { generatePrettyPassword } from 'lib/utils/functions';
import { MdAutorenew } from 'react-icons/md';
import { CustomPasswordErrorMessage } from 'app/pages/admin/components/PasswordTooltip';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { PASSWORD_REGEX } from 'lib/utils/regexes';
import { PASSWORD_FORMAT_ERROR } from 'app/pages/account/userAccount/editMyProfile/components';
import { useUpdateUsersFastPasswordsMutation } from 'lib/api';
import { useToastError } from 'lib/hooks';

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const passwordValidationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, PASSWORD_FORMAT_ERROR)
    .max(50, PASSWORD_FORMAT_ERROR)
    .matches(PASSWORD_REGEX, PASSWORD_FORMAT_ERROR)
    .required(PASSWORD_FORMAT_ERROR),
});

type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  selectedUserIds: number[];
};

export const FastPasswordResetModal = ({
  handleModalClose,
  selectedUserIds,
}: Props) => {
  const { showError } = useToastError();

  const [passwordReseted, setPasswordReseted] = useState(false);

  const { mutateAsync: updateUsersFastPasswords } =
    useUpdateUsersFastPasswordsMutation();

  const initialValues: UpdateUsersFastPasswordRequest = {
    newPassword: generatePrettyPassword(),
    userIds: selectedUserIds,
  };

  const handleSubmit = async (values: UpdateUsersFastPasswordRequest) => {
    try {
      await updateUsersFastPasswords(values);
      successToast({
        title: `Password has been reset for ${
          selectedUserIds.length
        } user${selectedUserIds.length > 1 ? 's' : ''}!`,
      });
      setPasswordReseted(true);
    } catch (error) {
      showError(error);
    }
  };

  const handleCopyPassword = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      successToast({
        title: `Password ${text} is successfully copied into the Clipboard.`,
      });
    } catch (error) {
      showError(error);
    }
  };

  const newPasswordResetInfo = `User${selectedUserIds.length > 1 ? 's' : ''} will be required to immediately reset the password on login. Password is valid for 12 hours.`;
  const headerText = `Fast Reset Password${selectedUserIds.length > 1 ? ` for ${selectedUserIds.length} users` : ''}`;

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Content>
        <Formik
          initialValues={initialValues}
          validationSchema={passwordValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, isValid, isSubmitting, values }) => (
            <Form>
              <Stack gap='24px' style={{ minHeight: 350 }}>
                <InformationBox text={newPasswordResetInfo} width='100%' />
                <Stack gap='2px' width='100%' alignItems='flex-start'>
                  <Gap width='100%' flexWrap='nowrap'>
                    <Field
                      as={TextInput}
                      name='newPassword'
                      autoFocus={true}
                      width='100%'
                      disabled={passwordReseted}
                    />
                    <Button
                      icon={<MdAutorenew size={18} />}
                      variant='secondary'
                      onClick={() =>
                        setFieldValue('newPassword', generatePrettyPassword())
                      }
                      title='Click to generate a new password'
                      disabled={passwordReseted}
                    />
                  </Gap>
                  <div>
                    <CustomPasswordErrorMessage name='newPassword' />
                  </div>
                </Stack>
                {passwordReseted && (
                  <InformationBox
                    text='Password Reset successfully!'
                    variant='success'
                    width='100%'
                  />
                )}
                <Gap justifyContent='flex-end' width='100%' m='auto 0 0'>
                  <Button
                    text={'Reset & Send Email'}
                    onClick={() =>
                      handleSubmit({
                        ...values,
                        sendEmail: true,
                      })
                    }
                    disabled={!isValid || isSubmitting || passwordReseted}
                  />
                  <Button
                    text={'Copy Password'}
                    disabled={!passwordReseted}
                    onClick={() => handleCopyPassword(values.newPassword)}
                    variant='secondary'
                  />
                  <Button
                    text={'Reset Password'}
                    type='submit'
                    disabled={!isValid || isSubmitting || passwordReseted}
                  />
                </Gap>
              </Stack>
            </Form>
          )}
        </Formik>
      </Content>
    </NewModal>
  );
};
