import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

interface CreateCustomerPaymentType {
  cardNumber: string;
  expMonth: string;
  expYear: string;
  cvc: string | number;
  billingAddress?: string;
  billingCity?: string;
  billingState?: string;
  billingCountry: string;
  billingPostalCode: string;
  billingEmail: string;
  fullName: string;
  addons: string[];
  interval: string;
  quantity: number;
  coupon?: string;
  isTrial?: boolean;
  packageId?: number;
}

export const createCustomerPayment = async (
  data: CreateCustomerPaymentType
) => {
  const response = await EXPRESS_API.post(
    '/register/payment/subscriptions',
    data
  );
  return response.data;
};

export const useCreateCustomerPaymentMutation = () => {
  const { showError } = useToastError();

  return useMutation(createCustomerPayment, {
    onSuccess: () => {
      successToast({
        title: `Subscription updated successfully.`,
      });
      window.location.href = '/home';
    },
    onError: (err: AxiosError) => showError(err),
  });
};
