import { FormColorPicker } from 'app/pages/video/videoDetails/main/videoAnnotations/components/FormColorPicker';
import { Field, FieldProps, useFormikContext } from 'formik';
import {
  CONTACT_ALIGNMENT,
  CONTACT_ORIENTATION,
} from 'lib/api/automotiveLanding/useAutomotiveLandingQuery';
import { CheckboxInput, RadioInput } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';

import React from 'react';
import { useCovideoTheme } from 'react-covideo-common';
import {
  CheckboxLabel,
  CheckboxWrapper,
  Label,
  Section,
  SectionTitle,
} from '../index.styled';
import { SalesPagesValues } from './types';
import { UploadImage } from './UploadImage';

export const SalesPagesHeaderForm = () => {
  const themes = useCovideoTheme();
  const { initialValues, setFieldValue, values } =
    useFormikContext<SalesPagesValues>();

  return (
    <Section>
      <SectionTitle>Header</SectionTitle>
      <Gap>
        <FormColorPicker
          label='Background Color'
          name='headerSectionBgColor'
          menuPlacement='bottom'
          defaultColor={initialValues?.headerSectionBgColor || '#FFF'}
          isClearable={true}
        />
        <FormColorPicker
          label='Text Color'
          name='headerSectionTextColor'
          menuPlacement='bottom'
          defaultColor={initialValues?.headerSectionTextColor || '#FFF'}
          isClearable={true}
        />
        <Gap
          flexDirection='column'
          gap='8px'
          width='100%'
          alignItems='flex-start'
        >
          <UploadImage name='logoImage' label='Logo' />
        </Gap>
        <Gap
          alignItems='flex-start'
          flexDirection='column'
          width='100%'
          gap='8px'
        >
          <Label htmlFor='contactOrientation'>Contact orientation</Label>
          <Gap>
            <Gap gap='4px'>
              <RadioInput
                checkedColor={themes.colors.primary[100]}
                checked={
                  values.contactOrientation === CONTACT_ORIENTATION.VERTICAL
                }
                name='contactOrientation'
                value={''}
                onChange={() =>
                  setFieldValue(
                    'contactOrientation',
                    CONTACT_ORIENTATION.VERTICAL
                  )
                }
                onClick={() =>
                  setFieldValue(
                    'contactOrientation',
                    CONTACT_ORIENTATION.VERTICAL
                  )
                }
              />
              <span>Vertical</span>
            </Gap>
            <Gap gap='4px'>
              <RadioInput
                checkedColor={themes.colors.primary[100]}
                checked={
                  values.contactOrientation === CONTACT_ORIENTATION.HORIZONTAL
                }
                name='contactOrientation'
                value={''}
                onChange={() =>
                  setFieldValue(
                    'contactOrientation',
                    CONTACT_ORIENTATION.HORIZONTAL
                  )
                }
                onClick={() =>
                  setFieldValue(
                    'contactOrientation',
                    CONTACT_ORIENTATION.HORIZONTAL
                  )
                }
              />
              <span>Horizontal</span>
            </Gap>
          </Gap>
        </Gap>
        <Gap
          alignItems='flex-start'
          flexDirection='column'
          width='100%'
          gap='8px'
        >
          <Label htmlFor='contactAlignment'>Contact aligment</Label>
          <Gap>
            <Gap gap='4px'>
              <RadioInput
                checkedColor={themes.colors.primary[100]}
                checked={values.contactAlignment === CONTACT_ALIGNMENT.LEFT}
                name='contactAlignment'
                value={''}
                onChange={() =>
                  setFieldValue('contactAlignment', CONTACT_ALIGNMENT.LEFT)
                }
                onClick={() =>
                  setFieldValue('contactAlignment', CONTACT_ALIGNMENT.LEFT)
                }
              />
              <span>Left</span>
            </Gap>
            <Gap gap='4px'>
              <RadioInput
                checkedColor={themes.colors.primary[100]}
                checked={values.contactAlignment === CONTACT_ALIGNMENT.CENTER}
                name='contactAlignment'
                value={''}
                onChange={() =>
                  setFieldValue('contactAlignment', CONTACT_ALIGNMENT.CENTER)
                }
                onClick={() =>
                  setFieldValue('contactAlignment', CONTACT_ALIGNMENT.CENTER)
                }
              />
              <span>Center</span>
            </Gap>
            <Gap gap='4px'>
              <RadioInput
                checkedColor={themes.colors.primary[100]}
                checked={values.contactAlignment === CONTACT_ALIGNMENT.RIGHT}
                name='contactAlignment'
                value={''}
                onChange={() =>
                  setFieldValue('contactAlignment', CONTACT_ALIGNMENT.RIGHT)
                }
                onClick={() =>
                  setFieldValue('contactAlignment', CONTACT_ALIGNMENT.RIGHT)
                }
              />
              <span>Right</span>
            </Gap>
          </Gap>
          <Gap>
            <Label htmlFor='contactInfo'>Contact info</Label>
            <Gap>
              <Field name={'contactIcons'}>
                {({ field }: FieldProps) => {
                  return (
                    <CheckboxWrapper>
                      <CheckboxInput
                        {...field}
                        checked={field.value}
                        onChange={() => {
                          setFieldValue(`contactIcons`, !values.contactIcons);
                        }}
                      />
                      <CheckboxLabel>Show/hide icons</CheckboxLabel>
                    </CheckboxWrapper>
                  );
                }}
              </Field>

              <Field name={'contactFirstName'}>
                {({ field }: FieldProps) => {
                  return (
                    <CheckboxWrapper>
                      <CheckboxInput
                        {...field}
                        checked={field.value}
                        onChange={() => {
                          setFieldValue(
                            `contactFirstName`,
                            !values.contactFirstName
                          );
                        }}
                      />
                      <CheckboxLabel>First Name</CheckboxLabel>
                    </CheckboxWrapper>
                  );
                }}
              </Field>

              <Field name={'contactLastName'}>
                {({ field }: FieldProps) => {
                  return (
                    <CheckboxWrapper>
                      <CheckboxInput
                        {...field}
                        checked={field.value}
                        onChange={() => {
                          setFieldValue(
                            `contactLastName`,
                            !values.contactLastName
                          );
                        }}
                      />
                      <CheckboxLabel>Last Name</CheckboxLabel>
                    </CheckboxWrapper>
                  );
                }}
              </Field>

              <Field name={'contactPhone1'}>
                {({ field }: FieldProps) => {
                  return (
                    <CheckboxWrapper>
                      <CheckboxInput
                        {...field}
                        checked={field.value}
                        onChange={() => {
                          setFieldValue(`contactPhone1`, !values.contactPhone1);
                        }}
                      />
                      <CheckboxLabel>Phone 1</CheckboxLabel>
                    </CheckboxWrapper>
                  );
                }}
              </Field>
              <Field name={'contactPhone2'}>
                {({ field }: FieldProps) => {
                  return (
                    <CheckboxWrapper>
                      <CheckboxInput
                        {...field}
                        checked={field.value}
                        onChange={() => {
                          setFieldValue(`contactPhone2`, !values.contactPhone2);
                        }}
                      />
                      <CheckboxLabel>Phone 2</CheckboxLabel>
                    </CheckboxWrapper>
                  );
                }}
              </Field>
              <Field name={'contactDepartment'}>
                {({ field }: FieldProps) => {
                  return (
                    <CheckboxWrapper>
                      <CheckboxInput
                        {...field}
                        checked={field.value}
                        onChange={() => {
                          setFieldValue(
                            `contactDepartment`,
                            !values.contactDepartment
                          );
                        }}
                      />
                      <CheckboxLabel>Department</CheckboxLabel>
                    </CheckboxWrapper>
                  );
                }}
              </Field>
              <Field name={'contactTitle'}>
                {({ field }: FieldProps) => {
                  return (
                    <CheckboxWrapper>
                      <CheckboxInput
                        {...field}
                        checked={field.value}
                        onChange={() => {
                          setFieldValue(`contactTitle`, !values.contactTitle);
                        }}
                      />
                      <CheckboxLabel>Title</CheckboxLabel>
                    </CheckboxWrapper>
                  );
                }}
              </Field>
              <Field name={'contactWebsite'}>
                {({ field }: FieldProps) => {
                  return (
                    <CheckboxWrapper>
                      <CheckboxInput
                        {...field}
                        checked={field.value}
                        onChange={() => {
                          setFieldValue(
                            `contactWebsite`,
                            !values.contactWebsite
                          );
                        }}
                      />
                      <CheckboxLabel>Website</CheckboxLabel>
                    </CheckboxWrapper>
                  );
                }}
              </Field>
            </Gap>
          </Gap>
        </Gap>
      </Gap>
    </Section>
  );
};
