import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { MANAGED_USERS_KEY } from './queryKeys';
import { IUserDataRequest } from './types';

interface UpdateUserParams {
  data: Partial<IUserDataRequest>;
  changeOrigin?: CHANGE_ORIGIN;
}

export const createUser = async ({
  data,
  changeOrigin,
}: UpdateUserParams): Promise<{ message: string; userId?: string }> => {
  const response = await EXPRESS_API.post('/users', data, {
    params: { changeOrigin },
    withCredentials: true,
  });

  return response.data;
};

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(createUser, {
    onSuccess: data => {
      if (data.userId) {
        history.push(`/users/entry/${data.userId}`);
      }
      queryClient.invalidateQueries([MANAGED_USERS_KEY]);
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Failed to create user';
      errorToast({ title: errorMessage });
    },
  });
};
