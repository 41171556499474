import { VerificationStatus } from 'lib/const/VerificationStatus';

import { useQuery } from 'react-query';
import { superAdminKeys } from './superAdminKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IUsersResponse {
  users: User[];
  count: string;
}

export interface User {
  id: number;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  verified: number;
  access: number;
  admin: number;
  phone1: string;
  loginDate: string;
  trialAccount: number;
  packageId: number;
  customerName: string;
  customerPackageId: number;
  departmentId: string;
  customerId: number;
}

export type UserListParams = {
  start?: number;
  limit?: number;
  search?: string;
  sortKey?: string;
  order?: string;
  status:
    | VerificationStatus.ACTIVE
    | VerificationStatus.INACTIVE
    | VerificationStatus.ALL;
  customerId?: number;
  departmentId?: number | null;
};

const getAllCustomersForSuperAdmin = async (params: UserListParams) => {
  return (
    await EXPRESS_API.get(`superadmin/users`, {
      params,
    })
  ).data as IUsersResponse;
};

export const useSuperAdminUserQuery = ({
  params,
  enabled,
}: {
  params: UserListParams;
  enabled: boolean;
}) => {
  return useQuery(
    superAdminKeys.users(
      `${params?.customerId}` || '',
      `${params?.departmentId}` || ''
    ),
    () => getAllCustomersForSuperAdmin(params),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
    }
  );
};
