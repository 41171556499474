import React, { useState } from 'react';
import { LoadingIndicator } from 'lib/components';
import { UserListFilter, SuperAdminUser, useAuth } from 'lib/context';
import { useHistory } from 'react-router';
import { SearchAndFilter } from '../components/SearchAndFilter';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { debounce } from 'lodash';
import {
  exportUsersData,
  useUserQuery,
  useUsersStatisticsQuery,
} from 'lib/api/superAdminApi';
import { UsersHeader } from '../components/UsersHeader';

import {
  Container,
  MainContainer,
  MainWrapper,
  NoList,
} from '../../index.styled';
import { SelectedUsers } from '../../components/SelectedUsers';
import { UserTable, UserTableColumns } from '../../components/UserTable';
import { errorToast } from 'lib/components/toasts/error';
import { SuperAdminRole } from 'lib/const/SuperAdminRole';
import { SCREEN_VALUES } from 'lib/const/SuperAdminConstants';
import { AllCustomerOption } from 'lib/const/SuperAdminOptions';
import {
  ModalCustomTableFields,
  TABLE_NAME_PREFIX,
} from 'lib/components/modal';

type TableFieldProps = {
  label: string;
  value: string;
  width?: number;
  sortable?: boolean;
  sortKey?: string;
};
const allTableFields = [
  {
    value: UserTableColumns.FIRST_NAME,
    label: 'First Name',
    sortable: true,
    sortKey: 'FIRST_NAME',
    width: 136,
  },
  {
    value: UserTableColumns.LAST_NAME,
    label: 'Last Name',
    sortable: true,
    sortKey: 'LAST_NAME',
    width: 150,
  },
  {
    value: UserTableColumns.PHONE,
    label: 'Phone',
  },
  {
    value: UserTableColumns.LAST_LOGIN,
    label: 'Last login',
    sortable: true,
    sortKey: 'LAST_LOGIN',
  },
  {
    value: UserTableColumns.STATUS,
    label: 'Status',
    width: 120,
  },
  {
    value: UserTableColumns.EMAIL,
    label: 'Email',
    width: 330,
  },
  {
    value: UserTableColumns.CUSTOMER,
    label: 'Customer',
    width: 140,
  },
  {
    value: UserTableColumns.TRANSCRIPTION_ACCESS,
    label: 'Captions',
  },
  {
    value: UserTableColumns.DEPARTMENT_NAME,
    label: 'Department',
  },
  {
    value: UserTableColumns.DROPLR_ACCESS,
    label: 'Files',
  },
  {
    value: UserTableColumns.USERNAME,
    label: 'Username',
  },
  {
    value: UserTableColumns.SIGNUP_DATE,
    label: 'Sign Up Date',
  },
];

const initialTableFields = [
  allTableFields[0],
  allTableFields[1],
  allTableFields[4],
  allTableFields[5],
  allTableFields[6],
  allTableFields[3],
];

export const List = () => {
  const tableName = 'SuperAdminUsers';
  const history = useHistory();
  const [selectedUsersData, setSelectedUsersData] = useState<SuperAdminUser[]>(
    []
  );

  const [userSearch, setUserSearch] = React.useState('');
  const [userStatus, setUserStatus] = React.useState(VerificationStatus.ACTIVE);

  const [userSortKey, setUserSortKey] = React.useState('');
  const [userSortOrder, setUserSortOrder] = React.useState(
    PaginationConstants.ASCENDING
  );
  const [userPage, setUserPage] = React.useState(DEFAULT_PAGE);
  const [userSize, setUserSize] = React.useState(DEFAULT_SIZE);
  const [selectedCustomerId, setCustomer] = React.useState(0);
  const [isCustomizeTableModel, setIsCustomizeTableModel] =
    React.useState(false);
  const { userData } = useAuth();

  const [tableFields, setTableFields] = React.useState(
    initialTableFields as TableFieldProps[]
  );

  const showCustomerOptions =
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.NON_COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.COVIDEO_ADMIN;

  const filter: UserListFilter = {
    sortKey: userSortKey,
    order: userSortOrder || PaginationConstants.ASCENDING,
    page: userPage || DEFAULT_PAGE,
    size: userSize || DEFAULT_SIZE,
    searchQuery: userSearch.trim(),
    status: userStatus,
    customerId:
      selectedCustomerId > 0 ? selectedCustomerId : AllCustomerOption.value,
  };

  const loadTableFields = () => {
    const selectedFields = localStorage.getItem(TABLE_NAME_PREFIX + tableName);
    if (selectedFields) {
      setTableFields(JSON.parse(selectedFields));
    }
  };

  React.useEffect(() => {
    loadTableFields();
  }, []);

  const {
    statistics,
    refetch: refreshUserStats,
    isLoading: statsLoading,
  } = useUsersStatisticsQuery({
    params: {
      customerId: filter.customerId,
    },
    filter,
  });

  const {
    count: userCount,
    userArray,
    refetch: refreshUserList,
    isLoading: showLoading,
  } = useUserQuery({
    params: {
      start: (filter.page || DEFAULT_PAGE) * (filter.size || DEFAULT_SIZE),
      limit: filter.size || DEFAULT_SIZE,
      search: filter.searchQuery,
      status: filter.status ?? VerificationStatus.ALL,
      sortKey: filter.sortKey,
      order: filter.order,
      customerId:
        !!filter.customerId && filter.customerId >= 0
          ? filter.customerId
          : undefined,
    },
    filter,
    enabled: true,
    onSuccess: () => setSelectedUsersData([]),
  });

  const onPaginationChange = debounce(
    async ({
      page: newPage,
      size: newSize,
    }: {
      page: number;
      size: number;
    }) => {
      setUserPage(newPage);
      setUserSize(newSize);
    },
    300
  );

  const handleSelectAll = () => {
    setSelectedUsersData(userArray);
  };

  const onSaveCustomTableFields = (selectedFields: TableFieldProps[]) => {
    localStorage.setItem(
      TABLE_NAME_PREFIX + tableName,
      JSON.stringify(selectedFields)
    );
    setTableFields(selectedFields);
    setIsCustomizeTableModel(false);
  };

  const exportUsers = async () => {
    try {
      const result = await exportUsersData(
        `users.csv`,
        filter.status,
        filter.searchQuery,
        filter.sortKey,
        filter.order,
        filter.customerId
      );
      return result;
    } catch (error) {
      errorToast({ title: `Error in exporting Users.` });
    }
  };

  const showEditRoleButton =
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.NON_COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.COVIDEO_ADMIN;

  return (
    <MainContainer>
      {!!selectedUsersData.length && (
        <SelectedUsers
          userArray={userArray}
          handleDeselected={() => setSelectedUsersData([])}
          handleSelectAll={handleSelectAll}
          selectedUsers={selectedUsersData}
          changeRoleInfoText={
            'Role adjustments will be made only for users associated with Automotive access'
          }
          showChangeCustomerButton={showCustomerOptions}
          showEditRoleButton={showEditRoleButton}
          showSelectAllDropdown={true}
          setSelectedUsers={(selectedUsers: SuperAdminUser[]) => {
            setSelectedUsersData(selectedUsers);
          }}
          onSuccessfullUpdate={() => {
            refreshUserList();
            refreshUserStats();
          }}
        />
      )}
      <MainWrapper>
        <Container>
          <UsersHeader
            statistics={statistics}
            loading={statsLoading}
            onAddNewUser={() => {
              history.push(`/admin/users/entry/${SCREEN_VALUES.USERS}`);
            }}
            exportUsers={exportUsers}
          />
          <SearchAndFilter
            customerFilter={filter.customerId}
            statusFilter={filter.status}
            showCustomerFilter={showCustomerOptions}
            onSelectCustomer={(customerId: number) => {
              setCustomer(customerId);
            }}
            onSelectStatus={setUserStatus}
            onSearch={setUserSearch}
            prevSearch={filter.searchQuery}
            setIsCustomizeTableModel={setIsCustomizeTableModel}
          />
          {showLoading && (
            <div style={{ width: '100%', height: '300px' }}>
              <LoadingIndicator isLoading={showLoading} height='300px' />
            </div>
          )}
          {!showLoading && !!userArray.length && (
            <UserTable
              userCount={userCount}
              userArray={userArray}
              selectedUsersData={selectedUsersData}
              page={filter.page}
              size={filter.size}
              sortKey={filter.sortKey}
              order={filter.order}
              tableFields={[
                {
                  value: UserTableColumns.ACCESS,
                  label: '',
                  width: 50,
                },
                {
                  value: UserTableColumns.LOGINAS,
                  label: '',
                  width: 50,
                },
                ...tableFields,
              ]}
              onPaginationChange={onPaginationChange}
              onSortingUpdate={(sortKey, order) => {
                setUserSortKey(sortKey);
                setUserSortOrder(order);
                setUserPage(DEFAULT_PAGE);
              }}
              openUserDetails={(user: SuperAdminUser) => {
                history.push(`/admin/users/${user.id}/${SCREEN_VALUES.USERS}`);
              }}
              onUserSelect={(user: SuperAdminUser, checked: boolean) => {
                setSelectedUsersData(
                  checked
                    ? [...selectedUsersData, user]
                    : selectedUsersData.filter(
                        (e: SuperAdminUser) => e.id != user.id
                      )
                );
              }}
            />
          )}
          {isCustomizeTableModel && (
            <ModalCustomTableFields
              title='Customize User Table'
              onClose={() => setIsCustomizeTableModel(false)}
              allFields={allTableFields}
              initialFields={tableFields}
              maxFieldConfig={{ count: 6 }}
              actionButtons={[
                {
                  onClick: onSaveCustomTableFields,
                  text: 'Update',
                },
              ]}
            />
          )}
          {!showLoading && !userArray.length && (
            <NoList>No Users to show.</NoList>
          )}
        </Container>
      </MainWrapper>
    </MainContainer>
  );
};
