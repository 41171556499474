import { useLeadsQuery } from 'lib/api/leads/getLeads';
import { Dropdown, LoadingIndicator, Search } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import { MdArrowBackIos, MdDeleteForever, MdEdit } from 'react-icons/md';
import { useHistory, useParams } from 'react-router';
import { FixedCounterHeader } from '../components/FixedCounterHeader';
import { LeadTable } from '../components/LeadTable';
import { NoLeadsInGroup } from '../components/NoLeadsInGroup';
import { ModalDeleteGroupLeads } from '../components/ModalDeleteGroupLeads';
import { ModalGroupForm } from '../components/ModalGroupForm';
import { Heading, ParagraphSmallBold } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { useSingleLeadGroupQuery } from 'lib/api/leadGroups/useSingleLeadGroupQuery';
import { ModalRemoveLeadFromGroup } from '../components/ModalRemoveLeadFromGroup';
import { GenericCountFilter } from 'lib/api';

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 530px;
`;

type LeadsFilter = {
  [key: string]: {
    label: string;
    value: string;
    sendsFilter: GenericCountFilter;
  };
};

const leadsFilter: LeadsFilter = {
  ALL: {
    label: 'Show All',
    value: 'ALL',
    sendsFilter: GenericCountFilter.ALL,
  },
  ONLY_WITH_SENDS: {
    label: 'Only contacted',
    value: 'ONLY_WITH_SENDS',
    sendsFilter: GenericCountFilter.WITH_COUNT,
  },
  ONLY_WITHOUT_SENDS: {
    label: 'Only not contacted',
    value: 'ONLY_WITHOUT_SENDS',
    sendsFilter: GenericCountFilter.WITHOUT_COUNT,
  },
};

export const CustomerLeadsSingleGroupPage = () => {
  const filterOptions = Object.values(leadsFilter);
  const [filterValue, setFilterValue] = useState(leadsFilter.ALL.value);

  const sendsFilter = leadsFilter[filterValue as keyof LeadsFilter].sendsFilter;
  const history = useHistory();
  const { groupId } = useParams() as { groupId: string };
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isRemoveFromGroupModalVisible, setIsRemoveFromGroupModalVisible] =
    useState(false);
  const [isGroupModalVisible, setIsGroupModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLead, setSelectedLead] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const { data: leads, isLoading } = useLeadsQuery(
    {
      size,
      page,
      search: searchQuery,
      leadGroupId: groupId,
      sendsFilter,
    },
    !!groupId
  );

  const { data: singleGroup } = useSingleLeadGroupQuery(Number(groupId));
  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
    if (page !== newPage) {
      setSelectedLead([]);
    }
  };
  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setPage(0);
  };

  const removeFromGroupHandler = () => {
    setIsRemoveFromGroupModalVisible(true);
  };

  const onModalCloseHandler = () => {
    setIsDeleteModalVisible(false);
    setIsGroupModalVisible(false);
    setIsRemoveFromGroupModalVisible(false);
  };

  const onSuccessCallback = () => {
    setSelectedLead([]);
  };

  return (
    <>
      <FixedCounterHeader
        selectedIds={selectedLead}
        setSelectedIds={setSelectedLead}
        groupsArray={leads?.leads.map(lead => lead.leadId) || []}
        label='Customers selected'
        removeFromGroupHandler={removeFromGroupHandler}
      />

      <Gap gap='12px' width='100%' justifyContent='space-between'>
        <Gap onClick={() => history.push(`/customers/group-items`)} gap='4px'>
          {!isLoading && (
            <>
              <MdArrowBackIos size={20} style={{ cursor: 'pointer' }} />
              <Heading>{singleGroup?.name || ''}</Heading>
            </>
          )}
        </Gap>

        <Gap justifyContent='flex-end' gap='8px'>
          <Gap>
            <Search
              placeholder='Search Customers...'
              onSearch={onSearch}
              width='300px'
            />
            <Dropdown
              width={220}
              options={filterOptions}
              value={filterOptions.find(o => {
                return o.value === filterValue;
              })}
              onChange={option => setFilterValue(option.value)}
              placeholder={'Show all'}
            />
          </Gap>
          <Button
            variant='secondary'
            icon={<MdEdit size='20' />}
            onClick={() => setIsGroupModalVisible(true)}
            disabled={isLoading}
          />

          <Button
            variant='destructive'
            icon={<MdDeleteForever size={20} />}
            onClick={() => setIsDeleteModalVisible(true)}
            disabled={isLoading}
          />
        </Gap>
      </Gap>

      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={isLoading} />
        </div>
      ) : (
        <>
          {leads?.leads.length === 0 ? (
            <>
              {searchQuery !== '' || filterValue !== 'ALL' ? (
                <EmptySearch>
                  <ParagraphSmallBold color={theme.palette.blue60}>
                    No groups match your current filter
                  </ParagraphSmallBold>
                  <ParagraphSmallBold color={theme.palette.blue60}>
                    Try adjusting your filters to find groups
                  </ParagraphSmallBold>
                </EmptySearch>
              ) : (
                <NoLeadsInGroup />
              )}
            </>
          ) : (
            <LeadTable
              leadsArray={leads?.leads || []}
              selectedLead={selectedLead}
              count={leads?.count || 0}
              page={page}
              size={size}
              onPaginationChange={onPaginationChange}
              setSelectedLead={setSelectedLead}
            />
          )}
        </>
      )}

      {isDeleteModalVisible && (
        <ModalDeleteGroupLeads
          count={1}
          onModalClose={onModalCloseHandler}
          leadGroupIds={[Number(groupId)]}
          onSuccessCallback={onSuccessCallback}
          handleBackNavigation={() => history.push(`/customers/group-items`)}
        />
      )}
      {isGroupModalVisible && (
        <ModalGroupForm
          onModalClose={onModalCloseHandler}
          editGroup={{
            leadGroupId: Number(groupId),
            name: singleGroup?.name || '',
          }}
          onSuccessCallback={onSuccessCallback}
        />
      )}

      {isRemoveFromGroupModalVisible && (
        <ModalRemoveLeadFromGroup
          handleModalClose={onModalCloseHandler}
          selectedLead={selectedLead}
          leadGroupId={Number(groupId)}
          onSuccessCallback={onSuccessCallback}
        />
      )}
    </>
  );
};
