export type TeleprompterParams = {
  start?: number;
  limit?: number;
  search?: string;
  field?: string;
  order?: string;
  scope?: string;
};

export const SCOPE = {
  PERSONAL: 'personal',
  SHARED: 'shared',
  PERSONAL_SHARED: 'personal_shared',
  COVIDEO: 'covideo',
};

export type CreateTeleprompterScriptParams = {
  data: object;
  scriptId?: string;
};

export type TeleprompterResponse = {
  content: string;
  createdAt?: string;
  customerId?: number;
  scriptId?: number;
  sharedWithCompany: boolean;
  sharedWithAll: boolean;
  title: string;
  userId?: number;
};
