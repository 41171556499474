import { importSuperAdminUsers, importUsers } from 'lib/api';
import { Modal } from 'lib/components';
import {
  colOptionProps,
  csvDataProps,
} from 'lib/components/modal/importModal/Types';
import { successToast } from 'lib/components/toasts/success';
import { AccessRole } from 'lib/const';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import {
  isEmail2FieldMandatory,
  validateInputForCsvFormulaInjection,
} from 'lib/utils/functions';
import get from 'lodash/get';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { AddBulkUser } from './importUserModalComponents/AddBulkUser';
import { ImportedUsers } from './importUserModalComponents/ImportedUsers';
import { LoadingView } from './importUserModalComponents/LoadingView';
import {
  MapImported,
  SAMPLE_LENGTH,
} from './importUserModalComponents/MapImported';
import { NonImportedUsers } from './importUserModalComponents/NonImportedUsers';
import {
  EMPTY_CSV_TEXT,
  UPLOAD_CSV_TEXT,
  USER_IMPORT_EMAIL_VALIDATION_REG,
} from './importUserModalComponents/Constants';
import { hasEmail2FieldAccess } from 'lib/utils/functions';
import { getAutomotiveRoleValueFromLabel } from 'lib/utils/automotiveRolePermissionChecks';

// Fields provided by Tanya for exact column matches
// https://covideo.atlassian.net/browse/CDS-3252

const FIRST_NAME_REG = new RegExp('^First_Name');
const LAST_NAME_REG = new RegExp('^Last_Name');
const EMAIL_REG = new RegExp('^Email$', 'i');
const USER_NAME_REG = new RegExp('^Username_Email_used_if_blank$');
const PHONE_REG = new RegExp('^Phone_Number$');
const CELL_PHONE_REG = new RegExp('^Cell_Phone$');
const USER_TYPE_REG = new RegExp('^Choose_Admin_Supervisor_User$');
const PASSWORD_REG = new RegExp('^Password$');
const TITLE_REG = new RegExp('^Title$');
const DEPARTMENT_REG = new RegExp('^Department$');
const AUTOMOTIVE_ROLE_REG = new RegExp('^Automotive_Role$');

// Documentation for these fields has not yet been received
const EMAIL2_REG = new RegExp('\\bEmail2\\b', 'ig');
const ADMIN_REG = new RegExp('^(admin|admin_y_n)$', 'i');
const NMLS_REG = new RegExp('nmls', 'ig');
const VERIFIED_REG = new RegExp('active', 'ig');
const CAPTION_REG = new RegExp('enable captions', 'ig');
const MOBILE_SMS_REG = new RegExp('mobile sms', 'ig');

const getHeaderRegexValues = (hasEmail2FieldAccess: boolean) => {
  const headerRegValues = [
    { field: 'firstName', reg: FIRST_NAME_REG },
    { field: 'lastName', reg: LAST_NAME_REG },
    { field: 'username', reg: USER_NAME_REG },
    { field: 'password', reg: PASSWORD_REG },
    { field: 'phone1', reg: PHONE_REG },
    { field: 'cellPhone', reg: CELL_PHONE_REG },
    { field: 'email', reg: EMAIL_REG },
    { field: 'title', reg: TITLE_REG },
    { field: 'departmentName', reg: DEPARTMENT_REG },
    { field: 'isAdmin', reg: ADMIN_REG },
    { field: 'nmls', reg: NMLS_REG },
    { field: 'userType', reg: USER_TYPE_REG },
    { field: 'automotiveRoleLabel', reg: AUTOMOTIVE_ROLE_REG },
    // SuperAdmin user fields
    { field: 'active', reg: VERIFIED_REG },
    { field: 'transcriptionAccess', reg: CAPTION_REG },
    { field: 'mobileSMSAccess', reg: MOBILE_SMS_REG },
  ];
  if (hasEmail2FieldAccess) {
    headerRegValues.push({ field: 'email2', reg: EMAIL2_REG });
  }
  return headerRegValues;
};

const FieldLength = {
  firstName: 25,
  lastName: 25,
  email: 255,
  phone1: 25,
  cellPhone: 25,
  username: 50,
  password: 25,
  email2: 255,
};

export enum ModalOrigin {
  USERS = 1,
  SUPER_ADMIN_USERS = 2,
}

export enum ModalType {
  ONE = 'one',
  BULK = 'bulk',
}

type Props = {
  disabled?: boolean;
  title?: string;
  text?: string;
  handleModalClose: (shouldRefresh?: boolean) => void;
  handleSubmit: (data: object) => void;
  modalType?: ModalType;
  origin?: ModalOrigin;
  customerId?: number;
  resellerId?: number;
  automotiveEnabled?: boolean;
};

const initialCSVData: csvDataProps = { data: [], uploaded: false };
const initialColOptions: colOptionProps[] = [];
const getIntitalColMapping = (
  hasEmail2FieldAccess: boolean
): { [key: string]: string } => {
  const colMapping = {
    firstName: 'None',
    lastName: 'None',
    username: 'None',
    phone1: 'None',
    cellPhone: 'None',
    email: 'None',
    password: 'None',
    title: 'None',
    departmentName: 'None',
    isAdmin: 'None',
    automotiveRoleLabel: 'None',
    nmls: 'None',

    // Super admin user fields
    active: 'None',
    transcriptionAccess: 'None',
    mobileSMSAccess: 'None',
    ...(hasEmail2FieldAccess ? { email2: 'None' } : {}),
  };
  return colMapping;
};

const initialColSampleData: { [key: string]: string[] } = {};
const initialInvalidRows: { [key: string]: boolean } = {};
const initialDuplicatedRows: { [key: string]: string[] } = {};

enum Views {
  MAPPING = 1,
  NON_IMPORTED_USERS = 2,
  IMPORT = 3,
  LOADING = 5,
  MAPPING_IMPORT = 6,
}

export const ImportUserModal = ({
  modalType = ModalType.ONE,
  disabled = false,
  title = '',
  text = '',
  origin = ModalOrigin.USERS,
  customerId = 0,
  resellerId = 0,
  handleModalClose,
  handleSubmit,
  automotiveEnabled,
}: Props) => {
  const { userData } = useAuth();
  const isAutomotive = automotiveEnabled ?? userData.isAutomotive;
  const hasEmail2Field = hasEmail2FieldAccess(
    !!resellerId ? resellerId.toString() : userData?.resellerId,
    !!customerId ? customerId.toString() : userData?.customerId
  );
  const isEmail2Mandatory: boolean = isEmail2FieldMandatory(
    !!resellerId ? resellerId.toString() : userData?.resellerId,
    !!customerId ? customerId.toString() : userData?.customerId
  );
  const HEADER_REG_VALUES = getHeaderRegexValues(hasEmail2Field);
  const initialColMapping = getIntitalColMapping(hasEmail2Field);

  const [currentView, setCurrentView] = useState<Views>(Views.IMPORT);
  const [CSVData, setCSVData] = useState(initialCSVData);
  const [colOptions, setColOptions] = useState(initialColOptions);
  const [colMapping, setColMapping] = useState(initialColMapping);
  const [colSamples, setColSamples] = useState(initialColSampleData);
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [_, setUserValid] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [nonImportedUser, setNonImportedUser] = useState<{
    discardedUsers: {
      firstName: string;
      lastName: string;
      email: string;
      failReason: string;
    }[];
    message: string;
  }>({
    discardedUsers: [],
    message: '',
  });
  const { showError } = useToastError();

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    isAdmin: '',
    automotiveRoleLabel: '',
    email2: '',
  });
  const [invalidRows, setInvalidRows] = useState(initialInvalidRows);
  const [invalidCount, setInvalidCount] = useState(0);
  const [duplicatedRows, setDuplicatedRows] = useState(initialDuplicatedRows);

  // remove empty rows
  const filteredData = CSVData.data.filter((row: string[]) =>
    row.some(cell => !!cell?.toString().trim())
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const values = Object.keys(colMapping).reduce(
      (obj: { [key: string]: string[] }, field: string) => {
        filteredData.forEach((d: string[]) => {
          if (!obj[field]) {
            obj[field] = [];
          }
          obj[field].push(getColValue(d, field));
        });
        return obj;
      },
      {}
    );
    const invalid: { [key: string]: boolean } = {};
    const duplicated: { [key: string]: string[] } = {};
    let uniqueField = 'username';
    const usernameValuesPresent = values['username']?.find(
      d => !!d?.toString().trim()
    );
    if (!usernameValuesPresent) {
      uniqueField = 'email';
    }

    Object.keys(values).forEach(field => {
      const data = values[field];
      for (let i in data) {
        const fieldValue = data[i];
        const validationResult =
          (!invalid[field] && !validate(fieldValue, field)) ||
          // @ts-ignore
          fieldValue.lenght > FieldLength[field];
        if (invalid[field] !== null && invalid[field] !== undefined) {
          invalid[field] = invalid[field] || validationResult;
        } else {
          invalid[field] = validationResult;
        }

        if (field === uniqueField) {
          if (!duplicated[fieldValue]) {
            duplicated[fieldValue] = [];
          }
          if (!invalid[field]) {
            const index = Number(i) + 2;
            duplicated[fieldValue].push(index.toString());
          }
          if (duplicated[fieldValue].length > 1) {
            invalid[field] = true;
          }
        }
      }
    });
    setInvalidRows(invalid);
    setDuplicatedRows(duplicated);
    setInvalidCount(Object.values(invalid).filter(Boolean).length);
  }, [colMapping, CSVData.data]);

  const initColOptions = (row: string[]) => {
    const options = row.map((_: string, i: number) => ({
      value: `${i}`,
      label: `Col ${i + 1}`,
    }));

    options.unshift({ value: 'None', label: 'Choose...' });
    setColOptions(options);
  };

  useEffect(() => {
    const bulkValid = modalType === ModalType.BULK && !!filteredData.length;
    setUserValid(bulkValid);
  }, [modalType, CSVData.data]);
  const initCSVHeaders = (row: string[]) => {
    const options = [];
    let found = false;
    let count = 0;
    for (let i in row) {
      if (!row[i]) {
        break;
      }
      let value = row[i].toString();
      options.push({ label: value, value: `${i}` });
      for (let header of HEADER_REG_VALUES) {
        if (header.reg != null && value.match(header.reg)) {
          found = true;
          count++;
          setColMapping(value => ({ ...value, [header.field]: `${i}` }));
        }
      }
    }
    options.unshift({ value: 'None', label: 'Choose...' });

    found ? setColOptions(options) : setColMapping(initialColMapping);
    return { found, count };
  };

  const initSampleData = (data: string[][]) => {
    let sample = { ...colSamples };
    let sampleCollected;
    let i = 0;
    while (!sampleCollected && i < data.length) {
      let row = data[i];
      row.forEach((field, index) => {
        if (!sample[index]) {
          sample[index] = [];
        }
        sample[index].push(field);
      });
      let values = Object.keys(sample).map(k => sample[k]);
      sampleCollected = values.reduce(
        (collected, fieldSample) =>
          collected && fieldSample.length >= SAMPLE_LENGTH,
        false
      );
      i++;
    }
    setColSamples(sample);
  };

  const onColChange = (field: string, value: string) => {
    const newField =
      Object.keys(colMapping).find(key => colMapping[key] === value) || 'None';
    if (field === 'None') {
      setColMapping({ ...colMapping, [newField]: 'None' });
    } else {
      setColMapping({ ...colMapping, [newField]: 'None', [field]: value });
    }
  };

  const onUpload = (csv: string[][], fileInfo: { name: string }) => {
    const data = [...csv];
    setUploadError(false);
    if (!fileInfo.name.includes('csv')) {
      setUploadErrorMessage(UPLOAD_CSV_TEXT);
      setUploadError(true);
      return;
    }

    if (data.length <= 1) {
      setUploadErrorMessage(EMPTY_CSV_TEXT);
      setUploadError(true);
      return;
    }
    const firstRow = data[0];
    const { found } = initCSVHeaders(firstRow);
    if (found) {
      data.shift();
    } else {
      initColOptions(firstRow);
    }
    initSampleData(data);
    setCSVData({ data, uploaded: true });
    setCurrentView(Views.MAPPING);
  };

  const getColValue = (data: string[], field: string) => {
    const columnValue = get(colMapping, field, '');
    if (!columnValue || columnValue === 'None') {
      return '';
    }
    return data[parseInt(columnValue)]?.toString().trim() || '';
  };

  const initializeListView = () => {
    setSelectedUser(
      filteredData
        .filter((e, i: number) => !invalidRows[i])
        .map(e => getColValue(e, 'username'))
    );
    setCurrentView(Views.MAPPING_IMPORT);
  };

  const getAccess = (data: string[]) => {
    const isAdmin = getColValue(data, 'isAdmin').toLowerCase();
    const userType = getColValue(data, 'userType').toLowerCase();
    if (isAdmin === 'yes' || userType === 'admin') {
      return AccessRole.ADMIN;
    }

    if (userType === 'supervisor') {
      return AccessRole.SUPERVISOR;
    }

    return AccessRole.USER;
  };
  const onSubmit = async () => {
    try {
      const users = filteredData
        .filter(
          (data, i) =>
            !invalidRows[i] &&
            selectedUser.includes(getColValue(data, 'username'))
        )
        .map(data => {
          const username = getColValue(data, 'username');
          const email = getColValue(data, 'email');
          return {
            firstName: getColValue(data, 'firstName'),
            lastName: getColValue(data, 'lastName'),
            email: email,
            phone1: getColValue(data, 'phone1'),
            cellPhone: getColValue(data, 'cellPhone'),
            username: !!username.length ? username : email,
            password: getColValue(data, 'password'),
            title: getColValue(data, 'title'),
            departmentName: getColValue(data, 'departmentName'),
            access: getAccess(data),
            nmls: getColValue(data, 'nmls'),
            active: getColValue(data, 'verified'),
            transcriptionAccess: getColValue(data, 'transcriptionAccess'),
            mobileSMSAccess: getColValue(data, 'mobileSMSAccess'),
            ...(hasEmail2Field ? { email2: getColValue(data, 'email2') } : {}),
            ...(isAutomotive
              ? {
                  automotiveRole: getAutomotiveRoleValueFromLabel(
                    getColValue(data, 'automotiveRoleLabel')
                  ),
                }
              : {}),
          };
        })
        .filter(Boolean);
      const response =
        origin === ModalOrigin.USERS
          ? await importUsers({ users })
          : await importSuperAdminUsers({ users, customerId });
      const importedUserIds = response?.data?.userIds;
      if (importedUserIds?.length) {
        handleSubmit(importedUserIds);
      }
      if (
        response.data &&
        response.data.discardedUsers &&
        response.data.discardedUsers.length
      ) {
        setNonImportedUser({
          discardedUsers: response.data.discardedUsers,
          message: response.message,
        });
        setCurrentView(Views.NON_IMPORTED_USERS);
        return;
      }
      successToast({ title: response.message });
      handleModalClose(true);
    } catch (error) {
      showError(error);
    }
  };

  const validateEmail = (email: string = '', key: string) => {
    let emailError = '';
    if (!email) {
      emailError = 'Please enter email address';
    } else if (!USER_IMPORT_EMAIL_VALIDATION_REG.test(email.toLowerCase())) {
      emailError = 'Please enter valid email address';
    } else if (!validateInputForCsvFormulaInjection(email)) {
      emailError = 'Please enter valid email';
    }
    if (key) {
      setErrors(prevState => ({ ...prevState, [key]: emailError }));
      return emailError;
    }

    setErrors(prevState => ({ ...prevState, email: emailError }));
    return emailError;
  };

  const validateEmail2 = (email2: string = '') => {
    if (!email2 && !isEmail2Mandatory) {
      return '';
    }

    return validateEmail(email2, 'email2');
  };

  const validateFirstName = (firstName: string | undefined) => {
    let firstNameError = '';
    if (!firstName) {
      firstNameError = 'Please enter valid first name';
    } else if (!validateInputForCsvFormulaInjection(firstName)) {
      firstNameError = 'Please enter valid first name';
    }
    setErrors(prevState => ({ ...prevState, firstName: firstNameError }));
    return firstNameError;
  };

  const validateLastName = (lastName: string | undefined) => {
    let lastNameError = '';
    if (!lastName) {
      lastNameError = 'Please enter valid last name';
    } else if (!validateInputForCsvFormulaInjection(lastName)) {
      lastNameError = 'Please enter valid last name';
    }
    setErrors(prevState => ({ ...prevState, lastName: lastNameError }));

    return lastNameError;
  };

  const validateIsAdmin = (isAdmin: string | undefined) => {
    let isAdminError = '';
    if (isAdmin && !['yes', 'no'].includes(isAdmin.toLowerCase())) {
      isAdminError = 'Please enter yes or no for Admin';
    }
    setErrors(prevState => ({ ...prevState, isAdmin: isAdminError }));
    return isAdminError;
  };

  const validateAutomotiveRoleLabel = (automotiveRole: string | undefined) => {
    let automotiveRoleError = '';
    if (
      automotiveRole &&
      !getAutomotiveRoleValueFromLabel(automotiveRole.toLowerCase())
    ) {
      automotiveRoleError = 'Please enter a valid Automotive Role';
    }
    setErrors(prevState => ({
      ...prevState,
      automotiveRoleLabel: automotiveRoleError,
    }));

    return automotiveRoleError;
  };

  const validatePhoneNumber = (phone: string, type: string) => {
    let phoneError = '';

    if (phone === '') {
      return '';
    }

    if (phone.length < 7) {
      phoneError = `Please enter a ${type} number equal or longer than 7 characters.`;
    }

    if (!validateInputForCsvFormulaInjection(phone)) {
      phoneError = `Please enter a valid ${type} number.`;
    }

    setErrors(prevState => ({ ...prevState, [type]: phoneError }));
    return phoneError;
  };

  const validatePhone = (phone: string) => validatePhoneNumber(phone, 'phone1');
  const validateCellPhone = (phone: string) =>
    validatePhoneNumber(phone, 'cellPhone');

  const validationMapping: { [key: string]: Function } = {
    email: validateEmail,
    firstName: validateFirstName,
    lastName: validateLastName,
    phone1: validatePhone,
    cellPhone: validateCellPhone,
    isAdmin: validateIsAdmin,
    ...(isAutomotive
      ? { automotiveRoleLabel: validateAutomotiveRoleLabel }
      : {}),
    ...(hasEmail2Field
      ? { email2: validateEmail2.bind(isEmail2FieldMandatory) }
      : {}),
  };

  const validate = (value: string, field: string) => {
    if (!validationMapping[field]) {
      return true;
    }
    return !validationMapping[field](value);
  };

  let CurrentVievComponent = null;
  switch (currentView) {
    case Views.IMPORT:
      CurrentVievComponent = (
        <AddBulkUser
          disabled={disabled}
          handleModalClose={handleModalClose}
          onUpload={onUpload}
          text={text}
          title={title}
          uploadError={uploadError}
          errorMessage={uploadErrorMessage}
          isEmail2Mandatory={isEmail2Mandatory}
        />
      );
      break;
    case Views.MAPPING:
      CurrentVievComponent = (
        <MapImported
          errors={errors}
          title={title}
          isAutomotive={isAutomotive}
          disabled={disabled}
          handleModalClose={handleModalClose}
          colOptions={colOptions}
          colSamples={colSamples}
          colMapping={colMapping}
          invalidCount={invalidCount}
          CSVData={{ data: filteredData }}
          duplicatedRows={duplicatedRows}
          onColChange={onColChange}
          initializeListView={initializeListView}
          hasEmail2FieldAccess={hasEmail2Field}
          isEmail2Mandatory={isEmail2Mandatory}
        />
      );
      break;
    case Views.MAPPING_IMPORT:
      CurrentVievComponent = (
        <ImportedUsers
          disabled={disabled}
          handleModalClose={handleModalClose}
          selectedUser={selectedUser}
          invalidCount={invalidCount}
          CSVData={{ data: filteredData }}
          colMapping={colMapping}
          invalidRows={invalidRows}
          setSelectedUser={setSelectedUser}
          onSubmit={() => {
            setCurrentView(Views.LOADING);
            onSubmit();
          }}
        />
      );
      break;
    case Views.LOADING:
      CurrentVievComponent = <LoadingView />;
      break;
    case Views.NON_IMPORTED_USERS:
      CurrentVievComponent = (
        <NonImportedUsers
          disabled={disabled}
          nonImportedUser={nonImportedUser}
          invalidRows={invalidRows}
          handleModalClose={handleModalClose}
        />
      );
      break;
  }
  return <Modal>{CurrentVievComponent}</Modal>;
};
