import React, { useState } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components/macro';
import { last } from 'lodash';
import { CheckboxInput } from 'lib/components';
import { DropdownArrowIcon, DropdownIndicatorProps } from './Dropdown';
import { MdArrowDropDown } from 'react-icons/md';
import { theme } from 'lib/style';
import { Ellipsis } from '../Ellipsis';

const MenuHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  padding: 8px 24px;
  border-bottom: 1px solid ${theme.palette.gray20};
  cursor: pointer;
`;

const MenuList = (props: any) => {
  return (
    <components.MenuList {...props}>
      <MenuHeader
        onClick={() => {
          props.selectProps.onChange([
            {
              value: props.selectProps.menuListValue,
              label: `${props.selectProps.menuListLabel}`,
            },
          ]);
        }}
      >
        {props.selectProps.menuListLabel}
      </MenuHeader>
      {props.children}
    </components.MenuList>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  > label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: ${theme.palette.gray100};
    margin-left: 8px;
    cursor: pointer;
    width: 168px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <OptionWrapper>
          <CheckboxInput
            checked={props.isSelected}
            onChange={() => null}
            onClick={(event: React.MouseEvent<HTMLLabelElement>) => {
              event.preventDefault();
            }}
          />
          <label title={props.label}>{props.label}</label>
        </OptionWrapper>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, ...props }: any) => {
  const values = props.getValue();
  // check if searching so we can hide label and show only search input while searching
  const isSearching = props.selectProps.inputValue !== '';

  // to enable closing on outside click
  const selectOptionsAndInput = React.Children.map(children, child => {
    if (child && [components.SingleValue].indexOf(child.type) === -1) {
      return child;
    }
    return null;
  });

  let searchInput = last(selectOptionsAndInput);

  let text = `${props.selectProps.menuListLabel}`;
  if (values.length) {
    if (values[0].label.trim().length > 15) {
      text = `${values[0].label.trim().substring(0, 15) + '...'}`;
    } else {
      text = `${values[0].label}`;
    }
  }
  if (values.length > 1) text = `${values.length} ${props.selectProps.name}s`;

  return (
    <components.ValueContainer {...props}>
      {!isSearching && (
        <Ellipsis maxWidth={props.selectProps.valueContainerTextWidth}>
          {text}
        </Ellipsis>
      )}
      {searchInput}
    </components.ValueContainer>
  );
};

export type MultiselectDropdownOption = {
  label: string;
  value: string | number;
};

type Props = {
  onOptionsSelected: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  options: MultiselectDropdownOption[];
  selectInputWidth?: string;
  menuWidth?: string;
  showMenu?: boolean;
  setShowMenu?: (arg: boolean) => void;
  menuPortalTarget?: HTMLElement | null;
  menuShouldBlockScroll?: boolean;
  placeholder?: string;
  onMenuScrollToBottom?: () => void;
  isLoading: boolean;
  isDisabled?: boolean;
  menuListValue?: string | number;
  menuListLabel?: string;
  showIndicator?: boolean;
  name?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
};

export const MultiselectDropdown = ({
  onOptionsSelected,
  options,
  selectInputWidth = '200px',
  menuWidth,
  menuPortalTarget = document.body,
  menuShouldBlockScroll = true,
  placeholder,
  onMenuScrollToBottom,
  isLoading = false,
  isDisabled = false,
  menuListValue,
  menuListLabel,
  showIndicator = true,
  name,
  isSearchable = false,
  isClearable = false,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<
    MultiselectDropdownOption[]
  >([]);

  const onChangeOptions = (newValues: MultiselectDropdownOption[]) => {
    newValues = newValues || [];
    if (newValues[0]?.value === -1) {
      setSelectedOptions(options);
      onOptionsSelected(options.map(value => value.value));
      return;
    }
    setSelectedOptions(newValues);
    onOptionsSelected(newValues.map(value => value.value));
  };

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return showIndicator ? (
      <DropdownArrowIcon menuIsOpen={props.selectProps.menuIsOpen}>
        <MdArrowDropDown size={18} opacity={0.5} />
      </DropdownArrowIcon>
    ) : (
      <></>
    );
  };

  const calculateEllipsisWidth = (inputWidth: string | undefined) => {
    if (!inputWidth || typeof inputWidth !== 'string') {
      return 'auto';
    }

    if (inputWidth.includes('px')) {
      const numericWidth = parseInt(inputWidth, 10);
      if (!isNaN(numericWidth)) {
        return `${Math.max(0, numericWidth - 90)}px`;
      }
    }

    return 'auto';
  };

  return (
    <Select
      placeholder={placeholder}
      options={options}
      menuListValue={menuListValue}
      menuListLabel={menuListLabel}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      menuShouldBlockScroll={menuShouldBlockScroll}
      menuPortalTarget={menuPortalTarget}
      menuPosition='absolute'
      isClearable={isClearable}
      isSearchable={isSearchable}
      isLoading={isLoading}
      isDisabled={isDisabled}
      name={name}
      onMenuScrollToBottom={onMenuScrollToBottom}
      components={{
        Option,
        ValueContainer,
        IndicatorSeparator: () => null,
        MenuList,
        DropdownIndicator,
      }}
      // @ts-ignore
      onChange={value => onChangeOptions(value)}
      allowSelectAll={true}
      value={selectedOptions}
      valueContainerTextWidth={calculateEllipsisWidth(selectInputWidth)}
      styles={{
        menu: (base: any) => ({
          ...base,
          width: menuWidth || 'fit-content',
          left: 0,
          zIndex: 999,
        }),
        menuList: (base: any) => ({
          ...base,
          width: '100%',
          overflow: 'auto',
          maxHeight: '220px',
          marginBottom: '10px',
        }),
        menuPortal: (base: any) => ({
          ...base,
          zIndex: 9999,
        }),
        option: (base: any) => ({
          ...base,
          cursor: 'pointer',
          background: 'transparent',
          display: 'flex',
          justifyContent: 'flex-start',
          '&:hover': {
            background: 'transparent',
          },
          '&:active': {
            background: 'transparent',
          },
        }),
        control: (base: any) => ({
          ...base,
          border: '1px solid #D0D3D9',
          color: theme.palette.covideoGray100,
          borderRadius: 6,
          height: '40px',
          width: selectInputWidth,
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #001B53',
          },
        }),
      }}
    />
  );
};
