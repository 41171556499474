import { useMutation } from 'react-query';
import { uploadImage } from '../imageApi';

export const useUploadImages = (userId: string) => {
  return useMutation(
    async (images: { file: File | null; url: string | null }[]) => {
      const uploadPromises = images.map(async image => {
        if (image.file) {
          return await uploadImage(image.file, userId);
        }
        return image.url || null;
      });

      const uploadedUrls = await Promise.all(uploadPromises);
      return uploadedUrls;
    }
  );
};
