import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

interface IUpdateLeadParams {
  name: string;
  leadGroupId: number;
}

const updateLeadGroup = async (params: IUpdateLeadParams) => {
  const { leadGroupId, ...payload } = params;
  return await EXPRESS_API.put(`/lead-groups/${leadGroupId}`, { ...payload });
};

export const useUpdateLeadGroupMutation = () => {
  const { showError } = useToastError();
  return useMutation(updateLeadGroup, {
    onSuccess: () => {
      successToast({ title: 'Group has been updated!' });
    },
    onError: error =>
      showError(error, 'An error occured while trying to update the group!'),
  });
};
