import { FormikSwitchField } from 'lib/components/formik/FormikSwitchField';
import React from 'react';
import {
  FormProps,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';

export const WeeklyReportSection = (props: FormProps) => {
  const { setFieldValue, values } = props;
  return (
    <Section>
      <SectionTitle>Weekly Report</SectionTitle>
      <RowWrapper>
        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='weeklyReportEnabled'
          label='Enable Weekly Report'
          onChange={() => {
            setFieldValue('weeklyReportEnabled', !values.weeklyReportEnabled);
          }}
        />
      </RowWrapper>
    </Section>
  );
};
