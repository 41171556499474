import React from 'react';
import { NewModal } from 'lib/components';
import { IGroups } from 'lib/api/leadGroups/useLeadGroupsQuery';
import { GroupForm } from './form/GroupForm';

interface IProps {
  onModalClose: () => void;
  onSuccessCallback: () => void;
  editGroup: Omit<IGroups, 'userId' | 'leads'> | null;
}

export const ModalGroupForm = ({
  onModalClose,
  editGroup,
  onSuccessCallback,
}: IProps) => {
  const headerText = editGroup?.leadGroupId ? 'Edit group' : 'Create new group';

  return (
    <NewModal closeModal={onModalClose} headerText={headerText}>
      <GroupForm
        editGroup={editGroup}
        onSuccessCallback={onSuccessCallback}
        onModalClose={onModalClose}
      />
    </NewModal>
  );
};
