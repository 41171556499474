import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { IFetchUsersProfileResponse } from 'lib/api/users/useUsersProfileQuery';
import { ManageUsersHeader } from './MangeUsersHeader';
import { CaptionsNotEnabled } from './CaptionsNotEnabled';
import { AddonsForm } from './AddonsForm';
import { useEditUserMutation } from 'lib/api/users/useEditUserMutation';
import { useParams } from 'react-router';
import { LoadingIndicator } from 'lib/components';
import { useIsFetching } from 'react-query';
import { SHOW_TAB_CHANGE_POPUP } from './utils';

export interface AddonsFormikValues {
  transcriptionAccess: string;
  autoTranscribe: number;
  transcriptionDefaultEnabled: number;
}

export const AddonsTab = ({
  currentUser,
  children,
}: {
  currentUser: IFetchUsersProfileResponse | undefined;
  children: React.ReactChild;
}) => {
  const isFetching = useIsFetching();
  const { id } = useParams() as { id: string | undefined };
  const { mutateAsync: editMutation, isLoading } = useEditUserMutation();

  const onSubmitFormHandler = async (values: AddonsFormikValues) => {
    if (id) {
      await editMutation({
        data: {
          transcriptionAccess: !!values.transcriptionAccess,
          autoTranscribe: !!values.autoTranscribe,
          transcriptionDefaultEnabled: !!values.transcriptionDefaultEnabled,
        },
        userId: id,
      });
      localStorage.setItem(SHOW_TAB_CHANGE_POPUP, '0');
    }
  };

  useEffect(() => {
    localStorage.setItem(SHOW_TAB_CHANGE_POPUP, '0');
  }, []);

  if (isLoading || !!isFetching) {
    return (
      <div>
        <LoadingIndicator isLoading={true} />
      </div>
    );
  }

  const INITIAL_VALUES: AddonsFormikValues = {
    transcriptionAccess: !!currentUser?.transcriptionAccess
      ? 'enabled'
      : 'disabled',
    autoTranscribe: currentUser?.autoTranscribe || 0,
    transcriptionDefaultEnabled: currentUser?.transcriptionDefaultEnabled || 0,
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmitFormHandler}
      validateOnMount
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <ManageUsersHeader
            showActionButtons={!!currentUser?.transcriptionAccess}
            currentUser={currentUser}
          />
          {children}
          {currentUser?.transcriptionAccess ? (
            <AddonsForm />
          ) : (
            <CaptionsNotEnabled />
          )}
        </Form>
      )}
    </Formik>
  );
};
