import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router';
import { AiFillInfoCircle } from 'react-icons/ai';
import {
  MdFileDownload,
  MdLock,
  MdClose,
  MdSupervisorAccount,
  MdDeleteForever,
  MdOutlineAddToPhotos,
} from 'react-icons/md';
import { CgHashtag } from 'react-icons/cg';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import {
  Search,
  ButtonSwitch,
  Dropdown,
  CheckboxInput,
  ListDropdownMenu,
  LoadingIndicator,
  ModalDelete,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { HiPencil } from 'react-icons/hi';
import { getShortLink, sortSharedDrops } from 'lib/utils/drop';
import {
  EmptyDrops,
  SetPasswordModal,
  TagsModal,
  SetBoardsModal,
  RenameFileModal,
} from '../components';
import { FiPlus } from 'react-icons/fi';
import FileSharingModal from './FileSharingModal';
import { IoDocumentText } from 'react-icons/io5';
import { successToast } from 'lib/components/toasts/success';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import {
  Actions,
  ButtonWrapper,
  CheckboxGridWrapper,
  CheckboxInputWrapper,
  CheckboxListWrapper,
  DropdownContainer,
  EmptySearchContainer,
  GridContent,
  GridItem,
  GridThumbnail,
  GridView,
  LinkButton,
  ListAction,
  ListItem,
  ListThumbnail,
  ListTitle,
  ListUrl,
  ListView,
  Menu,
  SelectionText,
  SelectionWrapper,
  Tab,
  TabContainer,
  ThumbnailContainer,
  Title,
  Url,
} from './styles';
import AddNewDropModal from './AddNewDropModal';
import { useGetBoard } from 'lib/api/droplr/getBoard';
import {
  DEFAULT_DROP,
  DROPTYPE_OPTIONS,
  DROP_FILE_TYPES,
  DroplrViews,
  LIBRARY_VIEW,
  SortFields,
  getIconForFileType,
} from 'lib/const/Droplr';
import { useGetDrops } from 'lib/api/droplr/getDrops';
import { DropItem } from 'lib/api/droplr/types';
import { useEditDrop } from 'lib/api/droplr/editDrop';
import { useDeleteDrop } from 'lib/api/droplr/deleteDrop';
import { useGetCurrentDroplrUser } from 'lib/api/droplr/getCurrentDroplrUser';
import { useDownloadDrop } from 'lib/api/droplr/downloadDrop';
import { Button } from 'react-covideo-common';
import { BsThreeDots } from 'react-icons/bs';
import { IoMdLink } from 'react-icons/io';
import { SelectAllDropdown } from 'lib/components/dropdown/SelectAllDropdown';

const ICON_COLOR = '#99A4BA';

const SIZE_OPTIONS = [12, 24, 48, 96];

type Props = {
  boardId?: string;
  currentTab?: DROPTYPE_OPTIONS;
  excludeFilesTab?: boolean;
};

export const ListDrops = ({
  boardId,
  currentTab = DROPTYPE_OPTIONS.OWNED,
  excludeFilesTab = false,
}: Props) => {
  const { userData } = useAuth();
  const [view, setView] = React.useState(DroplrViews[0].value);
  const [field, setField] = React.useState(SortFields[0]);
  const [type, setType] = React.useState(DROP_FILE_TYPES[0]);
  const [menuOpenedForDrop, setMenuOpenedForDrop] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showPasswordModal, setShowPasswordModal] = React.useState(
    [] as string[]
  );

  const [showAddDropModal, setShowAddDropModal] = React.useState(false);

  const [showTagsModal, setShowTagsModal] = React.useState([] as string[]);
  const [showBoardsModal, setShowBoardsModal] = React.useState([] as string[]);
  const [showDeleteModal, setShowDeleteModal] = React.useState([] as string[]);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(12);
  const [selectedDrops, setSelectedDrops] = React.useState([] as string[]);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [showRenameFileModal, setShowRenameFileModal] = React.useState('');
  const [showFileSharingModal, setShowFileSharingModal] =
    React.useState<boolean>(false);
  const [dropIdToShare, setDropIdToShare] = React.useState('');
  const [dropType, setDropType] = React.useState<DROPTYPE_OPTIONS>(currentTab);
  const { mutateAsync: editDrop } = useEditDrop();
  const { mutateAsync: deleteDrop } = useDeleteDrop();
  const { mutateAsync: downloadDrop } = useDownloadDrop();
  const { data: droplrUser } = useGetCurrentDroplrUser();
  const { id: droplrUserId } = droplrUser || { id: '' };
  const { data: board } = useGetBoard({
    boardId: boardId || '',
    userId: Number(userData.id),
    customerId: Number(userData.customerId),
  });

  // Change the  DropType from shared to owned for a shared board
  const {
    data: dropData,
    isRefetching: dropsRefetching,
    isLoading: dropsLoading,
    refetch: refetchDrops,
  } = useGetDrops({
    sort: field.key,
    order: field.order,
    search: searchQuery,
    page,
    size,
    boardId,
    type: type.value,
    dropType: !boardId ? dropType : DROPTYPE_OPTIONS.OWNED,
  });
  let drops = dropData?.drops || ([] as DropItem[]);
  const count = dropData?.count || 0;

  let userStatus = '';
  if (boardId && board) {
    userStatus = board.userStatus || userStatus;
  } else {
    userStatus = 'owner';
  }

  if (dropType === DROPTYPE_OPTIONS.SHARED) {
    if (type.value !== 'all') {
      drops = drops.filter((drop: DropItem) => {
        return drop.type === type.value;
      });
    }

    sortSharedDrops(drops, field);
  }

  const history = useHistory();
  const themes = useTheme();

  React.useEffect(() => {
    const savedView = localStorage.getItem(LIBRARY_VIEW);
    if (savedView) {
      setView(savedView);
    }
    refetchDrops();
  }, []);

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setPage(0);
  };

  const copyLink = (drops: DropItem[]) => {
    const cb = navigator.clipboard;
    const links = drops.map(drop => getShortLink(drop)).join('\n');
    cb.writeText(links).then(() => {
      successToast({ title: `Link to file copied successfully!` });
    });
  };

  const goToDrop = (dropId: string) =>
    history.push(`/files/${dropId}/${dropType}`);

  const actionItems = [
    {
      title: 'Details',
      icon: <AiFillInfoCircle size={20} color={ICON_COLOR} />,
      onClick: (id: string) => goToDrop(id),
    },
    {
      title: 'Download',
      icon: <MdFileDownload size={20} color={ICON_COLOR} />,
      onClick: (id: string) =>
        downloadDrop({
          dropId: id,
        }),
    },
    {
      title: 'Rename',
      icon: <HiPencil size={20} color={ICON_COLOR} />,
      onClick: (id: string) => setShowRenameFileModal(id),
    },
    {
      title: 'Copy Shortlink',
      icon: <IoMdLink color={ICON_COLOR} size={20} />,
      onClick: (id: string) => {
        const drop = drops.find(d => d.code === id);
        if (drop) {
          copyLink([drop]);
        }
      },
    },
    {
      title: 'Set Privacy',
      icon: <MdLock size={20} color={ICON_COLOR} />,
      onClick: (id: string) => {
        setShowPasswordModal([id]);
      },
    },
    {
      title: 'Add to Board',
      icon: <MdOutlineAddToPhotos size={20} color={ICON_COLOR} />,
      onClick: (id: string) => {
        setShowBoardsModal([id]);
      },
    },
    {
      title: 'Add Tags',
      icon: <CgHashtag size={20} color={ICON_COLOR} />,
      onClick: (id: string) => {
        setShowTagsModal([id]);
      },
    },
    {
      title: 'Delete',
      icon: <MdDeleteForever color={ICON_COLOR} size={20} />,
      onClick: (id: string) => {
        setShowDeleteModal([id]);
      },
    },
  ];

  const getThumbnailForDrop = (drop: DropItem, view: string) => {
    if (drop.type === 'IMAGE' || drop.previewMedium) {
      return (
        <>
          {view === 'grid' && (
            <GridThumbnail
              image={drop.previewMedium}
              onClick={() => goToDrop(drop.code)}
            />
          )}
          {view === 'list' && (
            <ListThumbnail
              image={drop.previewSmall}
              onClick={() => goToDrop(drop.code)}
            />
          )}
        </>
      );
    }

    const variant = drop.variant;
    const icon = getIconForFileType(variant, view);
    const width = view === 'list' ? '96px' : '100%';
    const height = view === 'list' ? 'auto' : '120px;';
    return (
      <ThumbnailContainer
        width={width}
        height={height}
        onClick={() => goToDrop(drop.code)}
      >
        {icon}
      </ThumbnailContainer>
    );
  };

  const actionComponent = (drop: DropItem) => {
    const dropOwnerId: number = drop.owner;
    return (
      <Actions>
        <LinkButton onClick={() => copyLink([drop])}>Copy Link</LinkButton>
        {dropOwnerId === droplrUserId && (
          <>
            <LinkButton
              onClick={() => {
                setDropIdToShare(drop.code);
                setShowFileSharingModal(true);
              }}
            >
              Sharing
            </LinkButton>
            <ButtonWrapper>
              <BsThreeDots
                onClick={() =>
                  setMenuOpenedForDrop(
                    menuOpenedForDrop !== drop.code ? drop.code : ''
                  )
                }
                color={theme.palette.blue80}
                style={{ cursor: 'pointer' }}
              />
            </ButtonWrapper>

            <ListDropdownMenu
              isMenuOpen={menuOpenedForDrop === drop.code}
              items={actionItems}
              id={drop.code}
              setIsMenuOpen={() => setMenuOpenedForDrop('')}
            />
          </>
        )}
      </Actions>
    );
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const AddDropButton = () => (
    <Button
      text='Add New'
      onClick={() => setShowAddDropModal(true)}
      icon={<FiPlus />}
    />
  );

  const showLoading = updateLoading || dropsLoading || dropsRefetching;
  return (
    <>
      {showLoading && (
        <LoadingIndicator isLoading={showLoading} height='500px' />
      )}
      {!showLoading && (
        <>
          {!!!boardId && (
            <>
              <HelpDesk name={EHelpDesk.FILES} />
              <TabContainer color={themes.colors.primary[100]}>
                <Tab
                  className={
                    dropType === DROPTYPE_OPTIONS.OWNED ? 'activeTab' : ''
                  }
                  onClick={() => {
                    setSelectedDrops([]);
                    setDropType(DROPTYPE_OPTIONS.OWNED);
                  }}
                  title={'myFiles'}
                >
                  <IoDocumentText />
                  &nbsp;My Files
                </Tab>
                <Tab
                  className={
                    dropType === DROPTYPE_OPTIONS.SHARED ? 'activeTab' : ''
                  }
                  onClick={() => {
                    setSelectedDrops([]);
                    setDropType(DROPTYPE_OPTIONS.SHARED);
                  }}
                  title={'sharedWithMe'}
                >
                  <MdSupervisorAccount size={24} />
                  &nbsp;Shared with me
                </Tab>
              </TabContainer>
              <br />
            </>
          )}
          {(searchQuery ||
            type.value !== DROP_FILE_TYPES[0].value ||
            !!drops.length) && (
            <div>
              {!!selectedDrops.length && (
                <SelectionWrapper>
                  <CheckboxInputWrapper>
                    <CheckboxInput
                      blueCheck={true}
                      grayCheck={true}
                      checkGroupIndicator={
                        selectedDrops.length !== drops.length
                      }
                      checked={selectedDrops.length === drops.length}
                      onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                        event.stopPropagation();
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.checked) {
                          setSelectedDrops(drops.map(drop => drop.code));
                        } else {
                          setSelectedDrops([]);
                        }
                      }}
                    />
                    <SelectAllDropdown
                      onItemClick={() =>
                        setSelectedDrops(drops.map(drop => drop.code))
                      }
                    />
                    <SelectionText>
                      Files selected: {selectedDrops.length}
                    </SelectionText>
                  </CheckboxInputWrapper>

                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                      variant='secondary'
                      text='Download'
                      icon={<MdFileDownload />}
                      onClick={async () => {
                        for (let dropId of selectedDrops) {
                          const dropArrVal = drops.find(
                            drop => drop.code === dropId
                          );
                          await downloadDrop({
                            dropId,
                            fileName: dropArrVal?.title,
                          });
                        }
                        setSelectedDrops([]);
                      }}
                    />
                    <Button
                      variant='secondary'
                      text='Copy Links'
                      icon={<IoMdLink size={18} />}
                      onClick={() => {
                        const dropsToCopy = drops.filter((d: DropItem) =>
                          selectedDrops.includes(d.code)
                        );
                        copyLink(dropsToCopy);
                        setSelectedDrops([]);
                      }}
                    />
                    {!!boardId && dropType === DROPTYPE_OPTIONS.OWNED && (
                      <Button
                        variant='secondary'
                        text='Remove from Board'
                        icon={<MdClose size={24} />}
                        onClick={async () => {
                          setUpdateLoading(true);
                          const dropsToRemove = drops.filter((d: DropItem) =>
                            selectedDrops.includes(d.code)
                          );
                          for (let drop of dropsToRemove) {
                            const boards = (drop.boards || []).filter(
                              (board: string) => board !== boardId
                            );
                            await editDrop({
                              boards,
                              dropId: drop.code,
                            });
                          }
                          setSelectedDrops([]);
                          setUpdateLoading(false);
                          refetchDrops();
                        }}
                      />
                    )}
                    {!boardId && dropType === DROPTYPE_OPTIONS.OWNED && (
                      <>
                        <Button
                          variant='secondary'
                          text='Add Tags'
                          icon={<CgHashtag size={24} />}
                          onClick={() => {
                            setShowTagsModal(selectedDrops);
                            setSelectedDrops([]);
                          }}
                        />
                        <Button
                          variant='secondary'
                          text='Add Boards'
                          icon={<MdOutlineAddToPhotos size={20} />}
                          onClick={() => {
                            setShowBoardsModal(selectedDrops);
                            setSelectedDrops([]);
                          }}
                        />
                        <Button
                          variant='secondary'
                          icon={<MdLock />}
                          onClick={() => {
                            setShowPasswordModal(selectedDrops);
                            setSelectedDrops([]);
                          }}
                        />
                      </>
                    )}
                    {dropType === DROPTYPE_OPTIONS.OWNED && (
                      <Button
                        variant='destructive'
                        icon={<MdDeleteForever size={18} />}
                        onClick={() => {
                          setShowDeleteModal(selectedDrops);
                          setSelectedDrops([]);
                        }}
                      />
                    )}
                  </div>
                </SelectionWrapper>
              )}

              <Menu>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '220px' }}>
                    <Search
                      placeholder={`Search files...`}
                      onSearch={onSearch}
                      prevSearch={searchQuery}
                    />
                  </div>
                  {!boardId && (
                    <DropdownContainer>
                      <Dropdown
                        value={type}
                        onChange={newValue => {
                          setType(newValue);
                        }}
                        options={DROP_FILE_TYPES}
                        dropdownHeight={210}
                        height={40}
                      />
                    </DropdownContainer>
                  )}
                </div>
                <div style={{ display: 'flex', gap: '12px' }}>
                  {
                    <DropdownContainer>
                      <Dropdown
                        value={field}
                        onChange={newValue => {
                          setField(newValue);
                        }}
                        options={SortFields}
                        dropdownHeight={450}
                        height={40}
                      />
                    </DropdownContainer>
                  }
                  <ButtonSwitch
                    defaultValue={view}
                    values={DroplrViews}
                    onChange={newView => {
                      setView(newView);
                      localStorage.setItem(LIBRARY_VIEW, newView);
                    }}
                  />
                  {userStatus &&
                    userStatus !== 'readCollaborator' &&
                    dropType === DROPTYPE_OPTIONS.OWNED && <AddDropButton />}
                </div>
              </Menu>
              {!!drops.length && (
                <>
                  {view === 'grid' && (
                    <GridView>
                      {drops.map((drop: DropItem) => {
                        const checked = !!selectedDrops.find(
                          id => id === drop.code
                        );

                        if (
                          dropType === DROPTYPE_OPTIONS.SHARED &&
                          searchQuery
                        ) {
                          if (
                            drop.title
                              .toLocaleLowerCase()
                              .search(searchQuery.toLowerCase()) < 0
                          ) {
                            return <React.Fragment key={drop.code} />;
                          }
                        }
                        return (
                          <GridItem key={drop.code}>
                            {getThumbnailForDrop(drop, view)}
                            <CheckboxGridWrapper>
                              <CheckboxInput
                                className={'whiteCheckBox'}
                                checked={checked}
                                onChange={() => {
                                  if (checked) {
                                    setSelectedDrops(
                                      selectedDrops.filter(
                                        id => id !== drop.code
                                      )
                                    );
                                  } else {
                                    setSelectedDrops([
                                      ...selectedDrops,
                                      drop.code,
                                    ]);
                                  }
                                }}
                              />
                            </CheckboxGridWrapper>
                            <GridContent>
                              <Title onClick={() => goToDrop(drop.code)}>
                                {drop.title}
                              </Title>
                              <Url style={{ marginTop: '8px' }}>
                                {drop.shortlink}
                              </Url>
                              <div style={{ marginTop: '18px' }}>
                                {actionComponent(drop)}
                              </div>
                            </GridContent>
                          </GridItem>
                        );
                      })}
                    </GridView>
                  )}
                  {view === 'list' && (
                    <ListView>
                      {drops.map((drop: DropItem) => {
                        const checked = !!selectedDrops.find(
                          id => id === drop.code
                        );

                        if (
                          dropType === DROPTYPE_OPTIONS.SHARED &&
                          searchQuery
                        ) {
                          if (
                            drop.title
                              .toLocaleLowerCase()
                              .search(searchQuery.toLowerCase()) < 0
                          ) {
                            return <React.Fragment key={drop.code} />;
                          }
                        }
                        return (
                          <ListItem key={drop.code}>
                            <CheckboxListWrapper>
                              <CheckboxInput
                                className={'whiteCheckBox'}
                                checked={checked}
                                onChange={() => {
                                  if (checked) {
                                    setSelectedDrops(
                                      selectedDrops.filter(
                                        id => id !== drop.code
                                      )
                                    );
                                  } else {
                                    setSelectedDrops([
                                      ...selectedDrops,
                                      drop.code,
                                    ]);
                                  }
                                }}
                              />
                            </CheckboxListWrapper>
                            {getThumbnailForDrop(drop, view)}
                            <ListTitle onClick={() => goToDrop(drop.code)}>
                              <Title>{drop.title}</Title>
                            </ListTitle>
                            <ListUrl>
                              <Url>{drop.shortlink}</Url>
                            </ListUrl>
                            <ListAction>{actionComponent(drop)}</ListAction>
                          </ListItem>
                        );
                      })}
                    </ListView>
                  )}
                  {count > 0 && (
                    <TableContextProvider
                      total={count}
                      initSize={size}
                      initPage={page}
                      onChange={onPaginationChange}
                      extraData={{ searchQuery }}
                    >
                      <TableFooter>
                        <TablePaginationNew />
                        <TablePaginationSizeNew
                          globalName='drops_list'
                          sizeOptions={SIZE_OPTIONS}
                        />
                      </TableFooter>
                    </TableContextProvider>
                  )}
                </>
              )}
              {!drops.length && (
                <EmptySearchContainer>
                  {searchQuery && (
                    <h1>
                      There are no files with "{searchQuery}" in the title
                    </h1>
                  )}
                  {!searchQuery && type.value !== DROP_FILE_TYPES[0].value && (
                    <div>
                      <h1>You don’t have any files of selected type</h1>
                      {userStatus &&
                        userStatus !== 'readCollaborator' &&
                        dropType === DROPTYPE_OPTIONS.OWNED && (
                          <AddDropButton />
                        )}
                    </div>
                  )}
                </EmptySearchContainer>
              )}
            </div>
          )}
          {!searchQuery &&
            type.value === DROP_FILE_TYPES[0].value &&
            !drops.length && (
              <>
                <EmptyDrops
                  boardId={boardId}
                  onUploadDropModalClosed={refetchDrops}
                  dropType={dropType}
                  excludeFilesTab={excludeFilesTab}
                />
              </>
            )}
        </>
      )}
      {!!showDeleteModal.length && (
        <ModalDelete
          whiteButtonText='Yes, Delete'
          orangeButtonText='No, Cancel'
          title={
            showDeleteModal.length === 1
              ? `You want to move file to recently deleted bin?`
              : `You want to move selected files to recently deleted bin?`
          }
          text={`Files are automatically deleted after 30 days.`}
          handleModalClose={() => setShowDeleteModal([])}
          onClickWhiteButton={async () => {
            setShowDeleteModal([]);
            setUpdateLoading(true);
            try {
              for (let dropId of showDeleteModal) {
                await deleteDrop(dropId);
              }
              successToast({
                title:
                  'You have successfully moved files to recently deleted bin',
              });
              await refetchDrops();
            } catch (error) {
              console.log(error);
            }
            setUpdateLoading(false);
          }}
          onClickOrangeButton={() => setShowDeleteModal([])}
        />
      )}
      {!!showPasswordModal.length && (
        <SetPasswordModal
          dropIds={showPasswordModal}
          handleModalClose={() => setShowPasswordModal([])}
        />
      )}
      {!!showTagsModal.length && (
        <TagsModal
          dropIds={showTagsModal}
          handleModalClose={() => setShowTagsModal([])}
        />
      )}
      {!!showBoardsModal.length && (
        <SetBoardsModal
          dropIds={showBoardsModal}
          handleModalClose={() => setShowBoardsModal([])}
        />
      )}
      {showRenameFileModal && (
        <RenameFileModal
          drop={
            drops.find(drop => drop.code === showRenameFileModal) ||
            DEFAULT_DROP
          }
          handleModalClose={() => {
            refetchDrops();
            setShowRenameFileModal('');
          }}
        />
      )}

      {showFileSharingModal && (
        <FileSharingModal
          drop={drops.find(drop => drop.code === dropIdToShare) || DEFAULT_DROP}
          handleModalClose={() => {
            setDropIdToShare('');
            setShowFileSharingModal(false);
          }}
        />
      )}

      {showAddDropModal && (
        <AddNewDropModal
          boardId={boardId}
          closeModal={() => {
            setShowAddDropModal(false);
          }}
          refreshDrops={refetchDrops}
          excludeFilesTab={excludeFilesTab}
        />
      )}
    </>
  );
};
