import { useQuery } from 'react-query';
import { wheelsTvKeys } from './wheelsTvKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { PaginationConstants } from 'lib/const/PaginationConstants';

export type WTVListItem = {
  wtvId: number;
  year: string;
  make: string;
  model: string;
  style: string;
  trim: string;
  wtvVideoId: string;
  bodyStyle: string;
  vehicleClass: string;
  animatedGifPath: string;
  createdAt: string;
};

export enum WtvSortKey {
  CREATED_AT = 'createdAt',
  YEAR = 'year',
  MAKE = 'make',
  MODEL = 'model',
}

export type WTVListParams = {
  start: number;
  limit: number;
  page: number;
  search?: string;
  order?: PaginationConstants;
  filter?: { [key: string]: string[] };
  sortKey?: WtvSortKey;
};

export const getWTVVideos = async (
  props: WTVListParams
): Promise<{
  videos: WTVListItem[];
  count: number;
}> => {
  const params = {
    start: props.start,
    limit: props.limit,
    search: props.search,
    order: props.order,
    sortKey: props.sortKey,
    filter: props.filter,
  };

  return (
    await EXPRESS_API.get(`/wtv`, {
      params,
    })
  ).data;
};

export const useWheelsTvVideosQuery = (params: WTVListParams) => {
  return useQuery(wheelsTvKeys.all(params), () => getWTVVideos(params), {
    refetchOnMount: true,
  });
};
