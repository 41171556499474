import { SORT_KEYS } from 'app/pages/reports/leads/main/MainLeadReports';
import { EXPRESS_API } from 'configs/api/expressApi';
import saveAs from 'file-saver';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { useMutation } from 'react-query';
import { LeadOpportunitiesBaseParams } from './types';

export interface LeadOpportunitiesDownloadParamsParams
  extends LeadOpportunitiesBaseParams {
  start?: number;
  limit?: number;
  sortKey?: SORT_KEYS;
  order?: PaginationConstants;
}

const getLeadOpportunutiesDownload = async ({
  params,
}: {
  params: LeadOpportunitiesDownloadParamsParams;
}): Promise<void> => {
  const response = await EXPRESS_API.post(
    `/lead-opportunities/metrics/download`,
    {},
    {
      params,
      responseType: 'blob',
    }
  );
  const contentType = response.headers['content-type'];

  saveAs(new Blob([response.data], { type: contentType }), `report.csv`);
};

export const useLeadOpportunutiesDownloadMutation = () => {
  return useMutation(getLeadOpportunutiesDownload, {
    onSuccess: () => {
      successToast({
        title: 'Lead Opportunities Report Downloaded',
      });
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred while downloading the lead opportunities report. Please try again.',
      });
    },
  });
};
