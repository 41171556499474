import { TableField } from 'lib/context';
import dayjs from 'dayjs';

export enum HistoryTableColumns {
  DATE = 'dateSent',
  RECIPIENTS = 'email',
  TIME_FRAME = 'dateRange',
  NAME = 'report',
  ACTION = 'action',
}

export const detailsTableFields: TableField[] = [
  {
    value: HistoryTableColumns.NAME,
    label: 'Report Name',
    width: 240,
  },
  {
    value: HistoryTableColumns.DATE,
    label: 'Sent Date',
    sortable: true,
    sortKey: 'DATE',
    width: 280,
  },
  {
    value: HistoryTableColumns.RECIPIENTS,
    label: 'Recipient(s)',
    width: 400,
  },
  {
    value: HistoryTableColumns.TIME_FRAME,
    label: 'Timeframe',
    width: 280,
  },
  {
    value: HistoryTableColumns.ACTION,
    label: '',
    height: '100%',
    width: 50,
  },
];

export enum CustomReportsTableColumns {
  REPORT_NAME = 'title',
  TYPE = 'type',
  RECIPIENTS = 'recipients',
  DATE = 'date',
  SYNC = 'sync',
  DELIVERY = 'delivery',
}

export const customReportTableFields: TableField[] = [
  {
    value: CustomReportsTableColumns.REPORT_NAME,
    label: 'Report Name',
    sortable: true,
    sortKey: 'REPORT_NAME',
    width: 170,
    height: '100%',
  },
  {
    value: CustomReportsTableColumns.TYPE,
    label: 'Type',
    sortable: true,
    width: 105,
    height: '100%',
  },
  {
    value: CustomReportsTableColumns.DELIVERY,
    label: 'Delivery',
    width: 105,
    height: '100%',
  },
  {
    value: CustomReportsTableColumns.RECIPIENTS,
    label: 'Recipients',
    width: 270,
  },
  {
    value: CustomReportsTableColumns.DATE,
    label: 'Timeframe',
    width: 150,
  },
  {
    value: CustomReportsTableColumns.SYNC,
    label: '',
    height: '100%',
  },
];

export const formatCustomReportDate = (value?: string) => {
  if (!value) {
    return new Date();
  }

  return dayjs(value, 'YYYY-MM-DD').toDate();
};
