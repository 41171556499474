import React from 'react';
import { Form, Formik } from 'formik';
import { SalesPagesHeaderForm } from './SalesPagesHeaderForm';
import { Button } from 'react-covideo-common';
import { SalesPagesValues } from './types';
import { SalesPagesBodyForm } from './SalesPagesBodyForm';
import { IoMdSave } from 'react-icons/io';
import { Gap } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { useAuth } from 'lib/context';
import { SalesPagesCTAForm } from './SalesPagesCTAForm';
import { SalesPagesSocialIconsForm } from './SalesPagesSocialIconsForm';
import { SalesPagesFooterForm } from './SalesPagesFooterForm';

export const SalesPagesForm = ({
  initialValues,
  onSubmitHandler,
}: {
  initialValues: SalesPagesValues;
  onSubmitHandler: (values: SalesPagesValues) => Promise<void>;
}) => {
  const { userData } = useAuth();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, dirty }) => {
        return (
          <Form>
            <Gap width='100%' justifyContent='space-between'>
              <Heading>
                {userData.isAutomotiveServiceRole ? 'Service' : 'Sales'} Pages
              </Heading>
              <Button
                text={isSubmitting ? 'Saving' : 'Save changes'}
                type='submit'
                disabled={isSubmitting || !dirty}
                icon={<IoMdSave />}
                onClick={submitForm}
              />
            </Gap>
            <SalesPagesHeaderForm />
            <SalesPagesBodyForm />
            <SalesPagesCTAForm />
            <SalesPagesSocialIconsForm />
            <SalesPagesFooterForm />
          </Form>
        );
      }}
    </Formik>
  );
};
