import React from 'react';
import { useParams, useHistory } from 'react-router';
import { TabsManager } from '../../customers/components/TabsManager';
import { LoadingIndicator } from 'lib/components';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { UserDetailsHeader } from '../components/UserDetailsHeader';
import {
  addUser,
  editUser,
  getCustomer,
  getResellerDetails,
  getUserDetails,
} from 'lib/api/superAdminApi';
import { UserStatusChangeConfirmationModal } from '../components/UserStatusChangeConfirmationModal';
import { Formik, FormikProps } from 'formik';
import { UserDetailsForm } from '../components/UserDetailsForm';
import {
  UserDetailsData,
  SuperAdminCustomer,
  Reseller,
  useAuth,
} from 'lib/context';
import * as Yup from 'yup';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { ResellerReportRoles } from 'lib/const/SuperAdminRole';
import {
  COVIDEO_RESELLER_ID,
  AUTOMOTIVE_RESELLER_ID,
  SCREEN_VALUES,
  PASSWORD_ERROR_MESSAGE,
  NAPLETON_AUTOMOTIVE_RESELLER_ID,
} from 'lib/const/SuperAdminConstants';
import {
  generatePrettyPassword,
  validateInputForCsvFormulaInjection,
  validPassword,
} from 'lib/utils/functions';

import { PASSWORD_REGEX } from 'lib/utils/regexes';
import {
  messages,
  PASSWORD_FORMAT_ERROR,
} from 'app/pages/account/userAccount/editMyProfile/components';
import { AccountChanges } from '../../customers/components/accountChanges/AccountChanges';
import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
import { useToastError } from 'lib/hooks';
import { WHITE_TEMPLATE_ID } from 'lib/const/templates';
import { useQueryClient } from 'react-query';
import { superAdminKeys } from 'lib/api/superadmin/superAdminKeys';
import { MainUserDetailsWrapper } from './MainUserDetailsWrapper';

enum TABS {
  USER_DETAILS,
  LOCATION,
  ACTIVITY,
}

const fieldsSchema = Yup.object({
  phone1: Yup.string()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v))
    .min(7, 'Please enter a phone number equal or longer than 7 characters.')
    .test(
      'isNotSuspicious',
      'phone number is not valid',
      function (value = '') {
        if (!value) {
          return true;
        }

        return validateInputForCsvFormulaInjection(value);
      }
    ),
  cellPhone: Yup.string()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v))
    .min(
      7,
      'Please enter a cell phone number equal or longer than 7 characters.'
    )
    .test(
      'isNotSuspicious',
      'Cell phone number is not valid',
      function (value = '') {
        if (!value) {
          return true;
        }

        return validateInputForCsvFormulaInjection(value);
      }
    ),
  email: Yup.string().required('Email is required').email('Email is not valid'),
  email2: Yup.string().email('Email is not valid'),
  username: Yup.string()
    .trim()
    .required('Username is required')
    .min(2, 'Username is too small'),
  firstName: Yup.string()
    .required('First name is required')
    .test('isNotSuspicious', 'First name is not valid', function (value = '') {
      if (!value) {
        return true;
      }

      return validateInputForCsvFormulaInjection(value);
    }),
  lastName: Yup.string().when(['resellerId'], (resellerId: number) => {
    if (resellerId === NAPLETON_AUTOMOTIVE_RESELLER_ID) {
      return Yup.string()
        .nullable()
        .transform((v, o) => (o === '' ? undefined : v))
        .min(2, 'Last name is too small')
        .test(
          'isNotSuspicious',
          'Last name is not valid',
          function (value = '') {
            if (!value) {
              return true;
            }

            return validateInputForCsvFormulaInjection(value);
          }
        );
    }
    return Yup.string()
      .required('Last name is required')
      .min(2, 'Last name is too small')
      .test('isNotSuspicious', 'Last name is not valid', function (value = '') {
        if (!value) {
          return true;
        }

        return validateInputForCsvFormulaInjection(value);
      });
  }),
  password: Yup.string().when(['id'], (id: number) => {
    if (!id || id === 0) {
      return Yup.string()
        .min(8, PASSWORD_ERROR_MESSAGE)
        .matches(PASSWORD_REGEX, PASSWORD_FORMAT_ERROR)
        .required('Please enter a valid password.');
    }
  }),
});

// to-do use const
let fields: UserDetailsData = {
  firstName: '',
  lastName: '',
  username: '',
  password: generatePrettyPassword(),
  email: '',
  email2: '',
  phone1: '',
  cellPhone: '',
  phone2: '',
  title: '',
  departmentId: -1,
  departmentName: '',
  access: 1,
  manualPassword: false,
  transcriptionAccess: false,
  qraAccess: false,
  customerId: -1,
  signupDate: '',
  trialAccount: false,
  verified: false,
  isResetPassword: true,
  mobileSMSAccess: true,
  resellerId: 0,
  resellerAdmin: 0,
  guideCategoriesAdministration: false,
  template: WHITE_TEMPLATE_ID,
};

const loadFields = (data: UserDetailsData) => {
  return {
    firstName: data.firstName || '',
    lastName: data.lastName || '',
    username: data.username || '',
    password: '',
    email: data.email || '',
    email2: data.email2 || '',
    phone1: data.phone1 || '',
    cellPhone: data.cellPhone || '',
    phone2: data.phone2 || '',
    title: data.title || '',
    departmentId: data.departmentId,
    departmentName: data.departmentName,
    access: data.access || 1,
    manualPassword: false,
    verified: data.verified || false,
    trialAccount: data.trialAccount || false,
    qraAccess: data.qraAccess || false,
    transcriptionAccess: data.transcriptionAccess || false,
    customerId: data.customerId || -1,
    signupDate: data.signupDate || '',
    mobileSMSAccess: data.hasOwnProperty('mobileSMSAccess')
      ? data.mobileSMSAccess
      : true,
    id: data.id || 0,
    resellerId: data.resellerId || 0,
    resellerAdmin: data.resellerAdmin || 0,
    automotiveRole: data.automotiveRole,
    guideCategoriesAdministration: data.guideCategoriesAdministration || false,
  };
};

export const Details = () => {
  const { showError } = useToastError();
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [newStatus, setNewStatus] = React.useState<
    VerificationStatus | undefined
  >(undefined);
  const [isEditView, setIsEditView] = React.useState(false);
  const formRef = React.useRef<FormikProps<UserDetailsData>>();

  // to_do remove state
  const [user, setUser] = React.useState<UserDetailsData>(
    fields as UserDetailsData
  );

  // to_do after implementing react-query this can be probably removed ****
  const [customer, setCustomer] = React.useState({} as SuperAdminCustomer);
  const [customerId, setCustomerId] = React.useState(-1);
  const [reseller, setReseller] = React.useState<Reseller | undefined>();
  const [resellerId, setResellerId] = React.useState(0);
  const [customerName, setCustomerName] = React.useState('');
  const [initialEmail, setInitialEmail] = React.useState('');
  // ****
  // to-do remove
  const [config, setConfig] = React.useState({
    title: 'New User',
    isValidUser: true,
    showAddView: false,
    user: {},
  });
  const [activeTab, setActiveTab] = React.useState(TABS.USER_DETAILS);
  const tabs = [
    { value: TABS.USER_DETAILS, label: 'User Details' },
    { value: TABS.ACTIVITY, label: 'Activity' },
  ];

  // to_do check how to submit and use formik setErros
  const [optionalError, setOptionalError] = React.useState<{
    message?: string;
    password?: string;
  }>({});

  let { id, fromScreen, cusId } = useParams() as {
    id: string;
    fromScreen: string;
    cusId: string;
  };
  const queryClient = useQueryClient();
  const isIdNumeric = /^[0-9]+$/.test(id);
  const history = useHistory();
  const { userData } = useAuth();
  const goToUserList = () => history.push(`/admin/users`);

  const goToCustomerDetails = () =>
    fromScreen === SCREEN_VALUES.RESELLERS
      ? history.push(
          `/admin/customers/${customerId}/${SCREEN_VALUES.RESELLERS}/${resellerId}`
        )
      : history.push(
          `/admin/customers/${customerId}/${SCREEN_VALUES.CUSTOMERS}/${resellerId}`
        );

  // to_do bind add react-query
  const loadUser = async () => {
    setLoading(true);
    try {
      if (cusId) {
        const customerDetails = await getCustomer(parseInt(cusId, 10));
        if (ResellerReportRoles.includes(userData.superAdminRole)) {
          const reseller = await getResellerDetails(
            parseInt(customerDetails.resellerId, 10)
          );
          setReseller(reseller);
          setResellerId(reseller.resellerId);
        }
        setCustomerName(customerDetails.business);
        setCustomerId(customerDetails.customerId);
        setConfig({
          title: 'New User',
          isValidUser: false,
          showAddView: true,
          user: {},
        });
        setIsEditView(false);
      }
      if (!id || !isIdNumeric) {
        setConfig({
          title: 'New User',
          isValidUser: false,
          showAddView: true,
          user: {},
        });
        setIsEditView(false);
        fields = {
          ...fields,
          ...loadFields({} as UserDetailsData),
          password: generatePrettyPassword(),
          customerId: Number(cusId || userData.customerId),
        };
        setUser({ ...fields });
        if (cusId) {
          setUser({ ...fields, customerId: Number(cusId) });
        }
      }
      if (id && isIdNumeric) {
        const res = await getUserDetails(parseInt(id, 10));

        setInitialEmail(res?.email);
        if (res.customerId) {
          try {
            const customerDetails = await getCustomer(
              parseInt(res.customerId, 10)
            );
            setCustomer(customerDetails);
            setCustomerId(customerDetails.customerId);
            setCustomerName(customerDetails.business);
          } catch (err) {}
        }
        setConfig({
          title: `${res.firstName} ${res.lastName}`,
          isValidUser: false,
          showAddView: false,
          user: res,
        });
        fields = { ...fields, ...loadFields(res) };
        setUser(fields);
        setIsEditView(true);
      }
      setLoading(false);
    } catch (error) {
      setConfig({
        title: '',
        isValidUser: false,
        showAddView: false,
        user: {},
      });
      setIsEditView(false);
      fields = { ...fields, ...loadFields({} as UserDetailsData) };
      setUser(fields);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadUser();
  }, []);

  if (loading) {
    return <LoadingIndicator isLoading={loading} />;
  }

  // to_do bind only with yup validation
  const prepareDataToSend = async (value: any) => {
    return new Promise(async (resolve, reject) => {
      const payload: any = {};
      const error: any = { message: '' };
      if (
        isEditView &&
        !!value.password.length &&
        (value.password.length < 8 || !validPassword(value.password))
      ) {
        error['password'] =
          value.password.length < 8
            ? Array.isArray(messages.password)
              ? messages.password[0]
              : ''
            : PASSWORD_FORMAT_ERROR;
        error['message'] +=
          (error['message'].length ? ', ' : '') +
          Array.isArray(messages.password)
            ? messages?.password[0]
            : '';
      }
      if (Object.keys(error).length > 1 && error.message != '')
        return reject(error);
      return resolve(payload);
    });
  };

  // to_do  formik on submit
  async function handleSave(
    reloadUserOnSave: boolean = true
  ): Promise<boolean> {
    let saveSuccessfull = false;
    if (formRef.current) {
      const updatedUserValues = formRef.current.values;
      if (
        updatedUserValues.resellerId === COVIDEO_RESELLER_ID ||
        updatedUserValues.resellerId === AUTOMOTIVE_RESELLER_ID
      ) {
        delete updatedUserValues.resellerAdmin;
      }
      updatedUserValues.automotiveRole = Number(
        updatedUserValues.automotiveRole
      );
      if (!isEditView) {
        delete updatedUserValues.id;
      }
      try {
        await fieldsSchema.validate(updatedUserValues);
      } catch (error) {
        setOptionalError(error);
        errorToast({
          title: error.message || `Some field have error or missing`,
        });
        return saveSuccessfull;
      }
      if (reloadUserOnSave) {
        setLoading(true);
      }

      updatedUserValues.username = updatedUserValues.username.trim();
      await prepareDataToSend(updatedUserValues);
      setOptionalError({});
      try {
        if (isEditView) {
          const isEmailEdited = updatedUserValues.email !== initialEmail;
          const res = await editUser({
            id,
            data: updatedUserValues,
            changeOrigin: CHANGE_ORIGIN.adminUserDetails,
          });

          if (res) {
            if (isEmailEdited) {
              // query cannot be invalidated because it contains enabled flag so it has to be removed instead
              queryClient.removeQueries({
                queryKey: [
                  superAdminKeys.all_invite_users()[0],
                  {
                    customerId: user.customerId,
                  },
                ],
                exact: false,
              });
            }
            successToast({ title: 'User updated successfully' });
            saveSuccessfull = true;
          }
          if (reloadUserOnSave) {
            await loadUser();
          }
          setLoading(false);
          return saveSuccessfull;
        }

        const res = await addUser(
          updatedUserValues,
          CHANGE_ORIGIN.adminUserDetails
        );
        if (res) {
          successToast({
            title: `New User saved successfully.`,
          });
          saveSuccessfull = true;
          fromScreen === SCREEN_VALUES.CUSTOMERS
            ? goToCustomerDetails()
            : goToUserList();
        }
        setLoading(false);
      } catch (error) {
        showError(error);
        setLoading(false);
      }
    }
    return saveSuccessfull;
  }
  if (isEditView && activeTab === TABS.ACTIVITY) {
    return (
      <MainUserDetailsWrapper
        reseller={reseller}
        resellerId={resellerId}
        fromScreen={fromScreen}
        customerId={customerId}
        loading={loading}
        isEditView={isEditView}
        config={config}
        customer={customer}
        customerName={customerName}
      >
        <UserDetailsHeader user={user} isEditView={isEditView} />
        {isEditView && (
          <TabsManager
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabTitlePadding='0'
            tabTitleMargin='0 0px 0 0'
            roundedBorder={false}
          />
        )}
        <AccountChanges
          targetCustomerId={customer.customerId}
          targetUserId={user.id}
        />
      </MainUserDetailsWrapper>
    );
  }

  return (
    <MainUserDetailsWrapper
      reseller={reseller}
      resellerId={resellerId}
      fromScreen={fromScreen}
      customerId={customerId}
      loading={loading}
      isEditView={isEditView}
      config={config}
      customer={customer}
      customerName={customerName}
    >
      <Formik
        initialValues={user}
        onSubmit={() => {}}
        validate={() => {}}
        validationSchema={fieldsSchema}
        enableReinitialize
        innerRef={ref => {
          if (ref) {
            formRef.current = ref as FormikProps<UserDetailsData>;
          }
        }}
      >
        {props => (
          <>
            <UserDetailsHeader
              user={user}
              onSave={() => handleSave()}
              disableSaveButton={loading || !props.dirty}
              onStatusChange={(value: VerificationStatus) => {
                setNewStatus(value);
                setShowStatusModal(true);
              }}
              isEditView={isEditView}
            />
            {isEditView && (
              <TabsManager
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabTitlePadding='0'
                tabTitleMargin='0 0px 0 0'
                roundedBorder={false}
              />
            )}

            {!isEditView && (
              <UserDetailsForm
                {...props}
                isNewUser={!isEditView}
                resellerId={user.resellerId}
                customerName={customerName}
                optionalError={optionalError}
                onSave={reloadUserOnSave => handleSave(reloadUserOnSave)}
              />
            )}
            {isEditView && activeTab === TABS.USER_DETAILS && (
              <UserDetailsForm
                {...props}
                isNewUser={!isEditView}
                resellerId={user.resellerId}
                customerName={customerName}
                optionalError={optionalError}
                onSave={reloadUserOnSave => handleSave(reloadUserOnSave)}
              />
            )}
            {isEditView && activeTab === TABS.ACTIVITY && (
              <AccountChanges
                targetCustomerId={customer.customerId}
                targetUserId={user.id}
              />
            )}
          </>
        )}
      </Formik>

      {showStatusModal && (
        <UserStatusChangeConfirmationModal
          user={user}
          newStatus={newStatus ?? VerificationStatus.ACTIVE}
          handleModalClose={refresh => {
            setNewStatus(undefined);
            setShowStatusModal(false);
            if (refresh) {
              loadUser();
            }
          }}
        />
      )}
    </MainUserDetailsWrapper>
  );
};
