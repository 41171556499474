import React from 'react';
import { Container, Gap, MainWrapper } from 'lib/components/styles/layout';
import { Button, Tooltip } from 'react-covideo-common';
import { Heading } from 'lib/components/styles/typography';
import { useGuideDetailsQuery } from 'lib/api/guides/guideDetailsQuery';
import { LoadingIndicator } from 'lib/components';
import { VideoPlayer } from '../../../video/videoPlayer';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import ThumbsUpIcon from 'assets/images/videoReactions/ThumbsUpIcon';
import ThumbsDownIcon from 'assets/images/videoReactions/ThumbsDownIcon';
import { useRateGuideQuery } from 'lib/api/guides/rateGuideQuery';
import { Links } from 'lib/api/guides/guidesQuery';
import { useHistory } from 'react-router-dom';
import { NotFound } from 'app/pages/notFound/NotFound';
import GuideBreadcrumbs from '../../components/GuideBreadcrumbs';
import { MdEdit } from 'react-icons/md';

interface GuideTitleHeadingProps {
  maxWidth?: string;
}

export const GuideTitleHeading = styled(Heading)<GuideTitleHeadingProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ maxWidth }) => maxWidth || '400px'};
`;

const Title = styled.div`
  display: flex;
  font-family: Work Sans;
  color: ${theme.palette.covideoBlue100};
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  font-weight: ${theme.fontNormal600};
  width: auto;
`;

const TipsWrapper = styled.div`
  display: flex;
  width: 228px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 16px;
  cursor: pointer;
  max-width: 228px;
`;

const Tip = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${theme.fontNormal400};
  text-decoration-line: underline;
  color: ${theme.palette.neutral100};
  align-self: stretch;
  width: auto;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 748px;
  padding: 12px;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  font-family: Work Sans;
  background: ${theme.palette.neutral10};
  width: 668px;
  margin-top: 16px;
  overflow-wrap: break-word;
`;

const DescriptionTitle = styled.div`
  font-weight: ${theme.fontNormal600};
  font-size: 16px;
  color: ${theme.palette.gray100};
`;

const DescriptionDate = styled.div`
  font-weight: ${theme.fontNormal400};
  font-size: 14px;
  color: ${theme.palette.neutral80};
  margin: auto;
`;
const DescriptionContent = styled.div`
  font-weight: ${theme.fontNormal400};
  font-size: 16px;
  color: ${theme.palette.neutral80};
  line-height: 24px;
  align-self: stretch;
`;

const RatingWrapper = styled.div`
  display: inline-flex;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  font-weight: ${theme.fontNormal400};
  font-size: 16px;
  color: ${theme.palette.neutral100};
`;

export const GuideDetailsPage = () => {
  const history = useHistory();
  const { data, isLoading } = useGuideDetailsQuery();
  const { mutateAsync: rateGuide, isLoading: isFeedbackGiven } =
    useRateGuideQuery();

  if (isLoading) {
    return <LoadingIndicator isLoading={true} />;
  }

  if (!isLoading && !data) {
    return <NotFound />;
  }

  return (
    <Container>
      <MainWrapper resetPadding>
        {data && (
          <GuideBreadcrumbs
            items={[
              { path: '/guides', name: 'Training', isDisabled: false },
              {
                path: `/guides/${data?.guideCategory.guideCategoryId}`,
                name: data?.guideCategory?.name || '',
                isDisabled: false,
              },
              {
                path: null,
                name: data?.name || '',
                isDisabled: true,
              },
            ]}
            styles={{ marginTop: '96px', marginBottom: '24px' }}
          />
        )}
        <Gap alignItems='center' justifyContent='space-between'>
          <Tooltip
            extendStyles={{
              popup: {
                padding: '10px',
              },
            }}
            popup={<>{data?.name}</>}
          >
            <GuideTitleHeading maxWidth='600px'>{data?.name}</GuideTitleHeading>
          </Tooltip>

          {data?.isEditable && (
            <Gap gap='0'>
              <Button
                onClick={() =>
                  history.push(
                    '/classic/edit-video/' + data?.videoId + '/details'
                  )
                }
                text='Edit Training Video'
                variant='primary'
                icon={<MdEdit color={theme.palette.white} size={'20px'} />}
              />
            </Gap>
          )}
        </Gap>
        <Gap m='32px 0 0 0' alignItems='flex-start' gap='32px' width='100%'>
          <VideoPlayer
            playerBackgroundColor={data?.video.playerBackgroundColor}
            videoSource={data?.video.videoSource || ''}
            videoId={data?.video.id.toString()}
            borderRadius='16px'
            playButtonPosition={data?.video.playButtonPosition}
            playerIconsColor={data?.video.playerIconsAndTextColor}
            videoRef={React.createRef()}
            thumbnail={data?.customThumbnail || data?.video?.thumbnail}
            width='690px'
            height='413px'
            videoChapters={data?.video?.chapters?.map(
              (val: { playbackPosition: number; title: string }) => ({
                playbackPosition: val.playbackPosition,
                title: val.title,
              })
            )}
          />

          {!!data?.links?.length && (
            <div>
              <Title>Help, tips and resources</Title>
              <TipsWrapper>
                {data?.links.map((link: Links, index: number) => (
                  <Tip
                    key={`tip-${index}`}
                    onClick={() => window.open(link.link, '_blank')}
                  >
                    {link.label}
                  </Tip>
                ))}
              </TipsWrapper>
            </div>
          )}
        </Gap>

        <Gap>
          <DescriptionWrapper>
            <Gap alignItems='flex-start' gap='16px'>
              <DescriptionTitle>Description</DescriptionTitle>
              <DescriptionDate>
                {dayjs(data?.createdAt).format('MM/DD/YYYY hh:mm A')}
              </DescriptionDate>
            </Gap>
            <DescriptionContent>
              {data?.description || 'No description provided.'}
            </DescriptionContent>
          </DescriptionWrapper>
        </Gap>
        <Gap gap='2px'>
          <RatingWrapper>
            {isFeedbackGiven && <>Thank you for the feedback.</>}
            {data?.isGuideRated && !isFeedbackGiven && (
              <>You had rated this guide. Thank you.</>
            )}
            {!data?.isGuideRated && !isFeedbackGiven && (
              <>
                Was this guide helpful?
                <Button
                  onClick={async () => {
                    await rateGuide('up');
                  }}
                  text=''
                  variant='transparent'
                  icon={<ThumbsUpIcon />}
                  disabled={isLoading || isFeedbackGiven}
                />
                <Button
                  onClick={async () => {
                    await rateGuide('down');
                  }}
                  text=''
                  variant='transparent'
                  icon={<ThumbsDownIcon />}
                  disabled={isLoading || isFeedbackGiven}
                />
              </>
            )}
          </RatingWrapper>
        </Gap>
      </MainWrapper>
    </Container>
  );
};
