import { IUserData } from 'lib/api/users/getUser';
import { regionResellers } from 'lib/const/RegionsReseller';
import { ICombinedUserData } from 'lib/context';
import { InternalCustomers } from '../const/InternalCustomers';
import spectrumAutomotiveHoldingsMcDavid from 'assets/images/virtualBackgrounds/spectrum-automotive-holdings/McDavid.webp';
import spectrumAutomotiveHoldingsSpectrum from 'assets/images/virtualBackgrounds/spectrum-automotive-holdings/Spectrum.webp';
import spectrumAutomotiveHoldingsVanguard from 'assets/images/virtualBackgrounds/spectrum-automotive-holdings/Vanguard.webp';

type ProductFeatureType = { [key: string]: number };

type ProductFeatureDataType = {
  [key: number]: {
    title: string;
    description: string;
  };
};

export const productFeature: ProductFeatureType = {
  FILES_AND_BOARDS: 1,
  WEB_RECORDER: 2,
  CHROME_EXTENSION: 3,
  MOBILE_APPS: 4,
  OUTLOOK_LEGACY: 5,
  OUTLOOK_365_INTEGRATION: 6,
  SALESFORCE_INTEGRATION_BASIC: 7,
  SALESFORCE_INTEGRATION_NATIVE: 8,
  ZOOM_INTEGRATION: 9,
  VIRTUAL_BACKGROUNDS: 10,
  FOLDER_ORGANIZATION: 11,
  SHARED_FOLDER: 12,
  VIDEO_EDITING: 13,
  VIDEO_MERGE: 14,
  CUSTOM_THUMBNAILS: 15,
  GIF_THUMBNAILS: 16,
  ANNOTATIONS: 17,
  CAPTIONS: 18,
  LINK_SHARING: 19,
  CRM_AND_SOCIAL_SHARING: 20,
  VIDEO_EMBEDS: 21,
  WEBSITE_OVERLAY: 22,
  BUILT_IN_SMS: 23,
  SMS_ADMIN_CONTROLS: 24,
  NATIVE_SOCIAL_VIDEOS: 25,
  VIDEO_EXPIRATION: 26,
  VIDEO_REPLY: 27,
  LANDING_PAGE_BUILDER: 28,
  CTAS: 29,
  CUSTOM_BRANDING: 30,
  VIEW_NOTIFICATIONS: 31,
  HOTSPOTS_AND_ANALYTICS: 32,
  REPORTS: 33,
  VIDEO_PLAYER_APPEARANCE: 34,
  VIDEO_ANALYTICS_REPORTS: 35,
  PASSWORD_PROTECTION: 36,
  GMAIL_INTEGRATION: 37,
  USER_ROLES_AND_MANAGEMENT: 38,
  MANAGE_USERS_AS_TEAMS: 39,
  SSO: 40,
  SCIM_PROVISIONING: 41,
  SOC_2: 42,
  PLAYBACK_DOMAIN: 43,
  WTV: 44,
  REPAIR_ORDER_APPROVAL: 45,
  INVENTORY: 46,
  VIDEO_ATTRIBUTES: 47,
  NOTIFICATION_CENTER: 48,
  VIDEO_TAGS: 49,
  TELEPROMPTER: 50,
  ELEMENT451_INTEGRATION: 51,
  ELEAD_INTEGRATION: 52,
  CHAT_SUPPORT: 53,
  LINKEDIN: 54,
  HUBSPOT: 55,
  VINSOLUTIONS: 58,
  IPACKET: 59,
  AFFILIATE: 60,
  CODIRECT: 61,
  FACEBOOK: 62,
  YOUTUBE: 63,
  DEALERSOCKET_INTEGRATION: 64,
  CHAPTERS: 65,
  CONVERSATIONS: 66,
  VIDEO_VOICEOVER: 67,
  QR_CODE: 68,
  SECURITY: 69,
  REACTIONS_AND_COMMENTS: 70,
  SCHEDULES: 71,
  TEMPLATES: 72,
  INTERACTIVE_CTAS: 73,
  FOLDER_SHARING: 74,
  LIVE_VIDEO: 75,
};

export enum VideoLimitStatus {
  NOT_REACHED = 1,
  REACHED = 2,
  EXCEEDED = 3,
}

export const VIDEO_LIMIT_REACHED = [
  VideoLimitStatus.REACHED,
  VideoLimitStatus.EXCEEDED,
];

export const checkIfFeatureIsEnabled = (
  userData: ICombinedUserData,
  featureId: string | number
): boolean => {
  if (!featureId) {
    return false;
  }

  if (userData && userData.user && userData.user.packageDetails) {
    // legacy user has access to all features
    if (userData.user.packageDetails.id === 0) {
      return true;
    }

    if (
      !userData.user.packageDetails.products ||
      !userData.user.packageDetails.products.length
    ) {
      return false;
    }

    return userData.user.packageDetails.products.includes(featureId);
  }

  return false;
};

export const isVideoCountValid = (
  userData: ICombinedUserData,
  videoCount: number | undefined,
  checkEqual?: boolean
): boolean => {
  const totalVideoCount = videoCount !== undefined ? videoCount : 0;
  if (userData && userData.user && userData.user.packageDetails) {
    // legacy user has access to all features
    if (userData.user.packageDetails.id === 0) {
      return true;
    }

    if (
      userData.user.packageDetails.maxVideosCreated !== 0 &&
      (totalVideoCount > userData.user.packageDetails.maxVideosCreated ||
        (!!checkEqual &&
          totalVideoCount >= userData.user.packageDetails.maxVideosCreated))
    ) {
      return false;
    }

    return true;
  }

  return false;
};

export const isInternalCustomer = (
  userData: Partial<ICombinedUserData>
): boolean => {
  const internalCustomers: string[] = [
    InternalCustomers.COVIDEO,
    InternalCustomers.AUTOMOTIVE,
    InternalCustomers.DEV,
    InternalCustomers.QA_COVIDEO,
  ];
  return !!(
    userData.customerId && internalCustomers.includes(userData.customerId)
  );
};

export const checkIfMultipleAttendeesAvailable = (
  userData: ICombinedUserData | null
) => {
  return Number(userData?.customer?.liveAccess) === 2;
};

export const checkAttendeeLimit = (userData: ICombinedUserData | null) => {
  return checkIfMultipleAttendeesAvailable(userData) ? 20 : 2;
};

export const checkGuideCategoriesAdministrationAccess = (
  userData: ICombinedUserData | null
) => {
  return userData?.isCompanyAdmin && userData?.guideCategoriesAdministration;
};

export const isRegionsReseller = (userData: IUserData | undefined): boolean => {
  if (userData !== undefined) {
    return !!(
      userData.customer &&
      userData.customer.resellerId &&
      regionResellers.find(
        regReseller =>
          regReseller.toString() === userData?.customer?.resellerId.toString()
      )
    );
  }
  return false;
};

export const productFeatureData: ProductFeatureDataType = {
  1: {
    title: 'Files & Boards',
    description:
      'Host files, organize them on boards, and share at ease with links.',
  },
  5: {
    title: 'Outlook - Standard',
    description: 'Record and send your Covideos directly inside Outlook.',
  },
  6: {
    title: 'Outlook 365',
    description: 'Record and send your Covideos directly inside Outlook.',
  },
  7: {
    title: 'Salesforce Integration - basic',
    description:
      'Automatically track the sending of your Covideo back to your Salesforce.',
  },
  8: {
    title: 'Salesforce Integration - native',
    description:
      'Record, send and track your Covideos directly inside Salesforce. Pre-built Salesforce reports and dashboard included.',
  },
  9: {
    title: 'Zoom Integration',
    description:
      'Automatically upload, save and host your Zoom recordings to your Covideo account for instantly shareable, trackable content. Unlimited video storage!',
  },
  10: {
    title: 'Virtual Backgrounds',
    description:
      'Select from a library of high-resolution images, or upload your own for extra personalization.',
  },
  11: {
    title: 'Folder Organization',
    description:
      'Assign videos to folders to manage your video library more efficiently.',
  },
  12: {
    title: 'Shared Folder (Company Folder)',
    description:
      'Access and share video content between users connected through the same account.',
  },
  14: {
    title: 'Video Merge',
    description:
      'Combine multiple videos into one to scale your video messages in a snap.',
  },
  17: {
    title: 'Annotations',
    description:
      'Add text, graphics or hyperlinked calls-to-action to your videos.',
  },
  18: {
    title: 'Captions & Translations',
    description:
      'Auto-generate closed captioning for your videos and allow viewers to select the language of their choice for on-demand subtitles.',
  },
  21: {
    title: 'Video Embeds',
    description:
      'Easily grab HTML code to embed your videos into your website.',
  },
  22: {
    title: 'Website Overlay',
    description:
      'Drive traffic to important sites by hovering your video on top of a URL of your choice.',
  },
  23: {
    title: 'Built-In SMS',
    description:
      'Message customers all from one, unique local number dedicated specifically to your company. Up to 2,500 outgoing texts each month.',
  },
  26: {
    title: 'Video Expiration',
    description:
      'Control how long your videos are active and viewable by setting an expiration date.',
  },
  27: {
    title: 'Video Reply',
    description:
      'Allow recipients to respond with a video of their own – no Covideo account required.',
  },
  28: {
    title: 'Video Landing Page Customization (Builder)',
    description:
      'Create custom pages on the fly with our no-code video landing page builder.',
  },
  29: {
    title: 'Calls-To-Action',
    description:
      'Drive engagement and provide clear next steps with customizable buttons that accompany your videos',
  },
  30: {
    title: 'Remove Covideo Branding',
    description:
      'Remove Covideo logo and copyright from your video player and landing page.',
  },
  32: {
    title: 'Analytics',
    description:
      'Created for individual videos, analytics shows exactly who is watching your video when and how many times, as well as which calls-to-action they’ve clicked.',
  },
  33: {
    title: 'Reports',
    description:
      ' Easily pull reports by timeframe to see important, high-level stats like videos recorded, total views, CTA clicks, average engagement rate, and more.',
  },
  34: {
    title: 'Appearance',
    description: 'Control the color and design of your video player.',
  },
  36: {
    title: 'Password Protect Videos',
    description: 'Secure your videos by assigning a password of your choosing.',
  },
  40: {
    title: 'SSO (SAML)',
    description:
      'Make activating and deactivating your user’s accounts simple and streamlined with single-sign-on.',
  },
  47: {
    title: 'Attributes',
    description:
      'Capture additional video characteristics and properties such as type of video, purpose, RO number, and more.',
  },
  49: {
    title: 'Tags',
    description:
      'Create custom categories and tag your videos to stay organized and make finding recordings a snap.',
  },
  50: {
    title: 'Telemprompter',
    description:
      'Create and read your own scripts while recording videos so you can nail it on your first take.',
  },
  51: {
    title: 'Element451',
    description: 'Record and send your Covideo directly inside Element451.',
  },
  53: {
    title: 'Chat Support',
    description: 'Chat directly with a live Covideo representative.',
  },
  65: {
    title: 'Video Chapters',
    description: 'Easily organize your content with video chapters.',
  },
  66: {
    title: 'Conversations',
    description:
      'Message customers all from one, unique local number dedicated specifically to your company. Up to 2,500 outgoing texts each month.',
  },
  67: {
    title: 'Voiceover',
    description: 'Easily record a voiceover for your videos.',
  },
  68: {
    title: 'QR Codes',
    description:
      'Create QR codes for your Covideos, your website or social media accounts',
  },
  69: {
    title: 'Password Protect Videos',
    description: 'Secure your videos by assigning a password of your choosing',
  },
  70: {
    title: 'Comments & Reactions',
    description: `Say exactly what's on your mind with real-time emoji reactions and time-stamped text comments to boost engagement with your viewers.`,
  },
  71: {
    title: 'Schedules',
    description:
      'Schedule your videos to be sent out at a time that suits you or your recipients.',
  },
  72: {
    title: 'Templates',
    description:
      'Send your video emails more efficiently with pre-saved templates',
  },
  73: {
    title: 'Interactive CTAs',
    description: `Increase you video engagement with interactive CTA's`,
  },
  74: {
    title: 'Folder Sharing',
    description:
      'Seamlessly share video folders with your colleagues, with 3 permission levels.',
  },
  75: {
    title: 'Live Video',
    description: 'Easily schedule live video calls with prospective customers!',
  },
};

export const PackageNameLabel = {
  FREE: 'Free',
  PRO: 'Pro',
  TEAMS: 'Teams',
  ENTERPRISE: 'Enterprise',
};

export const checkIfConversationsAvailable = (userData: ICombinedUserData) => {
  return (
    userData.customerId.toString() === 'removed conversations for all customers'
  );
};

export const checkIfCustomerIsDev = (userData: ICombinedUserData) => {
  return (
    userData.customerId.toString() === InternalCustomers.DEV ||
    userData.customerId.toString() === InternalCustomers.AUTOMOTIVE
  );
};

export const checkIfPreloadedTeleprompterScriptsAvailable = (
  userData: ICombinedUserData
) => {
  return userData?.isAutomotiveSalesRole;
};

export const checkIfUniqueSentReportTypeAvailable = (
  userData: ICombinedUserData
) => {
  return (
    userData.customerId.toString() === InternalCustomers.DEV ||
    userData.customerId.toString() === InternalCustomers.QA_COVIDEO ||
    userData.customerId.toString() === InternalCustomers.AUTOMOTIVE ||
    userData.customerId.toString() === '66977' || // Ancira nissan
    userData.customerId.toString() === '32918' // Ancira Chevrolet
  );
};

export const shouldNavigateToManageUsers = (userData: ICombinedUserData) => {
  return (
    userData.customerId.toString() === InternalCustomers.DEV ||
    userData.customerId.toString() === InternalCustomers.QA_COVIDEO
  );
};

export const FORCED_VIRTUAL_BACKGROUND_OPTIONS: {
  [customerId: string]: string[];
} = {
  // Spectrum Automotive Holdings
  '25288': [
    spectrumAutomotiveHoldingsMcDavid,
    spectrumAutomotiveHoldingsSpectrum,
    spectrumAutomotiveHoldingsVanguard,
  ],
};
