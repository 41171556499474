import { THEME_NAME, useAuth } from 'lib/context';
import brandingLogoDark from 'assets/icons/logo/brandingLogoDark.svg';
import cdkLogoDark from 'assets/icons/logo/cdkLogoDark.svg';
import cdkLogoWhite from 'assets/icons/logo/cdkLogoWhite.svg';
// Define the type for the logo options
type LogoOption = 'dark' | 'dark-with-default';

export const useLogoUrl = (logoType?: LogoOption): { logoUrl: string } => {
  const { whitelabel, themeName } = useAuth();

  // Constants for logo URLs
  const DEFAULT_PATH = `https://${whitelabel?.domainV2}${whitelabel?.contentPath}`;

  const DEFAULT_LOGO_URL = `${DEFAULT_PATH}/images/logo-secondary.svg`;
  if (logoType === 'dark') {
    return {
      logoUrl: themeName === THEME_NAME.CDK ? cdkLogoDark : brandingLogoDark,
    };
  }

  if (logoType === 'dark-with-default') {
    if (whitelabel?.name === 'Covideo') {
      return { logoUrl: brandingLogoDark };
    }
    if (themeName === THEME_NAME.CDK) {
      return { logoUrl: cdkLogoWhite };
    }
    return { logoUrl: DEFAULT_LOGO_URL };
  }

  // all whitelabels logos
  return {
    logoUrl: themeName === THEME_NAME.CDK ? cdkLogoWhite : DEFAULT_LOGO_URL,
  };
};
