import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { InventoryItem } from 'lib/context';
import { priceFormatter } from 'lib/utils/functions';

const Container = styled.div`
  box-sizing: border-box;
  width: 384px;
  border: 1px solid #eeeff2;
  border-radius: 7px;
  padding: 24px 16px 16px;
`;
const PriceWrapper = styled.div`
  padding-left: 104px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${theme.palette.blue60};
`;
const Price = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${theme.palette.blue100};
`;
const Row = styled.div`
  display: flex;
  margin-bottom: 4px;
  column-gap: 8px;
`;
const Label = styled.div`
  width: 96px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
`;
const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
`;

type Props = {
  item: InventoryItem;
};

const fields = [
  {
    value: 'vin',
    label: 'VIN',
  },
  {
    value: 'stockNumber',
    label: 'Stock #',
  },
  {
    value: 'itemCondition',
    label: 'Condition',
  },
  {
    value: 'engine',
    label: 'Engine',
  },
  {
    value: 'make',
    label: 'Make',
  },
  {
    value: 'model',
    label: 'Model',
  },
  {
    value: 'trim',
    label: 'Trim',
  },
  {
    value: 'year',
    label: 'Year',
  },
  {
    value: 'mileage',
    label: 'Mileage',
  },
  {
    value: 'exteriorColor',
    label: 'Ext. Color',
  },
  {
    value: 'interiorColor',
    label: 'Int. Color',
  },
  {
    value: 'transmission',
    label: 'Transmission',
  },
  {
    value: 'manufacturer',
    label: 'Manufacturer',
  },
  {
    value: 'vehicleType',
    label: 'Vehicle Type',
  },
];

export const ItemDetails = ({ item }: Props) => {
  const priceAvailable =
    !!item.price && !!parseInt(item.price) && !isNaN(parseInt(item.price));
  return (
    <Container>
      <PriceWrapper>
        {priceAvailable && (
          <>
            Price <Price>{priceFormatter(parseInt(item.price))}</Price>
          </>
        )}
        {!priceAvailable && <Price>Please call for price</Price>}
      </PriceWrapper>
      {fields.map((field, i) => {
        const value = item[field.value as keyof InventoryItem];
        return value ? (
          <Row key={i}>
            <Label>{field.label}</Label>
            <Value>{value}</Value>
          </Row>
        ) : null;
      })}
    </Container>
  );
};
