import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-covideo-common';
import { get } from 'lodash';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import Select from 'react-select';
import {
  CheckboxInput,
  Modal,
  Table,
  Search,
  LoadingIndicator,
} from 'lib/components';
import DragAndDrop from '../inputs/DragAndDrop';
import { MdAdd, MdClose, MdInfo, MdLaunch, MdWarning } from 'react-icons/md';
import { AiFillInfoCircle } from 'react-icons/ai';
import placeholder from 'assets/images/table/placeholder.webp';
import { ContactListItem } from '../../api/types';
import { validateInputForCsvFormulaInjection } from 'lib/utils/functions';
import { useContactsQuery } from 'lib/api/contacts/useContactsQuery';
import { useGetOptOutContacts } from 'lib/api/contacts/getOptOutContacts';
import { useQueryGroups } from 'lib/api/group/useQueryGroups';
import { useCreateGroupMutation } from 'lib/api/group/useCreateGroupMutation';
import { useIsMutating } from 'react-query';
import { IoMdClose } from 'react-icons/io';

const SAMPLE_LENGTH = 1;
const FIRST_NAME_REG = new RegExp('first|^name$', 'ig');
const LAST_NAME_REG = new RegExp('last|surname', 'ig');
const COMPANY_NAME_REG = new RegExp('company', 'ig');
const PHONE_REG = new RegExp('phone', 'ig');
const EMAIL_REG = new RegExp('email', 'ig');
const FAX_REG = new RegExp('fax', 'ig');
const STREET_REG = new RegExp('street|address', 'ig');
const CITY_REG = new RegExp('city', 'ig');
const STATE_REG = new RegExp('state', 'ig');
const ZIP_REG = new RegExp('zip|postal', 'ig');
const COUNTRY_REG = new RegExp('country', 'ig');

const HEADER_REG_VALUES = [
  { field: 'firstName', reg: FIRST_NAME_REG },
  { field: 'lastName', reg: LAST_NAME_REG },
  { field: 'companyName', reg: COMPANY_NAME_REG },
  { field: 'phone', reg: PHONE_REG },
  { field: 'email', reg: EMAIL_REG },
  { field: 'fax', reg: FAX_REG },
  { field: 'address', reg: STREET_REG },
  { field: 'city', reg: CITY_REG },
  { field: 'state', reg: STATE_REG },
  { field: 'zip', reg: ZIP_REG },
  { field: 'country', reg: COUNTRY_REG },
];
const FieldOptions = [
  { value: 'None', label: 'Choose...' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'email', label: 'Email' },
  { value: 'companyName', label: 'Company' },
  { value: 'phone', label: 'Phone Number' },
  { value: 'fax', label: 'Fax' },
  { value: 'address', label: 'Street' },
  { value: 'city', label: 'City' },
  { value: 'state', label: 'State' },
  { value: 'zip', label: 'Zip' },
  { value: 'country', label: 'Country' },
];

const FieldLength = {
  firstName: 25,
  lastName: 25,
  email: 255,
  phone: 25,
  companyName: 50,
  fax: 25,
  street: 30,
  city: 20,
  state: 20,
  zip: 10,
  country: 30,
};

type Props = {
  disabled?: boolean;
  title?: string;
  text?: any;
  handleModalClose: () => void;
  handleSubmit: (
    newContacts: object,
    updatedContacts: any,
    updatedGroupsOnlyContacts: any
  ) => void;
  handleUpdateGroupSubmit?: (
    updateContacts: NewContactProps[],
    addedGroups: NewContactGroup[]
  ) => void;
  modalType?: string;
};

type csvDataProps = {
  data: string[][];
  uploaded?: boolean;
};

type colOptionProps = {
  label: string;
  value: string;
};

type BaseContact = {
  companyName?: string;
  contactId: number;
  email: string;
  fax?: string;
  firstName: string;
  lastName: string;
  notes?: string;
  phone?: string;
  phone2?: string;
  userId: number;
};

export type NewContactGroup = {
  groupId: number | string;
  name: string;
  userId: number;
  contacts?: BaseContact[];
};

export type NewContactProps = {
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  phone?: string;
  groups: NewContactGroup[];
};

type groupOptionsProps = {
  value: any;
  label: string;
};

const EMAIL_VALIDATION_REG = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const Label = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;

const TextInput = styled.input`
  display: flex;
  padding-left: 8px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-right: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  &:focus {
    outline: 0;
    border-color: ${theme.palette.primaryDarkBlue};
  }
`;

const ModalItem = styled.div`
  min-height: 528px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: auto;
  background-color: #fff;
  margin: 32px;
`;

const Content = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
`;
const ContentHeaderWrap = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  max-width: 100%;
  overflow: none;
`;

const ContentFooter = styled.div`
  span {
    opacity: 0.75;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.palette.themeDark};
  }
  p {
    opacity: 0.6;
    font-size: 11px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: 0.1px;
    margin-left: 22px;

    b {
      opacity: 0.8;
      font-size: 13px;
      margin-left: 8px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 12px;
`;

const Checkbox = styled(CheckboxInput)`
  float: left;
  margin-right: 8px;
  display: inline;
`;

const Form = styled.form`
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

const Col = styled.div`
  overflow: hidden;
  width: 100%;
`;

const IAgree = styled.div`
  height: 24px;
  margin: 0 0 0 12px;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
`;

const NoteSpan = styled.span`
  float: left;
  width: 528px;
  margin: 16px 0 16px 0;
`;

const ErrorMessage = styled.div`
  background-color: ${theme.palette.secondaryRedDanger};
  height: 48px;
  width: 100%;
  margin-top: 5px;
  span {
    display: flex;
    color: ${theme.palette.primaryRedDanger};
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 4;
    letter-spacing: normal;
    text-transform: uppercase;
  }
  svg {
    margin: 8px 16px 0 8px;
  }
`;

const NoGroupsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 208px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
`;

const NoGroups = styled.div`
  height: 24px;
  ${theme.fontBold700}
  font-size: 18px;
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
`;

const TableCellHeader = styled.div`
  text-align: left;
`;

const TableCell = styled.div`
  padding-right: 8px;
  text-align: left;
`;

const TableCellRight = styled.div`
  text-align: right;
`;

const Text = styled.div`
  color: ${theme.palette.buttonDanger};
`;

const SelectInput = styled(Select)`
  width: 200px;
  border-radius: 4px;
`;

const InfoMsg = styled.div`
  width: 944px;
  margin: 8px 0 24px 0;
  padding: 20px 48px 20px 16px;
  border-radius: 5px;
  background-color: rgba(182, 224, 236, 0.2);
`;

const InfoMsgHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
`;

const InfoMsgBody = styled.div`
  width: 844px;
  height: 60px;
  margin: 8px 0 0 38px;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.palette.black_1_100};
`;

const InfoBox = styled.span`
  background: ${theme.palette.primaryThemeColor10Percent};
  color: ${theme.palette.coal};
  font-weight: 500;
  font-size: 15px;
  padding: 12px;
  border-radius: 5px;
  display: flex;
  margin-bottom: 24px;
  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0 0 0 12px;
    color: ${theme.palette.coal};
    font-size: 16px;
  }
  span {
    margin: 0 0 0 8px;
    color: ${theme.palette.coal};
    font-size: 14px;
  }
`;

const MatchingListWrapper = styled.div`
  background: ${theme.palette.gray10};
  border-radius: 5px;
  padding: 16px;
  height: 312px;
  overflow-y: auto;
  margin-top: 16px;
`;
const MatchingListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
  div:nth-child(1) {
    margin-right: 8px;
  }
  div:nth-child(2) {
    margin-right: auto;
  }
  div:nth-child(2) {
    margin-left: auto;
  }
`;

const CloseWrapper = styled.div<{ disabled: boolean }>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const ContactLink = styled.span`
  color: ${theme.palette.title};
  &:hover {
    cursor: pointer;
  }
`;

const initialCSVData: csvDataProps = { data: [], uploaded: false };
const initialColOptions: colOptionProps[] = [];
const initialColMapping: { [key: string]: string } = {
  firstName: 'None',
  lastName: 'None',
  email: 'None',
  phone: 'None',
  fax: 'None',
  address: 'None',
  city: 'None',
  state: 'None',
  zip: 'None',
  country: 'None',
};

const initialColSampleData: { [key: string]: string[] } = {};
const initialContact: NewContactProps = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  phone: '',
  groups: [],
};
const initialGroupOptions: groupOptionsProps[] = [];
const initialInvalidRows: { [key: string]: boolean } = {};
const initialDuplicatedRows: { [key: string]: string[] } = {};
const initialMatchingRows: { [key: string]: boolean } = {};

enum Views {
  MAPPING = 1,
  UPDATE_MATCHING = 2,
  ADD_TO_GROUP = 3,
  IMPORT = 4,
  CREATE = 5,
  LOADING = 6,
}

export const ModalAddContact = ({
  modalType = 'one',
  disabled = false,
  title = '',
  text = '',
  handleModalClose,
  handleSubmit,
  handleUpdateGroupSubmit,
}: Props) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  const [currentView, setCurrentView] = useState<Views>(
    modalType == 'one' ? Views.CREATE : Views.IMPORT
  );
  const [createGroup, setCreateGroup] = useState(false);
  const [contact, setContact] = React.useState(initialContact);
  const [agreed, setAgreed] = useState(false);
  const [CSVData, setCSVData] = useState(initialCSVData);
  const [colOptions, setColOptions] = useState(initialColOptions);
  const [colMapping, setColMapping] = useState(initialColMapping);
  const [colSamples, setColSamples] = useState(initialColSampleData);
  const [uploadError, setUploadError] = useState(false);
  const [emptyFileError, setEmptyFileError] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [_, setSelectableGroups] = useState(initialGroupOptions);
  const [group, setGroup] = useState({
    name: '',
  });
  const [contactValid, setContactValid] = useState(false);
  const [existingContact, setExistingContact] = useState<
    ContactListItem | undefined
  >(undefined);
  const [submitting, setSubmitting] = React.useState(false);

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
  });
  const [emailAndPhoneMissingError, setEmailAndPhoneMissingError] =
    useState('');
  const [invalidRows, setInvalidRows] = useState(initialInvalidRows);
  const [duplicatedCsvIndex, setDuplicatedCsvIndex] = useState(
    {} as { [key: string]: boolean }
  );
  const [invalidCount, setInvalidCount] = useState(0);
  const [duplicateCount, setDuplicateCount] = useState(0);
  const [duplicatedRows, setDuplicatedRows] = useState(initialDuplicatedRows);
  const [matchingRows, setMatchingRows] = useState(initialMatchingRows);
  const [matchingContacts, setMatchingContacts] = useState([] as any[]);
  const [selectedMatching, setSelectedMatching] = useState([] as any[]);
  const [isDoNotUpdateSelected, setIsDoNotUpdateSelected] = useState(false);
  const [updateGroupContacts, setUpdateGroupContacts] = useState([] as any[]);

  const [groupSearch, setGroupSearch] = useState('');
  const GROUP_SIZE = 100;

  const groupsQueryParams = {
    search: groupSearch,
    size: GROUP_SIZE,
  };

  const { data: groupsData } = useQueryGroups(groupsQueryParams);
  const groupItems = groupsData?.items ?? [];

  const { data: contactsResult } = useContactsQuery(
    {
      search: contact.email,
      size: 100000,
    },
    true
  );

  const { mutateAsync: addGroupMutation } = useCreateGroupMutation(
    undefined,
    groupsQueryParams
  );

  const contactsData = {
    items: contactsResult?.contacts || [],
    count: contactsResult?.count || 0,
  };

  const { data } = useGetOptOutContacts({
    search: contact.email,
    limit: 100000,
  });

  const emptyOptOutContactsArray = React.useMemo(() => [], []);
  const optOutContacts = {
    items: data?.optOutContacts || emptyOptOutContactsArray,
    count: data?.count || 0,
  };

  const history = useHistory();

  const themes = useTheme();

  const onModalClose = () => {
    setMatchingContacts([]);
    setSelectedMatching([]);
    handleModalClose();
  };

  const onCreateGroup = async (event: MouseEvent) => {
    if (event && event.detail && event.detail > 1) {
      return;
    }
    let addToGroup: any =
      groupItems &&
      groupItems.find(
        (g: { name: string }) =>
          g.name.toLowerCase() === group.name.toLowerCase()
      );
    if (!addToGroup) {
      addToGroup = await addGroupMutation(group.name);
    }
    if (addToGroup) {
      setContact({
        ...contact,
        groups: [
          ...contact.groups,
          {
            groupId: addToGroup.groupId,
            name: addToGroup.name,
            userId: addToGroup.userId,
          },
        ],
      });
    }

    setGroup({ ...group, name: '' });
    setCreateGroup(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!groupItems || !groupItems.length) {
      return;
    }
    setSelectableGroups(
      groupItems.map(i => ({ value: i.groupId, label: i.name }))
    );
  }, [groupItems]);

  useEffect(() => {
    const isEmailFieldMissing = colMapping.email === 'None';
    const isPhoneFieldMissing = colMapping.phone === 'None';
    const values = Object.keys(colMapping).reduce((obj: any, field: string) => {
      CSVData.data.forEach((d: string[]) => {
        if (!obj[field]) {
          obj[field] = [];
        }
        obj[field].push(getColValue(d, field));
      });
      return obj;
    }, {});
    const invalid: { [key: string]: boolean } = {};
    const duplicatedIndex: { [key: string]: boolean } = {};
    const duplicated: { [key: string]: string[] } = {};
    const matching: { [key: string]: boolean } = {};
    const foundMatchingContactsList = [] as any[];
    let countDuplicate = 0;
    Object.keys(values).forEach(field => {
      const data = values[field];
      for (let i in data) {
        invalid[i] =
          invalid[i] ||
          !validate(data[i], field) ||
          // @ts-ignore
          data[i].lenght > FieldLength[field];
        if (field === 'email' && !isEmailFieldMissing) {
          const email = data[i].toString().toLowerCase();
          if (email === '') {
            invalid[i] = true;
          }
          if (!duplicated[email]) {
            duplicated[email] = [];
          }
          if (!invalid[i]) {
            const index = Number(i) + 2;
            duplicated[email].push(index.toString());
          }
          let foundMatchingContact = contactsData.items.find(
            c => c.email.toLowerCase() === email
          );
          if (foundMatchingContact) {
            if (
              !foundMatchingContactsList.find(
                c => c.email.toLowerCase() === email
              )
            ) {
              foundMatchingContactsList.push(foundMatchingContact);
            }
            matching[i] = true;
          } else {
            matching[i] = false;
          }
          if (duplicated[email].length > 1) {
            duplicatedIndex[i] = true;
            countDuplicate++;
          } else {
            duplicatedIndex[i] = false;
          }
        }
        if (field === 'phone' && !isPhoneFieldMissing && data[i] === '') {
          invalid[i] = true;
        }
      }
    });
    setInvalidRows(invalid);
    setDuplicatedRows(duplicated);
    setDuplicatedCsvIndex(duplicatedIndex);
    setMatchingRows(matching);
    setMatchingContacts(foundMatchingContactsList);
    setSelectedMatching(foundMatchingContactsList);
    setDuplicateCount(countDuplicate);
    setInvalidCount(
      isPhoneFieldMissing && isEmailFieldMissing
        ? CSVData.data.length
        : Object.values(invalid).filter(Boolean).length
    );
  }, [colMapping, CSVData.data]);

  const initColOptions = (row: string[]) => {
    const options = row?.map((_: string, i: number) => ({
      value: `${i}`,
      label: `Col ${i + 1}`,
    }));

    options?.unshift({ value: 'None', label: 'Choose...' });
    setColOptions(options);
  };

  useEffect(() => {
    const bulkValid = modalType === 'bulk' && !!CSVData.data.length;
    const existingContactTmp = !!contact.email
      ? contactsData.items.find(
          c => c.email.toLowerCase() === contact.email.toLowerCase()
        )
      : undefined;

    let companyName = '';
    if (contact.companyName) {
      companyName = contact.companyName;
    }

    /* SUS-796 changes */
    const oneValid =
      (!!contact.email || !!contact.phone) &&
      (!contact.email ||
        EMAIL_VALIDATION_REG.test(contact.email.toLowerCase())) &&
      !existingContactTmp &&
      !optOutContacts?.items?.find(
        c => c.email.toLowerCase() === contact.email.toLowerCase()
      ) &&
      (!contact.phone || contact.phone?.length >= 7) &&
      contact.firstName.length <= 60 &&
      contact.lastName.length <= 60 &&
      companyName.length <= 60;

    setExistingContact(existingContactTmp);
    setContactValid(bulkValid || oneValid);
  }, [
    contact,
    modalType,
    CSVData.data,
    contactsData.items,
    optOutContacts.items,
  ]);
  const initCSVHeaders = (row: string[]) => {
    const options = [];
    let found = false;
    for (let i in row) {
      if (!row[i]) {
        break;
      }
      let value = row[i].toString();
      options.push({ label: value, value: `${i}` });
      for (let header of HEADER_REG_VALUES) {
        if (value.match(header.reg)) {
          found = true;
          setColMapping(value => ({ ...value, [header.field]: `${i}` }));
        }
      }
    }
    options.unshift({ value: 'None', label: 'Choose...' });
    setColOptions(options);
    return found;
  };

  const initSampleData = (data: string[][]) => {
    let sample = { ...colSamples };
    let sampleCollected;
    let i = 0;
    while (!sampleCollected && i < data.length) {
      let row = data[i];
      row.forEach((field, index) => {
        if (!sample[index]) {
          sample[index] = [];
        }
        sample[index].push(field);
      });
      let values = Object.keys(sample).map(k => sample[k]);
      sampleCollected = values.reduce(
        (collected, fieldSample) =>
          collected && fieldSample.length >= SAMPLE_LENGTH,
        false
      );
      i++;
    }
    setColSamples(sample);
  };

  const onColChange = (field: string, value: string) => {
    const newField =
      Object.keys(colMapping).find(key => colMapping[key] === value) || 'None';
    if (field === 'None') {
      setColMapping({ ...colMapping, [newField]: 'None' });
    } else {
      setColMapping({ ...colMapping, [newField]: 'None', [field]: value });
    }
  };

  const onUpload = (csv: string[][], fileInfo: any) => {
    const data = [...csv];
    setUploadError(false);
    setEmptyFileError(false);
    if (!fileInfo.name.includes('csv')) {
      setUploadError(true);
      return;
    }
    if (!data.length) {
      setEmptyFileError(true);
      return;
    }
    const firstRow = data[0];
    const headersPresent = initCSVHeaders(firstRow);
    if (headersPresent) {
      data.shift();
    } else {
      initColOptions(firstRow);
    }
    initSampleData(data);
    setCSVData({ data, uploaded: true });
  };

  const getSampleData = (index: string) => {
    if (!colSamples[index]) {
      return '';
    }
    return colSamples[index].filter(Boolean).slice(0, SAMPLE_LENGTH);
    //.join(', ') + (colSamples[index] ? '...' : '')
  };

  const getColValue = (data: string[], field: string) => {
    const columnValue = get(colMapping, field, '');
    if (!columnValue || columnValue === 'None') {
      return '';
    }
    return data[parseInt(columnValue)] || '';
  };

  const onSubmit = () => {
    if (modalType === 'one') {
      return handleSubmit(contact, [], []);
    }
    const addedGroups = contact.groups.map(group => {
      return { ...group, contacts: [] };
    });

    let newContacts: any[] = [];
    let updateContacts: any[] = [];
    const updatedGroupsOnlyContacts: any[] = [];
    CSVData.data.forEach((data, i) => {
      //data for inserting
      const csvForInsert = {
        firstName: getColValue(data, 'firstName'),
        lastName: getColValue(data, 'lastName'),
        email: (getColValue(data, 'email') || '').toLowerCase(),
        phone: getColValue(data, 'phone'),
        companyName: getColValue(data, 'companyName'),
        fax: getColValue(data, 'fax'),
        address: getColValue(data, 'address'),
        city: getColValue(data, 'city'),
        state: getColValue(data, 'state'),
        zip: getColValue(data, 'zip'),
        country: getColValue(data, 'country'),
        groups: addedGroups,
      };
      if (!invalidRows[i] && !duplicatedCsvIndex[i] && !matchingRows[i]) {
        newContacts.push(csvForInsert);
      }
      //data for updating
      const csvForUpdate = {
        firstName: getColValue(data, 'firstName'),
        lastName: getColValue(data, 'lastName'),
        email: (getColValue(data, 'email') || '').toLowerCase(),
        phone: getColValue(data, 'phone'),
        companyName: getColValue(data, 'companyName'),
        fax: getColValue(data, 'fax'),
        address: getColValue(data, 'address'),
        city: getColValue(data, 'city'),
        state: getColValue(data, 'state'),
        zip: getColValue(data, 'zip'),
        country: getColValue(data, 'country'),
      };
      if (!invalidRows[i] && matchingRows[i]) {
        const originalContactData = selectedMatching.find(
          row => row.email === csvForUpdate.email
        );
        if (originalContactData) {
          updateContacts.push({ ...originalContactData, ...csvForUpdate });
        }
        const originalContactDataGroupUpdate = updateContacts.find(
          row => row.email === csvForUpdate.email
        );
        if (originalContactDataGroupUpdate) {
          updatedGroupsOnlyContacts.push({
            ...originalContactDataGroupUpdate,
            ...{ groups: addedGroups },
          });
        }
      }
    });

    if (
      handleUpdateGroupSubmit &&
      isDoNotUpdateSelected &&
      !!addedGroups.length &&
      !!updateGroupContacts.length
    ) {
      handleUpdateGroupSubmit(updateGroupContacts, addedGroups);
    }
    handleSubmit(newContacts, updateContacts, updatedGroupsOnlyContacts);
  };

  const onGroupCheckboxClick = (groupObj: any) => {
    const isGroupAlreadySelected = contact.groups.find((currentGroup: any) => {
      return groupObj.groupId === currentGroup.groupId;
    });
    if (isGroupAlreadySelected) {
      removeGroupFromSelection(groupObj.groupId);
    } else {
      setContact({
        ...contact,
        groups: [...contact.groups, { ...groupObj, contacts: [] }],
      });
    }
  };

  const removeGroupFromSelection = (id: string | number) => {
    const list = contact.groups.filter(
      currentlySelectedGroup => currentlySelectedGroup.groupId !== id
    );
    setContact({ ...contact, groups: [...list] });
  };
  const validateEmail = (email: string = '') => {
    let emailError = '';
    if (email === '') {
      emailError = '';
    } else if (!EMAIL_VALIDATION_REG.test(email.toString().toLowerCase())) {
      emailError = 'Please enter valid email address';
    } else if (
      optOutContacts.items.find(
        c => c.email.toLowerCase() === email.toLowerCase()
      )
    ) {
      emailError = 'A contact with that email opted out';
    } else if (!validateInputForCsvFormulaInjection(email)) {
      emailError = 'Please enter valid email';
    }
    setErrors({ ...errors, email: emailError });
    return emailError;
  };

  /* SUS-796 changes (Changing limit to 60 SUS-908)*/
  const validateFirstName = (firstName: string | undefined) => {
    let firstNameError = '';
    if (firstName && firstName.length > 60) {
      /* SUS-908 changes */
      firstNameError =
        'First Name can not contain more than 60 characters'; /* SUS-908 changes */
    } else if (!validateInputForCsvFormulaInjection(firstName ?? '')) {
      firstNameError = 'Please enter valid first name';
    }
    setErrors({ ...errors, firstName: firstNameError });
    return firstNameError;
  };

  const validateLastName = (lastName: string | undefined) => {
    let lastNameError = '';
    if (lastName && lastName.length > 60) {
      /* SUS-908 changes */
      lastNameError =
        'Last Name can not contain more than 60 characters'; /* SUS-908 changes */
    } else if (!validateInputForCsvFormulaInjection(lastName ?? '')) {
      lastNameError = 'Please enter valid last name';
    }
    setErrors({ ...errors, lastName: lastNameError });
    return lastNameError;
  };

  const validateCompanyName = (companyName: string | undefined) => {
    let companyNameError = '';
    if (companyName && companyName.length > 60) {
      /* SUS-908 changes */
      companyNameError =
        'Company Name can not contain more than 60 characters'; /* SUS-908 changes */
    } else if (!validateInputForCsvFormulaInjection(companyName ?? '')) {
      companyNameError = 'Please enter valid company name';
    }
    setErrors({ ...errors, companyName: companyNameError });
    return companyNameError;
  };
  /******/
  const validatePhone = (phone: string | undefined) => {
    let phoneError = '';
    if (phone && phone?.length < 7) {
      phoneError = 'Please enter valid phone number';
    } else if (!validateInputForCsvFormulaInjection(phone ?? '')) {
      phoneError = 'Please enter valid phone number';
    }

    setErrors({ ...errors, phone: phoneError });
    return phoneError;
  };

  const validationMapping: { [key: string]: Function } = {
    email: validateEmail,
    phone: validatePhone,
    firstName: validateFirstName,
    lastName: validateLastName,
    companyName: validateCompanyName,
  };

  const validate = (value: any, field: string) => {
    if (!validationMapping[field]) {
      return true;
    }

    return !validationMapping[field](value);
  };

  const parseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const AddOneContact = (
    <ModalItem>
      <Content style={{ width: 1008 }}>
        <ContentHeaderWrap>
          <ContentHeader>{title}</ContentHeader>
          <CloseWrapper disabled={disabled} onClick={onModalClose}>
            <MdClose size={20} />
          </CloseWrapper>
        </ContentHeaderWrap>
        <ContentBody>
          {text}
          <Form
            onSubmit={e => {
              e.stopPropagation();
            }}
          >
            <Row>
              <Col className='input' style={{ marginRight: 12, flex: 1 }}>
                {/*  SUS- 896 changes */}
                <Label>First Name</Label>
                <div style={{ display: 'flex' }}>
                  <TextInput
                    name='contact.firstName'
                    type='text'
                    autoFocus={true}
                    required={true}
                    value={contact.firstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setContact({ ...contact, firstName: e.target.value })
                    }
                    onKeyUp={() =>
                      validateFirstName(contact.firstName)
                    } /* SUS-796 changes */
                  />
                </div>
                {errors.firstName && (
                  <Label>
                    <Text>{errors.firstName}</Text>
                  </Label>
                )}
              </Col>
              <Col className='input' style={{ marginLeft: 12, flex: 1 }}>
                <Label>Last Name</Label>
                <div style={{ display: 'flex' }}>
                  <TextInput
                    name='contact.lastName'
                    type='text'
                    required={true}
                    value={contact.lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setContact({ ...contact, lastName: e.target.value })
                    }
                    onKeyUp={() =>
                      validateLastName(contact.lastName)
                    } /* SUS-796 changes */
                  />
                </div>
                {errors.lastName && (
                  <Label>
                    <Text>{errors.lastName}</Text>
                  </Label>
                )}
              </Col>
            </Row>
            <Row>
              <Col className='input'>
                <Label>Email Address</Label>
                <div style={{ display: 'flex' }}>
                  <TextInput
                    name='contact.email'
                    type='text'
                    required={true}
                    value={contact.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setContact({ ...contact, email: e.target.value })
                    }
                    onBlur={() => {
                      if (!contact.email && !contact.phone) {
                        setErrors({ ...errors, email: '', phone: '' });
                        setEmailAndPhoneMissingError(
                          'Please enter email address or phone number'
                        );
                        return;
                      }
                      setEmailAndPhoneMissingError('');
                      validateEmail(contact.email);
                    }}
                  />
                </div>

                {emailAndPhoneMissingError && (
                  <Label>
                    <Text>{emailAndPhoneMissingError}</Text>
                  </Label>
                )}
                {errors.email && (
                  <Label>
                    <Text>{errors.email}</Text>
                  </Label>
                )}
                {existingContact && (
                  <Label>
                    <Text style={{ display: 'flex' }}>
                      Contact with this email address already exists.&nbsp;
                      <ContactLink
                        style={{ color: theme.palette.blue100 }}
                        onClick={() =>
                          history.push(
                            `/contacts/list/${existingContact.contactId}`
                          )
                        }
                      >
                        Open Contact <MdLaunch size={12} />
                      </ContactLink>{' '}
                    </Text>
                  </Label>
                )}
              </Col>
            </Row>
            <Row>
              <Col className='input'>
                <Label>Phone Number</Label>
                <div style={{ display: 'flex' }}>
                  <TextInput
                    name='contact.phone'
                    type='text'
                    required={true}
                    value={contact.phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setContact({ ...contact, phone: e.target.value })
                    }
                    onBlur={() => {
                      if (!contact.email && !contact.phone) {
                        setErrors({ ...errors, email: '', phone: '' });
                        setEmailAndPhoneMissingError(
                          'Please enter email address or phone number'
                        );
                        return;
                      }
                      setEmailAndPhoneMissingError('');
                      validatePhone(contact.phone);
                    }}
                  />
                </div>

                {emailAndPhoneMissingError && (
                  <Label>
                    <Text>{emailAndPhoneMissingError}</Text>
                  </Label>
                )}
                {errors.phone && (
                  <Label>
                    <Text>{errors.phone}</Text>
                  </Label>
                )}
              </Col>
            </Row>
            <Row>
              <Col className='input'>
                <Label>Company Name</Label>
                <div style={{ display: 'flex' }}>
                  <TextInput
                    name='contact.companyName'
                    type='text'
                    required={true}
                    value={contact.companyName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setContact({ ...contact, companyName: e.target.value })
                    }
                    onKeyUp={() =>
                      validateCompanyName(contact.companyName)
                    } /* SUS-796 changes */
                  />
                </div>
                {/* SUS-796 changes */}
                {errors.companyName && (
                  <Label>
                    <Text>{errors.companyName}</Text>
                  </Label>
                )}
              </Col>
            </Row>
          </Form>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          text='Create'
          variant='secondary'
          disabled={!contactValid || submitting}
          onClick={() => {
            setSubmitting(true);
            onSubmit();
          }}
        />
        <Button
          variant='primary'
          disabled={!contactValid}
          text={`Create and add to Groups`}
          onClick={() => setCurrentView(Views.ADD_TO_GROUP)}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const AddBulkContact = (
    <ModalItem>
      <Content>
        <ContentHeaderWrap>
          <ContentHeader>{title}</ContentHeader>
          <CloseWrapper disabled={disabled} onClick={onModalClose}>
            <MdClose size={20} />
          </CloseWrapper>
        </ContentHeaderWrap>
        <ContentBody>
          {text}
          <Form
            onSubmit={e => {
              e.stopPropagation();
            }}
          >
            <>
              <DragAndDrop
                showIcon={false}
                onFileLoaded={onUpload}
                parserOptions={parseOptions}
                button={true}
                isMultiple={false}
              />
              {uploadError && (
                <ErrorMessage>
                  <span>
                    <IoMdClose
                      size={32}
                      color={theme.palette.primaryRedDanger}
                    />
                    Uploaded file is not in .csv format. Try again!
                  </span>
                </ErrorMessage>
              )}
              {emptyFileError && (
                <ErrorMessage>
                  <span>
                    <IoMdClose
                      size={32}
                      color={theme.palette.primaryRedDanger}
                    />
                    The file doesn't contain valid data. Try again!
                  </span>
                </ErrorMessage>
              )}
            </>
          </Form>
        </ContentBody>
        <ContentFooter>
          <NoteSpan>
            <b>NOTE:</b> Emails sent through Covideo are capped at 1,000
            contacts per day
          </NoteSpan>
          <Checkbox
            id='iagree'
            blueCheck={true}
            checked={agreed}
            onChange={() => setAgreed(!agreed)}
          />
          <IAgree>
            <label htmlFor='iagree'>I agree with the Anti-Spam Policy</label>
          </IAgree>
          <NoteSpan style={{ marginBottom: 0 }}>
            The chosen email address(es) is/are permission based - Recipient(s)
            has/have specifically asked{' '}
            {seeMore
              ? ' to receive emails from me or I have a relationship with the recipient(s). ' +
                'The chosen email address(es) has/have not been purchased - Recipient(s) has/have not been purchased ' +
                'from any source in any way. The chosen email address(es) is/are not part of a mailing list or ' +
                'distribution list - Recipient(s) does/do not redistribute emails that have been sent to it to more ' +
                'than one email address on a regular basis. The chosen email address(es) is/are not captured - ' +
                'Recipient(s) has/have not been captured by any means (i.e. surfing the internet). '
              : 't... '}
            <b
              style={{
                fontWeight: 700,
                color: theme.palette.primaryDarkBlue,
                cursor: 'pointer',
              }}
              onClick={() => setSeeMore(!seeMore)}
            >
              See {seeMore ? 'less' : 'more'}
            </b>
          </NoteSpan>
        </ContentFooter>
      </Content>
      <ButtonContainer>
        <Button
          variant='primary'
          disabled={!agreed || !contactValid}
          text={modalType === 'one' ? 'Create Contact' : 'Upload'}
          onClick={() => {
            setCurrentView(Views.MAPPING);
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const printDuplicatedRows = React.useCallback(() => {
    const result: any[] = [];
    for (let email in duplicatedRows) {
      if (duplicatedRows[email].length > 1) {
        result.push(
          <Label>
            <Text>
              {' '}
              {email} has multiple entries in imported file (line{' '}
              {duplicatedRows[email].join(', ')})
            </Text>
          </Label>
        );
      }
    }
    return result;
  }, [duplicatedRows]);

  const MapImpoted = (
    <ModalItem>
      <Content style={{ width: 944 }}>
        <ContentHeaderWrap>
          <ContentHeader>{title}</ContentHeader>
          <CloseWrapper disabled={disabled} onClick={onModalClose}>
            <MdClose size={20} />
          </CloseWrapper>
        </ContentHeaderWrap>
        <ContentBody>
          <div style={{ marginBottom: 42 }}>
            Connect columns from .csv file to contact fields
          </div>
          <Table
            compact={true}
            headers={[
              <TableCellHeader>Column Label (csv)</TableCellHeader>,
              <TableCellHeader>Example</TableCellHeader>,
              <TableCellHeader>Contact Field</TableCellHeader>,
            ]}
            hoverable={false}
            rows={colOptions.slice(1).map((x, index: number) => ({
              key: index,
              columns: [
                <TableCell>{x.label}</TableCell>,
                <TableCell>{getSampleData(index.toString())}</TableCell>,
                <TableCell>
                  <SelectInput
                    menuPlacement='auto'
                    menuPosition='fixed'
                    placeholder='Choose...'
                    styles={{
                      control: (base: any) => ({ ...base, height: '40px' }),
                      indicatorSeparator: () => ({ display: 'none' }),
                    }}
                    options={FieldOptions}
                    value={FieldOptions.find(
                      option =>
                        option.value ==
                        (Object.keys(colMapping).find(
                          key => colMapping[key] === index.toString()
                        ) || 'None')
                    )}
                    onChange={(option: any) =>
                      onColChange(option.value, index.toString())
                    }
                  />
                </TableCell>,
              ],
              onClick: () => {},
            }))}
          />
          {invalidCount > 0 && invalidCount < CSVData.data.length && (
            <>
              <Row>
                <ErrorMessage>
                  <span>
                    <MdWarning
                      color={theme.palette.primaryRedDanger}
                      size={32}
                    />
                    {invalidCount}/{CSVData.data.length} rows contain invalid
                    data. Those rows will be ignored.
                  </span>
                </ErrorMessage>
              </Row>
            </>
          )}
          {invalidCount == CSVData.data.length && (
            <>
              <Row>
                <ErrorMessage style={{ minHeight: '48px', height: 'auto' }}>
                  <span
                    style={{
                      margin: '10px',
                      alignItems: 'center',
                      lineHeight: 'normal',
                    }}
                  >
                    <MdWarning
                      color={theme.palette.primaryRedDanger}
                      size={24}
                      style={{ marginTop: '0px' }}
                    />
                    {colMapping.email === 'None' &&
                      colMapping.phone === 'None' &&
                      'The Email Address or Phone Number field is required for importing contacts into Covideo. Please check your file to ensure you have created and labeled a column for Email Address or Phone Number.'}{' '}
                    {colMapping.email !== 'None' &&
                      colMapping.phone === 'None' &&
                      'Please check email column label in .csv file and ensure email column mapping with email column field'}{' '}
                    {colMapping.phone !== 'None' &&
                      colMapping.email === 'None' &&
                      'Please check phone column label in .csv file and ensure phone column mapping with phone column field'}{' '}
                    {colMapping.email !== 'None' &&
                      colMapping.phone !== 'None' &&
                      'Please check email and phone column labels in .csv file and ensure email and phone columns mapping with email and phone column fields'}{' '}
                  </span>
                </ErrorMessage>
              </Row>
            </>
          )}
          {duplicateCount > 0 && (
            <div style={{ maxHeight: 84, overflowY: 'auto', marginTop: 15 }}>
              {printDuplicatedRows()}
            </div>
          )}
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          variant='primary'
          disabled={CSVData.data.length - invalidCount == 0}
          text={'Continue'}
          onClick={() => {
            matchingContacts.length
              ? setCurrentView(Views.UPDATE_MATCHING)
              : setCurrentView(Views.ADD_TO_GROUP);
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const UpdateMatchingDataView = (
    <ModalItem>
      <Content style={{ width: 592 }}>
        <ContentHeaderWrap>
          <ContentHeader>
            Update data for {matchingContacts.length} matching contacts?
          </ContentHeader>
          <CloseWrapper disabled={disabled} onClick={onModalClose}>
            <MdClose size={20} />
          </CloseWrapper>
        </ContentHeaderWrap>
        <ContentBody>
          <InfoBox>
            <AiFillInfoCircle
              color={themes.colors.primary[100]}
              size={'20px'}
            />
            <span>
              We found {matchingContacts.length} contacts that match the ones
              already in the system. Do you want to update these contacts?
            </span>
          </InfoBox>
          <CheckboxWrapper>
            <CheckboxInput
              checked={selectedMatching.length === matchingContacts.length}
              onChange={(e: React.SyntheticEvent) => {
                let { checked } = e.target as HTMLInputElement;
                if (checked)
                  setSelectedMatching(matchingContacts.map((ele: any) => ele));
                else setSelectedMatching([]);
              }}
            />
            <p>All contacts</p>
          </CheckboxWrapper>
          <MatchingListWrapper>
            {matchingContacts.map((contact, i) => (
              <MatchingListItem key={i}>
                <CheckboxWrapper>
                  <CheckboxInput
                    checked={selectedMatching.includes(contact)}
                    onChange={(e: React.SyntheticEvent) => {
                      let { checked } = e.target as HTMLInputElement;
                      if (checked)
                        setSelectedMatching([...selectedMatching, contact]);
                      else
                        setSelectedMatching(
                          selectedMatching.filter((ele: any) => ele != contact)
                        );
                    }}
                  />
                  <span>{contact.email}</span>
                </CheckboxWrapper>
                <span>{contact.firstName}</span>
              </MatchingListItem>
            ))}
          </MatchingListWrapper>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          text="Don't update"
          variant='ghost'
          onClick={() => {
            setIsDoNotUpdateSelected(true);
            setUpdateGroupContacts(selectedMatching);
            setSelectedMatching([]);
            setCurrentView(Views.ADD_TO_GROUP);
          }}
        />
        <Button
          disabled={!selectedMatching.length}
          text={`Update data for ${selectedMatching.length} contacts`}
          variant='primary'
          onClick={() => setCurrentView(Views.ADD_TO_GROUP)}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const GroupView = (
    <ModalItem>
      <Content style={{ width: 1008 }}>
        <ContentHeaderWrap>
          <ContentHeader>Add Contacts to Group (optional)</ContentHeader>
          <CloseWrapper disabled={disabled} onClick={onModalClose}>
            <MdClose size={20} />
          </CloseWrapper>
        </ContentHeaderWrap>
        <ContentBody>
          {CSVData.data.length - invalidCount - duplicateCount > 1000 && (
            <InfoMsg>
              <InfoMsgHeader>
                <MdInfo
                  style={{
                    width: 24,
                    height: 24,
                    marginRight: 12,
                    opacity: '0.5',
                  }}
                />
                <div>
                  You've imported{' '}
                  {CSVData.data.length - invalidCount - duplicateCount} contacts
                </div>
              </InfoMsgHeader>
              <InfoMsgBody>
                Uh-Oh! It looks like your list of imported contacts exceeds the
                maximum number of contacts that can be uploaded into one group
                (1000). If you opt to add them regardless, we will automatically
                divide your list alphabetically by last name into multiple
                groups.
              </InfoMsgBody>
            </InfoMsg>
          )}
          <Row style={{ marginBottom: 32 }}>
            <div style={{ width: 300 }}>
              <Search
                placeholder='Search Groups...'
                onSearch={(value: string) => setGroupSearch(value)}
              />
            </div>
            {createGroup ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '12px',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <TextInput
                    style={{ boxSizing: 'border-box' }}
                    placeholder='Group Name'
                    type='text'
                    autoFocus={true}
                    value={group.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setGroup({ ...group, name: e.target.value })
                    }
                  />
                </div>
                <Button
                  onClick={(event?: MouseEvent | React.SyntheticEvent) =>
                    onCreateGroup(event as MouseEvent)
                  }
                  disabled={group.name.length == 0 || isDisabled}
                  text='Create Group'
                  variant='primary'
                  icon={<MdAdd />}
                />
                <Button
                  onClick={() => {
                    setCreateGroup(false);
                    setGroup({ ...group, name: '' });
                  }}
                  text='Cancel'
                  variant='secondary'
                />
              </div>
            ) : (
              <Button
                onClick={() => setCreateGroup(true)}
                text='Create Group'
                variant='secondary'
                icon={<MdAdd />}
                disabled={isDisabled}
              />
            )}
          </Row>
          {groupItems.length == 0 && !groupSearch ? (
            <NoGroupsWrap>
              <NoGroups>You have no groups</NoGroups>
            </NoGroupsWrap>
          ) : (
            <>
              <Table
                compact={true}
                hoverable={false}
                rows={groupItems.map((x, index: number) => ({
                  key: index,
                  columns: [
                    <TableCell>
                      <Checkbox
                        checked={
                          !!contact.groups.find(
                            group => group.groupId === x.groupId
                          )
                        }
                        onChange={() => {
                          onGroupCheckboxClick(x);
                        }}
                      />
                      {x.name}
                    </TableCell>,
                    <TableCellRight>
                      {x.contacts && x.contacts.length + ' Contacts'}
                    </TableCellRight>,
                  ],
                  onClick: () => {},
                }))}
              />
              {groupItems.length === 0 && (
                <p
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    margin: '36px 0',
                  }}
                >
                  No matching results found.{' '}
                </p>
              )}
            </>
          )}
        </ContentBody>
      </Content>
      <ButtonContainer>
        {!contact.groups.length && (
          <Button
            text='Finish'
            variant='secondary'
            onClick={onSubmit}
            disabled={isDisabled}
          />
        )}
        {!!contact.groups.length && (
          <Button
            variant='primary'
            disabled={contact.groups.length === 0 || submitting || isDisabled}
            text={`Add to ${
              contact.groups.length > 0 ? contact.groups.length : ''
            } Group${contact.groups.length > 1 ? 's' : ''}`}
            onClick={() => {
              setSubmitting(true);
              setCurrentView(Views.LOADING);
              onSubmit();
            }}
          />
        )}
      </ButtonContainer>
    </ModalItem>
  );

  const LoadingView = (
    <ModalItem>
      <Content style={{ width: 1008 }}>
        <ContentHeaderWrap>
          <ContentHeader></ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          <LoadingIndicator isLoading={true} text='Creating contacts...' />
        </ContentBody>
      </Content>
    </ModalItem>
  );

  let CurrentVievComponent = null;
  switch (currentView) {
    case Views.IMPORT:
      CurrentVievComponent = AddBulkContact;
      break;
    case Views.CREATE:
      CurrentVievComponent = AddOneContact;
      break;
    case Views.MAPPING:
      CurrentVievComponent = MapImpoted;
      break;
    case Views.UPDATE_MATCHING:
      CurrentVievComponent = UpdateMatchingDataView;
      break;
    case Views.ADD_TO_GROUP:
      CurrentVievComponent = GroupView;
      break;
    case Views.LOADING:
      CurrentVievComponent = LoadingView;
      break;
  }
  return <Modal>{CurrentVievComponent}</Modal>;
};
