import styled, { css } from 'styled-components/macro';

export const CommonStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

export const StyledLabel = styled.label<{ disabled?: boolean }>`
  ${CommonStyles}
  display: block;
  color: ${({ theme }) => theme.colors.neutral[60]};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const StyledParagraph = styled.p<{ disabled?: boolean }>`
  ${CommonStyles}
  color: ${({ theme }) => theme.colors.neutral[60]};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
