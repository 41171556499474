import { theme } from 'lib/style';
import React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { MdCheck } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';

type InfoBoxVariantType = 'info' | 'success';

const InfoVariant = css`
  background-color: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.covideoBlack05};
  color: ${theme.palette.covideoBlue100};
`;
const SuccessVariant = css`
  background-color: ${theme.palette.green02};
  border: 1px solid ${theme.palette.green05};
  color: ${theme.palette.green100};
`;

const Container = styled.div<{ variant: InfoBoxVariantType }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  padding: 12px;
  ${({ variant }) => {
    switch (variant) {
      case 'success':
        return SuccessVariant;
      case 'info':
      default:
        return InfoVariant;
    }
  }}
`;

const IconContainer = styled.div`
  flex-shrink: none;
  opacity: 0.6;
`;

type InformationProps = {
  text: string;
  width?: string;
  variant?: InfoBoxVariantType;
};

const getIcon = (variant: InfoBoxVariantType) => {
  switch (variant) {
    case 'success':
      return <MdCheck size={24} />;
    case 'info':
    default:
      return <AiFillInfoCircle size={24} />;
  }
};

export const InformationBox = ({
  text,
  width,
  variant = 'info',
}: InformationProps) => {
  return (
    <Container style={{ width }} variant={variant}>
      <IconContainer>{getIcon(variant)}</IconContainer>
      <div>{text}</div>
    </Container>
  );
};
