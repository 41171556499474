import styled from 'styled-components/macro';

export const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const SwitchLabel = styled.label<{ isOn: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 40px;
  height: 24px;
  background: ${({ isOn, theme }) =>
    isOn ? theme.colors.primary[100] : theme.colors.neutral[40]};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  :active span {
    width: 30px;
  }
`;

export const SwitchButton = styled.span`
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: ${({ theme }) => theme.colors.white[100]};
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  ${({ isOn }: { isOn: boolean }) =>
    isOn &&
    `
    left: calc(100% - 2px);
    transform: translateX(-100%);
  `}
`;
