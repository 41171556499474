import { isEmpty } from 'lodash';
import * as React from 'react';
import { Countries } from '../components/Countries';
import {
  ButtonContainer,
  CardContainer,
  ColumnContainer,
  HeaderImage,
  HeaderText,
  InformationText,
  InputField,
  InputText,
  PageContainer,
  SectionText,
  SelectBox,
} from '../components/PageElements';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import { useLogoUrl } from 'lib/hooks/useLogoUrl';
import { useCreateCustomerPaymentMutation } from 'lib/api/billing/useCreateCustomerPaymentMutation';

export const PaymentForm = () => {
  const { userData } = useAuth();
  const emailAddress = userData ? userData.email : '';
  const { logoUrl } = useLogoUrl('dark');

  const fullNameElement = React.createRef<HTMLInputElement>();
  const cardNumberElement = React.createRef<HTMLInputElement>();
  const cardExpElement = React.createRef<HTMLInputElement>();
  const cardCVCElement = React.createRef<HTMLInputElement>();
  const zipCodeElement = React.createRef<HTMLInputElement>();

  const [countryCode, setCountryCode] = React.useState('');
  const [error, setError] = React.useState('');
  const [submitEnabled, setSubmitEnabled] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const { mutateAsync: createCustomerPayment } =
    useCreateCustomerPaymentMutation();

  const emptyFields = () => {
    return (
      isEmpty(fullNameElement.current?.value) ||
      isEmpty(cardNumberElement.current?.value) ||
      isEmpty(cardExpElement.current?.value) ||
      isEmpty(cardCVCElement.current?.value) ||
      isEmpty(zipCodeElement.current?.value)
    );
  };

  const handleSubmit = async () => {
    try {
      setSubmitEnabled(false);
      setProcessing(true);

      const fullName = fullNameElement.current?.value || '';
      const cardNumber = cardNumberElement.current?.value || '';
      const cardExpDate = cardExpElement.current?.value.split('/');
      const cardExpMonth =
        cardExpDate !== undefined ? cardExpDate[0] || '' : '';
      const cardExpYear = cardExpDate !== undefined ? cardExpDate[1] || '' : '';
      const cardCVC = cardCVCElement.current?.value || '';
      const zipCode = zipCodeElement.current?.value || '';

      if (isEmpty(fullName)) throw new Error('No name entered');
      if (isEmpty(cardNumber)) throw new Error('No card number entered');
      if (isEmpty(cardExpMonth) || isEmpty(cardExpYear))
        throw new Error('No card expiration date entered');
      if (isEmpty(cardCVC)) throw new Error('No card CVC entered');
      if (isEmpty(countryCode))
        throw new Error('No country or region selected');

      if (cardNumber.length !== 19)
        throw new Error('Invalid credit card number');
      if (cardExpMonth.length !== 2)
        throw new Error('Invalid expiration date entered');
      if (cardExpYear.length !== 2)
        throw new Error('Invalid expiration date entered');

      if (cardExpYear < new Date().getFullYear().toString().substring(2))
        throw new Error('Card is expired');

      if (
        cardExpYear === new Date().getFullYear().toString().substring(2) &&
        parseInt(cardExpMonth) < parseInt(new Date().getMonth().toString()) + 1
      )
        throw new Error('Card is expired');

      // Create payment method
      await createCustomerPayment({
        cardNumber: cardNumber.replaceAll(' ', ''),
        expMonth: cardExpMonth,
        expYear: cardExpYear,
        cvc: cardCVC,
        billingCountry: countryCode,
        billingPostalCode: zipCode,
        billingEmail: emailAddress,
        fullName,
        addons: [],
        quantity: 1,
        interval: 'month',
        isTrial: true,
      });
    } catch (error) {
      setError(error.message);
      setProcessing(false);
      setSubmitEnabled(true);
    }
  };

  return (
    <PageContainer>
      <HeaderImage src={logoUrl} />
      <HeaderText>Enter payment details to start your free trial</HeaderText>
      <InformationText>
        Credit card will be charged $69 following 7-day free trial.
      </InformationText>
      <InformationText>
        Monthly subscription renews automatically. Cancel any time.
      </InformationText>
      <SectionText>
        Card information
        {error && (
          <>
            {' '}
            - <label style={{ color: '#ff0000' }}>{error}</label>
          </>
        )}
      </SectionText>
      <InputText>Name</InputText>
      <InputField
        ref={fullNameElement}
        onChange={() => {
          setSubmitEnabled(!emptyFields() && !isEmpty(countryCode));
        }}
      />
      <InputText>Card Number</InputText>
      <InputField
        placeholder='1234 1234 1234 1234'
        ref={cardNumberElement}
        maxLength={19}
        onChange={() => {
          const cardNumber =
            cardNumberElement.current?.value.replaceAll(' ', '') || '';
          const cardNumberLength = cardNumber.length || 0;

          if (cardNumberElement.current && cardNumberLength > 12) {
            cardNumberElement.current.value =
              cardNumber.substr(0, 4) +
              ' ' +
              cardNumber.substr(4, 4) +
              ' ' +
              cardNumber.substr(8, 4) +
              ' ' +
              cardNumber.substr(12);
          } else if (cardNumberElement.current && cardNumberLength > 8) {
            cardNumberElement.current.value =
              cardNumber.substr(0, 4) +
              ' ' +
              cardNumber.substr(4, 4) +
              ' ' +
              cardNumber.substr(8);
          } else if (cardNumberElement.current && cardNumberLength > 4) {
            cardNumberElement.current.value =
              cardNumber.substr(0, 4) + ' ' + cardNumber.substr(4);
          }

          setSubmitEnabled(!emptyFields() && !isEmpty(countryCode));
        }}
      />
      <CardContainer>
        <ColumnContainer>
          <InputText style={{ marginLeft: '0px' }}>
            Expiration (mm/yy)
          </InputText>
          <InputField
            placeholder='MM/YY'
            ref={cardExpElement}
            maxLength={5}
            onChange={() => {
              const expLength = cardExpElement.current?.value.length || 0;
              if (
                cardExpElement.current &&
                expLength === 2 &&
                cardExpElement.current?.value.substr(-1) !== '/'
              ) {
                cardExpElement.current.value += '/';
              } else if (
                expLength === 2 &&
                cardExpElement.current?.value.substr(-1) === '/'
              ) {
                cardExpElement.current.value =
                  cardExpElement.current.value.substr(
                    0,
                    cardExpElement.current.value.length - 1
                  );
              }

              setSubmitEnabled(!emptyFields() && !isEmpty(countryCode));
            }}
          />
        </ColumnContainer>
        <ColumnContainer>
          <InputText style={{ marginLeft: '0px' }}>Security Code</InputText>
          <InputField
            placeholder='CVC'
            ref={cardCVCElement}
            maxLength={4}
            onChange={() => {
              setSubmitEnabled(!emptyFields() && !isEmpty(countryCode));
            }}
          />
        </ColumnContainer>
      </CardContainer>
      <InputText>Country or a region</InputText>
      <SelectBox
        options={Countries}
        onChange={(value: any) => {
          if (value !== null) {
            setCountryCode(value.value);
          }

          setSubmitEnabled(!emptyFields());
        }}
      />
      <InputField
        placeholder='ZIP'
        ref={zipCodeElement}
        style={{ width: '200px', alignSelf: 'start' }}
        onChange={() => {
          setSubmitEnabled(!emptyFields() && !isEmpty(countryCode));
        }}
      />
      <ButtonContainer>
        <Button
          text={!processing ? 'SIGN UP' : 'Processing...'}
          onClick={handleSubmit}
          disabled={!submitEnabled}
        />
      </ButtonContainer>
    </PageContainer>
  );
};
