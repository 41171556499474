import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import { MdKeyboardArrowRight, MdAdd, MdCheck } from 'react-icons/md';
import { theme } from 'lib/style';
import { isNull } from 'lodash';

import { LoadingIndicator, MainContainer } from 'lib/components';
import Amex from 'assets/images/cards/amex.svg';
import Discover from 'assets/images/cards/discover.svg';
import MasterCard from 'assets/images/cards/masterCard.svg';
import Visa from 'assets/images/cards/visa.svg';
import Jcb from 'assets/images/cards/jcb.svg';
import UnionPay from 'assets/images/cards/unionPay.svg';
import DinersClub from 'assets/images/cards/dinersClub.svg';
import Maestro from 'assets/images/cards/maestro.svg';

import { AddCardModal } from './AddCardModal';
import { RemoveCardModal } from './RemoveCardModal';
import { DefaultCardModal } from './DefaultCardModal';
import { MESSAGES } from 'lib/const/Common';
import { Button } from 'react-covideo-common';
import { Card, useCardsQuery } from 'lib/api/billing/useCardsQuery';
import { useAuth } from 'lib/context';

const MainContainerWrapper = styled(MainContainer)`
  align-items: start;
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 0;
    nav {
      &:nth-of-type(1) {
        display: flex;
      }
      width: 100%;
      height: 48px;
      > div {
        float: left;
        display: block;
        background-color: ${theme.palette.white};
        border-top: 1px solid ${theme.palette.grayBorder};
        border-left: 1px solid ${theme.palette.grayBorder};
        border-right: 1px solid ${theme.palette.grayBorder};
        height: 48px;
        box-sizing: border-box;
        line-height: 1.5;
        padding-top: 12px;
        &:nth-of-type(1) {
          width: 152px;
        }
        &:nth-of-type(2) {
          width: 115px;
          border-left: none;
          border-right: none;
        }
        &:nth-of-type(3) {
          width: 100px;
        }
        &:nth-of-type(4) {
          flex-grow: 2;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid ${theme.palette.grayBorder};
          background-color: #fbfbfb;
          &:hover {
            cursor: default;
          }
          disabled: true;
        }
      }
    }
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 20px;
  & .tab-container {
    max-width: 100%;
  }
  & .tab-navigation {
    margin-top: 32px;
    padding: 0;
    justify-content: flex-start;
    max-width: 100%;
  }
  & .tab {
    margin-right: 32px;

    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  & .tab-content {
    padding: 0;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Filter = styled.div`
  float: right;
  ${theme.mediaQueryMinWidth.sm} {
    width: 205px;
  }
`;

const FieldContainer = styled.div`
  display: flex;
`;

const BreadcrumbsContainer = styled.div`
  display flex;
`;

const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;

const LinkText = styled.div`
  font-weight: 400;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
  margin-left: 8px;
`;

const CardListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 30px;
`;

const SingleCard = styled.div`
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  padding: 20px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .single {
    display: flex;
    justify-content: space-between;
    .card--number {
      display: flex;
      font-weight: bold;
      column-gap: 10px;
    }
    .card--exp {
      color: #4e5461;
    }
  }
  .card--address {
    max-width: 200px;
    color: #9297a2;
    padding-top: 10px;
  }
  .btn--wrapper {
    display: flex;
    padding-top: 10px;
    justify-content: space-between;
  }
`;

const cardLogo: any = {
  visa: Visa,
  amex: Amex,
  mastercard: MasterCard,
  discover: Discover,
  chinaunionpay: UnionPay,
  jcb: Jcb,
  diners: DinersClub,
  maestro: Maestro,
};

export const ListCardManager = () => {
  const history = useHistory();
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showRemoveModal, setShowRemoveModal] = React.useState(false);
  const [cardId, setCardId] = React.useState('');
  const [showDefaultModal, setShowDefaultModal] = React.useState(false);
  const { userData } = useAuth();

  const goToBilling = () => history.push('/billing');

  const {
    data,
    isLoading: loading,
    refetch: fetchCards,
  } = useCardsQuery(
    { limit: 100 },
    !(!userData.customer.stripeId || userData.customer.stripeId === '0')
  );
  const items = data?.methods || [];

  return (
    <>
      <MainContainerWrapper>
        <BreadcrumbsContainer>
          <LinkBtn onClick={goToBilling}>Billing</LinkBtn>
          <MdKeyboardArrowRight size={24} />
          <LinkText>Payment Method</LinkText>
        </BreadcrumbsContainer>
        <TabsContainer>
          <FiltersContainer>
            <FieldContainer>
              <Filter>
                <h3>Payment Method</h3>
              </Filter>
            </FieldContainer>
            {!loading ? (
              <ButtonContainer>
                <Button
                  icon={<MdAdd />}
                  text={'New'}
                  onClick={() => setShowAddModal(true)}
                />
              </ButtonContainer>
            ) : null}
          </FiltersContainer>
        </TabsContainer>
        <Content>
          {items.length && !loading ? (
            <CardListWrapper>
              {items.map((card: Card, index: number) => (
                <SingleCard key={`card-item-${index}`}>
                  <div className='single'>
                    <div className='card--number'>
                      <div className='card--img'>
                        <img src={cardLogo[card.brand]} alt='brand' />
                      </div>
                      <div className='card--digits'>
                        <span style={{ textTransform: 'capitalize' }}>
                          {card.brand}
                        </span>{' '}
                        ****{card.last4}
                      </div>
                    </div>
                    <div className='card--exp'>
                      Exp. {`${card.expMonth < 10 ? '0' : ''}${card.expMonth}`}/
                      {card.expYear}
                    </div>
                  </div>
                  <div className='card--address'>
                    <div>
                      {card.billingDetails && card.billingDetails.name
                        ? card.billingDetails.name
                        : '--'}
                    </div>
                    <div>{`${
                      !isNull(card.billingDetails.address.line1)
                        ? card.billingDetails.address.line1
                        : ''
                    } ${
                      !isNull(card.billingDetails.address.city)
                        ? card.billingDetails.address.city
                        : ''
                    } 
                ${
                  !isNull(card.billingDetails.address.state)
                    ? card.billingDetails.address.state
                    : ''
                } ${
                  !isNull(card.billingDetails.address.country)
                    ? card.billingDetails.address.country
                    : ''
                } 
                ${
                  !isNull(card.billingDetails.address.postalCode)
                    ? card.billingDetails.address.postalCode
                    : ''
                }`}</div>
                  </div>
                  <div className='btn--wrapper'>
                    {card.isDefault ? (
                      <div>
                        <Button
                          variant='transparent'
                          onClick={() => {}}
                          text='Default'
                          icon={<MdCheck />}
                        />
                      </div>
                    ) : (
                      <>
                        <div>
                          <Button
                            variant='secondary'
                            onClick={() => {
                              setCardId(card.id);
                              setShowDefaultModal(true);
                            }}
                            text='Set as Default'
                          />
                        </div>
                        <div>
                          <Button
                            variant='destructive'
                            onClick={() => {
                              setCardId(card.id);
                              setShowRemoveModal(true);
                            }}
                            text='Remove'
                          />
                        </div>
                      </>
                    )}
                  </div>
                </SingleCard>
              ))}
            </CardListWrapper>
          ) : null}
          {!items.length && !loading && <div>{MESSAGES.NO_DATA_FOUND}</div>}
          {loading && <LoadingIndicator isLoading={loading} height='500px' />}
        </Content>
        {showAddModal && (
          <AddCardModal
            handleModalClose={refresh => {
              setShowAddModal(false);
              if (refresh) {
                fetchCards();
              }
            }}
          />
        )}
        {showRemoveModal && (
          <RemoveCardModal
            id={cardId}
            handleModalClose={refresh => {
              setShowRemoveModal(false);
              setCardId('');
              if (refresh) {
                fetchCards();
              }
            }}
          />
        )}
        {showDefaultModal && (
          <DefaultCardModal
            id={cardId}
            handleModalClose={refresh => {
              setShowDefaultModal(false);
              setCardId('');
              if (refresh) {
                fetchCards();
              }
            }}
          />
        )}
      </MainContainerWrapper>
    </>
  );
};
