import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { lesaKeys } from './lesaKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

interface LesaFiltersResponse {
  filters: Filter[];
}

interface Filter {
  key: string;
  type: string;
  label: string;
  name: string;
  options: { value: string; label: string; group?: string }[];
}

const getLesaFilters = async (): Promise<LesaFiltersResponse> => {
  return (await EXPRESS_API.get(`/lesa/filters`)).data;
};

export const useLesaFiltersQuery = () => {
  const { showError } = useToastError();

  return useQuery(lesaKeys.filters(), () => getLesaFilters(), {
    onError: showError,
    refetchOnMount: true,
  });
};
