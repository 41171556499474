import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { usersKeys } from './queryKeys';

interface ICheckUsernameRequest {
  username: string;
  userId?: string | undefined;
}
interface ICheckUsernameResponse {
  userExist: boolean;
}

export const checkUsername = async ({
  userId,
  username,
}: ICheckUsernameRequest): Promise<ICheckUsernameResponse> => {
  const response = await EXPRESS_API.get(`users/username-check`, {
    params: { username, userId },
  });
  return response.data;
};

export const useCheckUsernameQuery = ({
  userId,
  username,
}: ICheckUsernameRequest) => {
  return useQuery(
    usersKeys.user_userneme(),
    () => checkUsername({ userId, username }),
    { enabled: false }
  );
};
