import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { theme } from 'lib/style';
import { Gap } from '../styles/layout';
import { isTimeValid } from 'app/pages/account/userAccount/schedule/utils';
import 'react-datepicker/dist/react-datepicker.css';
import { MdScheduleSend } from 'react-icons/md';
import { useScheduleMutation } from 'lib/api/schedule/createSchedule';
import { Schedule } from 'lib/api/schedule/types';
import dayjs from 'dayjs';
import {
  EmailContent,
  SendData,
} from 'app/pages/library/videos/videoList/components/SendAndShare';
import { AttachedFile } from 'lib/context';
import { useHistory } from 'react-router';
import CustomDateTimePicker from '../custom-time-picker/CustomDateTimePicker';
import { ExpDropdownItemsProps } from 'app/pages/library/videos/components/videoExpiration/videoExpiration';
import { Button } from 'react-covideo-common';
import { useUpdateSingleScheduleMutation } from 'lib/api/schedule/updateSingleSchedule';
import {
  TimezoneSelector,
  TimezonesOption,
} from '../timezoneSelector/TimezoneSelector';

const Label = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'ss02' on;
  color: ${theme.palette.gray100};
`;

type FormElementWrapperProps = {
  width?: string;
};
const FormElementWrapper = styled(Gap)<FormElementWrapperProps>`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const ModalContent = styled(Gap)`
  height: 100%;
  justify-content: space-between;
`;

const ModalBody = styled(Gap)`
  width: 100%;
  justify-content: space-between;
`;

const ModalFooter = styled(Gap)`
  width: 100%;
  justify-content: flex-end;
`;

type Props = {
  modalTitle: string;
  handleModalClose: () => void;
  handleCloseSendAndShareModal: () => void;
  sendData?: SendData | undefined;
  attachments?: AttachedFile[];
  emailContent?: EmailContent;
  overlayId?: string;
  templateId?: string;
  linksetId?: string;
  useAutomotive?: boolean;
  allowReactions?: boolean;
  allowVideoReply?: boolean;
  notifyWhenViewed?: boolean;
  skipSmsFlag?: boolean;
  contactIds?: number[] | string[] | (string | number)[];
  leadIds?: number[] | string[] | (string | number)[];
  groupIds?: number[];
  dateTime: Date;
  setDateTime: (newValue: Date) => void;
  timezone: TimezonesOption;
  setTimezone: (newValue: TimezonesOption) => void;
  expiration: ExpDropdownItemsProps;
  editSchedule?: Schedule | null;
};

export const ModalScheduleSendAndShare = ({
  modalTitle,
  handleModalClose,
  handleCloseSendAndShareModal,
  sendData,
  attachments,
  emailContent,
  overlayId,
  templateId,
  linksetId,
  useAutomotive = false,
  allowReactions = false,
  allowVideoReply = false,
  notifyWhenViewed = false,
  skipSmsFlag = false,
  contactIds = [],
  leadIds = [],
  groupIds = [],
  dateTime,
  setDateTime,
  timezone,
  setTimezone,
  expiration,
  editSchedule,
}: Props) => {
  const history = useHistory();
  const themes = useTheme();
  const { mutateAsync, isLoading } = useScheduleMutation();
  const { mutateAsync: updateSchedule } = useUpdateSingleScheduleMutation();

  const handleSubmit = async () => {
    const scheduleData: Partial<Schedule> = {
      videoId: sendData?.videoId,
      sendEmail: sendData?.sendEmail,
      sendSms: sendData?.sendSms,
      vin: sendData?.vin,
      sendVehicle: sendData?.sendVehicle,
      smsText: sendData?.smsText,
      deliveryTime: `${dayjs(dateTime).format('YYYY-MM-DD;hh:mm;A')};${
        timezone.utc[0]
      }`,
      attachments: attachments,
      emailContent: emailContent,
      overlayId: overlayId,
      templateId: templateId,
      linksetId: linksetId,
      useAutomotive: useAutomotive,
      allowVideoReply: allowVideoReply,
      allowReactions: allowReactions,
      notifyWhenViewed: notifyWhenViewed,
      skipSmsFlag: skipSmsFlag,
      groupIds: groupIds,
      contactIds: contactIds,
      leadIds: leadIds,
      expirationDropdownValue: expiration.id,
      expirationCustomDateValue: `${dayjs(expiration.value).format(
        'YYYY-MM-DD hh:mm A'
      )}`,
    };

    editSchedule
      ? await updateSchedule({
          scheduleId: editSchedule.scheduleId,
          data: scheduleData,
        })
      : await mutateAsync(scheduleData);
    handleModalClose();
    handleCloseSendAndShareModal();
    if (editSchedule) {
      history.push('/profile/schedules');
      return;
    }
    history.push('/home');
  };

  const isFormValid =
    isTimeValid(dayjs(dateTime).format('hh:mm')) && !!timezone.value;

  return (
    <NewModal
      headerText={modalTitle}
      closeModal={handleModalClose}
      headerMargin='0 0 32px'
      style={{
        content: {
          padding: '32px',
          minWidth: '369px',
          maxWidth: '369px',
          height: '280px',
          borderRadius: '6px',
          overflow: 'visible',
        },
      }}
    >
      <ModalContent flexDirection='column' alignItems='flex-start'>
        <ModalBody flexDirection='column' alignItems='flex-start'>
          <FormElementWrapper>
            <Label>Select Date</Label>
            <CustomDateTimePicker
              setDate={setDateTime}
              date={dateTime}
              useTimeSelectDropdown={true}
              allowCustomTime={false}
            />
          </FormElementWrapper>
          <FormElementWrapper>
            <Label>Timezone</Label>
            <TimezoneSelector
              value={timezone.value}
              onChange={newValue => setTimezone(newValue)}
            />
          </FormElementWrapper>
        </ModalBody>
        <ModalFooter flexDirection='row' alignItems='flex-end'>
          <Button
            text={modalTitle}
            icon={<MdScheduleSend color={themes.colors.white[100]} />}
            disabled={!isFormValid || isLoading}
            onClick={handleSubmit}
          />
        </ModalFooter>
      </ModalContent>
    </NewModal>
  );
};
