import React, { useEffect } from 'react';
import { Button } from 'react-covideo-common';
import { NewModal } from 'lib/components';

import { MdDeleteForever } from 'react-icons/md';
import { useIsMutating, useQueryClient } from 'react-query';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphNormal } from 'lib/components/styles/typography';
import { useDeleteLeadsFromGroupMutation } from 'lib/api/leadGroups/useDeleteLeadsFromGroupMutation';
import { leadsKeys } from 'lib/api/leads/queryKeys';

type Props = {
  handleModalClose: () => void;
  selectedLead: number[];
  leadGroupId: number;
  onSuccessCallback: () => void;
};

export const ModalRemoveLeadFromGroup = ({
  handleModalClose,
  selectedLead,
  leadGroupId,
  onSuccessCallback,
}: Props) => {
  const queryClient = useQueryClient();
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;

  const { mutateAsync, isSuccess, isLoading } =
    useDeleteLeadsFromGroupMutation();

  useEffect(() => {
    if (isSuccess) {
      queryClient.refetchQueries(leadsKeys.all());
      onSuccessCallback();
      handleModalClose?.();
    }
  }, [isSuccess]);

  const isMultiple = selectedLead.length > 1;

  return (
    <NewModal
      closeModal={handleModalClose}
      headerText={`Remove Customer${isMultiple ? 's' : ''}`}
      style={{
        content: {
          minWidth: 780,
        },
      }}
    >
      <Gap justifyContent='column'>
        <ParagraphNormal>
          Are you sure you want to remove customer{isMultiple ? 's' : ''} from
          group?
        </ParagraphNormal>
        <Gap width='100%' justifyContent='flex-end'>
          <Button
            onClick={handleModalClose}
            text='Cancel'
            variant='secondary'
            disabled={isDisabled || isLoading}
          />
          <Button
            onClick={() =>
              mutateAsync({ leadGroupId: leadGroupId, leads: selectedLead })
            }
            icon={<MdDeleteForever size={20} />}
            variant='destructive'
            text='Remove'
            disabled={isDisabled || isLoading}
          />
        </Gap>
      </Gap>
    </NewModal>
  );
};
