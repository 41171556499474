import {
  createFolder,
  deleteFolder,
  downloadVideo,
  duplicateVideo,
  getFolders,
  updateFolder,
  getVideos,
} from 'lib/api';
import {
  CheckboxInput,
  FolderDropdown,
  ListDropdownMenu,
  LoadingIndicator,
  Search,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import {
  ModalMoveCopy,
  ModalVideoMergeListVideo,
  ModalWheelsTV,
} from 'lib/components/modal';
import { CEModalFolders } from 'lib/components/modal/foldersModals/CE_Modal';
import { ModalDeleteFolder } from 'lib/components/modal/foldersModals/Delete';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import {
  AccessRole,
  AutomotiveRole,
  VideoEmailStatus,
  VideoStatus,
} from 'lib/const';
import {
  CDSVideoListFilter,
  VideoListAutomotiveItem,
  useAuth,
} from 'lib/context';
import { useToastError } from 'lib/hooks';
import { debounce, keyBy } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { IoMdLock } from 'react-icons/io';
import { MdAddToPhotos, MdDeleteForever, MdEdit, MdLock } from 'react-icons/md';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import Select from 'react-select';
import styled from 'styled-components/macro';
import { theme } from '../../../../../../lib/style';
import { ALL_FOLDERS, DEFAULT_FOLDER } from 'lib/const/Folders';
import { VideoItem, VideoItemCompany } from '../../components';
import { VideoItemType } from '../../components/VideoItem';
import { ModalFilterVideos, SelectedVideos } from '../components';
import {
  PackageNameLabel,
  checkIfFeatureIsEnabled,
  productFeature,
  VideoLimitStatus,
} from 'lib/utils/productFeature';
import { useGetFolderAccess } from 'lib/api/folderAccess/getFolderAccess';
import { ShareFoldersEditModal } from 'lib/components/folders/ShareFoldersEditModal';
import { ResellerModal } from 'lib/components/folders/ResellerModal';
import { ShareFoldersModal } from 'lib/components/folders/ShareFoldersModal';
import ResellerEditModal from 'lib/components/folders/ResellerEditModal';
import {
  DEFAULT_COMPANY_FOLDER,
  SHARED_FOLDER_PREFIX,
} from 'lib/const/Folders';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { checkIfFreemium } from 'lib/utils/functions';
import { ModalLeaveFolder } from 'lib/components/modal/ModalLeaveFolder';
import {
  FOLDER_ACCESS,
  FOLDER_GROUP_LABEL,
  FOLDER_GROUP_VALUE,
} from '../constants';
import selectors from '../../../../../../cypress/selectors';
import { Button } from 'react-covideo-common';
import { FaFilter } from 'react-icons/fa';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { IoCarSport } from 'react-icons/io5';
import { ModalLesaVideos } from 'lib/components/modal/lesaModal/ModalLesaVideos';
import { useEditVideoRequestMutation } from 'lib/api/videoRequests/useEditVideoRequestMutation';
import { videoRequestKeys } from 'lib/api/videoRequests/videoRequestKeys';
import { Folder } from 'lib/api/folders/getFolders';
import { useVideoLimit } from 'lib/context/videolimit/VideoLimitContext';
import {
  useDeleteVideoMutation,
  deleteVideoWithRO,
} from 'lib/api/videos/useDeleteVideoMutation';

const CheckVideoListItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
const CheckboxInputWrapper = styled.div`
  margin-right: 16px;
  margin-top: 38px;
`;
const CheckboxInputPlaceholder = styled.div`
  margin-right: 24px;
`;
const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 40px;

  .countRecord {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.82;
  }
  .textRed {
    color: red;
  }
  .textAmber {
    color: #ff8b21;
  }
`;
type FolderFilterProps = {
  width: string;
};
const FolderFilter = styled.div<FolderFilterProps>`
  .dropdown {
    width: ${props => (props.width ? props.width : '200px')};
    & > div {
      width: ${props => (props.width ? props.width : '200px')};
      border-radius: 4px;
    }
  }
  ${theme.mediaQueryMinWidth.sm} {
    margin: 0;
    &:nth-child(n + 2) {
      margin: 0 0 0 24px;
    }
  }
`;
const Filter = styled.div<{ minWidth?: string }>`
  margin-right: 8px;
  min-width: ${props => props.minWidth || '180px'};
`;
const NoList = styled.div`
  padding-left: 40px;
`;

const PaginationWrapper = styled.div`
  padding-left: 40px;
`;

const SharedInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 40px;
  gap: 8px;
`;
const SharedTitle = styled.div`
  color: var(--covideo-grey-80, ${theme.palette.gray80});
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
`;

const LeaveTitle = styled.div`
  color: var(--red-80, ${theme.palette.red80});
  font-variant-numeric: lining-nums tabular-nums;
  /* Paragraph/Small Link Bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  cursor: pointer;
`;

type FolderWrapperProps = {
  marginRight: string;
};
const FoldersWrapper = styled.div<FolderWrapperProps>`
  display: flex;
  gap: 6px;
  margin-right: ${props => props.marginRight};
  border-radius: 6px;
  .delete,
  .edit {
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 8px;
  }
  .edit:hover {
    background: #eeeff2;
    color: #272a32 !important;
  }
  .delete:hover {
    background: #fef6f5;
    color: #e84c3d !important;
  }
  div[id$='option-0'] {
    color: ${theme.palette.blue100};
    margin-top: -4px;
    height: 44px;
    border-bottom: 1px solid ${theme.palette.lightgray};
  }
`;

const DropdownElement = styled.div`
  position: relative;
  cursor: pointer;
  .show-more-dropdown {
    right: 0;
  }
`;

const IconWrapper = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  border: 1px solid ${theme.palette.blue05};
  border-radius: 5px;
  svg {
    fill: ${theme.palette.primaryDarkBlue};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.secondary['non_alpha']};
    border: 1px solid ${theme.palette.blue05};
    svg {
      fill: ${theme.palette.primaryDarkBlue};
    }
  }
`;
const CustomListDropdownMenu = styled(ListDropdownMenu)`
  width: 270px;
  right: 0px;
  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 6px 12px rgba(66, 79, 104, 0.06);
  border-radius: 8px;
  padding: 8px 0px;
`;

const DisabledMergeContainer = styled.div`
  margin: 0 0 0 auto;
  button {
    cursor: not-allowed;
  }
`;

const ActionWrapper = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;

const DEFAULT_PAGE = 0;
const DEFAULT_SIZE = 10;
const MAX_PINNED_VIDEOS_COUNT = 5;

export const List = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [foldersLoading, setFoldersLoading] = useState<boolean>(false);
  const history = useHistory();
  const { showError } = useToastError();
  const { userData } = useAuth();
  const { isAutomotive, isAutomotiveServiceRole, trialExpired } = userData;
  const [selectedVideosData, setSelectedVideosData] = useState<any[]>([]);
  const [showModalFilterVideos, setShowModalFilterVideos] = useState(false);
  const [showWTVModal, setWTVModal] = useState(false);
  const [showLesaModal, setShowLesaModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [videoMergeEnabled, setVideoMergeEnabled] = useState(false);
  const [showModalMoveCopyForVideos, setShowModalMoveCopyForVideos] = useState<
    string[]
  >([]);
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const isFolderSharingEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.FOLDER_SHARING
  );
  const [showLeaveFolderModal, setShowLeaveFolderModal] = useState(false);
  const [pinnedVideos, setPinnedVideos] = useState<VideoListAutomotiveItem[]>(
    []
  );
  const { videoLimitStatus, setVideoLimitStatus } = useVideoLimit();
  const [disablePinButton, setDisablePinButton] = useState(false);
  const { mutateAsync: deleteVideos } = useDeleteVideoMutation();

  // all filters are fetched from the URL
  let filter: CDSVideoListFilter = {
    videoType: queryParams.videoType || '',
    userId: queryParams.userId || '',
    advisorId: queryParams.advisorId || '',
    status: queryParams.status || VideoStatus.NoStatus,
    emailStatus: queryParams.emailStatus || VideoEmailStatus.NoStatus,
    folderId: queryParams.folderId || DEFAULT_FOLDER.folderId,
    page: queryParams.page
      ? Math.abs(parseInt(queryParams.page?.toString(), 10))
      : DEFAULT_PAGE,
    size: queryParams.size
      ? Math.abs(parseInt(queryParams.size?.toString(), 10))
      : DEFAULT_SIZE,
    searchQuery: queryParams.searchQuery || '',
  };

  //  status filtering disabled for company folder, reset status when folder set to company
  if (filter.folderId && filter.folderId.toString() === '-1') {
    filter.status = '';
    filter.emailStatus = '';
  }

  // count some filters to show on Filter Videos button
  const filterCount =
    (!!filter.videoType ? 1 : 0) +
    (!!filter.userId ? 1 : 0) +
    (!!filter.advisorId ? 1 : 0) +
    (!!filter.status ? 1 : 0) +
    (!!filter.emailStatus ? 1 : 0);

  const { data: folders, refetch: refreshFolderList } = useQuery(
    'cds-folders',
    () => getFolders({ getShared: true }),
    {
      staleTime: 500_000_000,
      select: folders =>
        userData.automotiveRole === AutomotiveRole.TECHNICIAN
          ? folders.filter(folder => {
              return folder.name !== 'Vault' && folder.name !== 'Private';
            })
          : folders,
    }
  );

  const foldersDictionary = folders ? keyBy(folders, 'folderId') : {};
  const currentFolder =
    foldersDictionary[filter.folderId ?? ''] || DEFAULT_FOLDER;

  let folderName =
    filter.folderId && foldersDictionary[filter.folderId]
      ? foldersDictionary[filter.folderId].name
      : DEFAULT_FOLDER.name;
  folderName =
    folderName === ALL_FOLDERS.label ? ALL_FOLDERS.value : folderName;

  const {
    data,
    refetch: refreshVideoList,
    isLoading: showLoading,
  } = useQuery(
    ['cds-videos', filter, currentFolder],
    async () =>
      await getVideos({
        folder: getFolderParam(),
        size: filter.size,
        page: filter.page,
        search: filter.searchQuery,
        filter,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!folders?.length,
      onSuccess: () => setSelectedVideosData([]),
    }
  );

  const { mutateAsync: editVideoRequestMutation } =
    useEditVideoRequestMutation();

  const emptyArray = React.useMemo(() => [], []);
  const { count: videoCount, videos } = data || {
    count: 0,
    videos: emptyArray,
  };
  const videoArray: VideoListAutomotiveItem[] =
    videos as unknown as VideoListAutomotiveItem[];

  const videoDictionary = keyBy(videoArray, 'id');
  const videoRequests = videoArray?.map(video => video.videoRequest);
  const videoRequestDictionary = keyBy(videoRequests, 'videoRequestId');

  /* SUS-1282 changes */
  const [disableDownloadButton, setDisableDownloadButton] =
    React.useState(false);
  const [totalSelectedDownloads, setTotalSelectedDownloads] = React.useState(0);
  const [downloadingCounter, setDownloadingCounter] = React.useState(0);

  const [showShareFoldersUsers, setShowShareFoldersUsers] =
    React.useState<boolean>(false);
  const [showShareFoldersResellers, setShowShareFoldersResellers] =
    React.useState<boolean>(false);
  const [showEditShareFolderModal, setShowEditShareFolderModal] =
    React.useState<boolean>(false);
  const [showEditResellerModal, setShowEditResellerModal] =
    React.useState(false);
  const { data: folderAccess, refetch: refetchFolderAccess } =
    useGetFolderAccess(Number(currentFolder.folderId) || 0);
  const [countColor, setCountColor] = useState('');
  const [videoCounter, setVideoCounter] = useState('');

  const isFolderShared = !!currentFolder.access;
  const folderHasSharedUsers =
    Array.isArray(folderAccess) && folderAccess.length;

  const hasUserCopyAccess = currentFolder.access === 'copy';
  const hasUserViewAccess = currentFolder.access === 'view';
  const hasUserEditAccess = currentFolder.access === 'edit';
  const selectedUsers: string[] | undefined = []; // for folder access
  const hideFolderSharingOption =
    userData.reseller.restrictFolderSharingToAdmins?.toString() === '1' &&
    userData.access !== AccessRole.ADMIN;
  const handleOpenModal = () => {
    refreshFolderList();
    if (Array.isArray(folderAccess)) {
      if (folderAccess.length !== 0) {
        userData.isResellerAdmin
          ? setShowEditResellerModal(true)
          : setShowEditShareFolderModal(true);
        return;
      }
    }
    userData.isResellerAdmin
      ? setShowShareFoldersResellers(true)
      : setShowShareFoldersUsers(true);
    return;
  };
  let moreDropdownItems = [
    {
      title: 'Rename',
      icon: (
        <MdEdit
          color={theme.palette.midGrey}
          style={{ width: 20, height: 20 }}
        />
      ),
      onClick: () => openEditFolderModal(),
    },
    {
      title: 'Share',
      subtitle: folderHasSharedUsers
        ? `Shared with ${folderAccess && folderAccess?.length} users`
        : 'Not Shared',
      icon: (
        <MdLock
          color={theme.palette.gray60}
          style={{ width: 20, height: 20 }}
        />
      ),
      onClick: () => handleOpenModal(),
      productFeatureId: productFeature.FOLDER_SHARING,
      isDisabled: !isFolderSharingEnabled,
      productTooltipStyle: {
        right: '0px',
        left: '250px',
        top: '77px',
      },
      nextPlan: PackageNameLabel.TEAMS,
    },
    {
      title: 'Delete',
      titleColor: theme.palette.primaryRedDanger,
      icon: (
        <MdDeleteForever
          color={theme.palette.primaryRedDanger}
          style={{ width: 20, height: 20 }}
        />
      ),
      onClick: () => openDeleteFolderModal(),
    },
  ];

  if (hideFolderSharingOption) {
    moreDropdownItems = moreDropdownItems.filter(
      item => item.title !== 'Share'
    );
  }

  useEffect(() => {
    if (userData.access.toString() === '4') {
      history.push('/reports/users');
    }
  }, [userData]);

  useEffect(() => {
    if (!totalSelectedDownloads) {
      return;
    }
    setDisableDownloadButton(totalSelectedDownloads !== downloadingCounter);
  }, [totalSelectedDownloads, downloadingCounter]);

  useEffect(() => {
    setVideoMergeEnabled(
      checkIfFeatureIsEnabled(userData, productFeature.VIDEO_MERGE)
    );
  }, [userData]);

  useEffect(() => {
    if (showLoading) {
      return;
    }
    setPinnedVideos(videoArray.filter(video => !!video.pinnedVideo?.pinnedAt));
  }, [videoArray, showLoading]);

  useEffect(() => {
    if (showLoading) {
      return;
    }
    const selectedPinnedVideos = selectedVideosData.filter(video => {
      return !!video.pinnedAt;
    });

    const selectedUnpinnedVideosCount =
      selectedVideosData.length - selectedPinnedVideos.length;
    const currentCountForPin =
      pinnedVideos.length + selectedUnpinnedVideosCount;
    setDisablePinButton(currentCountForPin > MAX_PINNED_VIDEOS_COUNT);
  }, [pinnedVideos, selectedVideosData, showLoading]);

  /******/

  const options =
    folders
      ?.filter(Boolean)
      .filter((f: Folder) => f.folderId !== ALL_FOLDERS.value)
      .map((f: any) => ({
        value: f.folderId,
        label: f.name,
      }))
      .concat([
        {
          value: ALL_FOLDERS.value,
          label: ALL_FOLDERS.label,
        },
      ]) || [];

  const getFolderParam = () => {
    return currentFolder && isFolderShared
      ? `${SHARED_FOLDER_PREFIX}${currentFolder.folderId}`
      : folderName;
  };
  const addFolderIDToUrl = (folderId: string | number) => {
    // reset to first page if folder set in case filtered results don't have multiple pages
    queryParams.page = DEFAULT_PAGE;
    queryParams.folderId = folderId;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const onSearch = (query: string) => {
    // reset to first page if search set in case filtered results don't have multiple pages
    queryParams.page = DEFAULT_PAGE;
    queryParams.searchQuery = query;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const handleReassignSubmit = async (
    advisorId: string,
    videos: any[],
    advisor: any
  ) => {
    const advisorName =
      advisor.firstName && advisor.lastName
        ? `${advisor.firstName} ${advisor.lastName}`
        : 'New Advisor';
    const videoRequestIds = videos.map((video: any) => {
      return videoDictionary[video.id].videoRequest.videoRequestId;
    });
    await Promise.all(
      videoRequestIds.map(async (videoRequestId: string | number) => {
        await editVideoRequestMutation({
          data: {
            advisorId: advisorId,
            status:
              videoRequestDictionary[videoRequestId].emailStatus ===
              VideoEmailStatus.NotSent
                ? VideoStatus.Pending
                : videoRequestDictionary[videoRequestId].status,
          },
          videoRequestId,
        });
      })
    );
    refreshVideoList();
    queryClient.refetchQueries(videoRequestKeys.count_pending());
    successToast({
      title: `${advisorName} is now assigned to ${
        videos.length > 1 ? 'these videos' : 'this video'
      }!`,
    });
  };

  const handleDeleteSubmitSelected = async () => {
    if (isAutomotive && currentFolder.name !== DEFAULT_COMPANY_FOLDER) {
      for (const video of selectedVideosData) {
        if (video?.videoRequest?.videoRequestId) {
          await deleteVideoWithRO(video.videoRequest.videoRequestId);
        }
      }
      successToast({
        title: `Video${
          selectedVideosData.length > 1 ? 's' : ''
        } successfully moved to recently deleted bin`,
      });
    } else {
      await handleDeleteSubmit(
        selectedVideosData.map(video => video.id),
        isFolderShared
          ? hasUserCopyAccess || hasUserViewAccess
          : currentFolder.name === DEFAULT_COMPANY_FOLDER
      );
    }
    refreshVideoList();
  };

  const handleDeleteSubmit = async (
    videoIds: string[],
    companyVideos: boolean = false
  ) => {
    try {
      await deleteVideos({ videoIds, deleteMultiple: true, companyVideos });
      refreshVideoList();
    } catch (error) {
      errorToast({
        title: error.message || 'Something went wrong',
      });
    }
  };

  const handleDeleteWithROSubmit = async (videoId: string) => {
    await deleteVideoWithRO(videoId);
    refreshVideoList();
    successToast({
      title: `Video successfully moved to recently deleted bin`,
    });
  };

  const handleDuplicate = async (video: any) => {
    try {
      await duplicateVideo({
        videosToDuplicate: [video.id],
      });
      successToast({
        title: `Duplicated: ${video.title}`,
      });
    } catch (error) {
      showError(error);
    }

    refreshVideoList();
  };

  const handleMerge = (videoIds: string) => {
    const url = `/classic/merge?videos=${videoIds}`;
    history.push(url);
  };

  const handleDownload = async (
    downloadData: { videoId: string; title: string }[]
  ) => {
    /** SUS-1282 Changes **/
    if (disableDownloadButton) {
      successToast({
        title: 'Downloading is in progress...',
      });
      return;
    }
    setDownloadingCounter(0);
    setTotalSelectedDownloads(downloadData.length);
    /*****/
    downloadData.map((d: any, index: number) =>
      setTimeout(async () => {
        /** SUS-1282 Changes **/
        if (index === 0) {
          successToast({
            title: 'Download Started...',
          });
        }
        /*****/
        await downloadVideo(d.videoId, d.title);
        /** SUS-1282 Changes **/
        setDownloadingCounter(previous => {
          return previous + 1;
        });
        /*****/
      }, index * 500)
    );
  };

  const handleMoveCopyButtonClick = async (videoIds: string[]) => {
    setShowModalMoveCopyForVideos(videoIds);
  };

  const handleMoveCopyDone = () => {
    setShowModalMoveCopyForVideos([]);
    refreshFolderList();
    refreshVideoList();
  };

  // short debounce so you have time to add 2 digit page number
  const onPaginationChange = debounce(
    async ({
      page: newPage,
      size: newSize,
    }: {
      page: number;
      size: number;
    }) => {
      queryParams.page = Math.abs(newPage);
      queryParams.size = Math.abs(newSize);
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(queryParams),
      });
    },
    300
  );
  const openCreateFolderModal = () => {
    setShowCreateFolderModal(true);
  };
  const closeCreateFolderModal = () => {
    setShowCreateFolderModal(false);
  };
  const openEditFolderModal = () => {
    setShowEditFolderModal(true);
  };
  const closeEditFolderModal = () => {
    setShowEditFolderModal(false);
  };
  const openDeleteFolderModal = () => {
    setShowDeleteFolderModal(true);
  };
  const closeDeleteFolderModal = () => {
    setShowDeleteFolderModal(false);
  };

  const handleFolderCreate = async (folder: Partial<Folder>) => {
    try {
      setFoldersLoading(true);
      const newFolder = await createFolder(folder);
      await refreshFolderList();
      setFoldersLoading(false);
      closeCreateFolderModal();
      successToast({ title: 'Folder created successfully.' });
      addFolderIDToUrl(newFolder.folderId);
    } catch (error) {
      showError(
        error,
        'An error occured creating a folder, please try again later.'
      );
      setFoldersLoading(false);
    }
  };

  const handleFolderEdit = async (folder: Partial<Folder>) => {
    try {
      setFoldersLoading(true);
      const updatedFolder = { ...currentFolder, ...folder };
      await updateFolder(updatedFolder.folderId as number, updatedFolder);
      await refreshFolderList();
      setFoldersLoading(false);
      closeEditFolderModal();
      successToast({ title: 'Folder updated successfully.' });
    } catch (error) {
      showError(error);
      setFoldersLoading(false);
    }
  };

  const handleFolderDelete = async (data: any) => {
    try {
      await deleteFolder(currentFolder.folderId as number, data);
      await refreshFolderList();
      closeDeleteFolderModal();
      successToast({ title: 'Folder deleted successfully.' });
      addFolderIDToUrl(-2);
    } catch (error) {
      showError(
        error,
        'An error occured deleting your folder, please try again later.'
      );
      setFoldersLoading(false);
    }
  };

  const isWTVEnabled = checkIfFeatureIsEnabled(userData, productFeature.WTV);
  const isFolderOrganizationEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.FOLDER_ORGANIZATION
  );

  useEffect(() => {
    const userPackageDetails = userData?.user?.packageDetails;
    setVideoCounter(
      `${videoCount} / ${userPackageDetails.maxVideosCreated} Videos`
    );
    if (userPackageDetails.maxVideosCreated) {
      const usedLimitQuotaPercentage =
        (videoCount / parseInt(`${userPackageDetails.maxVideosCreated}`)) * 100;

      if (usedLimitQuotaPercentage > 80 && usedLimitQuotaPercentage < 100) {
        setCountColor('textAmber');
      } else if (usedLimitQuotaPercentage >= 100) {
        setCountColor('textRed');
      }

      let videoLimitStatus = VideoLimitStatus.NOT_REACHED;
      if (videoCount < userPackageDetails.maxVideosCreated) {
        videoLimitStatus = VideoLimitStatus.NOT_REACHED;
      } else if (videoCount === userPackageDetails.maxVideosCreated) {
        videoLimitStatus = VideoLimitStatus.REACHED;
      } else {
        videoLimitStatus = VideoLimitStatus.EXCEEDED;
      }

      setVideoLimitStatus(videoLimitStatus);
    }
  }, [videoCount, userData]);

  const showVideoCount = checkIfFreemium(userData);

  const handleOnMarkAsSentSuccessCallback = async () => {
    await refreshVideoList();
  };

  let folderOptions: {
    value: number | string;
    label: string;
    isFolderShared?: boolean;
    access: boolean;
  }[] = [];
  if (isFolderOrganizationEnabled && !isAutomotiveServiceRole) {
    let ownedFolders =
      folders
        ?.filter(
          folder =>
            !!!folder.hasOwnProperty('access') &&
            folder.folderId !== ALL_FOLDERS.value
        )
        .map(folder => ({
          value: folder.folderId,
          label: folder.name,
          isFolderShared: !!folder.isFolderShared,
          access: !!folder.hasOwnProperty('access'),
        })) || [];
    let sharedFolders =
      folders
        ?.filter(folder => !!folder.hasOwnProperty('access'))
        .map(folder => ({
          value: folder.folderId,
          label: folder.name,
          isFolderShared: !!folder.isFolderShared,
          access: !!folder.hasOwnProperty('access'),
          sharedBy: folder.sharedBy,
        })) || [];
    folderOptions = [
      {
        value: ALL_FOLDERS.value,
        label: ALL_FOLDERS.label,
        isFolderShared: false,
        access: false,
      },
    ].concat([
      {
        value: FOLDER_GROUP_VALUE.MY_FOLDERS,
        label: FOLDER_GROUP_LABEL.MY_FOLDERS,
        isFolderShared: false,
        access: false,
      },
    ]);

    if (ownedFolders.length) {
      folderOptions = folderOptions.concat(ownedFolders);
    }

    if (sharedFolders.length) {
      folderOptions = folderOptions.concat(
        [
          {
            value: FOLDER_GROUP_VALUE.SHARED_WITH_ME,
            label: FOLDER_GROUP_LABEL.SHARED_WITH_ME,
            isFolderShared: false,
            access: false,
          },
        ],
        sharedFolders
      );
    }
  }
  return (
    <div data-cy={selectors.libraryPage.videoListTable}>
      {showShareFoldersUsers && currentFolder && (
        <ShareFoldersModal
          handleModalClose={() => {
            setShowShareFoldersUsers(false);
            refetchFolderAccess();
            refreshFolderList();
          }}
          initUsers={selectedUsers}
          folderId={currentFolder.folderId.toString()}
          folder={currentFolder}
        />
      )}
      {showShareFoldersResellers && currentFolder && (
        <ResellerModal
          handleModalClose={() => {
            setShowShareFoldersResellers(false);
            refetchFolderAccess();
          }}
          initUsers={selectedUsers}
          folderId={currentFolder.folderId.toString()}
        />
      )}
      {showEditShareFolderModal && folderAccess && currentFolder && (
        <ShareFoldersEditModal
          handleModalClose={() => {
            setShowEditShareFolderModal(false);
            refetchFolderAccess();
            refreshFolderList();
          }}
          initUsers={selectedUsers}
          accessUsers={folderAccess}
          folderId={currentFolder.folderId.toString()}
          isResellerAdmin={userData.isResellerAdmin}
          folder={currentFolder}
        />
      )}
      {showEditResellerModal && currentFolder && (
        <ResellerEditModal
          handleModalClose={() => {
            setShowEditResellerModal(false);
          }}
          folderId={currentFolder.folderId.toString() || ''}
          folder={currentFolder}
        />
      )}
      {!showLoading && !!selectedVideosData.length && !hasUserViewAccess && (
        <SelectedVideos
          videoArray={videoArray}
          selectedVideos={selectedVideosData}
          setSelectedVideos={setSelectedVideosData}
          handleReassignSubmit={handleReassignSubmit}
          handleDeleteSubmit={handleDeleteSubmitSelected}
          handleMerge={handleMerge}
          handleDownload={handleDownload}
          handleMoveCopyButtonClick={handleMoveCopyButtonClick}
          disableDownloadButton={disableDownloadButton} /* SUS-1282 changes */
          refreshVideoList={refreshVideoList}
          folderAccess={currentFolder?.access ?? ''}
          isFolderShared={isFolderShared}
          videoLimitStatus={videoLimitStatus}
          disablePinButton={disablePinButton}
          currentFolder={currentFolder}
        />
      )}
      <>
        <Container>
          <MainWrapper resetPadding>
            <HelpDesk name={EHelpDesk.VIDEOS} />
            <FiltersContainer>
              <Filter>
                <Search
                  placeholder='Search videos...'
                  onSearch={onSearch}
                  prevSearch={filter.searchQuery}
                />
              </Filter>

              {isFolderOrganizationEnabled ? (
                !isAutomotiveServiceRole ? (
                  <FoldersWrapper marginRight={'8px'}>
                    <FolderFilter width='205px'>
                      <FolderDropdown
                        value={{
                          value: currentFolder?.folderId,
                          label: currentFolder?.name,
                          isFolderShared: currentFolder?.isFolderShared,
                          access: currentFolder?.access,
                        }}
                        onChange={(option: { value: string }) => {
                          const folder = option?.value;

                          if (folder === 'create') {
                            return openCreateFolderModal();
                          }

                          addFolderIDToUrl(folder ?? DEFAULT_FOLDER.folderId);
                        }}
                        options={[
                          {
                            label: 'Create New Folder',
                            value: 'create',
                            isFolderShared: false,
                            access: false,
                          },
                          ...folderOptions,
                        ]}
                      />
                    </FolderFilter>
                    {currentFolder &&
                      Number(currentFolder?.folderId) > 0 &&
                      String(currentFolder.userId) === userData.userId && (
                        <DropdownElement
                          onClick={() => setShowMoreDropdown(true)}
                          data-cy={selectors.libraryPage.sharedFolderDropdown}
                        >
                          <IconWrapper>
                            <BiDotsVerticalRounded
                              size={20}
                              color={theme.palette.covideoBlue40}
                            />
                          </IconWrapper>
                          <CustomListDropdownMenu
                            isMenuOpen={showMoreDropdown}
                            items={moreDropdownItems}
                            id={'show-more-dropdown-folder'}
                            setIsMenuOpen={() => setShowMoreDropdown(false)}
                          />
                        </DropdownElement>
                      )}
                  </FoldersWrapper>
                ) : (
                  <Filter>
                    <Select
                      styles={{
                        control: (base: any) => ({ ...base, height: '40px' }),
                        indicatorSeparator: () => ({ display: 'none' }),
                        menuPortal: provided => ({
                          ...provided,
                          zIndex: 5,
                        }),
                      }}
                      options={options}
                      menuPortalTarget={document.body}
                      menuPlacement={'bottom'}
                      value={{
                        value:
                          currentFolder?.folderId || DEFAULT_FOLDER.folderId,
                        label: currentFolder?.name || DEFAULT_FOLDER.name,
                      }}
                      onChange={option =>
                        addFolderIDToUrl(
                          option?.value ?? DEFAULT_FOLDER.folderId
                        )
                      }
                      isSearchable={false}
                    />
                  </Filter>
                )
              ) : (
                <></>
              )}

              {isAutomotive && (
                <Filter minWidth='150px'>
                  <Button
                    variant='secondary'
                    disabled={trialExpired}
                    text={
                      filterCount
                        ? `${filterCount} Active Filter${
                            filterCount > 1 ? 's' : ''
                          }`
                        : 'Filter Videos'
                    }
                    onClick={() => setShowModalFilterVideos(true)}
                    icon={<FaFilter size={18} />}
                  />
                </Filter>
              )}

              {showVideoCount && (
                <div className={`countRecord ${countColor}`}>
                  {videoCounter}
                </div>
              )}

              <ActionWrapper>
                {videoMergeEnabled ? (
                  <Button
                    variant='secondary'
                    text='Merge Videos'
                    icon={<MdAddToPhotos width={24} height={24} />}
                    onClick={() => setShowMergeModal(true)}
                    disabled={trialExpired}
                  />
                ) : (
                  <DisabledMergeContainer>
                    <ProductInfoTooltip
                      width={'fit-content'}
                      style={{
                        left: '-70px',
                        top: '-50px',
                      }}
                      productFeatureId={productFeature.VIDEO_MERGE}
                      nextPlan={PackageNameLabel.TEAMS}
                    >
                      <Button
                        icon={<IoMdLock width={24} height={24} />}
                        variant='secondary'
                        text={'Merge Videos'}
                        onClick={() => {}}
                        disabled={true}
                      />
                    </ProductInfoTooltip>
                  </DisabledMergeContainer>
                )}

                {!isAutomotiveServiceRole &&
                  !!Number(userData.customer.wheelsTV) &&
                  (isWTVEnabled ? (
                    <Button
                      variant='secondary'
                      text='WheelsTV'
                      icon={<IoCarSport size={24} />}
                      onClick={() => setWTVModal(true)}
                      disabled={trialExpired}
                      data-cy={selectors.libraryPage.wheelsTvButton}
                    />
                  ) : (
                    <ProductInfoTooltip
                      width={'fit-content'}
                      style={{
                        left: '-70px',
                        top: '-50px',
                      }}
                      productFeatureId={productFeature.WTV}
                      nextPlan={PackageNameLabel.ENTERPRISE}
                    >
                      <Button
                        icon={<IoMdLock width={24} height={24} />}
                        variant='secondary'
                        text={'WheelsTV'}
                        onClick={() => {}}
                        disabled={true}
                      />
                    </ProductInfoTooltip>
                  ))}

                {!!Number(userData.customer.lesaAccess) && (
                  <Button
                    variant='secondary'
                    text='Lesa'
                    icon={<IoCarSport size={24} />}
                    onClick={() => setShowLesaModal(true)}
                    disabled={trialExpired}
                  />
                )}
              </ActionWrapper>
            </FiltersContainer>
            {currentFolder?.access && (
              <SharedInfoContainer>
                <SharedTitle>Owner: {currentFolder?.sharedBy}</SharedTitle>
                <SharedTitle>Shared By: {currentFolder?.sharedBy}</SharedTitle>
                <LeaveTitle
                  onClick={() => {
                    setShowLeaveFolderModal(true);
                  }}
                >
                  Leave Folder
                </LeaveTitle>
              </SharedInfoContainer>
            )}
            {showLesaModal && (
              <ModalLesaVideos
                handleModalClose={() => setShowLesaModal(false)}
                onCopy={() => {
                  addFolderIDToUrl(DEFAULT_FOLDER.folderId.toString());
                  refreshVideoList();
                }}
              />
            )}
            {showWTVModal && (
              <ModalWheelsTV
                handleModalClose={() => setWTVModal(false)}
                onCopy={() => {
                  addFolderIDToUrl(DEFAULT_FOLDER.folderId.toString());
                  refreshVideoList();
                }}
              />
            )}
            {showLoading && (
              <LoadingIndicator isLoading={showLoading} height='300px' />
            )}
            {!showLoading && !!videoArray.length && (
              <TableContextProvider
                total={videoCount}
                onChange={onPaginationChange}
                initPage={Math.abs(filter.page)}
                initSize={Math.abs(filter.size)}
              >
                {videoArray.map((video: VideoListAutomotiveItem, index) => (
                  <CheckVideoListItemWrapper
                    data-cy='video-list-item'
                    key={`video-list-item-${index}`}
                  >
                    <CheckboxInputWrapper>
                      {video?.videoRequest?.emailStatus !== 'deleted' &&
                      !hasUserViewAccess ? (
                        <CheckboxInput
                          width='24px'
                          blueCheck={true}
                          disabled={trialExpired}
                          checked={selectedVideosData.includes(video)}
                          dataCy={selectors.libraryPage.videoItemCheckbox}
                          onChange={(e: React.SyntheticEvent) => {
                            let { checked } = e.target as HTMLInputElement;
                            if (checked) {
                              setSelectedVideosData([
                                ...selectedVideosData,
                                video,
                              ]);
                            } else {
                              setSelectedVideosData(
                                selectedVideosData.filter(
                                  (e: any) => e.id != video.id
                                )
                              );
                            }
                          }}
                        />
                      ) : (
                        <CheckboxInputPlaceholder />
                      )}
                    </CheckboxInputWrapper>
                    {(isFolderShared
                      ? hasUserCopyAccess || hasUserViewAccess
                      : video?.folder === DEFAULT_COMPANY_FOLDER ||
                        video?.access === FOLDER_ACCESS.COPY) &&
                      video?.videoRequest?.emailStatus !==
                        VideoEmailStatus.Deleted && (
                        <VideoItemCompany
                          key={'video' + index}
                          recordedBy={video.user}
                          video={video}
                          videoRequest={video?.videoRequest}
                          handleDownload={handleDownload}
                          access={currentFolder.access || ''}
                          handleDeleteSubmit={videoIds =>
                            handleDeleteSubmit(videoIds, true)
                          }
                          disablePinOption={
                            pinnedVideos.length >= MAX_PINNED_VIDEOS_COUNT
                          }
                          currentFolder={currentFolder}
                          isFolderShared={isFolderShared}
                          refreshVideoList={refreshVideoList}
                        />
                      )}
                    {(isFolderShared
                      ? hasUserEditAccess
                      : video?.folder !== DEFAULT_COMPANY_FOLDER &&
                        video?.access !== FOLDER_ACCESS.COPY) && (
                      <VideoItem
                        key={video.id}
                        videoItemType={VideoItemType.Video}
                        recordedBy={video.user}
                        assignedTo={
                          isAutomotive
                            ? video?.videoRequest?.advisor
                            : undefined
                        }
                        video={video}
                        videoRequest={
                          isAutomotive ? video?.videoRequest : undefined
                        }
                        repairOrder={
                          isAutomotive ? video?.repairOrder : undefined
                        }
                        handleReassignSubmit={handleReassignSubmit}
                        handleDeleteSubmit={handleDeleteSubmit}
                        handleDeleteWithROSubmit={handleDeleteWithROSubmit}
                        handleMerge={handleMerge}
                        handleDownload={handleDownload}
                        handleDuplicate={handleDuplicate}
                        handleMoveCopyButtonClick={handleMoveCopyButtonClick}
                        refreshVideoList={refreshVideoList}
                        videoLimitStatus={videoLimitStatus}
                        currentFolder={currentFolder}
                        isFolderShared={isFolderShared}
                        disablePinOption={
                          pinnedVideos.length >= MAX_PINNED_VIDEOS_COUNT
                        }
                        handleOnMarkAsSentSuccessCallback={
                          handleOnMarkAsSentSuccessCallback
                        }
                      />
                    )}
                  </CheckVideoListItemWrapper>
                ))}
                <PaginationWrapper>
                  <TableFooter>
                    <TablePaginationNew />
                    <TablePaginationSizeNew text='Show videos:' />
                  </TableFooter>
                </PaginationWrapper>
              </TableContextProvider>
            )}
            {!showLoading && !videoArray.length && (
              <NoList>No videos to show.</NoList>
            )}
          </MainWrapper>
        </Container>
        {showModalFilterVideos && (
          <ModalFilterVideos
            handleModalClose={() => setShowModalFilterVideos(false)}
            filter={filter}
            currentFolder={currentFolder?.name}
          />
        )}
        {!!showModalMoveCopyForVideos.length && (
          <ModalMoveCopy
            videoId={showModalMoveCopyForVideos}
            foldersData={
              folders?.filter(
                (folder: Folder) => !folder.access || folder.access === 'edit'
              ) || []
            }
            currentFolder={currentFolder}
            text='Keep a copy of this video in the current folder'
            handleModalClose={() => handleMoveCopyDone()}
            userData={userData}
          />
        )}
        {(showCreateFolderModal || showEditFolderModal) && (
          <CEModalFolders
            loading={foldersLoading}
            presentFolders={folders}
            onSubmit={
              showCreateFolderModal ? handleFolderCreate : handleFolderEdit
            }
            edit={showCreateFolderModal ? undefined : { currentFolder }}
            handleModalClose={
              showCreateFolderModal
                ? closeCreateFolderModal
                : closeEditFolderModal
            }
          />
        )}
        {showDeleteFolderModal && (
          <ModalDeleteFolder
            onSubmit={handleFolderDelete}
            folders={folders}
            currentFolder={currentFolder}
            handleModalClose={closeDeleteFolderModal}
          />
        )}
        {showMergeModal && (
          <ModalVideoMergeListVideo
            handleSelectedVideos={(selectedVideos: string[] = []) => {
              history.push('/classic/merge?videos=' + selectedVideos.join(','));
            }}
            handleModalClose={() => setShowMergeModal(false)}
          />
        )}
        {showLeaveFolderModal && (
          <ModalLeaveFolder
            folderName={currentFolder?.name}
            folderId={currentFolder?.folderId}
            handleModalClose={() => setShowLeaveFolderModal(false)}
            refreshFolderList={() => {
              refreshFolderList();
              addFolderIDToUrl(DEFAULT_FOLDER.folderId);
            }}
          />
        )}
      </>
    </div>
  );
};
