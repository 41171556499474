import { ModalVideoListVideo } from 'lib/components';
import { VideoEmailStatus, VideoPages } from 'lib/const';
import { AutomotiveRole } from 'lib/const/AutomotiveRole';
import { VideoListAutomotiveItem, VideoRequest, useAuth } from 'lib/context';
import { theme } from 'lib/style';
import React, { useState } from 'react';
import { BsPinAngleFill } from 'react-icons/bs';
import { MdBarChart, MdLock } from 'react-icons/md';
import { useHistory } from 'react-router';
import styled, { useTheme } from 'styled-components/macro';
import { VideoItemActions, VideoItemStatus, VideoItemThumbnail } from '.';

import { RepairOrderItem } from 'lib/api/repairOrders/types';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import {
  checkIfUserIsServiceManager,
  checkIfUserIsTechnician,
} from 'lib/utils/automotiveRolePermissionChecks';
import { checkIfFreemium } from 'lib/utils/functions';
import { VideoLimitStatus } from 'lib/utils/productFeature';
import { serverTimeToUserTime } from 'lib/utils/time';
import { FOLDER_ACCESS } from '../videoList/constants';
import selectors from '../../../../../cypress/selectors';
import { Folder } from 'lib/api/folders/getFolders';

const Row = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  padding: 16px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid ${theme.palette.secondaryButtonBlueBg};
`;
const Left = styled.div`
  width: 130px;
  flex-shrink: 0;
`;
const Center = styled.div`
  width: 100%;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Right = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Info = styled.div`
  color: ${theme.palette.gray80};
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  > b {
    font-weight: 500;
  }
`;
const RONumber = styled.div`
  font-size: 13px;
  line-height: 24px;
  color: ${theme.palette.gray80};
  > b {
    font-weight: 500;
  }
`;
const ViewCounter = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 24px;
  color: ${theme.palette.gray80};
  cursor: pointer;
  > span {
    margin-left: 2px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
type TitleProps = {
  clickable: boolean;
};
const Title = styled.h3<TitleProps>`
  color: ${theme.palette.gray100};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  cursor: ${props => props.clickable && 'pointer'};
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const Tag = styled.div`
  background: ${theme.palette.covideoSecondaryButtonBg};
  color: ${theme.palette.blue60};
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  padding: 6px;
  margin: 0 8px 0px 0;
  border-radius: 5px;
`;
const ShowMoreTags = styled(Tag)`
  cursor: pointer;
`;
const Bull = styled.span`
  :after {
    content: '•';
    margin: 0 6px;
    color: ${theme.palette.gray80};
  }
`;

type UserAdvisor = {
  firstName: string;
  lastName: string;
};
export enum VideoItemType {
  Video = 'video',
  VideoRequest = 'videoRequest',
}
type Props = {
  videoItemType: VideoItemType;
  recordedBy: UserAdvisor;
  assignedTo: UserAdvisor;
  video: VideoListAutomotiveItem;
  videoRequest?: VideoRequest;
  repairOrder?: RepairOrderItem;
  videoLimitStatus?: VideoLimitStatus;
  handleReassignSubmit: (
    advisorId: string,
    videoIds: string[],
    advisor: any
  ) => void;
  handleDeleteSubmit?: (videoIds: string[]) => void;
  handleDeleteWithROSubmit?: (videoId: string) => void;
  handleMerge?: (videoIds: string) => void;
  handleDownload?: (downloadData: { videoId: string; title: string }[]) => void;
  handleDuplicate?: (video: any) => void;
  handleMoveCopyButtonClick?: (videoIds: string[]) => void;
  refreshVideoList?: () => void;
  disablePinOption?: boolean;
  currentFolder?: Folder | null;
  isFolderShared?: boolean;
  handleOnMarkAsSentSuccessCallback?: () => void;
};
export const VideoItem = ({
  videoItemType,
  recordedBy,
  assignedTo,
  video,
  videoRequest,
  videoLimitStatus,
  handleReassignSubmit,
  handleDeleteSubmit,
  handleDeleteWithROSubmit,
  handleMerge,
  handleDownload,
  handleDuplicate,
  repairOrder,
  handleMoveCopyButtonClick,
  refreshVideoList,
  disablePinOption,
  currentFolder = null,
  isFolderShared = false,
  handleOnMarkAsSentSuccessCallback,
}: Props) => {
  const [showMoreTags, setShowMoreTags] = useState(false);
  const [showModalPreviewVideo, setShowModalPreviewVideo] = useState(false);
  const [showModalQuickShareMarkAsSent, setShowModalQuickShareMarkAsSent] =
    useState(false);
  const { userData } = useAuth();
  const { colors } = useTheme();
  const { trialExpired } = userData;
  const history = useHistory();
  const isFreemium = checkIfFreemium(userData);
  const isUserServiceTechnician = checkIfUserIsTechnician(userData);

  const handleVideoThumbnailClick = () => {
    setShowModalPreviewVideo(true);
  };

  const handleAnalyticsClick = () => {
    if (trialExpired) {
      return;
    }
    history.push(`/classic/edit-video/${video.id}/${VideoPages.ANALYTICS}`);
  };

  const isTechnician = userData.automotiveRole === AutomotiveRole.TECHNICIAN;
  const isManager = checkIfUserIsServiceManager(userData);

  const advisorInfo = isManager ? (
    <>
      Advisor:{' '}
      <b>
        {assignedTo ? assignedTo.firstName + ' ' + assignedTo.lastName : ''}
      </b>
    </>
  ) : (
    <>
      Assigned to{' '}
      <b>
        {assignedTo ? assignedTo.firstName + ' ' + assignedTo.lastName : ''}
      </b>
    </>
  );
  const recordedInfo = isManager ? (
    <>
      Recorded:{' '}
      <b>
        {recordedBy ? recordedBy.firstName + ' ' + recordedBy.lastName : ''}
      </b>
    </>
  ) : (
    <></>
  );

  const handleViewDetailsClick = () => {
    if (trialExpired || isUserServiceTechnician) {
      return;
    }
    videoRequest?.emailStatus !== VideoEmailStatus.Deleted &&
      history.push(`/classic/edit-video/${video.id}/${VideoPages.DETAILS}`);
  };

  const clientTime = serverTimeToUserTime(
    video.recordDate,
    userData,
    `${STANDARD_DATE_FORMAT} hh:mm:ss A`,
    false
  );

  return (
    <>
      <Row>
        <Left>
          <VideoItemThumbnail
            handleClick={() => handleVideoThumbnailClick()}
            videoId={video.id}
            thumbnail={video.thumbnail}
            duration={video.videoLength || 0}
            emailStatus={videoRequest?.emailStatus}
            processing={video.processing}
            refreshVideoList={refreshVideoList}
          />
          {!isFreemium &&
            (videoRequest?.status ? (
              <VideoItemStatus
                videoId={video.id}
                status={videoRequest?.status}
                emailStatus={videoRequest.emailStatus as VideoEmailStatus}
                videoItemType={videoItemType}
                automotiveRole={userData.automotiveRole}
              />
            ) : (
              <VideoItemStatus videoId={video.id} />
            ))}
        </Left>
        <Center>
          <TitleWrapper>
            {!!video.pinnedVideo?.pinnedAt && (
              <>
                <BsPinAngleFill color={colors.primary[100]} size={18} /> &nbsp;
              </>
            )}
            {video.protected.toString() === '1' && (
              <MdLock
                onClick={() => {
                  if (trialExpired) {
                    return;
                  }
                  history.push(`/classic/edit-video/${video.id}/security`);
                }}
                opacity={0.5}
              />
            )}
            <Title
              onClick={handleViewDetailsClick}
              clickable={
                videoRequest?.emailStatus !== VideoEmailStatus.Deleted &&
                !isUserServiceTechnician
              }
              data-cy={selectors.libraryPage.videoTitle}
            >
              {/* SUS-797 changes */}
              {video.title.length > 60
                ? video.title.substring(0, 60) + '...'
                : video.title}
            </Title>
          </TitleWrapper>

          <Info>
            {isTechnician ? advisorInfo : recordedInfo}
            {(isManager || isTechnician) && <Bull />}
            {clientTime}
            {isManager ? (
              <>
                <Bull />
                {advisorInfo}
              </>
            ) : null}
          </Info>
          <Footer>
            {userData.isAutomotiveSalesRole && videoRequest?.vin && (
              <>
                <RONumber>
                  <b>VIN:</b> {videoRequest.vin}
                </RONumber>
              </>
            )}
            {userData.isAutomotiveServiceRole &&
              videoRequest?.repairOrderNumber && (
                <>
                  <RONumber>
                    <b>RO#:</b> {videoRequest.repairOrderNumber}
                  </RONumber>
                </>
              )}
            {videoItemType === VideoItemType.Video && !isTechnician && (
              <>
                {/*If we have vin for sales role or we have ro number for service role - show bull*/}
                {((userData.isAutomotiveSalesRole && videoRequest?.vin) ||
                  (userData.isAutomotiveServiceRole &&
                    videoRequest?.repairOrderNumber)) && <Bull />}
                <ViewCounter onClick={handleAnalyticsClick}>
                  <MdBarChart color={theme.palette.gray60} size={18} />
                  <span>
                    {`${video.views} view${
                      video.views.toString() === '1' ? '' : 's'
                    }`}
                  </span>
                </ViewCounter>
              </>
            )}
            {video.tags && !!video.tags.length && (
              <>
                <Bull />
                {video.tags.slice(0, 2).map((t: any, index) => (
                  <Tag key={'videotag-' + index}># {t.tag}</Tag>
                ))}
                {!showMoreTags && video.tags.length > 2 && (
                  <ShowMoreTags onClick={() => setShowMoreTags(true)}>
                    +{video.tags.length - 2}
                  </ShowMoreTags>
                )}
                {showMoreTags &&
                  video.tags.length > 2 &&
                  video.tags
                    .slice(2)
                    .map((t: any, index) => (
                      <Tag key={'videotag-' + index}># {t.tag}</Tag>
                    ))}
              </>
            )}
          </Footer>
        </Center>
        {video.access !== FOLDER_ACCESS.VIEW && (
          <Right>
            <VideoItemActions
              videoItemType={videoItemType}
              videoRequest={videoRequest}
              repairOrder={repairOrder}
              video={video}
              setShowModalQuickShareMarkAsSent={
                setShowModalQuickShareMarkAsSent
              }
              handleReassignSubmit={handleReassignSubmit}
              handleDeleteSubmit={handleDeleteSubmit}
              handleDeleteWithROSubmit={handleDeleteWithROSubmit}
              handleMerge={handleMerge}
              handleDownload={handleDownload}
              handleDuplicate={handleDuplicate}
              handleAnalyticsClick={handleAnalyticsClick}
              handleMoveCopyButtonClick={handleMoveCopyButtonClick}
              refreshVideoList={refreshVideoList}
              videoLimitStatus={videoLimitStatus}
              disablePinOption={disablePinOption}
              currentFolder={currentFolder}
              isFolderShared={isFolderShared}
              showModalQuickShareMarkAsSent={showModalQuickShareMarkAsSent}
              handleOnMarkAsSentSuccessCallback={
                handleOnMarkAsSentSuccessCallback
              }
            />
          </Right>
        )}
      </Row>
      {showModalPreviewVideo && (
        <ModalVideoListVideo
          videoId={video.id}
          handleModalClose={() => setShowModalPreviewVideo(false)}
        />
      )}
    </>
  );
};
