import * as React from 'react';
import { ColorChangeHandler, ColorResult, SketchPicker } from 'react-color';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdEdit, MdOutlineAutorenew } from 'react-icons/md';
import { hexToColorResult } from '../../utils';

interface ComponentProps {
  handleColorChange: ColorChangeHandler;
  playerColor: string;
  width?: string;
  menuPlacement?: string;
  isClearable?: boolean;
  onClearColor?: () => void;
  wrapperWidth?: string;
  wrapperMargin?: string;
  className?: string;
  disableZIndex?: boolean;
  ref?: object;
  appearanceTab?: boolean;
}

type PickerProps = {
  isPickerOpen?: boolean;
  onBlur?: () => void;
  onMouseDown?: (e: any) => void;
  tabIndex?: number;
  height?: string;
  menuPlacement?: string;
  disableZIndex?: boolean;
  appearanceTab?: boolean;
};

type ColorProps = {
  color?: string;
};

type ColorPickerWrapperProps = {
  wrapperWidth?: string;
  wrapperMargin?: string;
};

const Picker = styled(SketchPicker)<PickerProps>`
  display: ${props => !props.isPickerOpen && 'none'};
  position: ${props => props.isPickerOpen && 'absolute'};
  z-index: 4;
  margin-top: 2px;
  max-width: ${props => `${props.width}px`};
  overflow-y: auto !important;
  div {
    border-radius: 0;
  }
  &:focus {
    outline: 0;
  }
  ${props =>
    props.menuPlacement === 'top' &&
    css`
      top: -260px;
    `}
  ${props =>
    props.menuPlacement === 'bottom' &&
    css`
      top: ${!!props.appearanceTab ? '212px' : '62px'};
      ${!props.appearanceTab && 'right: 0'};
    `}
`;

const ColorSelect = styled.div<PickerProps>`
  box-sizing: border-box;
  border: 1px solid
    ${props =>
      props.isPickerOpen
        ? props.theme.colors.primary[100]
        : theme.palette.grayBorder};
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.palette.themeDark};
  font-size: 16px;
  width: 100%;
  z-index: ${props => (props.disableZIndex == true ? '' : '2')};
  ${theme.mediaQueryMinWidth.sm} {
    max-width: 384px;
  }
  &:focus {
    outline: 0;
  }
`;

const Color = styled.div<ColorProps>`
  width: 24px;
  height: 24px;
  margin: 4px 8px 4px 8px;
  border-radius: 4px;
  background-color: ${props => props.color};
  border: 1px solid;
`;

const ColorContent = styled.div<ColorProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  color: ${theme.palette.primaryDarkBlue};
  svg {
    font-size: 24px;
    margin: 0 12px 0 0;
  }
`;

const ClearableIconWrapper = styled.div`
  align-self: center;
  margin-left: 8px;
`;

const ColorPickerWrapper = styled.div<ColorPickerWrapperProps>`
  display: flex;
  max-height: 40px;
  width: ${props => (props.wrapperWidth ? props.wrapperWidth : '100%')};
  margin: ${props => (props.wrapperMargin ? props.wrapperMargin : '')};
  border-radius: 4px;
  &:focus {
    outline: 0;
  }
`;

const BlankColorInput = styled.input`
  border: none;
  background: none;
  outline: none;
  font: inherit;
  padding: 0;
`;

const matcher = /^#?([0-9A-F]{3,8})$/i;

export const validHex = (value: string, alpha?: boolean): boolean => {
  const match = matcher.exec(value);
  const length = match ? match[1].length : 0;

  return (
    length === 3 || // '#rgb' format
    length === 6 || // '#rrggbb' format
    (!!alpha && length === 4) || // '#rgba' format
    (!!alpha && length === 8) // '#rrggbbaa' format
  );
};

const prefix = (value: string) => '#' + value;
const validate = (value: string) => validHex(value);

export const EnhancedColorPicker = (props: ComponentProps) => {
  /** Escapes all non-hexadecimal characters including "#" */
  const escape = (value: string) =>
    value?.replace(/([^0-9A-F]+)/gi, '').substring(0, 6) || '';

  const {
    playerColor,
    handleColorChange,
    width,
    menuPlacement = 'bottom',
    isClearable = false,
    onClearColor,
    className = '',
    disableZIndex = false,
    appearanceTab = false,
  } = props;

  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  const [inputColor, setInputColor] = React.useState(escape(playerColor));

  const wrapperRef = React.createRef<HTMLDivElement>();

  const pickNewColor = (
    color: ColorResult,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleColorChange(color, e);
  };

  const handleColorPickerChange = (color: any, event: any) => {
    setInputColor(escape(color.hex));
    pickNewColor(color, event);
  };

  const toggleColorPicker = () => {
    isPickerOpen ? setIsPickerOpen(false) : setIsPickerOpen(true);
    const { current } = wrapperRef;
    if (current) {
      current.focus();
    }
  };

  const clearColor = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClearColor && onClearColor();
  };

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsPickerOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChangeInputColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = escape(e.target.value);
    setInputColor(newColor);
    if (validate(newColor)) {
      pickNewColor({ ...hexToColorResult(newColor), hex: prefix(newColor) }, e);
    }
  };

  return (
    <ColorPickerWrapper
      wrapperWidth={props.wrapperWidth}
      wrapperMargin={props.wrapperMargin}
      ref={wrapperRef}
      id='colorPickerContainer'
      tabIndex={0}
      className={className}
    >
      <ColorSelect disableZIndex={disableZIndex} isPickerOpen={isPickerOpen}>
        <ColorContent>
          <Color color={playerColor} onClick={toggleColorPicker} />
          <BlankColorInput
            value={prefix(inputColor)}
            onChange={handleChangeInputColor}
          />
        </ColorContent>
        <IconWrapper>
          <MdEdit
            opacity={0.5}
            size='16px'
            color={theme.palette.primaryDarkBlue}
            onClick={toggleColorPicker}
          />
        </IconWrapper>
      </ColorSelect>
      {isClearable && (
        <ClearableIconWrapper onClick={clearColor}>
          <MdOutlineAutorenew
            opacity={0.5}
            style={{ cursor: 'pointer' }}
            size='16px'
          />
        </ClearableIconWrapper>
      )}
      <Picker
        isPickerOpen={isPickerOpen}
        width={width || '250'}
        color={playerColor}
        onChange={handleColorPickerChange}
        menuPlacement={menuPlacement}
        appearanceTab={appearanceTab}
      />
    </ColorPickerWrapper>
  );
};
