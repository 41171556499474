import React, { useState } from 'react';
import { NewModal } from 'lib/components';
import { ParagraphNormal } from 'lib/components/styles/typography';
import { SuperAdminCustomerDetails } from 'lib/context';
import { Button } from 'react-covideo-common';
import { Flex } from 'lib/components/styles/layout';
import { SwitchField } from 'lib/components/form/switch';

type Props = {
  newResellerName: string;
  customer: SuperAdminCustomerDetails;
  handleModalClose(confirm: boolean): void;
};

export const ResellerChangeConfirmationModal = ({
  newResellerName,
  customer,
  handleModalClose,
}: Props) => {
  const [confirm, setConfirm] = useState(false);

  return (
    <NewModal
      headerText={'Change Reseller for this Customer?'}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose(false)}
      style={{
        content: {
          minWidth: 'auto',
          paddingLeft: '32px',
          maxWidth: 512,
        },
      }}
    >
      <ParagraphNormal>
        Are you sure you want to move the '{customer.business}', and all it's
        users to '{newResellerName}'.
      </ParagraphNormal>

      <Flex
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
        margin='32px 0 0 0'
        alignItems='center'
      >
        <Flex flexDirection='row' width='100%' justifyContent='space-between'>
          <SwitchField
            id={'switch-confirm-reseller-change'}
            name={'switch-confirm-reseller-change'}
            wrapper={{ width: 'auto' }}
            label='I confirm this action'
            onChange={() => {
              setConfirm(true);
            }}
            checked={confirm}
          />
        </Flex>
        <Flex width='auto'>
          <Button
            onClick={() => handleModalClose(true)}
            text={'Change Reseller'}
            disabled={!confirm}
          />
        </Flex>
      </Flex>
    </NewModal>
  );
};
