export type PaymentCardParams = {
  cardNumber: string;
  expiry: string;
  cvc: string;
  holderName: string;
  billingAddress: string;
  city: string;
  state: string;
  country: string;
  postCode: string;
  makeDefaultCard: boolean;
};

export const initialValues: PaymentCardParams = {
  cardNumber: '',
  expiry: '',
  cvc: '',
  holderName: '',
  billingAddress: '',
  city: '',
  state: '',
  country: 'US',
  postCode: '',
  makeDefaultCard: false,
};
