import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

interface UpdateCustomerPaymentType {
  interval?: string;
  quantity?: number;
  price?: string;
  active?: boolean;
  defaultMethod?: string;
  cancellationReason?: string;
}

const updateCustomerSubscription = async (data: UpdateCustomerPaymentType) => {
  const response = await EXPRESS_API.put(
    '/register/payment/subscriptions',
    data
  );
  return response.data;
};

export const useUpdateCustomerPaymentMutation = () => {
  const { showError } = useToastError();

  return useMutation(updateCustomerSubscription, {
    onError: (err: AxiosError) => showError(err),
  });
};
