import React from 'react';
import styled from 'styled-components/macro';
import { SuperAdminCustomer } from 'lib/context';
import {
  CheckboxInput,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
} from 'lib/components';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { Badge, BadgeType } from 'lib/components/Badge';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { FaChevronRight } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { theme } from 'lib/style';
import { CommonTypography } from 'lib/components/styles/typography';
import {
  HoverElement,
  Layout,
  NormalText,
  PaginationWrapper,
  TableCell,
} from '../../index.styled';
import { copyField } from 'lib/utils/functions';
import { SortButtons } from '../../components/SortButtons';

const columnWidths = [40, 270, 230, 120, 240, 100, 40];

const tableFields = [
  {
    value: 'business',
    label: 'Business Name',
    sortable: true,
    sortKey: 'BUSINESS',
  },
  {
    value: 'admin',
    label: 'Admin',
    sortable: true,
    sortKey: 'ADMIN',
  },
  {
    value: 'verified',
    label: 'Status',
  },
  {
    value: 'reseller',
    label: 'Reseller',
  },
  {
    value: 'users',
    label: 'Active Users',
  },
];

const TextWrapper = styled.div<{ width?: number; bottomPadding?: number }>`
  padding-bottom: ${props =>
    props.bottomPadding ? `${props.bottomPadding}px` : '0'};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
`;
const CustomerTitle = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${theme.palette.gray100};
  cursor: pointer;
`;

const CustomerSubTitle = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${theme.palette.gray60};
`;

const CustomerUserCount = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-align: right;
`;

const CustomerUserCountWrapper = styled.div<{ color?: string }>`
  width: 100%;
  color: ${props => (props.color ? props.color : theme.palette.gray100)};
`;
const ActionWrapper = styled.div`
  padding: 12px;
`;

type Props = {
  customerCount: number;
  customerArray: SuperAdminCustomer[];
  selectedCustomersData: SuperAdminCustomer[];
  page: number;
  size: number;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  onCustomerSelect(customer: SuperAdminCustomer, checked: boolean): void;
  onPaginationChange({ page, size }: { page: number; size: number }): void;
  openCustomerDetails(customer: SuperAdminCustomer): void;
  onEmailIconClick(customer: SuperAdminCustomer): void;
  onSortingUpdate(sortKey: string, order: string): void;
};

export const Customers = ({
  customerCount,
  customerArray,
  selectedCustomersData,
  page,
  size,
  sortKey,
  order,
  onCustomerSelect,
  onPaginationChange,
  openCustomerDetails,
  onSortingUpdate,
}: Props) => {
  return (
    <Layout>
      <TableContextProvider
        total={customerCount}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div
          className='scroll--container'
          style={{ overflowX: 'auto', paddingBottom: '20px' }}
        >
          <Table
            compact={true}
            relative={true}
            headers={[
              '',
              ...tableFields.map((item, index) => {
                return (
                  <TableCell
                    key={index}
                    width={columnWidths[index + 1]}
                    onClick={() => {}}
                  >
                    {item.label}
                    {item.sortable && (
                      <SortButtons
                        isSorted={sortKey === item.sortKey}
                        order={order}
                        onChange={value => onSortingUpdate(item.sortKey, value)}
                      />
                    )}
                  </TableCell>
                );
              }),
              '',
            ]}
            hoverable={false}
            fixColumnIndex={[0]}
            rows={customerArray.map(
              (customer: SuperAdminCustomer, index: number) => ({
                key: index,
                columns: [
                  <TableCell width={columnWidths[0]} alignItems='center'>
                    <CheckboxInput
                      width='24px'
                      blueCheck={true}
                      checked={selectedCustomersData.includes(customer)}
                      onChange={(e: React.SyntheticEvent) => {
                        e.stopPropagation();
                        let { checked } = e.target as HTMLInputElement;
                        onCustomerSelect(customer, checked);
                      }}
                    />
                  </TableCell>,

                  ...tableFields.map((item, index) => {
                    return (
                      <TableCell
                        key={index}
                        width={columnWidths[index + 1]}
                        cursor={item.value === 'admin' ? 'pointer' : 'default'}
                        textAlign={item.value === 'users' ? 'right' : 'left'}
                        alignItems='center'
                        onClick={() => {}}
                      >
                        {item.value === 'business' && (
                          <TextWrapper width={columnWidths[index + 1]}>
                            <HoverElement right='30'>
                              <CustomerTitle
                                onClick={() => openCustomerDetails(customer)}
                              >
                                {!!customer.business &&
                                !!customer.business.trim()
                                  ? customer.business
                                  : '-'}
                              </CustomerTitle>
                              {customer?.business?.length > 20 && (
                                <div className='hover-content'>
                                  <div className='sub-content'>
                                    {customer.business}
                                  </div>
                                </div>
                              )}
                            </HoverElement>
                            <CustomerSubTitle>
                              ID: {customer.customerId}
                            </CustomerSubTitle>
                          </TextWrapper>
                        )}
                        {item.value === 'verified' && (
                          <Badge
                            type={
                              customer.verified === VerificationStatus.ACTIVE
                                ? BadgeType.LIGHT_ORANGE
                                : BadgeType.GRAY
                            }
                            text={
                              customer.verified === VerificationStatus.ACTIVE
                                ? 'ACTIVE'
                                : 'INACTIVE'
                            }
                          />
                        )}
                        {item.value === 'admin' && (
                          <>
                            <HoverElement>
                              <MdEmail
                                size={24}
                                color={theme.palette.gray60}
                                onClick={() => {
                                  copyField(customer.email ?? '', 'Email');
                                }}
                              />
                              <div className='hover-content'>
                                <strong>Click to copy:</strong>
                                <br />
                                <div className='sub-content'>
                                  {customer.email}
                                </div>
                              </div>
                            </HoverElement>
                            &nbsp;
                            <TextWrapper
                              width={columnWidths[index + 1] - 30}
                              bottomPadding={4}
                            >
                              <NormalText>
                                {customer.firstName} {customer.lastName}
                              </NormalText>
                            </TextWrapper>
                          </>
                        )}
                        {item.value === 'reseller' && (
                          <NormalText>{customer.resellerName}</NormalText>
                        )}

                        {item.value === 'users' && (
                          <CustomerUserCountWrapper>
                            <CustomerUserCount
                              color={
                                customer.verified
                                  ? theme.palette.gray100
                                  : theme.palette.newCovideoGray20
                              }
                            >
                              {customer.userCount} / {customer.maxUsers}
                            </CustomerUserCount>
                          </CustomerUserCountWrapper>
                        )}
                      </TableCell>
                    );
                  }),
                  <TableCell
                    cursor='pointer'
                    textAlign='center'
                    alignItems='center'
                    onClick={() => openCustomerDetails(customer)}
                  >
                    <ActionWrapper>
                      <FaChevronRight size={12} />
                    </ActionWrapper>
                  </TableCell>,
                ],
                onClick: () => {},
              })
            )}
          />
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
    </Layout>
  );
};
