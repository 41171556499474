import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';
import { MeetingResponse, MEETING_STATUSES } from './types';
import { createMeeting } from './useCreateMeetingMutation';
import { useCurrentAppUrl } from 'lib/hooks/useCurrentAppUrl';

export const useCreateInstantMeetingMutation = () => {
  const { livePageUrl } = useCurrentAppUrl();
  return useMutation(
    () => createMeeting({ meetingStatus: MEETING_STATUSES.SCHEDULED }),
    {
      onError: () => {
        errorToast({
          title:
            'An error occurred creating the live meeting, please try again later!',
        });
      },
      onSuccess: (meeting: MeetingResponse) => {
        if (!!meeting.meetingId) {
          const liveLink = livePageUrl + meeting.meetingId;
          navigator.clipboard.writeText(liveLink);
          successToast({
            title: 'Live link copied to clipboard',
          });
          window.open(liveLink, '_blank');
        }
      },
    }
  );
};
