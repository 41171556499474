import { IAutomotiveBase } from 'app/pages/design/landingPages-v1/form/types';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { automotiveLandingKeys } from './automotiveLandingKeys';
import { IAutomotiveResponse } from './useAutomotiveLandingQuery';

export interface IAutomotivePayload extends IAutomotiveBase {}

export const updateAutomotiveLanding = async (
  payload: IAutomotivePayload
): Promise<IAutomotiveResponse> => {
  const response = await EXPRESS_API.put('/automotive-landing', payload);
  return response.data;
};

export const useAutomotiveLandingMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(updateAutomotiveLanding, {
    onError: error =>
      showError(
        error || 'Something went wrong while submitting the landing page.'
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(automotiveLandingKeys.all());
      successToast({ title: 'Your landing page is updated!' });
    },
  });
};
