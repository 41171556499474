import { DocumentHead, PageTemplate, SidebarNavigation } from 'lib/components';
import {
  ForbiddenFeaturesForWhitelabels,
  SuperAdminRole,
  getAdminAccessList,
} from 'lib/const/SuperAdminRole';
import { useAuth } from 'lib/context';
import {
  MdAddLink,
  MdContacts,
  MdLocationPin,
  MdOutlineBarChart,
  MdSupervisorAccount,
} from 'react-icons/md';
import { InventoryManagmentSystem } from './ims/InventoryManagmentSystem';
import { MultiLocationsPages } from './multiLocation/main';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Customers } from './customers';
import Integrations from './integrations/Main';
import { Organizations } from './organizations/Organizations';
import { Resellers } from './resellers';
import { UsageReports } from './usageReports';
import { Users } from './users';
import { FaPuzzlePiece } from 'react-icons/fa';
import { CgOrganisation } from 'react-icons/cg';
import {
  DEFAULT_RESELLER_ID,
  SCREEN_VALUES,
} from 'lib/const/SuperAdminConstants';
import { FaShop } from 'react-icons/fa6';

export const AdminRoutes = () => {
  const { userData } = useAuth();
  const {
    showCustomers,
    showResellers,
    showUsageReports,
    showUsers,
    showOrganizations,
    showIms,
    showMlm,
    showIntegrations,
  } = getAdminAccessList(userData.superAdminRole);
  let sidebarItems = [
    {
      title: 'MLM',
      icon: <MdLocationPin size={24} />,
      path: '/admin/multilocations',
      showItem: showMlm,
    },
    {
      title: 'IMS',
      icon: <MdAddLink size={24} />,
      path: '/admin/ims',
      showItem: showIms,
    },
    {
      title: 'Organizations',
      icon: <CgOrganisation size={24} />,
      path: '/admin/organizations',
      showItem: showOrganizations,
    },
    {
      title: 'Resellers',
      icon: <FaShop size={24} />,
      path: '/admin/resellers',
      showItem: showResellers,
    },
    {
      title:
        userData.superAdminRole === SuperAdminRole.ADMIN
          ? 'Company Profile'
          : 'Customers',
      icon: <MdContacts size={24} />,
      path:
        userData.superAdminRole === SuperAdminRole.ADMIN
          ? `/admin/customers/${userData.customerId}/${SCREEN_VALUES.CUSTOMERS}/${DEFAULT_RESELLER_ID}`
          : '/admin/customers',
      showItem: showCustomers,
    },
    {
      title: 'Users',
      icon: <MdSupervisorAccount size={24} />,
      path: '/admin/users',
      showItem: showUsers,
    },
    {
      title: 'Usage Reports',
      icon: <MdOutlineBarChart size={24} />,
      path: '/admin/usage-reports',
      showItem: showUsageReports,
    },
    {
      title: 'Integrations',
      icon: <FaPuzzlePiece size={24} />,
      path: '/admin/integrations',
      showItem: showIntegrations,
    },
  ];

  if (!showIntegrations) {
    sidebarItems = sidebarItems.filter(
      item => !ForbiddenFeaturesForWhitelabels[item.title]
    );
  }

  return (
    <>
      <DocumentHead title='Admin' />
      <PageTemplate
        main={
          <>
            <SidebarNavigation
              items={sidebarItems.filter(item => item.showItem)}
              hasBack={false}
            />
            <Switch>
              <>
                {showMlm && (
                  <Route
                    path='/admin/multilocations'
                    component={MultiLocationsPages}
                  />
                )}
                {showIntegrations && (
                  <Route path='/admin/integrations' component={Integrations} />
                )}
                {showOrganizations && (
                  <Route
                    path='/admin/organizations'
                    component={Organizations}
                  />
                )}
                {showIms && (
                  <Route
                    path='/admin/ims'
                    component={InventoryManagmentSystem}
                  />
                )}
                {showUsageReports && (
                  <Route path='/admin/usage-reports' component={UsageReports} />
                )}
                {showCustomers && (
                  <Route path='/admin/customers' component={Customers} />
                )}
                <Route path='/admin/resellers' component={Resellers} />
                {showUsers && <Route path='/admin/users' component={Users} />}
              </>
            </Switch>
          </>
        }
      />
    </>
  );
};
