import React, { useEffect, useState } from 'react';
import { CONNECTION_TYPES } from '../account/userAccount/socialProfiles/constants';
import { ProfileCard } from '../account/userAccount/socialProfiles/ProfileCard';
import { SocialButton } from '../account/userAccount/socialProfiles/SocialButton';
import { useHistory } from 'react-router';
import {
  deleteLinkedinIntegration,
  getLinkedinIntegration,
  getLinkedInOrganizations,
  saveLinkedinAccessToken,
  updateLinkedinIntegration,
} from 'lib/api/linkedinApi';
import { LinkedInIcon } from '../account/userAccount/socialProfiles/assets/social-icons';
import { successToast } from 'lib/components/toasts/success';
import { useAuth } from 'lib/context';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { useToastError } from 'lib/hooks';
import { useCurrentAppUrl } from 'lib/hooks/useCurrentAppUrl';

interface IDropdownItem {
  value: number;
  label: string;
  id: string;
}

interface ILinkedInOrganization {
  'organization~': {
    localizedName: string;
  };
  role: string;
  organization: string;
  roleAssignee: string;
  state: string;
  'roleAssignee~': {
    localizedLastName: string;
    localizedFirstName: string;
  };
}

const social = {
  id: 3,
  title: 'LinkedIn',
  description: `Connect with LinkedIn to allow users to publish videos to your company page on LinkedIn. Be sure to log in with an account that manages your company’s page.`,
};

const Linkedin = () => {
  const { covideoAppUrl } = useCurrentAppUrl();
  const history = useHistory();
  const [linkedinCode, setLinkedinCode] = useState('');
  const [linkedinConnected, setLinkedinConnected] = useState(
    CONNECTION_TYPES['NOT CONNECTED']
  );
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [dropdownItems, setDropdownItems] = useState<IDropdownItem[] | null>(
    null
  );
  const [disabledSave, setDisabledSave] = useState<boolean>();

  const [isLoading, setIsLoading] = useState(true);
  const { whitelabel } = useAuth();
  const { showError } = useToastError();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if ('code' in params) {
      return setLinkedinCode(params.code);
    }
    const asyncCall = async () => {
      const linkedinIntegrationData = await getLinkedinIntegration();
      if (linkedinIntegrationData.organizationId) {
        setSelectedItem(linkedinIntegrationData.organizationId);
      }
      setLinkedinConnected(
        !!linkedinIntegrationData?.success &&
          !!linkedinIntegrationData?.accessToken
          ? CONNECTION_TYPES['CONNECTED']
          : CONNECTION_TYPES['NOT CONNECTED']
      );
      setIsLoading(false);
    };
    asyncCall();
  }, []);

  useEffect(() => {
    // If there is only one available organization -> save it to the database
    if (dropdownItems?.length === 1) {
      updateLinkedinOrganization(false, dropdownItems[0].id);
    }
  }, [dropdownItems]);

  const logout = async () => {
    setIsLoading(true);
    try {
      await deleteLinkedinIntegration('/linkedin/integration');
      setLinkedinConnected(CONNECTION_TYPES['NOT CONNECTED']);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showError(error);
    }
  };
  const reconnect = async () => {
    setIsLoading(true);
    try {
      const res = await deleteLinkedinIntegration('/linkedin/integration');
      setIsLoading(false);
      if (res) {
        const isWhitelabel = whitelabel?.name !== WHITELABEL_NAME.COVIDEO;
        const encodedUrl = encodeURIComponent(
          `${isWhitelabel ? `https://${whitelabel.domainV3}` : covideoAppUrl}/profile/social-profiles/`
        );
        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=777xuc6teae6q5&redirect_uri=${encodedUrl}&scope=r_liteprofile%20rw_organization_admin%20r_emailaddress%20w_member_social%20w_organization_social%20r_organization_social`;
      }
    } catch (error) {
      setIsLoading(false);
      showError(error);
    }
  };

  const fetchOrganizations = async () => {
    const organizations = await getLinkedInOrganizations();
    if (organizations && organizations.length) {
      const dropdownItems = organizations.map(
        (organization: ILinkedInOrganization) => ({
          value: organization.organization,
          label: organization['organization~'].localizedName,
          id: organization.organization,
        })
      );

      setDropdownItems(dropdownItems);
    }
  };

  // Update page in LinkedinIntegrations table
  const updateLinkedinOrganization = async (
    clicked: boolean,
    selectedItem: string
  ) => {
    const selectedPage = dropdownItems?.find(item => item.id === selectedItem);
    if (selectedPage) {
      await updateLinkedinIntegration({
        organizationId: selectedPage.id,
        organizationName: selectedPage.label,
      });
      setDisabledSave(true);
      if (clicked) {
        successToast({
          title: 'Organization has been sucessfully saved!',
        });
      }
    }
  };

  useEffect(() => {
    const getIntegrationDetails = async () => {
      const integration = await getLinkedinIntegration();
      setDisabledSave(
        !selectedItem ||
          (!!integration.organizationId &&
            integration.organizationId === selectedItem)
      );
    };
    getIntegrationDetails();
  }, [selectedItem]);

  useEffect(() => {
    if (linkedinCode.length) {
      const asyncCall = async () => {
        setIsLoading(true);
        const data = await saveLinkedinAccessToken(
          '/linkedin/store-token',
          linkedinCode
        );
        if (data) history.push('/profile/social-profiles');
        const linkedinIntegrationData = await getLinkedinIntegration();
        if (linkedinIntegrationData?.accessToken) {
          setLinkedinConnected(CONNECTION_TYPES['CONNECTED']);
        } else {
          setLinkedinConnected(CONNECTION_TYPES['NOT CONNECTED']);
        }
        setIsLoading(false);
      };
      asyncCall();
    }
  }, [linkedinCode]);

  useEffect(() => {
    if (linkedinConnected === CONNECTION_TYPES['CONNECTED']) {
      fetchOrganizations();
    }
  }, [linkedinConnected]);

  return (
    <ProfileCard
      key={social.id}
      title={social.title}
      description={social.description}
      connection={linkedinConnected}
      button={
        <SocialButton
          title='Login with LinkedIn'
          icon={<LinkedInIcon />}
          onClick={() => {
            const isWhitelabel = whitelabel?.name !== WHITELABEL_NAME.COVIDEO;
            const encodedUrl = encodeURIComponent(
              `${isWhitelabel ? `https://${whitelabel.domainV3}` : covideoAppUrl}/profile/social-profiles/`
            );
            window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=777xuc6teae6q5&redirect_uri=${encodedUrl}&scope=r_liteprofile%20rw_organization_admin%20r_emailaddress%20w_member_social%20w_organization_social%20r_organization_social`;
          }}
          color='#007AB6'
        />
      }
      loading={isLoading}
      onSaveClick={() => {
        if (selectedItem) {
          updateLinkedinOrganization(true, selectedItem);
        }
      }}
      onSaveDisabled={disabledSave}
      onDisconnect={logout}
      onReconnect={() => reconnect()}
      dropdown={{
        label: 'LinkedIn organization',
        items: dropdownItems,
      }}
      onDropdownChange={setSelectedItem}
      selectedItem={selectedItem}
    />
  );
};

export default Linkedin;
