import * as React from 'react';
import styled from 'styled-components/macro';
import { Field, FieldProps, Form, useFormikContext } from 'formik';
import { theme } from 'lib/style';
import Select from 'react-select';
import { VideoEmailStatus } from '../../../../../lib/const';
import { VideoAttribute } from 'lib/api';
import { useCovideoTheme } from 'react-covideo-common';
import { ListContainer } from 'lib/components/styles/layout';

type ComponentProps = {
  attributes: VideoAttribute[];
  onUpdate: Function;
  handleFormTouch: () => void;
  emailStatus: string;
};

const Content = styled.div`
  width: 100px;
  ${theme.mediaQueryMinWidth.xs} {
    width: 248px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    width: 380px;
  }
  .error {
    border-color: #e84c3d;
  }
  .disabled {
    background-color: #eeeff2;
    cursor: not-allowed;
  }

  .disabled > div {
    background-color: #eeeff2;
    cursor: not-allowed;
  }
`;

export const Header = styled.div`
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
`;

const VideoAttributeField = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;

const TextInput = styled.input`
  padding-left: 8px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-right: 8px;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

const Textarea = styled.textarea`
  padding-left: 8px;
  width: 242px;
  min-width: 242px;
  max-width: 242px;
  height: 100px;
  min-height: 100px;
  max-height: 300px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  &:focus {
    outline: 0;
  }
  ${theme.mediaQueryMinWidth.lg} {
    width: 368px;
    min-width: 368px;
    max-width: 368px;
  }
`;

const SelectInput = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  &:focus {
    outline: 0;
  }
`;

export const CountLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: ${({ theme }) => theme.colors.primary[100]};
  float: right;
`;
export const AttributesForm = ({
  attributes,
  emailStatus,
  handleFormTouch,
}: ComponentProps) => {
  const { colors } = useCovideoTheme();
  const [totalRequiredAttributes, setTotalRequiredAttributes] =
    React.useState(0);
  const { errors, validateForm, values }: any = useFormikContext();
  const isFieldDisabled = emailStatus === VideoEmailStatus.Deleted;

  React.useEffect(() => {
    const count = getRequiredAttributesCount();
    setTotalRequiredAttributes(count);
  }, []);

  const initValueObject: any = {};

  const getRequiredAttributesCount = () => {
    let count = 0;
    attributes.forEach(attribute => {
      if (attribute && attribute.isRequired) {
        ++count;
      }
    });

    return count;
  };
  return (
    <Content>
      <Header>
        Video Attributes{' '}
        <CountLabel>{totalRequiredAttributes} required</CountLabel>
      </Header>
      <Form>
        <ListContainer height='900px' width='100%'>
          {attributes.map((attribute: VideoAttribute, index: number) => {
            const isDisabled =
              (!!attribute.isReadOnly || !!attribute.isLocked) &&
              !!initValueObject[`attributes${attribute.index}`];
            const hasError = !!errors[`attributes${attribute.index}`];
            const isRequired = !!attribute.isRequired;
            let className = '';
            if (isDisabled) {
              className = 'disabled';
            }

            if (hasError) {
              className += ' error';
            }
            return (
              <VideoAttributeField key={index}>
                <Label>
                  {attribute.title}
                  {isRequired && (
                    <span style={{ color: colors.primary[100] }}>*</span>
                  )}
                </Label>
                {attribute.type === 'text' && (
                  <>
                    <Field
                      aria-label={attribute.title}
                      name={`attributes${attribute.index}`}
                      disabled={isDisabled || isFieldDisabled}
                      as={TextInput}
                      type='text'
                      className={className}
                      onKeyUp={() => handleFormTouch()}
                      onBlur={() => {
                        validateForm().then(() => {
                          if (
                            (attribute.isReadOnly || attribute.isLocked) &&
                            initValueObject[`attributes${attribute.index}`]
                          ) {
                            values[`attributes${attribute.index}`] =
                              initValueObject[`attributes${attribute.index}`];
                          }
                        });
                      }}
                      onFocus={() => {
                        validateForm();
                      }}
                    />
                    {hasError ? (
                      <div style={{ color: '#E84C3D', marginTop: '3px' }}>
                        {errors[`attributes${attribute.index}`]}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {attribute.type === 'textarea' && (
                  <>
                    <Field
                      aria-label={attribute.title}
                      disabled={isDisabled || isFieldDisabled}
                      name={`attributes${attribute.index}`}
                      as={Textarea}
                      className={className}
                      type='text'
                      onKeyUp={() => handleFormTouch()}
                      onBlur={() => {
                        validateForm().then(() => {
                          if (
                            (attribute.isReadOnly || attribute.isLocked) &&
                            initValueObject[`attributes${attribute.index}`]
                          ) {
                            values[`attributes${attribute.index}`] =
                              initValueObject[`attributes${attribute.index}`];
                          }
                        });
                      }}
                    />
                    {hasError ? (
                      <div style={{ color: '#E84C3D', marginTop: '3px' }}>
                        {errors[`attributes${attribute.index}`]}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {attribute.type === 'dropdown' && (
                  <Field
                    name={`attributes${attribute.index}`}
                    disabled={isDisabled || isFieldDisabled}
                    className={className}
                  >
                    {({ field, form }: FieldProps) => {
                      const { value } = field;
                      const options = attribute.dropdownValues.map(
                        (dropdown: any) => ({
                          value: dropdown.value,
                          label: dropdown.value,
                        })
                      );
                      return (
                        <SelectInput
                          menuPortalTarget={document.body}
                          aria-label={attribute.title}
                          disabled={isDisabled || isFieldDisabled}
                          maxMenuHeight={100}
                          className={className}
                          styles={{
                            control: (base: any) => ({
                              ...base,
                              height: '40px',
                              border: '1px solid #d0d3d9',
                              boxShadow: 'none',
                              '&:hover': {
                                border: '1px solid #d0d3d9',
                              },
                            }),
                            options: () => ({
                              disabled: { isDisabled },
                            }),
                            indicatorSeparator: () => ({ display: 'none' }),
                          }}
                          options={options}
                          value={options.find((o: any) => o.value == value)}
                          onChange={(option: any) => {
                            handleFormTouch();
                            const data = { ...values };
                            if (
                              (attribute.isReadOnly || attribute.isLocked) &&
                              initValueObject[`attributes${attribute.index}`]
                            ) {
                              form.setFieldValue(
                                `attributes${attribute.index}`,
                                initValueObject[`attributes${attribute.index}`]
                              );
                              values[`attributes${attribute.index}`] =
                                initValueObject[`attributes${attribute.index}`];
                              data[`attributes${attribute.index}`] =
                                initValueObject[`attributes${attribute.index}`];
                            } else {
                              form.setFieldValue(
                                `attributes${attribute.index}`,
                                option.value
                              );
                              data[`attributes${attribute.index}`] =
                                option.value;
                            }
                          }}
                        />
                      );
                    }}
                  </Field>
                )}
              </VideoAttributeField>
            );
          })}
        </ListContainer>
      </Form>
    </Content>
  );
};
