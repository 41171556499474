import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { SidebarNavigation } from 'lib/components/SidebarNavigation';

import { NotFoundTemplate } from '../notFound';
import { CustomerLeadsGroupsPage } from './pages/CustomerLeadsGroupsPage';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { MdContacts, MdFolder } from 'react-icons/md';
import { CustomerLeadsPage } from './pages/CustomerLeadsPage';
import { CustomerLeadsSingleGroupPage } from './pages/CustomerLeadsSingleGroupPage';

export const CustomerRoutes = () => {
  const iconSize = '24px';

  const customerRoutesItems = [
    {
      title: 'Customers',
      icon: <MdContacts size={iconSize} />,
      path: `/customers`,
      exact: true,
    },
    {
      title: 'Groups',
      icon: <MdFolder size={iconSize} />,
      path: '/customers/group-items',
      exact: true,
    },
  ];

  return (
    <>
      <DocumentHead title='Customers' />
      <SidebarNavigation items={customerRoutesItems} hasBack={false} />
      <PageTemplate
        main={
          <Container>
            <MainWrapper resetPadding={false}>
              <Switch>
                <Route
                  path='/customers'
                  component={CustomerLeadsPage}
                  exact
                ></Route>

                <Route
                  path='/customers/group-items'
                  component={CustomerLeadsGroupsPage}
                  exact
                />
                <Route
                  path='/customers/group-items/:groupId'
                  component={CustomerLeadsSingleGroupPage}
                  exact
                />

                <Route path='*' component={NotFoundTemplate} />
              </Switch>
            </MainWrapper>
          </Container>
        }
      />
    </>
  );
};
