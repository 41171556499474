import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { ModalDelete, ModalAddContactToGroup } from 'lib/components/modal';
import { useState } from 'react';
import {
  GroupListItem,
  ContactsBulkUpdateParams,
  ContactListItem,
  GroupItems,
} from 'lib/api/types';
import { CheckboxInput, SelectionHeaderPopup } from 'lib/components';
import { Button } from 'react-covideo-common';
import { MdDeleteForever, MdFolder, MdFileDownload } from 'react-icons/md';
import { exportCSVFile } from 'lib/utils/functions';
import { ISingleConversation } from 'lib/api/conversations/getSingleConversation';
import { useDeleteContactsMutation } from 'lib/api/contacts/useDeleteContactsMutation';
import { IContact } from 'lib/api/contacts/getSingleContact';
import { useDeleteAllContactsMutation } from 'lib/api/contacts/useDeleteAllContactsMutation';
import { useUpdateMultipleContactsMutation } from 'lib/api/contacts/useUpdateMultipleContactsMutation';
import { useIsMutating } from 'react-query';
import { DeleteContactParams } from 'lib/api/contacts/types';
import { shouldInvalidatePage } from 'app/pages/design/common/utils';
import { SelectAllDropdown } from 'lib/components/dropdown/SelectAllDropdown';

type SelectedContacts = {
  count: number;
  contacts: Map<string, ContactListItem | undefined>;
};

interface ComponentProps {
  selectedContacts: SelectedContacts;
  onDelete: Function;
  checkIndicator: Function;
  isAllSelected: boolean;
  selectAll: Function;
  setGlobalSelectAll: any;
  deselected: any;
  handleDeselected: Function;
  tableFields: {
    value: string;
    label: string;
  }[];
  setCurrentViewContacts: () => void;
  contacts: IContact[];
  count?: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  size: number;
}

export type ExportContactListItem = {
  contactId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  groups: string | undefined;
};

const LayoutOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;

const SelectionCountText = styled.div`
  margin: 0 8px 0 0;
  font-size: 14px;
`;

const SelectionCountNumber = styled.span`
  font-weight: bold;
`;

const smallDropDownOptions = [
  {
    id: '1',
    text: 'Select Current View',
  },
  {
    id: '2',
    text: 'Select All',
  },
];

const Component = (props: ComponentProps) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  const {
    selectedContacts,
    onDelete,
    checkIndicator,
    isAllSelected,
    selectAll,
    tableFields,
    setGlobalSelectAll,
    deselected,
    handleDeselected,
    setCurrentViewContacts,
    contacts,
    count = 0,
    setPage,
    page,
    size,
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddToGroupModal, setShowAddToGroupModal] = useState(false);
  const [exportHeader, setExportHeader] = useState<object>({});
  const [isDeleteAll, setIsDeleteAll] = useState(false);

  const ids = Array.from(selectedContacts.contacts.keys())
    .map((key: string) => {
      const value = selectedContacts.contacts.get(key);
      return value ? key : undefined;
    })
    .filter(key => key !== undefined) as string[];

  const onDeleteContactsMutationSuccess = () => {
    setShowDeleteModal(false);
    if (shouldInvalidatePage(count, ids.length, page, size)) {
      setPage(page - 1);
    }
    onDelete();
  };

  const deleteContactsParams: DeleteContactParams = {
    ids,
    onDeleteContactsMutationSuccess,
  };

  const { mutateAsync: deleteContactsMutation } =
    useDeleteContactsMutation(deleteContactsParams);

  const allIds: string[] = contacts.map(
    (contact: IContact) => contact.contactId
  );
  const difference = allIds.filter((x: string) => !ids.includes(x));

  const { mutateAsync: deleteAllContactsMutation } =
    useDeleteAllContactsMutation(difference, onDeleteContactsMutationSuccess);

  const onUpdateMultipleContactsMutationSuccess = () => {
    setShowAddToGroupModal(false);
    onDelete();
  };

  const { mutateAsync: updateContactsBulk } = useUpdateMultipleContactsMutation(
    onUpdateMultipleContactsMutationSuccess
  );

  const handleActionClick = async () => {
    !isDeleteAll
      ? await deleteContactsMutation()
      : await deleteAllContactsMutation();
  };

  const addContactsToGroup = async (groups: GroupListItem[]) => {
    const contactsToUpdate: ContactListItem[] = [];
    selectedContacts.contacts.forEach(
      (value: ContactListItem | undefined, key: string) => {
        value && contactsToUpdate.push({ contactId: key } as ContactListItem);
      }
    );
    const data: ContactsBulkUpdateParams = {
      contacts: contactsToUpdate,
      groups: groups,
    };
    await updateContactsBulk(data);
  };

  const contactsWithConversations: number[] = [];

  function findConversations(
    value:
      | (ContactListItem & { conversation?: ISingleConversation })
      | undefined
  ) {
    if (!value || !value.conversation) {
      return;
    }
    if (value?.conversation !== null) {
      contactsWithConversations.push(value?.conversation?.conversationId);
    }
  }

  selectedContacts.contacts.forEach(findConversations);

  const hasMultipleConversations =
    contactsWithConversations.length > 1 ? 's' : '';

  let modalDeleteText = `Deleting this contact will move the contact into the recently deleted bin.`;
  let modalDeleteText1 = `${
    contactsWithConversations.length > 0
      ? `If you delete this contact${hasMultipleConversations},
       all conversations
       associated with this contact${hasMultipleConversations} will be deleted too.`
      : ''
  }`;
  let whiteButtonText = 'Yes, Delete';
  let orangeButtonText = 'No, Cancel';
  let modalDeleteTitle =
    'You want to move the contact to the recently deleted bin?';
  if (selectedContacts.count > 1) {
    modalDeleteText = `Deleting this ${
      !isDeleteAll ? selectedContacts.count : count - deselected
    } contacts will move the contacts into the recently deleted bin.`;
    whiteButtonText = 'Yes, Delete';
    orangeButtonText = 'No, Cancel';
    modalDeleteTitle = `Are you sure you want to move ${
      !isDeleteAll ? selectedContacts.count : count - deselected
    } contacts to the recently deleted bin?`;
  }

  React.useEffect(() => {
    let tempExportHeader: object = {};
    tableFields.forEach(field => {
      // @ts-ignore
      tempExportHeader[field.value] = field.label;
    });
    setExportHeader(tempExportHeader);
  }, [tableFields]);

  const handleSelectClick = (option: any) => {
    handleDeselected();
    !isAllSelected && selectAll();
    if (option.id === '1') {
      setCurrentViewContacts();
      setIsDeleteAll(false);
    } else if (option.id === '2') {
      setGlobalSelectAll();
      setIsDeleteAll(true);
    }
  };

  const exportSelectedContacts = () => {
    let exportContactList: ExportContactListItem[] = [];

    selectedContacts.contacts.forEach(ele => {
      exportContactList.push({
        contactId: ele?.contactId ?? '',
        firstName: ele?.firstName ?? '',
        lastName: ele?.lastName ?? '',
        email: ele?.email ?? '',
        phone: ele?.phone ?? '',
        companyName: ele?.companyName ?? '',
        groups: ele?.groups?.map((key: GroupItems) => key.name).join(' | '),
      });
    });
    exportCSVFile(exportHeader, exportContactList, 'selected_contacts');
  };
  return (
    <>
      {selectedContacts.count > 0 && (
        <SelectionHeaderPopup left='208px' fixedWidth='1032px'>
          <LayoutOption>
            <CheckboxInput
              blueCheck={true}
              checkGroupIndicator={checkIndicator()}
              checked={isAllSelected}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIsDeleteAll(false);
                event.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                selectAll({ event })
              }
            />
            <SelectionCountWrap>
              <SelectAllDropdown
                onItemClick={handleSelectClick}
                options={smallDropDownOptions}
              />
              <SelectionCountText>Contacts selected:</SelectionCountText>
              <SelectionCountNumber>
                {!isDeleteAll ? selectedContacts.count : count - deselected}
              </SelectionCountNumber>
            </SelectionCountWrap>
            <Button
              onClick={() => setShowAddToGroupModal(true)}
              text='Add to Group'
              variant='primary'
              icon={<MdFolder />}
              disabled={isDisabled}
            />
            <Button
              onClick={() => exportSelectedContacts()}
              text='Export'
              variant='ghost'
              icon={<MdFileDownload />}
              disabled={isDisabled}
            />
            <Button
              onClick={() => setShowDeleteModal(true)}
              text='Delete'
              variant='destructive'
              icon={<MdDeleteForever color='#e84c3d' width={20} />}
              disabled={isDisabled}
            />
          </LayoutOption>
        </SelectionHeaderPopup>
      )}
      {showDeleteModal && (
        <ModalDelete
          whiteButtonText={whiteButtonText}
          orangeButtonText={orangeButtonText}
          title={modalDeleteTitle}
          text={modalDeleteText}
          text1={modalDeleteText1}
          hasConfirm={true}
          handleModalClose={() => setShowDeleteModal(false)}
          onClickWhiteButton={handleActionClick}
          onClickOrangeButton={() => setShowDeleteModal(false)}
        />
      )}
      {showAddToGroupModal && (
        <ModalAddContactToGroup
          handleModalClose={() => setShowAddToGroupModal(false)}
          handleSubmit={addContactsToGroup}
          selectedContacts={selectedContacts}
        />
      )}
    </>
  );
};

export const ContactsHeader = React.memo(Component);
