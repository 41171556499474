import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation, useQueryClient } from 'react-query';
import { CreateParams } from '../types';
import { successToast } from 'lib/components/toasts/success';
import { videoAttributesKeys } from './videoAttributesKeys';

export const createAttribute = async ({ data }: CreateParams) => {
  const response = await EXPRESS_API.post('/video-attributes', data);

  return response.data;
};

export const useCreateVideoAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation(createAttribute, {
    onSuccess: () => {
      queryClient.refetchQueries(videoAttributesKeys.attributes());
      successToast({ title: 'Video Attribute added successfully.' });
    },
  });
};
