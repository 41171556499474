import React from 'react';
import { useField } from 'formik';
import styled, { CSSProperties } from 'styled-components/macro';
import { FormikErrorMessage } from './FormikErrorMessage';
import { FormikLabel } from './FormikLabel';
import { theme } from 'lib/style';
import { Flex, FlexProps } from '../styles/layout';

const TextInput = styled.input`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #e1e2e5;
  padding: 8px 12px;
  &:focus {
    outline: none;
    border: solid 1px ${theme.palette.covideoBlue100};
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  info?: string | React.ReactNode;
  isRequired: boolean;
  extendStyles?: {
    errorWrapper?: CSSProperties;
    labelStyles?: CSSProperties;
    inputWrapper?: FlexProps;
    mainWrapper?: FlexProps;
  };
}

export const FormikInputField = React.forwardRef<
  HTMLInputElement,
  CustomInputProps
>(({ label, isRequired, extendStyles, info, ...props }, ref) => {
  const [field, meta] = useField(props);
  return (
    <Flex {...extendStyles?.mainWrapper} gap='4px'>
      {label && (
        <FormikLabel
          label={label}
          name={props.name}
          isRequired={isRequired}
          disabled={!!props.disabled}
          info={info}
          labelStyles={extendStyles?.labelStyles}
        />
      )}
      <Flex {...extendStyles?.inputWrapper} gap='4px'>
        <TextInput {...field} {...props} id={props.name} ref={ref} />
        <FormikErrorMessage meta={meta} extendStyles={extendStyles} />
      </Flex>
    </Flex>
  );
});

FormikInputField.displayName = 'FormikInputField';
