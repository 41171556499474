import React from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import {
  UpdateMultipleUsersBody,
  updateMultipleUsers,
} from 'lib/api/superAdminApi';
import { CommonTypography } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { successToast } from 'lib/components/toasts/success';
import { UserDetailsData } from 'lib/context';
import { Button } from 'react-covideo-common';
import { useQueryClient } from 'react-query';
import { usersKeys } from 'lib/api/users/queryKeys';

const SubmitButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: right;
  margin-top: 32px;
`;

const Title = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${theme.palette.covideoGray100};
`;

type Props = {
  newStatus: VerificationStatus;
  user: Partial<UserDetailsData>;
  handleModalClose(refresh: boolean): void;
};

export const UserStatusChangeConfirmationModal = ({
  newStatus,
  user,
  handleModalClose,
}: Props) => {
  const queryClient = useQueryClient();

  const [loadingModal, setLoadingModal] = React.useState(false);
  const isActive = newStatus === VerificationStatus.ACTIVE;

  const handleUpdateStatus = async () => {
    setLoadingModal(true);
    try {
      const res = await updateMultipleUsers({
        userIds: [user.id],
        status: newStatus,
      } as UpdateMultipleUsersBody);
      if (res) {
        successToast({ title: 'User status updated successfully' });
        if (user?.id) {
          queryClient.invalidateQueries(usersKeys.user_profile(`${user?.id}`));
        }
      }
    } catch (error) {
      successToast({ title: `Error while updating user status.` });
      console.log('Error', error);
    }

    handleModalClose(true);
    setLoadingModal(false);
  };

  return (
    <NewModal
      headerText={`${isActive ? 'Activate' : 'Deactivate'} '${
        (user.firstName || '') + ' ' + (user.lastName || '')
      }'?`}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose(false)}
      style={{
        content: {
          minWidth: '592px',
          paddingLeft: '32px',
        },
      }}
    >
      <Title>
        Are you sure you want to {isActive ? 'activate' : 'deactivate'} '
        {(user.firstName || '') + ' ' + (user.lastName || '')}'?
      </Title>

      <SubmitButtonWrapper>
        <Button
          variant='secondary'
          onClick={() => handleModalClose(false)}
          text={`No, Keep  ${isActive ? 'Inactive' : 'Active'}`}
          disabled={loadingModal}
        />
        <Button
          variant='red'
          onClick={() => handleUpdateStatus()}
          text={`Yes, ${isActive ? 'Activate' : 'Deactivate'}`}
          disabled={loadingModal}
        />
      </SubmitButtonWrapper>
    </NewModal>
  );
};
