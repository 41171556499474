import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { useCovideoTheme } from 'react-covideo-common';

export const useNavigationColors = () => {
  const { whitelabel } = useAuth();

  const themes = useCovideoTheme();

  const getBgColor = () => {
    if (whitelabel.name === WHITELABEL_NAME.COVIDEO) {
      return theme.palette.darkGreen;
    }
    if (whitelabel.name === WHITELABEL_NAME.CDK) {
      return themes.colors.black[100];
    }
    return themes.colors.secondary[100];
  };
  const getColor = () => {
    if (whitelabel.name === WHITELABEL_NAME.COVIDEO) {
      return themes.colors.white[100];
    }
    if (whitelabel.name === WHITELABEL_NAME.CDK) {
      return themes.colors.white[100];
    }
    return themes.colors.white[80];
  };

  const backgroundColor = getBgColor();
  const color = getColor();

  return { color, backgroundColor };
};
