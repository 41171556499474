import { AxiosError } from 'axios';
import { IReseller } from 'lib/context';
import { useQuery } from 'react-query';

import { usersKeys } from './queryKeys';
import { IWhiteLabel } from '../whitelabel/getWhiteLabel';
import { EXPRESS_API } from 'configs/api/expressApi';
import { IMSFields } from 'app/pages/admin/ims/types';

export interface IUserData {
  id: number;
  customerId: number;
  resellerId: number;
  username: string;
  firstName: string;
  lastName: string;
  webName1: string;
  webName2: string;
  conferenceName: null;
  email: string;
  phone1: string;
  phone2: string;
  verified: number;
  signupDate: string;
  purchaseDate: number;
  Dept: string;
  website: string;
  title: string;
  access: number;
  admin: number;
  csAdmin: number;
  resellerAdmin: string | boolean;
  videoCount: number;
  defaultVolume: number;
  addedBy: string;
  betaj: number;
  onVirtualRep: number;
  videoRepTimeout: string;
  videoRepDept: number;
  betaMember: number;
  videoConfUser: number;
  emailIcon: string;
  playInEmail: boolean;
  notifyMe: boolean;
  playButtonPosition: number;
  webmail: number;
  integration: number;
  totalSecondsAllowed: number;
  totalSecondsUsed: number;
  BSORFLN: null;
  fusemail: boolean;
  distributor: number;
  packageId: number;
  trialAccount: boolean;
  affiliate: string;
  affiliateAccess: boolean;
  isEmailTemplate: boolean;
  isPending: boolean;
  qraAccess: boolean;
  loginDate: string;
  totalLogins: number;
  android: boolean;
  mobileEmailAccess: boolean;
  mobileSMSAccess: boolean;
  mobileShareAccess: boolean;
  mobileDownloadAccess: boolean;
  desktopEmailAccess: boolean;
  webEmailAccess: boolean;
  webShare: boolean;
  allowReplies: boolean;
  preloadTeleprompterScripts: boolean;
  globalBcc: string;
  infusionSoftCID: number;
  defaultSignature: number;
  timezone: string;
  showWelcome: number;
  transcriptionAccess: boolean;
  droplrAccess: boolean;
  transcriptionDefaultEnabled: boolean;
  autoTranscribe: boolean;
  phoneId: number;
  allowDelete: boolean;
  onboarded: boolean;
  eleadDMSEmployeeID: null;
  eleadEnabled: number;
  nmls: string;
  reactionsPreference: null;
  vdpEnabled: boolean;
  automotiveRole: number;
  videoShareLinkText?: string;
  reseller: IReseller;
  customer: ICustomer;
}
//Check with miho about interface
export type ICustomer = IMSFields & {
  hasReadOnlyVideoAttributeConstraint: number;
  hasLockableVideoAttribute: number;
  hasVideoAttributeDepartmentLimit: number;
  automotiveAccess: number;
  webShare: number;
  packageId: number;
  newPackageId: number;
  customerId: number;
  resellerId: number;
  stripeId: string;
  codirectId: null;
  infusionsoftContactId: number;
  infusionsoftCcid: number;
  infusionsoftSequence: number;
  contractId: number;
  business: string;
  customerLogo: string;
  dealerGroup: number;
  crmId: number;
  crmProviderId: number;
  defaultTemplate: number;
  firstName: string;
  quoteSettings: string;
  lastName: string;
  title: string;
  legalFirstName: string;
  legalLastName: string;
  legalTitle: string;
  eLeadEnabled: number;
  streetAddress: string;
  city: string;
  state: string;
  cardOther: string;
  zip: string;
  country: string;
  phone1: string;
  phone2: string;
  fax1: string;
  email: string;
  url: string;
  connSpeed: string;
  referredBy: string;
  referralAgent: number;
  coagent: number;
  accountManager: number;
  signupDate: string;
  endDate: null;
  trialEnd: string;
  maxUsers: number;
  totalSecondsAllowed: number;
  totalSecondsUsed: number;
  startPage: string;
  videoEmail: number;
  virtualRep: number;
  videoConference: number;
  videoOutlook: number;
  videoPartsSearch: number;
  videoBlast: number;
  videoOverlay: number;
  bandwidthAllowed: number;
  mailServer: string;
  crmReportEmail: string;
  videoRepEmail: string;
  active: number;
  emailIcon: string;
  streetAddressShipping: string;
  cityShipping: string;
  stateShipping: string;
  zipShipping: string;
  agreementLength: string;
  setupFee: string;
  monthlyFee: string;
  startingDate: string;
  shippingNumber: string;
  industry: string;
  numOfTemplates: number;
  integration: number;
  ownerId: number;
  verified: number;
  freeTrial: number;
  events: number;
  banner: string;
  bgImage: string;
  bgSlice: string;
  bgColor: string;
  txtColor1: string;
  txtColor2: string;
  maxUsersBasic: number;
  maxUsersAdvanced: number;
  maxUsersVideoEmail_1000: number;
  maxUsersVideoEmail_5000: number;
  maxUsersAdvanced_1000: number;
  maxUsersAdvanced_5000: number;
  maxUsersLiveConference: number;
  notes: string;
  carfax: number;
  slipstream: number;
  partnerType: number;
  cimProfileId: null;
  isEmailTemplate: number;
  triggerStartDate: string;
  allowReplies: number;
  displayInReports: number;
  customGraphics: number;
  socialLinksLocked: number;
  launched: number;
  launchDate: null;
  transcriptions: number;
  transcriptionLicenses: number;
  smsEnabled: number;
  smsProfileId: string;
  ssoId: number;
  droplrEnabled: number;
  landingPageBuilder: number;
  droplrLicenses: number;
  droplrTeamHash: string;
  dealerSocketId: null;
  dealerSocketFranchiseId: null;
  videoDeletionAccess: number;
  defaultSMSUserId: null;
  departmentTagEnabled: number;
  salesforceAccountId: string;
  dealerComId: string;
  dominionDealerId: string;
  credit700Url: string;
  dealerDotComIMS: string;
  groupId: number;
  reactionsPreference: null;
  leadsIntegrationActive: null;
  salesforceIntegration: number;
  wheelsTV: number;
  allowDelete: number;
  planEndsAt: null;
  isPlanCancelled: number;
  newMaxUsers: number;
  hasMultiLocation: string | null;
  locationId: any;
  regionId: number;
  preventUserEditAccess: string;
  liveAccess: number;
  iPacketDealerId: string;
  jdPowerVDPEnabled: number;
  apiDocumentationAccess?: string;
};

export const getUser = async (): Promise<IUserData> => {
  return (await EXPRESS_API.get(`/auth/user`, { withCredentials: true }))
    .data as IUserData;
};

export const useGetUserQuery = (whitelabel: IWhiteLabel | undefined) => {
  const isSignUp = window.location.pathname.includes('/signup');
  const isResetPassword = window.location.pathname.includes(
    '/verify-reset-password'
  );
  return useQuery(usersKeys.user(), getUser, {
    enabled: !!whitelabel && !(isSignUp || isResetPassword),
    onError: (err: AxiosError) => {
      if (err.response?.status === 401) {
        window.location.href = `https://${
          whitelabel?.domainV2 || 'www.covideo.com'
        }/backoffice/incs/logout.php`;
      }
    },
    onSuccess: _ => {
      if (!whitelabel) {
        return;
      }
    },
  });
};
