import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation } from 'react-query';

interface IConnectVinSolutionUsersRequest {
  data: { vinsolutionsUserId: number; userId: number };
  customerId: number;
}

export const connectVinsolutionUsers = async ({
  data,
  customerId,
}: IConnectVinSolutionUsersRequest) => {
  const response = await EXPRESS_API.put(
    `/crm/vinsolutions/${customerId}/users`,
    data
  );
  return response.data;
};

export const useVinSolutionsUsersMutation = () => {
  return useMutation(connectVinsolutionUsers, {
    onError: error => {
      console.log(error);
    },
  });
};
