import React, { useState } from 'react';
import { Button, Tooltip, useCovideoTheme } from 'react-covideo-common';
import { IoMdCalendar } from 'react-icons/io';
import { MdCancelScheduleSend, MdEmail, MdPhone } from 'react-icons/md';
import {
  parseDeliveryTimeToScheduledTimezone,
  parseDeliveryUnixTimestampToUserTimezone,
} from 'app/pages/account/userAccount/schedule/utils';
import { SortButtons } from 'app/pages/admin/components/SortButtons';
import { TableCell } from 'app/pages/reports/custom/main/CreatedByMe';
import {
  UserInvite,
  useUserInvitesQuery,
} from 'lib/api/superadmin/useUserInvitesQuery';
import {
  CheckboxInput,
  Dropdown,
  LoadingIndicator,
  Search,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { Flex, Gap } from 'lib/components/styles/layout';
import {
  ParagraphExtraSmallBold,
  ParagraphNormal,
  ParagraphNormalBold,
  ParagraphNormalBold500,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_SIZE_100,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { useAuth } from 'lib/context';
import { PaginationWrapper } from '../accountChanges/style';
import {
  IOnPaginationChange,
  MANAGE_INVITES_COLUMNS,
  MANAGE_INVITES_MODAL,
  USER_INVITES_ROLES_FILTER,
  userInvitesOptions,
  USER_INVITES_SORY_KEY,
  USER_INVITES_STATUS_FILTER,
  USER_INVITE_SENT_STATUS,
  userInvitesRolesOptions,
} from './types';
import { debounce } from 'lodash';
import { ManageInvitesSelectedUsers } from './ManageInvitesSelectedUsers';
import { copyField, getDisplayName } from 'lib/utils/functions';
import { ManageInvitesModal } from './ManageInvitesModal';
import { MangeInvitesCancelModal } from './MangeInvitesCancelModal';
import { PiWarningOctagonFill } from 'react-icons/pi';
import { useHistory } from 'react-router';
import { SCREEN_VALUES } from 'lib/const/SuperAdminConstants';
import { IoSend } from 'react-icons/io5';
import { RiPencilFill } from 'react-icons/ri';
import BouncedEmail from 'lib/components/bounced-email/BouncedEmail';
import { FaUser } from 'react-icons/fa';
import { LastFetched } from 'lib/components/LastFetched';
import { UserAccessBadge } from 'app/pages/admin/components/UserAccessBadge';

const manageInvitesTableFields = [
  {
    value: MANAGE_INVITES_COLUMNS.ACCESS,
    label: '',
    sortable: false,
  },
  {
    value: MANAGE_INVITES_COLUMNS.FIRST_NAME,
    label: 'First Name',
    sortable: true,
    sortKey: USER_INVITES_SORY_KEY.FIRST_NAME,
  },
  {
    value: MANAGE_INVITES_COLUMNS.LAST_NAME,
    label: 'Last Name',
    sortable: true,
    sortKey: USER_INVITES_SORY_KEY.LAST_NAME,
  },
  {
    value: MANAGE_INVITES_COLUMNS.EMAIL,
    label: 'Email',
  },
  {
    value: MANAGE_INVITES_COLUMNS.CONTACT,
    label: 'Contact',
  },
  {
    value: MANAGE_INVITES_COLUMNS.INVITE_SENT,
    label: 'Invite sent',
    sortable: true,
    sortKey: USER_INVITES_SORY_KEY.INVITE_SENT,
  },
  {
    value: MANAGE_INVITES_COLUMNS.BOUNCED,
    label: '',
  },
  {
    value: MANAGE_INVITES_COLUMNS.ACTIONS,
    label: '',
  },
];
interface ManageInvitesProps {
  customerId?: number;
}

export const ManageInvites = ({ customerId }: ManageInvitesProps) => {
  const { colors } = useCovideoTheme();
  const { userData } = useAuth();
  const history = useHistory();
  const [search, setSearchQuery] = useState('');
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [size, setSize] = useState(DEFAULT_SIZE_100);
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState(PaginationConstants.ASCENDING);
  const [selectedUsers, setSelectedUsers] = useState<UserInvite[]>([]);
  const [selectedStatus, setSelectedStatus] =
    useState<USER_INVITES_STATUS_FILTER | null>(null);
  const [selectedRole, setSelectedRole] = useState<USER_INVITES_ROLES_FILTER>(
    USER_INVITES_ROLES_FILTER.ALL
  );

  const [modalType, setModalType] = useState<null | {
    modalType: MANAGE_INVITES_MODAL;
    users: UserInvite[];
  }>(null);
  const sort = {
    sortKey: sortKey,
    order: sortOrder || PaginationConstants.ASCENDING,
  };

  const {
    data,
    isLoading,
    isRefetching,
    dataUpdatedAt,
    refetch: refetchUserInvites,
  } = useUserInvitesQuery({
    customerId,
    search,
    start: page * size || DEFAULT_PAGE,
    limit: size || DEFAULT_SIZE,
    status:
      selectedStatus === null ? USER_INVITES_STATUS_FILTER.ALL : selectedStatus,
    role: selectedRole,
    ...(sortKey === '' ? {} : { sortKey: sortKey }),
    order: sortOrder,
    refetchInterval: 10000,
  });

  const onResendHandler = async (user: UserInvite) => {
    setModalType({ modalType: MANAGE_INVITES_MODAL.INVITE, users: [user] });
  };
  const onInviteHandler = (user: UserInvite) => {
    setModalType({ modalType: MANAGE_INVITES_MODAL.INVITE, users: [user] });
  };
  const onInviteAllHandler = (users: UserInvite[]) => {
    setModalType({ modalType: MANAGE_INVITES_MODAL.INVITE, users });
  };
  const onEditSchedluedHandler = (user: UserInvite) => {
    setModalType({ modalType: MANAGE_INVITES_MODAL.INVITE, users: [user] });
  };

  const onCancelSchedluedHandler = (user: UserInvite) => {
    setModalType({ modalType: MANAGE_INVITES_MODAL.CANCEL, users: [user] });
  };
  const onCancelAllHandler = (users: UserInvite[]) => {
    setModalType({ modalType: MANAGE_INVITES_MODAL.CANCEL, users });
  };

  const handleModalClose = () => {
    setModalType(null);
  };

  const onCheckBoxClickHandler = (user: UserInvite) => {
    const userExist = selectedUsers.find(
      selectedUser => selectedUser.id === user.id
    );
    if (userExist) {
      const newUsers = selectedUsers.filter(
        selectedUser => selectedUser.id !== user.id
      );
      return setSelectedUsers(newUsers);
    }
    setSelectedUsers(prevState => [...prevState, user]);
  };

  const onSortingUpdate = (
    sortKey: string | undefined,
    order: PaginationConstants
  ) => {
    if (sortKey) {
      setSortKey(sortKey);
      setSortOrder(order);
      setPage(DEFAULT_PAGE);
    }
  };

  const onStatusChangeHandler = (value: {
    value: USER_INVITES_STATUS_FILTER;
  }) => {
    setSelectedStatus(value.value);
    setSelectedUsers([]);
  };

  const onSearchHandler = (value: string) => {
    setSearchQuery(value);
    setPage(DEFAULT_PAGE);
    setSelectedUsers([]);
  };

  const onPaginationChange = debounce(
    async ({ page: newPage, size: newSize }: IOnPaginationChange) => {
      setPage(newPage);
      setSize(newSize);
      setSelectedUsers([]);
    },
    500
  );

  const onRoleChangeHandler = (option: {
    value: USER_INVITES_ROLES_FILTER;
  }) => {
    setSelectedRole(option.value);
    setSelectedUsers([]);
  };

  //COLUMNS
  const getColumns = (user: UserInvite) => [
    <CheckboxInput
      width='36px'
      checked={
        !!selectedUsers.find(selectedUser => selectedUser.id === user.id)
      }
      onChange={() => onCheckBoxClickHandler(user)}
    />,
    // email
    ...manageInvitesTableFields.map(({ value }) => {
      if (value === MANAGE_INVITES_COLUMNS.ACCESS) {
        return (
          <UserAccessBadge
            isAutomotive={false}
            automotiveRole={0}
            access={user?.access?.toString()}
            mlAutoCreated={user?.mlAutoCreated}
          />
        );
      }

      if (value === MANAGE_INVITES_COLUMNS.EMAIL) {
        return (
          <TableCell key={value} width={250}>
            <ParagraphSmall color={colors.neutral[100]} ellipsis>
              {user[value]}
            </ParagraphSmall>
          </TableCell>
        );
      }
      // contact
      if (value === MANAGE_INVITES_COLUMNS.CONTACT) {
        return (
          <TableCell key={value} width={100}>
            <Gap gap='8px'>
              <Tooltip
                popup={
                  !!user.cellPhone
                    ? `Click to copy: ${user.cellPhone}`
                    : "Phone number doesn't exist."
                }
              >
                <MdPhone
                  color={
                    !!user.cellPhone ? colors.neutral[60] : colors.neutral[20]
                  }
                  style={{ cursor: 'pointer' }}
                  size={18}
                  onClick={() =>
                    !!user.cellPhone ? copyField(user.cellPhone, 'Phone') : null
                  }
                />
              </Tooltip>
              <Tooltip
                popup={
                  !!user.email
                    ? `Click to copy: ${user.email}`
                    : "Email doesn't exist."
                }
              >
                <MdEmail
                  color={!!user.email ? colors.neutral[60] : colors.neutral[20]}
                  style={{ cursor: 'pointer' }}
                  size={18}
                  onClick={() =>
                    !!user.email ? copyField(user.email, 'Email') : null
                  }
                />
              </Tooltip>
            </Gap>
          </TableCell>
        );
      }
      // invite sent
      if (value === MANAGE_INVITES_COLUMNS.INVITE_SENT) {
        if (user.userInvite?.status === USER_INVITE_SENT_STATUS.SCHEDULED) {
          return (
            <TableCell key={value} width={200}>
              <Gap gap='8px' style={{ color: colors.primary[100] }}>
                <Tooltip
                  popup={
                    <div style={{ color: colors.black[100] }}>
                      <Flex flexDirection='row' alignItems='center' gap='8px'>
                        <ParagraphNormalBold>Sender: </ParagraphNormalBold>
                        <div>
                          {getDisplayName([
                            user?.userInvite?.scheduler.firstName,
                            user?.userInvite.scheduler.lastName,
                          ])}
                        </div>
                      </Flex>
                      <Flex flexDirection='row' alignItems='center' gap='8px'>
                        <ParagraphNormalBold>Username: </ParagraphNormalBold>
                        <div>{user?.userInvite?.scheduler.username}</div>
                      </Flex>
                    </div>
                  }
                >
                  <IoMdCalendar size={18} style={{ marginTop: '2px' }} />
                </Tooltip>

                <Tooltip
                  popup={parseDeliveryTimeToScheduledTimezone(
                    user.userInvite?.deliveryTime || ''
                  )}
                >
                  <ParagraphSmall color={colors.primary[100]}>
                    {parseDeliveryUnixTimestampToUserTimezone(
                      user.userInvite?.deliveryUnixTimestamp,
                      userData
                    )}
                  </ParagraphSmall>
                </Tooltip>
              </Gap>
            </TableCell>
          );
        }
        if (user.userInvite?.status === USER_INVITE_SENT_STATUS.FAILED) {
          return (
            <TableCell key={value} width={246}>
              <Tooltip
                extendStyles={{ element: { cursor: 'pointer' } }}
                popup={
                  <ParagraphNormal color={colors.neutral[100]}>
                    Something went wrong
                    <br /> when sending invite. Please <br />
                    contact support to get <br /> more info.{' '}
                  </ParagraphNormal>
                }
                position={'bottom' as any}
              >
                <Gap alignItems='center' justifyContent='center' gap='4px'>
                  <PiWarningOctagonFill color={colors.danger[100]} size={18} />
                  <ParagraphExtraSmallBold color={colors.danger[100]}>
                    Invite failed
                  </ParagraphExtraSmallBold>
                </Gap>
              </Tooltip>
            </TableCell>
          );
        }

        return (
          <TableCell key={value} width={246}>
            {user?.userInvite === null ? (
              <ParagraphSmall>-</ParagraphSmall>
            ) : (
              <>
                {user.userInvite.deliveryTime !== null ? (
                  <Tooltip
                    popup={parseDeliveryTimeToScheduledTimezone(
                      user.userInvite?.deliveryTime || ''
                    )}
                  >
                    <ParagraphSmall color={colors.neutral[100]}>
                      {parseDeliveryUnixTimestampToUserTimezone(
                        user.userInvite?.deliveryUnixTimestamp,
                        userData
                      )}
                    </ParagraphSmall>
                  </Tooltip>
                ) : (
                  <Flex flexDirection='row' alignItems='center' gap='12px'>
                    <Tooltip
                      popup={
                        <div>
                          <Flex
                            flexDirection='row'
                            alignItems='center'
                            gap='8px'
                          >
                            <ParagraphNormalBold>Sender: </ParagraphNormalBold>
                            <div>
                              {getDisplayName([
                                user?.userInvite?.scheduler.firstName,
                                user?.userInvite?.scheduler.lastName,
                              ])}
                            </div>
                          </Flex>
                          <Flex
                            flexDirection='row'
                            alignItems='center'
                            gap='8px'
                          >
                            <ParagraphNormalBold>
                              Username:{' '}
                            </ParagraphNormalBold>
                            <div>{user?.userInvite?.scheduler.username}</div>
                          </Flex>
                        </div>
                      }
                    >
                      <FaUser
                        size={13}
                        color={colors.primary[100]}
                        style={{ marginLeft: '2px' }}
                      />
                    </Tooltip>
                    <ParagraphSmall>
                      {user.userInvite.deliveryUnixTimestamp === null
                        ? '-'
                        : parseDeliveryUnixTimestampToUserTimezone(
                            user.userInvite?.deliveryUnixTimestamp,
                            userData
                          )}
                    </ParagraphSmall>
                  </Flex>
                )}
              </>
            )}
          </TableCell>
        );
      }
      // user actions
      if (value === MANAGE_INVITES_COLUMNS.ACTIONS) {
        if (
          user.userInvite?.status === USER_INVITE_SENT_STATUS.SENT ||
          user.userInvite?.status === USER_INVITE_SENT_STATUS.FAILED
        ) {
          const isFailed =
            user.userInvite?.status === USER_INVITE_SENT_STATUS.FAILED;

          if (!!user.bouncedEmail) {
            return (
              <TableCell key={value} width={150}>
                <Flex
                  alignItems='center'
                  flexDirection='row'
                  gap='8px'
                  justifyContent='center'
                >
                  <Tooltip popup='Resend Invitation'>
                    <Button
                      variant='secondary'
                      icon={<IoSend size={13} />}
                      onClick={() => onResendHandler(user)}
                      style={{ width: '52px' }}
                    />
                  </Tooltip>
                  <Tooltip popup='Edit User'>
                    <Button
                      variant='secondary'
                      icon={<RiPencilFill size={20} />}
                      style={{ width: '52px' }}
                      onClick={() => {
                        history.push(
                          `/admin/users/${user.id}/${SCREEN_VALUES.CUSTOMERS}`
                        );
                      }}
                    />
                  </Tooltip>
                </Flex>
              </TableCell>
            );
          }
          return (
            <TableCell key={value} width={150}>
              <Flex justifyContent='center' flexDirection='row'>
                <Button
                  variant='secondary'
                  onClick={() => onResendHandler(user)}
                  text='Resend'
                  icon={<IoSend size={13} />}
                  style={{ width: '112px' }}
                  disabled={isFailed}
                  iconPosition='right'
                />
              </Flex>
            </TableCell>
          );
        }
        if (user.userInvite?.status === USER_INVITE_SENT_STATUS.SCHEDULED) {
          return (
            <TableCell key={value} width={150}>
              <Flex justifyContent='center' flexDirection='row' gap='8px'>
                <Tooltip popup='Cancel Scheduled Invite'>
                  <Button
                    variant='destructive'
                    onClick={() => onCancelSchedluedHandler(user)}
                    icon={<MdCancelScheduleSend />}
                    style={{ width: '52px' }}
                  />
                </Tooltip>
                <Tooltip popup='Edit Scheduled Invite' position={'left'}>
                  <Button
                    variant='secondary'
                    onClick={() => onEditSchedluedHandler(user)}
                    icon={<RiPencilFill size={20} />}
                    style={{ width: '52px' }}
                  />
                </Tooltip>
              </Flex>
            </TableCell>
          );
        }
        return (
          <TableCell key={value} width={150}>
            <Flex justifyContent='center' flexDirection='row'>
              <Button
                variant='primary'
                onClick={() => onInviteHandler(user)}
                text='Invite'
                style={{ width: '112px' }}
              />
            </Flex>
          </TableCell>
        );
      }

      if (value === MANAGE_INVITES_COLUMNS.BOUNCED) {
        const bounced = !!user.bouncedEmail;

        return (
          <TableCell width={70}>
            {bounced ? (
              <BouncedEmail
                email={user.email}
                bouncedEmail={user.bouncedEmail}
              />
            ) : (
              <></>
            )}
          </TableCell>
        );
      }
      return (
        <TableCell key={value} width={158}>
          <ParagraphNormalBold500 color={colors.neutral[100]} ellipsis>
            {user[value]}
          </ParagraphNormalBold500>
        </TableCell>
      );
    }),
  ];

  //HEADERS
  const headers = [
    <CheckboxInput
      width='36px'
      checked={(data?.users.length || []) == selectedUsers.length}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const { checked } = event.target as HTMLInputElement;
        setSelectedUsers(checked ? data?.users || [] : []);
      }}
    />,
    ...manageInvitesTableFields.map((item, index) => {
      return (
        <TableCell key={index}>
          {item.label}
          {item?.sortable && item?.sortKey && (
            <SortButtons
              isSorted={sort.sortKey === item.sortKey}
              order={sort.order}
              onChange={value => onSortingUpdate(item.sortKey, value)}
            />
          )}
        </TableCell>
      );
    }),
    '',
  ];

  //ROWS
  const rows =
    data?.users?.map((item, index: number) => ({
      key: index,
      columns: getColumns(item),
    })) || [];

  return (
    <>
      {selectedUsers.length > 0 && (
        <ManageInvitesSelectedUsers
          selectedUsers={selectedUsers}
          users={data?.users || []}
          setSelectedUsers={setSelectedUsers}
          onInviteAllHandler={onInviteAllHandler}
          onCancelAllHandler={onCancelAllHandler}
        />
      )}
      <LastFetched
        dataUpdatedAt={dataUpdatedAt}
        isRefetching={isRefetching}
        refetch={refetchUserInvites}
      />
      <Gap
        flexDirection='row'
        style={{ width: '100%', marginBottom: 16, marginTop: 16 }}
      >
        <Search
          placeholder='Search users...'
          onSearch={onSearchHandler}
          width='240px'
          disabled={isLoading}
        />
        <Dropdown
          menuPortalTarget={document.body}
          menuPlacement={'bottom'}
          height={40}
          creatable={false}
          className='dropdown'
          placeholder={'All Statuses'}
          zIndexProp={1}
          value={userInvitesOptions.find(
            option => option.value === selectedStatus
          )}
          onChange={onStatusChangeHandler}
          options={userInvitesOptions}
          width={224}
          disabled={isLoading}
        />

        <Dropdown
          menuPortalTarget={document.body}
          menuPlacement='bottom'
          height={40}
          creatable={false}
          className='dropdown'
          placeholder='All Roles'
          zIndexProp={1}
          value={userInvitesRolesOptions.find(
            option => option.value === selectedRole
          )}
          onChange={onRoleChangeHandler}
          options={userInvitesRolesOptions}
          width={334}
          disabled={isLoading}
        />
      </Gap>
      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={true} />
        </div>
      ) : (
        <>
          {data?.count === 0 ? (
            <Gap
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 200,
              }}
            >
              <ParagraphSmall>No users to show</ParagraphSmall>
            </Gap>
          ) : (
            <TableContextProvider
              total={data?.count}
              onChange={onPaginationChange}
              initPage={page}
              initSize={size}
            >
              <div style={{ paddingBottom: '20px' }}>
                <Table
                  compact={true}
                  relative={true}
                  headers={headers}
                  hoverable={false}
                  fixColumnIndex={[0]}
                  rows={rows}
                />
              </div>
              <PaginationWrapper>
                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew text='Show rows:' />
                </TableFooter>
              </PaginationWrapper>
            </TableContextProvider>
          )}
          {modalType?.modalType === MANAGE_INVITES_MODAL.INVITE && (
            <ManageInvitesModal
              handleModalClose={handleModalClose}
              users={modalType.users}
              customerId={customerId}
              setSelectedUsers={setSelectedUsers}
            />
          )}
          {modalType?.modalType === MANAGE_INVITES_MODAL.CANCEL && (
            <MangeInvitesCancelModal
              handleModalClose={handleModalClose}
              users={modalType.users}
              customerId={customerId}
              setSelectedUsers={setSelectedUsers}
            />
          )}
        </>
      )}
    </>
  );
};
