import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: white;
  overflow: hidden;
`;

const ModalHeader = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 37px;
  background: ${theme.palette.white};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 3;
`;

const ModalBody = styled.div`
  box-sizing: border-box;
  padding: 32px 0;
  height: calc(100vh - 64px);
  overflow-y: scroll;
`;

type FullScreenModalProps = {
  headerLeft?: React.ReactNode;
  headerCenter?: React.ReactNode;
  headerRight?: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export const FullScreenModal = ({
  headerLeft,
  headerCenter,
  headerRight,
  children,
  style,
}: FullScreenModalProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const root = document.getElementById('root') as HTMLElement;
    root.style.overflow = 'hidden';
    return () => {
      root.style.overflow = '';
    };
  }, []);

  const isWrapperOnly = !!headerLeft || !!headerCenter || !!headerRight;

  return (
    <ModalWrapper style={style}>
      {isWrapperOnly && (
        <ModalHeader>
          {!!headerLeft && <div>{headerLeft}</div>}
          {!!headerCenter && (
            <div style={{ margin: 'auto' }}>{headerCenter}</div>
          )}
          {!!headerRight && <div>{headerRight}</div>}
        </ModalHeader>
      )}

      {!isWrapperOnly ? children : <ModalBody>{children}</ModalBody>}
    </ModalWrapper>
  );
};
