import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { ContentWrapper } from 'app/pages/library/videos/videoList/components/SendAndShare/send/SendTab';
import { useAuth } from 'lib/context';
import {
  LoadingIndicator,
  ModalVideoListVideo,
  TableContextProvider,
  Search,
  TablePaginationNew,
} from 'lib/components';
import { Button, Tooltip } from 'react-covideo-common';
import { HelpDesk } from '../../helpDesk';
import { EHelpDesk } from '../../helpDesk/utils';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { Gap } from '../../styles/layout';
import {
  useWheelsTvVideosQuery,
  WTVListItem,
  WtvSortKey,
} from 'lib/api/wheelsTv/useWheelsTvVideosQuery';
import { useWheelsTvSourceQuery } from 'lib/api/wheelsTv/useWheelsTvSourceQuery';
import { useWheelsTvCopyMutation } from 'lib/api/wheelsTv/useCopyWheelsTvMutation';
import { ParagraphSmallBold } from '../../styles/typography';
import { theme } from 'lib/style';
import { VideoItemThumbnail } from 'app/pages/library/videos/components';
import imagePlaceholder from 'assets/images/single/imagePlaceholder.webp';
import selectors from '../../../../cypress/selectors';
import { debounce } from 'lodash';
import { useWtvFiltersQuery } from 'lib/api/wheelsTv/useWtvFiltersQuery';
import { MultiselectDropdown } from 'lib/components/dropdown/MultiselectDropdown';
import { SortButtons } from 'app/pages/admin/components/SortButtons';

const Content = styled(ContentWrapper)`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 956px;
  padding-bottom: 0;
`;

const Video = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid rgb(223, 227, 230);
`;

const VideosList = styled.div`
  width: 100%;
  min-height: 530px;
`;

const BaseModalText = styled.div`
  height: 24px;
  margin-left: 24px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left !important;
  color: ${theme.palette.title};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const BaseHeaderText = styled(BaseModalText)`
  font-size: 14px;
  color: ${theme.palette.blackRgb60};
  display: flex;
`;
// vehicle year
const VehicleYearWidth = css`
  max-width: 185px;
  min-width: 80px;
`;
const VehicleYear = styled(BaseModalText)`
  ${VehicleYearWidth}
`;

const VehicleYearHeader = styled(BaseHeaderText)`
  ${VehicleYearWidth}
`;
// vehicle make
const VehicleMakeWidth = css`
  max-width: 180px;
  min-width: 130px;
`;
const VehicleMake = styled(BaseModalText)`
  ${VehicleMakeWidth}
`;
const VehicleMakeHeader = styled(BaseHeaderText)`
  ${VehicleMakeWidth}
`;
// vehicle model
const VehicleModelWidth = css`
  width: inherit;
`;
const VehicleModel = styled(BaseModalText)`
  ${VehicleModelWidth}
`;

const VehicleModelHeader = styled(BaseHeaderText)`
  ${VehicleModelWidth}
`;

const PreviewModelHeader = styled(BaseHeaderText)`
  width: 105px;
  margin-left: 0;
`;

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 530px;
`;

const Footer = styled(ContentWrapper)`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  padding-bottom: 0;
`;

const DEFAULT_PAGE = 0;
const DEFAULT_SIZE = 10;

type Props = {
  showAddToCovideo?: boolean;
  handleModalClose?: () => void;
  onCopy?: () => void;
  onSelectVideo?: (lesaVideo: WTVListItem) => void;
};

export const WheelsTVTable = ({
  handleModalClose,
  onCopy,
  showAddToCovideo = true,
  onSelectVideo,
}: Props) => {
  const { userData } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [wtvVideoId, setWtvVideoId] = useState('');
  const [videoSource, setVideoSource] = useState('');
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [order, setOrder] = useState<PaginationConstants>(
    PaginationConstants.ASCENDING
  );
  const [sortKey, setSortKey] = useState<WtvSortKey | null>(null);
  const [filter, setFilter] = useState<{
    [key: string]: string[];
  }>({});

  // consts
  const resetPage = () => {
    setPage(0);
  };

  const onSuccesCallback = () => {
    onCopy?.();
    handleModalClose?.();
  };

  //queries
  const { data: filterData, isLoading: isLoadingFilters } =
    useWtvFiltersQuery();
  const { data, isFetching } = useWheelsTvVideosQuery({
    start: page * DEFAULT_SIZE,
    limit: DEFAULT_SIZE,
    page,
    search: searchTerm,
    filter,
    ...(!!sortKey && !!order ? { sortKey, order } : {}),
  });

  useWheelsTvSourceQuery({ wtvVideoId, setVideoSource });

  // mutations
  const { mutateAsync: copyWheelsTvMutation, isLoading: isCopyingVideo } =
    useWheelsTvCopyMutation({
      onSuccesCallback,
    });

  const isLoading = isCopyingVideo || isFetching || isLoadingFilters;

  const onSearchChangeHandler = async (search: string = '') => {
    setSearchTerm(search);
    resetPage();
  };

  const onPaginationChangeHandler = debounce(
    async ({ page: newPage }: { page: number }) => {
      if (page !== newPage) {
        setPage(newPage);
      }
    },
    300
  );

  const copyVideoTVMutationHandler = (video: WTVListItem) => {
    copyWheelsTvMutation({
      title: video.year + ' ' + video.make + ' ' + video.model,
      flvName: video.wtvVideoId,
      animatedGifPath: video.animatedGifPath,
      year: parseInt(video.year),
      make: video.make,
      model: video.model,
      wtvId: video.wtvId,
    });
  };

  const handleFilterChange = (key: string, selectedOption: string[]) => {
    if (key === 'make') {
      setFilter(prevState => ({
        ...prevState,
        [key]: selectedOption,
        model: [],
      }));
      return;
    }
    setFilter(prevState => ({
      ...prevState,
      [key]: selectedOption,
    }));
  };

  const handleSortChange = (
    sortKey: WtvSortKey,
    order: PaginationConstants
  ) => {
    setOrder(order);
    setSortKey(sortKey);
    setPage(DEFAULT_PAGE);
  };

  const getEmptyStateMessage = () => {
    const hasFilters = Object.values(filter).some(
      val => Array.isArray(val) && val.length > 0
    );

    if (searchTerm && hasFilters) {
      return {
        primary: `No videos found matching "${searchTerm}" with your selected filters.`,
        secondary:
          'Try adjusting your filters or using a different search term.',
      };
    }
    if (searchTerm) {
      return {
        primary: `There are no videos with "${searchTerm}" in the title.`,
        secondary: 'Try using a different search term.',
      };
    }
    if (hasFilters) {
      return {
        primary: 'No videos match your selected filters.',
        secondary: 'Try selecting different filter options.',
      };
    }
    return {
      primary:
        "Unfortunately, there aren't any videos available at the moment.",
      secondary: '',
    };
  };

  const showSelectVideo = !!onSelectVideo;

  return (
    <Content>
      {userData.isAutomotive ? <HelpDesk name={EHelpDesk.WHEELS} /> : null}
      <Gap
        m='0 0 20px auto'
        alignItems='flex-end'
        justifyContent='space-between'
        width='100%'
      >
        {filterData && (
          <Gap gap='12px'>
            {filterData.filters?.map(item => {
              // custom rule for model dropdown
              if (item.key === 'model') {
                const isDisabled = isLoadingFilters || !filter?.make?.length;
                return (
                  <Tooltip
                    popup={isDisabled ? 'Select Make first' : ''}
                    position='bottom'
                  >
                    <MultiselectDropdown
                      key={`model-${filter.make?.join('-')}`}
                      onOptionsSelected={value =>
                        handleFilterChange(item.key, value as string[])
                      }
                      options={item.options.filter(
                        option =>
                          filter.make &&
                          option.group &&
                          filter.make.includes(option.group)
                      )}
                      menuListValue={-1}
                      menuListLabel={`All ${item.name}s`}
                      name={`${item.name}`}
                      menuShouldBlockScroll={false}
                      isLoading={isLoadingFilters}
                      selectInputWidth='200px'
                      menuWidth='280px'
                      isDisabled={isDisabled}
                      isSearchable
                      isClearable
                    />
                  </Tooltip>
                );
              }
              return (
                <MultiselectDropdown
                  key={item.key}
                  onOptionsSelected={value =>
                    handleFilterChange(item.key, value as string[])
                  }
                  options={item.options}
                  menuListValue={-1}
                  menuListLabel={`All ${item.name}s`}
                  name={`${item.name}`}
                  menuShouldBlockScroll={false}
                  isLoading={isLoadingFilters}
                  selectInputWidth='200px'
                  menuWidth='280px'
                  isDisabled={isLoadingFilters}
                  isSearchable
                  isClearable
                />
              );
            })}
          </Gap>
        )}
        <Search
          placeholder='Search videos...'
          onSearch={onSearchChangeHandler}
          width='300px'
        />
      </Gap>
      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={isLoading} />
        </div>
      ) : (
        <>
          {!data?.videos?.length ? (
            <EmptySearch>
              {(() => {
                const message = getEmptyStateMessage();
                return (
                  <>
                    <ParagraphSmallBold color={theme.palette.blue60}>
                      {message.primary}
                    </ParagraphSmallBold>
                    {message.secondary && (
                      <ParagraphSmallBold color={theme.palette.blue60}>
                        {message.secondary}
                      </ParagraphSmallBold>
                    )}
                  </>
                );
              })()}
            </EmptySearch>
          ) : (
            <>
              <VideosList>
                <Video>
                  <PreviewModelHeader>Preview</PreviewModelHeader>
                  <VehicleYearHeader>
                    Year
                    <SortButtons
                      isSorted={sortKey === WtvSortKey.YEAR}
                      order={order}
                      onChange={value =>
                        handleSortChange(WtvSortKey.YEAR, value)
                      }
                    />
                  </VehicleYearHeader>
                  <VehicleMakeHeader>
                    Make
                    <SortButtons
                      isSorted={sortKey === WtvSortKey.MAKE}
                      order={order}
                      onChange={value =>
                        handleSortChange(WtvSortKey.MAKE, value)
                      }
                    />
                  </VehicleMakeHeader>
                  <VehicleModelHeader>
                    Model
                    <SortButtons
                      isSorted={sortKey === WtvSortKey.MODEL}
                      order={order}
                      onChange={value =>
                        handleSortChange(WtvSortKey.MODEL, value)
                      }
                    />
                  </VehicleModelHeader>
                </Video>
                {data?.videos?.map((video: WTVListItem) => (
                  <Video key={video.wtvId}>
                    <VideoItemThumbnail
                      handleClick={() => setWtvVideoId(video.wtvVideoId)}
                      thumbnail={video.animatedGifPath || imagePlaceholder}
                      videoId={video.wtvVideoId}
                      processing={0}
                      thumbnailHeight={'80%'}
                      thumbnailWidth={'15%'}
                    />
                    <VehicleYear>{video.year}</VehicleYear>
                    <VehicleMake>{video.make}</VehicleMake>
                    <VehicleModel>{video.model}</VehicleModel>
                    <Gap flexWrap='noWrap' gap='10px'>
                      {showAddToCovideo && (
                        <Button
                          variant='secondary'
                          text='Add to Covideo'
                          onClick={() => copyVideoTVMutationHandler(video)}
                          data-cy={
                            selectors.libraryPage.wheelsTvAddToCovideoButton
                          }
                        />
                      )}
                      {showSelectVideo && (
                        <Button
                          variant='secondary'
                          text='Select Video'
                          onClick={() => onSelectVideo(video)}
                        />
                      )}
                    </Gap>
                  </Video>
                ))}
              </VideosList>
              <Footer>
                <TableContextProvider
                  total={data?.count || 0}
                  initSize={DEFAULT_SIZE}
                  onChange={onPaginationChangeHandler}
                  initPage={page}
                >
                  <TablePaginationNew disabled={isLoading} />
                </TableContextProvider>
              </Footer>
            </>
          )}

          {!!videoSource && (
            <ModalVideoListVideo
              videoId={''}
              source={videoSource}
              handleModalClose={() => {
                setVideoSource('');
                setWtvVideoId('');
              }}
              hideContext={true}
            />
          )}
        </>
      )}
    </Content>
  );
};
