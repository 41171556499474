import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

interface IUpdateLeadParams {
  leads: number[];
  leadGroups: number[];
}

const linkLeadGroup = async (params: IUpdateLeadParams) => {
  return await EXPRESS_API.put(`/lead-groups/link`, { ...params });
};

export const useLinkLeadGroupMutation = () => {
  const { showError } = useToastError();
  return useMutation(linkLeadGroup, {
    onSuccess: () => {
      successToast({ title: 'Customers has been linked!' });
    },
    onError: error =>
      showError(error, 'An error occured while trying to link the group!'),
  });
};
