import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { videoAttributesKeys } from './videoAttributesKeys';

export const deleteAttribute = async (attributeId: string) => {
  await EXPRESS_API.delete(`/video-attributes/${attributeId}`);

  return { attributeId };
};

export const useRemoveAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAttribute, {
    onSuccess: () => {
      queryClient.refetchQueries(videoAttributesKeys.attributes());
      successToast({
        title: 'Video Attribute deleted successfully.',
      });
    },
  });
};
