import { useAuth } from 'lib/context';
import { shouldNavigateToManageUsers } from 'lib/utils/productFeature';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ManageUsersPage } from '../../components/ManageUserPage';
import { UserDetails } from './UserDetails';
// import { Details } from './Details';

export const Main = () => {
  const { userData } = useAuth();
  const showNewPage = shouldNavigateToManageUsers(userData);

  return (
    <Switch>
      <Route
        path='/users/entry'
        component={showNewPage ? ManageUsersPage : UserDetails}
        exact={true}
      />
      <Route
        path='/users/entry/:id'
        component={showNewPage ? ManageUsersPage : UserDetails}
        exact={true}
      />
    </Switch>
  );
};
