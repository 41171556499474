import * as React from 'react';
import { DefaultLabel } from 'lib/components/labels';
import { theme } from 'lib/style';
import styled, { useTheme } from 'styled-components/macro';
import { MdArrowDropDown } from 'react-icons/md';
import { useEffect, useRef } from 'react';

type Props = {
  options?: SmallDropdownOption[];
  onItemClick: (option: SmallDropdownOption) => void;
  onClickOutSide?: Function;
  width?: string;
};

type OptionsDropdownProps = {
  isOpen?: boolean;
  width?: string;
};

type SmallDropdownOption = {
  id: string;
  text: string;
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 24px;
`;

const TitleAndArrow = styled(DefaultLabel)`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 26px;
  height: 14px;
  padding: 1px;
`;

const Dropdown = styled.div<OptionsDropdownProps>`
  position: absolute;
  background: #fff;
  right: 0;
  top: 26px;
  left: 0;
  width: ${props => (props.width ? props.width : '180px')};
  border: ${props =>
    props.isOpen ? `1px solid ${theme.palette.grayBorder}` : 'none'};
  box-sizing: border-box;
  padding: ${props => (props.isOpen ? '12px 20px 8px 12px' : 0)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: ${props => (props.isOpen ? 'auto' : 'hidden')};
  max-height: ${props => (props.isOpen ? '176px' : 0)};
  z-index: 100;
`;

const Item = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary[100]}; /** SUS-961 changes **/
  }
`;

export const SELECT_ALL_DROPDOWN_OPTIONS = [
  {
    id: '1',
    text: 'Select All',
  },
];

export const SelectAllDropdown = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    options = SELECT_ALL_DROPDOWN_OPTIONS,
    onItemClick,
    onClickOutSide,
    width = '285px',
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const themes = useTheme();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutSide && onClickOutSide();
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutSide]);

  const handleClick = (option: SmallDropdownOption) => {
    onItemClick(option);
    setIsOpen(false);
  };
  return (
    <Container onBlur={() => setIsOpen(false)} ref={ref}>
      <TitleAndArrow onClick={() => setIsOpen(!isOpen)}>
        <MdArrowDropDown
          style={{
            color: isOpen ? themes.colors.primary[100] : theme.palette.label,
          }} /** SUS-961 changes **/
          width={30}
          height={30}
        />
      </TitleAndArrow>
      <Dropdown isOpen={isOpen} width={width}>
        {options.map((option: SmallDropdownOption, index: number) => {
          return (
            <Item key={index} onClick={() => handleClick(option)}>
              {option.text}
            </Item>
          );
        })}
      </Dropdown>
    </Container>
  );
};
