import { theme } from 'lib/style';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components/macro';
import Select, { components, StylesConfig } from 'react-select';
import { MdAdd, MdFolder } from 'react-icons/md';
import { HoverPopup } from 'lib/components';
import { CustomerHover } from 'app/pages/repairOrders/components';
import { trim, uniqBy } from 'lodash';
import {
  AdditionalRecipient,
  MultipleRecipientType,
  Recipient,
} from '../types';
import selectors from '../../../../../../../../cypress/selectors';
import { FaUser } from 'react-icons/fa';
import { debounce } from 'lodash';
import { IoMdCloseCircle } from 'react-icons/io';
import { Gap } from 'lib/components/styles/layout';
import { EmailCount } from './EmailCount';

import { EMAIL_REGEX } from 'lib/utils/regexes';
import { errorToast } from 'lib/components/toasts/error';

type OptionLabelProps = {
  label: string;
  phone: string;
  name: string;
};

const Card = styled.div`
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
  padding: 24px;
`;
const CardTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${theme.palette.covideoBlue100};
`;
const SelectedWrapper = styled.div<{ padding?: string }>`
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding ?? '4px 6px'};
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.secondary['non_alpha']};
  color: ${theme.palette.primaryDarkBlue};
`;
const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
`;
const OptionLabel = styled.div`
  margin-left: 12px;
  width: 700px;
  max-width: 100%;
`;
const CustomOptionLabel = styled.div`
  display: flex;
  justify-content: 'flex-end';
  width: 100%;
  align-items: center;
`;
const Name = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray60};
  text-align: right;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Email = styled.div`
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;
const Phone = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray60};
  text-align: right;
  width: 120px;
  margin-left: auto;
`;

const ExpandedContentWrapper = styled.div`
  margin-top: 8px;
`;

const ClearSelect = styled.div`
  margin-left: 6px;
  padding-top: 3px;
  cursor: pointer;
`;

const SelectedValueText = styled.div<{ isGroup?: boolean }>`
  font-weight: ${({ isGroup }) => (isGroup ? 600 : 400)};
  font-size: ${({ isGroup }) => (isGroup ? '14px' : '16px')};
  color: ${({ isGroup }) =>
    isGroup ? theme.palette.blue80 : theme.palette.blue100};
  line-height: 24px;
  display: flex;
  align-items: center;
  max-width: 700px;
  @media screen and (max-width: 800px) {
    max-width: 280px;
  }
`;

const GroupContactCount = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: 4px;
  color: ${theme.palette.blue80};
`;

const selectStyle: StylesConfig<OptionLabelProps, boolean> = {
  control: (styles: any, state: any) => ({
    ...styles,
    minHeight: '40px',
    boxShadow: 'none',
    borderColor: state.isFocused
      ? theme.palette.covideoBlue100
      : theme.palette.gray40,
    ':hover': {
      borderColor: theme.palette.covideoBlue100,
    },
    ':focus': {
      borderColor: theme.palette.covideoBlue100,
    },
    ':active': {
      borderColor: theme.palette.covideoBlue100,
    },
  }),
  option: base => ({
    ...base,
    backgroundColor: 'transparent',
    color: theme.palette.gray100,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
    ':focus': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
  }),
  singleValue: base => ({
    ...base,
    position: 'static',
    width: 'auto',
    maxWidth: 'initial',
    transform: 'none',
  }),
  multiValue: base => ({
    ...base,
    position: 'static',
    width: 'auto',
    maxWidth: 'initial',
    transform: 'none',
    background: 'transparent',
  }),
  multiValueRemove: base => ({
    ...base,
    ':hover': {
      backgroundColor: 'transparent',
    },
    ':focus': {
      backgroundColor: 'transparent',
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  valueContainer: base => ({
    ...base,
    padding: 4,
    overflow: 'show',
    gap: 4,
  }),
  input: base => ({
    ...base,
    maxWidth: '60%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
};

const createNew = {
  value: '0',
  label: 'Create New Customer',
  phone: '',
  name: '',
  type: MultipleRecipientType.CREATE_NEW,
  contactsCount: 0,
};

const filterOptions = (
  candidate: { label: string; value: string; data: any },
  input: string
) => {
  if (candidate.value === createNew.value) {
    return true;
  }
  const label = candidate.label.toLowerCase();
  const name = candidate.data.name ? candidate.data.name.toLowerCase() : '';
  const phone = candidate.data.phone ? candidate.data.phone.toLowerCase() : '';
  const haystack = `${label} ${name} ${phone}`;
  return haystack.includes(input.toLowerCase());
};

const Option = (props: any) => {
  return (
    <OptionWrapper
      data-cy={selectors.libraryPage.sendAndShareModal.recipientOption}
    >
      {props.value === '0' ? (
        <MdAdd color={theme.palette.blue40} />
      ) : props?.value && props.value.toString().includes('group-') ? (
        <MdFolder color={theme.palette.blue40} />
      ) : (
        <FaUser color={theme.palette.blue40} />
      )}
      <OptionLabel>
        <components.Option {...props} />
      </OptionLabel>
    </OptionWrapper>
  );
};

type Props = {
  selectedRecipientIds: (string | number)[] | null;
  onChangeRecipient: (selectedRecipientIds: (string | number)[]) => void;
  recipients: Recipient[];
  expandedContent: ReactNode;
  showExpandedContent: boolean;
  createNewOption?: boolean;
  onInputChange: (inputValue: string) => void;
  multiple?: boolean;
  onMenuScrollToBottom: () => void;
  title: string;
  emailLimit: number;
  leadsIntegrationActive: boolean;
};

export const RecipientSearch = ({
  selectedRecipientIds,
  onChangeRecipient,
  recipients,
  expandedContent,
  showExpandedContent,
  onInputChange,
  createNewOption = true,
  multiple = false,
  onMenuScrollToBottom,
  title,
  emailLimit,
  leadsIntegrationActive,
}: Props) => {
  const ref = useRef(null);
  const [selectRef, setSelectRef] = useState<any>(ref);
  const [options, setOptions] = useState<any[]>([]);
  const [isImporting, setIsImporting] = useState(false);
  const MAX_BULK_IMPORT = 50;
  const [additionalRecipients, setAdditionalRecipients] = useState<Recipient[]>(
    []
  );
  const [internalInputValue, setInternalInputValue] = useState('');

  const handleRecipientChange = (option: any) => {
    if (!option) {
      onChangeRecipient([]);
      return;
    }
    if (!multiple) {
      onChangeRecipient([option.value]);
    }
    if (multiple) {
      onChangeRecipient(option.map((o: any) => o.value));
    }
  };

  const formatOptionLabel = ({ label, phone, name }: OptionLabelProps) => (
    <CustomOptionLabel>
      <Email>{label || '-'}</Email>
      <Phone>&nbsp;{phone || '-'}</Phone>
      <Name>&nbsp;{name.trim() || '-'}</Name>
    </CustomOptionLabel>
  );

  const SingleValue = ({ children, ...props }: any) => {
    let selectedValueText;
    if (trim(props.data.name)) {
      selectedValueText = props.data.name;
    } else if (props.data.label) {
      selectedValueText = props.data.label;
    } else if (props.data.phone) {
      selectedValueText = props.data.phone;
    } else {
      selectedValueText = children;
    }
    return (
      <SelectedWrapper>
        <HoverPopup
          position='bottom-left'
          popup={
            <CustomerHover
              firstName={trim(props.data.name)}
              lastName={''}
              email={props.data.label}
              phone={props.data.phone}
            />
          }
          hoverable={
            <>
              <components.SingleValue {...props}>
                <SelectedValueText>{selectedValueText}</SelectedValueText>
              </components.SingleValue>
            </>
          }
          disableHover={selectedRecipientIds?.includes('0')}
        />
        <ClearSelect onClick={() => selectRef.select.clearValue()}>
          <IoMdCloseCircle color={theme.palette.blue40} size={13} />
        </ClearSelect>
      </SelectedWrapper>
    );
  };

  const MultiValueRemove = (props: any) => {
    return (
      <components.MultiValueRemove {...props}>
        <IoMdCloseCircle color={theme.palette.blue40} size={13} />
      </components.MultiValueRemove>
    );
  };

  const MultiValue = ({ children, ...props }: any) => {
    let selectedValueText;
    if (props.data.type === MultipleRecipientType.GROUP) {
      selectedValueText = props.data.label;
    } else if (trim(props.data.name)) {
      selectedValueText = props.data.name;
    } else if (props.data.label) {
      selectedValueText = props.data.label;
    } else if (props.data.phone) {
      selectedValueText = props.data.phone;
    } else {
      selectedValueText = children;
    }
    return (
      <SelectedWrapper padding='0'>
        <HoverPopup
          position='bottom-left'
          popup={
            <CustomerHover
              firstName={
                props.data.type === MultipleRecipientType.GROUP
                  ? ''
                  : trim(props.data.name)
              }
              lastName={''}
              email={
                props.data.type === MultipleRecipientType.GROUP
                  ? props.data.name
                  : props.data.label
              }
              phone={props.data.phone}
            />
          }
          hoverable={
            <>
              <components.MultiValue {...props}>
                <SelectedValueText
                  isGroup={props.data.type === MultipleRecipientType.GROUP}
                >
                  {props.data.type === MultipleRecipientType.GROUP && (
                    <MdFolder
                      size={22}
                      color={theme.palette.blue40}
                      style={{ marginRight: 6 }}
                    />
                  )}{' '}
                  {selectedValueText}
                  {props.data.type === MultipleRecipientType.GROUP && (
                    <GroupContactCount>
                      {props.data.contactsCount} contact
                      {props.data.contactsCount === 1 ? '' : 's'}
                    </GroupContactCount>
                  )}
                </SelectedValueText>
              </components.MultiValue>
            </>
          }
          disableHover={selectedRecipientIds?.includes('0')}
        />
      </SelectedWrapper>
    );
  };

  const handleBulkPaste = async (pastedText: string): Promise<boolean> => {
    const splitted = pastedText
      .split(/[;,]+/)
      .map(s => s.trim())
      .filter(Boolean);

    if (splitted.length > MAX_BULK_IMPORT) {
      errorToast({
        title: `You can upload up to ${MAX_BULK_IMPORT} emails on bulk upload.`,
      });
      return false;
    }
    setIsImporting(true);
    try {
      const uniqueEmails = Array.from(new Set(splitted));
      const validEmails = uniqueEmails.filter(token => EMAIL_REGEX.test(token));
      const invalidEmails = uniqueEmails.filter(
        token => !EMAIL_REGEX.test(token)
      );

      if (invalidEmails.length) {
        errorToast({ title: `Invalid emails: ${invalidEmails.join(', ')}` });
      }

      const newEmailOptions = validEmails.map(email => ({
        id: email,
        value: email,
        label: email,
        email: email,
        phone: '',
        firstName: '',
        lastName: '',
        name: email,
        type: leadsIntegrationActive
          ? MultipleRecipientType.LEAD
          : MultipleRecipientType.CONTACT,
        contactsCount: 0,
      }));

      setAdditionalRecipients(prev =>
        uniqBy([...prev, ...newEmailOptions], 'value')
      );

      const newSelectedIds = newEmailOptions.map((nm: AdditionalRecipient) =>
        nm.id.toString()
      );
      const mergedSelectedIds = uniqBy(
        [...(selectedRecipientIds || []), ...newSelectedIds],
        id => id.toString()
      );

      onChangeRecipient(mergedSelectedIds);
    } catch (error) {
      errorToast({ title: `There was an error importing your emails.` });
    } finally {
      setIsImporting(false);
    }
    return true;
  };

  useEffect(() => {
    const recipientOptions = recipients.map(r => {
      const groupRecipients = r?.multipleRecipients || [];
      const multipleRecipientsPhone = groupRecipients.filter(
        groupRec => !!groupRec.phone
      );
      const multipleRecipientsEmail = groupRecipients.filter(
        groupRec => !!groupRec.email
      );
      return {
        value: r.id,
        label:
          r.type !== MultipleRecipientType.GROUP
            ? r.email || ''
            : `${r.name}` || '',
        type: r.type,
        contactsCount: groupRecipients.length,
        phone:
          r.type !== MultipleRecipientType.GROUP
            ? r.phone || ''
            : r.multipleRecipients
              ? `${multipleRecipientsPhone.length} SMS contact${
                  multipleRecipientsPhone.length !== 1 ? 's' : ''
                }`
              : '',
        name:
          r.type !== MultipleRecipientType.GROUP
            ? r.name || ''
            : r.multipleRecipients
              ? `${multipleRecipientsEmail.length} email contact${
                  multipleRecipientsEmail.length !== 1 ? 's' : ''
                }`
              : '',
      };
    });

    const mappedNew = additionalRecipients.map((obj: AdditionalRecipient) => {
      return {
        value: obj.id,
        label: obj.label || '',
        type: obj.type,
        phone: '',
        name: obj.label || '',
      };
    });
    const combinedOptions = uniqBy(
      [...recipientOptions, ...mappedNew],
      'value'
    );

    if (createNewOption) {
      combinedOptions.unshift(createNew);
    } else {
      combinedOptions.filter(option => option.value !== '0');
    }
    setOptions(combinedOptions);
  }, [recipients, createNewOption, additionalRecipients]);

  const debouncedChangeHandler = useCallback(
    debounce((value: string) => {
      onInputChange(value);
    }, 1500),
    []
  );

  const handlePasteEmails = async (event: React.ClipboardEvent) => {
    const pastedText = event.clipboardData.getData('text');
    if (pastedText.includes(',') || pastedText.includes(';')) {
      event.preventDefault();
      await handleBulkPaste(pastedText);
      setInternalInputValue('');
    }
  };

  return (
    <Card
      data-cy={selectors.libraryPage.sendAndShareModal.recipientSearch}
      onPaste={handlePasteEmails}
    >
      <Gap width='100%' justifyContent='space-between' alignItems='center'>
        <CardTitle>{title}</CardTitle>
        <EmailCount emailLimit={emailLimit} />
      </Gap>

      {isImporting && (
        <div style={{ marginBottom: '8px', color: theme.palette.blue80 }}>
          Importing contacts...
        </div>
      )}

      <Select
        ref={r => setSelectRef(r)}
        isMulti={multiple}
        components={{
          Option,
          SingleValue,
          MultiValue,
          MultiValueRemove,
        }}
        styles={selectStyle}
        options={options}
        value={options.filter(o => selectedRecipientIds?.includes(o.value))}
        onChange={handleRecipientChange}
        isSearchable={true}
        placeholder={
          createNewOption
            ? 'Search contacts or enter a new one...'
            : 'Search customers...'
        }
        filterOption={filterOptions}
        formatOptionLabel={formatOptionLabel}
        onInputChange={inputValue => {
          setInternalInputValue(inputValue);
          debouncedChangeHandler(inputValue);
          return inputValue;
        }}
        isDisabled={selectedRecipientIds?.includes('0')}
        inputValue={internalInputValue}
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
      {showExpandedContent && selectedRecipientIds && (
        <ExpandedContentWrapper>{expandedContent}</ExpandedContentWrapper>
      )}
    </Card>
  );
};
