import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Field, FieldProps, useFormikContext } from 'formik';
import { theme } from 'lib/style';
import Select from 'react-select';
import { useAuth } from 'lib/context';
import { AutomotiveAttributesFields } from '.';
import { checkIfVideoRequestFieldsRequired } from 'lib/utils/automotiveRolePermissionChecks';
import { VideoAttribute } from 'lib/api';
import { ListContainer } from 'lib/components/styles/layout';

const Content = styled.div`
  width: 100px;
  ${theme.mediaQueryMinWidth.xs} {
    width: 248px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    width: 380px;
  }
  .error {
    border-color: #e84c3d;
  }
  .disabled {
    background-color: #eeeff2;
    cursor: not-allowed;
  }

  .disabled > div {
    background-color: #eeeff2;
    cursor: not-allowed;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
`;

const VideoAttributeField = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextInput = styled.input`
  padding-left: 8px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-right: 8px;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

const Textarea = styled.textarea`
  padding-left: 8px;
  width: 242px;
  min-width: 242px;
  max-width: 242px;
  height: 100px;
  min-height: 100px;
  max-height: 300px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  &:focus {
    outline: 0;
  }
  ${theme.mediaQueryMinWidth.lg} {
    width: 368px;
    min-width: 368px;
    max-width: 368px;
  }
`;

const SelectInput = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  &:focus {
    outline: 0;
  }
`;

const CountLabel = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: ${({ theme }) => theme.colors.primary[100]};
  float: right;
`;
type ComponentProps = {
  attributes: VideoAttribute[];
  onUpdate: (arg: object) => void;
  initValues: any;
  onChangeVideoRequestData?: (data: any) => void;
  videoRequestData?: any; // TO-DO: replace in separate task
};

export const AttributeFields = ({
  attributes,
  onUpdate,
  initValues,
  onChangeVideoRequestData = () => {},
  videoRequestData,
}: ComponentProps) => {
  const [totalRequiredAttributes, setTotalRequiredAttributes] =
    React.useState(0);
  const { userData } = useAuth();
  const showVideoRequestFields = userData.isAutomotive;
  const videoRequestFieldsRequired =
    checkIfVideoRequestFieldsRequired(userData);
  const themes = useTheme();

  React.useEffect(() => {
    const count = getRequiredAttributesCount();
    setTotalRequiredAttributes(count);
  }, [attributes]);

  const { errors, validateForm, values }: any = useFormikContext();

  React.useEffect(() => {
    validateForm();
  }, []);

  React.useEffect(() => {
    onUpdate(values);
  }, [values]);

  const getRequiredAttributesCount = () => {
    let count = 0;
    attributes.forEach(attribute => {
      if (attribute && attribute.isRequired) {
        ++count;
      }
    });

    if (userData.isAutomotiveSalesRole && videoRequestFieldsRequired)
      count += 1;
    if (userData.isAutomotiveServiceRole && videoRequestFieldsRequired)
      count += 2;

    return count;
  };

  if (!showVideoRequestFields && !attributes.length) {
    return null;
  }

  return (
    <Content>
      <Header>
        Video Attributes{' '}
        <CountLabel>{totalRequiredAttributes} required</CountLabel>
      </Header>
      {showVideoRequestFields && (
        <AutomotiveAttributesFields
          onChangeVideoRequestData={onChangeVideoRequestData}
          videoRequest={videoRequestData}
          fetchSoldVehiclesForAutomotive={false}
        />
      )}
      <ListContainer height='900px' width='100%'>
        {attributes.map((attribute: VideoAttribute, index) => {
          const attributeIndex = `attributes${attribute.index}-${attribute.attributeId}`;

          const isDisabled =
            (!!attribute.isReadOnly || !!attribute.isLocked) &&
            !!initValues[attributeIndex];
          const hasError = !!errors[attributeIndex];
          const isRequired = !!attribute.isRequired;
          let className = '';
          if (isDisabled) {
            className = 'disabled';
          }

          if (hasError) {
            className += ' error';
          }
          return (
            <VideoAttributeField key={index}>
              <Label title={attribute.title}>
                {attribute.title}
                {isRequired && (
                  <span style={{ color: themes.colors.primary[100] }}>*</span>
                )}
              </Label>
              {attribute.type === 'text' && (
                <>
                  <Field
                    name={attributeIndex}
                    disabled={isDisabled}
                    as={TextInput}
                    type='text'
                    className={className}
                    onFocus={() => {
                      validateForm();
                    }}
                  />
                  {hasError ? (
                    <div style={{ color: '#E84C3D', marginTop: '3px' }}>
                      {errors[attributeIndex]}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {attribute.type === 'textarea' && (
                <>
                  <Field
                    disabled={isDisabled}
                    name={attributeIndex}
                    as={Textarea}
                    className={className}
                    type='text'
                  />
                  {hasError ? (
                    <div style={{ color: '#E84C3D', marginTop: '3px' }}>
                      {errors[attributeIndex]}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {attribute.type === 'dropdown' && (
                <Field
                  name={attributeIndex}
                  disabled={isDisabled}
                  className={className}
                >
                  {({ field, form }: FieldProps) => {
                    const { value } = field;
                    const options = attribute.dropdownValues.map(
                      (dropdown: any) => ({
                        value: dropdown.value,
                        label: dropdown.value,
                      })
                    );
                    return (
                      <SelectInput
                        menuPortalTarget={document.body}
                        disabled={isDisabled}
                        maxMenuHeight={100}
                        className={className}
                        styles={{
                          control: (base: any) => ({
                            ...base,
                            height: '40px',
                            border: '1px solid #d0d3d9',
                            boxShadow: 'none',
                            '&:hover': {
                              border: '1px solid #d0d3d9',
                            },
                          }),
                          options: () => ({
                            disabled: { isDisabled },
                          }),
                          indicatorSeparator: () => ({ display: 'none' }),
                        }}
                        options={options}
                        value={options.find((o: any) => o.value == value)}
                        onChange={(option: any) => {
                          if (
                            (attribute.isReadOnly || attribute.isLocked) &&
                            initValues[attributeIndex]
                          ) {
                            form.setFieldValue(
                              attributeIndex,
                              initValues[attributeIndex]
                            );
                          } else {
                            form.setFieldValue(attributeIndex, option.value);
                          }
                        }}
                      />
                    );
                  }}
                </Field>
              )}
            </VideoAttributeField>
          );
        })}
      </ListContainer>
    </Content>
  );
};
