import { importSuperAdminCustomers } from 'lib/api';
import {
  CheckboxInput,
  CloseButton,
  LoadingIndicator,
  Modal,
  Table,
} from 'lib/components';
import DragAndDrop from 'lib/components/inputs/DragAndDrop';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { theme } from 'lib/style';
import { validateInputForCsvFormulaInjection } from 'lib/utils/functions';
import { EMAIL_REGEX } from 'lib/utils/regexes';
import get from 'lodash/get';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  ButtonContainer,
  Content,
  ContentBody,
  ContentHeader,
  ContentHeaderWrap,
  Description,
  ErrorMessage,
  Form,
  HeaderContainer,
  Label,
  ListContainer,
  ListItem,
  ModalItem,
  Row,
  SelectInput,
  TableCell,
  TableCellHeader,
  Text,
  UserExistLabel,
  UserExistWrapper,
} from './Components';
import {
  CUSTOMER_HEADER_REG_VALUES,
  CUSTOMER_REQUIRED_FIELDS,
  ColumnValues as ColumnFields,
  CustomerFieldOptions,
  FieldLength,
  NONE,
  VersionAccess,
  Views,
  initialCSVData,
  initialColOptions,
  initialColSampleData,
  initialCustomerColMapping,
  initialDuplicatedRows,
  initialInvalidRows,
  parseOptions,
} from './Constants';

import { FailedCustomerType, Props, colOptionProps } from './Types';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';
import { MdWarning } from 'react-icons/md';

const SAMPLE_LENGTH = 1;

export const ImportCustomerModal = ({
  handleModalClose,
  handleSubmit,
}: Props) => {
  const [currentView, setCurrentView] = useState<Views>(Views.IMPORT);
  const [CSVData, setCSVData] = useState(initialCSVData);
  const [colOptions, setColOptions] = useState(initialColOptions);
  const [colMapping, setColMapping] = useState(initialCustomerColMapping);
  const [colSamples, setColSamples] = useState(initialColSampleData);
  const [uploadError, setUploadError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string[]>([]);
  const [nonImportedCustomer, setNonImportedCustomer] = useState<{
    discardedCustomers: FailedCustomerType[];
    message: string;
  }>({
    discardedCustomers: [],
    message: '',
  });
  const { showError } = useToastError();

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [invalidRows, setInvalidRows] = useState(initialInvalidRows);
  const [invalidCount, setInvalidCount] = useState(0);
  const [duplicatedRows, setDuplicatedRows] = useState(initialDuplicatedRows);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const values = Object.keys(colMapping).reduce(
      (obj: { [key: string]: string[] }, field: string) => {
        CSVData.data.forEach((d: string[]) => {
          if (!obj[field]) {
            obj[field] = [];
          }
          obj[field].push(getColValue(d, field));
        });
        return obj;
      },
      {}
    );
    const invalid: { [key: string]: boolean } = {};
    const duplicated: { [key: string]: string[] } = {};
    Object.keys(values).forEach(field => {
      const data = values[field];
      for (let i in data) {
        invalid[i] =
          invalid[i] ||
          !validate(data[i], field) ||
          data[i].length > FieldLength[field];
        if (field === ColumnFields.USERNAME) {
          const username = data[i];
          if (!duplicated[username]) {
            duplicated[username] = [];
          }
          if (!invalid[i]) {
            const index = Number(i) + 2;
            duplicated[username].push(index.toString());
          }
          if (duplicated[username].length > 1) {
            invalid[i] = true;
          }
        }
      }
    });
    setInvalidRows(invalid);
    setDuplicatedRows(duplicated);
    setInvalidCount(Object.values(invalid).filter(Boolean).length);
  }, [colMapping, CSVData.data]);

  const initColOptions = (row: string[]) => {
    const options = row.map((_: string, i: number) => ({
      value: `${i}`,
      label: `Col ${i + 1}`,
    }));

    options.unshift({ value: NONE, label: 'Choose...' });
    setColOptions(options);
  };

  const initCSVHeaders = (row: string[]) => {
    const options = [];
    let found = false;
    let count = 0;
    for (let i in row) {
      if (!row[i]) {
        break;
      }
      let value = row[i].toString();
      options.push({ label: value, value: `${i}` });
      for (let header of CUSTOMER_HEADER_REG_VALUES) {
        if (header.reg != null && value.match(header.reg)) {
          found = true;
          count++;
          setColMapping(value => ({ ...value, [header.field]: `${i}` }));
        }
      }
    }
    options.unshift({ value: NONE, label: 'Choose...' });
    found ? setColOptions(options) : setColMapping(initialCustomerColMapping);
    return { found, count };
  };

  const initSampleData = (data: string[][]) => {
    let sample = { ...colSamples };
    let sampleCollected;
    let i = 0;
    while (!sampleCollected && i < data.length) {
      let row = data[i];
      row.forEach((field, index) => {
        if (!sample[index]) {
          sample[index] = [];
        }
        sample[index].push(field);
      });
      let values = Object.keys(sample).map(k => sample[k]);
      sampleCollected = values.reduce(
        (collected, fieldSample) =>
          collected && fieldSample.length >= SAMPLE_LENGTH,
        false
      );
      i++;
    }
    setColSamples(sample);
  };

  const onColChange = (field: string, value: string) => {
    const newField =
      Object.keys(colMapping).find(key => colMapping[key] === value) || NONE;
    if (field === NONE) {
      setColMapping({ ...colMapping, [newField]: NONE });
    } else {
      setColMapping({ ...colMapping, [newField]: NONE, [field]: value });
    }
  };

  const onUpload = (csv: string[][], fileInfo: { name: string }) => {
    const data = [...csv];
    setUploadError(false);
    if (!fileInfo.name.includes('csv')) {
      setUploadError(true);
      return;
    }
    const firstRow = data[0];
    const { found } = initCSVHeaders(firstRow);
    if (found) {
      data.shift();
    } else {
      initColOptions(firstRow);
    }
    initSampleData(data);
    setCSVData({ data, uploaded: true });
    setCurrentView(Views.MAPPING);
  };

  const getSampleData = (index: string) => {
    if (!colSamples[index]) {
      return '';
    }
    return colSamples[index].filter(Boolean).slice(0, SAMPLE_LENGTH);
  };

  const getNumberValue = (value: string) => {
    return !!value && !!value.length ? parseInt(value, 10) : undefined;
  };

  const getVersionValue = (value: string, version: VersionAccess) => {
    if (!value) {
      return version === VersionAccess.CDS ? 1 : 0;
    }

    const valueArray = value.toLowerCase().split(',');
    const containsCDSVersion =
      valueArray.includes('v4') || valueArray.includes('cds');
    if (version !== VersionAccess.CDS && containsCDSVersion) {
      return 0;
    }

    if (containsCDSVersion) {
      return 1;
    }

    if (version === VersionAccess.TWO && valueArray.includes('v2')) {
      return 1;
    }

    if (version === VersionAccess.THREE && valueArray.includes('v3')) {
      return 1;
    }

    return 0;
  };

  const getColValue = (data: string[], field: string) => {
    const columnValue = get(colMapping, field, '');
    if (!columnValue || columnValue === NONE) {
      return '';
    }
    return data[parseInt(columnValue)]?.toString().trim() || '';
  };

  const initializeListView = () => {
    setSelectedCustomer(
      CSVData.data
        .filter((e, i: number) => !invalidRows[i])
        .map(e => getColValue(e, ColumnFields.USERNAME))
    );
    setCurrentView(Views.MAPPING_IMPORT);
  };

  const onSubmit = async () => {
    try {
      const customers = CSVData.data
        .filter(
          (data, i) =>
            !invalidRows[i] &&
            selectedCustomer.includes(getColValue(data, ColumnFields.USERNAME))
        )
        .map(data => {
          return {
            resellerId:
              getNumberValue(getColValue(data, ColumnFields.RESELLER)) || 1,
            firstName: getColValue(data, ColumnFields.FIRST_NAME),
            lastName: getColValue(data, ColumnFields.LAST_NAME),
            email: getColValue(data, ColumnFields.EMAIL),
            phone1: getColValue(data, ColumnFields.PHONE1),
            username: getColValue(data, ColumnFields.USERNAME),
            password: getColValue(data, ColumnFields.PASSWORD),
            business: getColValue(data, ColumnFields.BUSINESS) || undefined,
            url: getColValue(data, ColumnFields.URL) || undefined,
            salesforceAccountId: getColValue(
              data,
              ColumnFields.SALESFORCE_ACCOUNT_ID
            ),
            covideoPackageId:
              getNumberValue(getColValue(data, ColumnFields.PACKAGE)) ||
              PackageName.LEGACY,
            referralAgent: getNumberValue(
              getColValue(data, ColumnFields.REFERRAL_AGENT)
            ),
            accountManager: getNumberValue(
              getColValue(data, ColumnFields.ACCOUNT_MANAGER)
            ),
            crmProviderId: getNumberValue(
              getColValue(data, ColumnFields.CRM_PROVIDER)
            ),
            maxUsers:
              getNumberValue(getColValue(data, ColumnFields.MAX_USERS)) || 1,
            versionTwoEnabled: getVersionValue(
              getColValue(data, ColumnFields.VERSION_ACCESS),
              VersionAccess.TWO
            ),
            versionThreeEnabled: getVersionValue(
              getColValue(data, ColumnFields.VERSION_ACCESS),
              VersionAccess.THREE
            ),
            versionCDSEnabled: getVersionValue(
              getColValue(data, ColumnFields.VERSION_ACCESS),
              VersionAccess.CDS
            ),
            verified: 1,
            lesaAccess: 0,
          };
        });
      const response = await importSuperAdminCustomers({ customers });
      const importedCustomerIds = response?.data?.customerIds;
      if (importedCustomerIds?.length) {
        handleSubmit(importedCustomerIds);
      }
      if (
        response.data &&
        response.data.discardedCustomers &&
        response.data.discardedCustomers.length
      ) {
        setNonImportedCustomer({
          discardedCustomers: response.data.discardedCustomers,
          message: response.message,
        });
        setCurrentView(Views.NON_IMPORTED_ENTITIES);
        return;
      }
      successToast({ title: response.message });
      handleModalClose(true);
    } catch (error) {
      showError(error);
    }
  };

  const validateEmail = (email: string = '') => {
    let emailError = '';
    if (!email) {
      emailError = 'Please enter email address';
    } else if (!EMAIL_REGEX.test(email.toLowerCase())) {
      emailError = 'Please enter valid email address';
    } else if (!validateInputForCsvFormulaInjection(email)) {
      emailError = 'Please enter valid email';
    }
    setErrors({ ...errors, email: emailError });
    return emailError;
  };

  const validateFirstName = (firstName: string | undefined) => {
    let firstNameError = '';
    if (!firstName) {
      firstNameError = 'Please enter valid first name';
    } else if (!validateInputForCsvFormulaInjection(firstName)) {
      firstNameError = 'Please enter valid first name';
    }
    setErrors({ ...errors, firstName: firstNameError });
    return firstNameError;
  };

  const validateLastName = (lastName: string | undefined) => {
    let lastNameError = '';
    if (!lastName) {
      lastNameError = 'Please enter valid last name';
    } else if (!validateInputForCsvFormulaInjection(lastName)) {
      lastNameError = 'Please enter valid last name';
    }
    setErrors({ ...errors, lastName: lastNameError });
    return lastNameError;
  };

  const validationMapping: { [key: string]: Function } = {
    email: validateEmail,
    firstName: validateFirstName,
    lastName: validateLastName,
  };

  const validate = (value: string, field: string) => {
    if (!validationMapping[field]) {
      return true;
    }
    return !validationMapping[field](value);
  };

  const AddBulkCustomer = (
    <ModalItem>
      <Content>
        <ContentHeaderWrap>
          <ContentHeader>Import customers</ContentHeader>
          <CloseButton onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          Import customers
          <HeaderContainer>
            <div>First name</div>
            <div>Last name</div>
            <div>Email</div>
            {/* <div>Phone</div> */}
            <div>Username</div>
            <div>Password</div>
          </HeaderContainer>
          <HeaderContainer>
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
            {/* <div className="bottom--none">
              <div className="span--holder"></div>
            </div> */}
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
            <div className='bottom--none'>
              <div className='span--holder'></div>
            </div>
          </HeaderContainer>
          <Form
            onSubmit={e => {
              e.stopPropagation();
            }}
          >
            <>
              <DragAndDrop
                showIcon={false}
                onFileLoaded={onUpload}
                parserOptions={parseOptions}
                button={true}
                width={590}
                isMultiple={false}
              />

              {uploadError && (
                <ErrorMessage>
                  <span>
                    <IoMdClose
                      size={32}
                      color={theme.palette.primaryRedDanger}
                    />
                    Uploaded file is not in .csv format. Try again!
                  </span>
                </ErrorMessage>
              )}
            </>
          </Form>
        </ContentBody>
      </Content>
    </ModalItem>
  );

  const printDuplicatedRows = React.useCallback(() => {
    const result = [];
    for (let username in duplicatedRows) {
      if (duplicatedRows[username].length > 1) {
        result.push(
          <Label key={`error-key-${username}`}>
            <Text>
              {' '}
              {username} has multiple entries( line{' '}
              {duplicatedRows[username].join(', ')})
            </Text>
          </Label>
        );
      }
    }
    return result;
  }, [duplicatedRows]);

  const MapImported = (
    <ModalItem>
      <Content style={{ width: 944 }}>
        <ContentHeaderWrap>
          <ContentHeader>Import Customers</ContentHeader>
          <CloseButton onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <div style={{ marginBottom: 42 }}>
            Connect columns from .csv file to customer fields
          </div>
          <Table
            compact={true}
            headers={[
              <TableCellHeader>Column Label (csv)</TableCellHeader>,
              <TableCellHeader>Example</TableCellHeader>,
              <TableCellHeader>Customer Field</TableCellHeader>,
            ]}
            hoverable={false}
            rows={colOptions.slice(1).map((x, index: number) => ({
              key: index,
              columns: [
                <TableCell>{x.label}</TableCell>,
                <TableCell>{getSampleData(index.toString())}</TableCell>,
                <TableCell>
                  <SelectInput
                    menuPlacement='auto'
                    menuPosition='fixed'
                    placeholder='Choose...'
                    styles={{
                      control: (base: React.CSSProperties) => ({
                        ...base,
                        height: '40px',
                      }),
                      indicatorSeparator: () => ({ display: NONE }),
                    }}
                    options={CustomerFieldOptions}
                    value={CustomerFieldOptions.find(
                      option =>
                        option.value ==
                        (Object.keys(colMapping).find(
                          key => colMapping[key] === index.toString()
                        ) || NONE)
                    )}
                    onChange={(option: colOptionProps) =>
                      onColChange(option.value, index.toString())
                    }
                  />
                </TableCell>,
              ],
              onClick: () => {},
            }))}
          />
          {invalidCount > 0 && invalidCount < CSVData.data.length && (
            <>
              <Row>
                <ErrorMessage>
                  <span>
                    <MdWarning
                      color={theme.palette.primaryRedDanger}
                      size={32}
                    />
                    {invalidCount}/{CSVData.data.length} rows contain invalid
                    data. Those rows will be ignored.
                  </span>
                </ErrorMessage>
              </Row>
              <div style={{ maxHeight: 84, overflowY: 'auto' }}>
                {printDuplicatedRows()}
              </div>
            </>
          )}
          {invalidCount == CSVData.data.length && (
            <>
              <Row>
                <ErrorMessage style={{ minHeight: '48px', height: 'auto' }}>
                  <span
                    style={{
                      margin: '10px',
                      alignItems: 'center',
                      lineHeight: 'normal',
                    }}
                  >
                    <MdWarning
                      color={theme.palette.primaryRedDanger}
                      size={32}
                      style={{ marginTop: '0px' }}
                    />
                    {colMapping.username !== NONE
                      ? 'Please check username column label in .csv file and ensure username column mapping with username column field'
                      : 'The Username field is required for importing customer into Covideo. Please check your file to ensure you have created and labeled a column for Username.'}{' '}
                  </span>
                </ErrorMessage>
              </Row>
              <div style={{ maxHeight: 84, overflowY: 'auto' }}>
                {printDuplicatedRows()}
              </div>
            </>
          )}
          {CUSTOMER_REQUIRED_FIELDS.some(e => colMapping[e] == NONE) && (
            <>
              <Row>
                <ErrorMessage style={{ minHeight: '48px', height: 'auto' }}>
                  <span
                    style={{
                      margin: '10px',
                      alignItems: 'center',
                      lineHeight: 'normal',
                    }}
                  >
                    <MdWarning
                      color={theme.palette.primaryRedDanger}
                      size={32}
                      style={{ marginTop: '0px' }}
                    />
                    {CUSTOMER_REQUIRED_FIELDS.filter(
                      e => colMapping[e] == NONE
                    ).join(', ') +
                      ' field is required for importing customers into Covideo. Please check your file to ensure you have created and labeled a column for ' +
                      CUSTOMER_REQUIRED_FIELDS.filter(
                        e => colMapping[e] == NONE
                      ).join(', ')}{' '}
                  </span>
                </ErrorMessage>
              </Row>
            </>
          )}
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          disabled={
            CSVData.data.length - invalidCount == 0 ||
            CUSTOMER_REQUIRED_FIELDS.some(e => colMapping[e] == NONE)
          }
          text={'Continue'}
          onClick={() => {
            initializeListView();
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const ImportCustomer = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader>Choose customers to import</ContentHeader>
          <CloseButton onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <ListContainer>
            <ListItem key={`customer-item-global`} bgColor={'#FFFFF'}>
              <div className='item--label'>
                <CheckboxInput
                  blueCheck={true}
                  checkGroupIndicator={false}
                  checked={
                    selectedCustomer.length ==
                    CSVData.data.length - invalidCount
                  }
                  onChange={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    let { checked } = event.target as HTMLInputElement;
                    if (checked) {
                      setSelectedCustomer(
                        CSVData.data
                          .filter((e, i: number) => !invalidRows[i])
                          .map((e: string[]) =>
                            getColValue(e, ColumnFields.USERNAME)
                          )
                      );
                    } else setSelectedCustomer([]);
                  }}
                />
                <label>All Customers</label>
              </div>
            </ListItem>
            {CSVData.data.map((e: string[], i: number) => {
              if (invalidRows[i]) return null;
              return (
                <ListItem key={`customer-item-${i}`}>
                  <div className='item--label'>
                    <CheckboxInput
                      blueCheck={true}
                      checkGroupIndicator={false}
                      checked={selectedCustomer.includes(
                        getColValue(e, ColumnFields.USERNAME)
                      )}
                      onChange={(event: React.SyntheticEvent) => {
                        event.stopPropagation();
                        let { checked } = event.target as HTMLInputElement;
                        if (checked)
                          setSelectedCustomer([
                            ...selectedCustomer,
                            getColValue(e, ColumnFields.USERNAME),
                          ]);
                        else
                          setSelectedCustomer(
                            selectedCustomer.filter(
                              el => el != getColValue(e, ColumnFields.USERNAME)
                            )
                          );
                      }}
                    />
                    <label>{getColValue(e, ColumnFields.FIRST_NAME)}</label>
                  </div>
                  <label>{getColValue(e, ColumnFields.EMAIL)}</label>
                </ListItem>
              );
            })}
          </ListContainer>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          disabled={!selectedCustomer.length}
          text={`Import ${selectedCustomer.length} customers`}
          onClick={() => {
            setCurrentView(Views.LOADING);
            onSubmit();
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const NonImportedCustomers = (
    <ModalItem>
      <Content style={{ width: 944 }}>
        <ContentHeaderWrap>
          <ContentHeader>Import Complete</ContentHeader>
          <CloseButton onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <Description
            style={{ justifyContent: 'start', background: 'transparent' }}
          >
            {nonImportedCustomer.message}
          </Description>
          <Description
            style={{
              justifyContent: 'start',
              background: 'transparent',
              paddingLeft: '17px',
            }}
          >
            List of skipped (not imported) customers
          </Description>
          <ListContainer>
            {nonImportedCustomer.discardedCustomers.map(
              (customer: FailedCustomerType, i: number) => {
                if (invalidRows[i]) return null;
                return (
                  <ListItem key={`customer-item-${i}`}>
                    <div className='item--label'>
                      <label>{`${customer.firstName} ${customer.lastName}`}</label>
                    </div>
                    <UserExistWrapper>
                      <UserExistLabel>{customer.failReason}</UserExistLabel>
                      <div style={{ color: '#9297A2' }}>{customer.email}</div>
                    </UserExistWrapper>
                  </ListItem>
                );
              }
            )}
          </ListContainer>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          text='Finish'
          onClick={() => {
            handleModalClose(true);
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const LoadingView = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader></ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          <LoadingIndicator isLoading={true} text='Importing customer...' />
        </ContentBody>
      </Content>
    </ModalItem>
  );

  let CurrentVievComponent = null;
  switch (currentView) {
    case Views.IMPORT:
      CurrentVievComponent = AddBulkCustomer;
      break;
    case Views.MAPPING:
      CurrentVievComponent = MapImported;
      break;
    case Views.MAPPING_IMPORT:
      CurrentVievComponent = ImportCustomer;
      break;
    case Views.LOADING:
      CurrentVievComponent = LoadingView;
      break;
    case Views.NON_IMPORTED_ENTITIES:
      CurrentVievComponent = NonImportedCustomers;
      break;
  }
  return <Modal>{CurrentVievComponent}</Modal>;
};
