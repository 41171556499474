import React from 'react';
import styled from 'styled-components/macro';
import searchGuideEmpty from '../assets/images/searchGuideEmpty.png';

const ThumbnailWrapper = styled.div`
  max-width: calc(25% - 10px);
  width: calc(25% - 10px);

  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;

  img {
    height: 201px;

    @media (max-width: 1639px) {
      height: 155px;
    }

    @media (max-width: 1280px) {
      height: 145px;
    }
  }
`;

const SearchMessage = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  color: #4e5461;
`;
const AdditionalMessage = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  color: #4e5461;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  flex-direction: column;
  gap: 6px;
`;

const GuidesCategoryEmptyWrapper = styled.div`
  display: flex;
  gap: 13px;
  flex-wrap: wrap;
  position: relative;
`;

export const CategorizedEmpty = () => {
  const emptyArray = [...Array(4)];
  return (
    <GuidesCategoryEmptyWrapper>
      <InfoWrapper>
        <SearchMessage>
          {'This category is empty. Add or upload training video.'}
        </SearchMessage>
        <AdditionalMessage>
          Empty categories will not be shown to users
        </AdditionalMessage>
      </InfoWrapper>
      {emptyArray.map((_, index) => (
        <ThumbnailWrapper key={index}>
          <img src={searchGuideEmpty} alt='searchGuideEmpty' />
        </ThumbnailWrapper>
      ))}
    </GuidesCategoryEmptyWrapper>
  );
};
