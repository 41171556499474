export const lesaKeys = {
  all: (params: {
    page: number;
    search: string;
    sortKey: string;
    order: string;
    filter?: { [key: string]: string[] };
  }) => ['LESA_LIST', params],
  filters: () => ['LESA_LIST_FILTERS'],
};
