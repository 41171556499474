import * as React from 'react';

import { useGetUserQuery } from 'lib/api/users/getUser';
import {
  useGetWhiteLabelQuery,
  IWhiteLabel,
} from 'lib/api/whitelabel/getWhiteLabel';
import { LoadingIndicator } from 'lib/components';
import jwtDecode from 'jwt-decode';
import { AccessRole } from 'lib/const';
import { toString } from 'lib/utils/functions';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { usersKeys } from 'lib/api/users/queryKeys';
import { notificationKeys } from 'lib/api/notifications/queryKeys';
import { getSuperAdminRole } from 'lib/utils/superAdminRole';
import { SuperAdminRole } from 'lib/const/SuperAdminRole';
import {
  ILocation,
  useGetLocationsQuery,
} from 'lib/api/locations/getUserLocations';
import { InteractiveCTA } from 'lib/api';
import { isRegionsReseller } from 'lib/utils/productFeature';
import { getAutomotiveFlags } from 'lib/utils/automotiveRolePermissionChecks';
import { useGetOrganizationAccessQuery } from 'lib/api/organizations/getOrganizationAccess';
import { UserOrganizationAccess } from '../types';
import { IMSFields } from 'app/pages/admin/ims/types';
import { getCurrentToken } from 'configs/api/token';
import { getThemeByWhiteLabel } from '../theme/utils';
import * as Sentry from '@sentry/browser';
import { useToastError } from 'lib/hooks';
import { updateAxiosInstaceToken } from 'configs/api/helpers';

enum VideoQualityType {
  'FHD' = '1080',
  '4K' = '4096',
}

enum VideoEditingType {
  'Basic' = 'Basic',
  'Advanced' = 'Advanced',
}

// DOUBLE CHECK
export interface IPackageProduct {
  id: number;
  name: string;
  value?: string;
}

export interface IPackageDetails {
  id: number;
  stripePackageId: string;
  products: any;
  maxVideosCreated: number;
  monthlyVideos: number;
  maxLength: number;
  maxQuality: VideoQualityType;
  editingType: VideoEditingType;
  title: string;
}

export interface IUser {
  packageDetails: IPackageDetails;
  features: any[];
  customerId: string;
  intercom: IIntercom;
}

//INTERCOM
export interface IIntercom {
  app_id: string;
  name: string;
  email: string;
  user_id: string;
  user_hash: string;
  cv_user_id: string;
  num_videos: string;
  free_trial: boolean;
  signup_date: string;
  purchase_date: string;
  reseller_id: string;
  reseller_name: string;
  auto: boolean;
  admin: boolean;
  wheelsTV: string;
  user_active: boolean;
  last_active: string;
  v1: boolean;
  v2: boolean;
  v3: boolean;
  covideo_3: boolean;
  covideo_4: boolean;
  covideo_4_service: boolean;
  covideo_4_sales: boolean;
}
export interface IWorkingHours {
  sunday: IWorkingDay | null;
  monday: IWorkingDay | null;
  tuesday: IWorkingDay | null;
  wednesday: IWorkingDay | null;
  thursday: IWorkingDay | null;
  friday: IWorkingDay | null;
  saturday: IWorkingDay | null;
}
export interface IWorkingDay {
  from: IWorkingDayOptions;
  to: IWorkingDayOptions;
}

export interface IWorkingDayOptions {
  hours: string;
  meridiem: string;
}
export interface IReseller {
  resellerId: string;
  resellerName: string;
  url: string;
  email: string;
  phoneNumber: string;
  pls: string;
  customerId: string;
  playbackUrl: string;
  integration: string;
  fullReportAccess: string;
  easyCare: string;
  dealer: string;
  resellerReportingEnabled: string;
  restrictFolderSharingToAdmins: string;
}
export enum IQuoteSettings {
  DOLLAR = 'dollar',
  ITEM = 'item',
}

//CUSTOMER
export type Customer = IMSFields & {
  preventUserEditAccess: string;
  hasReadOnlyVideoAttributeConstraint: string;
  hasLockableVideoAttribute: string;
  hasVideoAttributeDepartmentLimit: string;
  automotiveAccess: string;
  webShare: string;
  packageId: string;
  newPackageId: string;
  customerId: string;
  parentCustomerId: string;
  locationId?: any;
  resellerId: string;
  stripeId: string;
  codirectId?: any;
  infusionsoftContactId: string;
  infusionsoftCcid: string;
  infusionsoftSequence: string;
  contractId: string;
  business: string;
  customerLogo: string;
  dealerGroup: string;
  crmId: string;
  crmProviderId: string;
  defaultTemplate: string;
  firstName: string;
  quoteSettings: string;
  lastName: string;
  title: string;
  legalFirstName: string;
  legalLastName: string;
  legalTitle: string;
  eLeadEnabled: string;
  streetAddress: string;
  city: string;
  state: string;
  cardOther: string;
  zip: string;
  country: string;
  phone1: string;
  phone2: string;
  fax1: string;
  email: string;
  url: string;
  markVideosAsSent: number | null;
  preloadTeleprompterScripts: number;
  connSpeed: string;
  referredBy: string;
  referralAgent: string;
  coagent: string;
  accountManager: string;
  signupDate: string;
  endDate?: any;
  trialEnd: string;
  maxUsers: string;
  totalSecondsAllowed: string;
  totalSecondsUsed: string;
  startPage: string;
  videoEmail: string;
  virtualRep: string;
  videoConference: string;
  videoOutlook: string;
  videoPartsSearch: string;
  videoBlast: string;
  videoOverlay: string;
  bandwidthAllowed: string;
  mailServer: string;
  crmReportEmail: string;
  videoRepEmail: string;
  active: string;
  emailIcon: string;
  streetAddressShipping: string;
  cityShipping: string;
  stateShipping: string;
  zipShipping: string;
  agreementLength: string;
  setupFee: string;
  monthlyFee: string;
  startingDate: string;
  shippingNumber: string;
  industry: string;
  numOfTemplates: string;
  integration: string;
  ownerId: string;
  verified: string;
  freeTrial: string;
  events: string;
  banner: string;
  bgImage: string;
  bgSlice: string;
  bgColor: string;
  txtColor1: string;
  txtColor2: string;
  maxUsersBasic: string;
  maxUsersAdvanced: string;
  maxUsersVideoEmail_1000: string;
  maxUsersVideoEmail_5000: string;
  maxUsersAdvanced_1000: string;
  maxUsersAdvanced_5000: string;
  maxUsersLiveConference: string;
  notes: string;
  carfax: string;
  slipstream: string;
  partnerType: string;
  cimProfileId?: any;
  isEmailTemplate: string;
  triggerStartDate: string;
  allowReplies: string;
  displayInReports: string;
  customGraphics: string;
  socialLinksLocked: string;
  launched: string;
  launchDate?: any;
  transcriptions: string;
  transcriptionLicenses: string;
  smsEnabled: string;
  smsProfileId: string;
  ssoId: string;
  droplrEnabled: string;
  landingPageBuilder: string;
  droplrLicenses: string;
  droplrTeamHash: string;
  dealerSocketId?: any;
  dealerSocketFranchiseId?: any;
  videoDeletionAccess: string;
  defaultSMSUserId?: any;
  departmentTagEnabled: string;
  salesforceAccountId: string;
  credit700Url: string;
  dealerComId?: any;
  dominionDealerId?: any;
  groupId: string;
  reactionsPreference?: any;
  leadsIntegrationActive?: boolean | null;
  salesforceIntegration: string;
  wheelsTV: string;
  allowDelete: string;
  hasMultiLocation: string;
  hideInventoryItemPrice: string;
  planEndsAt?: any;
  isPlanCancelled: string;
  newMaxUsers: string;
  videoExpiration: string;
  regionId: number;
  organizationId: number;
  hasCovideoMagic?: boolean;
  keyMetricsEnabled: boolean;
  liveAccess?: number;
  iPacketDealerId?: string;
  jdPowerVDPEnabled?: number;
  lesaAccess: number;
  autominerEnabled: boolean;
  autominerGroupId: string;
  audiDealerCode: string;
  audiAreaCode?: string;
  audiSubAreaCode?: string;
  leadsNotificationsAccess: string;
  emailsSentLimit: number;
  webAppVersionAccess: {
    v1: boolean;
    v2: boolean;
    v3: boolean;
    v4: boolean;
  };
  apiDocumentationAccess?: string;
};

// ROOT USER
export interface RootUser {
  /**
   * @desc
   * Company admin is user who has access
   * values of "3" and "4"
   */
  isCompanyAdmin: boolean;
  isAutomotive: boolean;
  user: IUser;
  uid: string;
  userId: string;
  customerId: string;
  resellerId: string;
  bouncedEmail: string;
  firstName: string;
  admin: string;
  trial: boolean;
  access: string;
  verified: string;
  captions: boolean;
  vin: boolean;
  iat: number;
  exp: number;
  showWelcome: string;
  salesforceIntegration: boolean;
  wheelsTV: boolean;
  webEmailEnabled: boolean;
  allowDelete: boolean;
  webShare: boolean;
  intercom: IIntercom;
  id: string;
  parentUserId: string;
  parentCustomerId: string;
  username: string;
  lastName: string;
  webName1: string;
  webName2: string;
  conferenceName?: any;
  email: string;
  phone1: string;
  phone2: string;
  signupDate: string;
  purchaseDate: string;
  emailVerified: boolean;
  Dept: string;
  website: string;
  title: string;
  csAdmin: string;
  resellerAdmin?: any;
  videoCount: string;
  defaultVolume: string;
  addedBy: string;
  betaj: string;
  onVirtualRep: string;
  videoRepTimeout: string;
  videoRepDept: string;
  betaMember: string;
  videoConfUser: string;
  emailIcon: string;
  playInEmail: boolean;
  notifyMe: boolean;
  playButtonPosition: string;
  webmail: string;
  integration: string;
  totalSecondsAllowed: string;
  totalSecondsUsed: string;
  BSORFLN?: any;
  fusemail: boolean;
  distributor: string;
  packageId: string;
  trialAccount: boolean;
  affiliate: string;
  affiliateAccess: boolean;
  isEmailTemplate: boolean;
  isPending: boolean;
  qraAccess: boolean;
  loginDate: string;
  totalLogins: string;
  android: boolean;
  mobileEmailAccess: boolean;
  mobileSMSAccess: boolean;
  mobileShareAccess: boolean;
  mobileDownloadAccess: boolean;
  desktopEmailAccess: boolean;
  webEmailAccess: boolean;
  allowReplies: boolean;
  globalBcc: string;
  infusionSoftCID: string;
  defaultSignature: string;
  timezone: string;
  transcriptionAccess: boolean;
  droplrAccess: boolean;
  transcriptionDefaultEnabled: boolean;
  autoTranscribe: boolean;
  phoneId: string;
  onboarded: boolean;
  eleadDMSEmployeeID?: any;
  eleadEnabled: string;
  nmls: string;
  reactionsPreference?: any;
  vdpEnabled: boolean;
  onboardedFreemium: string;
  automotiveRole: string;
  workingHours: IWorkingHours;
  reseller: IReseller;
  customer: Customer;
  interactiveCTAs?: InteractiveCTA[] | null;
  customerAccess: boolean;
  userOrganizationAccess?: UserOrganizationAccess[];
  upgradeRequestedOn?: string;
  trialEmailsSent: number;
  videoShareLinkText?: string;
  email2?: string;
}

// Decoded token
export interface IDecodedToken {
  user: IUser;
  uid: string;
  userId: string;
  customerId: string;
  resellerId: string;
  firstName: string;
  admin: boolean;
  trial: boolean;
  access: string;
  verified: string;
  captions: boolean;
  vin: boolean;
  iat: number;
  exp: number;
  showWelcome: boolean;
  salesforceIntegration: boolean;
  webEmailEnabled: boolean;
  allowDelete: boolean;
  webShare: boolean;
  intercom: IIntercom;
}
interface IUserRoles {
  isCompanyAdmin: boolean;
  /**
   * @desc
   * User is automotive if they have automotiveAccess = 1 and have an automotive role
   */
  isAutomotive: boolean;
  /**
   * @desc
   * User is reseller admin if they are company admin and they have resellerAdmin = true || resellerAdmin = '1'
   */
  isResellerAdmin: boolean;
  isOrganizationAdmin: boolean;
  isMultiLocation: boolean;
  superAdminRole: SuperAdminRole;
  hasRegions: boolean;
  isAutomotiveServiceRole: boolean;
  isAutomotiveSalesRole: boolean;
  isIMSEnabled: boolean;
  isVdpEnabled: boolean;
  isVdpAllowed: boolean;
  hasLiveVideoAccess: boolean;
}

export interface ICombinedUserData extends RootUser, IUserRoles {
  preventUserEditAccess: boolean;
  trialDays: number;
  trialExpired: boolean;
  credit700Url: string;
  guideCategoriesAdministration: boolean;
}

export enum THEME_NAME {
  AUTOMOTIVE = 'automotive',
  COVIDEO = 'covideo',
  KENNECTED = 'kennected',
  PROVIDEO = 'provideo',
  APP_2_VID = 'app2vid',
  BRANDING = 'branding',
  CDK = 'cdk',
}
interface IAuthState {
  userData: ICombinedUserData;
  whitelabel: IWhiteLabel;
  setJwtAndUpdateToken: (
    tokens: {
      accessToken: string;
      refreshToken?: string;
      adminJwt?: string;
    },
    shouldStoreTokens?: boolean
  ) => void;
  invalidateUser: () => Promise<void>;
  invalidateNotificationCount: () => Promise<void>;
  themeName: THEME_NAME;
}

const authState = {} as IAuthState;

const AuthContext = React.createContext(authState);
/*  STEPS TO LOAD THE APP
1. Call whitelabel API, generate links and set usebeta cookie
2. After the whitelabel call is finished we are calling get user.
On get user ERROR we are checking if status is 401 and reddirecting user to logut
or we throw an error if user is undefined or api throws an error(404, 500, etc...).

On get user SUCCESS we are setting theme and setting favicon
*/

function AuthProvider(props: any) {
  const { token } = getCurrentToken();
  const { showError } = useToastError();
  const [jwt, setJwt] = useState<string | undefined>(token);
  const queryClient = useQueryClient();
  const isSignup = window.location.pathname.includes('signup');
  const isResetPassword = window.location.pathname.includes(
    'verify-reset-password'
  );

  //FIRST STEP: GET WHITE_LABEL
  const { data: whitelabel, isLoading: isLoadingWhiteLabel } =
    useGetWhiteLabelQuery();
  //SECOND STEP: GET USER DATA
  const { data, isLoading } = useGetUserQuery(whitelabel);

  // GET LOCATIONS DATA
  const hasLocationsEnabled = !!(
    data &&
    data.customer.hasMultiLocation &&
    data.customer.hasMultiLocation.toString() === '1'
  );
  const { data: locations, isLoading: isLoadingLocations } =
    useGetLocationsQuery(hasLocationsEnabled, data?.id || 0);

  const {
    data: userOrganizationAccess = [],
    isLoading: isLoadingOrganizationAccess,
  } = useGetOrganizationAccessQuery();
  const organizationIds = userOrganizationAccess.map(
    userOrganizationAccess => userOrganizationAccess.organizationId
  );
  const isOrganizationAdmin = !!organizationIds.length;

  const hasRegions = !!(data && data.customer && isRegionsReseller(data));
  if (
    isLoading ||
    isLoadingWhiteLabel ||
    isLoadingLocations ||
    isLoadingOrganizationAccess
  ) {
    return <LoadingIndicator isLoading={true} />;
  }

  if (!(isSignup || isResetPassword) && !data) {
    showError('Something went wrong with loading user!');
  }

  if (!(isSignup || isResetPassword) && !jwt) {
    const redirectParam =
      whitelabel?.name === 'Covideo'
        ? `?redirect=${window.location.toString()}`
        : '';
    window.location.href = `https://${whitelabel?.domainV2}/backoffice/incs/logout.php${redirectParam}`;
    return null;
  }

  const invalidateUser = async () => {
    await queryClient.refetchQueries(usersKeys.user());
  };

  const invalidateNotificationCount = async () => {
    await queryClient.refetchQueries(notificationKeys.count());
  };

  const decodedToken: IDecodedToken | undefined = data
    ? decodeJwt(jwt)
    : undefined;

  const isCompanyAdmin =
    `${data?.access}` === AccessRole.ADMIN ||
    `${data?.access}` === AccessRole.SUPERVISOR;

  const hasAccessToLocation = !!(
    locations &&
    locations.length &&
    locations.find(
      (location: ILocation) =>
        location.locationId.toString() === data?.customer.locationId.toString()
    )
  );

  const isResellerAdmin = isCompanyAdmin && !!data?.resellerAdmin;
  const superAdminRole = getSuperAdminRole(data);
  const isMultiLocation = hasLocationsEnabled && hasAccessToLocation;
  const hasLiveVideoAccess = !!data?.customer.liveAccess;

  const userRoles: IUserRoles = {
    isCompanyAdmin,
    isMultiLocation,
    superAdminRole,
    hasRegions,
    isResellerAdmin,
    isOrganizationAdmin,
    hasLiveVideoAccess,
    ...getAutomotiveFlags(data),
  };
  const preventUserEditAccess =
    data?.customer.preventUserEditAccess.toString() === '1' &&
    `${data?.access}` === AccessRole.USER;

  // Consider remove this method to string in future
  const payload = {
    ...toString({
      ...data,
      customer: { ...(data?.customer || {}) },
      reseller: { ...(data?.reseller || {}) },
      userOrganizationAccess: [...userOrganizationAccess],
    }),
  };

  let trialExpired = false;
  let trialDays = 0;
  if (!!payload.trialAccount) {
    // Get the signup date from the decoded Token
    let signupTime = new Date().getTime();
    const intercom = decodedToken?.intercom || decodedToken?.user?.intercom;
    if (!!intercom && !!intercom?.signup_date) {
      signupTime = new Date(intercom.signup_date + ' EDT').getTime();
    }

    // Get Trial period for the user
    const trialPeriod = (payload.id || 0) > 130968 ? 7 : 14;
    // Calculate the purchase date from the signup date
    const purchaseDate = new Date(signupTime);
    purchaseDate.setDate(purchaseDate.getDate() + trialPeriod);

    // Get the number of days left for the trial user
    const timeLeft = purchaseDate.getTime() - new Date().getTime();
    trialDays = Math.round(timeLeft / (1000 * 60 * 60 * 24));
    trialExpired = trialDays < 1;
  }
  const credit700Url = data?.customer?.credit700Url;
  const themeName = getThemeByWhiteLabel(whitelabel);

  const setJwtAndUpdateToken = (
    tokens: {
      accessToken: string;
      refreshToken?: string;
      adminJwt?: string;
    },
    shouldStoreTokens = true
  ) => {
    const whiteLabelRootDomain = whitelabel?.rootDomain || '';
    if (shouldStoreTokens) {
      updateAxiosInstaceToken(
        tokens.accessToken,
        tokens.refreshToken,
        tokens.adminJwt,
        whiteLabelRootDomain
      );
    }

    setJwt(tokens.accessToken);
  };

  const value = {
    userData: {
      ...userRoles,
      ...decodedToken,
      ...payload,
      preventUserEditAccess,
      trialDays,
      trialExpired,
      credit700Url,
    },
    setJwtAndUpdateToken,
    whitelabel: whitelabel,
    invalidateUser,
    invalidateNotificationCount,
    themeName,
  };

  Sentry.setUser({
    username: value?.userData?.username,
    id: value?.userData?.id,
  });

  return <AuthContext.Provider value={value} {...props} />;
}

const decodeJwt = (jwt: string | undefined) => {
  if (!jwt) {
    return;
  }
  const decodedJwt: IDecodedToken = jwtDecode(jwt);
  if (!decodedJwt?.user?.packageDetails?.products) {
    return decodedJwt;
  }
  let products: Array<string | number> = [];
  try {
    if (Array.isArray(decodedJwt.user.packageDetails.products)) {
      products = decodedJwt.user.packageDetails.products;
    } else {
      products = JSON.parse(decodedJwt.user.packageDetails.products);
    }
  } catch (ex) {}
  const dataWithProducts = {
    ...decodedJwt,
    user: {
      ...decodedJwt.user,
      packageDetails: { ...decodedJwt.user.packageDetails, products },
    },
  };
  return dataWithProducts;
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
