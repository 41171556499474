import { Option } from 'lib/components/dropdown/Dropdown';
import { NAPLETON_AUTOMOTIVE_RESELLER_ID } from 'lib/const/SuperAdminConstants';

export interface CompanyValues {
  businessName: string;
  firstNameBusiness: string;
  lastNameBusiness: string;
  businessPhone1: string;
  businessPhone2?: string;
  businessFax?: string;
  businessEmail: string;
  businessUrl?: string;
  allowReplies: boolean;
  preloadTeleprompterScripts: boolean;
  quoteSettings: string;
  reactionsPreference: number;
  region?: Option;
  markVideosAsSent?: string;
  credit700Url?: string;
  mapIframeEmbedCode: string;
  autominerGroupId?: string;
  resellerId?: number;
}

interface ErrorValues {
  businessName?: string;
  firstNameBusiness?: string;
  lastNameBusiness?: string;
  businessPhone1?: string;
  businessPhone2?: string;
  businessFax?: string;
  businessEmail?: string;
  businessUrl?: string /* SUS-796 changes */;
  mapIframeEmbedCode?: string;
}

const messages = {
  firstNameBusiness: [
    'Please enter your first name.',
    'First name cannot be empty.',
    'First name can only contain letters, numbers, parenthesis, dashes(-), plus(+) and periods(.).',
    'First name can not contain more than 100 characters.' /* SUS-796 changes */,
  ],
  lastNameBusiness: [
    'Please enter your last name.',
    'Last name can only contain letters, numbers, parenthesis, dashes(-), plus(+) and periods(.).',
    'Last name can not contain more than 100 characters.' /* SUS-796 changes */,
  ],
  businessEmail: ['Please enter a valid email address.'],
  businessPhone1: [
    'Your primary phone number cannot be empty.',
    'Please enter a phone number equal or longer than 7 characters.',
    'Phone number 1 can only contain letters, numbers, parenthesis, dashes(-), plus(+) and periods(.)',
  ],
  businessPhone2: [
    'Please enter a phone number equal or longer than 7 characters.',
    'Phone number 2 can only contain letters, numbers, parenthesis, dashes(-), plus(+) and periods(.)',
  ],
  businessName: [
    'Please enter your business name.',
    'Business name can only contain letters, numbers, spaces and/or (-+@,.&_) special characters can be used.',
    'Business name can not contain more than 100 characters.' /* SUS-796 changes */,
  ],
  businessFax: [
    'Fax can only contain letters, numbers, parenthesis, dashes(-), plus(+) and periods(.).',
  ],
  businessUrl: [
    'Business Url can not contain more than 100 characters.',
  ] /* SUS-796 changes */,
};

export const validateCompany = (values: CompanyValues) => {
  //const nameRegex = /^[a-zA-Z0-9()+\-.\s']+$/i;
  const businessRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9+\-,.&_@\s']+$/i;
  const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9()+\-.\s']+$/i;
  const emailRegex = /^[0-9a-z\\._-]+@[0-9a-z-]+\..+$/i;
  const phoneRegex = /^[0-9a-z()\-.\s]+$/i;
  const errors: ErrorValues = {};
  if (!values.businessName) {
    errors.businessName = messages.businessName[0];
  } else if (!businessRegex.test(values.businessName)) {
    errors.businessName = messages.businessName[1];
  } else if (values.businessName && values.businessName.length > 100) {
    /* SUS-796 changes */
    errors.businessName = messages.businessName[2];
  }

  if (!values.firstNameBusiness) {
    errors.firstNameBusiness = messages.firstNameBusiness[0];
  } else if (!nameRegex.test(values.firstNameBusiness)) {
    errors.firstNameBusiness = messages.firstNameBusiness[2];
  } else if (
    values.firstNameBusiness &&
    values.firstNameBusiness.length > 100
  ) {
    /* SUS-796 changes */
    errors.firstNameBusiness = messages.firstNameBusiness[3];
  }

  if (
    !values.lastNameBusiness &&
    Number(values.resellerId) !== NAPLETON_AUTOMOTIVE_RESELLER_ID
  ) {
    errors.lastNameBusiness = messages.lastNameBusiness[0];
  }
  if (values.lastNameBusiness) {
    if (!nameRegex.test(values.lastNameBusiness)) {
      errors.lastNameBusiness = messages.lastNameBusiness[1];
    } else if (
      values.lastNameBusiness &&
      values.lastNameBusiness.length > 100
    ) {
      /* SUS-796 changes */
      errors.lastNameBusiness = messages.lastNameBusiness[2];
    }
  }

  if (!values.businessEmail) {
    errors.businessEmail = messages.businessEmail[0];
  } else if (!emailRegex.test(values.businessEmail)) {
    errors.businessEmail = messages.businessEmail[1];
  }

  if (!values.businessPhone1) {
    errors.businessPhone1 = messages.businessPhone1[0];
  } else if (values.businessPhone1.length < 7) {
    errors.businessPhone1 = messages.businessPhone1[1];
  } else if (!phoneRegex.test(values.businessPhone1)) {
    errors.businessPhone1 = messages.businessPhone1[2];
  }

  if (values.businessPhone2 && values.businessPhone2.length > 0) {
    if (values.businessPhone2.length < 7) {
      errors.businessPhone2 = messages.businessPhone2[0];
    } else if (!phoneRegex.test(values.businessPhone2)) {
      errors.businessPhone2 = messages.businessPhone2[1];
    }
  }
  /* SUS-796 changes */
  if (values.businessUrl && values.businessUrl.length > 100) {
    errors.businessUrl = messages.businessUrl[0];
  }

  return errors;
};
