import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { ButtonPillSwitch, LoadingIndicator } from 'lib/components';
import { VideoListAutomotiveItem } from 'lib/context';
import { theme } from 'lib/style';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import { Note, ReviewForm, ModalDenyRequest } from '../components';
import {
  addVoiceoverToVideo,
  GetSignedUrlsParams,
  GetSignedUrlsResponse,
  getVoiceoverSignedURLs,
  TrimVideoData,
  uploadVoiceoverAudio,
} from 'lib/api';
import { VideoPages } from 'lib/const';
import dayjs from 'dayjs';
import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { NotFound } from 'app/pages/notFound/NotFound';
import { VideoCropAndVoiceover } from '../components/VideoCropAndVoiceover';
import { FILE_EXTENSION } from 'lib/hooks/useMediaRecorder';
import { generateTempFileName } from 'app/pages/video/videoVoiceover/components/util';
import { successToast } from 'lib/components/toasts/success';
import { ModalInputText, ModalPrompt } from 'lib/components/modal';
import { errorToast } from 'lib/components/toasts/error';
import { Button } from 'react-covideo-common';
import { useVideoRequestQuery } from 'lib/api/videoRequests/useVideoRequestQuery';
import { useApproveVideoRequestMutation } from 'lib/api/videoRequests/useApproveVideoRequestMutation';
import { useDenyVideoRequestMutation } from 'lib/api/videoRequests/useDenyVideoRequestMutation';
import { useEditVideoRequestMutation } from 'lib/api/videoRequests/useEditVideoRequestMutation';
import { useQueryClient } from 'react-query';
import { videoRequestKeys } from 'lib/api/videoRequests/videoRequestKeys';
import { FullScreenModal } from 'lib/components/FullScreenModal';

const REVIEW_VIDEO_MAX_WIDTH = 800;
const REVIEW_VIDEO_MAX_HEIGHT = REVIEW_VIDEO_MAX_WIDTH * 0.5625;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const LinkBtn = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: ${theme.palette.coal};
  cursor: pointer;
  span {
    margin-left: 5px;
  }
`;
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
const ContentWrapper = styled.div`
  width: 1216px;
  max-width: 100%;
  margin: 0 auto;
`;
const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const Left = styled.div`
  width: ${`${REVIEW_VIDEO_MAX_WIDTH}px`};
  max-width: 100%;
  flex-shrink: 0;
`;
const Right = styled.div`
  box-sizing: border-box;
  width: 384px;
  max-width: 100%;
  flex-shrink: 0;
`;
const VideoWrapper = styled.div`
  position: relative;
  .fullscreen {
    height: auto;
  }
  figure {
    background: black;
    min-width: ${`${REVIEW_VIDEO_MAX_WIDTH}px`};
  }
  video {
    max-height: ${`${REVIEW_VIDEO_MAX_HEIGHT}px`};
    object-position: top;
  }
`;
const Info = styled.div`
  box-sizing: border-box;
  padding: 16px 16px 20px;
  background-color: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
  margin-bottom: 16px;
`;

const TABS = {
  REVIEW: 'Review',
  EDIT: 'Trim & Voiceover',
};

interface MatchParams {
  videoRequestId: string;
}
export const Review = withRouter((props: RouteComponentProps<MatchParams>) => {
  const { videoRequestId } = props.match.params;
  const {
    mutateAsync: approveVideoRequestMutation,
    isLoading: isLoadingApprove,
  } = useApproveVideoRequestMutation();
  const { mutateAsync: denyVideoRequestMutation, isLoading: isLoadingDeny } =
    useDenyVideoRequestMutation();
  const { mutateAsync: editVideoRequestMutation, isLoading: isLoadingEdit } =
    useEditVideoRequestMutation();
  const { data: videoRequest, isLoading: isLoadingVideoRequest } =
    useVideoRequestQuery(videoRequestId || '');
  const queryClient = useQueryClient();
  const video = videoRequest?.video;
  const [videoRequestValid, setVideoRequestValid] = useState(true);
  const [formIsTouched, setFormIsTouched] = useState(false);
  const [updatedVideoRequest, setUpdatedVideoRequest] = useState(videoRequest);
  const [showModalDenyRequest, setShowModalDenyRequest] = useState(false);
  const [showDiscardVoiceoverPrompt, setShowDiscardPrompt] = useState(false);
  const [trimVideoData, setTrimVideoData] = useState({} as TrimVideoData);
  const [activeTab, setActiveTab] = useState(TABS.REVIEW);
  const [isVoiceoverValid, setIsVoiceoverValid] = useState(false);
  const [recordingBlobUrl, setRecordingBlobUrl] = useState('');
  const [useVoiceover, setUseVoiceover] = useState(false);
  const [editedVideo, setEditedVideo] = useState<VideoListAutomotiveItem>({
    ...video,
    videoRequest,
  });
  const [showModalRename, setShowModalRename] = useState(false);

  let newVideoCreated = !!editedVideo?.id && editedVideo.id !== video.id;

  const videoRef = createRef<HTMLVideoElement>();

  const validateVideoRequest = () => {
    return true;
  };
  const handleFormChange = () => {
    setVideoRequestValid(validateVideoRequest());
    setFormIsTouched(true);
  };

  const history = useHistory();

  const submitVoiceover = async (newVideoTitle: string) => {
    const blob = await fetch(recordingBlobUrl || '').then(r => r.blob());
    const newAudioFileName = generateTempFileName(
      editedVideo.id,
      FILE_EXTENSION
    );
    const file = new File([blob], newAudioFileName);

    const signedUrlData: GetSignedUrlsParams = {
      fileName: file.name,
      mimeType: 'audio/webm',
      folder: '',
    };

    const signedURL: GetSignedUrlsResponse =
      await getVoiceoverSignedURLs(signedUrlData);

    if (!signedURL || signedURL instanceof Error) {
      errorToast({
        title: 'Server is currently unavailable, try again later!',
      });
      return;
    }

    const data = {
      file: file as File,
      uploadURL: signedURL.uploadUrl,
      mimeType: signedUrlData.mimeType,
    };

    try {
      await uploadVoiceoverAudio({ data });
      const newVideo = await addVoiceoverToVideo({
        videoId: editedVideo.id,
        newVideoTitle: newVideoTitle || editedVideo?.title || video.title,
        audioFileName: newAudioFileName,
        overwriteOriginal: newVideoCreated,
      });
      setUseVoiceover(true);
      successToast({ title: 'Voiceover successfully created!' });
      return newVideo;
    } catch (error) {
      errorToast({ title: 'Creating voiceover failed, try again later!' });
      return 0;
    }
  };

  const denyVideoRequest = async (
    videoRequestId: string,
    emailContent?: string
  ) => {
    await editVideoRequestMutation({
      videoRequestId,
      data: {
        customerName: updatedVideoRequest.customerName,
        videoType: updatedVideoRequest.videoType,
        repairOrderNumber: updatedVideoRequest.repairOrderNumber,
      },
    });
    await denyVideoRequestMutation({
      videoRequestId,
      note: emailContent || '',
    });
    queryClient.refetchQueries(videoRequestKeys.count_pending());
  };

  const handleDenyClick = async (emailContent?: string) => {
    try {
      await denyVideoRequest(videoRequestId, emailContent);
      if (newVideoCreated) {
        await denyVideoRequest(editedVideo.videoRequest.videoRequestId);
      }
      setFormIsTouched(false);
      successToast({
        title: `Video request successfully denied!`,
      });
      if (emailContent) {
        setTimeout(() => {
          successToast({
            title: `Email Sent.`,
          });
        }, 0);
      }
      goToVideoLibrary();
    } catch (error) {
      console.log(error);
      errorToast({
        title: `There was an error updating the video request.`,
      });
    }
  };

  const approveVideoRequest = async (videoRequestId: string) => {
    await editVideoRequestMutation({
      videoRequestId,
      data: {
        customerName: updatedVideoRequest.customerName,
        videoType: updatedVideoRequest.videoType,
        repairOrderNumber: updatedVideoRequest.repairOrderNumber,
      },
    });
    await approveVideoRequestMutation({
      videoRequestId,
    });
    queryClient.refetchQueries(videoRequestKeys.count_pending());
  };

  const handleApproveClick = async (newVideoTitle?: string) => {
    let newVideoRequestId = editedVideo.videoRequest.videoRequestId;
    let newVideoId = editedVideo.id;
    if (isVoiceoverValid && !newVideoCreated && !newVideoTitle) {
      setShowModalRename(true);
      return;
    }
    try {
      if (isVoiceoverValid) {
        const voiceoveredVideo = await submitVoiceover(newVideoTitle || '');
        newVideoRequestId = voiceoveredVideo.videoRequest.videoRequestId;
        newVideoId = voiceoveredVideo.id;
        newVideoCreated = true;
      }
      await approveVideoRequest(videoRequestId);
      if (newVideoCreated) {
        await approveVideoRequest(newVideoRequestId);
      }
      setFormIsTouched(false);
      setIsVoiceoverValid(false);
      goToSendShare(newVideoCreated ? newVideoId : video.id);
    } catch (error) {
      console.log(error);
      errorToast({
        title: `There was an error updating the video request.`,
      });
    }
  };

  const goToVideoRequests = () => {
    history.push(`/video-requests`);
  };

  const goToVideoLibrary = () => {
    history.push(`/home`);
  };

  const goToSendShare = (id: string) => {
    history.push(`/home/${VideoPages.SEND_AND_SHARE}/${id}/`);
  };

  const onChangeTab = (tab: string) => {
    if (isVoiceoverValid) {
      setShowDiscardPrompt(true);
      return;
    }
    setActiveTab(tab);
  };

  const discardVoiceover = () => {
    setIsVoiceoverValid(false);
    setActiveTab(TABS.REVIEW);
    setShowDiscardPrompt(false);
    setRecordingBlobUrl('');
  };

  useEffect(() => {
    if (useVoiceover && window.Intercom) {
      window.Intercom('trackEvent', 'voiceover-used');
    }
  }, [useVoiceover]);

  useEffect(() => {
    if (videoRequest) {
      setUpdatedVideoRequest(videoRequest);
      setTrimVideoData({
        duration: videoRequest.video ? videoRequest.video.videoLength : 0,
        start: 0,
      });
    }
  }, [videoRequest]);

  useEffect(() => {
    setEditedVideo({ ...video, videoRequest });
  }, [videoRequest]);

  const isLoading =
    isLoadingVideoRequest || isLoadingApprove || isLoadingDeny || isLoadingEdit;

  if (!isLoading && !videoRequest) {
    return <NotFound />;
  }

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  return (
    <>
      <RouteLeavingGuard
        when={true}
        stay={true}
        onConfirm={() => {}}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return formIsTouched;
        }}
        title='Close Video request without saving changes?'
        text='Are you sure you want to close the video request? The changes made to the video request have not been saved.'
        confirmButtonText='Keep Editing'
        discardButtonText='Yes, Close'
        showDiscardIcon={false}
        showSaveIcon={false}
      />
      <FullScreenModal
        headerLeft={
          <LinkWrapper>
            <LinkBtn onClick={() => goToVideoRequests()}>
              <MdClose size='18' />
              <span>Close</span>
            </LinkBtn>
          </LinkWrapper>
        }
        headerCenter={
          <ButtonPillSwitch
            disableToggle={isVoiceoverValid}
            defaultValue={activeTab}
            values={TABS}
            onChange={activeTab => onChangeTab(activeTab)}
          />
        }
        headerRight={
          <Actions>
            <Button
              variant='secondary'
              text='Deny'
              onClick={() => setShowModalDenyRequest(true)}
            />
            <Button
              text='Approve'
              disabled={!videoRequestValid}
              onClick={() => handleApproveClick()}
            />
          </Actions>
        }
      >
        <ContentWrapper>
          <Row>
            <Left>
              {activeTab === TABS.REVIEW && (
                <VideoWrapper>
                  <VideoPlayer
                    width={`${REVIEW_VIDEO_MAX_WIDTH}px`}
                    height={`${REVIEW_VIDEO_MAX_HEIGHT}px`}
                    playerBackgroundColor={video.playerBackgroundColor || ''}
                    videoSource={video.videoSource}
                    videoId={video.id}
                    playButtonPosition={video.playButtonPosition || ''}
                    playerIconsColor={video.playerIconsAndTextColor || ''}
                    videoRef={videoRef}
                    autoplay={false}
                  />
                </VideoWrapper>
              )}
              {activeTab === TABS.EDIT && (
                <VideoCropAndVoiceover
                  video={editedVideo || video}
                  trimVideoData={trimVideoData}
                  setTrimVideoData={setTrimVideoData}
                  width={REVIEW_VIDEO_MAX_WIDTH}
                  showTickTime={false}
                  showFloatingPreview={false}
                  isVoiceoverValid={isVoiceoverValid}
                  onChangeVoiceoverValid={setIsVoiceoverValid}
                  onChangeRecordingBlob={setRecordingBlobUrl}
                  onChangeVideoToApprove={setEditedVideo}
                  skipTrim={!!editedVideo?.id && editedVideo?.id !== video.id}
                  setFormIsTouched={setFormIsTouched}
                />
              )}
            </Left>
            <Right>
              {videoRequest.note && (
                <Info>
                  <Note
                    note={videoRequest.note}
                    user={
                      videoRequest.user
                        ? `${videoRequest.user.firstName} ${videoRequest.user.lastName}`
                        : ''
                    }
                    createdAt={dayjs(videoRequest.createdAt).format(
                      `YYYY-MM-DD h:mm A`
                    )}
                  />
                </Info>
              )}
              <Info>
                {updatedVideoRequest && (
                  <ReviewForm
                    updatedVideoRequest={updatedVideoRequest}
                    setUpdatedVideoRequest={setUpdatedVideoRequest}
                    handleFormChange={handleFormChange}
                  />
                )}
              </Info>
            </Right>
          </Row>
        </ContentWrapper>
      </FullScreenModal>
      {showModalDenyRequest && (
        <ModalDenyRequest
          user={videoRequest.user.firstName}
          handleModalClose={() => setShowModalDenyRequest(false)}
          onClickWhiteButton={() => setShowModalDenyRequest(false)}
          onClickOrangeButton={(emailContent: string) => {
            handleDenyClick(emailContent);
          }}
        />
      )}
      {showDiscardVoiceoverPrompt && (
        <ModalPrompt
          title={'Discard voiceover?'}
          content={<>Your voiceover recording will be erased.</>}
          secondaryButtonText={'Go Back'}
          primaryButtonText={'Discard voiceover'}
          primaryButtonType={'destructive'}
          handleSubmit={() => discardVoiceover()}
          handleModalClose={() => setShowDiscardPrompt(false)}
        />
      )}
      {showModalRename && (
        <ModalInputText
          title='Name new video'
          label='Give your new video a name'
          defaultValue={video.title}
          secondaryButtonText='Go Back'
          primaryButtonText='Continue'
          handleSubmit={(newVideoTitle: string) => {
            handleApproveClick(newVideoTitle);
          }}
          handleModalClose={() => setShowModalRename(false)}
        />
      )}
    </>
  );
});
