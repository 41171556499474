export enum ACCESS_TYPE {
  STANDARD = 1,
  ADMIN = 3,
  SUPERVISOR = 4,
}

export const accessTypeOptions = [
  { value: ACCESS_TYPE.STANDARD, label: 'Standard' },
  { value: ACCESS_TYPE.ADMIN, label: 'Admin' },
  { value: ACCESS_TYPE.SUPERVISOR, label: 'Supervisor' },
];

export const SHOW_TAB_CHANGE_POPUP = 'showTabChangePopup';
