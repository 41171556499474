import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  LinkBtn,
  LinkWrapper,
} from 'app/pages/library/videos/videoList/components/SendAndShare';

import { MdClose } from 'react-icons/md';
import { WheelsTVTable } from './WheelsTVTable';
import { FullScreenModal } from 'lib/components/FullScreenModal';

const Title = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss02' on;
  color: ${theme.palette.covideoBlue100};
`;

type Props = {
  handleModalClose: () => void;
  onCopy?: () => void;
};

export const ModalWheelsTV = ({ handleModalClose, onCopy }: Props) => {
  return (
    <FullScreenModal
      headerLeft={
        <LinkWrapper>
          <LinkBtn onClick={handleModalClose}>
            <MdClose size='18' />
            <span>Close</span>
          </LinkBtn>
        </LinkWrapper>
      }
      headerCenter={<Title>Available WheelsTV Videos</Title>}
    >
      <WheelsTVTable handleModalClose={handleModalClose} onCopy={onCopy} />
    </FullScreenModal>
  );
};
