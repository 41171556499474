import { SortButtons } from 'app/pages/admin/components/SortButtons';

import dayjs from 'dayjs';
import { useAdminUsersQuery } from 'lib/api/admin/useAdminUsersQuery';
import { LeadOpportunitiesBaseParams } from 'lib/api/leadOpportunities/types';
import { useLeadOpportunutiesDownloadMutation } from 'lib/api/leadOpportunities/useLeadOpportunutiesDownloadMutation';
import { useLeadOpportunutiesFiltersQuery } from 'lib/api/leadOpportunities/useLeadOpportunutiesFiltersQuery';
import {
  ILeadOpportunitiesMetrics,
  useLeadOpportunutiesQuery,
} from 'lib/api/leadOpportunities/useLeadOpportunutiesQuery';
import { useLeadOpportunutiesStatsQuery } from 'lib/api/leadOpportunities/useLeadOpportunutiesStatsQuery';
import {
  Dropdown,
  LoadingIndicator,
  Search,
  Spinner,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import {
  Field,
  ModalCustomTableFields,
  TABLE_NAME_PREFIX,
} from 'lib/components/modal';
import {
  Container,
  Flex,
  Gap,
  MainWrapper,
} from 'lib/components/styles/layout';
import {
  Heading,
  ParagraphNormal,
  ParagraphSmallBold,
} from 'lib/components/styles/typography';
import { METRICS_DATE_FORMAT } from 'lib/const/DateFormat';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE_100,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-covideo-common';
import { FaFilter } from 'react-icons/fa';
import { IoMdOptions } from 'react-icons/io';
import { MdFileDownload } from 'react-icons/md';
import { useLocation } from 'react-router';
import styled, { useTheme } from 'styled-components/macro';
import { DateRangeSelector } from '../../components';
import {
  calculateDatesForRange,
  getDateRangeFromLocalstorage,
  timeRangeConstants,
} from '../../components/DateRangeSelector/DateRangeSelector';

import { ModalFilterLeads } from '../components/ModalFilterLeads';
import { formatFilters } from '../utils';
import { UsageStatsCard } from 'app/pages/admin/usageReports/components/UsageStatsCard';
type TableCellProps = {
  width?: string;
  display?: 'block';
};
const TableCell = styled.div<TableCellProps>`
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  align-items: center;
  width: ${({ width }) => width || 'auto'};
  display: ${({ display }) => display || 'flex'};
`;

const FilterDot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.colors.primary[100]};
  border-radius: 50%;
  top: -2px;
  right: -2px;
`;

const HeaderWrapper = styled(Flex)`
  .dropdown-container {
    margin: 0;
  }
`;

enum TABLE_FIELDS {
  DATE = 'date',
  LEAD_NAME = 'leadName',
  ASSIGNEE = 'assignee',
  RECORDED = 'recorded',
  SENT = 'sent',
  VIEWS = 'views',
  LEAD_SORUCE = 'leadSource',
  LEAD_GROUP_CATEGORY = 'leadGroupCategory',
  IS_HOT = 'isHot',
  LEAD_STATUS = 'leadStatus',
  LEAD_STATUS_TYPE = 'leadStatusType',
}

export enum SORT_KEYS {
  CREATED_AT = 'CREATED_AT',
  ASSIGNEE = 'ASSIGNEE',
  RECORDED = 'RECORDED',
  SENT = 'SENT',
  VIEWS = 'VIEWS',
}

export const MainLeadReports = () => {
  const sectionName = 'leadsReports';

  const location = useLocation();
  const { colors } = useTheme();

  const staticFields: Field[] = [
    {
      value: TABLE_FIELDS.DATE,
      label: 'Date',
      sortKey: SORT_KEYS.CREATED_AT,
    },
    {
      value: TABLE_FIELDS.LEAD_NAME,
      label: 'Lead Name',
    },
    {
      value: TABLE_FIELDS.ASSIGNEE,
      label: 'Assignee',
      sortKey: SORT_KEYS.ASSIGNEE,
    },
  ];

  const clientFields: Field[] = [
    {
      value: TABLE_FIELDS.RECORDED,
      label: 'Recorded',
      sortKey: SORT_KEYS.RECORDED,
      description: 'Number of videos recorded for the Lead.',
    },
    {
      value: TABLE_FIELDS.SENT,
      label: 'Sent',
      sortKey: SORT_KEYS.SENT,
      description: 'Number of sends to the Lead.',
    },
    {
      value: TABLE_FIELDS.VIEWS,
      label: 'Views',
      sortKey: SORT_KEYS.VIEWS,
      description: 'Number of views on sends to the Lead.',
    },
  ];

  // range
  const queryParams = queryString.parse(location.search);
  const storedDateRange = getDateRangeFromLocalstorage();
  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );
  const [startDate, setStartDate] = useState<Date>(start);
  const [endDate, setEndDate] = useState<Date>(end);
  const [dateRange, setDateRange] = useState(initRange);
  // query
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  //customize
  const [isCustomizeModalOpen, setCustomizeModalOpen] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState<null | number>(null);
  // All available fields (static, client, and API)
  const [allFields, setAllFields] = useState<Field[]>([]);
  // Selected fields, fetched from localStorage or defaults
  const [selectedFields, setSelectedFields] = useState<Field[]>([]);
  //sorting
  const [sortKey, setSortKey] = useState<SORT_KEYS | null>(null);
  const [order, setOrder] = useState(PaginationConstants.ASCENDING);
  const [filtersQuery, setFiltersQuery] = useState<{
    [key: string]: { value: string; label: string }[];
  }>({});

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [size, setSize] = useState(DEFAULT_SIZE_100);

  const { data, isLoading: isLoadingLeadOpportunutiesFilters } =
    useLeadOpportunutiesFiltersQuery();

  const { data: assigneeData, isLoading: isLoadingassignees } =
    useAdminUsersQuery({});
  const {
    mutateAsync: downloadReportMutation,
    isLoading: isDownloadingReport,
  } = useLeadOpportunutiesDownloadMutation();

  const formattedFilters = formatFilters(filtersQuery);

  const baseParams: LeadOpportunitiesBaseParams = {
    ...(selectedAssignee ? { assigneeId: selectedAssignee } : {}),
    ...(searchQuery ? { search: searchQuery } : {}),
    ...(!!Object.keys(formattedFilters).length
      ? { filter: formattedFilters }
      : {}),
    from: dayjs(startDate).format(METRICS_DATE_FORMAT),
    to: dayjs(endDate).format(METRICS_DATE_FORMAT),
  };

  const { data: leadOpportunutiesData, isLoading: isLoadingLeadOpportunuties } =
    useLeadOpportunutiesQuery({
      params: {
        ...baseParams,
        start: page * size || DEFAULT_PAGE,
        limit: size || DEFAULT_SIZE_100,
        ...(!!sortKey
          ? { sortKey: sortKey, order: order || PaginationConstants.ASCENDING }
          : {}),
      },
    });

  const { data: leadReportsStats, isLoading: isLoadingLeadsStats } =
    useLeadOpportunutiesStatsQuery({
      params: {
        ...baseParams,
      },
    });

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };

  const onLeadsSearchHandler = (value: string) => {
    setSearchQuery(value);
    setPage(DEFAULT_PAGE);
  };

  const assigneeOptions = [
    ...(assigneeData?.map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })) || []),
  ];

  const onPaginationChange = async ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    if (page !== newPage || size !== newSize) {
      setPage(newPage);
      setSize(newSize);
    }
  };

  const onSaveCustomTableFields = (fields: Field[]) => {
    localStorage.setItem(
      `${TABLE_NAME_PREFIX + sectionName}`,
      JSON.stringify(fields)
    );
    setSelectedFields([...staticFields, ...fields]); // Ensure UI updates
    setCustomizeModalOpen(false);
  };

  const onSubmitFiltersHandler = (values: {
    [key: string]: {
      value: string;
      label: string;
    }[];
  }) => {
    setFiltersQuery(values);
  };

  useEffect(() => {
    const storedFields = localStorage.getItem(
      `${TABLE_NAME_PREFIX + sectionName}`
    );

    if (storedFields) {
      try {
        const parsedFields = JSON.parse(storedFields) as Field[];
        setSelectedFields([...staticFields, ...parsedFields]);
      } catch (error) {
        console.error('Error parsing stored fields:', error);
      }
    } else {
      setSelectedFields([...staticFields]); // Default selection
    }
  }, []);

  useEffect(() => {
    if (data?.filters) {
      const apiFields: Field[] = data.filters.map(field => ({
        value: field.key,
        label: field.name,
      }));

      setAllFields([...clientFields, ...apiFields]);
    }
  }, [data]);

  const tableFields = [...selectedFields];

  const formatTableHeaders = () => {
    return tableFields.map(item => {
      return (
        <TableCell key={item.value}>
          {item.label}
          {item.sortKey && (
            <SortButtons
              isSorted={sortKey === item.sortKey}
              order={order}
              onChange={value => {
                if (item.sortKey) {
                  setOrder(value);
                  setSortKey(item.sortKey as SORT_KEYS); // Add sorting logic here
                  setPage(DEFAULT_PAGE);
                }
              }}
            />
          )}
        </TableCell>
      );
    });
  };

  const leadOpportunitiesMetrics =
    leadOpportunutiesData?.leadOpportunitiesMetrics;

  //COLUMNS
  const getColumns = (item: ILeadOpportunitiesMetrics) => [
    ...tableFields.map(({ value }) => {
      const itemIsSent = item.sent;
      const displayValue = item[value as keyof ILeadOpportunitiesMetrics];
      const color = !itemIsSent ? colors.danger[100] : '';
      switch (value) {
        case TABLE_FIELDS.LEAD_STATUS:
        case TABLE_FIELDS.LEAD_SORUCE:
        case TABLE_FIELDS.LEAD_NAME:
        case TABLE_FIELDS.ASSIGNEE:
          return (
            <TableCell width={'150px'} display='block'>
              <ParagraphNormal ellipsis color={color} title={`${displayValue}`}>
                {displayValue}
              </ParagraphNormal>
            </TableCell>
          );
        case TABLE_FIELDS.IS_HOT:
          return (
            <TableCell>
              <ParagraphNormal color={color}>
                {item[value] ? 'Hot' : 'Regular'}
              </ParagraphNormal>
            </TableCell>
          );

        default:
          return (
            <TableCell>
              <ParagraphNormal color={color}>{displayValue}</ParagraphNormal>
            </TableCell>
          );
      }
    }),
  ];

  //ROWS
  const rows =
    leadOpportunitiesMetrics?.map((dataItem, index: number) => ({
      key: index,
      columns: getColumns(dataItem),
      onClick: () => {},
    })) || [];

  const isLoading =
    isLoadingLeadOpportunuties ||
    isLoadingassignees ||
    isLoadingLeadOpportunutiesFilters;

  // CustomTableFields excluding static fields to ensure only dynamic fields are processed.
  const filteredSelectedFields = selectedFields.filter(
    field =>
      !staticFields.some(staticField => staticField.value === field.value)
  );

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <Heading m='0 0 32px 0'>Leads Report</Heading>
        <Flex flexDirection='column' gap='32px'>
          <HeaderWrapper flexDirection='row' justifyContent='space-between'>
            <DateRangeSelector
              initialValue={dateRange}
              onDateRangeChange={onDateRangeChange}
              disabled={isLoading}
            />
            <Flex gap='12px' width='auto'>
              <Button
                style={{ width: 'auto' }}
                text='Download'
                icon={
                  isDownloadingReport ? (
                    <Spinner size={8} />
                  ) : (
                    <MdFileDownload size={20} />
                  )
                }
                onClick={() =>
                  downloadReportMutation({
                    params: {
                      ...baseParams,
                      ...(!!sortKey
                        ? {
                            sortKey: sortKey,
                            order: order || PaginationConstants.ASCENDING,
                          }
                        : {}),
                    },
                  })
                }
                disabled={
                  isDownloadingReport ||
                  isLoading ||
                  !leadOpportunitiesMetrics?.length
                }
              />
            </Flex>
          </HeaderWrapper>
          <Gap gap='16px' width='100%' flexWrap='no-wrap'>
            <UsageStatsCard
              text='Total Leads'
              count={leadReportsStats?.count || 0}
              isLoading={isLoadingLeadsStats}
            />
            <UsageStatsCard
              text='Videos Recorded'
              count={leadReportsStats?.recorded || 0}
              isLoading={isLoadingLeadsStats}
            />
            <UsageStatsCard
              text='Videos Sent'
              count={leadReportsStats?.sent || 0}
              isLoading={isLoadingLeadsStats}
            />
            <UsageStatsCard
              text='Views'
              count={leadReportsStats?.views || 0}
              isLoading={isLoadingLeadsStats}
            />
          </Gap>
          <Flex justifyContent='space-between' flexDirection='row' width='100%'>
            <Flex>
              <Search
                onSearch={onLeadsSearchHandler}
                prevSearch={searchQuery}
                placeholder='Search leads...'
                width={'250px'}
                disabled={isLoading}
              />
            </Flex>
            <Flex flexDirection='row' gap='12px' justifyContent='flex-end'>
              <Flex width='auto'>
                <Dropdown
                  menuPortalTarget={document.body}
                  zIndexProp={1}
                  menuPlacement={'bottom'}
                  height={40}
                  creatable={false}
                  className='dropdown'
                  placeholder={'Assignee'}
                  value={assigneeOptions.filter(
                    option => option?.value === selectedAssignee
                  )}
                  onChange={value => setSelectedAssignee(value?.value || null)}
                  options={assigneeOptions}
                  width={220}
                  disabled={isLoading}
                  isClearable
                />
              </Flex>

              <Button
                icon={<IoMdOptions size={20} />}
                onClick={() => setCustomizeModalOpen(true)}
                disabled={isLoading}
              />
              <div style={{ position: 'relative' }}>
                <Button
                  icon={<FaFilter size={20} />}
                  onClick={() => setIsFilterModalOpen(true)}
                  disabled={isLoading}
                  variant='secondary'
                />
                {!!Object.keys(formattedFilters).length && <FilterDot />}
              </div>
            </Flex>
          </Flex>
        </Flex>

        <div style={{ marginTop: '32px' }}>
          {isLoading && (
            <LoadingIndicator isLoading={isLoading} height='300px' />
          )}
          {!isLoading && (
            <>
              {!leadOpportunitiesMetrics?.length ? (
                <Flex justifyContent='center' alignItems='center'>
                  {searchQuery !== '' ? (
                    <>
                      <ParagraphSmallBold color={colors.secondary[60]}>
                        There are no leads with “{searchQuery}” in the title
                      </ParagraphSmallBold>
                      <ParagraphSmallBold color={colors.secondary[60]}>
                        Try using a different search term
                      </ParagraphSmallBold>
                    </>
                  ) : (
                    <ParagraphSmallBold color={colors.secondary[60]}>
                      Unfortunately, there aren't any leads available at the
                      moment.
                    </ParagraphSmallBold>
                  )}
                </Flex>
              ) : (
                <TableContextProvider
                  total={leadOpportunutiesData?.count}
                  onChange={onPaginationChange}
                  initPage={page}
                  initSize={size}
                >
                  <div style={{ overflowX: 'auto' }}>
                    <Table
                      compact
                      hoverable={false}
                      headers={formatTableHeaders()}
                      rows={rows}
                      fixColumnIndex={[0, 1, 2]}
                      columnWidths={[213, 174, 174]}
                      fixColumnIndicator
                    />
                  </div>
                  <TableFooter>
                    <TablePaginationNew />
                    <TablePaginationSizeNew globalName='lead_opportunuties' />
                  </TableFooter>
                </TableContextProvider>
              )}
            </>
          )}
        </div>
      </MainWrapper>
      {isFilterModalOpen && (
        <ModalFilterLeads
          filters={data?.filters}
          handleModalClose={() => setIsFilterModalOpen(false)}
          onSubmitFiltersHandler={onSubmitFiltersHandler}
          defaultValues={filtersQuery}
        />
      )}
      {isCustomizeModalOpen && (
        <ModalCustomTableFields
          title='Change columns'
          onClose={() => setCustomizeModalOpen(false)}
          allFields={allFields} // All available fields
          initialFields={filteredSelectedFields} // Selected fields
          actionButtons={[
            {
              onClick: onSaveCustomTableFields,
              text: 'Update',
            },
          ]}
        />
      )}
    </Container>
  );
};
