import { theme } from 'lib/style';
import Select from 'react-select';
import styled from 'styled-components/macro';
import { ListItemProps } from './Types';
import { CommonTypography } from 'lib/components/styles/typography';

export const Label = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;

export const ModalItem = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: auto;
  background-color: #fff;
  margin: 32px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContentHeaderWrap = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

export const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  max-width: 100%;
  overflow: none;
`;

export const ContentFooter = styled.div`
  span {
    opacity: 0.75;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.palette.themeDark};
  }
  p {
    opacity: 0.6;
    font-size: 11px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: 0.1px;
    margin-left: 22px;

    b {
      opacity: 0.8;
      font-size: 13px;
      margin-left: 8px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 1em;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const NoteSpan = styled.span`
  float: left;
  width: 590px;
  margin: 16px 0 16px 0;
`;

export const ErrorMessage = styled.div`
  max-width: 660px;
  background-color: ${theme.palette.secondaryRedDanger};
  height: 48px;
  width: 100%;
  margin-top: 5px;
  span {
    display: flex;
    color: ${theme.palette.primaryRedDanger};
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 4;
    letter-spacing: normal;
    text-transform: uppercase;
  }
  svg {
    margin: 8px 16px 0 8px;
  }
`;

export const TableCellHeader = styled.div`
  text-align: left;
`;

export const TableCell = styled.div`
  padding-right: 8px;
  text-align: left;
`;

export const Text = styled.div`
  color: ${theme.palette.buttonDanger};
`;

export const SelectInput = styled(Select)`
  width: 200px;
  border-radius: 4px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  div {
    width: 88px;
    border-right: 1px solid #f6f7f9;
    border-bottom: 1px solid #f6f7f9;
    padding: 5px;
    text-align: center;
    justify-content: center;
    display: flex;
    .span--holder {
      min-width: 50px;
      max-width: 50px;
      min-height: 20px;
      background: #eeeff2;
      border-radius: 5px;
    }
  }
  .bottom--none {
    border-bottom: none;
  }
`;

export const ListContainer = styled.div`
  max-height: 410px;
  overflow-y: scroll;
`;

export const ListItem = styled.div<ListItemProps>`
  background: ${props => (props.bgColor ? props.bgColor : '#F6F7F9')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 10px;
  .item--label {
    display: flex;
    label {
      padding-left: 10px;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  background: #e2ecf5;
  padding: 5px;
`;

export const UserExistLabel = styled.div`
  padding: 5px;
  background: white;
  border-radius: 7px;
  margin-right: 15px;
  color: #e84c3dcc;
  font-weight: 600;
`;

export const UserExistWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SpanSmallBold = styled(CommonTypography).attrs({ as: 'span' })`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;
