import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MainLeadReports } from './MainLeadReports';

export const Main = () => {
  return (
    <Switch>
      <Route path='/reports/leads' component={MainLeadReports} exact={true} />
    </Switch>
  );
};
