import { useCustomVirtualBackgroundQuery } from 'lib/api/customVirtualBackground/useCustomVirtualBackgroundQuery';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { CovideoIcon } from 'lib/components/CovideoIcon';
import {
  ParagraphSmallBold,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { useAuth } from 'lib/context';
import { isFirefox } from 'lib/utils/browserChecks';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'react-covideo-common';
import { IoMdUnlock } from 'react-icons/io';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { VirtualBackgrounds } from './virtualBackgrounds/VirtualBackgrounds';
import { RecordCamLoader } from './RecordCamLoader';
import {
  PrivacyTypes,
  RECORDING_STATUS,
  VirtualBackground,
  VirtualBackgroundTypes,
} from './types';
import { useImageSegmenter } from './useImageSegmenter';
import { saveVirtualBackgroundToStorage } from './utils/virtualBackgrounds';

const VirtualBackgroundsWrapper = styled.div`
  max-width: 770px;
  margin-top: 25px;
`;

const VirtualBackgroundButtonWrapper = styled.div`
  position: absolute;
  bottom: 25px;
  right: 20px;
  z-index: 150;
`;

interface IVirtualBackgroundButtonProps {
  videoRef: React.RefObject<HTMLVideoElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  isSegmenterActive: boolean;
  setIsSegmenterActive: React.Dispatch<React.SetStateAction<boolean>>;
  status: RECORDING_STATUS;
  initialImage: VirtualBackground;
  setIsLoadingVB: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingVb: boolean;
}

const DisabledTooltipPopup = () => {
  const history = useHistory();
  return (
    <>
      <ParagraphSmallBold>Virtual Backgrounds</ParagraphSmallBold>
      <ParagraphSmall>
        Select from a library of high-resolution images, or upload your own for
        extra personalization.
      </ParagraphSmall>
      <Button
        variant='primary'
        text={'Upgrade to Pro'}
        style={{ margin: '0 auto', width: 160 }}
        icon={<IoMdUnlock />}
        onClick={() => {
          history.push('/upgrade-plan');
        }}
      />
    </>
  );
};

export const VirtualBackgroundsButton = ({
  videoRef,
  canvasRef,
  isSegmenterActive,
  setIsSegmenterActive,
  status,
  initialImage,
  setIsLoadingVB,
  isLoadingVb,
}: IVirtualBackgroundButtonProps) => {
  const { userData, whitelabel } = useAuth();
  const [showVirtualBackgrounds, setShowVirtualBackgrounds] = useState(false);

  const [showVirtualBackgroundButton, setShowVirtualBackgroundButton] =
    useState(true);
  const enabledVB =
    (whitelabel.name === WHITELABEL_NAME.COVIDEO ||
      whitelabel.name === WHITELABEL_NAME.APP_2_VID) &&
    !isFirefox;
  const [selectedVirtualBackground, setSelectedVirtualBackground] =
    useState<VirtualBackground>(initialImage);

  const {
    onCreateVirtualBackgroundHandler,
    onDestroyVirtualBackgroundHandler,
    imageRef,
    getCavansBgImage,
  } = useImageSegmenter(canvasRef, videoRef, setIsLoadingVB);

  const { data: customVirtualBackgrounds } = useCustomVirtualBackgroundQuery();

  const startVirualBg = () => {
    if (
      !!canvasRef.current?.width &&
      !!canvasRef.current?.height &&
      !!videoRef.current?.videoWidth &&
      !!videoRef.current.videoHeight
    ) {
      onCreateVirtualBackgroundHandler();
      setIsSegmenterActive(true);
    }
  };

  const stopVirtualBg = () => {
    onDestroyVirtualBackgroundHandler();
    setIsSegmenterActive(false);
  };

  const onChangeImageHandler = (url: string, getCavansBgImage?: () => void) => {
    if (imageRef.current) {
      const imageElement = imageRef.current;
      imageElement.src = `${url}?t=virtual`;
      imageElement.crossOrigin = 'Anonymous';

      const handleImageLoad = () => {
        getCavansBgImage?.();
        if (!isSegmenterActive) {
          startVirualBg(); // Make sure this is spelled correctly
        }
      };

      // Assign the onload handler
      imageElement.onload = handleImageLoad;

      // Cleanup function to remove the onload event handler
      return () => {
        imageElement.onload = null;
      };
    }
  };

  const onSelectImageSaveToLocalStorageHandler = ({
    url,
    type,
    index,
    privacy,
    id,
  }: VirtualBackground) => {
    const saveParams = { url, type, index, privacy, id };
    setSelectedVirtualBackground(saveParams);
    saveVirtualBackgroundToStorage(saveParams, userData.id);
  };

  const onVirtualBacgroundClickHandler = () => {
    showVirtualBackgroundButton &&
      setShowVirtualBackgrounds(!showVirtualBackgrounds);
  };

  useEffect(() => {
    if (userData.user?.packageDetails?.id == 0) {
      setShowVirtualBackgroundButton(true);
    } else if (userData.user?.packageDetails?.products) {
      const status = checkIfFeatureIsEnabled(
        userData,
        productFeature.VIRTUAL_BACKGROUNDS
      );
      setShowVirtualBackgroundButton(status);
    }
  }, []);
  useEffect(() => {
    if (enabledVB && status === RECORDING_STATUS.CAMERA_READY) {
      const { url } = selectedVirtualBackground;
      if (!!url && imageRef.current) {
        const imageElement = imageRef.current;
        imageElement.crossOrigin = 'Anonymous';
        imageElement.src = `${url}?t=virtual`;

        imageElement.onload = async () => {
          if (!isSegmenterActive) {
            getCavansBgImage?.();
            startVirualBg();
          }
        };

        // Cleanup function to remove the onload event handler
        return () => {
          imageElement.onload = null;
        };
      }
    }
  }, [enabledVB, status]);

  useEffect(() => {
    return () => {
      onDestroyVirtualBackgroundHandler();
    };
  }, []);

  return (
    <>
      {!showVirtualBackgrounds && enabledVB && (
        <>
          {isLoadingVb && (
            <RecordCamLoader text='Applying Virtual Background' />
          )}
          {status === RECORDING_STATUS.CAMERA_READY && (
            <>
              {!isLoadingVb && (
                <VirtualBackgroundButtonWrapper>
                  <Tooltip
                    position='bottom'
                    children={
                      <Button
                        icon={<CovideoIcon name='virtual-bg' height={'16px'} />}
                        variant='secondary'
                        disabled={!showVirtualBackgroundButton}
                        text='Virtual Backgrounds'
                        onClick={onVirtualBacgroundClickHandler}
                      />
                    }
                    popup={
                      !showVirtualBackgroundButton ? (
                        <DisabledTooltipPopup />
                      ) : null
                    }
                    extendStyles={{
                      popup: !showVirtualBackgroundButton
                        ? {
                            width: 210,
                            gap: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px 24px',
                          }
                        : { display: 'none' },
                    }}
                  />
                </VirtualBackgroundButtonWrapper>
              )}
            </>
          )}
        </>
      )}
      <>
        <VirtualBackgroundsWrapper
          hidden={
            !showVirtualBackgrounds || status === RECORDING_STATUS.RECORDING
          }
        >
          <VirtualBackgrounds
            selectedVirtualBackgroundUrl={selectedVirtualBackground.url}
            customVirtualBackgrounds={customVirtualBackgrounds}
            onMainButtonClick={() => {
              setShowVirtualBackgrounds(false);
            }}
            onSelect={(
              url: string = '',
              type: VirtualBackgroundTypes,
              index: number,
              privacy: PrivacyTypes = 'PUBLIC',
              id: string = ''
            ) => {
              const saveParams = {
                url,
                type,
                index,
                privacy,
                id,
              };
              if (type === 'none') {
                stopVirtualBg();
                onSelectImageSaveToLocalStorageHandler(saveParams);
                return;
              }
              onSelectImageSaveToLocalStorageHandler(saveParams);
              onChangeImageHandler(url, getCavansBgImage);
            }}
          />
        </VirtualBackgroundsWrapper>
      </>

      <img
        ref={imageRef}
        crossOrigin='anonymous'
        alt='Segmentation'
        style={{ display: 'none' }}
      />
    </>
  );
};
