import * as React from 'react';
import { ParagraphSmall } from 'lib/components/styles/typography';
import {
  Content,
  ContentBody,
  ContentHeader,
  ContentHeaderWrap,
  ErrorMessage,
  Form,
  ModalItem,
} from 'lib/components/modal/importModal/Components';
import { CloseButton } from 'lib/components';
import DragAndDrop from 'lib/components/inputs/DragAndDrop';

import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdFileDownload } from 'react-icons/md';
import styled from 'styled-components/macro';
import { exportCSVFile } from 'lib/utils/functions';
import { IoMdClose } from 'react-icons/io';

const ButtonWrapper = styled.div`
  margin-top: 23px;
  margin-bottom: 23px;
  text-align: center;
`;

const parseOptions = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
};

const exportHeader = {
  firstName: 'First_Name',
  lastName: 'Last_Name',
  email: 'Email',
  username: 'Username_Email_used_if_blank',
  phone: 'Phone_Number',
  cellPhone: 'Cell_Phone',
  userType: 'Choose_Admin_Supervisor_User',
};

type Props = {
  title: string;
  text: string;
  disabled: boolean;
  uploadError: boolean;
  errorMessage: string;
  onUpload(csv: string[][], fileInfo: { name: string }): void;
  handleModalClose(): void;
  isEmail2Mandatory?: boolean;
};

export const AddBulkUser = ({
  title,
  disabled,
  text,
  uploadError,
  errorMessage,
  handleModalClose,
  onUpload,
  isEmail2Mandatory = false,
}: Props) => {
  const downloadTemplate = async () => {
    let headers: { [key: string]: string } = exportHeader;

    if (isEmail2Mandatory) {
      headers = {
        ...headers,
        email2: 'Email_2',
      };
    }
    exportCSVFile(headers, [], 'import_users');
  };
  return (
    <ModalItem>
      <Content style={{ width: '530px' }}>
        <ContentHeaderWrap>
          <ContentHeader>{title}</ContentHeader>
          <CloseButton
            padding='24px 10px 0px'
            disabled={disabled}
            onClick={handleModalClose}
          />
        </ContentHeaderWrap>
        <ContentBody>
          <ParagraphSmall>{text}</ParagraphSmall>
          <ButtonWrapper>
            <Button
              variant='secondary'
              icon={<MdFileDownload />}
              text='Download Template'
              onClick={downloadTemplate}
            />
          </ButtonWrapper>

          <Form
            onSubmit={e => {
              e.stopPropagation();
            }}
          >
            <>
              <DragAndDrop
                showIcon={false}
                onFileLoaded={onUpload}
                parserOptions={parseOptions}
                button={true}
                width={528}
                height={232}
                isMultiple={false}
              />
              {uploadError && (
                <ErrorMessage>
                  <span>
                    <IoMdClose
                      size={32}
                      color={theme.palette.primaryRedDanger}
                    />
                    {errorMessage}
                  </span>
                </ErrorMessage>
              )}
            </>
          </Form>
        </ContentBody>
      </Content>
    </ModalItem>
  );
};
