import React, { useEffect, useState } from 'react';
import { ChangeUserCustomerModal } from './ChangeUserCustomerModal';
import { SuperAdminUser } from 'lib/context';
import { ChangeUserStatusModal } from './ChangeUserStatusModal';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { ChangeAutomotiveRoleModal } from 'app/pages/management/components/ChangeAutomotiveRoleModal';
import { ChangeUsersAccessRoleModal } from 'app/pages/management/components/ChangeUsersAccessRoleModal';
import { useBulkEditUserAccessRoleMutation } from 'lib/api/superadmin/useBulkEditUserAccessRoleMutation';
import { AccessRole } from 'lib/const';
import { setCommonPropertyForSelectedUsers } from 'lib/utils/functions';
import { FastPasswordResetModal } from 'app/pages/management/components/FastPasswordResetModal';

export const SelectedUsersActions = ({
  selectedUsers,
  showChangeCustomerButton,
  onSuccessfullUpdate,
  showEditRoleButton,
  changeRoleInfoText,
}: {
  selectedUsers: SuperAdminUser[];
  showChangeCustomerButton: boolean;
  onSuccessfullUpdate(): void;
  showEditRoleButton?: boolean;
  changeRoleInfoText?: string;
}) => {
  const [showUserCustomerPopup, setShowUserCustomerPopup] = useState(false);
  const [showFastPasswordResetModal, setShowFastPasswordResetModal] =
    useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [showModalChangeAutomotiveRole, setShowModalChangeAutomotiveRole] =
    useState(false);
  const [commonUserAutomotiveRole, setCommonUserAutomotiveRole] = useState('');
  const [commonUserType, setCommonUserType] = useState(
    AccessRole.USER as string
  );
  const [showEditUserTypeModal, setShowEditUserTypeModal] = useState(false);
  const { mutateAsync: updateAccessRole, isLoading: isUpdating } =
    useBulkEditUserAccessRoleMutation();

  const handleUpdateUsersAccessRole = async (
    userIds: number[],
    accessRole: number
  ) => {
    await updateAccessRole({
      userIds,
      access: accessRole,
    });
  };

  useEffect(() => {
    if (!selectedUsers.length) {
      return;
    }
    showEditRoleButton &&
      setCommonPropertyForSelectedUsers(
        selectedUsers,
        'automotiveRole',
        setCommonUserAutomotiveRole
      );
    setCommonPropertyForSelectedUsers(
      selectedUsers,
      'access',
      setCommonUserType
    );
  }, [showEditRoleButton, selectedUsers]);

  return (
    <>
      <Gap gap='8px'>
        <Button
          onClick={() => {
            setShowStatusPopup(true);
          }}
          text='Change Status'
          variant='secondary'
        />
        <Button
          onClick={() => {
            setShowEditUserTypeModal(true);
          }}
          text='Edit User Type'
          variant='secondary'
        />
        {showChangeCustomerButton && (
          <Button
            onClick={() => {
              setShowUserCustomerPopup(true);
            }}
            text='Change Customer'
            variant='secondary'
          />
        )}
        <Button
          onClick={() => {
            setShowFastPasswordResetModal(true);
          }}
          text='Fast Password Reset'
          variant='secondary'
        />

        {showEditRoleButton && (
          <Button
            onClick={() => {
              setShowModalChangeAutomotiveRole(true);
            }}
            text='Edit Role'
            variant='secondary'
          />
        )}
      </Gap>
      {showUserCustomerPopup && showChangeCustomerButton && (
        <ChangeUserCustomerModal
          selectedUsers={selectedUsers}
          handleModalClose={shouldRefresh => {
            setShowUserCustomerPopup(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
        />
      )}
      {showFastPasswordResetModal && (
        <FastPasswordResetModal
          selectedUserIds={selectedUsers.map(user => user.id)}
          handleModalClose={shouldRefresh => {
            setShowFastPasswordResetModal(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
        />
      )}
      {showStatusPopup && (
        <ChangeUserStatusModal
          selectedUsers={selectedUsers}
          handleModalClose={shouldRefresh => {
            setShowStatusPopup(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
        />
      )}
      {showModalChangeAutomotiveRole && (
        <ChangeAutomotiveRoleModal
          selectedUsers={selectedUsers
            ?.filter(user => !!user.automotiveAccess)
            .map(user => user.id)}
          changeRoleInfoText={changeRoleInfoText}
          selectedAutomotiveRole={parseInt(commonUserAutomotiveRole, 10)}
          handleModalClose={shouldRefresh => {
            setShowModalChangeAutomotiveRole(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
        />
      )}
      {showEditUserTypeModal && (
        <ChangeUsersAccessRoleModal
          selectedUsers={selectedUsers.map(user => user.id)}
          isLoading={isUpdating}
          commonUserType={parseInt(commonUserType, 10)}
          handleModalClose={shouldRefresh => {
            setShowEditUserTypeModal(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
          onPrimaryButtonClick={handleUpdateUsersAccessRole}
        />
      )}
    </>
  );
};
