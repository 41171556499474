import { theme } from 'lib/style';
import React from 'react';
import { Tooltip } from 'react-covideo-common';
import styled from 'styled-components/macro';

type BouncedProps = {
  color: string;
};

const BouncedContainer = styled.div<BouncedProps>`
  color: ${props => props.color};
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
`;

const BouncedTooltip = styled.div`
  max-width: 300px;
  white-space: wrap;
  padding: 10px;
  line-height: 20px;
`;

const Bounced = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #11a694;
  word-break: break-all;
`;

type BouncedEmailProps = {
  email: string;
  bouncedEmail: string;

  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
};

const BouncedEmail = ({
  email,
  bouncedEmail,
  tooltipPosition,
}: BouncedEmailProps) => {
  let tooltipText = <></>;
  let color = '';

  if (!bouncedEmail || !email) return <></>;

  if (email === bouncedEmail) {
    tooltipText = (
      <BouncedTooltip>
        Email invitation has bounced for <Bounced>{bouncedEmail}</Bounced>.
        Please update the email address.
      </BouncedTooltip>
    );
    color = theme.palette.red100;
  } else if (email !== bouncedEmail) {
    tooltipText = (
      <BouncedTooltip>
        Email address was updated from <Bounced>{bouncedEmail}</Bounced> to{' '}
        <Bounced>{email}</Bounced>. Please resend the invitation.
      </BouncedTooltip>
    );

    color = theme.palette.covideoOrange100;
  }

  return (
    <Tooltip popup={tooltipText} position={tooltipPosition || 'top'}>
      <BouncedContainer color={color}>Bounced</BouncedContainer>
    </Tooltip>
  );
};

export default BouncedEmail;
