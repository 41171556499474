import { Field, FieldProps, useFormikContext } from 'formik';
import { CheckboxInput } from 'lib/components';

import { Gap } from 'lib/components/styles/layout';
import React from 'react';
import {
  CheckboxLabel,
  CheckboxWrapper,
  Section,
  SectionTitle,
} from '../index.styled';
import { SalesPagesValues } from './types';

export const SalesPagesSocialIconsForm = () => {
  const { setFieldValue, values } = useFormikContext<SalesPagesValues>();

  return (
    <Section>
      <SectionTitle>Social Icons</SectionTitle>
      <Gap>
        <Field name={'socialIcons'}>
          {({ field }: FieldProps) => {
            return (
              <CheckboxWrapper>
                <CheckboxInput
                  {...field}
                  checked={field.value}
                  onChange={() => {
                    setFieldValue(`socialIcons`, !values.socialIcons);
                  }}
                />
                <CheckboxLabel>Show/hide</CheckboxLabel>
              </CheckboxWrapper>
            );
          }}
        </Field>
      </Gap>
    </Section>
  );
};
