import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
//pages
import { hasChosenLocation } from 'lib/utils/locations';
import { AccountRoutes } from '../pages/account';
import { AdminRoutes } from '../pages/admin';
import { CompanyRoutes } from '../pages/company';
import { Contacts } from '../pages/contacts';
import { ConversationRoutes } from '../pages/conversations/routes';
import { DesignRoutes } from '../pages/design';
import { Inventory } from '../pages/inventory';
import { LibraryRoutes } from '../pages/library';
import { LinkedinRoutes } from '../pages/linkedin';
import { Login } from '../pages/login';
import { LocationSelect } from '../pages/multilocation/components';
import { NotFoundTemplate } from '../pages/notFound';
import { NotificationRoutes } from '../pages/notifications';
import { Onboarding } from '../pages/onboarding';
import { OnboardingFreemiumRoutes } from '../pages/onboardingFreemium';
import { Main } from '../pages/onboardingFreemium/record/Main';
import { RegisterRoutes } from '../pages/register/routes';
import { RepairOrders } from '../pages/repairOrders';
import { ReportRoutes } from '../pages/reports';
import { ResetPassword } from '../pages/resetPassword';
import { Signup } from '../pages/signup';
import { SmsRoutes } from '../pages/sms';
import { UpgradeRoutes } from '../pages/upgrade';
import { VideoRoutes } from '../pages/video';

import { WellDone } from 'app/pages/onboardingFreemium/wellDone';
import { useGetLocationsQuery } from 'lib/api/locations/getUserLocations';
import { DocumentHead } from 'lib/components';
import { useAuth, WHITELABELINFO } from 'lib/context';
import {
  checkIfMobile,
  checkOnboardingPhaseForFreemium,
} from 'lib/utils/functions';
import { IntegrationRoutes } from 'app/pages/integrations/routes';
import { EmailVerification } from 'app/pages/signup/components/EmailVerification';
import { WelcomeMobile } from 'app/pages/onboardingFreemium/wellDone/WelcomeMobile';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { RecordingRoutes } from 'app/pages/recordv1/routes';
import { CustomerRoutes } from 'app/pages/leads/routes';

export const MainRoutes = () => {
  const chosenLocation = hasChosenLocation();
  const { whitelabel, userData } = useAuth();
  const freemiumOnboardingRedirect = checkOnboardingPhaseForFreemium(
    userData,
    '0'
  );
  const { pathname } = useLocation();
  const isSignupRoute = pathname.includes('signup');

  const { data: locations } = useGetLocationsQuery(
    userData.isMultiLocation,
    userData.id
  );
  const isTrialAccount =
    typeof userData.trialAccount === 'string'
      ? userData.trialAccount === 'true'
      : !!userData.trialAccount;

  const shouldGoToLocations =
    userData.isMultiLocation &&
    !chosenLocation &&
    locations.length &&
    userData.verified.toString() === '1';

  const showEmailVerification = isTrialAccount && !userData.emailVerified;

  const isMobile = checkIfMobile();

  const showWelcomeMobileForAllRoutes =
    isMobile && whitelabel.name === WHITELABEL_NAME.COVIDEO;

  if (showEmailVerification) {
    return (
      <Switch>
        <Route path='/email-verification' component={EmailVerification} />
        <Redirect to='/email-verification' />
      </Switch>
    );
  }

  if (showWelcomeMobileForAllRoutes && !isSignupRoute) {
    return (
      <Switch>
        <Route path='*' component={WelcomeMobile} />
      </Switch>
    );
  }

  return (
    <>
      {freemiumOnboardingRedirect && (
        <Switch>
          <Route path='*' component={OnboardingFreemiumRoutes} />
        </Switch>
      )}
      {!freemiumOnboardingRedirect && (
        <Switch>
          {/* External routes */}
          <Route
            path='/Email_RecordVideo'
            component={() => {
              window.location.href = `https://${whitelabel.domainV2}/backoffice/Email_RecordVideo.php`;
              return null;
            }}
          />
          {/* HELP */}
          <Route
            path='/Help'
            component={() => {
              window.open(
                whitelabel.supportURL || 'https://support.covideo.com',
                '_blank'
              );
              return null;
            }}
          />
          {/* Sign out */}
          <Route
            path='/Sign_Out'
            component={() => {
              localStorage.setItem(WHITELABELINFO, '');
              window.location.href = `https://${whitelabel.domainV2}/backoffice/incs/logout.php`;
              return null;
            }}
          />
          <Route path='/login' component={Login} />
          <Route
            path='/verify-reset-password/:token'
            component={ResetPassword}
          />
          <Route path='/signup' component={Signup} />
          <Route path='/email-verification'>
            <Redirect to='/home' />
          </Route>

          {/* TOOLS */}
          <Route
            path='/reset-password'
            component={() => {
              window.location.href = `https://${whitelabel.domainV2}/reset-password`;
              return null;
            }}
          />

          {/* LOCATIONS */}
          <Route path='/location'>
            <>
              <DocumentHead title='Locations' />
              <LocationSelect />
            </>
          </Route>
          {shouldGoToLocations && <Redirect to='/location' />}

          {/* ONBOARDING */}
          <Route path='/welcome' component={Onboarding} />
          <Route path='/integrations' component={IntegrationRoutes} />
          <Route path='/design' component={DesignRoutes} />
          <Route path='/repair-orders' component={RepairOrders} />
          <Route path='/contacts' component={Contacts} />
          <Route path='/company' component={CompanyRoutes} />
          <Route path='/classic' component={VideoRoutes} />
          <Route path='/record' component={RecordingRoutes} />
          <Route path='/reports' component={ReportRoutes} />
          <Route path='/register' component={RegisterRoutes} />
          <Route path='/linkedin' component={LinkedinRoutes} />
          <Route path='/sms' component={SmsRoutes} />
          <Route path='/conversations' component={ConversationRoutes} />
          <Route path='/(users|billing|profile)' component={AccountRoutes} />
          <Route path='/notifications' component={NotificationRoutes} />
          <Route
            path='/(home|screenshots|files|boards|meetings|review-videos|video-requests|codirect|vdps|guides|review-guides)'
            component={LibraryRoutes}
          />
          <Route
            path='/(upgrade|upgrade-plan)'
            component={
              isTrialAccount && userData.isAutomotive
                ? () => {
                    window.location.href = `https://dealerservices.covideo.com/contact-us?utm_source=freetrial`;
                    return null;
                  }
                : UpgradeRoutes
            }
          />
          <Route path='/inventory' component={Inventory} />
          <Route path='/admin' component={AdminRoutes} />
          <Route path='/customers' component={CustomerRoutes} />
          <Route
            exact={true}
            path='/onboarding-freemium/well-done'
            component={WellDone}
          />
          <Route
            exact={true}
            path='/onboarding-freemium/record'
            component={Main}
          />

          <Route path='/' component={LibraryRoutes} exact>
            <Redirect to='/home' />
          </Route>
          <Route path='*' component={NotFoundTemplate} />
        </Switch>
      )}
    </>
  );
};
