import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

interface IDeleteLeadParams {
  leadGroupIds: number[];
}

const deleteLeadGroup = async (
  params: IDeleteLeadParams
): Promise<{ message: string }> => {
  const { leadGroupIds } = params;
  return await EXPRESS_API.delete(`/lead-groups`, { data: leadGroupIds });
};

export const useDeleteLeadGroupMutation = () => {
  const { showError } = useToastError();
  return useMutation(deleteLeadGroup, {
    onSuccess: (_, variables) => {
      const groupCount = variables.leadGroupIds.length;
      const message =
        groupCount === 1
          ? 'Group has been deleted!'
          : `${groupCount} groups have been deleted!`;
      successToast({ title: message });
    },
    onError: (error, variables) => {
      const groupCount = variables.leadGroupIds.length;
      const message =
        groupCount === 1
          ? 'An error occurred while trying to delete the group!'
          : 'An error occurred while trying to delete the groups!';
      showError(error, message);
    },
  });
};
