import { QueryListParams } from '../types';

export const leadsKeys = {
  all: () => ['leads'] as const,
  lists: () => [...leadsKeys.all(), 'list'] as const, // list of all items
  list: (filters: QueryListParams) =>
    [...leadsKeys.lists(), { ...filters }] as const, // list of items with specific filters,
  details: () => [...leadsKeys.all(), 'detail'] as const, // all single items we visit
  detail: (conversationId: string) =>
    [...leadsKeys.details(), conversationId] as const, // single item,
  stats: () => ['stats'] as const,
};
