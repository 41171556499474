import { useFormikContext } from 'formik';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphExtraSmall } from 'lib/components/styles/typography';
import React from 'react';
import { Button } from 'react-covideo-common';
import { MdUpload, MdDeleteForever } from 'react-icons/md';
import { Label, UploadBtnWrapper, UploadInput } from '../index.styled';
import { SalesPagesValues } from './types';

export const UploadImage = ({
  name,
  label,
}: {
  label: string;
  name: 'footerImage' | 'bodyBgImage' | 'logoImage';
}) => {
  const { setFieldValue, values } = useFormikContext<SalesPagesValues>();
  const url = values?.[name].url;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setFieldValue(name, { file: file, url: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Label htmlFor={name}>{label}</Label>
      <Gap>
        <UploadBtnWrapper>
          <UploadInput
            id={name}
            name={name}
            type='file'
            onChange={e => handleFileChange(e)}
            accept={'image/png, image/jpeg, image/svg+xml'}
          />
          <MdUpload size={24} />
          {url ? 'Replace image...' : 'Update image...'}
        </UploadBtnWrapper>
        <Button
          variant='destructive'
          icon={<MdDeleteForever size={22} />}
          onClick={() => {
            if (!url) return;
            const current_file = document.getElementById(
              name
            ) as HTMLInputElement;
            if (!current_file) return;
            current_file.value = '';
            setFieldValue(name, { url: '', file: null });
          }}
          disabled={!url}
        />
        {url ? (
          <img src={url} alt='logo ' style={{ maxHeight: 40 }} />
        ) : (
          <ParagraphExtraSmall>No image uploaded</ParagraphExtraSmall>
        )}
      </Gap>
    </>
  );
};
