import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';

type Props = {
  copy: boolean;
  videoFolder: string;
  ids: number[];
  fromCompany?: boolean;
  successMessage?: string;
};
const moveCopyVideos = async (data: Props) => {
  const { successMessage: _, ...params } = data;
  const response = await EXPRESS_API.put('/videos/move', params);
  return response.data;
};
export const useVideosMoveCopyMultipleMutation = () => {
  return useMutation(moveCopyVideos, {
    onSuccess: (
      data: { fulfilled: number[]; rejected: number[] },
      variables: Props
    ) => {
      const fulfilled = data?.fulfilled?.length || 0;
      if (!fulfilled) {
        errorToast({
          title: 'An error occurred while moving/coping videos.',
        });

        return;
      }
      successToast({
        title:
          variables?.successMessage ||
          `${fulfilled} out of ${variables.ids.length} Videos copied/moved successfully.`,
      });
    },
    onError: () => {
      errorToast({ title: 'An error occurred while moving/coping videos.' });
    },
  });
};
