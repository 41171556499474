import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { EnhancedColorPicker } from '../../../../video/videoDetails/components';
import { useLandingPageBuilderContext } from '../../context';
import { calculateHexAlpha } from 'lib/utils/annotations';
import { ColorResult } from 'react-color';
import {
  defaultFontFamilyOptions,
  fontSizeOptions,
  LpbTextEditor,
  ResetIcon,
  FontFamilyOption,
  FontStyleOption,
  FontFamilyVariants,
} from '..';
import Select, { components, OptionProps } from 'react-select';
import { useGetGoogleFontsQuery } from 'lib/api/googleFonts/getGoogleFonts';
import { convertToHttps } from 'lib/utils/functions';

interface Props {
  width?: string;
}

interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px 10px 0px 10px')};
  flex-direction: row;
  flex-wrap: wrap;
  .pointer {
    cursor: pointer;
  }
  .widthFitContent {
    width: fit-content;
    margin: 0;
    height: 24px;
    line-height: 1.5;
  }
  box-sizing: border-box;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
  // margin: 25px auto 0 auto;
`;
const EditorWrapper = styled.div`
  height: 160px;
  border-radius: 4px;
  & > div {
    height: 160px;
    border-radius: 4px;
  }
  .demo-toolbar {
    z-index: 2;
    border: 1px solid rgb(223, 227, 230);
    border-radius: 4px;
    border-bottom: none;
    position: relative;
    height: 30px;
    max-width: 578px;
    padding: 6px 5px 0;
    div {
      max-height: 24px;
      font-size: 12px;
    }
    .toolbar-history {
      height: 24px;
      div {
        width: 22px;
        height: 22px;
        padding: 0;
        margin: 0 2px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .toolbar-font-family {
      height: 24px;
      width: 88px;
    }
    .toolbar-custom-icon {
      width: 22px;
      height: 22px;
      padding: 0;
      margin: 0 2px;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
  .demo-editor {
    border-radius: 4px;
    min-height: 148px !important;
    padding: 40px 12px 12px 12px !important;
    position: relative;
    top: -42px;
    max-height: 160px;
  }
  .rdw-image-modal {
    div {
      max-height: 200px !important;
    }
    top: 28px;
    max-height: 196px !important;
  }
  .rdw-emoji-modal {
    top: 28px;
    max-height: 196px !important;
  }
  .rdw-link-modal {
    top: 28px;
    max-height: 196px !important;
  }
  margin: 5px 0 0;
`;
const SelectInput = styled(Select)<Props>`
  width: ${props => (props.width ? props.width : '100%')};
  height: 40px;
  border-radius: 4px;
  margin-top: 5px;
  &:focus {
    outline: 0;
  }
`;
function replaceHttpWithHttps(url: string) {
  if (url.includes('http://')) {
    return url.replace(/http:\/\//g, 'https://');
  }
  return url;
}
export const GlobalFontStyles = createGlobalStyle<{
  fonts: FontFamilyOption[];
}>`
  ${({ fonts }) => {
    return fonts
      ? fonts
          .map((font: FontFamilyOption) => {
            const fontURL = font.files.regular || font.files.normal;
            const modifiedURL = replaceHttpWithHttps(fontURL as string);
            return `
          @font-face {
            font-family: '${font.value}';
            src: url('${modifiedURL}') format('truetype');
    }
          `;
          })
          .join('')
      : '';
  }}
`;

export const CustomLandingPageFontOption = ({
  children,
  ...props
}: OptionProps<FontFamilyOption, false>) => {
  const { data }: { data: FontFamilyOption } = props;
  const isRemote = Object.values(data?.files)?.[0]?.includes('http') || false;
  const fontFamily = isRemote
    ? `${data.value}`
    : `${data.value}-${data?.variants?.[0] || 'normal'}`;

  return (
    <components.Option {...props}>
      <p style={{ fontFamily, margin: 0, fontSize: '16px' }}>{children}</p>
    </components.Option>
  );
};

export const EditTextSection = () => {
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    setElementToEdit,
    elementSectionKey,
    elementToEdit,
    elementIndex,
    selectedStyleProperties,
  } = useLandingPageBuilderContext();

  const [allFontFamilyOptions, setAllFontFamilyOptions] = React.useState<
    FontFamilyOption[]
  >([...defaultFontFamilyOptions]);
  const [allFontStyleOptions, setAllFontStyleOptions] = React.useState<
    FontStyleOption[]
  >([]);

  const { data: googleFonts } = useGetGoogleFontsQuery();

  React.useEffect(() => {
    let googleFontsMapped: FontFamilyOption[] = [];
    let newFontFamilyOptions: FontFamilyOption[] = [
      ...defaultFontFamilyOptions,
    ];

    if (googleFonts && googleFonts.items) {
      // Map the google fonts list response
      googleFontsMapped = googleFonts.items.map((font: any) => {
        return {
          value: font.family,
          label: font.family,
          variants: font.variants,
          files: font.files,
        };
      });

      // Remove fonts that match our default ones
      const googleFontsFiltered = googleFontsMapped.filter(elem => {
        return newFontFamilyOptions.every(ele => {
          return ele.value !== elem.value;
        });
      });

      // Sort all fonts in ascending order
      newFontFamilyOptions = [
        ...newFontFamilyOptions,
        ...googleFontsFiltered,
      ].sort((a, b) => (a.value > b.value ? 1 : -1));

      setAllFontFamilyOptions(newFontFamilyOptions);
    }

    const fontFamily =
      sectionDetails?.textFont || selectedStyleProperties?.fontFamily;
    if (fontFamily) {
      const selectedFontFamily = newFontFamilyOptions.find(
        (o: FontFamilyOption) => {
          const fontFamilyList = fontFamily.split('-');
          const fontFamilyValue = fontFamilyList[0];
          return o.value == fontFamilyValue;
        }
      );

      if (selectedFontFamily) {
        const initialFontStyleOptions: FontStyleOption[] =
          selectedFontFamily.variants.map((variant: FontFamilyVariants) => {
            return {
              value: variant,
              label: variant,
              url: selectedFontFamily.files[variant] || '',
            };
          });

        setAllFontStyleOptions(initialFontStyleOptions || []);
        return;
      }
    }
  }, [googleFonts]);

  const data = { ...selectedLayoutData };
  if (!elementSectionKey || !data) {
    return <></>;
  }

  const sectionDetails = elementToEdit;
  if (!sectionDetails) {
    return <></>;
  }

  let isElementCase = false;
  if (
    elementIndex !== null &&
    elementIndex !== '' &&
    elementIndex !== undefined
  ) {
    isElementCase = true;
  }
  let isVisible = true;
  if (isElementCase) {
    isVisible = data[elementSectionKey].childSections[elementIndex]?.isVisible;
  } else {
    isVisible = data[elementSectionKey]?.isVisible;
  }
  if (!isVisible) {
    return <></>;
  }

  let fontStyle =
    sectionDetails?.textWeight ||
    selectedStyleProperties?.fontWeight ||
    'normal';
  let fontFamily =
    sectionDetails?.textFont || selectedStyleProperties?.fontFamily;
  let fontSize = sectionDetails?.textSize || '16px';
  let fontColor =
    sectionDetails?.textColor || selectedStyleProperties?.textColor;

  const handleChangeFontColor = (color: ColorResult) => {
    const hexAlpha: string = calculateHexAlpha(color);
    sectionDetails.textColor = hexAlpha;
    fontColor = hexAlpha;
    updateContext();
  };

  const handleChangeFontStyle = (style: string, url: string) => {
    sectionDetails.textWeight = style;
    sectionDetails.fontUrl = convertToHttps(url);

    fontStyle = style;
    let newFontFamily = fontFamily.split('-');
    newFontFamily = newFontFamily[0];
    newFontFamily += '-' + style;
    updateContext();
    handleChangeFontFamily(newFontFamily);
  };

  const handleChangeFontFamily = (fontFamilyName: string) => {
    if (!fontFamilyName) {
      return;
    }

    if (fontFamilyName.indexOf('-') == -1) {
      fontFamilyName += '-' + fontStyle;
    }
    sectionDetails.textFont = fontFamilyName;
    fontFamily = fontFamilyName;
    updateContext();
  };

  const handleChangeFontSize = (size: string) => {
    sectionDetails.textSize = size;
    fontSize = size;
    updateContext();
  };

  const handleTextEditorChange = (editorData: any) => {
    sectionDetails.textContent = encodeURIComponent(editorData || '');
    updateContext();
  };

  const updateContext = () => {
    if (isElementCase) {
      data[elementSectionKey].childSections[elementIndex] = sectionDetails;
    } else {
      data[elementSectionKey] = sectionDetails;
    }

    setElementToEdit(sectionDetails);
    setSelectedLayoutData(data);
  };

  const reset = () => {
    fontFamily = selectedStyleProperties.fontFamily;
    fontStyle = selectedStyleProperties.fontWeight;
    fontSize = sectionDetails.textSize || '16px';
    fontColor = selectedStyleProperties.textColor;
    sectionDetails.textSize = fontSize;
    sectionDetails.textFont = fontFamily;
    sectionDetails.textWeight = fontStyle;
    sectionDetails.textColor = fontColor;
    updateContext();
  };

  return (
    <>
      <GlobalFontStyles fonts={allFontFamilyOptions} />
      <Row>
        <TopLabel>Content</TopLabel>
        <EditorWrapper>
          <LpbTextEditor
            height={'160px'}
            onTextEditorChange={data => {
              handleTextEditorChange(data);
            }}
            initialContent={decodeURIComponent(
              elementToEdit?.textContent || ''
            )}
            placeholder={'Enter your message...'}
          />
        </EditorWrapper>
      </Row>
      <Row>
        <TopLabel className={'widthFitContent'}>Text style</TopLabel>
        <ResetIcon
          className={'pointer'}
          onClick={() => {
            reset();
          }}
        />
        <SelectInput
          styles={{
            control: (base: any) => ({ ...base, height: '40px' }),
            indicatorSeparator: () => ({ display: 'none' }),
          }}
          options={allFontFamilyOptions}
          menuPlacement={'bottom'}
          maxMenuHeight={200}
          value={allFontFamilyOptions.find(o => {
            fontFamily = fontFamily.split('-');
            fontFamily = fontFamily[0];
            return o.value == fontFamily;
          })}
          components={{
            Option: CustomLandingPageFontOption,
          }}
          onChange={(option: any) => {
            handleChangeFontFamily(option.value);
            if (option.variants) {
              const newStyleOptions: FontStyleOption[] = option.variants.map(
                (variant: FontFamilyVariants) => {
                  return {
                    label: variant,
                    value: variant,
                    url: option.files[variant],
                  };
                }
              );
              setAllFontStyleOptions(newStyleOptions);
              // check if the already selected value is present in options
              // if not, select the first one
              const selectedStyleOption = newStyleOptions.find(
                o => o.value == fontStyle
              );
              if (!selectedStyleOption) {
                handleChangeFontStyle(
                  newStyleOptions[0].value,
                  newStyleOptions[0].url
                );
                return;
              }
              handleChangeFontStyle(
                selectedStyleOption.value,
                selectedStyleOption.url
              );
            }
          }}
        />
        <SelectInput
          styles={{
            control: (base: any) => ({ ...base, height: '40px' }),
            indicatorSeparator: () => ({ display: 'none' }),
          }}
          menuPlacement={'bottom'}
          width={'65%'}
          options={allFontStyleOptions}
          value={allFontStyleOptions.find(o => o.value == fontStyle)}
          onChange={(option: any) => {
            handleChangeFontStyle(option.value, option.url);
          }}
        />
        <SelectInput
          styles={{
            control: (base: any) => ({ ...base, height: '40px' }),
            indicatorSeparator: () => ({ display: 'none' }),
          }}
          menuPosition='fixed'
          menuPlacement={'bottom'}
          width={'35%'}
          options={fontSizeOptions}
          value={fontSizeOptions.find(o => o.value == fontSize)}
          onChange={(option: any) => {
            handleChangeFontSize(option.value);
          }}
        />
        <EnhancedColorPicker
          disableZIndex={true}
          handleColorChange={handleChangeFontColor}
          playerColor={fontColor || '#ffffff'}
          wrapperWidth={'100%'}
          wrapperMargin={'10px auto 0 auto'}
        />
      </Row>
    </>
  );
};
