import { EXPRESS_API } from 'configs/api/expressApi';

enum optionSelections {
  None = 0,
  SMS2500 = 1,
  SMS5k = 2,
  SMS7k = 3,
}

export const GetSMSStats = async () => {
  const response = await EXPRESS_API.get('/addons/sms/check');

  return {
    activated: response.data.activated,
    smsPackage: response.data.package,
  };
};

export const GetLPBStats = async () => {
  const response = await EXPRESS_API.get('/addons/lpb/check');

  return response.data;
};

export const GetCaptionStats = async () => {
  const response = await EXPRESS_API.get('/addons/captions/check');

  return {
    activated: response.data?.activated,
    licenses: response.data?.license?.total,
    remaining: response.data?.license?.remaining,
    users: response.data?.license?.users,
  };
};

export const GetFilesStats = async () => {
  const response = await EXPRESS_API.get('/droplr/check');
  return response.data;
};

export const ActivateFiles = async (total: number) => {
  const response = await EXPRESS_API.post('/droplr/integration', { total });
  return response.data;
};

export const DeactivateFiles = async () => {
  const response = await EXPRESS_API.delete('/droplr/integration');
  return response.data;
};

export const AddFilesToUser = async (userId: number) => {
  const response = await EXPRESS_API.post('/droplr/users', { userId });

  return response.data;
};

export const RemoveFilesFromUser = async (userId: number) => {
  const response = await EXPRESS_API.delete(`/droplr/users/${userId}`);
  return response.data;
};

export const ActivateSMS = async (smsPackage: optionSelections) => {
  const response = await EXPRESS_API.post('/addons/sms/activate', undefined, {
    params: { smsPackage },
  });
  return response.data?.message;
};

export const ActivateLPB = async () => {
  const response = await EXPRESS_API.post('/addons/lpb/activate');
  return response.data.message;
};

export const ActivateCaptions = async (size: number) => {
  const response = await EXPRESS_API.post(
    '/addons/captions/activate',
    undefined,
    {
      params: { size },
    }
  );

  return response.data.message;
};

export const DeactivateSMS = async () => {
  const response = await EXPRESS_API.post('/addons/sms/deactivate');
  return response.data.message;
};

export const DeactivateLPB = async () => {
  const response = await EXPRESS_API.post('/addons/lpb/deactivate');

  return response.data.message;
};

export const DeactivateCaptions = async () => {
  const response = await EXPRESS_API.post('/addons/captions/deactivate');

  return response.data.message;
};

export const AddCaptionToUser = async (userId: number) => {
  const response = await EXPRESS_API.post('/addons/captions/add', undefined, {
    params: { user: userId },
  });

  return response.data.message;
};

export const RemoveCaptionFromUser = async (userId: number) => {
  const response = await EXPRESS_API.post(
    '/addons/captions/remove',
    undefined,
    {
      params: { user: userId },
    }
  );

  return response.data.message;
};

export const RequestCodirect = async (props: {
  subject: string;
  notes: string;
  email: boolean;
  phone: boolean;
}) => {
  const response = await EXPRESS_API.post('/addons/codirect', undefined, {
    params: props,
  });

  return response.data.message;
};

export const ReqestEleadIntegration = async (props: {
  subject: string;
  notes: string;
  email: boolean;
  phone: boolean;
}) => {
  const response = await EXPRESS_API.post('/integration/elead', undefined, {
    params: props,
  });

  return response.data.message;
};

export const RequestVinSolutionsIntegration = async (props: {
  notes: string;
  email: boolean;
  phone: boolean;
}) => {
  const { notes, phone, email } = props;
  const response = await EXPRESS_API.post('/addons/vinsolution', {
    message: notes,
    usePhone: phone,
    useEmail: email,
  });
  return response.data.message;
};

export const requestAddon = async (props: {
  subject: string;
  notes: string;
  email: boolean;
  phone: boolean;
  isBilling?: boolean;
  isUpgradeRequest?: boolean;
}) => {
  const { subject, notes, phone, email, isBilling, isUpgradeRequest } = props;
  const URL = isBilling ? '/forms/contact-am' : '/addons/contact';
  const response = await EXPRESS_API.post(URL, {
    subject,
    message: notes,
    usePhone: phone,
    useEmail: email,
    isUpgradeRequest,
  });

  return response.data.message;
};

export const requestAffiliateAddon = async (props: {
  subject: string;
  notes: string;
  email: boolean;
  phone: boolean;
  isBilling?: boolean;
}) => {
  const { subject, notes, phone, email } = props;
  const response = await EXPRESS_API.post('/addons/affiliates', {
    subject,
    message: notes,
    usePhone: phone,
    useEmail: email,
  });
  return response.data.message;
};

export const requestNeedMoreUsers = async (props: {
  subject: string;
  notes: string;
  email: boolean;
  phone: boolean;
  users: number | string;
}) => {
  const { subject, notes, phone, email, users } = props;
  const response = await EXPRESS_API.post('/forms/contact-am', {
    subject,
    message: notes,
    usePhone: phone,
    useEmail: email,
    users,
  });

  return response.data.message;
};
