import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useHistory } from 'react-router';
import { Card } from 'lib/api/billing/useCardsQuery';

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  margin-bottom: 12px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray100};
`;
const Manage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.covideoOrange100};
  text-decoration-line: underline;
  cursor: pointer;
`;
const Main = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.blue100};
`;
const Labels = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
`;
const NoCard = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.primaryRedDanger};
`;
const CardBrand = styled.span`
  text-transform: capitalize;
`;
type Props = {
  card?: Card;
};
export const PaymentMethodOverview = ({ card }: Props) => {
  const history = useHistory();
  return (
    <>
      <Header>
        <Title>Payment Method</Title>
        {card && !!Object.keys(card).length && (
          <Manage onClick={() => history.push('/billing/cards')}>Edit</Manage>
        )}
      </Header>
      {card && Object.keys(card).length ? (
        <>
          <Main>
            <CardBrand>{card.brand}</CardBrand> ****{card.last4}
          </Main>
          <Labels>{`Exp. ${('0' + card.expMonth).slice(-2)}/${
            card.expYear
          }`}</Labels>
        </>
      ) : (
        <NoCard>No payment method on file</NoCard>
      )}
    </>
  );
};
