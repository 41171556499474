import { IAutomotiveBase } from 'app/pages/design/landingPages-v1/form/types';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { automotiveLandingKeys } from './automotiveLandingKeys';

export enum CONTACT_ORIENTATION {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum CONTACT_ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface IAutomotiveResponse extends IAutomotiveBase {
  automotiveLandingPageID: number;
  customerId: number;
}

export const getAutoMotiveLanding = async (): Promise<IAutomotiveResponse> => {
  const response = await EXPRESS_API.get('/automotive-landing');
  return response.data;
};

export const useAutomotiveLandingQuery = () => {
  const { showError } = useToastError();
  return useQuery<IAutomotiveResponse, Error>(
    automotiveLandingKeys.all(),
    getAutoMotiveLanding,
    {
      onError: error => showError(error || 'Error in fetching landing page.'),
    }
  );
};
