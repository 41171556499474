import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { usersKeys } from './queryKeys';

export interface IFetchUsersProfileResponse {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  email2: string;
  phone1: string;
  phone2: string;
  title: string;
  transcriptionAccess: number;
  access: number;
  automotiveRole: null;
  vdpEnabled: number;
  autoTranscribe: number;
  transcriptionDefaultEnabled: number;
  verified: number;
  signupDate: string;
  eleadDMSEmployeeID: null;
  website: string;
  timezone: string;
  playButtonPosition: number;
  defaultSignature: number;
  notifyMe: number;
  nmls: string;
  reactionsPreference: null;
  workingHours: string;
  customerId: number;
  trialAccount: number;
  resellerId: number;
  qraAccess: number;
  cellPhone: string;
  guideCategoriesAdministration: number;
  resellerAdmin: number;
  videoShareLinkText: string;
  mobileSMSAccess: number;
  signature: string;
  platform: number;
  googleCalendarId: null;
  calendlyCalendarUrl: null;
  calendlyAccessToken: null;
  outlookRefreshToken: null;
  calendarWebsiteUrl: null;
  DeptName: string;
  Dept: number;
  notificationPreferences: NotificationPreferences;
}

export interface NotificationPreferences {
  filesEmail: boolean;
  filesInApp: boolean;
  boardsEmail: boolean;
  boardsInApp: boolean;
  reportsEmail: boolean;
  reportsInApp: boolean;
  videoEmailOpensEmail: boolean;
  videoEmailOpensInApp: boolean;
  videoEmailDeliveryStatusEmail: boolean;
  videoEmailDeliveryStatusInApp: boolean;
  videoEmailOpenStatusEmail: boolean;
  videoEmailOpenStatusInApp: boolean;
  videoViewsEmail: boolean;
  videoViewsInApp: boolean;
  ctaClicksEmail: boolean;
  ctaClicksInApp: boolean;
  videoReplyEmail: boolean;
  videoReplyInApp: boolean;
  reactionsEmail: boolean;
  reactionsInApp: boolean;
  pushedToVdpEmail: boolean;
  pushedToVdpInApp: boolean;
  liveEmail: boolean;
  liveInApp: boolean;
  quoteUpdateInApp: boolean;
  quoteUpdateEmail: boolean;
  testDriveScheduleEmail: boolean;
  testDriveScheduleInApp: boolean;
  videoRequestEmail: boolean;
  videoRequestInApp: boolean;
  preferenceId: number;
  userId: number;
  sound: string;
  createdAt: string;
  updatedAt: string;
}

export const fetchUserProfile = async (
  userId: string
): Promise<IFetchUsersProfileResponse> => {
  const response = await EXPRESS_API.get(`/users/${userId}`);
  return response.data;
};

export const useUserProfileQuery = ({ userId }: { userId: string }) => {
  return useQuery(
    usersKeys.user_profile(userId),
    () => fetchUserProfile(userId),
    { enabled: !!userId }
  );
};
