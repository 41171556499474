import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation } from 'react-query';
import { useToastError } from 'lib/hooks';
import { AxiosError } from 'axios';
import { successToast } from 'lib/components/toasts/success';

export const deleteSharedReport = async (id: number) => {
  const response = await EXPRESS_API.delete(`/shared-reports/${id}`);
  return response.data;
};

export const useDeleteSharedReportMutation = () => {
  const { showError } = useToastError();

  return useMutation(deleteSharedReport, {
    onSuccess: () => {
      successToast({ title: 'Report deleted successfully' });
    },
    onError: (err: AxiosError) => showError(err),
  });
};
