import React from 'react';

import { LoadingIndicator } from 'lib/components';
import { MainWrapper } from 'lib/components/styles/layout';
import { useAuth } from 'lib/context';

import { useAutomotiveLandingQuery } from 'lib/api/automotiveLanding/useAutomotiveLandingQuery';
import { SalesPagesForm } from './form';
import { NotFound } from 'app/pages/notFound/NotFound';
import { SalesPagesValues } from './form/types';
import { useUploadImages } from 'lib/api/automotiveLanding/useUploadAutomotiveLandingImages';
import { useAutomotiveLandingMutation } from 'lib/api/automotiveLanding/useAutomotiveLandingMutation';

export const SalesPages = () => {
  const { userData } = useAuth();

  const { data, isLoading } = useAutomotiveLandingQuery();
  const { mutateAsync: uploadImagesMutation } = useUploadImages(userData.id);
  const { mutateAsync: updateAutomotiveLandingPageMutation } =
    useAutomotiveLandingMutation();

  if (isLoading) {
    return <LoadingIndicator isLoading={true} />;
  }

  if (!data) {
    return <NotFound />;
  }

  const onSubmitHandler = async (values: SalesPagesValues) => {
    const images = [values.logoImage, values.bodyBgImage, values.footerImage];

    const uploadedUrls = await uploadImagesMutation(images);

    await updateAutomotiveLandingPageMutation({
      bgColor: values.bgColor,
      additionalTextForSales: values?.additionalTextForSales || '',
      additionalTextColorForSales:
        values?.additionalTextColorForSales || '#fff',
      additionalTextForService: values?.additionalTextForService || '',
      additionalTextColorForService:
        values?.additionalTextColorForService || '#fff',
      imageUrl: uploadedUrls[0] === null ? '' : uploadedUrls[0],
      headerSectionBgColor: values.headerSectionBgColor,
      headerSectionTextColor: values.headerSectionTextColor,
      contactOrientation: values.contactOrientation,
      contactAlignment: values.contactAlignment,
      contactIcons: values.contactIcons,
      contactFirstName: values.contactFirstName,
      contactLastName: values.contactLastName,
      contactPhone1: values.contactPhone1,
      contactPhone2: values.contactPhone2,
      contactDepartment: values.contactDepartment,
      contactTitle: values.contactTitle,
      contactWebsite: values.contactWebsite,
      bodySectionBgImageUrl: uploadedUrls[1] === null ? '' : uploadedUrls[1],
      ctaButtonBgColor: values.ctaButtonBgColor,
      ctaButtonTextColor: values.ctaButtonTextColor,
      ctaButtonBorderColor: values.ctaButtonBorderColor,
      ctaButtonBgColorHover: values.ctaButtonBgColorHover,
      ctaButtonTextColorHover: values.ctaButtonTextColorHover,
      ctaButtonBorderColorHover: values.ctaButtonBorderColorHover,
      ctaButtonBorderThickness: values.ctaButtonBorderThickness,
      ctaButtonBorderRadius: values.ctaButtonBorderRadius,
      ctaButtonShadow: values.ctaButtonShadow,
      socialIcons: values.socialIcons,
      footerSectionBgColor: values.footerSectionBgColor,
      footerImageUrl: uploadedUrls[2] === null ? '' : uploadedUrls[2],
      footerAdditionalText: values.footerAdditionalText,
      footerSectionTextColor: values.footerSectionTextColor,
    });
  };

  const INITIAL_VALUES: SalesPagesValues = {
    ...data,
    logoImage: {
      url: data.imageUrl,
      //on first load file is null because we we dont have uploaded files in form
      file: null,
    },
    bodyBgImage: {
      url: data.bodySectionBgImageUrl,
      //on first load file is null because we we dont have uploaded files in form
      file: null,
    },

    footerImage: {
      url: data.footerImageUrl,
      //on first load file is null because we we dont have uploaded files in form
      file: null,
    },
  };

  return (
    <MainWrapper resetPadding={false}>
      <SalesPagesForm
        initialValues={INITIAL_VALUES}
        onSubmitHandler={onSubmitHandler}
      />
    </MainWrapper>
  );
};
