import React from 'react';
import SocialStatsTable from './SocialStatsTable';
import { InsightsWrapper, NoStats } from './styles/socialInsights';
import { LoadingIndicator } from 'lib/components';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { useQueryFacebookVideoAnalytics } from 'lib/api/social/facebook/useQueryFacebookVideoAnalytics';
import { useQueryYoutubeVideoAnalytics } from 'lib/api/social/youtube/useQueryYoutubeVideoAnalytics';
import { useQueryLinkedInVideoAnalytics } from 'lib/api/social/linkedin/useQueryLinkedinVideoAnalytics';
import { ISocialInsights } from 'lib/api/social/types';
import { useQueryYoutubeIntegrationStatus } from 'lib/api/social/youtube/useQueryYoutubeIntegrationStatus';
import { useQueryFacebookIntegrationStatus } from 'lib/api/social/facebook/useQueryFacebookIntegrationStatus';
import { useQueryLinkedInIntegrationStatus } from 'lib/api/social/linkedin/useQueryLinkedinIntegrationStatus';

const SocialInsights = ({ videoId }: ISocialInsights) => {
  const { userData } = useAuth();

  const { data: youtubeStatus, isLoading: loadingYoutubeStatus } =
    useQueryYoutubeIntegrationStatus();
  const youtubeIntegrationEnabled = youtubeStatus?.success;

  const { data: facebookStatus, isLoading: loadingFacebookStatus } =
    useQueryFacebookIntegrationStatus();
  const facebookIntegrationEnabled = facebookStatus?.accessToken;

  const { data: linkedInStatus, isLoading: loadingLinkedInStatus } =
    useQueryLinkedInIntegrationStatus();
  const linkedInIntegrationEnabled = linkedInStatus?.success;

  const isYoutubeFeatureAvailable = checkIfFeatureIsEnabled(
    userData,
    productFeature.YOUTUBE
  );
  const isFacebookFeatureAvailable = checkIfFeatureIsEnabled(
    userData,
    productFeature.FACEBOOK
  );
  const isLinkedInFeatureAvailable = checkIfFeatureIsEnabled(
    userData,
    productFeature.LINKEDIN
  );

  const facebookAnalyticsFetchingEnabled =
    isFacebookFeatureAvailable && facebookIntegrationEnabled;

  const { data: facebookAnalytics, isLoading: facebookLoading } =
    useQueryFacebookVideoAnalytics(
      parseInt(videoId),
      !!facebookAnalyticsFetchingEnabled
    );

  const youtubeAnalyticsFetchingEnabled =
    isYoutubeFeatureAvailable && youtubeIntegrationEnabled;

  const { data: youtubeAnalytics, isLoading: youtubeLoading } =
    useQueryYoutubeVideoAnalytics(
      parseInt(videoId),
      !!youtubeAnalyticsFetchingEnabled
    );

  const linkedInAnalyticsFetchingEnabled =
    isLinkedInFeatureAvailable && linkedInIntegrationEnabled;

  const { data: linkedinAnalytics, isLoading: linkedinLoading } =
    useQueryLinkedInVideoAnalytics(
      parseInt(videoId),
      !!linkedInAnalyticsFetchingEnabled
    );

  const loadingMetrics =
    facebookLoading ||
    youtubeLoading ||
    linkedinLoading ||
    loadingFacebookStatus ||
    loadingYoutubeStatus ||
    loadingLinkedInStatus;
  const metrics = [
    ...(Array.isArray(facebookAnalytics) ? facebookAnalytics : []),
    ...(Array.isArray(youtubeAnalytics) ? youtubeAnalytics : []),
    ...(Array.isArray(linkedinAnalytics) ? linkedinAnalytics : []),
  ];
  return (
    <InsightsWrapper loading={loadingMetrics || metrics.length === 0}>
      {loadingMetrics ? (
        <LoadingIndicator isLoading={loadingMetrics} />
      ) : !loadingMetrics && metrics.length === 0 ? (
        <>
          <NoStats>No data to show.</NoStats>
        </>
      ) : (
        metrics.map(analytic => {
          return (
            analytic && (
              <SocialStatsTable data={analytic} key={analytic.postId} />
            )
          );
        })
      )}
    </InsightsWrapper>
  );
};

export default SocialInsights;
