import React, { useEffect, useState } from 'react';
import { useDepartmentsQuery } from 'lib/api/superadmin/departments';
import { useSuperAdminUserQuery } from 'lib/api/superadmin/useSuperAdminUserQuery';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { useVisibilityContextProvider } from '../provider/context';
import { VisibilityMessage } from '../VisibilityMessage';
import { SelectableBodySearch } from './components/SelectableBodySearch';
import { SelectableBodyWrapper } from './components/SelectableBodyWrapper';
import { SelectableMainWrapper } from './components/SelectableMainWrapper';
import { SelectableListItem } from './components/SeletableListItem';
import { VISIBILITY_TYPE } from './SelectableArea';
import { SelectableLoadingWrapper } from './components/SelectableLoaderWrapper';
import { LoadingIndicator } from 'lib/components';
import { EmptyMessageWrapper } from './components/EmptyMessageWrapper';
import { MdRefresh } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { sortItemsByChecked } from '../provider/const';
import { ResellerUser } from '../provider/types';
import { SelectableUsersHeader } from './components/SelectableUsersHeader';

interface IProps {
  item: VISIBILITY_TYPE;
  onItemClick: (id: number, item: VISIBILITY_TYPE) => void;
  selectedCustomerId: number | undefined;
  selectedResellerId: number | undefined;
  openHowToShareModal: () => void;
}

export const SelectableUsers = ({
  item,
  onItemClick,
  selectedCustomerId,
  selectedResellerId,
  openHowToShareModal,
}: IProps) => {
  const [search, setSearch] = useState('');
  const [departmentId, setDepartmentId] = useState<null | number>(null);
  const [isInitiated, setIsInitiated] = useState(false);
  const SIZE = 100;
  const {
    setUsers,
    selectAllUsers,
    deselectAllUsers,
    updateCustomerChecked,
    updateUsersChecked,
    incrementUserPageCount,
    state,
  } = useVisibilityContextProvider();
  const currentPage =
    (selectedCustomerId && state?.usersCurrentPage?.[selectedCustomerId]) || 0;
  const { data, refetch, isLoading, isRefetching } = useSuperAdminUserQuery({
    enabled: !!selectedCustomerId,
    params: {
      status: VerificationStatus.ALL,
      start: currentPage * SIZE,
      limit: SIZE,
      search,
      customerId: selectedCustomerId,
      departmentId: departmentId || null,
    },
  });

  const { data: departments } = useDepartmentsQuery({
    customerId: !!selectedCustomerId ? `${selectedCustomerId}` : '',
  });

  useEffect(() => {
    if (data && selectedCustomerId && selectedResellerId) {
      setUsers(
        data?.users,
        selectedCustomerId,
        selectedResellerId,
        isInitiated
      );
      setIsInitiated(true);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [search, departmentId, currentPage]);

  const onSearchHandler = (value: string) => {
    setSearch(value);
  };

  const onCheckboxClickHandler = (id: number, checked: boolean) => {
    if (selectedResellerId && selectedCustomerId) {
      updateUsersChecked(selectedResellerId, selectedCustomerId, id, checked);
    }
  };
  const onCustomerDropdownChangeHandler = (value: {
    label: string;
    value: number;
  }) => {
    setDepartmentId(value.value);
  };

  const onSwitchButtonChangeHandler = (value: string) => {
    if (selectedResellerId && selectedCustomerId) {
      updateCustomerChecked(
        selectedResellerId,
        selectedCustomerId,
        value === 'all' ? true : false
      );
    }
  };

  if (!selectedResellerId || !selectedCustomerId) {
    return <></>;
  }

  if (isLoading) {
    return (
      <SelectableLoadingWrapper>
        <LoadingIndicator isLoading={true} />
      </SelectableLoadingWrapper>
    );
  }

  const departmentsOptions =
    departments?.data?.map(department => ({
      value: department.id,
      label: department.name,
    })) || [];

  const users =
    state?.resellers?.[selectedResellerId]?.customers?.[selectedCustomerId]
      ?.users || {};

  const usersCountLength = state?.usersCount?.[selectedCustomerId]?.length;
  const { initialCheckedUsers } = state;

  const sortedUsers = sortItemsByChecked(users, initialCheckedUsers, 'id');

  const transformedUsers = Object.keys(users).length
    ? sortedUsers.map((user: ResellerUser) => {
        return {
          displayText: `${user?.firstName} ${user?.lastName}`,
          checked: user?.checked,
          departmentId: user?.departmentId,
          id: user?.id,
          isPartiallySelected: false,
        };
      })
    : [];

  const searchFirstName = search.toLocaleLowerCase();

  const filteredUsers = transformedUsers.filter(user => {
    const userFirstName = user.displayText.toLocaleLowerCase();
    const userDepartmentId = user.departmentId;

    if (departmentId === null) {
      return userFirstName.includes(searchFirstName);
    }

    return (
      userFirstName.includes(searchFirstName) &&
      `${userDepartmentId}` === `${departmentId}`
    );
  });

  const isAllChecked =
    state?.resellers?.[selectedResellerId]?.customers?.[selectedCustomerId]
      ?.checked || false;

  const checkGroupIndicator =
    usersCountLength > 0 && Object.keys(users).length !== usersCountLength;

  const checked = Object.keys(users).length === usersCountLength;

  return (
    <>
      <SelectableMainWrapper>
        {!Object.keys(users).length ? (
          <EmptyMessageWrapper />
        ) : (
          <>
            <SelectableUsersHeader
              isRefetching={isRefetching}
              isAllChecked={isAllChecked}
              defaultValue={!!isAllChecked ? 'all' : 'manually'}
              onSearch={onSearchHandler}
              departmentsOptions={[
                { value: null, label: 'All' },
                ...(departmentsOptions || []),
              ]}
              onCustomerDropdownChangeHandler={onCustomerDropdownChangeHandler}
              departmentId={departmentId}
              onSwitchButtonChange={onSwitchButtonChangeHandler}
            />
            {isAllChecked ? (
              <VisibilityMessage
                openHowToShareModal={openHowToShareModal}
                message='This guide will be shared with all current and new users.'
              />
            ) : (
              <SelectableBodyWrapper>
                <SelectableBodySearch
                  checkGroupIndicator={checkGroupIndicator}
                  checked={checked}
                  onSelectAll={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                    const { checked } = event.target as HTMLInputElement;
                    if (checked) {
                      return selectAllUsers(
                        selectedResellerId,
                        selectedCustomerId
                      );
                    }
                    return deselectAllUsers(
                      selectedResellerId,
                      selectedCustomerId
                    );
                  }}
                  message={`${
                    state?.usersCount?.[selectedCustomerId]?.length || 0
                  } users`}
                />
                <SelectableListItem
                  onCheckboxClick={onCheckboxClickHandler}
                  selectedResellerId={selectedResellerId}
                  selectedCustomerId={selectedCustomerId}
                  data={
                    !!search || !!departmentId
                      ? filteredUsers
                      : transformedUsers
                  }
                  onItemClick={onItemClick}
                  type={item}
                />

                {data &&
                Number(data.count) <= Object.keys(users).length ? null : (
                  <Button
                    onClick={() => incrementUserPageCount(selectedCustomerId)}
                    text={isRefetching ? 'Loading...' : 'Load more'}
                    icon={<MdRefresh size={16} />}
                    variant='secondary'
                    disabled={isRefetching}
                  />
                )}
              </SelectableBodyWrapper>
            )}
          </>
        )}
      </SelectableMainWrapper>
    </>
  );
};
