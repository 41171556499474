import React from 'react';
import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { Modal } from 'lib/components';
import { theme } from 'lib/style';
import { useToastError } from 'lib/hooks';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';
import { useUpdateCustomerPaymentMutation } from 'lib/api/billing/useUpdateCustomerPaymentMutation';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  font-family: Work Sans;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  id: string;
};

export const DefaultCardModal = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const { mutateAsync: updateCustomerSubscription } =
    useUpdateCustomerPaymentMutation();

  const { handleModalClose, id } = props;

  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const { showError } = useToastError();

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const makeCardDefault = async () => {
    setLoading(true);
    try {
      await updateCustomerSubscription({ defaultMethod: id });
      setLoading(false);
      successToast({ title: 'Card is made default successfully!' });
      handleModalClose(true);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Card default?</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              onClick={() => handleModalClose()}
              size={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>Are you sure about making this card default one?</Content>
        <Footer>
          <Button
            variant='secondary'
            text='No, Cancel'
            onClick={async () => {
              handleModalClose();
            }}
            disabled={loading}
          />
          <Button
            variant='primary'
            text='Confirm'
            onClick={async () => {
              await makeCardDefault();
            }}
            disabled={loading}
          />
        </Footer>
      </div>
    </Modal>
  );
};
