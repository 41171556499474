import { PasswordGuide } from 'app/pages/admin/components/PasswordTooltip';
import { useFormikContext } from 'formik';
import { useCreateDepartmentMutation } from 'lib/api/departments/useCreateDepartmentMutation';
import { useCheckUsernameQuery } from 'lib/api/users/useCheckUsernameQuery';
import {
  FormikDropdownField,
  OptionValue,
} from 'lib/components/formik/FormikDropdown';
import { FormikInputField } from 'lib/components/formik/FormikInputField';
import { FormikRadioField } from 'lib/components/formik/FormikRadioField';
import { FormikSwitchField } from 'lib/components/formik/FormikSwitchField';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import { Flex, FlexProps } from 'lib/components/styles/layout';
import {
  ParagraphNormalBold,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { successToast } from 'lib/components/toasts/success';
import {
  AutomotiveRole,
  automotiveRoleOptions,
} from 'lib/const/AutomotiveRole';
import { NAPLETON_AUTOMOTIVE_RESELLER_ID } from 'lib/const/SuperAdminConstants';
import { useAuth } from 'lib/context';
import { generatePrettyPassword } from 'lib/utils/functions';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-covideo-common';
import { IoMdCopy, IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import styled, { CSSProperties, useTheme } from 'styled-components/macro';
import { LoginAsButton } from './LoginAsButton';
import { UserFormikValues } from './UserInfoTab';
import { accessTypeOptions, ACCESS_TYPE, SHOW_TAB_CHANGE_POPUP } from './utils';

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
  margin: 16px 0px 32px 0;
`;
const EyeField = styled.div`
  position: relative;
  right: 30px;
  top: 13px;
  cursor: pointer;
`;

type Options = {
  label: string;
  value: number;
};

interface IProps {
  departmentsOptions: Options[];
  landingPageOptions: Options[];
  websiteOverlaysOptions: Options[];
  ctaSetOptions: Options[];
  setApiError: React.Dispatch<React.SetStateAction<string | null>>;
  hasEmail2Field: boolean;
  isExceeded: boolean;
}

const extendStyles = {
  labelStyles: {
    width: '200px',
    alignItems: 'center',
    maxHeight: '40px',
  },
  mainWrapper: { flexDirection: 'row' },
} as {
  labelStyles: CSSProperties;
  mainWrapper: FlexProps;
};

export const UserForm = ({
  departmentsOptions,
  landingPageOptions,
  websiteOverlaysOptions,
  ctaSetOptions,
  setApiError,
  hasEmail2Field,
  isExceeded,
}: IProps) => {
  const { id } = useParams() as { id: string | undefined };
  const userId = Number(id);
  const isEditView = !!id;
  const [showAMModal, setShowAMModal] = useState(false);
  const { userData } = useAuth();
  // double check is vdpEnabled
  const { isAutomotive, access, resellerId, isVdpEnabled } = userData;
  const { colors } = useTheme();
  const { values, setFieldValue, setFieldError, setFieldTouched, dirty } =
    useFormikContext<UserFormikValues>();
  const { refetch: refetchUsername } = useCheckUsernameQuery({
    username: values.username,
    userId: id,
  });

  const { mutateAsync: mutateCreatingDepartment, isLoading } =
    useCreateDepartmentMutation();

  const usernameRef = React.useRef<HTMLInputElement | null>(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const copyPassword = (password: string) => {
    const cb = navigator.clipboard;
    cb.writeText(password || '').then(() => {
      successToast({ title: 'Password copied successfully!' });
    });
  };

  const checkIfUsenameExist = async (value: string) => {
    if (value) {
      setFieldTouched('username', true, false);
      const usernameResponse = await refetchUsername();
      if (usernameResponse.data?.userExist) {
        setFieldError('username', 'Username already exists');
        setApiError('Username already exists');
        return;
      }
      setFieldError('username', undefined);
      setApiError(null);
      return;
    }
    setFieldError('username', undefined);
    setApiError(null);
  };

  const debounceFn = React.useMemo(
    () => debounce(checkIfUsenameExist, 500),
    []
  );

  const isLastNameReq =
    resellerId !== NAPLETON_AUTOMOTIVE_RESELLER_ID.toString();

  const automotiveRoleOptionsToDisplay = !isEditView
    ? automotiveRoleOptions.filter(option => option.value !== '')
    : automotiveRoleOptions;

  const isCreatingUserDueToLimitExceed = isExceeded && !id;

  const filteredAccessTypeOptions = isCreatingUserDueToLimitExceed
    ? accessTypeOptions.filter(type => type.value === ACCESS_TYPE.SUPERVISOR)
    : accessTypeOptions;

  useEffect(() => {
    if (dirty) {
      localStorage.setItem(SHOW_TAB_CHANGE_POPUP, '1');
      return;
    }
    localStorage.setItem(SHOW_TAB_CHANGE_POPUP, '0');
  }, [dirty]);

  const onDepartmentChange = async (option: OptionValue): Promise<void> => {
    if (option?.value) {
      setFieldValue('departmentId', option.value);
    }
    if (option?.__isNew__ && option?.value) {
      const res = await mutateCreatingDepartment({
        data: { name: `${option.value}` },
      });
      await setFieldValue('departmentId', res.data.id);
    }
  };

  return (
    <>
      {isEditView && !isNaN(userId) && (
        <>
          <Flex flexDirection='row'>
            <ParagraphNormalBold style={{ maxWidth: 270, width: '100%' }}>
              Log In As User:
            </ParagraphNormalBold>
            <Flex gap='4px' width='160px'>
              <LoginAsButton userId={userId} username={values.username} />
            </Flex>
          </Flex>
          <Divider />
        </>
      )}

      <Flex flexDirection='row'>
        <ParagraphNormalBold style={{ maxWidth: 270, width: '100%' }}>
          Personal Info:
        </ParagraphNormalBold>
        <Flex gap='4px'>
          <FormikInputField
            extendStyles={extendStyles}
            name='firstName'
            isRequired
            label='First Name'
            placeholder='First Name'
          />
          <FormikInputField
            extendStyles={extendStyles}
            name='lastName'
            isRequired={isLastNameReq}
            label='Last Name'
            placeholder='Last Name'
          />
          <FormikInputField
            extendStyles={extendStyles}
            name='email'
            isRequired
            label='Email'
            placeholder='Email'
            type='email'
            onChange={async e => {
              const value = e.target.value;
              await setFieldValue('email', value, false);
              await setFieldValue('username', value, false);
              debounceFn(value);
            }}
          />

          {hasEmail2Field && (
            <FormikInputField
              extendStyles={extendStyles}
              name='email2'
              isRequired={false}
              label='Email 2'
              placeholder='Email 2'
              type='email'
            />
          )}
          <FormikInputField
            extendStyles={extendStyles}
            name='username'
            isRequired
            label='Username'
            placeholder='Username'
            required
            ref={usernameRef}
            disabled={access !== '3' && access !== '4'}
            onChange={async e => {
              const value = e.target.value;
              await setFieldValue('username', value, false);
              debounceFn(value);
            }}
          />
        </Flex>
      </Flex>
      <Divider />
      {/*  PASSWORD SECTION */}
      {isEditView ? (
        <>
          <Flex flexDirection='row'>
            <ParagraphNormalBold style={{ maxWidth: 270, width: '100%' }}>
              Password:
            </ParagraphNormalBold>
            <Flex width='100%' flexDirection='column'>
              <Flex width='100%' flexDirection='row'>
                <FormikInputField
                  extendStyles={{
                    ...extendStyles,
                  }}
                  name='password'
                  isRequired
                  label='New password'
                  placeholder='Password'
                  autoComplete='new-password'
                  type={isPasswordVisible ? 'text' : 'password'}
                  info={<PasswordGuide color={colors.neutral[80]} />}
                />
                <EyeField
                  onClick={() => {
                    setIsPasswordVisible(prev => !prev);
                  }}
                >
                  {isPasswordVisible ? <IoMdEyeOff /> : <IoMdEye />}
                </EyeField>
              </Flex>
              <ParagraphSmall
                color={colors.neutral[80]}
                style={{ marginLeft: 170, marginTop: 8 }}
              >
                Leave blank if you don't want to change the password.
              </ParagraphSmall>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Flex gap='16px'>
            <Flex flexDirection='row'>
              <ParagraphNormalBold style={{ maxWidth: 270, width: '100%' }}>
                Password:
              </ParagraphNormalBold>
              <Flex
                flexDirection='row'
                alignItems='flex-start'
                gap='10px'
                width='450px'
              >
                <FormikRadioField
                  name='manualPassword'
                  label='Enter manually'
                  onClick={async () => {
                    await setFieldValue('manualPassword', 1);
                    await setFieldTouched('password', false);
                    await setFieldValue('password', '');
                  }}
                  value={1}
                />
                <FormikRadioField
                  name='manualPassword'
                  label='Let user set a password'
                  onClick={async () => {
                    await setFieldValue('manualPassword', 0);
                    await setFieldValue('password', generatePrettyPassword());
                  }}
                  info='User will automatically receive an invitation via email.'
                  value={0}
                />
              </Flex>
            </Flex>
            <div style={{ height: 84 }}>
              {!!values.manualPassword && (
                <Flex flexDirection='row' style={{ marginLeft: 270 }}>
                  <Flex
                    flexDirection='row'
                    width='calc(100% - 270px)'
                    alignItems='center'
                    gap='8px'
                  >
                    <Flex width='100%' flexDirection='row'>
                      <FormikInputField
                        extendStyles={extendStyles}
                        name='password'
                        isRequired
                        label='Password'
                        placeholder='Password'
                        autoComplete='new-password'
                        type={isPasswordVisible ? 'text' : 'password'}
                        info={<PasswordGuide color={colors.neutral[80]} />}
                      />
                      <EyeField
                        onClick={() => {
                          setIsPasswordVisible(prev => !prev);
                        }}
                      >
                        {isPasswordVisible ? <IoMdEyeOff /> : <IoMdEye />}
                      </EyeField>
                    </Flex>
                    <Button
                      variant='secondary'
                      text='Copy'
                      style={{ height: 40, alignSelf: 'flex-start' }}
                      icon={<IoMdCopy size={20} height='18px' />}
                      onClick={() => {
                        copyPassword(values.password);
                      }}
                    />
                  </Flex>
                </Flex>
              )}
            </div>
          </Flex>
        </>
      )}
      <Divider />

      <Flex flexDirection='row'>
        <ParagraphNormalBold style={{ maxWidth: 270, width: '100%' }}>
          Contact:
        </ParagraphNormalBold>
        <Flex gap='4px'>
          <FormikInputField
            extendStyles={extendStyles}
            name='phone1'
            isRequired={false}
            label='Phone 1'
            placeholder='Phone number'
          />
          <FormikInputField
            extendStyles={extendStyles}
            name='phone2'
            isRequired={false}
            label='Phone 2'
            placeholder='Phone number'
          />
          <FormikInputField
            extendStyles={extendStyles}
            name='cellPhone'
            isRequired={false}
            placeholder='Cell Phone'
            label='Cell Phone'
            info={
              'This is useful for delivering important onboarding content to each user.'
            }
          />
        </Flex>
      </Flex>
      <Divider />

      <Flex flexDirection='row'>
        <ParagraphNormalBold style={{ maxWidth: 270, width: '100%' }}>
          Job Positon:
        </ParagraphNormalBold>
        <Flex gap='4px'>
          <FormikInputField
            extendStyles={extendStyles}
            name='title'
            isRequired={false}
            placeholder='Title'
            label='Title'
          />

          {/*    THIS SHOULD BE ABLE TO CREATE NEW DEPARTMENT an */}
          <FormikDropdownField
            extendStyles={extendStyles}
            name='departmentId'
            label={'Department'}
            disabled={isLoading}
            isRequired={false}
            options={departmentsOptions}
            onChange={onDepartmentChange}
            creatable
            placeholder={isLoading ? 'Creating...' : 'Select...'}
          />
          {!isAutomotive && (
            <FormikInputField
              extendStyles={extendStyles}
              name='nmls'
              isRequired={false}
              label='NMLS'
            />
          )}
        </Flex>
      </Flex>
      <Divider />

      {/*   {SHOW ONLY WHEN CREATING} */}
      {!isEditView && (
        <>
          <Flex flexDirection='row'>
            <ParagraphNormalBold style={{ maxWidth: 270, width: '100%' }}>
              Set defaults:
            </ParagraphNormalBold>
            <Flex gap='4px'>
              <FormikDropdownField
                extendStyles={extendStyles}
                name='designId'
                label={'Landing Page'}
                disabled={false}
                isRequired={false}
                options={landingPageOptions}
              />
              <FormikDropdownField
                extendStyles={extendStyles}
                name='ctaSetId'
                label={'CTA set'}
                disabled={false}
                isRequired={false}
                options={ctaSetOptions}
              />

              <FormikDropdownField
                extendStyles={extendStyles}
                name='overlayUrlId'
                label={'Website Overlay'}
                disabled={false}
                isRequired={false}
                options={websiteOverlaysOptions}
              />
            </Flex>
          </Flex>
          <Divider />
        </>
      )}
      <Flex flexDirection='row'>
        <ParagraphNormalBold style={{ maxWidth: 270, width: '100%' }}>
          Access level:
        </ParagraphNormalBold>
        <Flex gap='4px'>
          <Flex>
            <FormikDropdownField
              extendStyles={extendStyles}
              name='access'
              label={'User type'}
              disabled={false}
              isRequired={false}
              options={filteredAccessTypeOptions}
              onChange={async option => {
                if (isCreatingUserDueToLimitExceed) {
                  await setFieldValue('access', option?.value);
                  return;
                }
                await setFieldValue('access', option?.value);
                // change automotive role ony if is automotive
                if (isAutomotive) {
                  await setFieldValue(
                    'automotiveRole',
                    `${option?.value}` === `${ACCESS_TYPE.ADMIN}`
                      ? AutomotiveRole.SALES_MANAGER
                      : AutomotiveRole.SALESPERSON
                  );
                }
              }}
              info={
                <>
                  <div style={{ marginBottom: 4 }}>
                    'Standard' access allows the user to only record and send
                    videos.
                  </div>
                  <div style={{ marginBottom: 4 }}>
                    'Admin' access allows the user to both record and send
                    videos, as well as pull reports and manage users. This is
                    the highest level of access.
                  </div>
                  <div style={{ marginBottom: 4 }}>
                    'Supervisor' access allows the user to pull reports and
                    manage users, but they cannot record or send videos. This
                    type of account does not count against your allotted users.
                  </div>
                </>
              }
            />
            {isCreatingUserDueToLimitExceed && (
              <ParagraphSmall
                color={colors.neutral[80]}
                style={{ marginLeft: 170 }}
              >
                This type of account does not count against your allotted users.
                If you need to add another user, please{' '}
                <span
                  onClick={() => setShowAMModal(true)}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  contact us
                </span>
                .
              </ParagraphSmall>
            )}
          </Flex>

          {isAutomotive && (
            <FormikDropdownField
              extendStyles={extendStyles}
              name='automotiveRole'
              label={'Automotive role:'}
              disabled={false}
              isRequired={false}
              options={automotiveRoleOptionsToDisplay}
            />
          )}
          {isVdpEnabled && (
            <FormikSwitchField
              wrapper={{ width: '270px' }}
              name='vdpEnabled'
              label='User can push videos to a VDP '
            />
          )}
        </Flex>
      </Flex>

      {showAMModal && (
        <ModalContactAM
          handleModalClose={() => setShowAMModal(false)}
          subject='Covideo Legacy Plan Update'
          title='Would you like to manage your subscription?'
          description={
            <div>
              To make changes to your subscription, please contact your Covideo
              account manager or fill out the form below.
            </div>
          }
        />
      )}
    </>
  );
};
