import React, { useState, useEffect } from 'react';
import { TextInput } from 'lib/components/inputs';
import styled from 'styled-components/macro';
import { VideoPlayer } from '../../../videoPlayer';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { Heading } from 'lib/components/styles/typography';
import { FormGroup, ItemContainer, ItemsWrapper } from '../../styles/layout';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import { theme } from 'lib/style';
import { BsPlus } from 'react-icons/bs';
import { InfoIcon } from '../../../../account/userAccount/socialProfiles/assets/social-icons';
import { successToast } from 'lib/components/toasts/success';
import { toHHMMSS, getSecondsFromHHMMSS } from 'lib/utils/functions';
import { errorToast } from 'lib/components/toasts/error';
import { Button } from 'react-covideo-common';
import { FiPlus } from 'react-icons/fi';
import { MdDeleteForever, MdSave } from 'react-icons/md';
import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import { VideoData } from 'lib/hooks';
import RouteLeavingGuard from '../RouteLeavingGuard';
import { useHistory } from 'react-router-dom';
import { useUpdateVideoChaptersMutation } from 'lib/api/videos/useUpdateVideoChaptersMutation';

const Label = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${theme.palette.gray60};
  margin-bottom: 8px;
`;
const ErrorLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.tomatoRed};
  flex: none;
  order: 9;
  flex-grow: 0;
  margin-top: 12px;
  margin-bottom: 12px;
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss02' on;
  color: ${theme.palette.midGrey};
  margin-bottom: 24px;
`;
const BoxContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const InfoBoxWrapper = styled.div`
  background: ${theme.palette.secondaryButtonBlueBg};
  border: 1px solid rgba(39, 42, 50, 0.05);
  border-radius: 6px;
  padding: 12px;
  display: flex;
  gap: 4px;
  margin-top: 8px;
`;
const BoxContent = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: rgba(39, 42, 50, 0.6);
  display: flex;
`;
const InfoContainer = styled.div`
  margin-top: 16px;
`;
const AddChapterBtnWrapper = styled.div`
  width: 160px;
`;

const warningMessages = [
  {
    id: 1,
    message: 'Video can’t have only one chapter.',
  },
  {
    id: 2,
    message: 'Each chapter has to be at least 1 second long.',
  },
  {
    id: 3,
    message: 'Chapters will be automatically reordered based on the timestamp.',
  },
];

export const VideoChapters = ({
  videoDetails,
  setVideoData,
  resetForm,
}: any) => {
  const { mutateAsync: updateVideoChapters } = useUpdateVideoChaptersMutation();
  const { userData } = useAuth();
  const videoRef = React.createRef<HTMLVideoElement>();
  const [formValues, setFormValues] = useState<any>([]);
  const [error, setError] = useState({
    lengthError: false,
    gapError: false,
    titleError: false,
    index: -1,
  });
  const [hasChapters, setHasChapters] = useState<boolean>(false);
  const chapterMinLength = 1;
  const videoLength = Math.round(videoDetails.videoLength * 0.001);
  const maxChapterNumber = videoLength.toFixed();
  // const canHaveChapters = videoLength >= 1;
  const canHaveChapters = true;
  const isFieldError = error.lengthError || error.gapError || error.titleError;
  const [isTouched, setIsTouched] = useState(false);
  const history = useHistory();
  const handleChange = (i: number, e: any, isTimeStamp: boolean) => {
    setIsTouched(true);
    if (i === 0 && isTimeStamp) {
      errorToast({
        title: 'First chapter should always start at 0:00',
      });
      return;
    }

    let newValue = e.target.value;
    let newFormValues: any = [...formValues];

    if (i !== 0 && (newValue === '0' || newValue === '0:00')) {
      errorToast({
        title: 'Only first chapter should start at 0:00',
      });
      return;
    }
    if (!isTimeStamp) {
      // if chapter title is empty or it has more then 100 chars - show error
      let isTitleInvalid = newValue.length === 0 || newValue.length > 100;
      newFormValues[i].isError = isTitleInvalid;
    }
    newFormValues[i][e.target.name] = newValue;
    setFormValues(newFormValues);
  };

  const handleBlur = (e: any, index: number) => {
    let newFormValues: any = [...formValues];
    const seconds = Math.max(0, getSecondsFromHHMMSS(e.target.value));
    newFormValues[index].isError = false;
    const formattedSeconds = toHHMMSS(seconds);
    newFormValues[index][e.target.name] = formattedSeconds;
    newFormValues[index]['playbackPosition'] = seconds;
    newFormValues[index]['minNextChapterValue'] = seconds + chapterMinLength;
    newFormValues[index]['maxPreviousChapterValue'] = Math.max(
      seconds - chapterMinLength,
      0
    );
    // sort values
    newFormValues.sort(
      (p1: { playbackPosition: number }, p2: { playbackPosition: number }) =>
        p1.playbackPosition > p2.playbackPosition
          ? 1
          : p1.playbackPosition < p2.playbackPosition
            ? -1
            : 0
    );
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setIsTouched(true);
    if (formValues.length === 0) {
      setFormValues([
        {
          minNextChapterValue: chapterMinLength,
          maxPreviousChapterValue: 0,
          playbackPosition: 0,
          timestamp: '0:00',
          title: 'Chapter #1',
          isError: false,
        },
        {
          minNextChapterValue: 2,
          maxPreviousChapterValue: 0,
          playbackPosition: chapterMinLength,
          timestamp: '0:01',
          title: 'Chapter #2',
          isError: false,
        },
      ]);
      setHasChapters(true);
      return;
    }
    const newPlayBackPosition =
      formValues[formValues.length - 1].minNextChapterValue;
    if (newPlayBackPosition > videoLength) {
      return;
    }

    setFormValues([
      ...formValues,
      {
        minNextChapterValue:
          formValues[formValues.length - 1].minNextChapterValue +
          chapterMinLength,
        maxPreviousChapterValue: Math.max(
          formValues[formValues.length - 1].minNextChapterValue -
            chapterMinLength,
          0
        ),
        playbackPosition: newPlayBackPosition,
        timestamp: toHHMMSS(
          formValues[formValues.length - 1].minNextChapterValue
        ),
        title: `Chapter #${String(formValues.length + 1)}`,
        isError: isFieldError,
      },
    ]);
  };

  const removeFormFields = (i: number) => {
    setIsTouched(true);
    if ((i === 0 || i === 1) && formValues.length <= 2) {
      setFormValues([]);
      setHasChapters(false);
      return;
    }

    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    if (i === 0) {
      newFormValues[0].playbackPosition = 0;
      newFormValues[0].timestamp = '0:00';
    }

    setFormValues(newFormValues);
  };

  const handleSubmit = async (event: any) => {
    if (event) {
      event?.preventDefault();
    }
    // extract and prepare data
    const chaptersData = formValues.map(
      (val: { playbackPosition: number; title: string }) => ({
        playbackPosition: val.playbackPosition,
        title: val.title,
      })
    );
    // save chapters to DB
    await updateVideoChapters({
      videoId: videoDetails.id,
      videoBody: {
        chapters: chaptersData,
      },
    });
    const video: VideoData = await getVideo(videoDetails.id);
    setVideoData(video);
    successToast({ title: 'Video chapters updated successfully!' });
    setIsTouched(false);
  };

  useEffect(() => {
    const isChaptersAllowed = checkIfFeatureIsEnabled(
      userData,
      productFeature.CHAPTERS
    );
    if (!isChaptersAllowed) {
      window.location.href = '/upgrade-plan';
    }
  }, []);

  useEffect(() => {
    if (videoDetails.chapters && videoDetails.chapters.length) {
      // parse chapters based on db data
      const parsedFormValues = [];
      for (const chapter of videoDetails.chapters) {
        const parsedChapter = {
          ...chapter,
          minNextChapterValue: chapter.playbackPosition + chapterMinLength,
          maxPreviousChapterValue: Math.max(
            chapter.playbackPosition - chapterMinLength,
            0
          ),
          timestamp: toHHMMSS(chapter.playbackPosition),
          isError: false,
        };
        parsedFormValues.push(parsedChapter);
      }
      setFormValues(parsedFormValues);
      setHasChapters(true);
    }
  }, []);

  useEffect(() => {
    const error = {
      lengthError: false,
      gapError: false,
      titleError: false,
      index: -1,
    };

    formValues.forEach(
      (val: { playbackPosition: number; title: string }, index: number) => {
        if (val.playbackPosition >= videoLength) {
          error.lengthError = true;
          error.index = index;
        }
        if (
          index !== 0 &&
          val.playbackPosition <= formValues[index - 1].playbackPosition
        ) {
          error.gapError = true;
          error.index = index;
        }
        if (val.title === '') {
          error.titleError = true;
          error.index = index;
        }
        if (val.title.trim().length > 100) {
          error.titleError = true;
          error.index = index;
        }
      }
    );

    setError(error);
  }, [formValues]);

  return (
    <>
      <RouteLeavingGuard
        when={true}
        navigate={path => history.push(path)}
        onConfirm={handleSubmit}
        onDiscard={resetForm}
        shouldBlockNavigation={() => {
          return isTouched;
        }}
      />
      <HeaderWrapper>
        <Heading>Chapters</Heading>
        <Button
          text='Save Changes'
          icon={<MdSave size={18} />}
          onClick={handleSubmit}
          disabled={isFieldError || !canHaveChapters || !isTouched}
        />
      </HeaderWrapper>
      <ItemsWrapper>
        <ItemContainer>
          {!hasChapters && (
            <>
              <Text>This video has no chapters.</Text>
              {canHaveChapters && (
                <AddChapterBtnWrapper>
                  <Button
                    text='Add Chapter'
                    icon={<FiPlus size={20} />}
                    onClick={() => {
                      setFormValues([{}]);
                      addFormFields();
                    }}
                  />
                </AddChapterBtnWrapper>
              )}
              {!canHaveChapters && (
                <ErrorLabel>
                  This video is too short to have chapters
                </ErrorLabel>
              )}
            </>
          )}
          {hasChapters && (
            <form onSubmit={handleSubmit}>
              <FormGroup direction='row'>
                <Gap center style={{ gap: '24px' }}>
                  <Label>Timestamp</Label>
                  <Label>Title</Label>
                </Gap>
              </FormGroup>
              {formValues.map((element: any, index: number) => (
                <FormGroup
                  direction='row'
                  key={index}
                  style={{ marginBottom: '16px' }}
                >
                  <TextInput
                    type='text'
                    name='timestamp'
                    value={element.timestamp || ''}
                    width={'100px'}
                    onChange={(e: any) => handleChange(index, e, true)}
                    onBlur={e => handleBlur(e, index)}
                    style={{
                      backgroundColor:
                        (isFieldError && error.index === index && '#FEF6F5') ||
                        '',
                      border:
                        (isFieldError &&
                          error.index === index &&
                          '1px solid red') ||
                        '',
                    }}
                  />
                  <TextInput
                    type='text'
                    name='title'
                    value={element.title || ''}
                    style={{
                      backgroundColor:
                        (error.titleError &&
                          error.index === index &&
                          '#FEF6F5') ||
                        '',
                      border:
                        (error.titleError &&
                          error.index === index &&
                          '1px solid red') ||
                        '',
                    }}
                    onChange={e => handleChange(index, e, false)}
                  />
                  <Button
                    variant='destructive'
                    icon={<MdDeleteForever size={18} />}
                    onClick={() => removeFormFields(index)}
                  />
                </FormGroup>
              ))}
              {error.gapError && (
                <ErrorLabel>
                  There should be at least 1 second gap between each chapter.
                </ErrorLabel>
              )}
              {error.lengthError && (
                <ErrorLabel>
                  A chapter cannot be added since the time is greater than the
                  video duration.
                </ErrorLabel>
              )}
              {error.titleError && (
                <ErrorLabel>
                  A chapter title cannot be empty or longer than 100 characters.
                </ErrorLabel>
              )}
              <Button
                text={`Add Chapter`}
                disabled={
                  formValues.length >= maxChapterNumber ||
                  error.lengthError ||
                  error.gapError ||
                  videoLength - 1 <
                    formValues[formValues.length - 1].playbackPosition
                }
                icon={<BsPlus size={20} />}
                onClick={() => addFormFields()}
              />
              {formValues.length >= maxChapterNumber && (
                <ErrorLabel>
                  This video can’t have any more chapters.
                </ErrorLabel>
              )}
            </form>
          )}
        </ItemContainer>
        <ItemContainer>
          <VideoPlayer
            playerBackgroundColor={videoDetails.playerBackgroundColor}
            videoId={videoDetails.id}
            videoSource={videoDetails.videoSource}
            playButtonPosition={videoDetails.playButtonPosition}
            playerIconsColor={videoDetails.playerIconsAndTextColor}
            thumbnail={videoDetails.personalThumbnail}
            videoRef={videoRef}
            height='280px'
            videoChapters={
              formValues.map(
                (val: { playbackPosition: number; title: string }) => ({
                  playbackPosition: val.playbackPosition,
                  title: val.title,
                })
              ) || []
            }
          />
          <InfoContainer>
            {warningMessages.map(element => (
              <InfoBoxWrapper key={element.id}>
                <InfoIcon
                  style={{ flexShrink: 0, color: 'rgba(39, 42, 50, 0.6)' }}
                />
                <BoxContentWrapper>
                  <BoxContent>{element.message}</BoxContent>
                </BoxContentWrapper>
              </InfoBoxWrapper>
            ))}
          </InfoContainer>
        </ItemContainer>
      </ItemsWrapper>
    </>
  );
};
