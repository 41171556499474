import { FormikSwitchField } from 'lib/components/formik/FormikSwitchField';
import React from 'react';
import {
  FieldLabel,
  FormProps,
  getNumberInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';

export const CaptionSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;

  return (
    <Section>
      <SectionTitle>Captions</SectionTitle>
      <RowWrapper>
        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='transcriptions'
          label='Enable Captions'
          onChange={() => {
            setFieldValue('transcriptions', values.transcriptions ? 0 : 1);
          }}
        />

        {!!values.transcriptions && (
          <Row>
            <FieldLabel>Number of Licenses</FieldLabel>
            {getNumberInputField(
              'transcriptionLicenses',
              true,
              false,
              isSubmitting
            )}
          </Row>
        )}
      </RowWrapper>
    </Section>
  );
};
