import React, { useEffect, useState } from 'react';
import { Button } from 'react-covideo-common';
import styled, { useTheme } from 'styled-components/macro';
import {
  Dropdown,
  LoadingIndicator,
  NewModal,
  Table,
  TableContextProvider,
} from 'lib/components';
import { VerificationStatus } from '../../const/VerificationStatus';
import {
  User,
  useSuperAdminUserQuery,
} from 'lib/api/superadmin/useSuperAdminUserQuery';
import {
  ParagraphNormal,
  ParagraphSmall,
  ParagraphSmallBold,
} from '../styles/typography';
import { SwitchField } from '../form/switch';
import { Flex } from '../styles/layout';
import { IntegrationCustomer } from 'app/pages/admin/integrations/Main';
import { useVinSolutionsUsersMutation } from 'lib/api/crm/useVinSolutionsUsersMutation';
import { OptionProps, OptionTypeBase } from 'react-select';

const Scrollable = styled.div`
  overflow: scroll;
  height: 350px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const TableCell = styled.div<{ width?: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 24px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
  white-space: initial;

  .arrow--right {
    padding: 2px 0 0 30px;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
`;
const OptionLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral[100]};
`;
const OptionEmail = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral[80]};
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  handleModalSave: (shouldRefresh?: boolean) => void;
  customer: IntegrationCustomer;
  vinsolutionDealerUsers: VinsolutionUser[];
};

type PickedUser = Pick<User, 'firstName' | 'lastName' | 'email'>;

type SingleMappedUser = PickedUser & {
  userId: number;
  vinsolutionsUserId: number | null;
  order: number;
};

interface MappedUser {
  [userId: number]: SingleMappedUser;
}

type VinsolutionUser = {
  dealerId: number;
  email: string;
  firstName: string;
  lastName: string;
  ilmAccess: string;
  isActive: number;
  userId: number | null;
  vinUserId: number;
  vinsolutionsUserId: number;
};

interface MapObject {
  vinsolutionsUserId: number;
  userId: number;
}

enum TABLE_FIELDS {
  COVIDEO_USER = 'covideo_user',
  EMAIL = 'email',
  VIN_USER = 'vin_user',
}

const tableFields = [
  {
    label: 'Covideo User',
    value: TABLE_FIELDS.COVIDEO_USER,
  },
  {
    label: 'Email',
    value: TABLE_FIELDS.EMAIL,
  },
  {
    label: 'VinSolution User',
    value: TABLE_FIELDS.VIN_USER,
  },
];

export const CRMAssignUserModal = (props: Props) => {
  const { colors } = useTheme();
  const [isFilterOn, setIsFilterOn] = useState(false);

  const {
    handleModalSave,
    handleModalClose,
    customer,
    vinsolutionDealerUsers: initialVinSolutionDealerUsers,
  } = props;

  const [allUsers, setAllUsers] = useState<MappedUser>({});
  const [defaultUsers, setDefaultUsers] = useState<MappedUser>({});
  const [mapArray, setMapArray] = useState<MapObject[]>([]);
  const [vinSolutionDealerUsersOptions, setVinSolutionDealerUsersOptions] =
    useState<{ value: number | null; label: string; email: string }[]>([]);

  const { mutateAsync } = useVinSolutionsUsersMutation();

  const { data: superAdminUsers, isLoading } = useSuperAdminUserQuery({
    params: {
      start: 0,
      limit: 200,
      status: VerificationStatus.ACTIVE,
      customerId: customer.customerId,
    },
    enabled: true,
  });

  const onSave = async () => {
    for (const mapItem of mapArray) {
      await mutateAsync({ data: mapItem, customerId: 0 });
    }

    handleModalSave();
  };

  const handleUserChange = (userId: number, updateValue: number) => {
    setAllUsers(prevState => {
      const updatedState = { ...prevState };

      // Get the current user and update their vinsolutionsUserId
      const currentUser = updatedState[userId];

      updatedState[userId] = {
        ...currentUser,
        vinsolutionsUserId: updateValue,
      };

      return updatedState;
    });
  };

  // initial use effect, wire up vinUsers with covideo users
  useEffect(() => {
    if (!superAdminUsers?.users || !initialVinSolutionDealerUsers) {
      return;
    }

    const mappedUsers = superAdminUsers.users.reduce((acc, user, index) => {
      const matchedVinUser = initialVinSolutionDealerUsers.find(
        vinUser => vinUser.userId === user.id
      );

      acc[user.id] = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userId: user.id,
        vinsolutionsUserId: matchedVinUser
          ? matchedVinUser.vinsolutionsUserId
          : null,
        order: index, // Use for keep ordering
      };

      return acc;
    }, {} as MappedUser);

    setAllUsers(mappedUsers);
    // helper to determinate which field is updated
    setDefaultUsers(mappedUsers);
  }, [superAdminUsers, initialVinSolutionDealerUsers]);

  // Using useEffect to update mapArray when users use dropdown
  useEffect(() => {
    const updatedMapArray: MapObject[] = [];

    Object.values(allUsers).forEach(user => {
      const defaultUser = defaultUsers[user.userId];

      if (defaultUser?.vinsolutionsUserId !== user.vinsolutionsUserId) {
        updatedMapArray.push({
          vinsolutionsUserId: user.vinsolutionsUserId,
          userId: user.userId,
        });
      }
    });

    setMapArray(updatedMapArray);
  }, [allUsers]);

  //  Using when we update user recalculate options to remove selected ones
  useEffect(() => {
    const allUserVinsolutionsUserIds = Object.values(allUsers).map(
      user => user.vinsolutionsUserId
    );

    // Filter vinSolutionDealerUsersOptions to exclude users that already have the same vinsolutionsUserId
    const filteredOptions = initialVinSolutionDealerUsers
      .filter(
        user => !allUserVinsolutionsUserIds.includes(user.vinsolutionsUserId)
      )
      .map(user => ({
        value: user.vinsolutionsUserId,
        label: `${user.firstName} ${user.lastName}`,
        email: `${user.email}`,
      }));

    setVinSolutionDealerUsersOptions([
      { value: null, label: 'None', email: '' },
      ...filteredOptions,
    ]);
  }, [allUsers]);

  useEffect(() => {
    if (!unassignUsers.length) {
      setIsFilterOn(false);
    }
  }, [allUsers]);

  const allVinSolutionDealerUsers = [
    { value: null, label: 'None' },
    ...initialVinSolutionDealerUsers.map(user => ({
      value: user.vinsolutionsUserId,
      label: `${user.firstName} ${user.lastName}`,
      email: `${user.email} `,
    })),
  ];

  const unassignUsers = Object.values(allUsers).filter(
    user => user.vinsolutionsUserId === null
  ) as unknown as SingleMappedUser[];

  const filteredUsers = isFilterOn
    ? unassignUsers.sort((a, b) => a.order - b.order)
    : Object.values(allUsers).sort((a, b) => a.order - b.order);

  const CustomOption = (props: OptionProps<OptionTypeBase, boolean>) => {
    const { innerProps, innerRef } = props;
    return (
      <OptionWrapper ref={innerRef} {...innerProps}>
        <OptionLabel>{props.data.label}</OptionLabel>
        <OptionEmail>{props.data.email}</OptionEmail>
      </OptionWrapper>
    );
  };

  // TABLE HEADERS
  const formatTableHeaders = () => {
    return tableFields.map(item => {
      return <TableCell key={item.value}>{item.label}</TableCell>;
    });
  };

  //COLUMNS
  const getColumns = (user: SingleMappedUser) => [
    ...tableFields.map(({ value }) => {
      const displayValue = user[value as keyof SingleMappedUser];
      switch (value) {
        case TABLE_FIELDS.COVIDEO_USER:
          return (
            <TableCell>
              <ParagraphNormal>
                {user.firstName} {user.lastName}
              </ParagraphNormal>
            </TableCell>
          );
        case TABLE_FIELDS.VIN_USER:
          return (
            <TableCell>
              <Dropdown
                width={300}
                customComponents={{ Option: CustomOption }}
                extendStyles={{
                  menuList: { cursor: 'pointer' },
                }}
                isSearchable={true}
                creatable={false}
                menuPortalTarget={document.body}
                closeMenuOnScroll={(event: any) => {
                  return event.target?.id === 'scrollContainer';
                }}
                menuPosition='fixed'
                menuPlacement={'bottom'}
                options={vinSolutionDealerUsersOptions}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
                value={allVinSolutionDealerUsers.find(
                  vinUser =>
                    vinUser.value !== null &&
                    vinUser.value === user.vinsolutionsUserId
                )}
                onChange={option => {
                  return handleUserChange(user.userId, option.value);
                }}
                placeholder='None'
              />
            </TableCell>
          );
        default:
          return (
            <TableCell>
              <ParagraphNormal>{displayValue}</ParagraphNormal>
            </TableCell>
          );
      }
    }),
  ];

  //ROWS
  const rows =
    filteredUsers?.map((dataItem, index: number) => ({
      key: index,
      columns: getColumns(dataItem),
      onClick: () => {},
    })) || [];

  return (
    <NewModal
      closeModal={handleModalClose}
      headerText={`Assign users from VinSolutions to ${customer.business}`}
    >
      {isLoading ? (
        <div style={{ height: 350 }}>
          <LoadingIndicator isLoading={true} />
        </div>
      ) : (
        <>
          <Flex margin='0 0 32px 0'>
            <ParagraphNormal m='0 0 8px 0'>
              Assign each fetched user from integration to users under dropdowns
              on the left.
            </ParagraphNormal>
            {Object.values(allUsers).length !== 0 ? (
              <>
                <Flex
                  width='100%'
                  justifyContent='space-between'
                  flexDirection='row'
                  margin='0 0 8px 0'
                >
                  <SwitchField
                    wrapper={{ width: 'auto' }}
                    id={'switch-un-users'}
                    label='Show only unassigned users'
                    name={'switch-un-users'}
                    checked={isFilterOn}
                    onChange={() => {
                      setIsFilterOn(prev => !prev);
                    }}
                    disabled={!unassignUsers.length}
                  />
                  {unassignUsers.length === 0 ? null : (
                    <Flex
                      justifyContent='center'
                      alignItems='flex-end'
                      width='auto'
                    >
                      <ParagraphSmall color={colors.danger[100]}>
                        {unassignUsers.length}{' '}
                        {unassignUsers.length === 1 ? 'user' : 'users'} to
                        assign
                      </ParagraphSmall>
                    </Flex>
                  )}
                </Flex>
                <Scrollable id='scrollContainer'>
                  <TableContextProvider
                    total={filteredUsers.length}
                    initPage={0}
                    initSize={10}
                  >
                    <Table
                      compact={true}
                      hoverable={false}
                      headers={[...formatTableHeaders()]}
                      rows={rows}
                      columnWidths={['300px', '300px', '300px']}
                    />
                  </TableContextProvider>
                </Scrollable>
              </>
            ) : (
              <>
                <Flex
                  height='350px'
                  justifyContent='center'
                  alignItems='center'
                >
                  <ParagraphSmallBold>
                    Users {customer.business} not found
                  </ParagraphSmallBold>
                </Flex>
              </>
            )}
          </Flex>
          <Flex justifyContent='flex-end' flexDirection='row'>
            <Button
              text='Connect & Save'
              variant='primary'
              onClick={onSave}
              disabled={!mapArray.length}
            />
          </Flex>
        </>
      )}
    </NewModal>
  );
};
