import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import {
  Search,
  Table,
  CheckboxInput,
  NewModal,
  LoadingIndicator,
} from 'lib/components';

import { MdAdd } from 'react-icons/md';
import { useIsMutating } from 'react-query';
import { Gap } from 'lib/components/styles/layout';
import { GroupForm } from './form/GroupForm';
import { useLeadGroupsQuery } from 'lib/api/leadGroups/useLeadGroupsQuery';
import { useLinkLeadGroupMutation } from 'lib/api/leadGroups/useLinkLeadGroupMutation';
import { ParagraphSmallBold } from 'lib/components/styles/typography';

type Props = {
  handleModalClose: () => void;
  selectedLead: number[];
  setSelectedLead: React.Dispatch<React.SetStateAction<number[]>>;
};

const Checkbox = styled(CheckboxInput)`
  float: left;
  margin-right: 8px;
  display: inline;
`;

type TableCellProp = {
  disabled?: boolean;
};
const TableCell = styled.div<TableCellProp>`
  padding-right: 8px;
  text-align: left;
  ${props => (!!props.disabled ? `color: ${theme.palette.gray60}` : '')};
`;

const TableCellRight = styled.div<TableCellProp>`
  text-align: right;
  ${props => (!!props.disabled ? `color: ${theme.palette.gray60}` : '')};
`;

const NoGroupsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 430px;
  max-height: 430px;
  background-repeat: no-repeat;
  background-size: cover;
`;

const NoGroups = styled.div`
  height: 24px;
  ${theme.fontBold700}
  font-size: 18px;
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
`;

const TableWrapper = styled.div`
  min-height: 430px;
  max-height: 430px;
  overflow: auto;
`;

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 430px;
  max-height: 430px;
`;

export const ModalAddCustomerToGroup = ({
  handleModalClose,
  selectedLead,
  setSelectedLead,
}: Props) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  const [groupSearch, setGroupSearch] = useState('');
  const [createGroup, setCreateGroup] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([] as number[]);

  const GROUP_SIZE = 100;
  const groupsQueryParams = {
    search: groupSearch,
    limit: GROUP_SIZE,
  };
  const {
    mutateAsync,
    isLoading: isLoadingLinking,
    isSuccess,
  } = useLinkLeadGroupMutation();
  const { data: groupsData, isLoading: isGettingLeads } =
    useLeadGroupsQuery(groupsQueryParams);

  useEffect(() => {
    if (isSuccess) {
      handleModalClose();
      setSelectedLead([]);
    }
  }, [isSuccess]);

  const groupItems = groupsData?.groups ?? [];

  return (
    <NewModal
      closeModal={handleModalClose}
      headerText='Add Customers to Group (optional)'
      style={{
        content: {
          minWidth: 780,
          maxHeight: 592,
          minHeight: 592,
        },
      }}
      headerMargin={'0px'}
    >
      <>
        <Gap
          flexDirection='row'
          height='64px'
          alignItems='flex-end'
          style={{ minHeight: 64 }}
          m='0 0 8px 0'
          justifyContent='space-between'
        >
          <Search
            placeholder='Search Groups...'
            onSearch={(value: string) => setGroupSearch(value)}
            width='300px'
            initialValue={groupSearch}
            disabled={isGettingLeads || isLoadingLinking || isDisabled}
          />

          {createGroup ? (
            <Gap gap='8px' justifyContent='space-between' alignItems='flex-end'>
              <GroupForm
                onModalClose={() => {
                  setCreateGroup(false);
                }}
                editGroup={null}
                extendStyles={{
                  wrapper: {
                    flexWrap: 'nowrap',
                    margin: 0,
                    gap: 4,
                    alignItems: 'flex-end',
                  },
                  btnWrapper: {
                    flexWrap: 'nowrap',
                    gap: 4,
                  },
                }}
              />
            </Gap>
          ) : (
            <Button
              onClick={() => setCreateGroup(true)}
              text='Create Group'
              variant='secondary'
              icon={<MdAdd />}
              disabled={isDisabled}
            />
          )}
        </Gap>
      </>
      {isGettingLeads || isLoadingLinking ? (
        <div>
          <LoadingIndicator isLoading={true} />
        </div>
      ) : (
        <>
          {groupItems.length === 0 && !groupSearch ? (
            <NoGroupsWrap>
              <NoGroups>You have no groups</NoGroups>
            </NoGroupsWrap>
          ) : (
            <>
              <TableWrapper>
                <Table
                  compact={true}
                  hoverable={false}
                  rows={groupItems.map((item, index: number) => {
                    return {
                      key: index,
                      columns: [
                        <TableCell>
                          <Checkbox
                            checked={selectedGroups.includes(item.leadGroupId)}
                            onChange={(e: React.SyntheticEvent) => {
                              const { checked } = e.target as HTMLInputElement;

                              if (checked)
                                setSelectedGroups([
                                  ...selectedGroups,
                                  item.leadGroupId,
                                ]);
                              else
                                setSelectedGroups(
                                  selectedGroups.filter(
                                    e => e !== item.leadGroupId
                                  )
                                );
                            }}
                          />
                          {item.name}
                        </TableCell>,
                        <TableCellRight>
                          {item.leads && item.leads.length + ' customers'}
                        </TableCellRight>,
                      ],
                    };
                  })}
                />
                {groupItems.length === 0 && (
                  <EmptySearch>
                    <ParagraphSmallBold color={theme.palette.blue60}>
                      There are no groups with “{groupSearch}” in the title
                    </ParagraphSmallBold>
                    <ParagraphSmallBold color={theme.palette.blue60}>
                      Try using a different search term
                    </ParagraphSmallBold>
                  </EmptySearch>
                )}
              </TableWrapper>
            </>
          )}
          <Gap width='100%' justifyContent='flex-end' m='16px 0 0 0'>
            <Button
              disabled={selectedGroups.length === 0 || isDisabled}
              text={`Add to ${
                selectedGroups.length > 0 ? selectedGroups.length : ''
              } Group${selectedGroups.length > 1 ? 's' : ''}`}
              onClick={() =>
                mutateAsync({ leadGroups: selectedGroups, leads: selectedLead })
              }
            />
          </Gap>
        </>
      )}
    </NewModal>
  );
};
