import React, { useEffect, useRef, useState } from 'react';
import VideoRecorderActions from './VideoRecorderActions';
import useRecorder from './useRecorder';
import { DetailsRecordingPreview } from './detailsPreview/DetailsPreview';
import { ModalTeleprompterRecord } from 'lib/components/modal/ModalTeleprompterRecord';
import { TeleprompterActions } from './Teleprompter/TeleprompterActions';
import TeleprompterDisplay from './Teleprompter/TeleprompterDisplay';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { RecorderBody } from './RecorderBody';
import { RecordingHeaderWithRouteGuard } from './RecordingHeaderWithRouteGuard';

import { VirtualBackgroundsButton } from './VirtualBackgroundsButton';
import { useTeleprompter } from 'lib/context/teleprompter/provider';
import { RECORDING_STATUS } from './types';
import { RecordCamLoader } from './RecordCamLoader';
import { getVirtualBackgroundFromStorage } from './utils/virtualBackgrounds';

interface ScreenRecorderProps {
  screenStream: MediaStream;
  stopAllStreams: () => void;
  handleGoToRecordHome: () => void;
  showPreview?: boolean;
  onStartUpladingVoiceOverHandler?: (blob: Blob) => void;
}

export const RecordCam = ({
  screenStream,
  stopAllStreams,
  handleGoToRecordHome,
  showPreview = true,
  onStartUpladingVoiceOverHandler,
}: ScreenRecorderProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { userData, whitelabel } = useAuth();

  // virtual background state
  const initialImage = getVirtualBackgroundFromStorage(userData);
  const [isLoadingVB, setIsLoadingVB] = useState(!!initialImage.url);
  const [isSegmenterActive, setIsSegmenterActive] = useState(false);

  const {
    disableOrEnableTeleprompter,
    turnOnTeleprompterPlaying,
    saveTeleprompterSettingsHandler,
    onTeleprompterModalClose,
    showTeleprompterModal,
    isUsingTeleprompter,
    teleprompterSettings,
  } = useTeleprompter();
  const {
    stopRecording,
    pauseRecording,
    startCountDown,
    stopCountDown,
    status,
    recordedBlob,
    countdown,
    timeInSeconds,
  } = useRecorder({
    isSegmenterActive,
    canvasRef,
    videoRef,
    screenStream,
    stopAllStreams,
    onStartUpladingVoiceOverHandler,
    shouldStartCountdown: false,
    handleGoToRecordHome,
  });
  const showTeleprompter = [
    WHITELABEL_NAME.COVIDEO,
    WHITELABEL_NAME.APP_2_VID,
    WHITELABEL_NAME.PRO_VIDEO_NOW,
  ].includes(whitelabel.name);

  useEffect(() => {
    if (userData?.user?.packageDetails?.id) {
      const status = !checkIfFeatureIsEnabled(
        userData,
        productFeature.TELEPROMPTER
      );
      disableOrEnableTeleprompter(status);
    }
  }, []);

  useEffect(() => {
    if (
      countdown === 0 &&
      status === RECORDING_STATUS.COUNTDOWN &&
      isUsingTeleprompter
    ) {
      turnOnTeleprompterPlaying();
    }
  }, [countdown, status]);

  if (status === RECORDING_STATUS.DONE && recordedBlob && showPreview) {
    return (
      <DetailsRecordingPreview
        videoBlob={recordedBlob}
        handleGoToRecordHome={() => {
          handleGoToRecordHome();
        }}
      />
    );
  }

  return (
    <>
      <RecordingHeaderWithRouteGuard
        status={status}
        handleGoToRecordHome={() => {
          handleGoToRecordHome();
        }}
        teleprompterActions={
          showTeleprompter ? <TeleprompterActions status={status} /> : null
        }
      />

      <div style={{ position: 'relative' }}>
        {status === RECORDING_STATUS.IDLE && (
          <RecordCamLoader text='Loading camera' />
        )}

        <RecorderBody
          videoRef={videoRef}
          canvasRef={canvasRef}
          countdown={countdown}
          status={status}
          teleprompter={<TeleprompterDisplay status={status} />}
        />
        <VirtualBackgroundsButton
          setIsLoadingVB={setIsLoadingVB}
          isLoadingVb={isLoadingVB}
          initialImage={initialImage}
          videoRef={videoRef}
          canvasRef={canvasRef}
          isSegmenterActive={isSegmenterActive}
          setIsSegmenterActive={setIsSegmenterActive}
          status={status}
        />
      </div>
      <VideoRecorderActions
        startCountDown={startCountDown}
        stopCountDown={stopCountDown}
        pauseRecording={pauseRecording}
        stopRecording={stopRecording}
        status={status}
        timeInSeconds={timeInSeconds}
        isLoadingVB={isLoadingVB}
      />

      {showTeleprompterModal && (
        <ModalTeleprompterRecord
          handleModalClose={onTeleprompterModalClose}
          handleSubmit={saveTeleprompterSettingsHandler}
          settings={teleprompterSettings}
        />
      )}
    </>
  );
};
