import React from 'react';
import {
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from '../../../../lib/components';
import dayjs from 'dayjs';
import { STANDARD_DATE_FORMAT } from '../../../../lib/const/DateFormat';
import { TableCell } from 'app/pages/admin/ims/components/style';
import { NormalText } from 'app/pages/admin/index.styled';
import { detailsTableFields, HistoryTableColumns } from '../custom/main/const';
import { SortButtons } from 'app/pages/admin/components/SortButtons';
import {
  DEFAULT_PAGE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { MdFileDownload } from 'react-icons/md';
import { Button, Tooltip } from 'react-covideo-common';
import { useDownloadCustomReportMutation } from 'lib/api/customReports/useDownloadCustomReportMutation';

type Props = {
  data: any[];
  count: number;
  onPaginationChange: (page: number, size: number) => void;
  onSortingUpdate(sortKey: string, order: PaginationConstants): void;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
};

export const HistoryTable = (props: Props) => {
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);

  const { data, count, onPaginationChange, onSortingUpdate, sortKey, order } =
    props;
  const { mutateAsync: downloadCustomReportData, isLoading: isDownloading } =
    useDownloadCustomReportMutation();
  const onHistoryPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
    onPaginationChange(page, size);
  };

  const downloadReportHistory = async (report: any) => {
    return await downloadCustomReportData({
      reportId: report.reportId || '',
      name: `report.csv`,
      from: report.from,
      to: report.to,
    });
  };

  const rows = data.map((report: any, index: number) => ({
    key: index,
    columns: [
      ...detailsTableFields.map(item => {
        let value = <NormalText>{report[item.value]}</NormalText>;
        if (item.value === HistoryTableColumns.DATE) {
          value = (
            <NormalText>
              {dayjs(report[item.value]).format(STANDARD_DATE_FORMAT)}
            </NormalText>
          );
        }
        if (item.value === HistoryTableColumns.TIME_FRAME) {
          if (!report.from || !report.to) {
            value = <NormalText>-</NormalText>;
          } else {
            value = (
              <NormalText>{`${dayjs(report.from).format(
                STANDARD_DATE_FORMAT
              )} - ${dayjs(report.to).format(STANDARD_DATE_FORMAT)}`}</NormalText>
            );
          }
        }
        if (item.value === HistoryTableColumns.ACTION) {
          value = (
            <Tooltip popup='Download.csv'>
              <Button
                variant='secondary'
                icon={<MdFileDownload size={24} />}
                disabled={isDownloading}
                onClick={() => downloadReportHistory(report)}
              />
            </Tooltip>
          );
        }
        return <TableCell>{value}</TableCell>;
      }),
    ],
  }));

  return (
    <div>
      {!!data.length && (
        <TableContextProvider
          total={count}
          onChange={onHistoryPaginationChange}
          initPage={page}
          initSize={size}
        >
          <div style={{ overflowX: 'auto' }}>
            <Table
              compact={true}
              columnWidths={detailsTableFields.map(item => item.width)}
              headers={[
                ...detailsTableFields.map((item, index) => {
                  return (
                    <TableCell key={index} onClick={() => {}}>
                      {item.label}
                      {item.sortable && item.sortKey && (
                        <SortButtons
                          isSorted={sortKey === item.sortKey}
                          order={order}
                          onChange={value => {
                            onSortingUpdate(item.sortKey ?? '', value);
                            setPage(DEFAULT_PAGE);
                          }}
                        />
                      )}
                    </TableCell>
                  );
                }),
                '',
              ]}
              hoverable={false}
              rows={rows}
            />
          </div>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew globalName='history_reports_list' />
          </TableFooter>
        </TableContextProvider>
      )}
      {!data.length && (
        <div style={{ textAlign: 'center' }}>No data to show.</div>
      )}
    </div>
  );
};
