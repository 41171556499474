import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  LoadingIndicator,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
  CheckboxInput,
  HoverPopup,
} from 'lib/components';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { BsChevronRight } from 'react-icons/bs';
import { useHistory } from 'react-router';
import { useAuth } from 'lib/context';
import { CustomerHover, TableCellTotalApproved } from './';
import { TableCellCloseRatio } from './';
import dayjs from 'dayjs';
import { AutomotiveRole } from 'lib/const';
import { RepairOrderItem } from 'lib/api/repairOrders/types';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';

type TableCellProps = {
  justifyContent?: string;
  padding?: string;
};
const TableCell = styled.div<TableCellProps>`
  width: auto;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  padding: ${props => props.padding || '0'};
  display: flex;
  position: relative;
  cursor: pointer;
`;
const SortCell = styled.div`
  cursor: pointer;
  padding-top: 2px;
  padding-left: 10px;
  .active {
    fill: black;
  }
`;

type BadgeProps = {
  status: string;
};
const Badge = styled.div<BadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  color: ${props =>
    props.status === 'Sent' ? theme.palette.blue80 : theme.palette.white};
  background-color: ${props =>
    props.status === 'Sent' ? theme.palette.blue05 : theme.palette.blue80};
  margin-left: 16px;
`;

const DetailsIcon = styled(BsChevronRight)`
  cursor: pointer;
`;

type Props = {
  repairOrdersArray: RepairOrderItem[];
  loading: boolean;
  count: number;
  page: number;
  size: number;
  sort: string;
  selectedRepairOrder: string[];
  sortElement: (sortParam: any) => void;
  setSelectedRepairOrder: (selectedRepairOrder: string[]) => void;
  onPaginationChange: ({ page, size }: { page: number; size: number }) => void;
};
export const TableListing = ({
  repairOrdersArray,
  loading,
  count,
  onPaginationChange,
  page,
  size,
  sortElement,
  sort,
  setSelectedRepairOrder,
  selectedRepairOrder,
}: Props) => {
  const { userData } = useAuth();
  const isServiceManager =
    userData.automotiveRole === AutomotiveRole.SERVICE_MANAGER;
  const history = useHistory();
  const goToRepairOrder = (repairOrderId: string) =>
    history.push(`/repair-orders/${repairOrderId}`);
  const tableFields = [
    {
      value: 'repairOrderNumber',
      label: 'RO#',
    },
    {
      value: 'status',
      label: '',
    },
    {
      value: 'customer',
      label: 'Customer',
    },
    {
      value: 'createdAt',
      label: 'Date',
    },
    {
      value: 'total',
      label: 'Total',
    },
    {
      value: 'approved',
      label: 'Approved',
    },
    {
      value: 'closeRatio',
      label: 'Close ratio',
    },
    {
      value: 'details',
      label: '',
    },
  ];
  const columnWidths = [24, 90, '', '', '', '', '', '', 35];
  if (isServiceManager) {
    tableFields.splice(4, 0, {
      value: 'advisor',
      label: 'Advisor',
    });
    columnWidths.splice(4, 0, '');
  }
  return (
    <>
      {repairOrdersArray.length && !loading ? (
        <TableContextProvider
          total={count}
          onChange={onPaginationChange}
          initPage={page}
          initSize={size}
        >
          <Table
            columnWidths={columnWidths}
            compact={true}
            relative={true}
            headers={[
              '',
              ...tableFields.map(item => {
                return (
                  <TableCell
                    onClick={() => sortElement(item.value)}
                    justifyContent={
                      item.value == 'total' ||
                      item.value == 'approved' ||
                      item.value == 'closeRatio'
                        ? 'flex-end'
                        : 'flex-start'
                    }
                  >
                    {item.label}
                    {item.value == 'repairOrderNumber' ||
                    item.value == 'createdAt' ? (
                      <SortCell>
                        <IoMdArrowRoundUp
                          className={sort == `${item.value}` ? 'active' : ''}
                        />
                        <IoMdArrowRoundDown
                          className={sort == `-${item.value}` ? 'active' : ''}
                        />
                      </SortCell>
                    ) : null}
                  </TableCell>
                );
              }),
            ]}
            hoverable={false}
            fixColumnIndex={[0]}
            rows={repairOrdersArray.map((repairOrder: any, index: number) => ({
              key: index,
              columns: [
                <TableCell padding={'0 15px 0 0'}>
                  <CheckboxInput
                    width='24px'
                    blueCheck={true}
                    checked={selectedRepairOrder.includes(
                      repairOrder.repairOrderId
                    )}
                    onChange={(e: React.SyntheticEvent) => {
                      let { checked } = e.target as HTMLInputElement;
                      if (checked)
                        setSelectedRepairOrder([
                          ...selectedRepairOrder,
                          repairOrder.repairOrderId,
                        ]);
                      else
                        setSelectedRepairOrder(
                          selectedRepairOrder.filter(
                            (e: any) => e != repairOrder.repairOrderId
                          )
                        );
                    }}
                  />
                </TableCell>,
                ...tableFields.map((item: { value: string; label: string }) => {
                  return (
                    <TableCell
                      onClick={() =>
                        item.value !== 'customer'
                          ? goToRepairOrder(repairOrder.repairOrderId)
                          : {}
                      }
                      justifyContent={
                        item.value == 'total' ||
                        item.value == 'approved' ||
                        item.value == 'details' ||
                        item.value == 'closeRatio'
                          ? 'flex-end'
                          : 'flex-start'
                      }
                    >
                      {item.value === 'total' || item.value === 'approved' ? (
                        <TableCellTotalApproved
                          quoteItems={repairOrder.quoteItems}
                          field={item.value}
                        />
                      ) : item.value === 'createdAt' ? (
                        <>
                          {dayjs(repairOrder[item.value]).format(
                            `${STANDARD_DATE_FORMAT} hh:mm A`
                          )}
                        </>
                      ) : item.value === 'repairOrderNumber' ? (
                        <b>{repairOrder[item.value]}</b>
                      ) : item.value === 'advisor' ? (
                        <>
                          {repairOrder.advisor.firstName}{' '}
                          {repairOrder.advisor.lastName}
                        </>
                      ) : item.value === 'closeRatio' ? (
                        <TableCellCloseRatio
                          quoteItems={repairOrder.quoteItems}
                        />
                      ) : item.value === 'status' ? (
                        <Badge status={repairOrder[item.value]}>
                          {repairOrder[item.value]}
                        </Badge>
                      ) : item.value === 'customer' ? (
                        <HoverPopup
                          popup={
                            <CustomerHover
                              firstName={repairOrder.firstName}
                              lastName={repairOrder.lastName}
                              email={repairOrder.email}
                              phone={repairOrder.phone}
                            />
                          }
                          hoverable={
                            <>
                              {`${
                                repairOrder.firstName && repairOrder.lastName
                                  ? `${repairOrder.firstName} ${repairOrder.lastName}`
                                  : `${repairOrder.email}`
                              }`}
                            </>
                          }
                        />
                      ) : item.value === 'details' ? (
                        <DetailsIcon color={theme.palette.gray60} />
                      ) : (
                        <>{repairOrder[item.value]}</>
                      )}
                    </TableCell>
                  );
                }),
              ],
              onClick: () => {},
            }))}
          />
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show repair orders:' />
          </TableFooter>
        </TableContextProvider>
      ) : !repairOrdersArray.length && !loading ? (
        <p>no repair orders...</p>
      ) : (
        <LoadingIndicator isLoading={loading} height='500px' />
      )}
    </>
  );
};
