import dayjs from 'dayjs';
import {
  Dropdown,
  LoadingIndicator,
  Search,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import {
  Field,
  ModalCustomTableFields,
  TABLE_NAME_PREFIX,
} from 'lib/components/modal';
import { AccessRole, reportData, reportTypes } from 'lib/const';
import { addThousandCommaSeparator, getDisplayName } from 'lib/utils/functions';
import {
  MdArrowDownward,
  MdArrowUpward,
  MdLaunch,
  MdRemoveRedEye,
} from 'react-icons/md';
import styled from 'styled-components/macro';

import {
  LoginAsModal,
  ReportCard,
  SendReportModal,
} from 'app/pages/reports/components';
import { DateRangeSelector } from 'app/pages/reports/components/DateRangeSelector/DateRangeSelector';
import { MetricsUser } from 'lib/api/metrics/getUserMetrics';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import {
  ALL_USERS,
  CUSTOM_TABLE_COLUMNS_KEY,
  DEFAULT_REPORTS,
  TableFieldType,
  allDynamicTableFields,
  fieldValues,
  fixedTableFields,
  order,
  tableFieldToReportTypeMapper,
} from 'lib/const/MyTeamConstants';
import { ReportTypes } from 'lib/const/ReportData';
import { useToastError } from 'lib/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-covideo-common';
import { IoMdOptions } from 'react-icons/io';
import { NoList, TableCell } from '../../index.styled';
import { useLoginAsUser } from 'lib/api/users/useLoginasUser';
import { useCustomerUsageQuery } from 'lib/api/superadmin/useCustomerUsageQuery';
import { IoKey } from 'react-icons/io5';
import { Filter } from '../../users/components/SearchAndFilter';

const CardWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  & > div {
    margin: 32px 32px 32px 0;
  }
  & > div:last-child {
    margin: 32px 0;
  }
`;
const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .CustomersSelect {
    .Select-menu-outer {
      line-height: 40px;
      min-height: 40px;
    }
    width: 200px;
    height: 40px;
    line-height: 40px;
    .Select-control {
      height: 40px;
    }
    .Select-input {
      height: 40px;
    }
  }
`;
const Wrapper = styled.div`
  width: 100%;
  .dropdown-container {
    margin: 0;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export enum CustomerUsageRole {
  ALL = 1,
  STANDARD = 2,
  NOUSAGE = 3,
}

export const ROLE_OPTIONS = [
  {
    value: CustomerUsageRole.ALL,
    label: 'All Roles',
  },
  {
    value: CustomerUsageRole.STANDARD,
    label: 'Exclude Admin & Supervisors',
  },
  {
    value: CustomerUsageRole.NOUSAGE,
    label: 'Exclude Admin & Supervisors - Zero Usage',
  },
];

type MetricsUserKey = keyof MetricsUser;

const sortUsers = (
  filteredTableFields: TableFieldType[],
  users: MetricsUser[],
  field: MetricsUserKey,
  sortOrder: string
) => {
  const fieldDetails = filteredTableFields.find(
    tableField => tableField.value === field
  );
  // fix sort breaking when dynamic field removed
  if (!fieldDetails && field !== fixedTableFields.name.orderKey) {
    return users;
  }
  const sortUsers = [...users];
  const sorting = sortOrder === order.ASC ? 1 : -1;

  return sortUsers.sort((userA: MetricsUser, userB: MetricsUser) => {
    let valueA: string | number = userA[field].toString();
    let valueB: string | number = userB[field].toString();
    if (field === fieldValues.LAST_LOGIN) {
      valueA = dayjs(valueA, 'MMM DD YYYY').unix();
      valueB = dayjs(valueB, 'MMM DD YYYY').unix();
      if (isNaN(valueA)) {
        valueA = 0;
      }
      if (isNaN(valueB)) {
        valueB = 0;
      }
    } else {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
    }
    let result = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    if (fieldDetails && fieldDetails.type === 'number') {
      valueA = parseInt(valueA.toString(), 10);
      valueB = parseInt(valueB.toString(), 10);

      result = valueA - valueB;
    }

    return result * sorting;
  });
};

type Props = {
  customerId: number;
  isAutomotive: boolean;
  startDate: Date;
  endDate: Date;
  customReportTypes: ReportTypes[];
  dateRange: string;
  showSendReportModal: boolean;
  usageRole: CustomerUsageRole;
  setUsageRole(usageRole: CustomerUsageRole): void;
  setShowSendReportModal(show: boolean): void;
  onDateRangeChange(start: Date, end: Date, range: string): void;
  setCustomReportTypes(report: ReportTypes[]): void;
};

export const Usage = ({
  customerId,
  isAutomotive,
  startDate,
  endDate,
  customReportTypes,
  dateRange,
  showSendReportModal,
  setShowSendReportModal,
  onDateRangeChange,
  setCustomReportTypes,
  usageRole,
  setUsageRole,
}: Props) => {
  const { showError } = useToastError();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState(order.ASC);
  const [showLoginAsModal, setShowLoginAsModal] = useState(false);
  const [reports, setReports] = useState<Field[]>([]);
  const [showCustomizeTableFieldsModal, setShowCustomizeTableFieldsModal] =
    useState(false);
  const [sortField, setSortField] = useState<MetricsUserKey>(
    fixedTableFields.name.orderKey as MetricsUserKey
  );
  const [userIds, setUserIds] = useState<string[]>(ALL_USERS);

  const allReports = useMemo(() => {
    return Object.keys(reportData)
      .filter(
        reportValue =>
          !reportData[reportValue].isAutomotive ||
          (!!isAutomotive && !!reportData[reportValue].isAutomotive)
      )
      .filter(report =>
        allDynamicTableFields.some(field => {
          const fieldReportValueKey =
            tableFieldToReportTypeMapper[field.value] || field.value;
          return fieldReportValueKey === report;
        })
      )
      .map(reportValue => ({
        value: reportValue,
        label: reportData[reportValue].label,
        description: reportData[reportValue].description || '',
      }));
  }, [reportData, isAutomotive, allDynamicTableFields]);

  const defaultReports = useMemo(() => {
    return allReports.filter(report => DEFAULT_REPORTS.includes(report.value));
  }, [allReports, DEFAULT_REPORTS]);
  const { mutateAsync: loginAsUser, isLoading: loginAsLoading } =
    useLoginAsUser();

  const { data, isLoading } = useCustomerUsageQuery({
    params: {
      from: startDate,
      to: endDate,
      search: searchQuery,
      reports: customReportTypes,
      customer: customerId,
      start: 0,
      limit: 1000,
    },
    enabled: !!customReportTypes?.length,
  });

  const users = useMemo(() => {
    return (data?.users || []).map((user: MetricsUser) => ({
      ...user,
      [fixedTableFields.name.value]: getDisplayName([
        user.firstName,
        user.lastName,
      ]),
    }));
  }, [data?.users]);

  const filteredTableFields: TableFieldType[] = useMemo(() => {
    const filteredFields: TableFieldType[] = [];
    reports.forEach(report => {
      const tableField = allDynamicTableFields.find(field => {
        const fieldReportValueKey =
          tableFieldToReportTypeMapper[field.value] || field.value;
        return fieldReportValueKey === report.value;
      });
      if (tableField) {
        filteredFields.push(tableField);
      }
    });
    return filteredFields;
  }, [allDynamicTableFields, reports]);

  const filterUserByUsageRole = (user: MetricsUser) => {
    if (usageRole === CustomerUsageRole.NOUSAGE) {
      return (
        user.access.toString() === AccessRole.USER ||
        !!parseInt(user.recorded.toString(), 10) ||
        !!parseInt(user.sentEmails.toString(), 10) ||
        !!parseInt(user.views.toString(), 10)
      );
    }
    if (usageRole === CustomerUsageRole.STANDARD) {
      return user.access.toString() === AccessRole.USER;
    }
    return true;
  };
  const filteredUsers = useMemo(() => {
    const start = page * size;
    return sortUsers(filteredTableFields, users, sortField, sortOrder)
      .filter(filterUserByUsageRole)
      .slice(start, start + size);
  }, [filteredTableFields, users, sortField, sortOrder, page, size, usageRole]);

  const onSearch = (query: string) => {
    query = query.toLowerCase();
    setPage(0);
    setSearchQuery(query.toLowerCase());
  };

  useEffect(() => {
    setCustomReportTypes(
      (reports || []).map(report => report.value) as ReportTypes[]
    );
  }, [reports]);

  useEffect(() => {
    try {
      const selectedReports = localStorage.getItem(
        TABLE_NAME_PREFIX + CUSTOM_TABLE_COLUMNS_KEY
      );
      if (!selectedReports) {
        setReports(defaultReports);
        return;
      }
      const localStorageReports: Field[] = JSON.parse(selectedReports) || [];
      setReports(
        localStorageReports.filter((report: Field) =>
          allReports.some(r => r.value === report.value)
        )
      );
    } catch (error) {
      setReports(defaultReports);
    }
  }, [allReports]);

  useEffect(() => {
    if (usageRole === CustomerUsageRole.ALL) {
      setUserIds(ALL_USERS);
      return;
    }
    const userList = filteredUsers.map(user => user.id.toString());
    if (userList.length !== userIds.length) {
      setUserIds(userList);
    }
  }, [filteredUsers, usageRole]);

  const onPaginationChange = ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(size);
    setPage(page);
  };

  const handleSortClick = (newSortField: string, defaultOrder: string) => {
    setSortField(newSortField as MetricsUserKey);
    if (sortField !== newSortField) {
      setSortOrder(defaultOrder);
    } else {
      setSortOrder(sortOrder === order.ASC ? order.DESC : order.ASC);
    }
  };

  const handleLoginAsClick = async (user: MetricsUser) => {
    if (loginAsLoading) {
      return;
    }
    try {
      await loginAsUser({
        userId: user.id?.toString(),
        timestamp: user.timestamp,
        key: user.key,
      });
      setShowLoginAsModal(true);
    } catch (error) {
      showError(error);
      window.location.reload();
    }
  };

  const onSaveCustomTableFields = (fields: Field[]) => {
    localStorage.setItem(
      `${TABLE_NAME_PREFIX + CUSTOM_TABLE_COLUMNS_KEY}`,
      JSON.stringify(fields)
    );
    setReports(fields);
    setShowCustomizeTableFieldsModal(false);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <DateRangeSelector
          initialValue={dateRange}
          onDateRangeChange={onDateRangeChange}
        />
      </HeaderWrapper>
      <CardWrapper>
        <ReportCard
          reportType={reportTypes.RECORDED}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={userIds}
          customer={customerId.toString()}
        />
        <ReportCard
          reportType={reportTypes.SENT}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={userIds}
          customer={customerId.toString()}
        />
        <ReportCard
          reportType={reportTypes.VIEWS}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={userIds}
          customer={customerId.toString()}
        />
        <ReportCard
          reportType={reportTypes.CTA}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={userIds}
          customer={customerId.toString()}
        />
      </CardWrapper>
      <SearchWrapper>
        <Search
          width='300px'
          placeholder='Search users...'
          onSearch={onSearch}
        />
        <FilterWrapper>
          <Filter width={330}>
            <Dropdown
              height={40}
              creatable={false}
              className='dropdown'
              placeholder={'All Roles'}
              value={ROLE_OPTIONS.find(
                roleOption => roleOption.value === usageRole
              )}
              onChange={value => {
                setUsageRole(value.value);
              }}
              options={ROLE_OPTIONS}
              zIndexProp={1}
              extendStyles={{
                valueContainer: {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  flexWrap: 'nowrap',
                },
              }}
            />
          </Filter>
          <Button
            icon={<IoMdOptions size={20} />}
            onClick={() => setShowCustomizeTableFieldsModal(true)}
            style={{ marginLeft: 'auto' }}
          />
        </FilterWrapper>
      </SearchWrapper>
      <div style={{ marginTop: '32px' }}>
        {isLoading && <LoadingIndicator isLoading={isLoading} height='300px' />}
        {!isLoading && (
          <>
            {!!filteredUsers.length && (
              <TableContextProvider
                total={filteredUsers.length}
                onChange={onPaginationChange}
                initPage={page}
                initSize={size}
              >
                <div style={{ overflowX: 'auto' }}>
                  <Table
                    compact={true}
                    headers={[
                      <TableCell
                        style={{ paddingLeft: 0 }}
                        gap='4px'
                        onClick={() =>
                          handleSortClick(
                            fixedTableFields.name.orderKey,
                            fixedTableFields.name.defaultOrder
                          )
                        }
                      >
                        {sortField === fixedTableFields.name.orderKey && (
                          <div>
                            {sortOrder === order.ASC && (
                              <MdArrowUpward size={16} />
                            )}
                            {sortOrder === order.DESC && (
                              <MdArrowDownward size={16} />
                            )}
                          </div>
                        )}
                        <div>{fixedTableFields.name.label}</div>
                      </TableCell>,
                      ...filteredTableFields.map(item => {
                        return (
                          <TableCell
                            gap='4px'
                            padding={'0 16px 0 0'}
                            onClick={() =>
                              handleSortClick(item.value, item.defaultOrder)
                            }
                          >
                            {sortField === item.value && (
                              <div>
                                {sortOrder === order.ASC && (
                                  <MdArrowUpward size={16} />
                                )}
                                {sortOrder === order.DESC && (
                                  <MdArrowDownward size={16} />
                                )}
                              </div>
                            )}
                            <div>{item.label}</div>
                          </TableCell>
                        );
                      }),
                      <TableCell>{fixedTableFields.loginAs.label}</TableCell>,
                    ]}
                    hoverable={false}
                    rows={filteredUsers.map(
                      (user: MetricsUser, index: number) => ({
                        key: index,
                        columns: [
                          <TableCell
                            padding={
                              sortField === fixedTableFields.name.orderKey
                                ? '8px'
                                : '4px'
                            }
                          >
                            <Gap
                              style={{ flexGrow: 1 }}
                              gap={'4px'}
                              flexWrap='nowrap'
                            >
                              {user[
                                fixedTableFields.name.value as MetricsUserKey
                              ] || '-'}
                              {user.access.toString() ===
                                AccessRole.SUPERVISOR && (
                                <MdRemoveRedEye size={12} title='Supervisor' />
                              )}
                              {user.access.toString() === AccessRole.ADMIN && (
                                <IoKey size={14} title='Admin' />
                              )}
                            </Gap>
                          </TableCell>,
                          ...filteredTableFields.map(item => {
                            const cellPadding =
                              sortField === item.value ? '8px' : '2px';
                            if (!!item.formatter) {
                              return (
                                <TableCell padding={cellPadding}>
                                  {item.formatter(
                                    user[item.value as MetricsUserKey] || 0
                                  )}
                                </TableCell>
                              );
                            }
                            if (item.type === 'number') {
                              return (
                                <TableCell padding={cellPadding}>
                                  {addThousandCommaSeparator(
                                    parseInt(
                                      user[
                                        item.value as MetricsUserKey
                                      ]?.toString() || '0',
                                      10
                                    )
                                  )}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell padding={cellPadding}>
                                {user[item.value as MetricsUserKey] || '-'}
                              </TableCell>
                            );
                          }),
                          <TableCell>
                            <Button
                              variant='transparent'
                              title='Log in as user'
                              icon={<MdLaunch color='#9297A2' size={18} />}
                              onClick={() => handleLoginAsClick(user)}
                              style={{ marginLeft: 'auto' }}
                              disabled={loginAsLoading}
                            />
                          </TableCell>,
                        ],
                      })
                    )}
                  />
                </div>
                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew globalName='users_list' />
                </TableFooter>
              </TableContextProvider>
            )}
            {(!filteredUsers.length || !filteredTableFields.length) && (
              <NoList>No data to show.</NoList>
            )}
          </>
        )}
      </div>
      {showCustomizeTableFieldsModal && (
        <ModalCustomTableFields
          title='Customize Overview users'
          onClose={() => setShowCustomizeTableFieldsModal(false)}
          allFields={allReports}
          initialFields={reports}
          actionButtons={[
            {
              onClick: () => setShowCustomizeTableFieldsModal(false),
              text: 'Back',
              variant: 'secondary',
            },
            {
              onClick: onSaveCustomTableFields,
              text: 'Update',
            },
          ]}
        />
      )}
      {showSendReportModal && (
        <SendReportModal
          handleModalClose={() => {
            setShowSendReportModal(false);
          }}
          startDate={startDate}
          endDate={endDate}
          range={dateRange}
          reportUrl='/superadmin/customers/usage'
          reports={customReportTypes}
          customer={customerId.toString()}
        />
      )}

      {showLoginAsModal && (
        <LoginAsModal
          handleModalClose={() => {
            setShowLoginAsModal(false);
            window.location.reload();
          }}
        />
      )}
    </Wrapper>
  );
};
