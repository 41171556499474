import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormikInputField } from 'lib/components/formik/FormikInputField';
import { Gap } from 'lib/components/styles/layout';
import { Formik, Form } from 'formik';
import { Button } from 'react-covideo-common';
import * as Yup from 'yup';
import { useCreateLeadGroupMutation } from 'lib/api/leadGroups/useCreateLeadGroupMutation';
import { useQueryClient } from 'react-query';
import { leadGroupsKeys } from 'lib/api/leadGroups/leadGroupsKeys';
import {
  IGroups,
  useLeadGroupsQuery,
} from 'lib/api/leadGroups/useLeadGroupsQuery';
import { useUpdateLeadGroupMutation } from 'lib/api/leadGroups/useEditLeadGroupMutation';
import { CSSProperties } from 'styled-components/macro';
import debounce from 'lodash/debounce';

interface IProps {
  onModalClose?: () => void;
  onSuccessCallback?: () => void;
  editGroup: Omit<IGroups, 'userId' | 'leads'> | null;
  extendStyles?: { wrapper?: CSSProperties; btnWrapper?: CSSProperties };
}

interface IAddGroupProps {
  name: string;
}

const modalGroupSchema = Yup.object({
  name: Yup.string().required('Name is required'),
});

export const GroupForm = ({
  onSuccessCallback,
  editGroup,
  onModalClose,
  extendStyles,
}: IProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const formikRef = useRef<any>(null); // Create ref for Formik inst

  const [groupSearch, setGroupSearch] = useState('');
  const queryClient = useQueryClient();
  const GROUP_SIZE = 100;
  const groupsQueryParams = {
    search: groupSearch,
    size: GROUP_SIZE,
  };
  const { isRefetching: isGettingLeads, refetch } = useLeadGroupsQuery(
    groupsQueryParams,
    false
  );
  const { mutateAsync: mutateAddGroup, isSuccess: isCreateSuccess } =
    useCreateLeadGroupMutation();
  const { mutateAsync: mutateEditGroup, isSuccess: isEditSuccess } =
    useUpdateLeadGroupMutation();

  const onSubmitHandler = async (values: IAddGroupProps) => {
    editGroup?.leadGroupId
      ? await mutateEditGroup({
          name: values.name,
          leadGroupId: editGroup.leadGroupId,
        })
      : await mutateAddGroup({ name: values.name.trim() });
  };

  const isSuccess = isCreateSuccess || isEditSuccess;

  const debouncedCheckNameWithAPI = useCallback(
    debounce(async (value: string) => {
      setGroupSearch(value);
    }, 500),
    [queryClient, refetch]
  );

  const checkDoesNameExists = async () => {
    // Trigger a refetch with the new parameters
    const result = await refetch();
    if (result.data) {
      const groupNameExists = result.data?.groups?.some(
        group => group.name.toLowerCase() === groupSearch.toLowerCase()
      );
      if (!!groupNameExists) {
        setApiError('Group name already exists');
        formikRef?.current?.setFieldError('name', 'Group name already exists');
      } else {
        setApiError(null);
        formikRef?.current?.setErrors({});
      }
    }
  };

  useEffect(() => {
    if (groupSearch === '') {
      setApiError(null);
      return;
    }
    checkDoesNameExists();
  }, [groupSearch]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    formikRef?.current?.setFieldValue('name', value);
    formikRef?.current?.setFieldTouched('name', true, false);

    debouncedCheckNameWithAPI(value);
  };

  // Custom validation combining Yup and API
  const validate = async (values: { name: string }) => {
    const errors: Record<string, string> = {};
    try {
      // Run Yup validation first
      await modalGroupSchema.validate(values, { abortEarly: false });
    } catch (yupError: any) {
      yupError.inner.forEach((err: any) => {
        errors[err.path] = err.message;
      });
    }

    // Run API validation
    if (apiError) {
      errors.name = apiError; // Include API error in Formik's errors
    }

    return errors;
  };

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(leadGroupsKeys.all());
      onSuccessCallback?.();
      onModalClose?.();
    }
  }, [isSuccess]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ name: editGroup?.name || '' }}
      onSubmit={onSubmitHandler}
      validate={validate}
      validateOnMount
      enableReinitialize
    >
      {({ handleSubmit, isValid, isSubmitting, dirty }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Gap
              gap='32px'
              style={extendStyles?.wrapper}
              width='100%'
              justifyContent='flex-end'
            >
              <FormikInputField
                label='Group name'
                name='name'
                type='text'
                isRequired
                autoFocus={true}
                disabled={isSubmitting || isGettingLeads}
                extendStyles={{
                  errorWrapper: {
                    position: 'absolute',
                  },
                }}
                onChange={onChangeHandler}
              />
              <Gap style={extendStyles?.btnWrapper}>
                <Button
                  onClick={onModalClose}
                  text='Cancel'
                  variant='secondary'
                  disabled={isSubmitting || isGettingLeads}
                />
                <Button
                  text={isSubmitting ? 'Saving...' : 'Save'}
                  disabled={
                    !isValid || isSubmitting || !dirty || isGettingLeads
                  }
                  type='submit'
                />
              </Gap>
            </Gap>
          </Form>
        );
      }}
    </Formik>
  );
};
