import React, { useEffect } from 'react';
import { NewModal } from 'lib/components';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { Formik, Form } from 'formik';
import { FormikInputField } from 'lib/components/formik/FormikInputField';
import * as Yup from 'yup';
import { useDeleteLeadGroupMutation } from 'lib/api/leadGroups/useDeleteLeadGroupMutation';
import { useQueryClient } from 'react-query';
import { leadGroupsKeys } from 'lib/api/leadGroups/leadGroupsKeys';

type Props = {
  count: number;
  onModalClose: () => void;
  onSuccessCallback?: () => void;
  handleBackNavigation: () => void;
  leadGroupIds: number[];
};

const modalDeleteSchema = Yup.object().shape({
  delete: Yup.string()
    .required('This field is required.')
    .oneOf(['DELETE'], 'You must type DELETE to confirm deletion.'),
});

export default modalDeleteSchema;

export const ModalDeleteGroupLeads = ({
  count,
  onModalClose,
  leadGroupIds,
  onSuccessCallback,
  handleBackNavigation,
}: Props) => {
  const { mutateAsync, isSuccess } = useDeleteLeadGroupMutation();
  const queryClient = useQueryClient();
  const onSubmitHandler = () => {
    mutateAsync({ leadGroupIds });
  };

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(leadGroupsKeys.all());
      handleBackNavigation();
      onModalClose();
      onSuccessCallback?.();
    }
  }, [isSuccess]);

  return (
    <NewModal
      closeModal={onModalClose}
      headerText={`Delete ${count} ${count === 1 ? 'group' : 'groups'} forever`}
    >
      <Formik
        initialValues={{ delete: '' }}
        onSubmit={onSubmitHandler}
        validationSchema={modalDeleteSchema}
        validateOnMount
      >
        {({ handleSubmit, isValid, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Gap alignItems='center' justifyContent='flex-end'>
                <FormikInputField
                  name='delete'
                  type='text'
                  label={`Type DELETE to confirm deletion`}
                  isRequired={false}
                  disabled={isSubmitting}
                  placeholder='DELETE'
                />
              </Gap>
              <Gap m='32px 0 0 0' width='100%' justifyContent='flex-end'>
                <Button
                  variant='secondary'
                  text={'Keep it'}
                  onClick={onModalClose}
                  type='button'
                  disabled={isSubmitting}
                />
                <Button
                  variant='red'
                  text={'Delete'}
                  disabled={!isValid || isSubmitting}
                  type='submit'
                />
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
