export const buttonBorderOptions = [
  { value: '0px', label: 'No border' },
  { value: '1px', label: 'Thin Border' },
  { value: '2px', label: 'Regular Border' },
  { value: '3px', label: 'Medium Border' },
  { value: '4px', label: 'Bold Border' },
  { value: '5px', label: 'Extra Bold Border' },
];

export const buttonBorderRadiusOptions = [
  { value: '0px', label: 'No Radius' },
  { value: '2px', label: 'Small Radius' },
  { value: '4px', label: 'Regular Radius' },
  { value: '8px', label: 'Medium Radius' },
  { value: '12px', label: 'Large Radius' },
  { value: '50%', label: 'Full Radius' },
];

export const boxShadowOptions = [
  { value: 'none', label: 'No Shadow' },
  { value: '0px 1px 2px rgba(0, 0, 0, 0.1)', label: 'Light Shadow' },
  { value: '0px 2px 4px rgba(0, 0, 0, 0.1)', label: 'Moderate Shadow' },
  { value: '0px 4px 8px rgba(0, 0, 0, 0.1)', label: 'Strong Shadow' },
  { value: '0px 8px 16px rgba(0, 0, 0, 0.2)', label: 'Deep Shadow' },
];
