import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { MeetingResponse, MEETING_STATUSES } from './types';
import { updateMeeting } from './useUpdateMeetingMutation';
import { useCurrentAppUrl } from 'lib/hooks/useCurrentAppUrl';

interface IParams {
  meetingId: string | null;
  userId: number;
}

export const useStartNowMeetingMutation = () => {
  const { livePageUrl } = useCurrentAppUrl();
  return useMutation(
    ({ meetingId, userId }: IParams) =>
      updateMeeting({
        meetingId,
        userId,
        meetingStatus: MEETING_STATUSES.SCHEDULED,
      }),
    {
      mutationKey: meetingKeys.meeting_is_started(),
      onSuccess: (meeting: MeetingResponse) => {
        if (!!meeting.meetingId) {
          const liveLink = livePageUrl + meeting.meetingId;
          navigator.clipboard.writeText(liveLink);
          successToast({
            title: 'Live link copied to clipboard',
          });
          window.open(liveLink, '_blank');
        }
      },
      onError: () => {
        errorToast({
          title:
            'An error occurred updating the live meeting, please try again later!',
        });
      },
    }
  );
};
