import { EXPRESS_API } from 'configs/api/expressApi';
import { AttributeListItem } from '../types';
import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { videoAttributesKeys } from './videoAttributesKeys';

export const updateAttribute = async (data: AttributeListItem) => {
  const response = await EXPRESS_API.put(
    `/video-attributes/${data.attributeId}`,
    data
  );

  return response.data;
};

export const useUpdateAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAttribute, {
    onSuccess: () => {
      queryClient.refetchQueries(videoAttributesKeys.attributes());
      successToast({
        title: 'Video Attribute updated successfully.',
      });
    },
  });
};
