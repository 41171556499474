import React, { useState } from 'react';
import { ContentWrapper } from 'app/pages/library/videos/videoList/components/SendAndShare/send/SendTab';
import {
  LoadingIndicator,
  ModalVideoListVideo,
  TableContextProvider,
  Search,
  TablePaginationNew,
} from 'lib/components';
import { Button, Tooltip } from 'react-covideo-common';
import { BsPlayFill } from 'react-icons/bs';
import { Gap } from '../../styles/layout';
import { ParagraphSmallBold } from '../../styles/typography';
import { HoverPopup } from 'lib/components';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  ILesaItem,
  LesaSortKey,
  useLesaTvVideosQuery,
} from 'lib/api/lesa/useLesaTvQuery';
import { useLesaVideosCopyMutation } from 'lib/api/lesa/useLesaVideosMutations';
import { getDisplayName } from 'lib/utils/functions';
import imagePlaceholder from 'assets/images/single/imagePlaceholder.webp';
import { VideoItemThumbnail } from 'app/pages/library/videos/components';
import { debounce } from 'lodash';
import { useLesaFiltersQuery } from 'lib/api/lesa/useLesaFiltersQuery';
import { MultiselectDropdown } from 'lib/components/dropdown/MultiselectDropdown';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { SortButtons } from 'app/pages/admin/components/SortButtons';

const Content = styled(ContentWrapper)`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 956px;
  padding-bottom: 0;
`;

const VideosList = styled.div`
  width: 100%;
  min-height: 530px;
`;

const Video = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid rgb(223, 227, 230);
`;

const BaseModalText = styled.div`
  height: 24px;
  margin-left: 24px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left !important;
  color: ${theme.palette.title};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

const BaseHeaderText = styled(BaseModalText)`
  font-size: 14px;
  color: ${theme.palette.blackRgb60};
  display: flex;
`;

// vehicle year
const VehicleYearWidth = css`
  max-width: 180px;
  min-width: 80px;
`;
const VehicleYear = styled(BaseModalText)`
  ${VehicleYearWidth}
`;

const VehicleYearHeader = styled(BaseHeaderText)`
  ${VehicleYearWidth}
`;
// vehicle make
const VehicleMakeWidth = css`
  max-width: 180px;
  min-width: 120px;
`;
const VehicleMake = styled(BaseModalText)`
  ${VehicleMakeWidth}
`;
const VehicleMakeHeader = styled(BaseHeaderText)`
  ${VehicleMakeWidth}
`;

// vehicle stock
const VehicleStockNumberWidth = css`
  min-width: 120px;
  max-width: 120px;
`;
const VehicleStockNumber = styled(BaseModalText)`
  ${VehicleStockNumberWidth}
`;
const VehicleStockNumberHeader = styled(BaseHeaderText)`
  ${VehicleStockNumberWidth}
`;

const PreviewModelHeader = styled(BaseHeaderText)`
  width: 105px;
  margin-left: 0;
`;

// vehicle model
const VehicleModelWidth = css`
  width: inherit;
`;
const VehicleModel = styled(BaseModalText)`
  ${VehicleModelWidth}
`;

const VehicleModelHeader = styled(BaseHeaderText)`
  ${VehicleModelWidth}
`;

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 530px;
`;

const Footer = styled(ContentWrapper)`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  padding-bottom: 0;
`;

type Props = {
  showAddToCovideo?: boolean;
  handleModalClose?: () => void;
  onCopy?: () => void;
  onSelectVideo?: (lesaVideo: ILesaItem) => void;
};

const DEFAULT_PAGE = 0;
const DEFAULT_SIZE = 10;

export const LesaTable = ({
  handleModalClose,
  onCopy,
  showAddToCovideo = true,
  onSelectVideo,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [videoSource, setVideoSource] = useState('');
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [filter, setFilter] = useState<{
    [key: string]: string[];
  }>({});
  //sorting
  const [sortKey, setSortKey] = useState<LesaSortKey | null>(null);
  const [order, setOrder] = useState(PaginationConstants.ASCENDING);

  const resetPage = () => {
    setPage(0);
  };

  const onSuccesCallback = () => {
    onCopy?.();
    handleModalClose?.();
  };

  //queries
  const { data, isLoading: isLoadingLesaTv } = useLesaTvVideosQuery(
    {
      start: page * DEFAULT_SIZE,
      limit: DEFAULT_SIZE,
      page,
      search: searchTerm,
      filter,
      ...(!!sortKey && !!order ? { sortKey, order } : {}),
    },
    handleModalClose
  );
  const { data: filterData, isLoading: isLoadingLesaFilters } =
    useLesaFiltersQuery();

  // mutations
  const { mutateAsync: copyLesaVideosMutation, isLoading: isCopyingVideo } =
    useLesaVideosCopyMutation({
      onSuccesCallback,
    });

  const isLoading = isLoadingLesaTv || isCopyingVideo || isLoadingLesaFilters;

  const onSearchChangeHandler = async (search: string = '') => {
    setSearchTerm(search);
    resetPage();
  };

  const onPaginationChangeHandler = debounce(
    async ({ page: newPage }: { page: number }) => {
      if (page !== newPage) {
        setPage(newPage);
      }
    },
    300
  );

  const copyLesaMutationHandler = (video: ILesaItem) => {
    if (!video.video) {
      return;
    }

    const title = getDisplayName([
      video?.year.toString(),
      video.make,
      video.model,
      video.stockNumber,
    ]);

    copyLesaVideosMutation({
      url: video.video?.url,
      fileName: title,
    });
  };

  const showSelectVideo = !!onSelectVideo;

  const handleFilterChange = (key: string, selectedOption: string[]) => {
    if (key === 'make') {
      setFilter(prevState => ({
        ...prevState,
        [key]: selectedOption,
        model: [],
      }));
      return;
    }
    setFilter(prevState => ({
      ...prevState,
      [key]: selectedOption,
    }));
  };

  const handleSortChange = (
    sortKey: LesaSortKey,
    order: PaginationConstants
  ) => {
    setOrder(order);
    setSortKey(sortKey);
    setPage(DEFAULT_PAGE);
  };

  const getEmptyStateMessage = () => {
    const hasFilters = Object.values(filter).some(
      val => Array.isArray(val) && val.length > 0
    );

    if (searchTerm && hasFilters) {
      return {
        primary: `No videos found matching "${searchTerm}" with your selected filters.`,
        secondary:
          'Try adjusting your filters or using a different search term.',
      };
    }
    if (searchTerm) {
      return {
        primary: `There are no videos with "${searchTerm}" in the title.`,
        secondary: 'Try using a different search term.',
      };
    }
    if (hasFilters) {
      return {
        primary: 'No videos match your selected filters.',
        secondary: 'Try selecting different filter options.',
      };
    }
    return {
      primary:
        "Unfortunately, there aren't any videos available at the moment.",
      secondary: '',
    };
  };

  return (
    <Content>
      <Gap
        m='0 0 20px auto'
        alignItems='flex-end'
        justifyContent='space-between'
        width='100%'
      >
        {filterData && (
          <Gap gap='12px'>
            {filterData.filters?.map(item => {
              // custom rule for model dropdown
              if (item.key === 'model') {
                const isDisabled =
                  isLoadingLesaFilters || !filter?.make?.length;
                return (
                  <Tooltip
                    popup={isDisabled ? 'Select Make first' : ''}
                    position='bottom'
                  >
                    <MultiselectDropdown
                      key={`model-${filter.make?.join('-')}`}
                      onOptionsSelected={value =>
                        handleFilterChange(item.key, value as string[])
                      }
                      options={item.options.filter(
                        option =>
                          filter.make &&
                          option.group &&
                          filter.make.includes(option.group)
                      )}
                      menuListValue={-1}
                      menuListLabel={`All ${item.name}s`}
                      name={`${item.name}`}
                      menuShouldBlockScroll={false}
                      isLoading={isLoadingLesaFilters}
                      selectInputWidth='200px'
                      menuWidth='280px'
                      isDisabled={isDisabled}
                      isSearchable
                      isClearable
                    />
                  </Tooltip>
                );
              }
              return (
                <MultiselectDropdown
                  key={item.key}
                  onOptionsSelected={value =>
                    handleFilterChange(item.key, value as string[])
                  }
                  options={item.options}
                  menuListValue={-1}
                  menuListLabel={`All ${item.name}s`}
                  name={`${item.name}`}
                  menuShouldBlockScroll={false}
                  isLoading={isLoadingLesaFilters}
                  selectInputWidth='200px'
                  menuWidth='280px'
                  isDisabled={isLoadingLesaFilters}
                  isSearchable
                  isClearable
                />
              );
            })}
          </Gap>
        )}
        <Search
          placeholder='Search videos...'
          onSearch={onSearchChangeHandler}
          width='300px'
          disabled={isCopyingVideo}
        />
      </Gap>
      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={isLoading} />
        </div>
      ) : (
        <>
          {!data?.videos?.length ? (
            <EmptySearch>
              {(() => {
                const message = getEmptyStateMessage();
                return (
                  <>
                    <ParagraphSmallBold color={theme.palette.blue60}>
                      {message.primary}
                    </ParagraphSmallBold>
                    {message.secondary && (
                      <ParagraphSmallBold color={theme.palette.blue60}>
                        {message.secondary}
                      </ParagraphSmallBold>
                    )}
                  </>
                );
              })()}
            </EmptySearch>
          ) : (
            <>
              <VideosList>
                <Video>
                  <PreviewModelHeader>Preview</PreviewModelHeader>
                  <VehicleStockNumberHeader>
                    Stock #
                    <SortButtons
                      isSorted={sortKey === LesaSortKey.STOCK_NUMBER}
                      order={order}
                      onChange={value =>
                        handleSortChange(LesaSortKey.STOCK_NUMBER, value)
                      }
                    />
                  </VehicleStockNumberHeader>
                  <VehicleYearHeader>
                    Year
                    <SortButtons
                      isSorted={sortKey === LesaSortKey.YEAR}
                      order={order}
                      onChange={value =>
                        handleSortChange(LesaSortKey.YEAR, value)
                      }
                    />
                  </VehicleYearHeader>
                  <VehicleMakeHeader>
                    Make
                    <SortButtons
                      isSorted={sortKey === LesaSortKey.MAKE}
                      order={order}
                      onChange={value =>
                        handleSortChange(LesaSortKey.MAKE, value)
                      }
                    />
                  </VehicleMakeHeader>
                  <VehicleModelHeader>
                    Model
                    <SortButtons
                      isSorted={sortKey === LesaSortKey.MODEL}
                      order={order}
                      onChange={value =>
                        handleSortChange(LesaSortKey.MODEL, value)
                      }
                    />
                  </VehicleModelHeader>
                </Video>
                {data?.videos?.map(video => (
                  <Video key={video.postingId}>
                    <VideoItemThumbnail
                      handleClick={() => {
                        if (!!video.video?.url) {
                          setVideoSource(video.video?.url);
                        }
                      }}
                      thumbnail={video.thumbnailUrl || imagePlaceholder}
                      videoId={video?.video?.uuid || ''}
                      processing={0}
                      thumbnailHeight={'80%'}
                      thumbnailWidth={'15%'}
                    />
                    <VehicleStockNumber>{video.stockNumber}</VehicleStockNumber>
                    <VehicleYear>{video.year}</VehicleYear>
                    <VehicleMake>{video.make}</VehicleMake>
                    <VehicleModel>{video.model}</VehicleModel>
                    <Gap flexWrap='noWrap' gap='10px'>
                      {video.video === null ? (
                        <>
                          <HoverPopup
                            width='170px'
                            position='above'
                            padding='8px 12px'
                            hoverable={
                              <Button
                                disabled={true}
                                variant='secondary'
                                icon={<BsPlayFill />}
                                onClick={() => null}
                              />
                            }
                            popup={<>Preview not available!</>}
                          />
                          <HoverPopup
                            width='155px'
                            position='above'
                            padding='8px 12px'
                            hoverable={
                              <Button
                                disabled={true}
                                variant='secondary'
                                text='Add to Covideo'
                                onClick={() => null}
                              />
                            }
                            popup={<>Video not available!</>}
                          />
                        </>
                      ) : (
                        <>
                          {showAddToCovideo && (
                            <Button
                              variant='secondary'
                              text='Add to Covideo'
                              onClick={() => copyLesaMutationHandler(video)}
                            />
                          )}
                          {showSelectVideo && (
                            <Button
                              variant='secondary'
                              text='Select Video'
                              onClick={() => onSelectVideo(video)}
                            />
                          )}
                        </>
                      )}
                    </Gap>
                  </Video>
                ))}
              </VideosList>
              <Footer>
                <TableContextProvider
                  total={data?.count || 0}
                  initSize={DEFAULT_SIZE}
                  onChange={onPaginationChangeHandler}
                  initPage={page}
                >
                  <TablePaginationNew disabled={isLoading} />
                </TableContextProvider>
              </Footer>
            </>
          )}

          {!!videoSource && (
            <ModalVideoListVideo
              videoId={''}
              source={videoSource}
              handleModalClose={() => {
                setVideoSource('');
              }}
              hideContext={true}
            />
          )}
        </>
      )}
    </Content>
  );
};
