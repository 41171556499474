import * as React from 'react';
import styled from 'styled-components/macro';
import { ButtonPillSwitch } from 'lib/components';
import { useAuth } from '../../../context';
import { useEffect, useState } from 'react';
import { HelpDesk } from '../../helpDesk';
import { EHelpDesk } from '../../helpDesk/utils';
import { Button } from 'react-covideo-common';
import { Gap } from '../../styles/layout';
import { MdAdd, MdClose } from 'react-icons/md';
import { MERGE_VIDEO_SOURCE_TABS } from 'app/pages/video/videoMerge/const';
import { getAvailableMergeVideoSources } from 'lib/utils/automotiveRolePermissionChecks';
import {
  HeaderCenter,
  HeaderSide,
  LinkBtn,
  LinkWrapper,
  ModalHeader,
} from 'app/pages/library/videos/videoList/components/SendAndShare/styles';
import { ParagraphNormalBold600 } from '../../styles/typography';
import { TabLibrary } from './TabLibrary';
import { TabLesa } from './TabLesa';
import { TabWheelsTV } from './TabWheelsTV';
import { FullScreenModal } from 'lib/components/FullScreenModal';

const ModalBody = styled.div`
  height: calc(100vh - 64px);
  padding: 0;
  overflow-y: auto;
  .recordHomeMainWrapper {
    margin: 0 auto;
    min-height: calc(100vh - 183px);
  }
`;

const Content = styled.div`
  width: 956px;
  margin: 0 auto;
  padding-bottom: 32px;
`;

const ButtonPillSwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0 36px;
  width: 500px;
  > div {
    width: 100%;
  }
`;

type Props = {
  title?: string;
  showHelpdesk?: boolean;
  showExternalSources?: boolean;
  handleSelectedVideos: (arg: any) => void;
  handleModalClose: () => void;
  onSubmitExternal?: (videoSource: string) => void;
};

export const ModalVideoMergeListVideo = ({
  handleSelectedVideos,
  handleModalClose,
  title = 'Select videos to merge',
  showHelpdesk = true,
  showExternalSources = false,
  onSubmitExternal,
}: Props) => {
  const { userData } = useAuth();
  const [activeTab, setActiveTab] = useState(
    MERGE_VIDEO_SOURCE_TABS.library.value
  );
  const [selectedVideoIds, setSelectedVideoIds] = useState<string[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (document && document.body) {
      const orig = document.body.className;
      document.body.className = orig + (orig ? ' ' : '') + 'modal-open';
    }

    return () => {
      if (document && document.body) {
        document.body.className = document.body.className.replace(
          / ?modal-open/,
          ''
        );
      }
    };
  }, []);

  const onChangeTab = (tab: string) => {
    setSelectedVideoIds([]);
    setActiveTab(tab);
  };

  const availableTabs = getAvailableMergeVideoSources(userData);
  const availableTabsCount = Object.values(availableTabs).filter(
    tab => !tab.isHidden
  ).length;
  const showTabs = showExternalSources && availableTabsCount > 1;

  return (
    <FullScreenModal>
      <ModalHeader>
        <HeaderSide>
          <LinkWrapper>
            <LinkBtn onClick={handleModalClose}>
              <MdClose size='18' />
              <span>Close</span>
            </LinkBtn>
          </LinkWrapper>
        </HeaderSide>
        <HeaderCenter>
          {showTabs && (
            <ButtonPillSwitchWrapper>
              <ButtonPillSwitch
                defaultValue={activeTab}
                values={availableTabs}
                onChange={onChangeTab}
                pillStyle={{ width: `${100 / availableTabsCount - 1}%` }}
                textStyle={{ width: `${100 / availableTabsCount - 1}%` }}
              />
            </ButtonPillSwitchWrapper>
          )}
          {!showTabs && (
            <ParagraphNormalBold600>{title}</ParagraphNormalBold600>
          )}
        </HeaderCenter>
        <HeaderSide>
          <Gap justifyContent='flex-end' width='100%'>
            <Button
              text='Add Videos'
              variant='primary'
              disabled={!selectedVideoIds.length}
              onClick={() => handleSelectedVideos(selectedVideoIds)}
              icon={<MdAdd />}
            />
          </Gap>
        </HeaderSide>
      </ModalHeader>
      <ModalBody>
        <Content>
          {showHelpdesk && <HelpDesk name={EHelpDesk.MERGE} />}
          {/* Library Tab Content */}
          {activeTab === MERGE_VIDEO_SOURCE_TABS.library.value && (
            <TabLibrary changeSelectedVideoIds={setSelectedVideoIds} />
          )}
          {/* WheelsTV Tab Content */}
          {activeTab === MERGE_VIDEO_SOURCE_TABS.wheelsTv.value && (
            <TabWheelsTV selectWheelsTvVideo={onSubmitExternal} />
          )}
          {/* Lesa Tab Content */}
          {activeTab === MERGE_VIDEO_SOURCE_TABS.lesa.value && (
            <TabLesa selectLesaVideo={onSubmitExternal} />
          )}
        </Content>
      </ModalBody>
    </FullScreenModal>
  );
};
