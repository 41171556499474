import { LeadFilters } from 'lib/api/leadOpportunities/types';

export const formatFilters = (filters: {
  [key: string]: { value: string; label: string }[];
}): LeadFilters => ({
  ...(!!filters?.leadStatus?.length
    ? { leadStatus: filters.leadStatus.map(item => item.value) }
    : {}),
  ...(!!filters?.leadStatusType?.length
    ? { leadStatusType: filters.leadStatusType.map(item => item.value) }
    : {}),
  ...(!!filters?.leadType?.length
    ? { leadType: filters.leadType.map(item => item.value) }
    : {}),
  ...(!!filters?.leadGroupCategory?.length
    ? { leadGroupCategory: filters.leadGroupCategory.map(item => item.value) }
    : {}),
  ...(!!filters?.leadSource?.length
    ? { leadSource: filters.leadSource.map(item => item.value) }
    : {}),
  ...(!!filters?.isHot?.length
    ? { isHot: filters.isHot.map(item => item.value) }
    : {}),
});
