import * as React from 'react';
import styled from 'styled-components/macro';
import { AddCard } from './AddCard';
import { SubscriptionBreakdown } from './SubscriptionBreakdown';
import { subscriptionDataType } from 'lib/const/Subscription';
import { PaymentCardType } from 'lib/api/billing/useAddCustomerCardMutation';

type Props = {
  handleModalClose?: (shouldRefresh?: boolean) => void;
  onSubmit: (cardDetails: PaymentCardType) => Promise<void>;
  signupPage?: boolean;
  subscriptionData: subscriptionDataType;
  onBackButtonPress: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 10%;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const SubscriptionBreakdownWrapper = styled.div`
  margin-top: 60px;
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    margin-bottom: 60px;
  }
`;

export const PaymentDetails = ({
  onSubmit,
  subscriptionData,
  signupPage,
  onBackButtonPress,
  handleModalClose,
}: Props) => {
  const { isProPackage } = subscriptionData;

  return (
    <>
      <Container>
        <AddCard
          onSubmit={onSubmit}
          subscriptionData={subscriptionData}
          signupPage={signupPage}
          handleModalClose={handleModalClose}
          onBackButtonPress={onBackButtonPress}
        />
        {!isProPackage && (
          <SubscriptionBreakdownWrapper>
            <SubscriptionBreakdown subscriptionData={subscriptionData} />
          </SubscriptionBreakdownWrapper>
        )}
      </Container>
    </>
  );
};
