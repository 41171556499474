import { useQuery } from 'react-query';
import { lesaKeys } from './lesaKeys';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { PaginationConstants } from 'lib/const/PaginationConstants';

export interface ILesaItem {
  postingId: number;
  stockNumber: string;
  year: number;
  make: string;
  model: string;
  vehicleCondition: VEHICLE_CONDITION;
  video: Video | null;
  thumbnailUrl?: string;
}

export enum LesaSortKey {
  CREATED_AT = 'createdAt',
  STOCK_NUMBER = 'stockNumber',
  YEAR = 'year',
  MAKE = 'make',
  MODEL = 'model',
}

enum VEHICLE_CONDITION {
  NEW = 'New',
  USED = 'Used',
}

interface Video {
  url: string;
  fileName: string;
  uuid: null;
  createdAt: null;
}

type LesaParams = {
  limit: number;
  page: number;
  search?: string;
  start: number;
  filter?: { [key: string]: string[] };
  sortKey?: LesaSortKey;
  order?: PaginationConstants;
};

const getLesaVideos = async (
  props: LesaParams
): Promise<{ videos: ILesaItem[]; count: number }> => {
  const params = {
    limit: props.limit,
    search: props.search,
    page: props.page + 1,
    filter: props.filter,
    sortKey: props.sortKey,
    order: props.order,
  };

  return (
    await EXPRESS_API.get(`lesa/videos`, {
      params,
    })
  ).data;
};

export const useLesaTvVideosQuery = (
  params: LesaParams,
  handleModalClose?: () => void
) => {
  return useQuery(
    lesaKeys.all({
      page: params.page,
      search: params.search || '',
      filter: params.filter,
      sortKey: params.sortKey as string,
      order: params.order as string,
    }),
    () => getLesaVideos(params),
    {
      refetchOnMount: true,
      onError: () => {
        errorToast({
          title:
            "We're currently experiencing difficulties fetching Lesa videos",
        });
        handleModalClose?.();
      },
    }
  );
};
