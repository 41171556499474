import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components/macro';
import { Field, Form, Formik } from 'formik';
import { theme } from 'lib/style';
import { Modal, ButtonSwitch, ModalDelete } from 'lib/components';
import { useAuth } from 'lib/context';
import { VideoPlayer } from '../../../../videoPlayer';
import { TimelineRange } from '../../videoTrim/components';
import { ModalTemplateName } from './ModalTemplateName';
import { TextInput } from './TextInput';
import { FormColorPicker } from './FormColorPicker';
import { FormSelect } from './FormSelect';
import {
  borderWidthValues,
  fontFamilies,
  opacityValues,
  textareaFontSizes,
} from '../constants/style';
import { positions } from '../constants/positions';
import { annotationSteps, initialTemplate } from '../constants/templates';
import {
  calculateDimensionsOnChange,
  calculateImageDimensions,
  calculatePercentageOnChange,
  getImageRatio,
  prepareAnnotation,
} from '../util/functions';
import { annotationsSchema } from '../constants/types';
import { Template } from './Templates';
import { defaultAnnotation } from '../VideoAnnotations';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { useCreateAnnotationMutation } from 'lib/api/annotations/createAnnotation';
import { useEditAnnotationMutation } from 'lib/api/annotations/editAnnotation';
import { useCreateTemplateMutation } from 'lib/api/annotations/createTemplate';
import { useEditTemplateMutation } from 'lib/api/annotations/editTemplate';
import { IoMdClose, IoMdLink } from 'react-icons/io';
import { MdImage, MdOutlineAutorenew } from 'react-icons/md';
import Switch from 'app/pages/design/landingPageBuilder/components/Switch';

const linkTypes = ['No URL', 'Web', 'Email', 'Phone'];

const Header = styled.div`
  display: flex;
  padding: 32px;
  align-items: center;
`;

const HeaderTitle = styled.div`
  font-size: ${theme.fontSizes.lg};
  height: 24px;
  ${theme.fontBold700}
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
  margin-right: 32px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  padding: 0 32px;
  display: flex;
  min-width: 1000px;
  min-height: 400px;

  ${theme.mediaQueryMaxWidth.md} {
    flex-direction: column;
    min-width: 100%;
    width: 100vw;
    box-sizing: border-box;
    grid-gap: 20px;
  }
`;

const StyleStep = styled.div<{ width?: string }>`
  width: 50%;
  .infoField {
    font-size: 10px;
  }
  > div {
    ${props => (props.width ? `max-width: ${props.width}px;` : '')}
  }

  ${theme.mediaQueryMaxWidth.md} {
    width: 100%;
    box-sizing: border-box;

    > div {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const VideoPlayerWrapper = styled.div`
  width: 100%;
  height: 280px;
`;

const FormGroupWrapper = styled.div`
  margin-top: 16px;
`;

const Textarea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  padding-top: 60px;
  padding-left: 12px;
  &:focus {
    outline: 0;
  }
`;

const FontGroup = styled.div`
  position: absolute;
  display: flex;
  top: 10px;
  left: 12px;
  > div {
    margin-right: 4px;
  }
`;

const FontButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #e1e2e5;
  background-color: #fff;
  margin-left: 4px;
  text-align: center;
  cursor: pointer;
  &.italic {
    font-style: italic;
  }
  &.underline {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
  }
  &.active {
    border: solid 2px #e1e2e5;
    background-color: #eeeff2;
  }
`;

const Label = styled.label`
  display: flex;
  height: 20px;
  ${theme.fontNormal500}
  line-height: 1.43;
  color: ${theme.palette.label};
  margin-bottom: 8px;
  font-size: 15px;
`;

const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ImageFileWrapper = styled.div`
  width: 45%;
`;

const ImageFile = styled.label<{ imageFile?: string }>`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 38px;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  > svg {
    position: absolute;
    top: 8px;
    right: 10px;
  }
  &::after {
    position: absolute;
    top: 0;
    z-index: 3;
    display: block;
    height: 43px;
    line-height: 43px;
    color: ${theme.palette.label};
    z-index: 3;
    display: block;
    content: ${props => `"${props.imageFile}"`};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
    width: 120px;
  }
`;

const FileInput = styled.input`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 45px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
`;

const Footer = styled.div`
  padding: 32px;
  display: flex;
  justify-content: space-between;
`;

const SwitchToClassicContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const typeValues = [
  {
    value: 'text',
    text: 'Text',
  },
  {
    value: 'image',
    text: 'Image',
  },
];

export const ModalVideoAnnotations = ({
  videoDetails,
  videoDuration,
  selectedAnnotation,
  templates,
  handleModalClose,
  setOpenClassicEditor,
  setSelectedAnnotation,
  ref = () => {},
}: any) => {
  const { colors } = useCovideoTheme();
  const [step, setStep] = React.useState(
    selectedAnnotation.annotationId ||
      selectedAnnotation.imageUrl ||
      selectedAnnotation.text
      ? annotationSteps.STYLE
      : annotationSteps.SELECT_TEMPLATE
  );
  let imageRatio = getImageRatio(selectedAnnotation);
  const [selectedTemplate, setSelectedTemplate] = React.useState({
    ...initialTemplate,
    ...selectedAnnotation,
    imageRatio,
    useImagePercentage: !!selectedAnnotation.imageWidthPercentage,
  });
  const [selectedImage, setSelectedImage] = React.useState<File>();
  const [showTemplateNameModal, setShowTemplateNameModal] =
    React.useState(false);
  const [showTemplateUpdateModal, setShowTemplateUpdateModal] =
    React.useState(false);

  const [showSaveBeforeExitModal, setShowSaveBeforeExitModal] =
    React.useState(false);
  const { userData } = useAuth();
  const videoRef = React.createRef<HTMLVideoElement>();
  const valueRef = React.useRef<Array<number>>([0, 0]);
  const [time, setTime] = React.useState<ReadonlyArray<number>>([
    selectedAnnotation.startTime || 0,
    selectedAnnotation.endTime || videoDuration,
  ]);
  const { mutateAsync: createAnnotation } = useCreateAnnotationMutation();
  const { mutateAsync: editAnnotation } = useEditAnnotationMutation();
  const { mutateAsync: creaateTemplate } = useCreateTemplateMutation();
  const { mutateAsync: editTemplate } = useEditTemplateMutation();

  const onModalClose = (isDirty: boolean = true) => {
    if (isDirty && selectedAnnotation.annotationId) {
      return setShowSaveBeforeExitModal(true);
    }

    return handleModalClose();
  };

  const escPress = (key: string) => {
    if (key === 'Escape') {
      onModalClose();
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const isSelectTemplateStep = step === annotationSteps.SELECT_TEMPLATE;
  const isSaveTemplateStep = step === annotationSteps.SAVE_TEMPLATE;

  templates = [
    {
      ...initialTemplate,
      templateId: 'blank',
      title: isSelectTemplateStep ? 'Blank' : 'Save as New',
    },
  ].concat(templates);

  let modalTitle = 'New Annotation';
  if (selectedTemplate.annotationId) {
    modalTitle = 'Edit Annotation';
  }
  if (isSelectTemplateStep) {
    modalTitle = 'Choose Annotation Template';
  }

  if (isSaveTemplateStep) {
    modalTitle = 'Save New Template or Update Existing';
  }

  const handleSwitchEditor = (values: any) => {
    let position = '';
    let fontSize = '';
    if (values.type === 'image') {
      if (values.position.includes('Right')) position = 'Top Right';
      else position = 'Top Left';
    }

    if (values.type === 'text') {
      if (values.position.includes('Top'))
        position = 'Bottom ' + values.position.substr(4);
      else position = 'Bottom Center';
      // font size
      if (Number(values.fontSize) <= 12) fontSize = '12';
      else if (Number(values.fontSize) <= 14) fontSize = '14';
      else fontSize = '16';
    }

    setSelectedAnnotation({
      ...values,
      position,
      fontSize,
      useClassicEditor: false,
    });
    setOpenClassicEditor(false);
  };

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref}>
        <Formik
          initialValues={{
            ...selectedTemplate,
          }}
          enableReinitialize={true}
          validationSchema={annotationsSchema}
          onSubmit={async data => {
            if (showTemplateNameModal) {
              return;
            }
            const annotationData = await prepareAnnotation({
              data,
              userData,
              selectedImage,
              time,
            });

            if (annotationData.annotationId) {
              await editAnnotation({
                data: { ...annotationData },
                videoId: videoDetails.id,
                annotationId: annotationData.annotationId,
              });
              setSelectedAnnotation(defaultAnnotation);
              setOpenClassicEditor(false);
            } else {
              await createAnnotation({
                videoId: videoDetails.id,
                data: annotationData,
              });

              setOpenClassicEditor(false);
            }
            handleModalClose();
          }}
        >
          {({
            isValid,
            submitForm,
            isSubmitting,
            values,
            setFieldValue,
            dirty,
          }) => {
            const isAnnotationValid =
              (values.type === 'text' && values.text) ||
              (values.type === 'image' &&
                values.imageUrl &&
                ((values.useImagePercentage && values.imageWidthPercentage) ||
                  (!values.useImagePercentage && values.imageWidth)));
            return (
              <Form>
                <Header>
                  <HeaderTitle>{modalTitle}</HeaderTitle>
                  {step === annotationSteps.STYLE && (
                    <ButtonSwitch
                      defaultValue={values.type}
                      values={typeValues}
                      onChange={newValue => setFieldValue('type', newValue)}
                    />
                  )}
                  <CloseButtonWrap title={'Close video annotations'}>
                    <IoMdClose
                      size={24}
                      onClick={() => onModalClose(dirty)}
                      color={theme.palette.label}
                    />
                  </CloseButtonWrap>
                </Header>
                <Content>
                  {step !== annotationSteps.STYLE && (
                    <Template
                      isSaveTemplateStep={isSaveTemplateStep}
                      templates={templates}
                      isSelectTemplateStep={isSelectTemplateStep}
                      setStep={setStep}
                      setSelectedTemplate={setSelectedTemplate}
                      setShowTemplateNameModal={setShowTemplateNameModal}
                      setFieldValue={setFieldValue}
                      setShowTemplateUpdateModal={setShowTemplateUpdateModal}
                    />
                  )}

                  {step === annotationSteps.STYLE && (
                    <>
                      <StyleStep width='444'>
                        <div>
                          {values.type === 'text' && (
                            <>
                              <div style={{ position: 'relative' }}>
                                <Field
                                  name='text'
                                  as={Textarea}
                                  type='text'
                                  maxLength='300'
                                />
                                <span className='infoField'>
                                  Note: Maximum 300 characters are allowed.
                                </span>
                                <FontGroup>
                                  <FormSelect
                                    label='Font'
                                    placeholder='Font'
                                    name='fontFamily'
                                    values={fontFamilies}
                                    width='100px'
                                    showLabel={false}
                                    forFonts={true}
                                  />
                                  <FormSelect
                                    label='Size'
                                    placeholder='Size'
                                    name='fontSize'
                                    values={textareaFontSizes}
                                    width='80px'
                                    showLabel={false}
                                    forFonts={true}
                                  />
                                  <FontButton
                                    type='button'
                                    title='Bold'
                                    onClick={() =>
                                      setFieldValue(
                                        'textBold',
                                        !values.textBold
                                      )
                                    }
                                    className={values.textBold ? 'active' : ''}
                                  >
                                    B
                                  </FontButton>
                                  <FontButton
                                    type='button'
                                    title='Italic'
                                    className={`italic ${
                                      values.textItalic ? 'active' : ''
                                    }`}
                                    onClick={() =>
                                      setFieldValue(
                                        'textItalic',
                                        !values.textItalic
                                      )
                                    }
                                  >
                                    I
                                  </FontButton>
                                  <FontButton
                                    type='button'
                                    title='Underline'
                                    className={`underline ${
                                      values.textUnderline ? 'active' : ''
                                    }`}
                                    onClick={() =>
                                      setFieldValue(
                                        'textUnderline',
                                        !values.textUnderline
                                      )
                                    }
                                  >
                                    U
                                  </FontButton>
                                </FontGroup>
                              </div>

                              <FormGroupWrapper>
                                <FormGroup
                                  style={{
                                    gap: '30px',
                                    justifyContent: 'unset',
                                  }}
                                >
                                  <FormColorPicker
                                    label='Background color'
                                    name='backgroundColor'
                                    width='44%'
                                    menuPlacement='top'
                                    defaultColor='#FFFFFF'
                                  />
                                  <FormColorPicker
                                    label='Text color'
                                    name='textColor'
                                    width='44%'
                                    menuPlacement='top'
                                    defaultColor='#000000'
                                  />
                                </FormGroup>
                              </FormGroupWrapper>

                              <FormGroupWrapper>
                                <FormGroup
                                  style={{
                                    gap: '30px',
                                    justifyContent: 'unset',
                                  }}
                                >
                                  <FormColorPicker
                                    label='Border color'
                                    name='borderColor'
                                    width='44%'
                                  />
                                  <FormSelect
                                    label='Border width'
                                    placeholder='Width'
                                    name='borderWidth'
                                    values={borderWidthValues}
                                    width='44%'
                                    menuPlacement='top'
                                  />
                                </FormGroup>
                              </FormGroupWrapper>
                            </>
                          )}

                          {values.type === 'image' && (
                            <>
                              <FormGroup>
                                <ImageFileWrapper>
                                  <Label>
                                    <div>Image file</div>
                                  </Label>
                                  <ImageFile
                                    imageFile={
                                      values.imageName || 'Choose file...'
                                    }
                                  >
                                    <FileInput
                                      accept='image/x-png,image/gif,image/jpeg'
                                      type='file'
                                      onChange={async (
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (
                                          event.currentTarget &&
                                          event.currentTarget.files &&
                                          event.currentTarget.files.length
                                        ) {
                                          const imageData =
                                            event.currentTarget.files[0];

                                          setSelectedImage(imageData);

                                          setFieldValue(
                                            'imageName',
                                            imageData.name
                                          );

                                          const reader = new FileReader();

                                          reader.onload = function (file: any) {
                                            const image = new Image();
                                            image.src = file.target.result;

                                            image.onload = function () {
                                              // @ts-ignore
                                              const imageWidth = this.width;
                                              // @ts-ignore
                                              const imageHeight = this.height;
                                              const { width, height, ratio } =
                                                calculateImageDimensions(
                                                  imageWidth,
                                                  imageHeight
                                                );
                                              setFieldValue(
                                                'imageWidth',
                                                width
                                              );
                                              setFieldValue(
                                                'imageHeight',
                                                height
                                              );
                                              setFieldValue(
                                                'imageRatio',
                                                ratio
                                              );
                                              setFieldValue(
                                                'imageUrl',
                                                reader.result
                                              );
                                            };
                                          };

                                          reader.readAsDataURL(imageData);
                                        }
                                      }}
                                    />
                                    <MdImage size='20px' color='#4e5461' />
                                  </ImageFile>
                                </ImageFileWrapper>

                                <FormSelect
                                  label='Image opacity'
                                  placeholder='Image opacity'
                                  name='imageOpacity'
                                  values={opacityValues}
                                  width='45%'
                                />
                              </FormGroup>
                              <FormGroupWrapper>
                                <Label style={{ alignItems: 'center' }}>
                                  <Switch
                                    id={'switch-caption-by-default'}
                                    isOn={values.useImagePercentage}
                                    onColor={colors.primary[100]}
                                    handleToggle={() => {
                                      const useImagePercentage =
                                        !values.useImagePercentage;

                                      setFieldValue(
                                        'useImagePercentage',
                                        useImagePercentage
                                      );
                                      if (useImagePercentage) {
                                        setFieldValue(
                                          'imageWidthPercentage',
                                          selectedAnnotation.imageWidthPercentage ||
                                            '50%'
                                        );
                                        setFieldValue(
                                          'imageHeightPercentage',
                                          selectedAnnotation.imageHeightPercentage ||
                                            'auto'
                                        );
                                      } else {
                                        setFieldValue(
                                          'imageWidthPercentage',
                                          ''
                                        );
                                        setFieldValue(
                                          'imageHeightPercentage',
                                          ''
                                        );
                                      }
                                    }}
                                  />
                                  <span style={{ marginLeft: '12px' }}>
                                    Use width percentage
                                  </span>
                                </Label>
                              </FormGroupWrapper>
                              <FormGroup>
                                {values.useImagePercentage && (
                                  <FormSelect
                                    label='Image width percentage'
                                    placeholder='Image percentage'
                                    name='imageWidthPercentage'
                                    values={opacityValues}
                                    width='100%'
                                    defaultValue={'100%'}
                                    onChange={(percentage: any) => {
                                      calculatePercentageOnChange(
                                        percentage,
                                        values.imageRatio,
                                        setFieldValue
                                      );
                                    }}
                                  />
                                )}
                                {!values.useImagePercentage && (
                                  <>
                                    <TextInput
                                      label='Width'
                                      name='imageWidth'
                                      type='number'
                                      width='45%'
                                      onKeyUp={() =>
                                        calculateDimensionsOnChange(
                                          values.imageWidth,
                                          0,
                                          values.imageRatio,
                                          setFieldValue
                                        )
                                      }
                                      onMouseUp={() =>
                                        calculateDimensionsOnChange(
                                          values.imageWidth,
                                          0,
                                          values.imageRatio,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <IoMdLink
                                      color={theme.palette.black_1_100}
                                      style={{
                                        alignSelf: 'flex-end',
                                        marginBottom: '8px',
                                      }}
                                    />
                                    <TextInput
                                      label='Height'
                                      name='imageHeight'
                                      type='number'
                                      width='45%'
                                      onKeyUp={() =>
                                        calculateDimensionsOnChange(
                                          0,
                                          values.imageHeight,
                                          values.imageRatio,
                                          setFieldValue
                                        )
                                      }
                                      onMouseUp={() =>
                                        calculateDimensionsOnChange(
                                          0,
                                          values.imageHeight,
                                          values.imageRatio,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </FormGroup>
                            </>
                          )}

                          <FormGroup>
                            <FormSelect
                              label='URL (optional)'
                              placeholder='Type'
                              name='linkType'
                              values={linkTypes}
                              width='30%'
                              onChange={(val: any) => {
                                if (val && val !== 'No URL')
                                  setFieldValue('isLinkEnabled', true);
                                else setFieldValue('isLinkEnabled', false);
                              }}
                            />
                            {values.linkType === 'No URL' && (
                              <TextInput
                                name='nourl'
                                type='text'
                                showLabel={true}
                                width='65%'
                                disabled
                              ></TextInput>
                            )}
                            {values.linkType === 'Web' && (
                              <TextInput
                                name='url'
                                type='text'
                                width='65%'
                                placeholder='www.domain.com'
                                showLabel={true}
                              />
                            )}
                            {values.linkType === 'Email' && (
                              <TextInput
                                name='email'
                                type='text'
                                width='65%'
                                placeholder='test@domain.com'
                                showLabel={true}
                              />
                            )}
                            {values.linkType === 'Phone' && (
                              <TextInput
                                name='phone'
                                type='text'
                                width='65%'
                                showLabel={true}
                              />
                            )}
                          </FormGroup>
                          <FormGroup>
                            <FormSelect
                              label='Position'
                              placeholder='Position'
                              name='position'
                              values={positions}
                              menuPlacement='top'
                            />
                          </FormGroup>
                        </div>
                      </StyleStep>
                      <StyleStep width='456'>
                        <VideoPlayerWrapper>
                          <VideoPlayer
                            playerBackgroundColor={
                              videoDetails.playerBackgroundColor
                            }
                            videoId={videoDetails.id}
                            videoSource={videoDetails.videoSource}
                            playButtonPosition={videoDetails.playButtonPosition}
                            playerIconsColor={
                              videoDetails.playerIconsAndTextColor
                            }
                            thumbnail={videoDetails.personalThumbnail}
                            videoRef={videoRef}
                            previewAnnotation={{
                              ...values,
                              startTime: time[0],
                              endTime: time[1],
                              annotationId: uuidv4(),
                              text: values.text || 'Annotation text',
                            }}
                            height='280px'
                          />
                        </VideoPlayerWrapper>
                        <TimelineRange
                          videoRef={videoRef}
                          videoDuration={videoDuration}
                          videoSource={videoDetails.videoSource}
                          setValue={setTime}
                          value={time}
                          valueRef={valueRef}
                          changeVideoPosition={false}
                        />
                      </StyleStep>
                    </>
                  )}
                </Content>
                {step === annotationSteps.STYLE && (
                  <Footer>
                    <SwitchToClassicContainer
                      style={
                        selectedTemplate.annotationId ||
                        userData.customer.automotiveAccess !== '1'
                          ? {
                              visibility: 'hidden',
                            }
                          : {}
                      }
                      onClick={() => {
                        handleSwitchEditor(values);
                      }}
                    >
                      <MdOutlineAutorenew
                        color={colors.primary[100]}
                        style={{ marginRight: '5px' }}
                      />
                      Switch to simplified editor
                    </SwitchToClassicContainer>

                    <Gap gap='8px'>
                      <Button
                        variant='secondary'
                        text='Save as Template'
                        onClick={() => setStep(annotationSteps.SAVE_TEMPLATE)}
                        disabled={
                          !isValid || isSubmitting || !isAnnotationValid
                        }
                      />
                      <Button
                        text={
                          selectedTemplate.annotationId
                            ? 'Save Changes'
                            : 'Add Annotation'
                        }
                        disabled={
                          !isValid || isSubmitting || !isAnnotationValid
                        }
                        type='submit'
                      />
                    </Gap>
                  </Footer>
                )}
                {showTemplateNameModal && (
                  <ModalTemplateName
                    onSubmit={async title => {
                      title = title.trim();
                      if (!title) {
                        return;
                      }
                      const annotationData = await prepareAnnotation({
                        data: { ...values, title },
                        userData,
                        selectedImage,
                        time,
                      });

                      await creaateTemplate({
                        data: annotationData,
                      });

                      handleModalClose();
                      submitForm();
                    }}
                    handleModalClose={() => setShowTemplateNameModal(false)}
                  />
                )}
                {showTemplateUpdateModal && (
                  <ModalDelete
                    whiteButtonText='No, Cancel'
                    orangeButtonText='Yes, Update'
                    title='Update Existing Template?'
                    text1={
                      <p>
                        You’re going to update Annotation Template{' '}
                        <strong>‘{values.title}’</strong>.
                      </p>
                    }
                    text={`This action can’t be undone.`}
                    handleModalClose={() => setShowTemplateUpdateModal(false)}
                    onClickWhiteButton={() => setShowTemplateUpdateModal(false)}
                    onClickOrangeButton={async () => {
                      const annotationData = await prepareAnnotation({
                        data: values,
                        time,
                        userData,
                        selectedImage,
                      });
                      await editTemplate({
                        ...annotationData,
                        templateId: values.templateId,
                      });
                      setShowTemplateUpdateModal(false);
                      handleModalClose();
                    }}
                  />
                )}
                {dirty && showSaveBeforeExitModal && (
                  <ModalDelete
                    whiteButtonText='Leave Without Saving'
                    orangeButtonText='Yes, Save'
                    title='Save before exit?'
                    text={`You have unsaved changes.`}
                    handleModalClose={() => setShowSaveBeforeExitModal(false)}
                    onClickWhiteButton={() => {
                      setShowSaveBeforeExitModal(false);
                      handleModalClose();
                    }}
                    onClickOrangeButton={async () => {
                      const annotationData = await prepareAnnotation({
                        data: values,
                        time,
                        selectedImage,
                        userData,
                      });

                      await editAnnotation({
                        ...annotationData,
                        templateId: values.templateId,
                      });
                      setSelectedAnnotation(defaultAnnotation);
                      setOpenClassicEditor(false);
                      setShowSaveBeforeExitModal(false);
                      handleModalClose();
                    }}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
