import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import { FiRefreshCw } from 'react-icons/fi';
import dayjs from 'dayjs';

interface LastFetchedProps {
  dataUpdatedAt: number;
  isRefetching: boolean;
  refetch: () => void;
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinningIcon = styled(FiRefreshCw)<{ loading: boolean }>`
  ${props =>
    props.loading &&
    css`
      animation: ${spin} 1s linear infinite;
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #4e5461;
`;

const RefreshWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const LastFetched = ({
  dataUpdatedAt,
  isRefetching,
  refetch,
}: LastFetchedProps) => {
  const [displayTime, setDisplayTime] = useState<null | number>(null);

  useEffect(() => {
    if (!isRefetching && dataUpdatedAt !== 0) {
      setDisplayTime(dataUpdatedAt);
    }
  }, [isRefetching, dataUpdatedAt]);

  if (!displayTime) {
    return null;
  }

  return (
    <RefreshWrapper onClick={() => refetch()}>
      <Paragraph>
        Last fetched on {dayjs(displayTime).format('MM/DD/YYYY')} at{' '}
        {dayjs(displayTime).format('h:mm:ss A')}
      </Paragraph>
      <SpinningIcon color='#4E5461' loading={isRefetching} />
    </RefreshWrapper>
  );
};
