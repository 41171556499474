import { FormikSwitchField } from 'lib/components/formik/FormikSwitchField';
import React from 'react';
import {
  RowWrapper,
  Section,
  SectionTitle,
  UserFormProps,
} from '../../../components/AdminFormComponents';

export const AddonSection = (props: UserFormProps) => {
  const { values, setFieldValue, isSubmitting } = props;

  return (
    <Section id='addonSection'>
      <SectionTitle>Add-ons</SectionTitle>
      <RowWrapper>
        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='transcriptionAccess'
          label='Enable Captions'
          onChange={() => {
            setFieldValue(`transcriptionAccess`, !values.transcriptionAccess);
          }}
          readOnly={isSubmitting}
        />
      </RowWrapper>
    </Section>
  );
};
