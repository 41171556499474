import React, { useState } from 'react';
import { useTheme } from 'styled-components/macro';
import {
  MdKeyboardArrowRight,
  MdLock,
  MdFileDownload,
  MdShare,
  MdDeleteForever,
  MdEdit,
} from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { HiPencil } from 'react-icons/hi';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { LoadingIndicator, ModalDelete, VideoTag } from 'lib/components';
import { theme } from 'lib/style';
import { addThousandCommaSeparator, formatBytes } from 'lib/utils/functions';
import { getShortLink } from 'lib/utils/drop';
import { useAuth } from 'lib/context';
import {
  SetBoardsModal,
  SetPasswordModal,
  TagsModal,
  RenameFileModal,
} from '..';
import { VideoPlayer } from '../../../video/videoPlayer';
import FileSharingModal from '../FileSharingModal';
import { successToast } from 'lib/components/toasts/success';
import Switch from '../../../design/landingPageBuilder/components/Switch';
import { NotFound } from 'app/pages/notFound/NotFound';
import {
  DROPTYPE_OPTIONS,
  DROP_ACTIVITY_TYPE,
  getIconForFileType,
} from 'lib/const/Droplr';
import {
  CombinedBoard,
  DropActivity,
  DropItem,
  SimplifiedDropActivity,
} from 'lib/api/droplr/types';
import {
  ActionsButtons,
  ActivityDate,
  ActivityItem,
  ActivityList,
  ActivityTitle,
  Container,
  Content,
  EditIconWrapper,
  Heading,
  Image,
  ImagePreview,
  ImageWrapper,
  Info,
  Label,
  LabelValue,
  LinkBtn,
  LinkText,
  LinkWrapper,
  Menu,
  NoTags,
  Permissions,
  RemainingDropdown,
  Sidebar,
  SidebarHeader,
  SidebarTitle,
  SubHeading,
  Tab,
  ThumbnailContainer,
  ToggleWrapper,
  Views,
} from './styles';
import { useGetDrop } from 'lib/api/droplr/getDrop';
import { useGetBoards } from 'lib/api/droplr/getBoards';
import { useGetCurrentDroplrUser } from 'lib/api/droplr/getCurrentDroplrUser';
import { useGetDropActivities } from 'lib/api/droplr/getActivities';
import { useDownloadDrop } from 'lib/api/droplr/downloadDrop';
import { useEditDrop } from 'lib/api/droplr/editDrop';
import { useDeleteDrop } from 'lib/api/droplr/deleteDrop';
import { Button } from 'react-covideo-common';
import { IoMdLink } from 'react-icons/io';

dayjs.extend(relativeTime);

const tabs = [
  {
    title: 'Info',
    value: 'info',
  },
  {
    title: 'Activity',
    value: 'activity',
  },
];

const sharedTabs = [
  {
    title: 'Info',
    value: 'info',
  },
];

type Option = {
  label: string;
  value: string;
};

export const DetailsDrops = () => {
  const [activeTab, setActiveTab] = React.useState(tabs[0].value);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showPasswordModal, setShowPasswordModal] = React.useState(false);
  const [showRemainingTags, setShowRemainingTags] = useState(false);
  const [showRemainingBoards, setShowRemainingBoards] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showBoardsModal, setShowBoardsModal] = useState(false);
  const [tags, setTags] = React.useState([] as Option[]);
  const [boards, setBoards] = React.useState([] as Option[]);
  const [isDownloadable, setIsDownloadable] = React.useState(false);
  const [activities, setActivities] = React.useState(
    [] as SimplifiedDropActivity[]
  );
  const [showRenameFileModal, setShowRenameFileModal] = React.useState(false);
  const [title, setTitle] = React.useState('');

  const history = useHistory();
  const { dropId, dropType } = useParams<{
    dropId: '';
    dropType: DROPTYPE_OPTIONS.OWNED;
  }>();
  const { userData } = useAuth();
  const [dropOwnerId, setDropOwnerId] = React.useState(-1);
  const [showFileShareModal, setShowFileShareModal] = React.useState(false);
  const themes = useTheme();

  const {
    data: drop,
    isLoading: loading,
    refetch: refetchDrop,
  } = useGetDrop({
    dropId,
  });
  const { data: boardsResponse } = useGetBoards({
    userId: Number(userData.id),
    customerId: Number(userData.customerId),
  });

  const { boards: boardItems } = boardsResponse || { boards: [] };
  const { data: droplrUser } = useGetCurrentDroplrUser();
  const { data: dropActivityData } = useGetDropActivities(dropId);
  const { data: dropActivities } = dropActivityData || { data: [] };
  const { id: droplrUserId } = droplrUser || { id: '' };

  const { mutateAsync: downloadDrop } = useDownloadDrop();
  const { mutateAsync: editDrop } = useEditDrop();
  const { mutateAsync: deleteDrop } = useDeleteDrop();

  React.useEffect(() => {
    if (dropActivities && dropActivities.length) {
      const newActivities = dropActivities
        .filter(
          (activity: DropActivity) =>
            activity.type === DROP_ACTIVITY_TYPE.VIEWED ||
            activity.type === DROP_ACTIVITY_TYPE.DOWNLOADED
        )
        .map((activity: DropActivity) => ({
          id: activity.id,
          type: activity.type,
          user: activity.metadata,
          text:
            activity.type === DROP_ACTIVITY_TYPE.VIEWED
              ? 'User viewed drop'
              : 'User downloaded drop',
          createdAt: activity.createdAt,
        }));
      setActivities(newActivities);
    }
  }, [dropActivities]);

  React.useEffect(() => {
    if (drop) {
      setDropOwnerId(drop.owner);
      setTitle(drop.title);
      setIsDownloadable(!!drop?.downloadable);

      if (drop.tags) {
        setTags(
          drop.tags.map((tag: string) => ({
            label: tag,
            value: tag,
          }))
        );
      }
    }
  }, [drop]);

  React.useEffect(() => {
    refetchDrop();
  }, [dropId]);

  React.useEffect(() => {
    if (drop && drop.boards && boardItems.length) {
      // board ids on drop
      const dropBoardIds = drop.boards;
      const currentBoards = boardItems
        .filter(bi => dropBoardIds.includes(bi.id))
        .map((bi: CombinedBoard) => ({
          label: bi.name,
          value: bi.id,
        }));

      setBoards(currentBoards);
    }
  }, [drop, boardItems]);

  if (loading) {
    return (
      <Container>
        <LoadingIndicator isLoading={loading} height='500px' />
      </Container>
    );
  }

  if (!drop) {
    return <NotFound />;
  }

  const onTagHoverOut = () => {
    setTimeout(() => {
      setShowRemainingTags(false);
    }, 200);
  };

  const onBoardHoverOut = () => {
    setTimeout(() => {
      setShowRemainingBoards(false);
    }, 200);
  };

  const copyLink = () => {
    const cb = navigator.clipboard;

    cb.writeText(getShortLink(drop)).then(() =>
      successToast({ title: `Link to files copied successfully!` })
    );
  };

  const goToDrops = () => history.push(`/files`);

  const getPreviewForDrop = (drop: DropItem) => {
    if (drop.type === 'VIDEO') {
      return (
        <VideoPlayer
          playerBackgroundColor={theme.palette.primaryDarkBlue}
          videoSource={drop.content}
          playButtonPosition='none'
          playerIconsColor={'#fff'}
          thumbnail={drop.previewMedium}
          videoRef={React.createRef()}
        />
      );
    }
    if (drop.type !== 'FILE' || drop.previewMedium) {
      return <Image src={drop.previewMedium || drop.content} />;
    }

    if (drop.previewMedium) {
      return <Image src={drop.previewMedium} />;
    }
    const icon = getIconForFileType(drop.variant, 'grid', '#001B53');
    const dateOptions: any = { dateStyle: 'long', timeStyle: 'short' };
    const dropCreationTime = new Intl.DateTimeFormat(
      'en-US',
      dateOptions
    ).format(drop.createdAt || new Date());
    let fileSize = formatBytes(drop.size);
    return (
      <>
        <ThumbnailContainer>{icon}</ThumbnailContainer>
        <Heading>{drop.title}</Heading>
        <SubHeading>
          {fileSize}&nbsp; • &nbsp;{dropCreationTime}
        </SubHeading>
        {isDownloadable && (
          <Button
            text='Download'
            icon={<MdFileDownload />}
            onClick={() => downloadDrop({ dropId })}
          />
        )}
      </>
    );
  };

  return (
    <Container>
      <>
        {showFileShareModal && droplrUserId === dropOwnerId && (
          <FileSharingModal
            drop={drop}
            handleModalClose={() => {
              setShowFileShareModal(false);
            }}
          />
        )}
        <Menu>
          <LinkWrapper>
            <LinkBtn onClick={goToDrops}>Files</LinkBtn>
            <MdKeyboardArrowRight size={24} />
            <LinkText>{title}</LinkText>
            {droplrUserId === dropOwnerId && (
              <EditIconWrapper>
                <MdEdit
                  size='18px'
                  color='rgba(0, 27, 83, 0.4)'
                  onClick={() => setShowRenameFileModal(true)}
                />
              </EditIconWrapper>
            )}
          </LinkWrapper>
          <ActionsButtons display='flex' gap='8px'>
            {droplrUserId === dropOwnerId && (
              <>
                <Button
                  variant='secondary'
                  icon={<IoMdLink size={18} />}
                  onClick={() => copyLink()}
                />

                <Button
                  variant='secondary'
                  icon={<MdShare />}
                  onClick={() => setShowFileShareModal(true)}
                />

                <Button
                  variant='secondary'
                  icon={<MdLock />}
                  onClick={() => setShowPasswordModal(true)}
                />

                <Button
                  variant='destructive'
                  icon={<MdDeleteForever color={theme.palette.buttonDelete} />}
                  onClick={() => setShowDeleteModal(true)}
                />

                {drop.type !== 'FILE' && drop.type !== 'VIDEO' && (
                  <Button
                    variant='secondary'
                    text='Markup'
                    icon={<HiPencil />}
                    onClick={() =>
                      window.open(
                        `https://files.covideo.com/draw/${dropId}`,
                        '_blank'
                      )
                    }
                  />
                )}
              </>
            )}
            {isDownloadable && (
              <Button
                text='Download'
                icon={<MdFileDownload />}
                onClick={() => downloadDrop({ dropId })}
              />
            )}
          </ActionsButtons>
        </Menu>
        <Content>
          <ImageWrapper>
            <ImagePreview>{getPreviewForDrop(drop)}</ImagePreview>
          </ImageWrapper>
          <Sidebar>
            <SidebarHeader>
              {(dropType === DROPTYPE_OPTIONS.OWNED ? tabs : sharedTabs).map(
                tab => (
                  <Tab
                    key={tab.value}
                    onClick={() => setActiveTab(tab.value)}
                    className={tab.value === activeTab ? 'active' : ''}
                  >
                    <div>{tab.title}</div>
                  </Tab>
                )
              )}
            </SidebarHeader>
            <div>
              {activeTab === tabs[0].value && (
                <>
                  {dropType === DROPTYPE_OPTIONS.OWNED && (
                    <Permissions>
                      <ToggleWrapper>
                        <span>Download permission</span>
                        <Switch
                          id={'switch-download'}
                          isOn={isDownloadable}
                          onColor={themes.colors.primary[100]}
                          handleToggle={async (event: any) => {
                            event.persist();
                            const downloadable = event.target.checked;
                            await editDrop({
                              dropId,
                              downloadable,
                            });
                            setIsDownloadable(downloadable);
                          }}
                        />
                      </ToggleWrapper>
                    </Permissions>
                  )}
                  <Info>
                    <div style={{ display: 'flex' }}>
                      <Label>Size</Label>
                      <LabelValue>{formatBytes(drop.size)}</LabelValue>
                    </div>

                    <div
                      style={{
                        cursor:
                          dropType === DROPTYPE_OPTIONS.OWNED
                            ? 'pointer'
                            : 'default',
                      }}
                      onClick={() =>
                        dropType === DROPTYPE_OPTIONS.OWNED
                          ? setShowBoardsModal(true)
                          : undefined
                      }
                    >
                      <SidebarTitle>
                        {dropType === DROPTYPE_OPTIONS.OWNED
                          ? 'Manage Boards'
                          : 'Boards'}
                      </SidebarTitle>
                      {!boards.length && <NoTags>No Boards.</NoTags>}
                      {!!boards.length && (
                        <>
                          <VideoTag tag={boards[0]} />
                          {boards.length > 1 && (
                            <VideoTag
                              tag={{ label: '', value: '' }}
                              onMouseEnter={() => setShowRemainingBoards(true)}
                              onMouseLeave={onBoardHoverOut}
                            >
                              +{boards.length - 1}
                              {showRemainingBoards && (
                                <RemainingDropdown>
                                  {boards.slice(1).map(board => (
                                    <VideoTag key={board.value} tag={board} />
                                  ))}
                                </RemainingDropdown>
                              )}
                            </VideoTag>
                          )}
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        cursor:
                          dropType === DROPTYPE_OPTIONS.OWNED
                            ? 'pointer'
                            : 'default',
                      }}
                      onClick={() =>
                        dropType === DROPTYPE_OPTIONS.OWNED
                          ? setShowTagsModal(true)
                          : undefined
                      }
                    >
                      <SidebarTitle>
                        {dropType === DROPTYPE_OPTIONS.OWNED
                          ? 'Manage Tags'
                          : 'Tags'}
                      </SidebarTitle>

                      {!tags.length && <NoTags>No tags.</NoTags>}
                      {!!tags.length && (
                        <>
                          <VideoTag tag={tags[0]} />
                          {tags.length > 1 && (
                            <VideoTag
                              tag={{ label: '', value: '' }}
                              onMouseEnter={() => setShowRemainingTags(true)}
                              onMouseLeave={onTagHoverOut}
                            >
                              +{tags.length - 1}
                              {showRemainingTags && (
                                <RemainingDropdown>
                                  {tags.slice(1).map(tag => (
                                    <VideoTag key={tag.value} tag={tag} />
                                  ))}
                                </RemainingDropdown>
                              )}
                            </VideoTag>
                          )}
                        </>
                      )}
                    </div>
                  </Info>
                  <Views>
                    <Label>Views</Label>
                    <LabelValue>
                      {addThousandCommaSeparator(drop.views)}
                    </LabelValue>
                  </Views>
                </>
              )}
              {activeTab === tabs[1].value && (
                <ActivityList>
                  {activities.map(activity => (
                    <ActivityItem key={activity.id}>
                      <ActivityTitle>{activity.text}</ActivityTitle>
                      <ActivityDate>
                        {dayjs(activity.createdAt).fromNow()}
                      </ActivityDate>
                    </ActivityItem>
                  ))}
                </ActivityList>
              )}
            </div>
          </Sidebar>
        </Content>
      </>
      {showDeleteModal && (
        <ModalDelete
          whiteButtonText='Yes, Delete'
          orangeButtonText='No, Cancel'
          title={`You want to move file to recently deleted bin?`}
          text={`Files are automatically deleted after 30 days.`}
          handleModalClose={() => setShowDeleteModal(false)}
          onClickWhiteButton={async () => {
            try {
              await deleteDrop(dropId);
              setShowDeleteModal(false);
              goToDrops();
              successToast({
                title:
                  'You have successfully moved files to recently deleted bin',
              });
            } catch (e) {
              console.log(e);
            }
          }}
          onClickOrangeButton={() => setShowDeleteModal(false)}
        />
      )}
      {showPasswordModal && (
        <SetPasswordModal
          dropIds={[dropId]}
          handleModalClose={() => setShowPasswordModal(false)}
        />
      )}
      {showTagsModal && (
        <TagsModal
          dropIds={[dropId]}
          handleModalClose={() => setShowTagsModal(false)}
        />
      )}
      {showBoardsModal && (
        <SetBoardsModal
          dropIds={[dropId]}
          handleModalClose={() => setShowBoardsModal(false)}
        />
      )}
      {showRenameFileModal && (
        <RenameFileModal
          drop={drop}
          handleModalClose={() => setShowRenameFileModal(false)}
          setUpdatedTitle={setTitle}
        />
      )}
    </Container>
  );
};
