import { NotFound } from 'app/pages/notFound/NotFound';
import { useManagedDepartmentsQuery } from 'lib/api/departments/useManagedDepartmentsQuery';
import { useGetLinkSetsQuery } from 'lib/api/exitLinkSets/getExitLinksSets';
import { useGetOverlays } from 'lib/api/overlays/getOverlaysQuery';
import { useGetTemplatesQuery } from 'lib/api/templates/getTemplatesQuery';
import { useManagedUsersQuery } from 'lib/api/users/useManagedUsersQuery';
import { useUserProfileQuery } from 'lib/api/users/useUsersProfileQuery';
import { LoadingIndicator, Tabs } from 'lib/components';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { useAuth } from 'lib/context';
import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { AddonsTab } from './AddonsTab';
import { UserInfoTab } from './UserInfoTab';

const Content = styled.div`
  width: 100%;
  & .tab-container {
    max-width: 100%;
  }
  & .tab-navigation {
    padding: 0;
    justify-content: flex-start;
    max-width: 100%;
    margin-bottom: 32px;
  }
  & .tab {
    margin-right: 32px;

    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  & .tab-content {
    padding: 0;
    width: 100%;
  }
`;

export const ManageUsersPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    userData: { customerId },
  } = useAuth();

  const { data, isLoading: isLoadingManagedUsers } = useManagedUsersQuery({
    customerId,
  });

  const { id } = useParams() as { id: string | undefined };

  const { data: departments, isLoading: isLoadingDepartments } =
    useManagedDepartmentsQuery({ customerId });

  const { data: templates, isLoading: isLoadingTemplates } =
    useGetTemplatesQuery({
      limit: 1000,
      showUserCustomLandingPages: false,
    });

  const { data: websiteOverlays, isLoading: isLoadingWebsiteOverlays } =
    useGetOverlays({});

  const { data: linksetsData } = useGetLinkSetsQuery({ limit: 1000 });
  const { data: currentUser, isLoading } = useUserProfileQuery({
    userId: id ? id : '',
  });

  if (
    isLoading ||
    isLoadingTemplates ||
    isLoadingWebsiteOverlays ||
    isLoadingDepartments ||
    isLoadingManagedUsers
  ) {
    return <LoadingIndicator isLoading={true} />;
  }

  if (!currentUser && !!id) {
    return <NotFound />;
  }

  const isExceeded =
    (data?.totalActiveUsers ?? 0) >= (data?.totalAllowedUsers ?? 0);

  return (
    <Content>
      <Container>
        <MainWrapper resetPadding={false}>
          {activeTab === 0 && (
            <UserInfoTab
              currentUser={currentUser}
              departments={departments}
              templates={templates}
              websiteOverlays={websiteOverlays}
              linksetsData={linksetsData}
              isExceeded={isExceeded}
            >
              {!!id ? (
                <Tabs
                  tabs={[
                    {
                      title: 'User Info',
                      takeConfirmation: true,
                    },
                    {
                      title: 'Add-ons',
                      takeConfirmation: true,
                    },
                  ]}
                  defaultActiveTab={activeTab}
                  onChange={setActiveTab}
                  takeConfirmation={true}
                />
              ) : (
                <></>
              )}
            </UserInfoTab>
          )}

          {activeTab === 1 && (
            <AddonsTab currentUser={currentUser}>
              {!!id ? (
                <Tabs
                  tabs={[
                    {
                      title: 'User Info',
                      takeConfirmation: true,
                    },
                    {
                      title: 'Add-ons',
                      takeConfirmation: true,
                    },
                  ]}
                  defaultActiveTab={activeTab}
                  onChange={setActiveTab}
                  takeConfirmation={true}
                />
              ) : (
                <></>
              )}
            </AddonsTab>
          )}
        </MainWrapper>
      </Container>
    </Content>
  );
};
