import * as React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { useHistory } from 'react-router-dom';
import { MdContacts } from 'react-icons/md';
import placeholder from 'assets/images/table/placeholderBig.webp';
import { ParagraphNormal } from 'lib/components/styles/typography';

const NoContactLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
  gap: 32px;
`;

export const NoLeadsInGroup = () => {
  const history = useHistory();
  return (
    <NoContactLayout>
      <ParagraphNormal textAlign='center' style={{ lineHeight: '32px' }}>
        <strong>This group has no customers</strong>
        <br />
        Add customers to this Group from your customer list
      </ParagraphNormal>
      <Button
        text='View customers'
        onClick={() => history.push('/customers')}
        icon={<MdContacts />}
      />
    </NoContactLayout>
  );
};
