import React, { useEffect } from 'react';

import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import { Flex } from 'lib/components/styles/layout';
import {
  ParagraphNormalBold,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { FormikRadioField } from 'lib/components/formik/FormikRadioField';
import { FormikLabel } from 'lib/components/formik/FormikLabel';
import { ButtonSwitch } from 'lib/components';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { AddonsFormikValues } from './AddonsTab';
import { SHOW_TAB_CHANGE_POPUP } from './utils';

export const AddonsForm = () => {
  const views = [
    {
      value: 'enabled',
      text: 'Enabled',
    },
    {
      value: 'disabled',
      text: 'Disabled',
    },
  ];
  const history = useHistory();
  const { values, setFieldValue, dirty } =
    useFormikContext<AddonsFormikValues>();

  useEffect(() => {
    if (dirty) {
      localStorage.setItem(SHOW_TAB_CHANGE_POPUP, '1');
      return;
    }
    localStorage.setItem(SHOW_TAB_CHANGE_POPUP, '0');
  }, [dirty]);

  return (
    <>
      <Flex gap='16px'>
        <Flex flexDirection='row'>
          <ParagraphNormalBold style={{ maxWidth: 270, width: '100%' }}>
            Captions:
          </ParagraphNormalBold>

          <Flex flexDirection='column' alignItems='flex-start' gap='32px'>
            <Flex flexDirection='row' alignItems='flex-start' gap='10px'>
              <FormikLabel
                label={'Access'}
                labelStyles={{ maxWidth: 200, width: '100%' }}
              />
              <Flex flexDirection='row' alignItems='center' gap='80px'>
                <ButtonSwitch
                  defaultValue={values.transcriptionAccess}
                  values={views}
                  onChange={newValue => {
                    setFieldValue('transcriptionAccess', newValue);
                  }}
                />
                <ParagraphSmall
                  style={{
                    cursor: 'pointer',
                    color: '#001b53',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    history.push('/profile/add-ons/captions/manage');
                  }}
                >
                  Manage Captions
                </ParagraphSmall>
              </Flex>
            </Flex>
            <Flex flexDirection='row' alignItems='flex-start' gap='10px'>
              <FormikLabel
                label={'Auto Transcription'}
                labelStyles={{ maxWidth: 200, width: '100%' }}
              />
              <Flex flexDirection='column' alignItems='flex-start' gap='16px'>
                <FormikRadioField
                  name='autoTranscribe'
                  label='Let user choose videos to transcribe'
                  value={0}
                />
                <FormikRadioField
                  name='autoTranscribe'
                  label='Automatically transcribe new video'
                  value={1}
                />
              </Flex>
            </Flex>

            <Flex flexDirection='row' alignItems='flex-start' gap='10px'>
              <FormikLabel
                label={'Visibility'}
                labelStyles={{ maxWidth: 200, width: '100%' }}
              />
              <Flex flexDirection='column' alignItems='flex-start' gap='16px'>
                <FormikRadioField
                  name='transcriptionDefaultEnabled'
                  label='Let user verify captions before showing'
                  value={0}
                />
                <FormikRadioField
                  name='transcriptionDefaultEnabled'
                  label='Show captions by default on all videos'
                  value={1}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <RouteLeavingGuard
        when={dirty}
        stay={true}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return true;
        }}
        title='Leave without saving changes?'
        text='Your updates will not be saved. This action can’t be undone.'
        confirmButtonText='Continue'
        discardButtonText='Leave'
      />
    </>
  );
};
