import React from 'react';
import styled from 'styled-components/macro';
import { Field, ErrorMessage, FieldProps, Form } from 'formik';
import { Dropdown, TextInput } from 'lib/components';
import { NumberInputField } from './NumberInputField';
import { SuperAdminCustomerDetails, UserDetailsData } from 'lib/context';
import { Option } from 'lib/components/dropdown/Dropdown';
import { CustomPasswordErrorMessage } from './PasswordTooltip';

export interface FormProps {
  isNewCustomer: boolean;
  isSubmitting?: boolean;
  setFieldValue(
    field: string,
    value: string | number | object | undefined | boolean
  ): void;
  values: SuperAdminCustomerDetails;
  dirty?: boolean;
  onFormDirty?: (isFormDirty: boolean) => void;
}

export interface UserFormProps {
  isNewUser: boolean;
  isSubmitting?: boolean;
  setFieldValue(
    field: string,
    value: string | number | object | boolean | undefined
  ): void;
  values: UserDetailsData;
  customerName?: string;
  dirty: boolean;
  isUsernameValid?: boolean;
  resellerId?: number;
  optionalError: { message?: string; password?: string };
  isAutomotive?: boolean;
  isVerifiedDisabled?: boolean;
  hasEmail2Field?: boolean;
}

export const Red = styled.label`
  color: ${({ theme }) => theme.colors.primary[100]};
`;

export const FieldContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  .user__desc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #4e5461;
  }
  .error {
    color: red;
    font-size: 14px;
    padding: 0.1rem;
  }
`;

export const ErrorMsg = styled.p`
  color: red;
  font-size: 14px;
  padding: 0.1rem;
`;

interface RowProps {
  alignItem?: string;
  justifyContent?: string;
  flexDirection?: string;
  gap?: string;
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  gap: ${props => (props.gap ? props.gap : '0')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'start'};
  align-items: ${props => (props.alignItem ? props.alignItem : 'flex-start')};

  width: 100%;
  padding: 0.5rem 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FieldLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 150px;
  color: rgba(146, 151, 162, 1);
  margin-right: 16px;
  display: flex;
`;

export const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  width: 100%;
  max-width: 180px;
  line-height: 24px;
`;

export const RowWrapper = styled.div`
  width: 100%;
  div:first-child,
  p:first-child {
    padding-top: 0px;
  }
`;

export const Section = styled.div`
  display: flex;
  max-width: 700px;
`;

export const getInputField = (
  name: string,
  required: boolean,
  readOnly: boolean,
  isSubmitting?: boolean,
  type: string = 'text',
  disabled: boolean = false
) => {
  return (
    <Field type={type} name={name} disabled={disabled} autoComplete='off'>
      {({ field }: FieldProps) => {
        return (
          <FieldContainer>
            <TextInput
              {...field}
              type={type}
              autoComplete='new-password'
              required={required}
              readOnly={isSubmitting || readOnly}
              disabled={disabled}
            />
            {name !== 'password' ? (
              <ErrorMessage name={name} component={ErrorMsg} />
            ) : (
              <CustomPasswordErrorMessage name='password' />
            )}
          </FieldContainer>
        );
      }}
    </Field>
  );
};

export const getNumberInputField = (
  name: string,
  required: boolean,
  readOnly: boolean,
  isSubmitting?: boolean,
  type: string = 'number',
  disabled: boolean = false
) => {
  return (
    <Field type={type} name={name} disabled={disabled}>
      {({ field }: FieldProps) => {
        return (
          <FieldContainer>
            <NumberInputField
              {...field}
              width='100%'
              required={required}
              readOnly={isSubmitting || readOnly}
              disabled={disabled}
            />
            <ErrorMessage name={name} component={ErrorMsg} />
          </FieldContainer>
        );
      }}
    </Field>
  );
};

export const getDropdownField = (
  name: string,
  placeholder: string,
  options: Array<{ [key: string]: string | number | boolean }>,
  value: string | number | boolean,
  onChange: (option: Option) => void
) => {
  return (
    <Field type='select'>
      {() => {
        return (
          <FieldContainer>
            <Dropdown
              creatable={false}
              onChange={onChange}
              options={options}
              value={options.find(
                (o: { [key: string]: string | number | boolean }) => {
                  return o.value === value;
                }
              )}
              placeholder={placeholder}
            />
          </FieldContainer>
        );
      }}
    </Field>
  );
};

export const FlexForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .upgrade-button {
    display: flex;
    margin-left: auto;
  }
`;
