import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import { Search, LoadingIndicator, Dropdown } from 'lib/components';
import { LeadItem } from 'lib/context';
import { exportCSVFile } from 'lib/utils/functions';
import { MdAdd } from 'react-icons/md';
import { useToastError } from 'lib/hooks';
import { ILead, useLeadsQuery } from 'lib/api/leads/getLeads';
import { useAddLeadMutation } from 'lib/api/leads/useAddLead';
import { LeadTable } from '../components/LeadTable';
import { ModalCreateLead } from '../components/ModalCreateLead';
import { FixedCounterHeader } from '../components/FixedCounterHeader';
import { Gap } from 'lib/components/styles/layout';
import { ModalAddCustomerToGroup } from '../components/ModalAddCustomerToGroup';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { GenericCountFilter } from 'lib/api';

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 530px;
`;

const exportHeader = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  phone: 'phone',
  addedOn: 'Added on',
  status: 'Status',
};

type LeadsFilter = {
  [key: string]: {
    label: string;
    value: string;
    sendsFilter: GenericCountFilter;
  };
};

const leadsFilter: LeadsFilter = {
  ALL: {
    label: 'Show All',
    value: 'ALL',
    sendsFilter: GenericCountFilter.ALL,
  },
  ONLY_WITH_SENDS: {
    label: 'Only contacted',
    value: 'ONLY_WITH_SENDS',
    sendsFilter: GenericCountFilter.WITH_COUNT,
  },
  ONLY_WITHOUT_SENDS: {
    label: 'Only not contacted',
    value: 'ONLY_WITHOUT_SENDS',
    sendsFilter: GenericCountFilter.WITHOUT_COUNT,
  },
};

export const CustomerLeadsPage = () => {
  const { showError } = useToastError();
  const [showCreateLeadModal, setShowCreateLeadModal] = useState(false);
  const [showAddCustomerToGroupModal, setShowAddCustomerToGroupModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [selectedLead, setSelectedLead] = useState<number[]>([]);
  const filterOptions = Object.values(leadsFilter);
  const [filterValue, setFilterValue] = useState(leadsFilter.ALL.value);

  const sendsFilter = leadsFilter[filterValue as keyof LeadsFilter].sendsFilter;

  const {
    data: leads,
    refetch: refreshLeads,
    isLoading,
  } = useLeadsQuery(
    { size, page, sort: '-', search: searchQuery, sendsFilter },
    true
  );
  const { mutateAsync: mutateAddLeadAsync } = useAddLeadMutation();

  const leadsArray = leads?.leads || [];

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setPage(0);
  };

  const handleExportBatchClick = () => {
    const selectedLeadsArray = leadsArray.filter((lead: ILead) => {
      return selectedLead.find((id: string | number) => id === lead?.leadId);
    });
    exportCSVFile(exportHeader, selectedLeadsArray, 'selected_customers');
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
    if (page !== newPage) {
      setSelectedLead([]);
    }
  };

  const handleSubmit = async (data: Partial<LeadItem>) => {
    try {
      await mutateAddLeadAsync(data);
      refreshLeads();
      setShowCreateLeadModal(false);
    } catch (error) {
      showError(error);
    }
  };

  const addToGroupHandler = () => {
    setShowAddCustomerToGroupModal(true);
  };

  const onModalCloseHandler = () => {
    setShowAddCustomerToGroupModal(false);
  };
  return (
    <>
      <FixedCounterHeader
        selectedIds={selectedLead}
        setSelectedIds={setSelectedLead}
        groupsArray={leadsArray.map(lead => lead.leadId)}
        handleExportBatchClick={handleExportBatchClick}
        addToGroupHandler={addToGroupHandler}
        label='Customers selected'
      />

      <Gap gap='12px' width='100%' justifyContent='space-between'>
        <Gap>
          <Search
            placeholder='Search Customers...'
            onSearch={onSearch}
            width='300px'
          />
          <Dropdown
            width={220}
            options={filterOptions}
            value={filterOptions.find(o => {
              return o.value === filterValue;
            })}
            onChange={option => setFilterValue(option.value)}
            placeholder={'Show all'}
          />
        </Gap>

        <Button
          onClick={() => setShowCreateLeadModal(true)}
          variant='primary'
          text='Create'
          icon={<MdAdd />}
        />
      </Gap>

      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={isLoading} />
        </div>
      ) : (
        <>
          {leads?.leads.length === 0 ? (
            <>
              {searchQuery !== '' ? (
                <EmptySearch>
                  <ParagraphSmallBold color={theme.palette.blue60}>
                    There are no groups with “{searchQuery}” in the title
                  </ParagraphSmallBold>
                  <ParagraphSmallBold color={theme.palette.blue60}>
                    Try using a different search term
                  </ParagraphSmallBold>
                </EmptySearch>
              ) : (
                <EmptySearch>
                  <ParagraphSmallBold color={theme.palette.blue60}>
                    Unfortunately, there aren't any customers available at the
                    moment.
                  </ParagraphSmallBold>
                </EmptySearch>
              )}
            </>
          ) : (
            <LeadTable
              leadsArray={leads?.leads || []}
              selectedLead={selectedLead}
              count={leads?.count || 0}
              page={page}
              size={size}
              onPaginationChange={onPaginationChange}
              setSelectedLead={setSelectedLead}
            />
          )}
        </>
      )}

      {showCreateLeadModal && (
        <ModalCreateLead
          handleModalClose={() => setShowCreateLeadModal(false)}
          handleSubmit={handleSubmit}
        />
      )}

      {showAddCustomerToGroupModal && (
        <ModalAddCustomerToGroup
          handleModalClose={onModalCloseHandler}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
        />
      )}
    </>
  );
};
