import React from 'react';
import { useHistory } from 'react-router';
import { LoadingIndicator } from 'lib/components';
import { Container, MainContainer, MainWrapper } from '../../index.styled';

import { Reseller, useAuth } from 'lib/context';

import { SuperAdminRole } from 'lib/const/SuperAdminRole';
import { SCREEN_VALUES } from 'lib/const/SuperAdminConstants';

import {
  ClickType,
  DetailsBreadcrumbs,
} from '../components/DetailsBreadcrumbs';

interface IProps {
  resellerId: number;
  fromScreen: string;
  customerId: number;
  loading: boolean;
  isEditView: boolean;
  config: {
    title: string;
  };
  reseller: Reseller | undefined;
  customer: {
    business: string;
  };
  customerName: string;
  children: any;
}

export const MainUserDetailsWrapper = ({
  resellerId,
  fromScreen,
  loading,
  customerId,
  config,
  isEditView,
  reseller,
  customer,
  customerName,
  children,
}: IProps) => {
  const history = useHistory();
  const { userData } = useAuth();
  const goToUserList = () => history.push(`/admin/users`);
  const goToCustomerList = () => history.push(`/admin/customers`);
  const goToResellerList = () => history.push(`/admin/resellers`);
  const goToResellerDetails = () =>
    history.push(`/admin/resellers/${resellerId}`);
  const goToCustomerDetails = () =>
    fromScreen === SCREEN_VALUES.RESELLERS
      ? history.push(
          `/admin/customers/${customerId}/${SCREEN_VALUES.RESELLERS}/${resellerId}`
        )
      : history.push(
          `/admin/customers/${customerId}/${SCREEN_VALUES.CUSTOMERS}/${resellerId}`
        );
  const showCustomerUserBreadcrumb =
    fromScreen === SCREEN_VALUES.USERS ||
    (userData.superAdminRole !== SuperAdminRole.ADMIN &&
      userData.superAdminRole !== SuperAdminRole.ACCESS);

  if (loading) {
    return <LoadingIndicator isLoading={loading} />;
  }

  return (
    <MainContainer>
      <MainWrapper>
        <Container>
          <DetailsBreadcrumbs
            businessName={isEditView ? customer.business : customerName}
            fromScreen={fromScreen}
            resellerName={reseller?.resellerName ?? ''}
            title={config.title}
            onClick={(type: ClickType) => {
              switch (type) {
                case ClickType.CUSTOMER_DETAILS:
                  goToCustomerDetails();
                  break;
                case ClickType.CUSTOMER_LIST:
                  goToCustomerList();
                  break;
                case ClickType.RESELLER_DETAILS:
                  goToResellerDetails();
                  break;
                case ClickType.RESELLER_LIST:
                  goToResellerList();
                  break;
                case ClickType.USER_LIST:
                  goToUserList();
                  break;
                default:
                  goToUserList();
                  break;
              }
            }}
            showCustomerUserBreadcrumb={showCustomerUserBreadcrumb}
          />
          {children}
        </Container>
      </MainWrapper>
    </MainContainer>
  );
};
