import { TextInput } from 'lib/components';
import { theme } from 'lib/style';
import styled, { css } from 'styled-components/macro';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;

  ${theme.mediaQueryMaxWidth.sm} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: 800;

  ${theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 20px;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.palette.gray20};
  width: 100%;
  margin-bottom: 32px;
`;

type TabProps = {
  active?: boolean;
};

export const Tab = styled.div<TabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 600;

  color: ${props =>
    props.active ? props.theme.colors.primary[100] : theme.palette.gray60};
  border-bottom: 4px solid
    ${props => (props.active ? props.theme.colors.primary[100] : 'transparent')};

  cursor: pointer;
  user-select: none;
  div {
    margin-left: 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;

  :first-child {
    padding: 0 0 0.5rem 0;
  }

  ${theme.mediaQueryMaxWidth.sm} {
    flex-direction: column;
  }
`;

export const RowTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  width: 176px;
  color: ${theme.palette.neutral80};
  flex-shrink: 0;

  ${theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 10px;
  }
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  width: 96px;
  color: ${theme.palette.midGrey};

  ${theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 5px;
  }
`;

export const EnhancedTextInput = styled(TextInput)`
  width: 372px;
  ${theme.mediaQueryMaxWidth.md} {
    max-width: 250px;
  }
`;

export const AdminContainer = styled.div``;

export const AdminTitle = styled.div`
  padding: 22px 0;
  font-size: 14px;
  font-weight: 500;
`;

export const SelectInputContainer = styled.div`
  position: relative;
`;

export const Email = styled.div`
  font-size: 14px;
  color: ${theme.palette.covideoBlue40};
  font-weight: 400;

  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
`;

export const Id = styled.div`
  margin-left: 15px;
  font-size: 14px;
  color: ${theme.palette.covideoBlue40};
  font-weight: 400;
`;

export const Name = styled.div`
  font-size: 14px;
  color: ${theme.palette.covideoBlue100};
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

export const CustomOptionLabel = styled.div<{ userSearch?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 12px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: ${theme.palette.covideoBlue02NonAlpha};
  }

  ${({ userSearch }) =>
    !userSearch &&
    css`
      justify-content: space-between;
    `}
`;

export const SearchResultsContainer = styled.div`
  border-radius: 8px;
  box-shadow:
    0px 6px 12px 0px rgba(66, 79, 104, 0.06),
    0px 0px 4px 0px rgba(66, 79, 104, 0.08);
  box-sizing: border-box;
  max-height: 266px;
  min-width: 100%;
  position: absolute;
  background-color: ${theme.palette.white};
`;

export const Badge = styled.div<{ isEmpty: boolean }>`
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 6px;
  font-weight: 600;
  text-align: center;
  color: ${theme.palette.white};
  margin-right: 8px;
  ${({ isEmpty }) =>
    !isEmpty &&
    css`
      background: ${theme.palette.gray100};
    `}
`;
