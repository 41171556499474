import React, { CSSProperties, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  ContentState,
  convertToRaw,
  EditorState,
  Modifier,
  convertFromHTML,
  RichUtils,
} from 'draft-js';
import { Editor, SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import './customEditorStyles.css';
import { AddVariableButton, CharacterCounter, SourceHTMLToggleButton } from '.';
import { theme } from 'lib/style';
import { SnippetType } from 'lib/const';
import {
  CtaFilesModal,
  ItemTypes,
} from 'app/pages/design/callsToAction/CtaFilesModal';
import {
  getDefaultPromptUseCase,
  prepareTextForSMS,
} from 'lib/utils/functions';
import { ModalChooseSnippet } from 'app/pages/snippets/components';
import sanitizeHtml from 'sanitize-html';
import { CovideoMagicButton, PROMPT_TYPE } from 'react-covideo-ai-assist';
import { getCurrentToken } from 'configs/api/token';
import { useAuth } from 'lib/context';
import { MdFolder } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { APP_ENVIROMENT } from 'configs/api/types';

type EditorWrapperProps = {
  padding: string;
};

const EditorWrapper = styled.div<EditorWrapperProps>`
  padding: ${({ padding }) => padding};
  position: relative;
`;

type HTMLEditorProps = {
  visible: boolean;
};

const HTMLEditor = styled.textarea<HTMLEditorProps>`
  display: block;
  min-height: 160px;
  box-sizing: border-box;
  resize: vertical;
  width: 100%;
  background: #1a1f29;
  color: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray40};
  border-radius: 5px;
  padding: 16px 24px;
  font-family: 'Courier New', monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
`;

const TemplatesButtonWrapper = styled.div<{ showBeneathEditor: boolean }>`
  ${({ showBeneathEditor }) =>
    showBeneathEditor &&
    css`
      position: absolute;
      bottom: 8px;
      right: 0;
    `}
`;

const ToolbarCustomButton = styled.div`
  margin-right: 8px;
`;

type Props = {
  onTextEditorChange: (text: string) => void;
  setTextEditorBody?: (text: string) => void;
  initialContent?: string;
  height?: string;
  customPadding?: string;
  placeholder?: string;
  editorWrapperClassname?: string;
  toolbar: {};
  hideAddVariableButton?: boolean;
  snippetButtonPosition?: 'hidden' | 'inline' | 'bottom';
  showFilesButton?: boolean;
  showSourceButton?: boolean;
  snippetType?: SnippetType;
  showCharacterCounter?: boolean;
  forceSoftNewLine?: boolean /** SUS-1003 changes **/;
  dataCy?: string;
  showMagicButton?: boolean;
  magicBtnLabel?: string;
  magicHoverPopup?: JSX.Element;
  defaultVin?: string;
  setSubject?: (value: string) => void;
  styles?: {
    wrapper?: CSSProperties;
  };
};

export const TextEditor = (props: Props) => {
  const {
    toolbar,
    onTextEditorChange,
    initialContent,
    height,
    customPadding,
    placeholder,
    hideAddVariableButton = false,
    snippetButtonPosition = 'hidden',
    showFilesButton = false,
    snippetType = SnippetType.NoType,
    showCharacterCounter = false,
    showSourceButton = false,
    editorWrapperClassname = 'automotive-wrapper',
    forceSoftNewLine = false /** SUS-1003 changes **/,
    dataCy,
    showMagicButton = false,
    magicBtnLabel = '',
    magicHoverPopup,
    defaultVin = '',
    setSubject,
    styles,
  } = props;

  const { userData } = useAuth();
  const [showHtmlEditor, setShowHtmlEditor] = useState(false);
  const [countCharacters, setCountCharacters] = useState(
    prepareTextForSMS(initialContent || '').length
  );
  const [showModalChooseSnippet, setShowModalChooseSnippet] = useState(false);
  const [showModalChooseFile, setShowModalChooseFile] = useState(false);
  const [editorHtml, setEditorHtml] = useState(initialContent);

  const blocksFromHTML = htmlToDraft(initialContent || '');
  const initialData = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialData)
  );

  const addFile = (text: string) => {
    let content = text || '';
    if (content && content.indexOf('<p>') !== 0) {
      content = `<a href='${sanitizeHtml(text)}' target='_blank'>${sanitizeHtml(text)}</a>&nbsp;`;
    }

    // Convert sanitized HTML to DraftJS content
    const { contentBlocks, entityMap } = htmlToDraft(content);

    // Create a new content state
    const newContentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    // Replace content in the editor
    const updatedContent = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      newContentState.getBlockMap()
    );

    // Push the new content to the editor state
    const newEditorState = EditorState.push(
      editorState,
      updatedContent,
      'insert-fragment'
    );

    // Update the editor state
    onEditorStateChange(newEditorState);
  };

  const cleanHTML = (dirtyHtml: string) => {
    const cleanHtml = sanitizeHtml(dirtyHtml, {
      allowedTags: [
        'div',
        'p',
        'b',
        'i',
        'em',
        'small',
        'strong',
        'a',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'img',
        'blockquote',
        'br',
        'hr',
        'ul',
        'ol',
        'li',
      ],
      allowedAttributes: {
        a: ['href', 'name', 'target'],
        img: ['src', 'srcset', 'alt', 'title', 'width', 'height'],
      },
    });
    return cleanHtml;
  };

  const onChangeHTMLEditor = (e: any) => {
    const editorHTML = e.target.value;
    let editor;
    const contentBlock = convertFromHTML(cleanHTML(editorHTML));
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editor = EditorState.createWithContent(contentState);
    } else {
      editor = EditorState.createEmpty();
    }
    onEditorStateChange(editor, editorHTML);
  };

  const handleReplaceContent = (text: string, subject?: string) => {
    let content = text || '';
    if (content && content.indexOf('<p>') !== 0) {
      content = `<p>${text}</p>`;
    }
    const blocksFromHTML = htmlToDraft(content);
    const blockData = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    onEditorStateChange(
      EditorState.push(editorState, blockData, 'insert-characters')
    );
    if (subject && setSubject) {
      setSubject(subject);
    }
  };

  const onEditorStateChange = (
    editorState: EditorState,
    htmlEditorContent = ''
  ) => {
    setEditorState(editorState);
    const editorContent =
      htmlEditorContent ||
      draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorHtml(editorContent);
    setCountCharacters(prepareTextForSMS(editorContent || '').length);
    onTextEditorChange(editorContent);
  };

  const getTextFromHTMLEditor = () =>
    prepareTextForSMS(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );

  let toolbarCustomButtons: JSX.Element[] = [];

  if (showCharacterCounter) {
    toolbarCustomButtons = [
      ...toolbarCustomButtons,
      <CharacterCounter count={countCharacters} />,
    ];
  }
  if (showFilesButton) {
    toolbarCustomButtons = [
      ...toolbarCustomButtons,
      <ToolbarCustomButton>
        <Button
          variant='secondary'
          text='Files'
          onClick={() => setShowModalChooseFile(true)}
          icon={<MdFolder style={{ marginRight: 4 }} size={18} />}
        />
      </ToolbarCustomButton>,
    ];
  }
  if (!hideAddVariableButton) {
    toolbarCustomButtons = [
      ...toolbarCustomButtons,
      <ToolbarCustomButton>
        <AddVariableButton
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      </ToolbarCustomButton>,
    ];
  }
  if (snippetButtonPosition !== 'hidden') {
    toolbarCustomButtons = [
      ...toolbarCustomButtons,
      <TemplatesButtonWrapper
        showBeneathEditor={snippetButtonPosition === 'bottom'}
      >
        <Button
          variant='secondary'
          text='Templates'
          onClick={() => setShowModalChooseSnippet(true)}
        />
      </TemplatesButtonWrapper>,
    ];
  }
  if (showSourceButton) {
    toolbarCustomButtons = [
      ...toolbarCustomButtons,
      <SourceHTMLToggleButton
        showHtmlEditor={showHtmlEditor}
        setShowHtmlEditor={setShowHtmlEditor}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />,
    ];
  }
  if (showMagicButton) {
    const defaultPromptType =
      snippetType === SnippetType.Sms ? PROMPT_TYPE.SMS : PROMPT_TYPE.EMAIL;
    const margin = showCharacterCounter ? '0 0 0 8px' : '0 0 0 auto';
    const defaultPromptUseCase = getDefaultPromptUseCase(userData.isAutomotive);
    toolbarCustomButtons = [
      ...toolbarCustomButtons,
      <CovideoMagicButton
        defaultPromptType={defaultPromptType}
        defaultPromptUseCase={defaultPromptUseCase}
        defaultVin={defaultVin}
        userInput={getTextFromHTMLEditor()}
        margin={margin}
        handleSubmitGeneratedContent={(content: string) =>
          handleReplaceContent(content)
        }
        variant='gradient'
        text={magicBtnLabel}
        hoverPopup={magicHoverPopup}
        token={getCurrentToken().token as string}
        userData={{
          customer: {
            business: userData.customer.business,
            hasCovideoMagic:
              userData?.customer?.hasCovideoMagic?.toString() === '1',
            markVideosAsSent: userData.customer.markVideosAsSent,
          },
          firstName: userData.firstName,
          isAutomotiveSalesRole: userData.isAutomotiveSalesRole,
          isAutomotiveServiceRole: userData.isAutomotiveServiceRole,
          isIMSEnabled: userData.isIMSEnabled,
          isAutomotive: userData.isAutomotive,
          isCompanyAdmin: userData.isCompanyAdmin,
        }}
        aiAssistTheme={'branding'}
        apiKey={process.env.REACT_APP_APIKEY}
        env={
          (process.env.REACT_APP_ENV as APP_ENVIROMENT) ||
          APP_ENVIROMENT.PRODUCTION
        }
      />,
    ];
  }

  /** SUS-1003 changes **/
  const handleReturn = (
    e: SyntheticKeyboardEvent,
    editorState2: EditorState
  ) => {
    if (forceSoftNewLine) {
      const newEditorState = RichUtils.insertSoftNewline(editorState2);
      if (newEditorState !== editorState2) {
        onEditorStateChange(newEditorState);
      }
      return true;
    }
    return false;
  };
  /******/

  return (
    <>
      <EditorWrapper
        padding={
          snippetButtonPosition === 'bottom' || showSourceButton
            ? '0 0 48px'
            : '0'
        }
        style={styles?.wrapper || {}}
      >
        <Editor
          webDriverTestID={dataCy}
          editorStyle={{
            minHeight: height || 300,
            background: theme.palette.white,
            border: `1px solid ${theme.palette.gray40}`,
            borderRadius: '5px',
            padding: customPadding || '16px 20px',
            fontFamily: 'Work Sans',
            marginTop: 0,
            cursor: 'text',
            display: showSourceButton && showHtmlEditor ? 'none' : 'block',
          }}
          placeholder={placeholder}
          editorState={editorState}
          wrapperClassName={`${editorWrapperClassname} ${
            showHtmlEditor ? 'disable-formatting-buttons' : ''
          }`}
          onEditorStateChange={onEditorStateChange}
          toolbar={toolbar}
          toolbarCustomButtons={toolbarCustomButtons}
          handlePastedText={() => false}
          handleReturn={handleReturn} /** SUS-1003 changes **/
        />

        <HTMLEditor
          visible={showSourceButton && showHtmlEditor}
          value={editorHtml}
          onChange={onChangeHTMLEditor}
        />
      </EditorWrapper>
      {showModalChooseSnippet && (
        <ModalChooseSnippet
          snippetType={snippetType}
          handleModalClose={() => setShowModalChooseSnippet(false)}
          handleSubmit={handleReplaceContent}
        />
      )}
      {showModalChooseFile && (
        <CtaFilesModal
          view={ItemTypes[0].value}
          selectedFile={''}
          handleModalClose={() => {
            setShowModalChooseFile(false);
          }}
          onSelect={(drop: any) => {
            addFile(drop.shortlink);
            setShowModalChooseFile(false);
          }}
        />
      )}
    </>
  );
};
