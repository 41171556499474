import React from 'react';
import { MdCheckCircle, MdAddCircle } from 'react-icons/md';
import styled, { useTheme } from 'styled-components/macro';
import { Gap, Stack } from '../styles/layout';
import { ParagraphNormalBold, ParagraphSmall } from '../styles/typography';
import { SingleAvailableFieldProps } from './types';

const FieldWrap = styled.div<{ isSelected: boolean }>`
  padding: 12px 20px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.neutral[20] : theme.colors.white[100]};
  display: flex;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[20]};
  border-radius: 8px;
  flex: 1;
  align-items: center;
  max-height: 74px;
  box-sizing: border-box;
`;

export const SingleAvailableField = ({
  field,
  isSelectionOrMaxReached,
  onAddClickHandler,
  isSelected,
}: SingleAvailableFieldProps) => {
  const { colors } = useTheme();
  return (
    <FieldWrap isSelected={isSelectionOrMaxReached}>
      <Stack alignItems='flex-start' gap='4px' style={{ flex: 1 }}>
        <ParagraphNormalBold color={colors.neutral[100]}>
          {field.label}
        </ParagraphNormalBold>
        {field.description && (
          <ParagraphSmall color={colors.neutral[80]}>
            {field.description}
          </ParagraphSmall>
        )}
      </Stack>
      <Gap
        gap='12px'
        onClick={() =>
          isSelectionOrMaxReached ? null : onAddClickHandler(field)
        }
        style={{ cursor: 'pointer' }}
      >
        {isSelected ? (
          <MdCheckCircle size={24} color={colors.neutral[40]} />
        ) : (
          <MdAddCircle size={24} color={colors.secondary[80]} />
        )}
      </Gap>
    </FieldWrap>
  );
};
