import { ICombinedUserData } from 'lib/context';
import { VirtualBackground } from '../types';
import { FORCED_VIRTUAL_BACKGROUND_OPTIONS } from 'lib/utils/productFeature';

const VB_STORAGE_KEY = 'covideoVirtualBackground';

export const getVirtualBackgroundFromStorage = (
  userData: ICombinedUserData
): VirtualBackground => {
  const { id } = userData;
  const forcedVirtualBackgroundOptions: string[] =
    FORCED_VIRTUAL_BACKGROUND_OPTIONS[userData.customerId.toString()];
  const initialVirtualBgState: VirtualBackground =
    forcedVirtualBackgroundOptions
      ? {
          id: '',
          index: 0,
          type: 'default',
          privacy: 'PUBLIC',
          url: forcedVirtualBackgroundOptions[0],
        }
      : {
          url: '',
          type: 'none',
          index: -1,
          privacy: 'PUBLIC',
          id: '',
        };
  const vbSelection = localStorage.getItem(VB_STORAGE_KEY) || '';
  if (!vbSelection && forcedVirtualBackgroundOptions) {
    return {
      id: '',
      index: 0,
      type: 'default',
      privacy: 'PUBLIC',
      url: forcedVirtualBackgroundOptions[0],
    };
  }
  try {
    const savedVirtualBackground: Record<string, VirtualBackground> =
      JSON.parse(vbSelection);
    if (savedVirtualBackground && typeof savedVirtualBackground === 'object') {
      return savedVirtualBackground?.[id] || initialVirtualBgState;
    }
    return initialVirtualBgState;
  } catch (ex) {
    return initialVirtualBgState;
  }
};

export const getAllVBFromStorage = (): Record<string, VirtualBackground> => {
  const vbSelection = localStorage.getItem(VB_STORAGE_KEY) || '';
  try {
    const savedVirtualBackground: Record<string, VirtualBackground> =
      JSON.parse(vbSelection);
    if (savedVirtualBackground && typeof savedVirtualBackground === 'object') {
      return savedVirtualBackground;
    }
    return {};
  } catch (ex) {
    return {};
  }
};

export const saveVirtualBackgroundToStorage = (
  currentVirtualBackground: VirtualBackground,
  id: string
) => {
  const currentVb = getAllVBFromStorage();
  const newItems = { ...currentVb, [id]: currentVirtualBackground };

  localStorage.setItem(VB_STORAGE_KEY, JSON.stringify(newItems));
};

export const removeVirtualBackgroundFromStorage = (id: string) => {
  const currentVb = getAllVBFromStorage(); // Retrieve all stored virtual backgrounds

  if (currentVb) {
    const { [id]: _, ...remainingItems } = currentVb;
    localStorage.setItem(VB_STORAGE_KEY, JSON.stringify(remainingItems));
  }
};
