import * as React from 'react';
import { Button } from 'react-covideo-common';
import { Modal } from 'lib/components';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  getSignedURLs,
  GetSignedUrlsParams,
  ListThumbnail,
  GetSignedUrlsResponse,
} from 'lib/api';
import { NAME_REGEX } from 'lib/utils/regexes';
import { ICombinedUserData, useAuth } from 'lib/context';
import { MdDeleteForever, MdImage, MdWarning } from 'react-icons/md';
import { uploadEmailThumbnail } from 'lib/api/designApi';
import { useToastError } from 'lib/hooks';
import { IoMdClose } from 'react-icons/io';

interface ComponentProps {
  data?: ListThumbnail;
  loading: boolean;
  mode: string;
  onAction: (p?: any) => void | Promise<void>;
  onDelete: (p?: any) => void | Promise<void>;
  onClose: (p?: any) => void;
}
interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 16)}px;
  &:last-of-type {
    margin-bottom: 0;
  }
  input {
    ${theme.mediaQueryMinWidth.md} {
      &:last-of-type {
        margin-left: 32px;
      }
    }
  }
  label {
    ${theme.mediaQueryMinWidth.md} {
      &:last-of-type {
        margin-left: 32px;
      }
    }
  }
`;

const WarningMessage = styled.span`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    margin-right: 16px;
  }
`;

const Label = styled.label<{ customWidth?: number }>`
  display: flex;
  width: ${p => (p.customWidth ? `${p.customWidth}px` : '50%')};
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.midGrey};
  margin: 0;
  padding: 0;
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const ThumbnailContainer = styled.img``;

const ThumbnailPlaceholder = styled.div`
  background-color: #f6f7f9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 308px;
  overflow: hidden;
`;

const FileInputHandler = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

const TextInput = styled.input`
  padding-left: 8px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-self: flex-end;
  color: red;
  margin-bottom: 8px;
`;
const MAX_TITLE_LENGTH = 50;

export const ModalAddEditThumbnail = (props: ComponentProps) => {
  const { data, mode, loading, onAction, onDelete, onClose } = props;
  const [thumbnailSource, setThumbnailSource] = React.useState(
    data ? data.thumbnail : ''
  );
  const [thumbnailName, setThumbnailName] = React.useState(
    data ? data.title : ''
  );
  const [thumbnailFile, setThumbnailFile] = React.useState<File>();
  const [uploadProgress, setUploadProgress] = React.useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });
  const [uploading, setUploading] = React.useState(false);
  const [thumbnailURL, setThumbnailURL] = React.useState('');
  const [error, setError] = React.useState('');
  const [showTitleError, setShowTitleError] = React.useState(false);
  let fileRef = React.createRef<HTMLInputElement>();
  let imageRef = React.createRef<HTMLImageElement>();
  const { userData } = useAuth();
  const { showError } = useToastError();

  const title = mode === 'add' ? 'New Thumbnail' : 'Edit Thumbnail';
  const buttonText = mode === 'add' ? 'Add Thumbnail' : 'Save Changes';
  const disableDeleteButton = (mode === 'edit' && !!data?.isDefault) || loading;

  const handleAdd = async () => {
    if (thumbnailSource === data?.thumbnail) {
      setUploadProgress({ ...uploadProgress, percentage: 1000 });
      setThumbnailURL(data.thumbnail);
      setUploading(false);
      return;
    }

    const signedUrlData: GetSignedUrlsParams = {
      fileName: thumbnailFile ? thumbnailFile.name : '',
      mimeType: thumbnailFile ? thumbnailFile.type : '',
      folder: 'user_icons',
    };
    const signedURLs: GetSignedUrlsResponse = await getSignedURLs(
      signedUrlData
    ).catch(err => err);
    if (signedURLs instanceof Error) {
      showError(signedURLs);
      return;
    }
    const fileData = {
      file: thumbnailFile as File,
      uploadURL: signedURLs.uploadUrl,
      mimeType: signedUrlData.mimeType,
    };
    uploadEmailThumbnail({ data: fileData, onUploadProgress });
    setThumbnailURL(signedURLs.downloadUrl);
  };

  React.useEffect(() => {
    if (uploadProgress.percentage >= 100) {
      setUploading(false);
      const params: { [key: string]: string | number } = {
        title: thumbnailName,
        thumbnail: thumbnailURL,
      };

      if (data?.id) {
        params.id = data.id;
      }
      onAction(params);
    }
  }, [uploadProgress]);

  React.useEffect(() => {
    if (
      mode === 'edit' &&
      !thumbnailFile &&
      thumbnailSource &&
      data &&
      data.type === 'icons'
    ) {
      let filenamePartsWithQueryParam = thumbnailSource.split('/');
      let filenameWithQuery =
        filenamePartsWithQueryParam &&
        filenamePartsWithQueryParam[
          filenamePartsWithQueryParam.length - 1
        ].split('?');
      let filename = filenameWithQuery && filenameWithQuery[0];
      fetch(thumbnailSource)
        .then(response => {
          return response.blob();
        })
        .then(data => {
          let file = new File([data], filename);
          setThumbnailFile(file);
        })
        .catch(ex => {
          console.log(ex);
        });
    }
  }, [mode, thumbnailSource, thumbnailFile, data?.type]);

  const generateName = handleNameGeneration(userData);
  const onChange = handleNameChange(setThumbnailName, setShowTitleError);
  const onUploadProgress = handleUploadProgress(setUploadProgress);
  const handleSelectedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    if (target && target.files && target.files.length) {
      let orgFile = target.files[0];
      const isValidType = /.*\.(gif|jpeg|jpg|png|bmp)/i.test(orgFile.name);
      if (isValidType) {
        let filename = orgFile.name.split('.');
        setThumbnailSource(filename[0]);
        let newFile = new File(
          [orgFile],
          generateName(filename[filename.length - 1]),
          { type: orgFile.type, lastModified: orgFile.lastModified }
        );
        setThumbnailFile(newFile);
        setThumbnailSource(URL.createObjectURL(newFile));
        setError('');
        if (
          Number((target.files[0].size / 1024 / 1024).toFixed(2)) > 5
          // As per ticker SUS-102
          // || (imageRef.current &&
          //   imageRef.current.naturalWidth > 640 &&
          //   imageRef.current.naturalHeight > 320)
        ) {
          setError('This image seems to be too large.');
        }
      } else {
        setError('Invalid format.');
      }
    } else {
      setError('Invalid file.');
    }
  };
  return (
    <Modal widthOnDesktop={'1008px'} disableFullScreen={true}>
      <Content>
        <Row justifyContent={'space-between'} marginBottom={32}>
          <Title>{title}</Title>
          <CloseIconWrap>
            <IoMdClose
              size={24}
              onClick={onClose}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        <Row marginBottom={8}>
          <Label>Image file (png, jpeg, gif)</Label>
          <Label>Thumbnail Name</Label>
        </Row>
        <Row
          marginBottom={showTitleError ? 5 : 32}
          justifyContent={'flex-start'}
        >
          <input
            type={'file'}
            ref={fileRef}
            style={{ display: 'none' }}
            name={'filePicker'}
            onChange={handleSelectedFile}
            accept={'image/png, image/jpeg, image/gif, image/bmp'}
          />
          <FileInputHandler onClick={() => fileRef && fileRef.current?.click()}>
            {thumbnailFile ? thumbnailFile.name : 'Choose your file...'}
            <MdImage size={24} />
          </FileInputHandler>
          <TextInput
            type={'text'}
            value={thumbnailName}
            maxLength={50}
            onChange={onChange}
          />
        </Row>
        {showTitleError && (
          <ErrorMessage>
            Thumbnail name's maximum character limit reached
          </ErrorMessage>
        )}
        <Row justifyContent={'center'}>
          <ThumbnailPlaceholder>
            {thumbnailSource ? (
              <ThumbnailContainer
                ref={imageRef}
                src={thumbnailSource}
                alt={`Image of Email Thumbnail called: ${title}`}
              />
            ) : (
              <>
                <span>Thumbnail will appear at their original size.</span>
                <span>Recommended thumbnail size: 640x320 px.</span>
              </>
            )}
          </ThumbnailPlaceholder>
        </Row>
        <Row
          justifyContent={
            error || (data && data.type) ? 'space-between' : 'flex-end'
          }
        >
          {data && data.type === 'icons' ? (
            <Button
              icon={<MdDeleteForever size={24} />}
              text={'Delete Thumbnail'}
              disabled={disableDeleteButton}
              variant='red'
              onClick={() => onDelete(data)}
            />
          ) : (
            <>
              {error && (
                <WarningMessage>
                  <MdWarning color={theme.palette.tomatoRed} size={24} />
                  {`  ${error}`}
                </WarningMessage>
              )}
            </>
          )}
          <Button
            disabled={
              loading ||
              !!error ||
              !thumbnailName ||
              !thumbnailSource ||
              uploading
            }
            text={buttonText}
            variant='primary'
            onClick={() => handleAdd()}
          />
        </Row>
      </Content>
    </Modal>
  );
};

// methods
function handleNameChange(
  setThumbnailName: React.Dispatch<React.SetStateAction<string>>,
  setShowTitleError: React.Dispatch<React.SetStateAction<boolean>>
) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value?.length >= MAX_TITLE_LENGTH) {
      setShowTitleError(true);
      return;
    }

    setShowTitleError(false);
    if (NAME_REGEX.test(value) || !value) {
      setThumbnailName(value);
    }
  };
}

function handleUploadProgress(setUploadProgress: any) {
  return (e: ProgressEvent) => {
    const percentCompleted = Math.round((e.loaded * 100) / e.total);
    setUploadProgress({
      loaded: Math.floor(e.loaded / 1024),
      total: Math.floor(e.total / 1024),
      percentage: percentCompleted,
    });
  };
}

function handleNameGeneration(userData: ICombinedUserData) {
  return (fileExt: string) => {
    return (
      userData.userId +
      '_' +
      userData.customerId +
      '_' +
      (Math.random() + 1).toString(36).substring(2) +
      '.' +
      fileExt
    );
  };
}
