import { FormColorPicker } from 'app/pages/video/videoDetails/main/videoAnnotations/components/FormColorPicker';
import { useFormikContext } from 'formik';
import { Dropdown } from 'lib/components';

import { Gap } from 'lib/components/styles/layout';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Label, Section, SectionTitle, PreviewBtnText } from '../index.styled';
import {
  boxShadowOptions,
  buttonBorderOptions,
  buttonBorderRadiusOptions,
} from './const';
import { SalesPagesValues } from './types';

export const SalesPagesCTAForm = () => {
  const { initialValues, values, setFieldValue } =
    useFormikContext<SalesPagesValues>();

  const ctaBtnStyles = css`
    max-height: 40px;
    width: 130px;
    height: 40px;
    cursor: pointer;
    background: ${values.ctaButtonBgColor};
    color: ${values.ctaButtonTextColor};
    border-color: ${values.ctaButtonBorderColor};
    border-radius: ${values.ctaButtonBorderRadius};
    box-shadow: ${values.ctaButtonShadow};
    border-width: ${values.ctaButtonBorderThickness};

    &:hover {
      background: ${values.ctaButtonBgColorHover};
      color: ${values.ctaButtonTextColorHover};
      border-color: ${values.ctaButtonBorderColorHover};
    }
  `;

  const ButtonPreview = styled.button`
    ${ctaBtnStyles}
  `;

  return (
    <Section>
      <SectionTitle>CTA </SectionTitle>
      <Gap>
        <FormColorPicker
          label='Button Background Color '
          name='ctaButtonBgColor'
          menuPlacement='bottom'
          defaultColor={initialValues?.ctaButtonBgColor || '#FFF'}
          isClearable={true}
        />
        <FormColorPicker
          label='Button Text Color '
          name='ctaButtonTextColor'
          menuPlacement='bottom'
          defaultColor={initialValues?.ctaButtonTextColor || '#FFF'}
          isClearable={true}
        />
        <FormColorPicker
          label='Button Border Color '
          name='ctaButtonBorderColor'
          menuPlacement='bottom'
          defaultColor={initialValues?.ctaButtonBorderColor || '#FFF'}
          isClearable={true}
        />
        <FormColorPicker
          label='Button Background Color Hover'
          name='ctaButtonBgColorHover'
          menuPlacement='bottom'
          defaultColor={initialValues?.ctaButtonBgColorHover || '#FFF'}
          isClearable={true}
        />
        <FormColorPicker
          label='Button Text Color Hover'
          name='ctaButtonTextColorHover'
          menuPlacement='bottom'
          defaultColor={initialValues?.ctaButtonTextColorHover || '#FFF'}
          isClearable={true}
        />
        <FormColorPicker
          label='Button Border Color Hover'
          name='ctaButtonBorderColorHover'
          menuPlacement='bottom'
          defaultColor={initialValues?.ctaButtonBorderColorHover || '#FFF'}
          isClearable={true}
        />
        <Gap
          flexDirection='column'
          alignItems='flex-start'
          gap='4px'
          width='100%'
        >
          <Label>Button Border Thickness</Label>
          <Dropdown
            options={buttonBorderOptions}
            value={buttonBorderOptions.find(
              option => option.value === values.ctaButtonBorderThickness
            )}
            onChange={(option: { value: number }) => {
              setFieldValue('ctaButtonBorderThickness', option.value);
            }}
            extendStyles={{
              container: {
                width: '100%',
              },
              menu: {
                zIndex: 3,
              },
            }}
            creatable={false}
            name='ctaButtonBorderThickness'
            placeholder='Border Thickness'
          />
        </Gap>

        <Gap
          flexDirection='column'
          alignItems='flex-start'
          gap='4px'
          width='100%'
        >
          <Label>Button Border Radius</Label>
          <Dropdown
            options={buttonBorderRadiusOptions}
            value={buttonBorderRadiusOptions.find(
              option => option.value === values.ctaButtonBorderRadius
            )}
            onChange={(option: { value: number }) => {
              setFieldValue('ctaButtonBorderRadius', option.value);
            }}
            extendStyles={{
              container: {
                width: '100%',
              },
              menu: {
                zIndex: 3,
              },
            }}
            creatable={false}
            name='ctaButtonBorderRadius'
            placeholder='Border Radius'
          />
        </Gap>

        <Gap
          flexDirection='column'
          alignItems='flex-start'
          gap='4px'
          width='100%'
        >
          <Label>Button Border Shadow</Label>
          <Dropdown
            options={boxShadowOptions}
            value={boxShadowOptions.find(
              option => option.value === values.ctaButtonShadow
            )}
            onChange={(option: { value: number }) => {
              setFieldValue('ctaButtonShadow', option.value);
            }}
            extendStyles={{
              container: {
                width: '100%',
              },
              menu: {
                zIndex: 3,
              },
            }}
            creatable={false}
            name='ctaButtonShadow'
            placeholder='Border Shadow'
          />
        </Gap>
      </Gap>
      <Gap alignItems='center' gap='4px' width='100%' justifyContent='flex-end'>
        <ButtonPreview type='button' onClick={() => null}>
          <PreviewBtnText>Preview</PreviewBtnText>
        </ButtonPreview>
      </Gap>
    </Section>
  );
};
