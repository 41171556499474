import { ActivityResponse } from 'lib/context';
import { ActivityParams } from './types';

import { useQuery } from 'react-query';
import { activityKeys } from './activityKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { AxiosError } from 'axios';
import { ActivityFeedSortKey } from 'app/pages/reports/activity/main/ActivityTableUtils';
import { PaginationConstants } from 'lib/const/PaginationConstants';

export const getUserActivities = async ({
  start = 0,
  limit = 10,
  search = '',
  order = PaginationConstants.ASCENDING,
  sortKey = ActivityFeedSortKey.DATE_SENT,
  from = '',
  to = '',
  senderIds = [],
  emailStatus = '',
  receiveMethod = '',
}: ActivityParams): Promise<ActivityResponse> => {
  const params = {
    start,
    limit,
    search,
    from,
    to,
    order,
    sortKey,
    ...(!!senderIds && senderIds.length ? { senderIds } : {}),
    ...(!!emailStatus ? { emailStatus } : {}),
    ...(!!receiveMethod ? { receiveMethod } : {}),
  };

  return (await EXPRESS_API.get(`activity-feed`, { params })).data;
};

export const useGetUserActivitiesQuery = (params: ActivityParams) => {
  const { showError } = useToastError();
  return useQuery(activityKeys.list(params), () => getUserActivities(params), {
    onSuccess: () => {},
    onError: (err: AxiosError) => showError(err),
  });
};
