import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

const removeCard = async (cardId: string) => {
  const data = { id: cardId };
  const response = await EXPRESS_API.delete('/register/payment/methods', {
    data,
  });
  return response.data;
};

export const useDeleteCustomerCardMutation = () => {
  const { showError } = useToastError();

  return useMutation(removeCard, {
    onSuccess: () => {
      successToast({ title: 'Card deleted successfully!' });
    },
    onError: (err: AxiosError) => showError(err),
  });
};
