import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { CreateTeleprompterScriptParams, TeleprompterResponse } from './types';

export const updateScript = async ({
  scriptId,
  data,
}: CreateTeleprompterScriptParams): Promise<TeleprompterResponse> => {
  const response = await EXPRESS_API.put(`/teleprompter/${scriptId}`, data);
  return response.data;
};

export const useEditScript = () => {
  return useMutation(updateScript, {
    onSuccess: () => {
      successToast({
        title: 'Successfully updated script!',
      });
    },
    onError: () => {
      errorToast({ title: 'An error occurred while creating script.' });
    },
  });
};
