import { FormColorPicker } from 'app/pages/video/videoDetails/main/videoAnnotations/components/FormColorPicker';
import { useFormikContext } from 'formik';

import { Gap } from 'lib/components/styles/layout';
import { TextEditor } from 'lib/components/textEditorAutomotive';
import { useAuth } from 'lib/context';
import React from 'react';
import { Label, Section, SectionTitle } from '../index.styled';
import { SalesPagesValues } from './types';
import { UploadImage } from './UploadImage';

const additionalTextEditorToolbar = {
  options: ['inline'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: {
      className: 'toolbar-custom-icon toolbar-bold-icon large-icon',
    },
    italic: {
      className: 'toolbar-custom-icon toolbar-italic-icon large-icon',
    },
    underline: {
      className: 'toolbar-custom-icon toolbar-underline-icon large-icon',
    },
  },
};

export const SalesPagesBodyForm = () => {
  const { userData } = useAuth();
  const { initialValues, values, setFieldValue } =
    useFormikContext<SalesPagesValues>();
  const defaultAdditionalTextColor = userData.isAutomotiveServiceRole
    ? values.additionalTextColorForService
    : values.additionalTextColorForService;
  return (
    <Section>
      <SectionTitle>Body</SectionTitle>
      <Gap>
        <FormColorPicker
          label='Background Color'
          name='bgColor'
          menuPlacement='bottom'
          defaultColor={initialValues?.bgColor || '#FFF'}
          isClearable={true}
        />
        <Gap
          flexDirection='column'
          gap='8px'
          width='100%'
          alignItems='flex-start'
        >
          <UploadImage name='bodyBgImage' label='Background Image' />
        </Gap>
        <Gap width='100%'>
          <FormColorPicker
            label='Text color'
            name={
              userData.isAutomotiveServiceRole
                ? 'additionalTextColorForService'
                : 'additionalTextColorForSales'
            }
            menuPlacement='bottom'
            defaultColor={defaultAdditionalTextColor}
            isClearable={true}
          />
        </Gap>
        <Gap
          flexDirection='column'
          alignItems='flex-start'
          gap='4px'
          width='100%'
        >
          <Label>Additional Text Below Gallery</Label>
          <TextEditor
            initialContent={
              userData.isAutomotiveServiceRole
                ? values.additionalTextForService
                : values.additionalTextForSales
            }
            onTextEditorChange={newValue => {
              if (userData.isAutomotiveServiceRole) {
                setFieldValue('additionalTextForService', newValue);
                return;
              }
              setFieldValue('additionalTextForSales', newValue);
            }}
            styles={{
              wrapper: {
                width: '100%',
              },
            }}
            height={'160px'}
            customPadding={'60px 15px 15px 15px'}
            placeholder={'Put your disclaimer or additional info here'}
            toolbar={additionalTextEditorToolbar}
            hideAddVariableButton={true}
            editorWrapperClassname='automotive-wrapper service-page-additional-text-input'
          />
        </Gap>
      </Gap>
    </Section>
  );
};
