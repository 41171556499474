import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { MdClose, MdOutlineEmail } from 'react-icons/md';
import { LoadingIndicator } from 'lib/components';
import { useAuth, VideoRequest } from 'lib/context';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import {
  AddMissingInfo,
  CardWithSwitch,
  EmailEditor,
  QuoteCard,
  SmsEditor,
} from '../components';
import { RepairOrderStatus } from 'lib/const';
import { RecipientType } from 'lib/hooks';
import {
  replaceVariables,
  savePreviewCookie,
} from 'app/pages/library/videos/videoList/components/SendAndShare';
import { getEmailReceiver } from 'lib/api';
import { removeHTMLTags } from '../../../../lib/utils/functions';
import { ModalNoQuotePrompt } from 'app/pages/library/videos/videoList/components/SendAndShare/';
import { BiLinkExternal } from 'react-icons/bi';
import { getAutoMotiveLanding } from 'lib/api/automotiveLandingApi';
import { noSmsMsg } from 'app/pages/library/videos/videoList/components/SendAndShare';
import { errorToast } from 'lib/components/toasts/error';
import { useGetSingleRepairOrder } from 'lib/api/repairOrders/getSingleRepairOrder';
import { useUpdateRepairOrder } from 'lib/api/repairOrders/updateRepairOrder';
import { QuoteItem, UpdatedRepairOrder } from 'lib/api/repairOrders/types';
import { useSendVideoEmail } from 'lib/api/email/useSendVideoEmail';
import { SendEmailParams } from 'lib/api/email/types';
import { successToast } from 'lib/components/toasts/success';
import { useGetQuickshareDetails } from 'lib/api/quickshare/useGetQuickshareDetails';
import { useSendVideoSMS } from 'lib/api/sms/useSendVideoSMS';
import { FullScreenModal } from 'lib/components/FullScreenModal';

const ModalHeader = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 37px;
  background: ${theme.palette.white};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 3;
`;

const ModalBody = styled.div`
  box-sizing: border-box;
  padding: 32px 0;
  height: calc(100vh - 64px);
  overflow-y: scroll;
`;

const HeaderSide = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

const HeaderTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.blue80};
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkBtn = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: ${theme.palette.coal};
  cursor: pointer;
  span {
    margin-left: 5px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const ContentWrapper = styled.div`
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
`;

const Card = styled.div`
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
  margin-bottom: 12px;
  padding: 24px;
  min-height: 50px;
  > h3 {
    color: ${theme.palette.covideoBlue100};
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 8px;
  }
  > p {
    color: ${theme.palette.gray80};
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }
`;

const getPreviewUrl = (url: string) => {
  return url + '?preview&nn=1';
};

interface MatchParams {
  repairOrderId: string;
}
export const EditResend = withRouter(
  (props: RouteComponentProps<MatchParams>) => {
    const { repairOrderId } = props.match.params;
    const { data: repairOrder, isLoading: loading } =
      useGetSingleRepairOrder(repairOrderId);

    const { mutateAsync: sendEmail } = useSendVideoEmail(repairOrder.videoId);
    const { mutateAsync: sendVideoSMS } = useSendVideoSMS(repairOrder.videoId);

    const { userData } = useAuth();
    const { customer } = userData;
    const isSmsEnabled = customer.smsEnabled === '1';

    const [emailContent, setEmailContent] = useState({
      subject: '',
      body: '',
    });
    const [smsContent, setSmsContent] = useState({
      body: '',
    });

    const [blockNavigation, setBlockNavigation] = useState(true);
    const [repairOrderValid, setRepairOrderValid] = useState(true);
    const [sendEmailFlag, setSendEmailFlag] = useState(false);
    const [sendSmsFlag, setSendSmsFlag] = useState(false);
    const [sendQuote, setSendQuote] = useState(false);
    const [requiredQuoteItems, setRequiredQuoteItems] = useState<QuoteItem[]>(
      []
    );
    const [optionalQuoteItems, setOptionalQuoteItems] = useState<QuoteItem[]>(
      []
    );
    const [updatedRepairOrder, setUpdatedRepairOrder] =
      useState<UpdatedRepairOrder>();
    const [newPhone, setNewPhone] = useState('');
    const [newPhoneValid, setNewPhoneValid] = useState(false);
    const [recipientHasPhone, setRecipientHasPhone] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [newEmailValid, setNewEmailValid] = useState(false);
    const [recipientHasEmail, setRecipientHasEmail] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [videoRequest, setVideoRequest] = useState<VideoRequest>();
    const [videoUrl, setVideoUrl] = useState('');
    const [showModalNoQuotePrompt, setShowModalNoQuotePrompt] = useState(false);
    const [previewData, setPreviewData] = useState({});
    const [recieverDetails, setRecieverDetails] = useState(
      {} as {
        sentItem: {
          designId: number;
          templateLinksGroupId: number;
          notify: boolean;
        };
      }[]
    );
    const { data: quickshareDetails, isLoading } = useGetQuickshareDetails({
      videoId: repairOrder?.videoId,
      enabledOnMount: true,
    });

    const history = useHistory();
    const { mutateAsync: editRepairOrder } =
      useUpdateRepairOrder(setShowLoading);

    const loadRecieverDetails = async () => {
      const recieverDetails = await getEmailReceiver(repairOrder.videoId);
      setRecieverDetails(recieverDetails?.emailReceivers);
    };

    useEffect(() => {
      setShowLoading(loading);
      loadRecieverDetails();
    }, [loading]);

    const getPreviewData = async () => {
      const { bgColor, imageUrl } = await getAutoMotiveLanding();
      setPreviewData({ bgColor, imageUrl });
    };

    useEffect(() => {
      if (repairOrder) {
        setSendEmailFlag(repairOrder.sendEmail || false);
        setSendSmsFlag(repairOrder.sendSms || false);
        setSendQuote(repairOrder.sendQuote || false);
        setEmailContent({
          subject: repairOrder.emailSubject || '',
          body: repairOrder.emailText || '',
        });
        setSmsContent({
          body: repairOrder.smsText || '',
        });

        getPreviewData();

        // @ts-ignore
        if (repairOrder.video) {
          // @ts-ignore
          setVideoRequest({
            ...repairOrder.video.videoRequest,
            advisor: repairOrder?.advisor,
            user: repairOrder?.user,
          });
        }

        if (repairOrder.quoteItems) {
          setRequiredQuoteItems(
            repairOrder.quoteItems.filter(item => {
              return item.required;
            })
          );
          setOptionalQuoteItems(
            repairOrder.quoteItems.filter(item => {
              return !item.required;
            })
          );
        }
      }
    }, [repairOrder]);

    useEffect(() => {
      if (repairOrder) {
        const recipientCheckEmail = repairOrder.email !== '';
        setRecipientHasEmail(recipientCheckEmail);
        setSendEmailFlag(
          prevSendEmailFlag =>
            prevSendEmailFlag && (recipientCheckEmail || newEmailValid)
        );

        const recipientCheckPhone = repairOrder.phone !== '';
        setRecipientHasPhone(recipientCheckPhone);
        setSendSmsFlag(
          prevSendSmsFlag =>
            prevSendSmsFlag && (recipientCheckPhone || newPhoneValid)
        );
      }
    }, [repairOrder, newEmail, newPhone]);

    useEffect(() => {
      setSendQuote(!!requiredQuoteItems.length || !!optionalQuoteItems.length);
    }, [requiredQuoteItems, optionalQuoteItems]);

    useEffect(() => {
      if (repairOrder) {
        setUpdatedRepairOrder({
          status: RepairOrderStatus.Sent,
          email: newEmail ? newEmail : repairOrder.email,
          phone: newPhone ? newPhone : repairOrder.phone,
          sendEmail: sendEmailFlag,
          sendQuote: sendQuote,
          sendSms: sendSmsFlag,
          emailSubject: emailContent.subject,
          emailText: emailContent.body,
          smsText: smsContent.body,
          quoteItems: requiredQuoteItems.concat(optionalQuoteItems),
        });
      }
    }, [
      repairOrder,
      sendEmailFlag,
      sendQuote,
      sendSmsFlag,
      emailContent,
      smsContent,
      requiredQuoteItems,
      optionalQuoteItems,
    ]);

    useEffect(() => {
      //TODO: validate repair order data
      setRepairOrderValid(sendEmailFlag || sendSmsFlag);
    }, [sendEmailFlag, sendSmsFlag]);

    useEffect(() => {
      if (quickshareDetails) {
        setVideoUrl(quickshareDetails?.data?.url);
      }
    }, [quickshareDetails]);

    useEffect(() => {
      if (repairOrder && updatedRepairOrder) {
        savePreviewCookie({
          quoteItems: updatedRepairOrder.sendQuote
            ? updatedRepairOrder.quoteItems
            : ([] as QuoteItem[]),
        });
      }
    }, [repairOrder, updatedRepairOrder, previewData]);

    const sendEmailSMSClick = () => {
      if (updatedRepairOrder && updatedRepairOrder.sendQuote) {
        sendEmailSMS();
      } else {
        setShowModalNoQuotePrompt(true);
      }
    };

    const sendEmailSMS = async () => {
      setShowModalNoQuotePrompt(false);
      setShowLoading(true);

      let quoteItems = [] as QuoteItem[];
      if (
        updatedRepairOrder &&
        updatedRepairOrder.sendQuote &&
        updatedRepairOrder.quoteItems
      ) {
        quoteItems = updatedRepairOrder.quoteItems;
        quoteItems.forEach(item => {
          if (!item.approved) {
            item.isTouched = false;
          }
        });
      }
      const repairOrder = await editRepairOrder({
        data: {
          ...updatedRepairOrder,
          quoteItems,
          repairOrderId,
        },
        repairOrderId,
      });

      if (repairOrder && videoRequest) {
        try {
          const emailText = replaceVariables({
            text: repairOrder.emailText || '',
            videoRequest,
            sendData: repairOrder,
            userData,
          });
          const subject = replaceVariables({
            text: repairOrder.emailSubject || '',
            videoRequest,
            sendData: repairOrder,
            userData,
          });
          const smsText = replaceVariables({
            text: repairOrder.smsText || '',
            videoRequest,
            sendData: repairOrder,
            userData,
          });
          const commonSendPayload = {
            vin: '',
            templateId:
              (recieverDetails && recieverDetails[0]?.sentItem?.designId) || 0,
            linksetId:
              (recieverDetails &&
                recieverDetails[0]?.sentItem?.templateLinksGroupId) ||
              -2,
            overlayId:
              recieverDetails && recieverDetails[0]?.sentItem?.designId === -1
                ? recieverDetails &&
                  recieverDetails[0]?.sentItem?.templateLinksGroupId
                : 0,
            notifyUser: !!(
              recieverDetails && recieverDetails[0]?.sentItem?.notify
            ),
            allowReactions: false,
            allowVideoReply: false,
          };
          if (repairOrder.sendEmail && repairOrder.email) {
            const email: SendEmailParams = {
              ...commonSendPayload,
              recipients: [
                {
                  type: RecipientType.USER,
                  id: repairOrder.email,
                  text: repairOrder.email,
                },
              ],
              html: emailText,
              subject,
              message: emailText,
            };

            const result = await sendEmail(email).catch(err => err);
            if (result instanceof Error) {
              errorToast({
                title:
                  'An error occurred preparing your email, please try again later!',
              });
            } else {
              successToast({
                title: result.message || 'Email Sent Successfully!',
              });
            }
          }

          if (repairOrder.sendSms && repairOrder.phone && repairOrder.smsText) {
            await sendVideoSMS({
              ...commonSendPayload,
              to: [repairOrder.phone],
              text: removeHTMLTags(smsText.trim()),
              attachments: [],
            });
          }

          setBlockNavigation(false);
          goToRepairOrders();
        } catch (error) {
          setShowLoading(false);
          errorToast({
            title:
              'An error occurred preparing your message, please try again later!',
          });
        }
      }
    };

    const goToPreview = () => {
      if (videoUrl) window.open(getPreviewUrl(videoUrl), '_blank');
    };

    const goToRepairOrder = () =>
      history.push(`/repair-orders/${repairOrderId}`);

    const goToRepairOrders = () => history.push(`/repair-orders`);

    let sendButtonText = 'Send Email';

    if (updatedRepairOrder) {
      if (updatedRepairOrder.sendSms && !updatedRepairOrder.sendEmail) {
        sendButtonText = 'Send SMS';
      }

      if (updatedRepairOrder.sendEmail && updatedRepairOrder.sendSms) {
        sendButtonText = 'Send Email & SMS';
      }
    }

    if (!repairOrder) {
      return null;
    }

    return (
      <>
        <RouteLeavingGuard
          when={true}
          stay={true}
          onConfirm={() => {}}
          navigate={path => history.push(path)}
          shouldBlockNavigation={() => {
            return blockNavigation;
          }}
          title='Close Repair Order Without Sending?'
          text='This Repair Order is not sent yet.'
          confirmButtonText='Keep Editing'
          discardButtonText='Yes, Close'
          showDiscardIcon={false}
          showSaveIcon={false}
        />
        <FullScreenModal>
          {showLoading && (
            <LoadingIndicator isLoading={showLoading} height='300px' />
          )}
          {!showLoading && (
            <>
              <ModalHeader>
                <HeaderSide>
                  <LinkWrapper>
                    <LinkBtn onClick={() => goToRepairOrder()}>
                      <MdClose size='18' />
                      <span>Close</span>
                    </LinkBtn>
                  </LinkWrapper>
                </HeaderSide>
                <HeaderTitle>RO# {repairOrder.repairOrderNumber}</HeaderTitle>
                <HeaderSide>
                  <Actions>
                    <Button
                      disabled={!repairOrderValid || isLoading}
                      text='Preview'
                      variant='secondary'
                      onClick={() => goToPreview()}
                      icon={<BiLinkExternal />}
                    />
                    <Button
                      disabled={!repairOrderValid || isLoading}
                      variant='primary'
                      text={sendButtonText}
                      icon={<MdOutlineEmail />}
                      onClick={() => sendEmailSMSClick()}
                    />
                  </Actions>
                </HeaderSide>
              </ModalHeader>
              <ModalBody>
                <ContentWrapper>
                  <Card>
                    <h3>Recipient</h3>
                    <p>
                      {repairOrder.firstName && repairOrder.lastName
                        ? `${repairOrder.firstName} ${repairOrder.lastName}`
                        : `${repairOrder.email}`}
                    </p>
                  </Card>
                  <CardWithSwitch
                    switchId={'switch-1'}
                    switchIsOn={sendEmailFlag}
                    handleToggle={() => {
                      setSendEmailFlag(prevSendEmailFlag => !prevSendEmailFlag);
                    }}
                    title={'Email'}
                    text={'Add email address to send Email'}
                    summary={emailContent}
                    switchDisabled={!recipientHasEmail && !newEmailValid}
                    expandedContent={
                      <>
                        {!recipientHasEmail && (
                          <AddMissingInfo
                            inputType='email'
                            inputValue={newEmail}
                            onChangeInputValue={setNewEmail}
                            inputPlaceholder={'Enter email address'}
                            inputValid={newEmailValid}
                            setInputValid={setNewEmailValid}
                          />
                        )}
                        <EmailEditor
                          emailContent={emailContent}
                          setEmailContent={setEmailContent}
                        />
                      </>
                    }
                  />
                  <CardWithSwitch
                    switchId={'switch-2'}
                    switchIsOn={sendSmsFlag}
                    handleToggle={() => {
                      setSendSmsFlag(prevSendSmsFlag => !prevSendSmsFlag);
                    }}
                    title={'SMS'}
                    text={'Add phone number to send SMS'}
                    summary={smsContent}
                    switchDisabled={
                      !smsContent.body.trim().length ||
                      (!recipientHasPhone && !newPhoneValid)
                    }
                    expandedDefault={!isSmsEnabled}
                    expandedContent={
                      !isSmsEnabled ? (
                        noSmsMsg
                      ) : (
                        <>
                          {!recipientHasPhone && (
                            <AddMissingInfo
                              inputType='phone'
                              inputValue={newPhone}
                              onChangeInputValue={setNewPhone}
                              inputPlaceholder={'xxx-xxx-xxxx'}
                              inputValid={newPhoneValid}
                              setInputValid={setNewPhoneValid}
                            />
                          )}
                          <SmsEditor
                            smsContent={smsContent}
                            setSmsContent={setSmsContent}
                          />
                        </>
                      )
                    }
                  />

                  <QuoteCard
                    switchId={'switch-3'}
                    switchIsOn={sendQuote}
                    handleToggle={() => {
                      setSendQuote(prevQuote => !prevQuote);
                    }}
                    title={'Quote'}
                    requiredQuoteItems={requiredQuoteItems}
                    optionalQuoteItems={optionalQuoteItems}
                    setRequiredQuoteItems={setRequiredQuoteItems}
                    setOptionalQuoteItems={setOptionalQuoteItems}
                  />
                </ContentWrapper>
              </ModalBody>
            </>
          )}
        </FullScreenModal>
        {showModalNoQuotePrompt && (
          <ModalNoQuotePrompt
            handleModalClose={() => setShowModalNoQuotePrompt(false)}
            handleSubmit={sendEmailSMS}
          />
        )}
      </>
    );
  }
);
