import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';

import { Dropdown, LoadingIndicator, Search } from 'lib/components';
import { theme } from 'lib/style';
import { formatBytes } from 'lib/utils/functions';
import { ExtendedFile, statuses } from '../../library/components/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getStatusSize,
  getStatusText,
} from 'app/pages/library/components/uploadDropTabs/UploadDropTab';
import { CombinedBoard, DropItem } from 'lib/api/droplr/types';
import { uploadDrop } from 'lib/api/droplr/uploadDrop';
import { getBoards } from 'lib/api/droplr/getBoards';
import { useAuth } from 'lib/context';
import { getDrop } from 'lib/api/droplr/getDrop';
import { getDrops } from 'lib/api/droplr/getDrops';
import { DROPTYPE_OPTIONS } from 'lib/const/Droplr';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { errorToast } from 'lib/components/toasts/error';

const DropContent = styled.div<{ isDragActive: boolean }>`
  margin-top: 24px;
  padding: 0 32px;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${props => (props.isDragActive ? '#F6F7F9' : '#F6F7F9')};
  border: 1px dashed #d0d3d9;
  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.coal};
  }
`;

const UploadFileContent = styled.div`
  margin-top: 24px;
  background: #f6f7f9;
  border-radius: 4px;
  display: flex;
  padding: 16px 32px;
`;

const UploadFileDescription = styled.div`
  width: 90%;
`;

const UploadFileAction = styled.div`
  width: 10%;
`;

const UploadFileTitle = styled.div`
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.coal};
  padding: 8px 12px;
`;

const UploadFileProgress = styled.progress`
  background-color: ${theme.palette.lightgray};
  border: none;
  border-radius: 2px;
  height: 4px;
  width: 100%;
  &::-webkit-progress-bar {
    margin: 0 auto;
    background-color: ${theme.palette.lightgray};
    border-radius: 2px;
    height: 4px;
  }
  &::-moz-progress-bar {
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.primary[100]};
    border-radius: 2px;
    height: 4px;
  }
  &::-webkit-progress-value {
    display: inline-block;
    float: left;
    height: 4px;
    background: ${({ theme }) => theme.colors.primary[100]};
    border-radius: 2px;
  }
`;

const UploadFileDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const FileStatus = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.color ? props.color : '#9297A2')};
`;

const FileSize = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: ${theme.palette.coal};
`;

const LibraryContainer = styled.div`
  margin-top: 24px;
  width: 100%;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const DropdownContainer = styled.div`
  width: 200px;
`;

const FilesSelector = styled.div`
  margin-top: 24px;
  height: 300px;
  overflow-y: auto;
`;

const EmptyFiles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 16.5px;
  grid-template-columns: 166px 166px 166px;
  .selected {
    border: 3px solid #001b53 !important;
  }
`;

type GridItemProps = {
  backgroundUrl?: string;
};
const GridItem = styled.div<GridItemProps>`
  background: url('${props =>
    props.backgroundUrl ? props.backgroundUrl : ''}');
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100px;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(39, 42, 50, 0.1);
`;

const ItemTypes = [
  {
    value: 'file',
    text: 'Files',
  },
  {
    value: 'board',
    text: 'Boards',
  },
];

type Props = {
  selectedFile?: any;
  handleModalClose: () => void;
  onSelect: any;
  selectedVirtualBackgroundUrl: string;
};

export const FilesTabContent = (props: Props) => {
  const {
    handleModalClose,
    onSelect,
    selectedFile,
    selectedVirtualBackgroundUrl,
  } = props;
  const [file, setFile] = React.useState({
    name: '',
    sizeText: '',
    size: 0,
    currentUpload: 0,
    status: '',
    progress: 0,
  } as ExtendedFile);
  const { colors } = useCovideoTheme();
  const [page, setPage] = React.useState(0);
  const [res, setRes] = React.useState({ count: '0', hasMore: 'false' } as any);
  const [drops, setDrops] = React.useState([] as DropItem[]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [fields, setFields] = React.useState([{ label: 'All', value: '' }]);
  const [field, setField] = React.useState<any>(fields[0]);
  const [boards, setBoards] = React.useState([] as CombinedBoard[]);
  const { userData } = useAuth();

  const itemType = selectedFile?.itemType || ItemTypes[0].value;

  const onDrop = useCallback(async acceptedFiles => {
    if (!acceptedFiles || acceptedFiles?.length === 0) {
      return;
    }
    let f = {
      name: acceptedFiles[0].name,
      status: statuses.WAITING,
      sizeText: formatBytes(acceptedFiles[0].size, 2),
      size: acceptedFiles[0].size,
      progress: 0,
      currentUpload: 0,
    };
    setFile(f);

    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = async () => {
      f = {
        ...f,
        status: statuses.UPLOADING,
      };
      setFile(f);

      try {
        const uploadedDrop: DropItem = await uploadDrop(
          acceptedFiles[0].name,
          acceptedFiles[0].type,
          reader.result
        );

        let previewUrl: any = uploadedDrop?.previewMedium || '';
        if (!previewUrl) {
          if (!uploadedDrop || !uploadedDrop.code) {
            errorToast({
              title: 'Error while uploading file, please try again later.',
            });
            return;
          }

          f = {
            ...f,
            status: statuses.GENERATING_PREVIEW,
            progress: 90,
          };
          setFile(f);

          previewUrl =
            (await getPreviewLink(file, uploadedDrop.code, 1500)) || '';
          f = {
            ...f,
            status: statuses.FINISHED,
            progress: 100,
          };
          setFile(f);
          setTimeout(() => {
            refreshDropsAndBoards();
          }, 150);
        } else {
          f = {
            ...f,
            status: statuses.FINISHED,
            progress: 100,
          };
          setFile(f);
          setTimeout(() => {
            refreshDropsAndBoards();
          }, 100);
        }
      } catch (err) {
        errorToast({
          title: 'Error while uploading file, please try again later.',
        });
      }
    };
    reader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);

  const getPreviewLink = async (
    file: ExtendedFile,
    dropId: string,
    timeout: number
  ) => {
    return new Promise(resolve => {
      getDrop(dropId)
        .then((dropDetails: any) => {
          if (dropDetails && dropDetails.previewMedium) {
            return resolve(dropDetails.previewMedium);
          } else {
            return helper(dropId, timeout);
          }
        })
        .then((previewUrl: any) => {
          return resolve(previewUrl);
        })
        .catch(() => {});
    });
  };

  const helper = (dropId: string, timeout: number) => {
    return new Promise(resolve => {
      setTimeout(() => {
        getDrop(dropId)
          .then((dropDetails: any) => {
            if (dropDetails && dropDetails.previewMedium) {
              return resolve(dropDetails.previewMedium);
            } else {
              if (timeout >= 96000) {
                return resolve('');
              } else {
                return resolve(helper(dropId, timeout * 2));
              }
            }
          })
          .catch(() => {});
      }, timeout);
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: ['image/png', 'image/jpeg'],
  });

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  const fetchDrops = async () => {
    const response = await getDrops({
      boardId: field.value || '',
      search: searchQuery,
      page: page + 1,
      type: 'IMAGE',
      dropType: DROPTYPE_OPTIONS.OWNED,
    });

    setPage(page + 1);
    setRes(response);
    setDrops([...drops, ...response.drops]);
  };

  const refreshDropsAndBoards = async () => {
    if (itemType !== 'file') {
      return;
    }
    setLoading(true);
    const response = await getDrops({
      boardId: field.value || '',
      search: searchQuery,
      type: 'IMAGE',
      dropType: DROPTYPE_OPTIONS.OWNED,
    });

    if (!boards || boards.length <= 1) {
      const boardsResponse = await getBoards({
        userId: Number(userData.id),
        customerId: Number(userData.customerId),
      }).catch(() => {});
      if (boardsResponse && boardsResponse.boards) {
        setBoards(boardsResponse.boards || []);
        const boardFields = generateBoardFields(boardsResponse.boards);
        setFields([...fields, ...boardFields]);
      }
    }
    setRes(response);
    setLoading(false);
    setDrops(response.drops);
  };

  React.useEffect(() => {
    if (itemType !== 'file') {
      return;
    }
    refreshDropsAndBoards();
  }, [field, searchQuery, itemType]);

  const uploadingFiles = !!file.name;

  const generateBoardFields = (boards: any) => {
    const boardFields = boards.map((board: any) => {
      return {
        label: board.name,
        value: board.id,
      };
    });

    return boardFields || [];
  };
  return (
    <>
      {!uploadingFiles && (
        <DropContent isDragActive={isDragActive} {...getRootProps()}>
          <input {...getInputProps()} />
          {!isDragActive && (
            <>
              <p>Drag and drop files here to upload new file</p>
              <Button text='Browse files...' variant='ghost' />
            </>
          )}
          {isDragActive && <p>Drop files here</p>}
        </DropContent>
      )}
      {uploadingFiles && (
        <UploadFileContent>
          <UploadFileDescription>
            <UploadFileTitle>{file.name}</UploadFileTitle>
            <UploadFileProgress value={file.progress} max={100} />
            <UploadFileDetails>
              <FileStatus
                color={
                  file.status === statuses.WAITING
                    ? '9297A2'
                    : colors.primary[100]
                }
              >
                {getStatusText(file.status)}
              </FileStatus>
              <FileSize>{getStatusSize(file)}</FileSize>
            </UploadFileDetails>
          </UploadFileDescription>
          <UploadFileAction></UploadFileAction>
        </UploadFileContent>
      )}
      {loading && <LoadingIndicator isLoading={loading} height='200px' />}
      {!loading && (
        <LibraryContainer>
          <Menu>
            <div style={{ width: '280px' }}>
              <Search
                placeholder={`Search files...`}
                onSearch={onSearch}
                prevSearch={searchQuery}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <DropdownContainer>
                <Dropdown
                  value={field}
                  onChange={newValue => {
                    setField(newValue);
                  }}
                  options={fields}
                  dropdownHeight={450}
                  height={40}
                  placeholder='All'
                />
              </DropdownContainer>
            </div>
          </Menu>

          <FilesSelector>
            <InfiniteScroll
              dataLength={page * parseInt(res.count)}
              next={fetchDrops}
              hasMore={res.hasMore === 'true'}
              loader={<h4>Loading...</h4>}
              height={300}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>No more files</b>
                </p>
              }
            >
              <Grid>
                {drops.map(drop => (
                  <GridItem
                    key={drop.code}
                    backgroundUrl={drop.previewMedium}
                    onClick={() => {
                      onSelect(
                        drop.previewMedium,
                        'custom',
                        -1,
                        drop.privacy,
                        drop.code
                      );
                      handleModalClose();
                    }}
                    className={
                      selectedVirtualBackgroundUrl === drop.previewMedium
                        ? 'selected'
                        : ''
                    }
                  ></GridItem>
                ))}
              </Grid>

              {!drops.length && <EmptyFiles>No files found.</EmptyFiles>}
            </InfiniteScroll>
          </FilesSelector>
        </LibraryContainer>
      )}
    </>
  );
};
