import React, { ChangeEvent } from 'react';
import { useField } from 'formik';
import styled from 'styled-components/macro';
import { ImCheckmark } from 'react-icons/im';
import { FormikLabel } from './FormikLabel';
import { Flex, FlexProps } from '../styles/layout';

const CheckboxInput = styled.input`
  position: absolute;
  top: 0px;
  bottom: 0;
  opacity: 0;
  left: 0;
  right: 0;
  cursor: pointer;
`;

const StyledCheckbox = styled.div<{ checked: boolean; disabled?: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.primary[100] : theme.colors.neutral[40]};
  border: ${({ checked, theme }) =>
    checked
      ? `1px solid ${theme.colors.primary[100]}`
      : `1px solid transparent`};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  svg {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
    color: white;
  }
`;

interface CustomCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  wrapper?: FlexProps;
}

export const FormikCheckboxField = ({
  label,
  wrapper,
  onChange,
  ...props
}: CustomCheckboxProps) => {
  const [field, _, helpers] = useField({ ...props, type: 'checkbox' });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) {
      return;
    }
    if (onChange) {
      return onChange(e);
    }
    helpers.setValue(!field.value);
  };

  return (
    <Flex
      flexDirection='row'
      alignItems='center'
      gap='4px'
      style={{ position: 'relative' }}
      width='auto'
      {...wrapper}
    >
      <CheckboxInput
        {...field}
        {...props}
        id={props.name}
        type='checkbox'
        onChange={handleChange}
      />
      <StyledCheckbox checked={!!field.checked} disabled={props.disabled}>
        <ImCheckmark size={16} />
      </StyledCheckbox>
      {label && (
        <FormikLabel
          label={label}
          name={props.name}
          isRequired={false}
          disabled={!!props.disabled}
        />
      )}
    </Flex>
  );
};
