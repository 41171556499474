import * as React from 'react';
import { useAuth } from 'lib/context';
import { ParagraphNormal } from 'lib/components/styles/typography';
import { Button } from 'react-covideo-common';
import { useHistory } from 'react-router-dom';
import { AccessRole } from 'lib/const';
import { Flex } from 'lib/components/styles/layout';

export const CaptionsNotEnabled = () => {
  const { userData } = useAuth();
  const history = useHistory();

  const buyCaptions = () => {
    history.push('/profile/add-ons/captions/manage');
  };

  return (
    <Flex gap='32px' alignItems='center' height='300px'>
      {userData.access === AccessRole.ADMIN ? (
        <>
          <ParagraphNormal>Captions are not enabled</ParagraphNormal>
          <Button text={'Buy Captions now'} onClick={buyCaptions} />
        </>
      ) : (
        <ParagraphNormal>
          Captions are not enabled for your account. Contact your account Admin.
        </ParagraphNormal>
      )}
    </Flex>
  );
};
