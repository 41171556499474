import * as React from 'react';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { IoIosCheckmarkCircle, IoMdClose } from 'react-icons/io';
import { components, OptionProps, OptionTypeBase } from 'react-select';
import dayjs from 'dayjs';
import { theme } from 'lib/style';
import { Modal, ButtonSwitch, NewTextInput } from 'lib/components';
import Select from 'react-select';
import { getScripts, createScript, updateScript } from 'lib/api';
import { MdSave, MdOutlineClose } from 'react-icons/md';
import { DroplrFilesTabContent } from './DroplrFilesTabContent';
import {
  handleScriptSpeedUsingScriptSize,
  sizeToSpeedRatioMedium,
} from 'lib/const';
import { useAuth } from 'lib/context';
import { successToast } from '../toasts/success';
import { CovideoMagicButton, PROMPT_TYPE } from 'react-covideo-ai-assist';
import { Gap } from '../styles/layout';
import { uniqBy } from 'lodash';
import { getCurrentToken } from 'configs/api/token';
import {
  decodeAndReplaceText,
  getDefaultPromptUseCase,
} from 'lib/utils/functions';
import { Button } from 'react-covideo-common';
import { ITeleprompterSettings } from 'lib/context/teleprompter/types';
import { APP_ENVIROMENT } from 'configs/api/types';
import mobileLogoActive from 'assets/icons/logo/covideoSmallLogoActive.svg';

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  handleSubmit?: (data?: any) => void;
  settings: ITeleprompterSettings | null;
};

type ScriptType = {
  scriptId: number;
  content: string;
  customerId: number;
  sharedWithCompany: boolean;
  title: string;
  userId: number;
  sharedWithAll: boolean;
};

const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: auto;
  background-color: #fff;
  margin: 32px;
`;

const Content = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;
const ContentHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  &.list {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const TabHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 4px;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(0, 27, 83, 0.05),
      rgba(0, 27, 83, 0.05)
    ),
    #ffffff;
  border-radius: 24px;
  .scriptTab,
  .appearanceTab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    color: ${theme.palette.primaryDarkBlue};
    opacity: 0.5;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    &.active {
      background: #ffffff;
      height: 32px;
      border-radius: 16px;
      box-shadow: 0px 2px 4px rgba(29, 30, 36, 0.04);
      opacity: 1;
    }
  }
`;

const StyledIoIosCheckmarkCircle = styled(IoIosCheckmarkCircle)`
  background: white;
  border-radius: 50%;
`;

const SelectContent = styled.div`
  display: flex;
  width: 100%;
  margin: 16px 0;
  align-items: baseline;
  justify-content: space-between;
  .scriptList {
    width: 49%;
  }
  .fullWidth {
    width: 100%;
  }
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.black_1_100};
  overflow-wrap: break-word;
  max-width: 100%;
  overflow: none;
  &.list--body {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;
  }
`;

const CloseButtonWrap = styled.div<{ position?: string }>`
  margin-left: 10px;
  color: ${theme.palette.black_1_100};
  height: 24px;
  width: 24px;
  position: ${props => (props.position ? props.position : 'relative')};
  &.list--bth--wrap {
    left: 0;
    display: flex;
    align-items: center;
    width: 150px;
  }
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  justify-content: space-between;
  .newScriptDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .saveNewScript {
    display: flex;
    column-gap: 8px;
    width: 100%;
    .closeCreateScriptView {
      background: #eeeff2;
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      border-radius: 5px;
    }
  }
`;

interface TextAreaProps {
  onChange: any;
  value?: string;
}
const Label = styled.label`
  font-weight: 500;
`;
const Textarea = styled.textarea<TextAreaProps>`
  display: block;
  width: 100%;
  height: 200px;
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
`;

const Row = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  &:nth-child(1) {
    margin-top: 24px;
  }
  &:nth-child(2) {
    margin-top: 42px;
    margin-bottom: 36px;
  }
`;

const Col = styled.div`
  flex: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .scriptSizeContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 65px;
      width: 32%;
      border-radius: 4px;
      border: solid 1px #d0d3d9;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: black;
      cursor: pointer;
      font-weight: 700;
      &.smallSize {
        font-size: 14px;
      }
      &.mediumSize {
        font-size: 18px;
      }
      &.largeSize {
        font-size: 24px;
      }
      &.active {
        border: solid 1px ${props => props.theme.colors.primary[100]};
        outline: 0;
      }
      svg {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
  }
`;

const FieldWrapper = styled.div<{ width: string }>`
  width: ${props => (props.width ? props.width : 'auto')};
  > div {
    margin-left: 0;
    width: 90%;
  }
`;

const Form = styled.form`
  float: left;
  width: 100%;
  color: #9297a2;
`;

const Container = styled.div`
  width: 592px;
  height: 648px;
  background: #ffffff;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  padding: 25px;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  width: 275px;
  height: 24px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #272a32;
`;

const CloseButtonWrap2 = styled.div`
  margin-left: 20px;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const CovideoScriptTitle = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;

enum Views {
  LIST = 1,
  FILES = 2,
  CANCEL = 3,
}

const scriptPositions = [
  {
    value: 'top',
    text: 'Top',
  },
  {
    value: 'bottom',
    text: 'Bottom',
  },
];

const CREATE_NEW_SCRIPT_ID = -1;

export const ModalTeleprompterRecord = ({
  handleModalClose,
  handleSubmit,
  settings,
}: Props) => {
  const defaultOptions = [
    { title: 'Create a new script', scriptId: CREATE_NEW_SCRIPT_ID },
  ];
  const defaultScriptTitle = `New Script ${dayjs(new Date()).format('YYYY-MM-DD')}`;
  const [currentView, setCurrentView] = useState<Views>(Views.LIST);
  const [currentTab, setCurrentTab] = useState<any>(0); // 0: Script, 1: appearance
  const [scriptTextTouched, setScriptTextTouched] = React.useState(false);
  const [appearanceConfig, setAppearanceConfig] = React.useState<any>({
    speed: settings?.speed || sizeToSpeedRatioMedium[2],
    scriptSize: settings?.scriptSize || 'large',
    scriptPosition: settings?.scriptPosition || scriptPositions[0].value,
    content: decodeAndReplaceText(settings?.content || ''),
    activeScriptId: settings?.activeScriptId || CREATE_NEW_SCRIPT_ID,
  });
  const [scriptText, setScriptText] = React.useState<any>(
    decodeAndReplaceText(appearanceConfig.content || '')
  );
  const [isNewScript, setIsNewScript] = React.useState(false);
  const [scriptTitleText, setScriptTitleText] =
    React.useState(defaultScriptTitle);
  const [selectedScriptFile, setSelectedScriptFile] = React.useState<any>({
    url: '',
    id: '',
  });
  const [scriptLoading, setScriptLoading] = React.useState<any>(false);
  const [scriptArray, setScriptArray] = React.useState<any>(defaultOptions);
  const themes = useTheme();
  const { userData } = useAuth();

  const getAllScripts = async () => {
    try {
      setScriptLoading(true);
      const scriptsArrayObj: {
        scripts: ScriptType[];
        count: number;
      } = await getScripts({});
      sortScripts(scriptsArrayObj.scripts);

      if (!scriptsArrayObj.count) {
        setScriptLoading(false);
      }

      let scriptOptions = JSON.parse(JSON.stringify(scriptArray));

      scriptsArrayObj.scripts.forEach((e: ScriptType) => {
        scriptOptions = [...scriptOptions, e];
      });

      scriptOptions = uniqBy(scriptOptions, 'scriptId');

      setScriptArray(scriptOptions);
      setScriptLoading(false);
    } catch (exp) {
      console.log('exp at get scripts', exp);
    }
  };

  const sortScripts = (unsortedScripts: any) => {
    return unsortedScripts.sort(
      (
        a: { scriptId: string; title: string },
        b: { scriptId: string; title: string }
      ) => a.title.localeCompare(b.title)
    );
  };

  const handleScript = (ele: any) => {
    if (ele.scriptId !== '' && ele.scriptId > CREATE_NEW_SCRIPT_ID) {
      const content = decodeAndReplaceText(ele.content || '');
      setScriptTitleText(ele.title);
      setScriptText(content);
      setAppearanceConfig({
        ...appearanceConfig,
        content,
        activeScriptId: ele.sharedWithAll ? CREATE_NEW_SCRIPT_ID : ele.scriptId,
      });
    } else {
      setScriptText('');
      setAppearanceConfig({
        ...appearanceConfig,
        content: '',
        activeScriptId: ele.sharedWithAll ? CREATE_NEW_SCRIPT_ID : ele.scriptId,
      });
      setScriptTitleText(ele.title ?? defaultScriptTitle);
    }
    if (!scriptArray[0].scriptId) {
      scriptArray.shift();
      setScriptArray(scriptArray);
    }

    setScriptTextTouched(false);
    setScriptLoading(false);
  };

  const handleChange = (event: any) => {
    setScriptText(event.target.value);
    setScriptTextTouched(true);
  };
  const handleScriptSpeed = (ele: any) => {
    setAppearanceConfig({ ...appearanceConfig, speed: ele });
  };

  const handleScriptSize = (ele: any) => {
    setAppearanceConfig({ ...appearanceConfig, scriptSize: ele });
  };

  const handleScriptPosition = (ele: any) => {
    setAppearanceConfig({ ...appearanceConfig, scriptPosition: ele });
  };

  const handleNewScript = async () => {
    if (appearanceConfig.activeScriptId < 0) {
      setIsNewScript(true);
    } else {
      await updateOldScript();
    }
  };

  React.useEffect(() => {
    getAllScripts();
  }, []);

  const updateOldScript = async () => {
    const content = encodeURIComponent(scriptText || '');
    const res = await updateScript({
      scriptId: appearanceConfig.activeScriptId,
      data: {
        content,
      },
    });
    if (res) {
      let scriptArrayLocal = JSON.parse(JSON.stringify(scriptArray));
      let indexOfListedEle = scriptArrayLocal.findIndex(
        (element: any) => element.scriptId == appearanceConfig.activeScriptId
      );
      if (indexOfListedEle != -1) {
        scriptArrayLocal[indexOfListedEle].content = decodeAndReplaceText(
          res.content
        );
      }
      setScriptArray(scriptArrayLocal);
      setIsNewScript(false);
      setAppearanceConfig({ ...appearanceConfig, content });
      setScriptTextTouched(false);
      successToast({
        title: `Script updated sucessfully.`,
      });
    }
  };

  const createNewScript = async () => {
    const content = encodeURIComponent(scriptText || '');
    const res = await createScript({
      data: {
        title: scriptTitleText,
        content,
      },
    });
    if (res) {
      let newList = scriptArray.filter((option: any) => !!option.scriptId);
      newList = [...newList, res];
      sortScripts(newList);
      newList = [...defaultOptions, ...newList];
      setScriptArray(newList);
      setIsNewScript(false);
      setAppearanceConfig({
        ...appearanceConfig,
        activeScriptId: res.scriptId,
        content,
      });
      setScriptTextTouched(false);
      successToast({
        title: `Script saved as ${scriptTitleText}.`,
      });
      setScriptTitleText(scriptTitleText);
      setIsNewScript(false);
    }
  };

  const readFileAndUpdateAppearanceConfig = async (source: any) => {
    fetch(source).then(function (response) {
      response.text().then(function (text) {
        setAppearanceConfig({ ...appearanceConfig, content: text });
      });
    });
  };

  const defaultPromptUseCase = getDefaultPromptUseCase(userData.isAutomotive);

  const sortedScriptArray = [
    ...scriptArray.filter((script: ScriptType) => !script.sharedWithAll),
    ...scriptArray.filter((script: ScriptType) => script.sharedWithAll),
  ];

  const CustomSingleValue = (props: any) => {
    const { data } = props;
    return (
      <components.SingleValue {...props}>
        {data.sharedWithAll ? (
          <CovideoScriptTitle>
            <img
              src={mobileLogoActive}
              alt='Covideo Logo'
              width={14}
              height={14}
            />
            {data.title}
          </CovideoScriptTitle>
        ) : (
          <>{data.title}</>
        )}
      </components.SingleValue>
    );
  };

  const CustomOption = (props: OptionProps<OptionTypeBase, boolean>) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          padding: '10px 15px',
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
          color: '##001B53',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {data.sharedWithAll ? (
          <CovideoScriptTitle>
            <img
              src={mobileLogoActive}
              alt='Covideo Logo'
              width={14}
              height={14}
            />
            {data.title}
          </CovideoScriptTitle>
        ) : (
          data.title
        )}
      </div>
    );
  };

  const List = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader>Teleprompter</ContentHeader>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => setCurrentView(Views.CANCEL)}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </ContentHeaderWrap>
        <ContentBody>
          <TabHeader>
            <div
              className={` scriptTab ${currentTab == 0 ? 'active' : ''}`}
              onClick={() => setCurrentTab(0)}
            >
              Script
            </div>

            <div
              className={` appearanceTab  ${currentTab == 1 ? 'active' : ''}`}
              onClick={() => {
                setCurrentTab(1);
                setIsNewScript(false);
              }}
            >
              Appearance
            </div>
          </TabHeader>
          {currentTab == 1 ? (
            <Form>
              <Row>
                <Col>
                  <Label htmlFor='audioSource'>Speed</Label>
                  <Select
                    name='videoSource'
                    value={appearanceConfig.speed}
                    placeholder='Select script...'
                    options={handleScriptSpeedUsingScriptSize(
                      appearanceConfig.scriptSize
                    )}
                    onChange={handleScriptSpeed}
                    dropdownHeight={140}
                    height={40}
                    width={130}
                    styles={{
                      control: (base: any) => ({
                        ...base,
                        height: '40px',
                        cursor: 'pointer',
                      }),
                      option: (base: any) => ({
                        ...base,
                        color: 'black',
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '400px',
                      }),
                      indicatorSeparator: () => ({ display: 'none' }),
                    }}
                  />
                </Col>
                &nbsp;&nbsp;
                <Col>
                  <Label htmlFor='audioSource'>Position</Label>
                  <div>
                    <FieldWrapper width='150px'>
                      <ButtonSwitch
                        defaultValue={appearanceConfig.scriptPosition}
                        values={scriptPositions}
                        onChange={handleScriptPosition}
                      />
                    </FieldWrapper>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Label>Text size</Label>
                  <div className='scriptSizeContainer'>
                    <div
                      className={` smallSize ${
                        appearanceConfig.scriptSize == 'small' ? 'active' : ''
                      }`}
                      onClick={() => {
                        handleScriptSize(`small`);
                      }}
                    >
                      Small
                      {appearanceConfig.scriptSize == 'small' ? (
                        <StyledIoIosCheckmarkCircle
                          fill={themes.colors.primary[100]}
                          size={22}
                        />
                      ) : null}
                    </div>
                    <div
                      className={` mediumSize ${
                        appearanceConfig.scriptSize == 'large' ? 'active' : ''
                      }`}
                      onClick={() => {
                        handleScriptSize(`large`);
                      }}
                    >
                      Medium
                      {appearanceConfig.scriptSize == 'large' ? (
                        <StyledIoIosCheckmarkCircle
                          fill={themes.colors.primary[100]}
                          size={22}
                        />
                      ) : null}
                    </div>
                    <div
                      className={` largeSize ${
                        appearanceConfig.scriptSize == 'x-large' ? 'active' : ''
                      }`}
                      onClick={() => {
                        handleScriptSize(`x-large`);
                      }}
                    >
                      Large
                      {appearanceConfig.scriptSize == 'x-large' ? (
                        <StyledIoIosCheckmarkCircle
                          fill={themes.colors.primary[100]}
                          size={22}
                        />
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          ) : (
            <>
              <Gap flexWrap='no-wrap' alignItems='center'>
                <SelectContent>
                  <div
                    className={`scriptList ${
                      userData.droplrAccess ? '' : 'fullWidth'
                    }`}
                  >
                    <Select
                      components={{
                        SingleValue: CustomSingleValue,
                        Option: CustomOption,
                      }}
                      styles={{
                        control: (base: any) => ({
                          ...base,
                          height: '40px',
                          cursor: 'pointer',
                        }),
                        option: (base: any) => ({
                          ...base,
                          cursor: 'pointer',
                        }),
                        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                        indicatorSeparator: () => ({ display: 'none' }),
                      }}
                      options={sortedScriptArray}
                      menuPortalTarget={document.body}
                      menuPosition='fixed'
                      menuPlacement={'bottom'}
                      placeholder='Select a script'
                      value={scriptArray.find(
                        (ele: any) =>
                          ele.scriptId == appearanceConfig.activeScriptId
                      )}
                      onChange={handleScript}
                      getOptionLabel={option => option.title}
                      getOptionValue={option => option.scriptId}
                      isLoading={scriptLoading}
                      onMenuClose={() => setScriptLoading(false)}
                      isDisabled={scriptLoading}
                    />
                  </div>
                </SelectContent>
                <CovideoMagicButton
                  userInput={scriptText}
                  defaultPromptType={PROMPT_TYPE.TELEPROPTER_SCRIPT}
                  defaultPromptUseCase={defaultPromptUseCase}
                  handleSubmitGeneratedContent={(generatedContent: string) => {
                    setScriptText(generatedContent);
                    setScriptTextTouched(true);
                  }}
                  variant='gradient'
                  token={getCurrentToken().token as string}
                  userData={{
                    customer: {
                      business: userData.customer.business,
                      hasCovideoMagic:
                        userData?.customer?.hasCovideoMagic?.toString() === '1',
                      markVideosAsSent: userData.customer.markVideosAsSent,
                    },
                    firstName: userData.firstName,
                    isAutomotiveSalesRole: userData.isAutomotiveSalesRole,
                    isAutomotiveServiceRole: userData.isAutomotiveServiceRole,
                    isIMSEnabled: userData.isIMSEnabled,
                    isAutomotive: userData.isAutomotive,
                    isCompanyAdmin: userData.isCompanyAdmin,
                  }}
                  aiAssistTheme={'branding'}
                  apiKey={process.env.REACT_APP_APIKEY}
                  env={
                    (process.env.REACT_APP_ENV as APP_ENVIROMENT) ||
                    'production'
                  }
                />
              </Gap>
              <Textarea
                value={scriptText}
                onChange={handleChange}
                placeholder='Type or paste your script here...'
              />
            </>
          )}
        </ContentBody>
      </Content>
      <ButtonContainer>
        {!isNewScript ? (
          <div className='newScriptDiv'>
            {currentTab != 1 ? (
              <Button
                disabled={
                  !appearanceConfig.activeScriptId ||
                  !scriptText ||
                  (scriptText && !scriptTextTouched) ||
                  scriptText.trim() === ''
                }
                variant='secondary'
                text='Save To My Scripts'
                icon={<MdSave size={18} />}
                onClick={handleNewScript}
              />
            ) : (
              <div> </div>
            )}
            <Button
              text={'Turn On'}
              disabled={
                (appearanceConfig.activeScriptId !== CREATE_NEW_SCRIPT_ID &&
                  !appearanceConfig.content) ||
                (appearanceConfig.activeScriptId === CREATE_NEW_SCRIPT_ID &&
                  (!scriptText ||
                    scriptText.trim() === '' ||
                    (scriptText && !scriptTextTouched)))
              }
              onClick={() => {
                const data = appearanceConfig;
                if (data.activeScriptId === CREATE_NEW_SCRIPT_ID) {
                  data.content = scriptText;
                }
                if (handleSubmit) {
                  handleSubmit(data);
                }
              }}
            />
          </div>
        ) : (
          <div className='saveNewScript'>
            <NewTextInput
              style={{ boxSizing: 'border-box', width: '75%' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setScriptTitleText(e.target.value)
              }
              value={scriptTitleText}
            />
            <Button
              text='Save'
              icon={<MdSave size={18} />}
              onClick={async () => {
                await createNewScript();
              }}
            />
            <div className='closeCreateScriptView'>
              <MdOutlineClose
                size='24px'
                color='#272A32'
                onClick={() => {
                  setIsNewScript(false);
                }}
              />
            </div>
          </div>
        )}
      </ButtonContainer>
    </ModalItem>
  );

  const DroplrFilesView = (
    <Modal
      style={{
        position: 'fixed',
      }}
    >
      <Container
        style={{
          width: '592px',
          height: '648px',
          background: '#FFFFFF',
          border: '1px solid #F7F8FA',
          boxShadow: '0px 4px 12px rgba(29, 30, 36, 0.04)',
          borderRadius: '4px',
        }}
      >
        <Header>
          <Title>Select file</Title>
          <CloseButtonWrap2 title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => setCurrentView(Views.LIST)}
              color={theme.palette.label}
            />
          </CloseButtonWrap2>
        </Header>

        <br />
        <DroplrFilesTabContent
          selectedVirtualBackgroundUrl={selectedScriptFile.url}
          onSelect={(url: string = '', id: string = '') => {
            setSelectedScriptFile({ url, id });
            setCurrentTab(1);
            readFileAndUpdateAppearanceConfig(url);
          }}
          handleModalClose={() => {
            setCurrentView(Views.LIST);
          }}
        />
      </Container>
    </Modal>
  );

  const CancelView = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader>Cancel Teleprompter?</ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          You will lose all changes for the Teleprompter.
        </ContentBody>
      </Content>
      <ButtonContainer style={{ justifyContent: 'end' }}>
        <Button
          variant='secondary'
          text='Back to editing'
          onClick={() => setCurrentView(Views.LIST)}
        />
        <Button
          text='Yes, cancel'
          onClick={() => handleModalClose()}
          variant='destructive'
        />
      </ButtonContainer>
    </ModalItem>
  );

  let CurrentVievComponent = null;
  switch (currentView) {
    case Views.LIST:
      CurrentVievComponent = List;
      break;
    case Views.FILES:
      CurrentVievComponent = DroplrFilesView;
      break;
    case Views.CANCEL:
      CurrentVievComponent = CancelView;
      break;
  }
  return (
    <Modal
    //   maxHeight={
    //     currentView == Views.CONFIRM
    //       ? '200px'
    //       : currentView == Views.LIST
    //       ? '100%'
    //       : currentView == Views.CANCEL
    //       ? 'auto'
    //       : undefined
    //   }
    //  overrideBodyHeight={true}
    >
      {CurrentVievComponent}
    </Modal>
  );
};
