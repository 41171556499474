import React from 'react';
import styled from 'styled-components/macro';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
  CheckboxInput,
} from 'lib/components';
import { MdEdit } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { IGroups } from 'lib/api/leadGroups/useLeadGroupsQuery';
import { useHistory } from 'react-router';

const TableCell = styled.div<{ width?: string }>`
  width: ${({ width }) => width || 'auto'};
  padding-left: 24px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

type Props = {
  count: number;
  page: number;
  size: number;
  groups: IGroups[];
  onPaginationChangeHandler: ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => void;
  onSelectedGroupHandler: (group: IGroups) => void;
  setLeadGroupIds: React.Dispatch<React.SetStateAction<number[]>>;
  leadGroupIds: number[];
};
export const GroupTable = ({
  groups,
  count,
  onPaginationChangeHandler,
  page,
  size,
  onSelectedGroupHandler,
  setLeadGroupIds,
  leadGroupIds,
}: Props) => {
  const history = useHistory();
  const handleGroupSelection = (groupId: number, isChecked: boolean) => {
    setLeadGroupIds(prevIds => {
      if (isChecked) {
        return [...prevIds, groupId];
      }
      return prevIds.filter(id => id !== groupId);
    });
  };

  return (
    <TableContextProvider
      total={count}
      onChange={onPaginationChangeHandler}
      initPage={page}
      initSize={size}
    >
      <Table
        compact={true}
        headers={[
          '',
          <TableCell width={'400px'}>Group Name</TableCell>,
          <TableCell># of Customers</TableCell>,
          <TableCell></TableCell>,
        ]}
        hoverable={false}
        rows={groups.map((group, index: number) => ({
          key: index,
          columns: [
            <CheckboxInput
              width='24px'
              blueCheck={true}
              onChange={(e: React.SyntheticEvent) => {
                const { checked } = e.target as HTMLInputElement;
                handleGroupSelection(group.leadGroupId, checked);
              }}
              checked={leadGroupIds.includes(group.leadGroupId)}
            />,
            <TableCell>{group?.name}</TableCell>,
            <TableCell>{group?.leads?.length || 0}</TableCell>,
            <TableCell>
              <Gap width='100%' justifyContent='flex-end' gap='8px'>
                <Button
                  variant='secondary'
                  icon={<MdEdit size='20' />}
                  onClick={() => onSelectedGroupHandler(group)}
                />

                <Button
                  variant='secondary'
                  text='Details'
                  onClick={() =>
                    history.push(`/customers/group-items/${group.leadGroupId}`)
                  }
                />
              </Gap>
            </TableCell>,
          ],
        }))}
      />
      <TableFooter>
        <TablePaginationNew />
        <TablePaginationSizeNew text='Show groups:' />
      </TableFooter>
    </TableContextProvider>
  );
};
