import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import dayjs from 'dayjs';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import { preparePrice } from 'lib/utils/invoice';
import { isNull, isEmpty } from 'lodash';
import {
  packageAdditionalInfo,
  PackageName,
} from 'lib/const/PackageAdditionalInfo';
import { MdEdit } from 'react-icons/md';
import { IoMdLock } from 'react-icons/io';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { GetSubscriptionResponse } from 'lib/api/billing/useSubscriptionQuery';

const SubscriptionWrapper = styled.div`
  display: flex;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  padding: 20px 15px 20px 20px;
  .div--left {
    width: 50%;
    display: flex;
    column-gap: 40px;
  }
  .div--right {
    width: 50%;
    display: flex;
    column-gap: 20px;
    justify-content: end;
  }
  ${theme.mediaQueryMaxWidth.lg} {
    flex-direction: column;
    row-gap: 40px;
    .div--left,
    .div--right {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }
`;

const SubscriptionDiv = styled.div`
  font-size: 14px;
  .plan--name {
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
    color: ${theme.palette.primaryDarkBlue};
    &--upcoming {
      padding-top: 10px;
      font-size: 16px;
      color: rgba(0, 27, 83, 0.6);

      font-weight: 800;
    }
  }
  .manage--btn {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
  }
  .plan--trial {
    color: ${theme.palette.primaryRedDanger};
  }
  .font--14 {
    font-size: 14px;
  }
  .plan--details {
    display: flex;
    padding-top: 10px;
  }
  ${theme.mediaQueryMaxWidth.lg} {
    width: 100%;
    .manage--btn {
      justify-content: start;
    }
  }
`;

const SubscriptionDivUntill = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  min-width: max-content;
  font-size: 14px;
  ${theme.mediaQueryMaxWidth.lg} {
    width: 100%;
    justify-content: start;
  }
`;

const Wrapper = styled.div`
  background: ${theme.palette.white};
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 4px;
  padding: 24px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Plan = styled.div`
  display: block;
`;
const Title = styled.div`
  color: ${theme.palette.blue100};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
type ActivePlanProps = {
  isBeingCanceled: boolean;
};
const ActivePlan = styled.div<ActivePlanProps>`
  color: ${theme.palette.gray100};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  ${({ isBeingCanceled }) =>
    isBeingCanceled &&
    css`
      color: ${theme.palette.red100};
    `}
`;
const NextPayment = styled.div`
  margin: 0 40px 0 auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray100};
`;
const ManagePlan = styled.div``;
const BillingCycle = styled.div`
  color: ${theme.palette.gray60};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
const Price = styled.span`
  color: ${theme.palette.covideoOrange100};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

type Props = {
  handleMangeModal: (data: any) => void;
  setShowAmModal: (show: boolean) => void;
  addonData: any;
  subscription: GetSubscriptionResponse | undefined;
};

export const CurrentSubscription = ({
  setShowAmModal,
  subscription,
}: Props) => {
  const { plan, upcomingInvoice, cancelAt, nextPeriodStart } =
    subscription || {};
  const { userData } = useAuth();
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [isLegacyAccount, setIsLegacyAccount] = React.useState(true);
  const [currentPackageId, setCurrentPackageId] = React.useState(
    PackageName.LEGACY
  );

  const [currentPackageAdditionalInfo, setCurrentPackageAdditionalInfo] =
    React.useState(packageAdditionalInfo[PackageName.LEGACY]);

  const isBeingCanceled = cancelAt !== null;

  useEffect(() => {
    if (userData?.user?.packageDetails?.id) {
      setCurrentPackageId(userData?.user?.packageDetails?.id);
      setIsLegacyAccount(false);
      setCurrentPackageAdditionalInfo(
        packageAdditionalInfo[userData?.user?.packageDetails?.id]
      );
    }
  }, [userData]);

  return (
    <>
      {userData.trialAccount ? (
        <SubscriptionWrapper>
          <div className='div--left'>
            <SubscriptionDiv>
              <div className='plan--trial font--14'>
                Trial active until{' '}
                {dayjs(userData.signupDate)
                  .add(7, 'day')
                  .format(STANDARD_DATE_FORMAT)}
              </div>
              <div className='plan--name'>Covideo Trial</div>
            </SubscriptionDiv>
          </div>
          <div className='div--right'>
            <SubscriptionDiv>
              <div className='manage--btn'>
                <Button
                  onClick={() => setShowContactUsModal(true)}
                  text='Upgrade Plan'
                  variant='primary'
                ></Button>
              </div>
            </SubscriptionDiv>
          </div>
        </SubscriptionWrapper>
      ) : isLegacyAccount ? (
        <SubscriptionWrapper>
          <div className='div--left'>
            <SubscriptionDiv>
              {isNull(cancelAt) ? (
                <div>Active plan</div>
              ) : (
                <div className='plan--trial font--14'>
                  Active until{' '}
                  {cancelAt &&
                    dayjs(new Date(cancelAt * 1000)).format(
                      STANDARD_DATE_FORMAT
                    )}
                </div>
              )}
              <div className='plan--name'>
                {(plan && plan.interval && plan.valid
                  ? plan.interval == 'year'
                    ? 'Covideo Annual'
                    : 'Covideo Monthly'
                  : (plan && !plan.valid) ||
                      (userData.customer && !userData.customer.stripeId)
                    ? 'Covideo Legacy Plan'
                    : null) || '--'}
              </div>
            </SubscriptionDiv>
            {!isNull(nextPeriodStart) ? (
              <SubscriptionDiv>
                <div className='font--14'>
                  From{' '}
                  {!isNull(nextPeriodStart)
                    ? nextPeriodStart &&
                      dayjs(new Date(nextPeriodStart * 1000)).format(
                        STANDARD_DATE_FORMAT
                      )
                    : '--'}
                </div>
                <div className='plan--name--upcoming'>
                  Covideo{' '}
                  {plan && plan.interval == 'year' ? 'Monthly' : 'Annual'}
                </div>
              </SubscriptionDiv>
            ) : null}
          </div>
          <div className='div--right'>
            {/* cancelAt is null that means subscription is activated */}
            {isNull(cancelAt) ? (
              <>
                {upcomingInvoice &&
                Object.keys(upcomingInvoice).length &&
                plan &&
                plan.valid ? (
                  <SubscriptionDivUntill>
                    Next payment on&nbsp;
                    {/* Next payment on &nbsp; */}
                    {upcomingInvoice.created ? (
                      <b>
                        {dayjs(new Date(upcomingInvoice.created * 1000)).format(
                          STANDARD_DATE_FORMAT
                        )}
                      </b>
                    ) : (
                      '--'
                    )}
                    :&nbsp;
                    {upcomingInvoice.amountDue
                      ? preparePrice(upcomingInvoice.amountDue, 'USD', true)
                      : '--'}{' '}
                  </SubscriptionDivUntill>
                ) : (plan && !plan.valid) ||
                  (userData.customer && !userData.customer.stripeId) ? (
                  <SubscriptionDivUntill>
                    Contact your AM to discuss your current pricing plan
                  </SubscriptionDivUntill>
                ) : null}
              </>
            ) : null}
            <SubscriptionDiv>
              <div className='manage--btn'>
                <Button
                  onClick={() => setShowAmModal(true)}
                  text='Manage Subscription'
                  variant='primary'
                ></Button>
              </div>
            </SubscriptionDiv>
          </div>
        </SubscriptionWrapper>
      ) : !isLegacyAccount ? (
        <Wrapper>
          <Row>
            <Plan>
              <ActivePlan isBeingCanceled={isBeingCanceled}>
                {!isBeingCanceled
                  ? 'Active plan'
                  : `Active plan until ${
                      cancelAt &&
                      dayjs(new Date(cancelAt * 1000)).format(
                        STANDARD_DATE_FORMAT
                      )
                    }`}
              </ActivePlan>
              <Title>Covideo {currentPackageAdditionalInfo.title}</Title>
              {currentPackageId !== PackageName.FREE && (
                <BillingCycle>
                  <Price>
                    {upcomingInvoice?.amountDue
                      ? preparePrice(upcomingInvoice.amountDue, 'USD', true)
                      : '--'}
                  </Price>{' '}
                  {plan?.interval === 'year' ? 'per year' : 'per month'}
                </BillingCycle>
              )}
            </Plan>
            {currentPackageId !== PackageName.FREE &&
              !isEmpty(upcomingInvoice) && (
                <NextPayment>
                  Next payment on{' '}
                  <b>
                    {upcomingInvoice &&
                      dayjs(new Date(upcomingInvoice?.created * 1000)).format(
                        STANDARD_DATE_FORMAT
                      )}
                  </b>
                </NextPayment>
              )}
            <ManagePlan>
              {currentPackageId === PackageName.FREE && (
                <Button
                  onClick={() => setShowContactUsModal(true)}
                  text='Upgrade to Pro'
                  icon={<IoMdLock />}
                />
              )}
              {currentPackageId === PackageName.ENTERPRISE && (
                <Button
                  onClick={() => setShowContactUsModal(true)}
                  text='Manage Subscription'
                  icon={<MdEdit />}
                  variant='secondary'
                />
              )}
              {[PackageName.PRO, PackageName.TEAMS].includes(
                currentPackageId
              ) && (
                <Button
                  onClick={() => setShowContactUsModal(true)}
                  text='Manage Subscription'
                  icon={<MdEdit />}
                  variant='secondary'
                />
              )}
            </ManagePlan>
          </Row>
        </Wrapper>
      ) : null}
      {showContactUsModal && (
        <ModalContactAM
          handleModalClose={() => setShowContactUsModal(false)}
          subject={'Covideo Subscription Update Request'}
          title='Manage Subscription'
        />
      )}
    </>
  );
};
