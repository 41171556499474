import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { leadOpportunitiesKeys } from './queryKeys';
import { LeadOpportunitiesBaseParams } from './types';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { SORT_KEYS } from 'app/pages/reports/leads/main/MainLeadReports';

export interface ILeadOpportunitiesMetrics {
  vinSolutionsLeadId: number;
  date: string;
  leadName: string;
  assignee: string;
  recorded: number;
  sent: number;
  views: number;
  leadSource: string;
  leadGroupCategory: string;
  isHot: number;
  leadStatus: string;
  leadStatusType: string;
}

export interface LeadOpportunitiesParams extends LeadOpportunitiesBaseParams {
  start?: number;
  limit?: number;
  sortKey?: SORT_KEYS;
  order?: PaginationConstants;
}

export interface LeadOpportunityResponse {
  leadOpportunitiesMetrics: ILeadOpportunitiesMetrics[];
  count: number;
}

const getLeadsOpportunities = async ({
  params,
}: {
  params?: LeadOpportunitiesParams;
}): Promise<LeadOpportunityResponse> => {
  const response = await EXPRESS_API.get(`/lead-opportunities/metrics`, {
    params: params,
  });
  return response.data;
};

export const useLeadOpportunutiesQuery = ({
  params,
}: {
  params?: LeadOpportunitiesParams;
}) => {
  const { showError } = useToastError();

  return useQuery(
    leadOpportunitiesKeys.list(params),
    () => getLeadsOpportunities({ params }),
    {
      onError: showError,
      refetchOnMount: true,
    }
  );
};
