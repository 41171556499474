import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { departmentKeys } from './queryKeys';

export interface CreateDepartmentResponse {
  data: {
    name: string;
    customerId: number;
    id: number;
  };
}

export const createDepartment = async ({
  data,
}: {
  data: { name: string };
}): Promise<CreateDepartmentResponse> => {
  const response = await EXPRESS_API.post(`/departments`, data);
  return response.data;
};

export const useCreateDepartmentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createDepartment, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(departmentKeys.all());
      successToast({ title: 'Department created successfully' });
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred while creating the department. Please try again.',
      });
    },
  });
};
