import * as React from 'react';
import { IoIosPlay } from 'react-icons/io';
import { Button } from 'react-covideo-common';
import { FcCheckmark } from 'react-icons/fc';
import { MoonLoader } from 'react-spinners';

import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';

interface Props {
  thumbnailSource?: string;
  title: string;
  isDefault?: boolean;
  noThumbnail?: boolean;
  onLeftButtonPress?: (params?: any) => Promise<void>;
  optionButtonsRight?: React.ReactNode[];
  disableLeftClick?: boolean;
}

interface ContainerProps {
  noThumbnail: boolean;
  isDefault?: boolean;
}

interface DetailsSectionProps {
  opacity: number;
}

interface ThumbnailProps {
  opacity: number;
}
const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(33% - 15px);
  max-width: calc(33% - 15px);
  height: 316px;
  border-radius: 4px;
  border: 1px solid #f7f8fa;

  box-shadow: ${props =>
    props.isDefault
      ? `0 6px 20px 0 ${props.theme.colors.primary[100]}`
      : '0 4px 12px 0 rgba(29, 30, 36, 0.04)'};
  background-color: ${props => (props.noThumbnail ? '#f6f7f9' : '#fff')};

  @media (min-width: 1640px) {
    flex: 1 1 calc(25% - 20px);
    max-width: calc(25% - 20px);
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 204px;
  ${theme.mediaQueryMinWidth.sm} {
    margin: 0;
    min-width: 208px;
  }
`;

const ThumbnailContainer = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: ${theme.palette.blackRgba75};
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;

const ThumbnailImage = styled.div`
  display: flex;
  min-width: 45px;
  transition: all 0.2s;
  justify-content: center;
  height: 100%;
`;

type ImgProps = {
  img?: string;
};

const Thumbnail = styled.img<ImgProps>`
  background-color: ${theme.palette.white};
  display: block;
  height: 100%;
  width: auto !important;
  max-width: 100%;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: ${theme.palette.coal};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 270px;
`;

const ThumbPlaceholder = styled.div<ThumbnailProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 204px;
  opacity: ${props => props.opacity};
`;

const GrayBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 167px;
  height: 108px;
  background-color: rgba(0, 27, 83, 0.2);
  border-radius: 8.9px;
`;

const DetailsSection = styled.section<DetailsSectionProps>`
  display: flex;
  padding: 16px;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
  gap: 12px;
`;

const DefaultText = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 107px;
  height: 40px;

  font-weight: 600;
  font-size: 15px;
  color: ${theme.palette.coal};
  svg {
    margin-right: 7px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionsContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  width: ${props => (props.itemsNumber > 1 ? 52 * props.itemsNumber : 138)}px;
  justify-content: ${props =>
    props.itemsNumber > 1 ? 'space-between' : 'flex-end'};
`;

// Component
export const SquareListItem = (props: Props) => {
  const {
    thumbnailSource,
    title,
    isDefault,
    noThumbnail,
    optionButtonsRight,
    onLeftButtonPress,
    disableLeftClick = false,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const themes = useTheme();

  const handleLeftButtonClick = async () => {
    if (onLeftButtonPress) {
      setLoading(true);
      await onLeftButtonPress();
      setLoading(false);
    }
  };

  return (
    <Container
      noThumbnail={!thumbnailSource}
      isDefault={isDefault}
      data-cy='square-list-item'
    >
      {loading && (
        <LoaderWrapper>
          <MoonLoader
            size={32}
            color={themes.colors.primary[100]}
            loading={true}
          />
        </LoaderWrapper>
      )}
      {noThumbnail ? (
        <ThumbPlaceholder opacity={loading ? 0.2 : 1}>
          <GrayBox>
            <IoIosPlay size={30} color={'rgba(0, 27, 83, 0.2)'} />
          </GrayBox>
        </ThumbPlaceholder>
      ) : (
        <ThumbnailWrapper>
          <ThumbnailContainer>
            <ThumbnailImage>
              <Thumbnail src={thumbnailSource} />
            </ThumbnailImage>
          </ThumbnailContainer>
        </ThumbnailWrapper>
      )}
      <DetailsSection opacity={loading ? 0.2 : 1}>
        <Title>{title}</Title>
        <ButtonsContainer>
          {isDefault ? (
            <DefaultText>
              <FcCheckmark size={20} />
              Default
            </DefaultText>
          ) : (
            <Button
              text={'Set as Default'}
              variant='secondary'
              disabled={disableLeftClick}
              onClick={handleLeftButtonClick}
            />
          )}
          {optionButtonsRight && (
            <OptionsContainer itemsNumber={optionButtonsRight.length}>
              {optionButtonsRight.map((item, index) => (
                <React.Fragment key={index}>{item}</React.Fragment>
              ))}
            </OptionsContainer>
          )}
        </ButtonsContainer>
      </DetailsSection>
    </Container>
  );
};
