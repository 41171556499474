import React, { ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { Flex, FlexProps } from '../styles/layout';
import { useField } from 'formik';
import { FormikLabel } from './FormikLabel';

interface CustomRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  wrapper?: FlexProps;
  info?: React.ReactNode;
}

const Input = styled.input<{ disabled?: boolean }>`
  position: absolute;
  opacity: 0;
  width: 24px;
  height: 24px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  z-index: 1;
`;

const Circle = styled.label`
  display: flex;
  align-items: center;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  z-index: 0;
`;

const OuterCircle = styled.span<{ checked: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  border: ${({ checked, theme }) =>
    checked
      ? `1px solid ${theme.colors.primary[100]}`
      : `1px solid ${theme.colors.black[20]}`};
  border-radius: 50%;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.primary[100] : theme.colors.secondary[5]};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const InnerCircle = styled.span<{ checked: boolean; disabled?: boolean }>`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.white[100] : `transparent`};
  border-radius: 50%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const FormikRadioField = ({
  label,
  onChange,
  wrapper,
  info,
  ...props
}: CustomRadioProps) => {
  const [field, _, helpers] = useField(props);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) {
      return;
    }
    if (onChange) {
      onChange(e);
    }
    helpers.setValue(props.value);
  };

  const isChecked = String(props.value) === String(field.value);
  return (
    <Flex {...wrapper}>
      <Input type='radio' {...props} {...field} onChange={handleChange} />
      <Circle htmlFor={props.name}>
        <OuterCircle checked={isChecked} disabled={props.disabled} />
        <InnerCircle checked={isChecked} disabled={props.disabled} />
        {label && (
          <FormikLabel
            label={label}
            isRequired={false}
            disabled={!!props.disabled}
            info={info}
          />
        )}
      </Circle>
    </Flex>
  );
};
