import React from 'react';
import styled from 'styled-components/macro';
import { UsageReportsStatistics } from 'lib/context';
import { UsageStatsCard } from './UsageStatsCard';

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`;

type Props = {
  statistics?: UsageReportsStatistics;
  isLoading: boolean;
};

export const Statistics = ({ statistics, isLoading }: Props) => {
  return (
    <StatsContainer>
      <UsageStatsCard
        count={statistics?.activeUsers ?? 0}
        text='Active Users'
        isLoading={isLoading}
      />
      <UsageStatsCard
        count={statistics?.recordedVideos ?? 0}
        text='Recorded Videos'
        isLoading={isLoading}
      />
      <UsageStatsCard
        count={statistics?.optOuts ?? 0}
        text='Opt-outs'
        isLoading={isLoading}
      />
      <UsageStatsCard
        count={statistics?.sentEmails ?? 0}
        text='Sent Emails'
        isLoading={isLoading}
      />
    </StatsContainer>
  );
};
