import React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';

import { Main } from './main';

export const UserEntry = () => (
  <>
    <DocumentHead title='Manage User' />
    <PageTemplate main={<Main />} />
  </>
);
