import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { get, isNumber } from 'lodash';
import {
  Modal,
  LoadingIndicator,
  CloseButton,
  CheckboxInput,
  Table,
} from 'lib/components';
import { CarServiceItem } from 'lib/api/carService/types';
import DragAndDrop from 'lib/components/inputs/DragAndDrop';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import servicesRequiredFieldsImage from 'assets/icons/servicesRequiredFields.svg';
import { AiFillInfoCircle } from 'react-icons/ai';
import { Button } from 'react-covideo-common';
import { useCarServicesQuery } from 'lib/api/carService/useCarServiceQuery';
import { ServiceActive } from 'lib/const';
import { IoMdClose } from 'react-icons/io';
import { MdWarning } from 'react-icons/md';

const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: auto;
  background-color: #fff;
  margin: 32px;
`;

const Content = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
`;

const ContentHeaderWrap = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  max-width: 100%;
  overflow: none;
`;

const ErrorMessage = styled.div`
  background-color: ${theme.palette.secondaryRedDanger};
  height: 48px;
  width: 100%;
  margin-top: 5px;
  span {
    display: flex;
    color: ${theme.palette.primaryRedDanger};
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 4;
    letter-spacing: normal;
    text-transform: uppercase;
  }
  svg {
    margin: 8px 16px 0 8px;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
`;

const TableCellHeader = styled.div`
  text-align: left;
`;

const TableCell = styled.div`
  padding-right: 8px;
  text-align: left;
`;

const SelectInput = styled(Select)`
  width: 200px;
  border-radius: 4px;
`;
const Label = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;
const Text = styled.div`
  color: ${theme.palette.buttonDanger};
`;

const InfoBox = styled.span`
  background: ${theme.palette.primaryThemeColor10Percent};
  color: ${theme.palette.coal};
  font-weight: 500;
  font-size: 15px;
  padding: 12px;
  border-radius: 5px;
  display: flex;
  margin-bottom: 24px;
  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0 0 0 12px;
    color: ${theme.palette.coal};
    font-size: 16px;
  }
  span {
    margin: 0 0 0 8px;
    color: ${theme.palette.coal};
    font-size: 14px;
  }
`;

const MatchingListWrapper = styled.div`
  background: ${theme.palette.gray10};
  border-radius: 5px;
  padding: 16px;
  height: 312px;
  overflow-y: auto;
  margin-top: 16px;
`;
const MatchingListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
  div:nth-child(1) {
    margin-right: 8px;
  }
  div:nth-child(2) {
    margin-right: auto;
  }
  div:nth-child(2) {
    margin-left: auto;
  }
`;

enum Views {
  MAPPING = 1,
  UPDATE_MATCHING = 2,
  IMPORT = 3,
  LOADING = 4,
}

type Props = {
  handleModalClose: () => void;
  handleImportService: (
    newServices: CarServiceItem[],
    updatedServices: CarServiceItem[]
  ) => void;
  disabled?: boolean;
};

type csvDataProps = {
  data: string[][];
  uploaded?: boolean;
};

type colOptionProps = {
  label: string;
  value: string;
};

const initialColSampleData: { [key: string]: string[] } = {};
const initialCSVData: csvDataProps = { data: [], uploaded: false };
const initialColOptions: colOptionProps[] = [];
const initialColMapping: { [key: string]: string } = {
  title: 'None',
  price: 'None',
};
const initialDuplicatedRows: { [key: string]: string[] } = {};
const initialInvalidRows: { [key: string]: boolean } = {};
const initialMatchingRows: { [key: string]: boolean } = {};

const SAMPLE_LENGTH = 1;
const TITLE_REG = new RegExp('title|name|service$', 'ig');
const PRICE_REG = new RegExp('price|value', 'ig');
const HEADER_REG_VALUES = [
  { field: 'title', reg: TITLE_REG },
  { field: 'price', reg: PRICE_REG },
];
const FieldOptions = [
  { value: 'None', label: 'Choose...' },
  { value: 'title', label: 'Title' },
  { value: 'price', label: 'Price' },
];
const FieldLength = {
  title: 70,
  price: 15,
};
export const ModalImportCsv = ({
  handleModalClose,
  handleImportService,
  disabled = false,
}: Props) => {
  const [currentView, setCurrentView] = useState<Views>(Views.IMPORT);
  const [uploadError, setUploadError] = useState(false);
  const [colOptions, setColOptions] = useState(initialColOptions);
  const [colMapping, setColMapping] = useState(initialColMapping);
  const [colSamples, setColSamples] = useState(initialColSampleData);
  const [CSVData, setCSVData] = useState(initialCSVData);
  const [matchingServices, setMatchingServices] = useState([] as any[]);
  const [selectedMatching, setSelectedMatching] = useState([] as any[]);
  const [invalidCount, setInvalidCount] = useState(0);
  const [duplicateCount, setDuplicateCount] = useState(0);
  const [duplicatedRows, setDuplicatedRows] = useState(initialDuplicatedRows);
  const [submitting, setSubmitting] = React.useState(false);
  const [invalidRows, setInvalidRows] = useState(initialInvalidRows);
  const [matchingRows, setMatchingRows] = useState(initialMatchingRows);
  const [_, setServiceValid] = useState(false);
  const [errors, setErrors] = useState({
    title: '',
    price: '',
  });
  const themes = useTheme();
  const [duplicatedCsvIndex, setDuplicatedCsvIndex] = useState(
    {} as { [key: string]: boolean }
  );

  const { data } = useCarServicesQuery({
    limit: 100,
    start: 0,
    sort: '',
    search: '',
    filterByActive: ServiceActive.Active,
  });

  const servicesArray: CarServiceItem[] = data?.services || [];

  const onModalClose = () => {
    setMatchingServices([]);
    setSelectedMatching([]);
    handleModalClose();
  };

  const initCSVHeaders = (row: string[]) => {
    const options = [];
    let found = false;
    for (let i in row) {
      if (!row[i]) {
        break;
      }
      let value = row[i].toString();
      options.push({ label: value, value: `${i}` });
      for (let header of HEADER_REG_VALUES) {
        if (value.match(header.reg)) {
          found = true;
          setColMapping(value => ({ ...value, [header.field]: `${i}` }));
        }
      }
    }
    options.unshift({ value: 'None', label: 'Choose...' });
    setColOptions(options);
    return found;
  };

  useEffect(() => {
    const values = Object.keys(colMapping).reduce((obj: any, field: string) => {
      CSVData.data.forEach((d: string[]) => {
        if (!obj[field]) {
          obj[field] = [];
        }
        if (d.every(value => !value)) return;
        obj[field].push(getColValue(d, field));
      });
      return obj;
    }, {});

    const invalid: { [key: string]: boolean } = {};
    const duplicatedIndex: { [key: string]: boolean } = {};
    const duplicated: { [key: string]: string[] } = {};
    const matching: { [key: string]: boolean } = {};
    const foundMatchingServicesList = [] as any[];
    let countDuplicate = 0;

    Object.keys(values).forEach(field => {
      const data = values[field];

      for (let i in data) {
        invalid[i] =
          invalid[i] ||
          !validate(data[i], field) ||
          // @ts-ignore
          data[i].length > FieldLength[field];
        if (field === 'title') {
          const title = data[i].toLowerCase();
          if (!duplicated[title]) {
            duplicated[title] = [];
          }
          if (!invalid[i]) {
            const index = Number(i) + 2;
            duplicated[title].push(index.toString());
          }

          let foundMatchingService = servicesArray.find(
            (service: any) => service.title.toLowerCase() === title
          );
          if (foundMatchingService) {
            if (
              !foundMatchingServicesList.find(
                c => c.title.toLowerCase() === title
              )
            ) {
              foundMatchingServicesList.push(foundMatchingService);
            }
            matching[i] = true;
          } else {
            matching[i] = false;
          }

          if (duplicated[title].length > 1) {
            duplicatedIndex[i] = true;
            countDuplicate++;
          } else {
            duplicatedIndex[i] = false;
          }
        }
        if (field === 'price') {
          if (invalid[i]) {
            // if price isn't valid, then remove matching service
            const currentService = values['title'][i];
            const invalidMatchingServiceIndex =
              foundMatchingServicesList.findIndex(
                item =>
                  item.title.toLowerCase() === currentService.toLowerCase()
              );
            if (invalidMatchingServiceIndex > -1)
              foundMatchingServicesList.splice(invalidMatchingServiceIndex, 1);
          }
        }
      }
    });
    setInvalidRows(invalid);
    setDuplicatedRows(duplicated);
    setDuplicatedCsvIndex(duplicatedIndex);
    setMatchingRows(matching);
    setMatchingServices(foundMatchingServicesList);
    setSelectedMatching(foundMatchingServicesList);
    setDuplicateCount(countDuplicate);
    setInvalidCount(Object.values(invalid).filter(Boolean).length);
  }, [colMapping, CSVData.data]);

  const initColOptions = (row: string[]) => {
    const options = row.map((_: string, i: number) => ({
      value: `${i}`,
      label: `Col ${i + 1}`,
    }));

    options.unshift({ value: 'None', label: 'Choose...' });
    setColOptions(options);
  };

  useEffect(() => {
    const bulkValid = !!CSVData.data.length;
    setServiceValid(bulkValid);
  }, [CSVData.data]);

  const initSampleData = (data: string[][]) => {
    let sample = { ...colSamples };
    let sampleCollected;
    let i = 0;
    while (!sampleCollected && i < data.length) {
      let row = data[i];
      row.forEach((field, index) => {
        if (!sample[index]) {
          sample[index] = [];
        }
        sample[index].push(field);
      });
      let values = Object.keys(sample).map(k => sample[k]);
      sampleCollected = values.reduce(
        (collected, fieldSample) =>
          collected && fieldSample.length >= SAMPLE_LENGTH,
        false
      );
      i++;
    }
    setColSamples(sample);
  };

  const onColChange = (field: string, value: string) => {
    const newField =
      Object.keys(colMapping).find(key => colMapping[key] === value) || 'None';
    if (field === 'None') {
      setColMapping({ ...colMapping, [newField]: 'None' });
    } else {
      setColMapping({ ...colMapping, [newField]: 'None', [field]: value });
    }
  };

  const removeEmptyRows = (data: any) => {
    const modifiedData = data.filter((item: any) => item[0] && item[1]);
    return modifiedData;
  };

  const onUpload = (csv: string[][], fileInfo: any) => {
    let data = [...csv];
    setUploadError(false);
    if (!fileInfo.name.includes('csv')) {
      setUploadError(true);
      return;
    }
    const firstRow = data[0];
    const headersPresent = initCSVHeaders(firstRow);
    if (headersPresent) {
      data.shift();
    } else {
      initColOptions(firstRow);
    }
    initSampleData(data);
    // remove empty rows
    data = removeEmptyRows(data);
    setCSVData({ data, uploaded: true });
    setCurrentView(Views.MAPPING);
  };

  const getSampleData = (index: string) => {
    if (!colSamples[index]) {
      return '';
    }
    return colSamples[index].filter(Boolean).slice(0, SAMPLE_LENGTH);
  };

  const parsePrice = (price: string) => {
    if (!price) return null;
    let priceValue = price.toString();
    if (priceValue.includes('$')) priceValue = priceValue.replace('$', '');
    priceValue = priceValue.replace(/\s/g, '');
    const numericalPriceValue = Number(priceValue);
    if (isNaN(numericalPriceValue)) return null;
    return Number(priceValue);
  };

  const getColValue = (data: string[], field: string) => {
    const columnValue = get(colMapping, field, '');
    if (!columnValue || columnValue === 'None') {
      return '';
    }
    return field === 'price'
      ? parsePrice(data[parseInt(columnValue)])
      : data[parseInt(columnValue)] || '';
  };

  const printDuplicatedRows = React.useCallback(() => {
    const result: any[] = [];
    for (let title in duplicatedRows) {
      if (duplicatedRows[title].length > 1) {
        result.push(
          <Label>
            <Text>
              {' '}
              {title} has multiple entries in imported file (line{' '}
              {duplicatedRows[title].join(', ')})
            </Text>
          </Label>
        );
      }
    }
    return result;
  }, [duplicatedRows]);

  const validateTitle = (title: string = '') => {
    let titleError = '';
    if (!title) {
      titleError = 'Please enter a title';
    }
    setErrors({ ...errors, title: titleError });
    return titleError;
  };

  const validatePrice = (price: string = '') => {
    let priceError = '';

    if (!isNumber(price)) {
      priceError = 'Please enter a price';
    }

    setErrors({ ...errors, price: priceError });
    return priceError;
  };

  const validationMapping: { [key: string]: Function } = {
    title: validateTitle,
    price: validatePrice,
  };

  const validate = (value: any, field: string) => {
    if (!validationMapping[field]) {
      return true;
    }

    return !validationMapping[field](value);
  };

  const parseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const onSubmit = () => {
    let newServices: any[] = [];
    let updateServices: any[] = [];
    CSVData.data.forEach((data, i) => {
      //data for inserting
      const csvForSubmit = {
        title: getColValue(data, 'title'),
        price: getColValue(data, 'price'),
      };

      if (csvForSubmit.title || csvForSubmit.price) {
        if (!invalidRows[i] && !duplicatedCsvIndex[i] && !matchingRows[i]) {
          newServices.push(csvForSubmit);
        }
        if (!invalidRows[i] && matchingRows[i]) {
          const originalServiceData = selectedMatching.find(
            row => row.title === csvForSubmit.title
          );
          if (originalServiceData) {
            updateServices.push({ ...originalServiceData, ...csvForSubmit });
          }
        }
      }
    });
    handleImportService(newServices, updateServices);
  };

  const AddBulkService = (
    <ModalItem>
      <Content>
        <ContentHeaderWrap>
          <ContentHeader>Import services</ContentHeader>
          <CloseButton disabled={disabled} onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <div style={{ marginBottom: 8 }}>
            Please make sure your csv file contains the required fields
          </div>
          <img
            style={{ marginBottom: 20 }}
            src={servicesRequiredFieldsImage}
            alt='service'
          />
          <Form
            onSubmit={e => {
              e.stopPropagation();
            }}
          >
            <>
              <DragAndDrop
                showIcon={false}
                onFileLoaded={onUpload}
                parserOptions={parseOptions}
                button={true}
                text={'Drag and drop a .csv file here to upload'}
                isMultiple={false}
              />
              {uploadError && (
                <ErrorMessage>
                  <span>
                    <IoMdClose
                      size={32}
                      color={theme.palette.primaryRedDanger}
                    />
                    Uploaded file is not in .csv format. Try again!
                  </span>
                </ErrorMessage>
              )}
            </>
          </Form>
        </ContentBody>
      </Content>
    </ModalItem>
  );

  const MapImported = (
    <ModalItem>
      <Content style={{ width: 944 }}>
        <ContentHeaderWrap>
          <ContentHeader>Map services fields</ContentHeader>
          <CloseButton disabled={disabled} onClick={onModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <div style={{ marginBottom: 42 }}>
            Connect columns from .csv file to services fields
          </div>
          <Table
            compact={true}
            headers={[
              <TableCellHeader>Column Label (from csv file)</TableCellHeader>,
              <TableCellHeader>Example</TableCellHeader>,
              <TableCellHeader>Service Field</TableCellHeader>,
            ]}
            hoverable={false}
            rows={colOptions.slice(1).map((x, index: number) => ({
              key: index,
              columns: [
                <TableCell>{x.label}</TableCell>,
                <TableCell>{getSampleData(index.toString())}</TableCell>,
                <TableCell>
                  <SelectInput
                    menuPlacement='auto'
                    menuPosition='fixed'
                    placeholder='Choose...'
                    styles={{
                      control: (base: any) => ({ ...base, height: '40px' }),
                      indicatorSeparator: () => ({ display: 'none' }),
                    }}
                    options={FieldOptions}
                    value={FieldOptions.find(
                      option =>
                        option.value ==
                        (Object.keys(colMapping).find(
                          key => colMapping[key] === index.toString()
                        ) || 'None')
                    )}
                    onChange={(option: any) =>
                      onColChange(option.value, index.toString())
                    }
                  />
                </TableCell>,
              ],
              onClick: () => {},
            }))}
          />
          {invalidCount > 0 && invalidCount < CSVData.data.length && (
            <>
              <Row>
                <ErrorMessage>
                  <span>
                    <MdWarning
                      color={theme.palette.primaryRedDanger}
                      size={32}
                    />
                    {invalidCount}/{CSVData.data.length} rows contain invalid
                    data. Those rows will be ignored.
                  </span>
                </ErrorMessage>
              </Row>
            </>
          )}
          {duplicateCount > 0 && (
            <div style={{ maxHeight: 84, overflowY: 'auto', marginTop: 15 }}>
              {printDuplicatedRows()}
            </div>
          )}
          {invalidCount == CSVData.data.length && (
            <>
              <Row>
                <ErrorMessage style={{ minHeight: '48px', height: 'auto' }}>
                  <span
                    style={{
                      margin: '10px',
                      alignItems: 'center',
                      lineHeight: 'normal',
                    }}
                  >
                    <MdWarning
                      color={theme.palette.primaryRedDanger}
                      size={24}
                      style={{ marginTop: '0px' }}
                    />
                    All rows contain invalid service title and/or price data.
                    <br />
                    {colMapping.title !== 'None'
                      ? 'Please check title column label in .csv file and ensure title column mapping with title column field'
                      : 'The Title field is required for importing services into Covideo. Please check your file to ensure you have created and labeled a column for Title.'}{' '}
                  </span>
                </ErrorMessage>
              </Row>
            </>
          )}
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          disabled={CSVData.data.length - invalidCount == 0 || submitting}
          text={'Continue'}
          onClick={() => {
            if (matchingServices.length) {
              setCurrentView(Views.UPDATE_MATCHING);
            } else {
              setSubmitting(true);
              setCurrentView(Views.LOADING);
              onSubmit();
            }
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const UpdateMatchingDataView = (
    <ModalItem>
      <Content style={{ width: 592 }}>
        <ContentHeaderWrap>
          <ContentHeader>
            Update data for {matchingServices.length} matching services?
          </ContentHeader>
          <CloseButton disabled={disabled} onClick={onModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <InfoBox>
            <AiFillInfoCircle
              color={themes.colors.primary[100]}
              size={'20px'}
            />
            <span>
              We found {matchingServices.length} services that match the ones
              already in the system. Do you want to update these services?
            </span>
          </InfoBox>
          <CheckboxWrapper>
            <CheckboxInput
              checked={selectedMatching.length === matchingServices.length}
              onChange={(e: React.SyntheticEvent) => {
                let { checked } = e.target as HTMLInputElement;
                if (checked)
                  setSelectedMatching(matchingServices.map((ele: any) => ele));
                else setSelectedMatching([]);
              }}
            />
            <p>All services</p>
          </CheckboxWrapper>
          <MatchingListWrapper>
            {matchingServices.map((service, i) => (
              <MatchingListItem key={i}>
                <CheckboxWrapper>
                  <CheckboxInput
                    checked={selectedMatching.includes(service)}
                    onChange={(e: React.SyntheticEvent) => {
                      let { checked } = e.target as HTMLInputElement;
                      if (checked)
                        setSelectedMatching([...selectedMatching, service]);
                      else
                        setSelectedMatching(
                          selectedMatching.filter((ele: any) => ele != service)
                        );
                    }}
                  />
                  <span>{service.title}</span>
                </CheckboxWrapper>
                <span>{service.price}</span>
              </MatchingListItem>
            ))}
          </MatchingListWrapper>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          text="Don't update"
          variant='secondary'
          disabled={submitting}
          onClick={() => {
            setSelectedMatching([]);
            setSubmitting(true);
            setCurrentView(Views.LOADING);
            onSubmit();
          }}
        />
        <Button
          disabled={!selectedMatching.length || submitting}
          text={`Update price for ${selectedMatching.length} services`}
          onClick={() => {
            setSubmitting(true);
            setCurrentView(Views.LOADING);
            onSubmit();
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const LoadingView = (
    <ModalItem>
      <Content style={{ width: 1008 }}>
        <ContentHeaderWrap>
          <ContentHeader></ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          <LoadingIndicator isLoading={true} text='Creating services...' />
        </ContentBody>
      </Content>
    </ModalItem>
  );

  let CurrentVievComponent = null;
  switch (currentView) {
    case Views.IMPORT:
      CurrentVievComponent = AddBulkService;
      break;
    case Views.MAPPING:
      CurrentVievComponent = MapImported;
      break;
    case Views.UPDATE_MATCHING:
      CurrentVievComponent = UpdateMatchingDataView;
      break;
    case Views.LOADING:
      CurrentVievComponent = LoadingView;
      break;
  }
  return <Modal>{CurrentVievComponent}</Modal>;
};
