import React from 'react';
import styled from 'styled-components/macro';
import { Field, FieldProps } from 'formik';
import { SelectInput } from 'lib/components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FieldLabel,
  FormProps,
  getNumberInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import { CustomerStatusOptions } from 'lib/const/SuperAdminOptions';
import { IoMdCalendar } from 'react-icons/io';
import { FormikSwitchField } from 'lib/components/formik/FormikSwitchField';
import { FormikCheckboxField } from 'lib/components/formik/FormikCheckbox';
import { Flex } from 'lib/components/styles/layout';

const InputDateWrapper = styled.div`
  position: relative;
  input {
    padding: 8px 0px 8px 8px;
    border-radius: 4px;
    border: solid 1px #d0d3d9;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #272a32;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  color: #9297a2;
  top: 8px;
  right: 8px;
  z-index: 1;
`;

export const LicenseSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;
  return (
    <Section>
      <SectionTitle>License</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>Active</FieldLabel>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{ width: '184px' }}>
              <Field type='select' name='verified'>
                {({ field }: FieldProps) => {
                  const { value, ...rest } = field;
                  return (
                    <SelectInput
                      {...rest}
                      value={value}
                      disabled={isSubmitting}
                      onChange={event => {
                        const activeValue = parseInt(event.target.value, 10);
                        if (activeValue === 2) {
                          setFieldValue(`activeEndDate`, new Date());
                        } else {
                          setFieldValue(`activeEndDate`, undefined);
                        }
                        setFieldValue(`verified`, activeValue);
                      }}
                    >
                      {CustomerStatusOptions.map(option => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </SelectInput>
                  );
                }}
              </Field>
            </div>
            <div style={{ width: '184px' }}>
              {!!values.activeEndDate && (
                <Field type='date' name='activeEndDate'>
                  {({ field }: FieldProps) => {
                    return (
                      <InputDateWrapper>
                        <IconWrapper>
                          <IoMdCalendar size={24} />
                        </IconWrapper>
                        <DatePicker
                          dateFormat='MM/dd/yyyy'
                          {...field}
                          onChange={(value: Date) => {
                            if (!!value) {
                              setFieldValue(`activeEndDate`, value);
                            }
                          }}
                          selected={values.activeEndDate || new Date()}
                        />
                      </InputDateWrapper>
                    );
                  }}
                </Field>
              )}
            </div>
          </div>
        </Row>
        <Row>
          <FieldLabel>Number of User Seats</FieldLabel>
          {getNumberInputField(
            'maxUsers',
            true,
            values.covideoPackageId !== PackageName.LEGACY &&
              values.covideoPackageId !== PackageName.TEAMS &&
              values.covideoPackageId !== PackageName.ENTERPRISE,
            isSubmitting
          )}
        </Row>

        <Row>
          <FieldLabel>Email Limit</FieldLabel>
          {getNumberInputField('emailsSentLimit', false, false, isSubmitting)}
        </Row>

        <Row>
          <FieldLabel>Version Access</FieldLabel>
          <Flex gap='32px' flexDirection='row'>
            <FormikCheckboxField
              name='versionTwoEnabled'
              label='2.0'
              onChange={() => {
                setFieldValue(
                  `versionTwoEnabled`,
                  values.versionTwoEnabled ? 0 : 1
                );
              }}
              disabled={!!values.versionCDSEnabled || isSubmitting}
            />

            <FormikCheckboxField
              name='versionThreeEnabled'
              label='3.0'
              onChange={() => {
                setFieldValue(
                  `versionThreeEnabled`,
                  values.versionThreeEnabled ? 0 : 1
                );
              }}
              disabled={!!values.versionCDSEnabled || isSubmitting}
            />

            <FormikCheckboxField
              name='versionCDSEnabled'
              label='CDS'
              onChange={() => {
                if (!values.versionCDSEnabled) {
                  setFieldValue(`covideoPackageId`, PackageName.LEGACY);
                }

                setFieldValue(
                  `versionCDSEnabled`,
                  values.versionCDSEnabled ? 0 : 1
                );
              }}
              disabled={isSubmitting}
            />
          </Flex>
        </Row>
        {values.covideoPackageId !== PackageName.PRO &&
          values.covideoPackageId !== PackageName.FREE && (
            <FormikSwitchField
              wrapper={{ margin: '0 0 16px 0' }}
              name='freeTrial'
              label='Enable trial'
              onChange={() => {
                setFieldValue('freeTrial', values.freeTrial ? 0 : 1);
              }}
            />
          )}

        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='lesaAccess'
          label='Lesa Video'
          onChange={() => {
            setFieldValue('lesaAccess', values.lesaAccess ? 0 : 1);
          }}
        />
        {values.covideoPackageId !== PackageName.PRO &&
          values.covideoPackageId !== PackageName.FREE && (
            <FormikSwitchField
              wrapper={{ margin: '0 0 16px 0' }}
              name='wheelsTV'
              label='Wheels TV'
              onChange={() => {
                setFieldValue('wheelsTV', values.wheelsTV ? 0 : 1);
              }}
            />
          )}

        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='liveAccess'
          label='Live Access'
          onChange={() => {
            setFieldValue('liveAccess', values.liveAccess ? 0 : 1);
          }}
        />
        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='hasCovideoMagic'
          label='AI Enabled'
          onChange={() => {
            setFieldValue('hasCovideoMagic', values.hasCovideoMagic ? 0 : 1);
          }}
        />
      </RowWrapper>
    </Section>
  );
};
