import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

interface ICreateLeadParams {
  name: string;
}

const createLeadGroup = async (params: ICreateLeadParams) => {
  return await EXPRESS_API.post(`/lead-groups`, { ...params });
};

export const useCreateLeadGroupMutation = () => {
  const { showError } = useToastError();
  return useMutation(createLeadGroup, {
    onSuccess: () => {
      successToast({ title: 'New group has been created!' });
    },
    onError: error =>
      showError(error, 'An error occured while trying to add the group!'),
  });
};
