import React from 'react';
import {
  ActionsWrapper,
  Card,
  CopyItem,
  CopyLinkWrapper,
  CopyTitle,
  CopyValue,
  CopyWrapper,
  // GoogleText,
  // GoogleWrapper,
} from '../styles';
import { successToast } from 'lib/components/toasts/success';
import { checkAttendeeLimit } from 'lib/utils/productFeature';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import { useCurrentAppUrl } from 'lib/hooks/useCurrentAppUrl';
interface MeetingInfoBannerProps {
  meetingId: string | null;
}

const MeetingInfoBanner = ({ meetingId }: MeetingInfoBannerProps) => {
  const { userData } = useAuth();
  const attendeeLimit = checkAttendeeLimit(userData);
  // const [googleCalendar, setGoogleCalendar] = useState<boolean>(false);
  const { livePageUrl } = useCurrentAppUrl();
  const liveLink = meetingId ? livePageUrl + meetingId : '';

  const copyToClipboard = (copy: string | null, type: 'URL' | 'Meeting ID') => {
    const cb = navigator.clipboard;
    if (copy)
      cb.writeText(copy).then(() => {
        successToast({ title: `${type} copied successfully!` });
      });
  };

  return (
    <Card>
      <ActionsWrapper>
        {/* <GoogleWrapper>
          <GoogleText>Add to Google Calendar</GoogleText>
          <Switch
            id={'add-to-google-calendar'}
            isOn={googleCalendar}
            onColor={theme.palette.covideoOrange100}
            handleToggle={() => setGoogleCalendar(!googleCalendar)}
          />
        </GoogleWrapper> */}
        <CopyWrapper>
          {meetingId && (
            <>
              <CopyItem>
                <CopyTitle>Meeting ID</CopyTitle>
                <CopyLinkWrapper>
                  <CopyValue>{meetingId}</CopyValue>
                  <Button
                    text='Copy'
                    onClick={() => {
                      copyToClipboard(meetingId, 'Meeting ID');
                    }}
                    variant='white'
                    size='small'
                  />
                </CopyLinkWrapper>
              </CopyItem>
              <CopyItem>
                <CopyTitle>URL to Join</CopyTitle>
                <CopyLinkWrapper>
                  <CopyValue>{liveLink}</CopyValue>
                  <Button
                    text='Copy'
                    onClick={() => {
                      copyToClipboard(liveLink, 'URL');
                    }}
                    variant='white'
                    size='small'
                  />
                </CopyLinkWrapper>
              </CopyItem>
            </>
          )}
          <CopyItem>
            <CopyTitle>Participants Allowed</CopyTitle>
            {attendeeLimit}
          </CopyItem>
        </CopyWrapper>
      </ActionsWrapper>
    </Card>
  );
};

export default MeetingInfoBanner;
