import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { BackToIntegrations } from './BackToIntegrations';
import { IntegrationHeadline } from './IntegrationHeadline';
import { CheckboxInput } from 'lib/components';
import { Video } from './Video';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { SALESFORCE_HELP_DESK_LINK } from '../main/constants';
import { connectServices } from 'lib/api/services/useServicesConnectQuery';
import { disconnectServices } from 'lib/api/services/useServicesDisconnectQuery';
import { useServicesStatusQuery } from 'lib/api/services/useServicesStatusQuery';
import { useServicesSubcriptionsQuery } from 'lib/api/services/useServicesSubcriptionsQuery';
import { useServicesSubsciptionMutation } from 'lib/api/services/useServicesSubsciptionMutation';
import { useServicesUnsubsciptionMutation } from 'lib/api/services/useServiceUnsubscribeMutation';
import { SERVICE } from 'lib/api/services/types';

type Status = {
  visible: boolean;
};
interface ISubscription {
  eventType: string;
  isEnabled: boolean;
  label?: string;
}
const ShareContainer = styled.div<Status>`
  display: ${props => (props.visible ? 'block' : 'none')};
  margin-top: 24px;
`;
const Label = styled.label`
  font-size: ${theme.fontSizes.m};
  font-weight: ${theme.fontNormal500};
  line-height: 2.29;
  color: ${theme.palette.themeDark};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;

const CheckboxLabel = styled.label`
  padding-left: 12px;
  cursor: pointer;
`;

const Inset = styled.div`
  padding-left: 12px;
`;

const initialEventTypes: ISubscription[] = [
  { eventType: 'email_sent', isEnabled: false, label: 'Emails Sent' },
  { eventType: 'email_opened', isEnabled: false, label: 'Email Opens' },
  { eventType: 'link_click', isEnabled: false, label: 'CTA Click' },
  { eventType: 'video_view', isEnabled: false, label: 'Video Views' },
];

export const SalesforceIntegration = () => {
  const {
    isLoading: loadingStatus,
    data: statusData,
    refetch: getInformationService,
  } = useServicesStatusQuery(SERVICE.SELESFORCE);
  const { mutateAsync: subscribe } = useServicesSubsciptionMutation(
    SERVICE.SELESFORCE
  );
  const { mutateAsync: unsubscribe } = useServicesUnsubsciptionMutation(
    SERVICE.SELESFORCE
  );

  const {
    isLoading: loadingMaps,
    data: serviceMaps,
    refetch: fetchServiceMaps,
  } = useServicesSubcriptionsQuery(SERVICE.SELESFORCE);

  const [allChecked, setAllChecked] = React.useState(false);
  const [eventTypes, setEventTypes] =
    React.useState<Array<ISubscription>>(initialEventTypes);

  const isConnected =
    !!statusData &&
    !statusData.error &&
    statusData.message &&
    statusData.message.authorized;

  React.useEffect(() => {
    if (
      statusData &&
      statusData.error === false &&
      statusData.message &&
      statusData.message.authorized === true
    ) {
      fetchServiceMaps();
    }
  }, [loadingStatus, statusData]);

  const openWindow = (message: string) => {
    let new_window = window.open(message, 'name', 'height=600,width=450');

    if (new_window) {
      new_window.focus();
    }

    let check_opened = setInterval(() => {
      if (new_window && new_window.closed) {
        clearInterval(check_opened);
        getInformationService();
      }
    }, 100);
  };

  React.useEffect(() => {
    let isAllChecked = true;
    for (let i = 0; i < eventTypes.length; i++) {
      isAllChecked = isAllChecked && eventTypes[i].isEnabled;
    }
    setAllChecked(isAllChecked);
  }, [eventTypes]);

  const onSelectAll = () => {
    let tempEventTypes = eventTypes.slice();
    for (let i = 0; i < tempEventTypes.length; i++) {
      if (!tempEventTypes[i].isEnabled) {
        subscribe({ eventType: tempEventTypes[i].eventType });
      }
      tempEventTypes[i].isEnabled = true;
    }
    setEventTypes(tempEventTypes);
  };

  const onChange = (eventType: string, checked: boolean) => {
    let tempEventTypes = eventTypes.slice();
    for (let i = 0; i < tempEventTypes.length; i++) {
      if (eventType === eventTypes[i].eventType) {
        tempEventTypes[i].isEnabled = checked;
        checked ? subscribe({ eventType }) : unsubscribe({ eventType });
      }
    }
    setEventTypes(tempEventTypes);
  };

  React.useEffect(() => {
    if (serviceMaps && serviceMaps.data) {
      let tempEventTypes = eventTypes.slice();
      serviceMaps.data.forEach(item => {
        for (let i = 0; i < tempEventTypes.length; i++) {
          if (item.eventType === eventTypes[i].eventType) {
            tempEventTypes[i].isEnabled = true;
          }
        }
      });
      setEventTypes(tempEventTypes);
    }
  }, [loadingMaps, serviceMaps]);

  return (
    <>
      <BackToIntegrations />
      <IntegrationHeadline
        title='Salesforce'
        description='Sync the data you want with Salesforce'
        imgSrc='https://www.covideo.com/backoffice/images/Salesforce_logo.png'
      >
        <Button
          variant={isConnected ? 'destructive' : 'primary'}
          text={isConnected ? 'Disconnect' : 'Connect'}
          // onClick={() => (isConnected ? disconnectService() : connectService())}
          onClick={async () => {
            if (isConnected) {
              const disconnectData = await disconnectServices(
                SERVICE.SELESFORCE
              );
              if (disconnectData && disconnectData.success) {
                getInformationService();
              }
            } else {
              const connectData = await connectServices(SERVICE.SELESFORCE);
              if (connectData && !connectData.error) {
                openWindow(connectData.message);
              }
            }
          }}
          disabled={loadingStatus}
        />
      </IntegrationHeadline>
      <Video
        thumbnail='https://images.covideo.com/thumbnails/SalesforceIntegration_Thumbnail.png'
        videoSource='https://d2k7766kmw4j3n.cloudfront.net/website/SalesforceIntegration.mp4'
      />
      <Gap m='32px 0 0 0'>
        <div>
          Connecting Covideo with your Salesforce account is easy! Simply use
          the connect button on this page and enter your Salesforce info. Once
          you have enabled this integration, any time you interact with an
          existing lead or contact through Covideo, it will show up in your
          Salesforce Activity History. You can also choose which data from
          Covideo to sync with your Salesforce account.
        </div>
        <div>
          With our native Covideo for Salesforce integration you can record new
          or insert previous videos without leaving Salesforce. This native
          integration is an add-on, so please contact your Covideo account
          manager if you would like to learn more and to enable this
          integration.
        </div>
        <div>
          For more information on how to add and use the Salesforce integration,{' '}
          <a href={SALESFORCE_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
            check out our Help Desk article
          </a>
          .
        </div>
      </Gap>

      <ShareContainer visible={isConnected}>
        <div>
          <Label>Select what to share with Salesforce</Label>
        </div>
        <div>
          <CheckboxWrapper>
            <CheckboxInput
              id='salesforce-all-data'
              checked={allChecked}
              onChange={(e: React.SyntheticEvent) => {
                let { checked } = e.target as HTMLInputElement;
                if (checked) onSelectAll();
              }}
            />
            <CheckboxLabel htmlFor='salesforce-all-data'>
              All Data
            </CheckboxLabel>
          </CheckboxWrapper>
          <Inset>
            {eventTypes.map((item, index) => {
              return (
                <CheckboxWrapper key={index}>
                  <CheckboxInput
                    id={item.eventType}
                    checked={item.isEnabled}
                    onChange={(e: React.SyntheticEvent) => {
                      let { checked } = e.target as HTMLInputElement;
                      onChange(item.eventType, checked);
                    }}
                  />
                  <CheckboxLabel htmlFor={item.eventType}>
                    {item.label}
                  </CheckboxLabel>
                </CheckboxWrapper>
              );
            })}
          </Inset>
        </div>
      </ShareContainer>
    </>
  );
};
