import React from 'react';

import { theme } from 'lib/style';
import styled, { css } from 'styled-components/macro';
import { isAdminSession } from 'lib/utils/auth';
import { useAuth } from 'lib/context';
import {
  MAIN_WRAPPER_MAX_WIDTH,
  MAIN_WRAPPER_MAX_WIDTH_LG,
  MAIN_WRAPPER_MAX_WIDTH_XL,
} from './styles/layout';

interface IWrapper {
  left?: string;
  right?: string;
  top?: string;
}
const Wrapper = styled.div<IWrapper>`
  position: fixed;
  left: ${({ left }) => left || 0};
  right: ${({ right }) => right || 0};
  top: ${({ top }) => top || 0};
  background: ${theme.palette.white};
  height: 40px;
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px ${theme.palette.gray};
`;

interface IContainer {
  fixedWidth?: string;
  padding?: string;
}

const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  width: 100%;
  max-width: ${({ fixedWidth }) => fixedWidth || MAIN_WRAPPER_MAX_WIDTH};
  ${({ fixedWidth }) =>
    !fixedWidth &&
    css`
      @media (min-width: 1280px) {
        max-width: ${MAIN_WRAPPER_MAX_WIDTH_LG};
      }
      @media (min-width: 1640px) {
        max-width: ${MAIN_WRAPPER_MAX_WIDTH_XL};
      }
    `}
`;

type Props = {
  left?: string;
  fixedWidth?: string;
  padding?: string;
  children?: React.ReactNode;
};

export const SelectionHeaderPopup = ({
  left = '0',
  padding = '0 16px',
  fixedWidth,
  children,
}: Props) => {
  const { userData } = useAuth();
  const adminSession = isAdminSession();
  const isTrialAccount =
    userData.trialAccount && !window.location.pathname.includes('/upgrade');
  const movePopupDown = adminSession || isTrialAccount;
  return (
    <Wrapper top={movePopupDown ? '128px' : '63px'} left={left}>
      <Container fixedWidth={fixedWidth} padding={padding}>
        {children}
      </Container>
    </Wrapper>
  );
};
