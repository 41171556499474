import {
  IUsersCustomersResponse,
  useGetUsersCustomerQuery,
} from 'lib/api/users/getUsersCustomers';
import {
  CheckboxInput,
  Dropdown,
  LoadingIndicator,
  Search,
} from 'lib/components';
import { CommonTypography } from 'lib/components/styles/typography';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  CustomReportModalFormikValues,
  IRecipient,
  TYPE_OF_REPORT,
} from './types';
import {
  getAdditionalRecipients,
  getTheAccessRole,
  getUserRecipients,
  RecipientTypeALL,
  RecipientTypeOptions,
} from './const';
import { useFormikContext } from 'formik';

const MenuFilters = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const SelectorTitle = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${theme.palette.neutral80};
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

const SelectAndCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 624px;
  padding-top: 16px;
`;

const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 12px;
`;

const SelectAllText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

const SelectionCountText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

const Counter = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary[100]};
  margin-left: 16px;
`;

const UsersList = styled.div`
  margin-top: 16px;
  height: 312px;
  box-sizing: border-box;
  border-top: 1px solid ${theme.palette.neutral20};
  border-bottom: 1px solid ${theme.palette.neutral20};
  padding: 12px;
  position: relative;
  overflow-y: auto;
  .infinite-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const UserItem = styled.div<{ disabled?: boolean; marginLeft?: string }>`
  display: flex;
  align-items: center;
  padding: 16px 0;
  opacity: 0.3;
  opacity: ${props => (props.disabled ? '0.3' : '1')};
  margin-left: ${props => props.marginLeft || '0px'};
`;

const UserTitle = styled.div<{ width?: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${theme.palette.gray100};
  margin-left: 8px;
  width: ${props => props.width || '272px'};
`;

const UserEmail = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: left;
  color: ${theme.palette.gray60};
  width: 220px;
  text-align: left;
`;

const UserAccess = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: left;
  color: ${theme.palette.gray60};
  width: 100px;
  text-align: left;
`;

const UserDetails = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 624px;
`;

const ListPrivacyWrapper = styled.div`
  display: flex;
  gap: 16px;
  height: 312px;
`;

type Selection = {
  [key: string]: IRecipient;
};

export const UsersSelector = () => {
  const { setFieldValue, values } =
    useFormikContext<CustomReportModalFormikValues>();

  const {
    userData: { customerId, userId },
  } = useAuth();
  const [users, setUsers] = useState<IUsersCustomersResponse[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recipientType, setRecipientType] = useState(RecipientTypeALL);
  const [selectAll, setSelectAll] = React.useState(false);
  const [filteredUsers, setFilteredUsers] = useState<IUsersCustomersResponse[]>(
    []
  );
  const [selectedUsers, setSelectedUsers] = useState<Selection>(
    getUserRecipients(values.receivers)
      .filter((item: IRecipient) => item.id !== userId)
      .reduce((selectedMap: Selection, current: IRecipient) => {
        selectedMap[current.id || ''] = current;
        return selectedMap;
      }, {})
  );

  const { data: usersCustomer, isLoading: loadingUsers } =
    useGetUsersCustomerQuery({ customerId });

  const onSearch = () => {
    const search = searchQuery.toLowerCase();
    const initialFiltered = [...users];

    // filter user type
    const filteredByType =
      recipientType !== RecipientTypeALL
        ? initialFiltered.filter(
            user =>
              !!user.access &&
              parseInt(user.access, 10) === parseInt(recipientType, 10)
          )
        : initialFiltered;

    // filter search
    const finalFiltered = !!searchQuery.length
      ? filteredByType.filter(user =>
          `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(
            search
          )
        )
      : filteredByType;

    setFilteredUsers(finalFiltered);
  };

  React.useEffect(() => {
    onSearch();
  }, [searchQuery, recipientType]);

  React.useEffect(() => {
    if (usersCustomer && usersCustomer.length > 0) {
      const filterUsers = usersCustomer?.sort(
        (userA: IUsersCustomersResponse, userB: IUsersCustomersResponse) => {
          if (userA.firstName < userB.firstName) {
            return -1;
          }
          if (userA.firstName > userB.firstName) {
            return 1;
          }
          return 0;
        }
      );
      // Remove logged user in order to avoid sharing a folder with yourself
      const withoutLoggedUser = filterUsers.filter(
        (item: { id: number }) => item.id !== parseInt(userId)
      );
      setUsers(withoutLoggedUser);
      setFilteredUsers(withoutLoggedUser);
    }
  }, [usersCustomer]);

  const updateReceivers = (selection: Selection) => {
    const userReceivers = Object.values(selection);
    const additionalRecipients = getAdditionalRecipients(values.receivers);
    setSelectedUsers(selection);
    setFieldValue('receivers', [...additionalRecipients, ...userReceivers]);
  };
  const toggleSelectAll = () => {
    const value = !selectAll;
    let selected = {};
    if (value) {
      selected = filteredUsers.reduce(
        (selectedMap: Selection, current: IUsersCustomersResponse) => {
          const userId = current.id.toString();
          selectedMap[userId] = {
            id: userId,
            email: current.email,
          };

          return selectedMap;
        },
        {}
      );
    }
    setSelectAll(value);
    updateReceivers(selected);
  };

  const toggleUser = (userId: string, email: string) => {
    const selected = { ...selectedUsers };
    if (selected[userId]) {
      delete selected[userId];
    } else {
      selected[userId] = {
        id: userId,
        email,
      };
    }
    updateReceivers(selected);
  };

  const toggleFutureUsers = () => {
    setFieldValue(
      'includeNewUsersInRecurring',
      !values.includeNewUsersInRecurring
    );
  };

  if (loadingUsers) {
    return <LoadingIndicator isLoading={loadingUsers} height='300px' />;
  }

  const count = Object.keys(selectedUsers).length;

  /**
   * If there is only one user in the company, we don't show the selector.
   * Make sure to not change the users state. Use filtered users to show the list.
   **/
  if (!users || !users.length) {
    return <></>;
  }
  return (
    <>
      <Menu>
        <SelectorTitle>Select Recipients</SelectorTitle>
        <MenuFilters>
          <Search
            placeholder='Search users...'
            onSearch={(searchTerm: string) => setSearchQuery(searchTerm)}
            bgColor='#fffff'
            width={'163px'}
          />
          <Dropdown
            height={40}
            creatable={false}
            className='dropdown'
            placeholder={'All users'}
            value={RecipientTypeOptions.find(
              option => option.value === recipientType
            )}
            onChange={value => setRecipientType(value.value)}
            options={RecipientTypeOptions}
            zIndexProp={1}
            extendStyles={{
              menu: {
                width: '200px',
              },
              menuList: {
                width: '200px',
              },
            }}
          />
        </MenuFilters>
      </Menu>
      <SelectAndCount>
        <SelectAllWrapper>
          <CheckboxInput
            disabled={false}
            checked={selectAll}
            onChange={toggleSelectAll}
          />
          <SelectAllText>Select all</SelectAllText>
          {values.typeOfReport === TYPE_OF_REPORT.RECURRING && (
            <>
              <CheckboxInput
                disabled={false}
                checked={values.includeNewUsersInRecurring}
                onChange={toggleFutureUsers}
              />
              <SelectAllText>Include New Users</SelectAllText>
            </>
          )}
        </SelectAllWrapper>
        <SelectionCountText>
          <Counter>{count} selected</Counter>
        </SelectionCountText>
      </SelectAndCount>
      <ListPrivacyWrapper>
        <ListWrapper>
          <UsersList>
            {filteredUsers.map((user: IUsersCustomersResponse) => (
              <UserItem key={user.id} disabled={false}>
                <CheckboxInput
                  checked={!!selectedUsers[user.id]}
                  onChange={() => {
                    toggleUser(user.id.toString(), user.email);
                  }}
                  disabled={false}
                />
                <UserDetails>
                  <UserTitle
                    width='220px'
                    onClick={() => {
                      toggleUser(user.id.toString(), user.email);
                    }}
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </UserTitle>
                  <UserEmail>{user.email}</UserEmail>
                  <UserAccess>
                    {getTheAccessRole(user.access?.toString())}
                  </UserAccess>
                </UserDetails>
              </UserItem>
            ))}
          </UsersList>
        </ListWrapper>
      </ListPrivacyWrapper>
    </>
  );
};
