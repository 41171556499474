import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const Section = styled.div`
  display: flex;
  max-width: 770px;
  border-bottom: 1px solid rgb(238, 239, 242);
  padding: 24px 0;
`;
export const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  line-height: 24px;
`;

export const UploadBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 8px;
  gap: 4px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: rgba(39, 42, 50, 1);
  background-color: rgba(238, 239, 242, 1);
  position: relative;
  border-radius: 5px;
`;

export const UploadInput = styled.input`
  opacity: 0;
  position: absolute;
  top: -5px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  font-size: 0;
`;

export const Label = styled.label`
  display: flex;
  height: 20px;
  ${theme.fontNormal500}
  line-height: 1.43;
  color: ${theme.palette.label};
  margin-bottom: 8px;
  font-size: 15px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CheckboxLabel = styled.label`
  margin: 0 0 0 12px;

  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgb(39, 42, 50);
`;

export const PreviewBtnText = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-pack: center;
  justify-content: center;
  font-family: 'Work Sans', sans-serif;
  padding: 0px 12px;
  font-size: 15px;
  color: inherit;
`;
