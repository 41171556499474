import React, { ChangeEvent } from 'react';
import { useField } from 'formik';
import { CustomSwitchField } from '../form/switch/types';
import { BaseSwitch } from '../form/switch/base';

export const FormikSwitchField = ({
  name,
  wrapper,
  onChange,
  ...props
}: CustomSwitchField) => {
  const [field, _, helpers] = useField({ name, type: 'checkbox' });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) {
      return;
    }
    if (onChange) {
      return onChange(e);
    }
    helpers.setValue(!field.value);
  };

  return (
    <BaseSwitch
      {...field}
      {...props}
      wrapper={wrapper}
      onChange={handleChange}
    />
  );
};
