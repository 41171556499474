import React from 'react';
import { Field } from 'lib/components/modal';
import { Activity, ICombinedUserData } from 'lib/context';
import {
  Badge,
  BadgeVariant,
  EllipsisDiv,
  Recipient,
  TableCell,
} from './styles';
import { HoverElement } from 'app/pages/admin/index.styled';
import { EmailStatus } from 'lib/const/EmailReceivers';
import { HoverPopup } from 'lib/components';
import { VideoData } from 'lib/hooks';
import { CustomerHover } from 'app/pages/repairOrders/components';
import { copyField, getDisplayName } from 'lib/utils/functions';

import { parseDeliveryUnixTimestampToUserTimezone } from 'app/pages/account/userAccount/schedule/utils';

export enum ActivityTableColumns {
  DATE = 'dateSentUnix',
  RECIPIENT = 'recipientName',
  METHOD = 'method',
  VIDEO = 'videoTitle',
  STATUS = 'emailStatus',
  VIEWS = 'viewCount',
  SMS = 'phone',
  EMAIL = 'email',
  USER = 'user',
}

export enum ActivityFeedSortKey {
  DATE_SENT = 'DATE_SENT',
  USER_NAME = 'USERNAME',
  VIDEO_TITLE = 'VIDEO_TITLE',
}

export const baseFields = [
  {
    label: 'Date Sent',
    value: ActivityTableColumns.DATE,
    sortable: true,
    sortKey: ActivityFeedSortKey.DATE_SENT,
  },
  {
    label: `Recipient's Name`,
    value: ActivityTableColumns.RECIPIENT,
  },
  {
    label: 'Method',
    value: ActivityTableColumns.METHOD,
  },
  {
    label: 'Video Title',
    value: ActivityTableColumns.VIDEO,
    sortable: true,
    sortKey: ActivityFeedSortKey.VIDEO_TITLE,
  },
  {
    label: 'Status',
    value: ActivityTableColumns.STATUS,
  },
  {
    label: 'Views',
    value: ActivityTableColumns.VIEWS,
  },
];

export const filterTableFields = (fields: Field[]) =>
  fields.filter(item => !!item.label);

export const prepareCsvData = (
  activities: Activity[],
  userData: ICombinedUserData
) => {
  return activities.map(item => {
    return {
      [ActivityTableColumns.DATE]: ` ${parseDeliveryUnixTimestampToUserTimezone(
        item?.dateSentUnix || 0,
        userData
      )}`,
      ...(userData.isCompanyAdmin
        ? {
            [ActivityTableColumns.USER]: `${item.user?.firstName} ${item.user?.lastName}`,
          }
        : {}),
      [ActivityTableColumns.RECIPIENT]: getDisplayName([
        item.recipient.firstName,
        item.recipient.lastName,
      ]),
      [ActivityTableColumns.VIDEO]: item.videoTitle || item.video.title,
      [ActivityTableColumns.METHOD]: item.method,
      [ActivityTableColumns.STATUS]:
        item.emailStatus.charAt(0).toUpperCase() + item.emailStatus.slice(1),
      [ActivityTableColumns.VIEWS]: item.viewCount,
      [ActivityTableColumns.EMAIL]: item.recipient.email,
      [ActivityTableColumns.SMS]: item.recipient.phone,
    };
  });
};

export const formatColumns = (
  activity: Activity,
  tableFields: Field[],
  userData: ICombinedUserData,
  setShowVideoPreview: (video: VideoData) => void,
  allTableFields: Field[]
) => {
  return tableFields.map(item => {
    const cellValue = activity[item.value as keyof Activity];

    switch (item.value) {
      case ActivityTableColumns.DATE:
        const value = parseDeliveryUnixTimestampToUserTimezone(
          cellValue as number,
          userData
        );
        return <TableCell>{value}</TableCell>;
      case ActivityTableColumns.RECIPIENT:
        const hasContact =
          !!activity.recipient.phone || !!activity.recipient.email;

        const recipientName =
          getDisplayName([
            activity.recipient.firstName,
            activity.recipient.lastName,
          ]) ||
          activity.recipient.email ||
          activity.recipient.phone ||
          '-';

        return (
          <TableCell>
            {hasContact ? (
              <HoverPopup
                popup={
                  <CustomerHover
                    phone={activity.recipient.phone}
                    email={activity.recipient.email}
                    maxWidth='220px'
                    onClick={(value: string, type: string) =>
                      copyField(value, type)
                    }
                  />
                }
                position='top'
                hoverable={<Recipient>{recipientName}</Recipient>}
              />
            ) : (
              <Recipient>{recipientName}</Recipient>
            )}
          </TableCell>
        );
      case ActivityTableColumns.VIDEO:
        const title = activity.videoTitle || activity.video.title; // new activity will always have videoTitle, but older may not
        const videoExists = !!activity.video.id;

        const hidePopup =
          title.length < 20 || tableFields.length < allTableFields.length;
        return (
          <HoverElement style={{ width: '250px' }} top='-35px' center>
            {!hidePopup && <div className='hover-content'>{title}</div>}

            <TableCell
              style={{ ...(videoExists && { cursor: 'pointer' }) }}
              onClick={() => videoExists && setShowVideoPreview(activity.video)}
            >
              <EllipsisDiv width='250px'>{title || '-'}</EllipsisDiv>
            </TableCell>
          </HoverElement>
        );
      case ActivityTableColumns.METHOD:
        return (
          <TableCell justifyContent='center'>
            <Badge style={{ marginRight: '5px' }} variant={BadgeVariant.BASE}>
              {activity.method.charAt(0).toUpperCase() +
                activity.method.slice(1)}
            </Badge>
          </TableCell>
        );
      case ActivityTableColumns.STATUS:
        const status = cellValue?.toString().toLowerCase();
        let badgeVariant = BadgeVariant.BASE;

        if (
          status === EmailStatus.BOUNCE ||
          status === EmailStatus.SPAM ||
          status === EmailStatus.DROPPED ||
          status === EmailStatus.DEFFERED
        )
          badgeVariant = BadgeVariant.DANGER;
        if (status === EmailStatus.OPEN || status === EmailStatus.VIEWED)
          badgeVariant = BadgeVariant.SUCCESS;

        let displayValue =
          activity.emailStatus.charAt(0).toUpperCase() +
          activity.emailStatus.slice(1);

        if (status === EmailStatus.OPEN && activity.viewCount === 1) {
          displayValue =
            EmailStatus.VIEWED.charAt(0).toUpperCase() +
            EmailStatus.VIEWED.slice(1);
        }
        if (status === EmailStatus.SPAM) displayValue = 'Spam';

        return (
          <TableCell justifyContent='center'>
            {!!status ? (
              <Badge variant={badgeVariant}>{displayValue}</Badge>
            ) : (
              '-'
            )}
          </TableCell>
        );
      case ActivityTableColumns.VIEWS:
        return (
          <TableCell justifyContent='right' width='50px'>
            {activity.viewCount}
          </TableCell>
        );
      case ActivityTableColumns.USER:
        return (
          <TableCell>
            <HoverElement top='-35px' center style={{ padding: 0 }}>
              <div className='hover-content'>
                {activity.user?.firstName} {activity.user?.lastName}
              </div>
              <Recipient>
                {activity.user?.firstName || '-'}{' '}
                {activity.user?.lastName || '-'}
              </Recipient>
            </HoverElement>
          </TableCell>
        );
      default:
        return <TableCell>{cellValue}</TableCell>;
    }
  });
};
