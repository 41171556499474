import * as React from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { theme } from 'lib/style';
import { CheckboxInput, Dropdown, NewModal } from 'lib/components';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import {
  DefaultFolderIds,
  SHARED_FOLDER_PREFIX,
  ALL_FOLDERS,
} from 'lib/const/Folders';
import { FOLDER_ACCESS } from 'app/pages/library/videos/videoList/constants';
import { createFolder } from 'lib/api';
import { useVideoMoveCopy } from 'lib/api/videos/useVideoMoveCopy';
import { Folder } from 'lib/api/folders/getFolders';
import { useVideosMoveCopyMultipleMutation } from 'lib/api/videos/useVideoMoveCopyMultiple';
import { useIsMutating } from 'react-query';

type CheckboxLabelProps = {
  disabled?: boolean;
};

const ModalItem = styled.div`
  display: block;
  position: relative;
  width: 100%;
  background-color: #fff;
`;

const Content = styled.div`
  padding-bottom: 32px;
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const CheckboxLabel = styled.label<CheckboxLabelProps>`
  padding-left: 12px;
  cursor: ${props => (props.disabled ? 'normal' : 'pointer')};
`;

const Title = styled.div`
  height: 24px;
  opacity: 0.75;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.4px;
  color: #343a40;
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const ModalMoveCopy = withRouter((props: any) => {
  const {
    disabled = false,
    text = '',
    handleModalClose,
    videoId,
    foldersData = [],
    currentFolder,
    setNewFolder,
    userData,
  } = props;

  const isMutating = useIsMutating();
  const loading = isMutating > 0;

  const [isCopyVideoSelected, setIsCopyVideoSelected] = React.useState(false);
  const [selectedFolder, setSelectedFolder] = React.useState({
    value: foldersData[0]?.folderId || '',
    label: foldersData[0]?.name || '',
    __isNew__: false,
  });
  const [folderOptions, setFolderOptions] = React.useState([]);

  React.useEffect(() => {
    setFolderOptions(
      foldersData
        .filter((f: Folder) => f.folderId !== ALL_FOLDERS.value)
        .filter((f: Folder) => f.name.toLowerCase() !== 'video replies')
        .map((f: Folder) => ({
          value: f.folderId,
          label: f.name,
        }))
    );
  }, []);

  const {
    userData: { resellerId },
  } = useAuth();

  const walkaroundSocialFolder = ['204'];

  const { mutateAsync } = useVideoMoveCopy();

  const { mutateAsync: mutateMultiple } = useVideosMoveCopyMultipleMutation();

  const checkIfUserHasSelectedFolderEditAccess = () => {
    const folder = foldersData.find(
      (folder: Folder) => folder.folderId === selectedFolder.value
    );
    return !!folder?.access && folder?.access === FOLDER_ACCESS.EDIT;
  };

  const handleChange = (e: React.SyntheticEvent) => {
    let { checked } = e.target as HTMLInputElement;
    setIsCopyVideoSelected(checked);
  };

  const handleSubmit = async () => {
    const obj = {
      videoId,
      copy: isCopyVideoSelected,
      videoFolder: checkIfUserHasSelectedFolderEditAccess()
        ? `${SHARED_FOLDER_PREFIX}${selectedFolder.value}`
        : selectedFolder.label,
      successMessage: `Video ${
        isCopyVideoSelected ? 'copied' : 'moved'
      } successfully.`,
    };
    try {
      let idsTemp = videoId.toString().split(',');
      let ids: number[] = [];
      let newFolder;
      if (!!selectedFolder.__isNew__) {
        newFolder = await createFolder({
          name: selectedFolder.label,
          userId: userData.id,
        });
      }
      if (idsTemp.length > 1) {
        for (let i = 0; i < idsTemp.length; i++) {
          const id = parseInt(idsTemp[i]);
          if (!isNaN(id)) ids.push(id);
        }
        await mutateMultiple({
          ...obj,
          ids,
          successMessage: `Videos ${
            isCopyVideoSelected ? 'copied' : 'moved'
          } successfully.`,
        });
      } else {
        await mutateAsync(obj);
      }

      setNewFolder &&
        setNewFolder?.(
          !selectedFolder.__isNew__ ? selectedFolder.value : newFolder?.folderId
        );
      handleModalClose();
      setIsCopyVideoSelected(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  React.useEffect(() => {
    if (
      selectedFolder.label === 'Company' ||
      currentFolder.folderId === DefaultFolderIds.COMPANY
    ) {
      setIsCopyVideoSelected(true);
    }
  }, [selectedFolder]);

  const getKeepCopyCondition = () => {
    if (selectedFolder.label === 'Company') {
      return { disabled: true, keepCopy: true };
    }

    if (currentFolder.folderId === DefaultFolderIds.COMPANY) {
      return { disabled: true, keepCopy: true };
    }

    if (selectedFolder.label === 'Vault' || currentFolder?.name === 'Vault') {
      return { disabled: true, keepCopy: false };
    }
    return { disabled: false, keepCopy: isCopyVideoSelected };
  };
  return (
    <NewModal
      hideHeader={true}
      closeModalOnBackdropOrEscPress={false}
      closeModal={() => {
        handleModalClose();
        setIsCopyVideoSelected(false);
      }}
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          maxWidth: '352px',
          minWidth: 'auto',
        },
      }}
    >
      <ModalItem>
        <Content>
          <ContentBody>
            <Title>Select Folder:</Title>
            <Dropdown
              placeholder='No Folders'
              value={folderOptions.find(
                (option: { value: string }) =>
                  option.value === selectedFolder.value
              )}
              disabled={loading}
              onChange={(selectedValue: any) => {
                if (selectedValue !== null) {
                  if (
                    selectedValue.value === 'Walkaround Social' &&
                    walkaroundSocialFolder.includes(resellerId)
                  ) {
                    setIsCopyVideoSelected(true);
                  } else {
                    setIsCopyVideoSelected(false);
                  }
                  setSelectedFolder(selectedValue);
                }
              }}
              options={folderOptions}
              dropdownHeight={120}
            />
            <CheckboxWrapper>
              <CheckboxInput
                checked={getKeepCopyCondition().keepCopy}
                onChange={handleChange}
                disabled={getKeepCopyCondition().disabled || loading}
                name={'keepACopyCheckbox'}
              />
              <CheckboxLabel
                disabled={getKeepCopyCondition().disabled || loading}
                htmlFor='keepACopyCheckbox'
              >
                {text}
              </CheckboxLabel>
            </CheckboxWrapper>
          </ContentBody>
        </Content>
      </ModalItem>
      <ButtonContainer>
        <Button
          text='Cancel'
          variant='secondary'
          disabled={disabled || loading}
          onClick={() => {
            handleModalClose();
            setIsCopyVideoSelected(false);
          }}
        />
        <Button
          variant='primary'
          text={getKeepCopyCondition().keepCopy ? 'Copy Video' : 'Move Video'}
          disabled={disabled || loading}
          onClick={handleSubmit}
        />
      </ButtonContainer>
    </NewModal>
  );
});
