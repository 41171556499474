import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import {
  LoadingIndicator,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
} from 'lib/components';
import { useHistory } from 'react-router';
import { useAuth, InventoryItem } from 'lib/context';
import dayjs from 'dayjs';
import { AutomotiveRole } from 'lib/const';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';
import { TableFieldVideosRecorded } from '.';

type TableCellProps = {
  justifyContent?: string;
  fontWeight?: number;
  color?: string;
};
const TableCell = styled.div<TableCellProps>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  color: ${({ color }) => color || theme.palette.gray80};
`;
const SortCell = styled.div`
  cursor: pointer;
  padding-top: 2px;
  padding-left: 10px;
  .active {
    fill: black;
  }
`;

type ImageProps = {
  bg: string;
  sold: boolean;
};
const Image = styled.div<ImageProps>`
  margin: 16px 0;
  background-color: ${theme.palette.black};
  ${({ bg }) =>
    bg &&
    css`
      background-image: url(${bg});
    `}
  width:120px;
  height: 64px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  ${({ sold }) =>
    sold &&
    css`
      &:after {
        content: 'SOLD';
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        width: 100%;
        background: rgba(19, 34, 66, 0.6);
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 1px;
        color: ${theme.palette.white};
      }
    `}
`;

type Props = {
  inventoryItemsArray: InventoryItem[];
  loading: boolean;
  count: number;
  page: number;
  size: number;
  sort: string;
  sortElement: (sortParam: any) => void;
  onPaginationChange: ({ page, size }: { page: number; size: number }) => void;
};
export const TableListing = ({
  inventoryItemsArray,
  loading,
  count,
  onPaginationChange,
  page,
  size,
  sortElement,
  sort,
}: Props) => {
  const { userData } = useAuth();
  const isSalesManager =
    userData.automotiveRole === AutomotiveRole.SALES_MANAGER;
  const history = useHistory();
  const goToDetails = (itemId: string) => history.push(`/inventory/${itemId}`);

  let columnWidths = isSalesManager
    ? [144, 312, 112, 256, 164, 144]
    : [144, 336, 176, 226, 212];

  const tableFields = [
    {
      value: 'image',
      label: '',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: false,
    },
    {
      value: 'title',
      label: 'Vehicle',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: true,
      fontWeight: 600,
      color: theme.palette.gray100,
    },
    {
      value: 'stockNumber',
      label: 'Stock #',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: true,
    },
    {
      value: 'vin',
      label: 'VIN',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: true,
    },
    {
      value: 'createdAt',
      label: 'Date Added',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: true,
    },
    {
      value: 'details',
      label: '',
      headerAlign: 'center',
      valueAlign: 'flex-end',
      sortable: false,
    },
  ];

  if (isSalesManager) {
    tableFields.splice(5, 0, {
      value: 'videosRecorded',
      label: 'Videos Recorded',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: false,
    });
  }

  return (
    <>
      {inventoryItemsArray.length && !loading ? (
        <TableContextProvider
          total={count}
          onChange={onPaginationChange}
          initPage={page}
          initSize={size}
        >
          <Table
            columnWidths={columnWidths}
            compact={true}
            relative={true}
            headers={[
              ...tableFields.map(field => {
                return (
                  <TableCell
                    onClick={() =>
                      field.sortable ? sortElement(field.value) : null
                    }
                    justifyContent={field.headerAlign}
                    color={theme.palette.gray60}
                  >
                    {field.label}
                    {field.sortable ? (
                      <SortCell>
                        <HiArrowNarrowUp
                          color={
                            sort == `${field.value}`
                              ? theme.palette.covideoBlue100
                              : theme.palette.blue20
                          }
                          width={8}
                          height={12}
                        />
                        <HiArrowNarrowDown
                          color={
                            sort == `-${field.value}`
                              ? theme.palette.covideoBlue100
                              : theme.palette.blue20
                          }
                          width={8}
                          height={12}
                        />
                      </SortCell>
                    ) : null}
                  </TableCell>
                );
              }),
            ]}
            hoverable={false}
            fixColumnIndex={[0]}
            rows={inventoryItemsArray.map(
              (inventoryItem: InventoryItem, index: number) => ({
                key: index,
                columns: [
                  ...tableFields.map(field => {
                    return (
                      <TableCell
                        justifyContent={field.valueAlign}
                        color={field.color}
                        fontWeight={field.fontWeight}
                      >
                        {field.value === 'image' ? (
                          <Image
                            sold={inventoryItem.sold}
                            bg={inventoryItem?.media?.[0]?.mediaSource || ''}
                          />
                        ) : field.value === 'videosRecorded' ? (
                          <TableFieldVideosRecorded
                            inventoryItem={inventoryItem}
                          />
                        ) : field.value === 'createdAt' ? (
                          <>
                            {dayjs(inventoryItem.createdAt).format(
                              'MMM D, YYYY'
                            )}
                          </>
                        ) : field.value === 'details' ? (
                          <Button
                            onClick={() => goToDetails(inventoryItem.itemId)}
                            variant='secondary'
                            text='Details'
                          />
                        ) : (
                          <>
                            {inventoryItem[field.value as keyof InventoryItem]}
                          </>
                        )}
                      </TableCell>
                    );
                  }),
                ],
                onClick: () => {},
              })
            )}
          />
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show inventory items:' />
          </TableFooter>
        </TableContextProvider>
      ) : !inventoryItemsArray.length && !loading ? (
        <p>No inventory items to show. </p>
      ) : (
        <LoadingIndicator isLoading={loading} height='500px' />
      )}
    </>
  );
};
