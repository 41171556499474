import { EXPRESS_API } from 'configs/api/expressApi';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';
import { whiteLabelKeys } from './queryKeys';
import { createLinksInHeadTagLinks } from './utils';

export type Reseller = {
  resellerId: number;
  resellerName: string;
  url: string;
  email: string;
  phoneNumber: string;
  customerId: number;
  playbackUrl: string;
  integration: number;
  fullReportAccess: number;
  easyCare: number;
  dealer: number;
};

export enum WHITELABEL_NAME {
  COVIDEO = 'Covideo',
  APP_2_VID = 'App2Vid',
  PRO_VIDEO_NOW = 'ProVideoNOW',
  KENNECTED = 'Kennected Video',
  CDK = 'CDK Global',
}

export type IWhiteLabel = {
  chromeExtensionSSODomain?: string;
  contentPath: string; // "/backoffice/whitelabel/app2vid"
  domainV2: string; //"members.app2vid.com"
  domainV3?: string; // ""
  homepage: string; //"https://app2vid.com"
  intercomId?: string; //""
  name: WHITELABEL_NAME; // "App2Vid"
  outlookAddinSSODomain?: string; //""
  resellerId: string; // 243
  rootDomain: string; // "app2vid.com"
  signupUrl?: string; //""
  supportEmail?: string; //""
  supportPhone?: string; // ""
  supportURL?: string; // ""
  whitelabelId: string; // 2
  androidURL: string; // 2
  iOSURL: string; // 2
  outlookURL: string; // 2
  officeURL: string; // 2
  browserExtensionURL: string; // 2
  intercomKey: string; // 2
  notificationAddress: string; // 2
  fromAddress: string; // 2
  reseller: Reseller;
  codeCDN?: string;
  liveURL?: string;
  landingPageURL?: string;
};

const getWhiteLabel = async (): Promise<IWhiteLabel> => {
  let origin = window.location.hostname;
  if (
    origin.includes('app-sandbox') ||
    origin.includes('app-sustaining') ||
    origin.includes('localhost')
  ) {
    origin = 'app-staging.covideo.com';
  } else if (origin.includes('app-staging')) {
    origin = 'app.covideo.com';
  }

  return (
    await EXPRESS_API.get(`whitelabel?domain=${origin}`, {
      headers: {
        Authorization: 'dc273c7a-4af4-46e3-aa69-c3db1e3f2f06',
      },
    })
  )?.data as IWhiteLabel;
};

export const useGetWhiteLabelQuery = () => {
  const [_, setCookie] = useCookies();

  return useQuery(whiteLabelKeys.white_label(), getWhiteLabel, {
    onSuccess: whitelabel => {
      createLinksInHeadTagLinks(whitelabel);
      //SET USEBETA COOKIE
      let cDate = new Date();
      cDate.setFullYear(cDate.getFullYear() + 10);
      setCookie('usebeta', 1, {
        path: '/',
        domain: whitelabel.rootDomain
          ? `.${whitelabel.rootDomain}`
          : '.covideo.com',
        expires: cDate,
        secure: true,
        sameSite: 'lax',
      });
      localStorage.setItem(
        'api',
        `https://${whitelabel.domainV2}/api/v2.0` || ''
      );
    },
  });
};
