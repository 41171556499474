import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { adminKeys } from './adminKeys';

export interface IAdminCustomersUsersResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  email2: string | null;
  username: string;
  access: number;
  phone1: string;
  Dept: string;
  DeptId: number;
}

export const getCustomerUsers = async (
  customer?: string
): Promise<IAdminCustomersUsersResponse[]> => {
  const response = await EXPRESS_API.get('/users', {
    params: { ...(customer ? { customer } : {}) },
  });
  return response.data;
};

export const useAdminUsersQuery = ({ customer }: { customer?: string }) => {
  const { showError } = useToastError();
  return useQuery(adminKeys.users(customer), () => getCustomerUsers(customer), {
    onError: error => showError(error || 'Error in fetching cards.'),
  });
};
