import styled from 'styled-components/macro';
type Props = {
  width?: string;
  maxWidth?: string;
};

export const Ellipsis = styled.div<Props>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`;
