import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { groupKeys } from './groupKeys';
import { GroupListItem } from '../types';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface QueryParams {
  search?: string;
  size?: number;
  sort?: string;
  page?: number;
}

interface Groups {
  items: GroupListItem[];
  count: number;
}

const getGroups = async ({
  search = '',
  size = 10,
  page = 0,
  sort = '',
}: QueryParams): Promise<Groups> => {
  const start = page * size;
  const params = {
    limit: size,
    search: search,
    sort: sort,
    start,
  };
  const response = await EXPRESS_API.get(`/groups`, { params });

  const initialData: Groups = {
    items: [],
    count: 0,
  };

  let groups: Groups = {
    items: (response.data && response.data.groups) || initialData.items,
    count: (response.data && response.data.count) || initialData.count,
  };

  return groups;
};

export const useQueryGroups = (
  params: QueryParams,
  enabled: boolean = true
) => {
  const { showError } = useToastError();
  return useQuery(groupKeys.groups(params), () => getGroups(params), {
    onError: showError,
    refetchOnMount: true,
    enabled,
  });
};
