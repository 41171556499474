import { NewModal, TextInput } from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { theme } from 'lib/style';
import { FieldAttributes, Field, Formik, Form, FormikValues } from 'formik';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Button } from 'react-covideo-common';

type InputFieldProps = FieldAttributes<{ name: string }>;

const InputField = (props: InputFieldProps) => {
  return <Field {...props} />;
};

interface IProps {
  closeModalHandler: () => void;
  handleSubmit: (title: string) => void;
  titleInitialValue: string;
  isSaveDisabled?: boolean;
}

const renameModalSchema = Yup.object({
  title: Yup.string().required(),
});

const Label = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${theme.palette.gray80};
`;

export const SaveTeleprompterScriptModal = ({
  closeModalHandler,
  titleInitialValue,
  handleSubmit,
  isSaveDisabled,
}: IProps) => {
  const onSubmitHandler = (values: FormikValues) => {
    handleSubmit(values.title);
  };
  return (
    <NewModal
      headerText='Save Teleprompter Script'
      closeModal={closeModalHandler}
    >
      <Formik
        initialValues={{ title: titleInitialValue }}
        onSubmit={onSubmitHandler}
        validationSchema={renameModalSchema}
        initialErrors={{ title: 'Title is required' }}
      >
        {({ handleSubmit, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Gap>
                <Label htmlFor='title'>Script Title</Label>

                <InputField
                  width='100%'
                  placeholder=''
                  name={'title'}
                  as={TextInput}
                  type='text'
                />
              </Gap>
              <Gap
                m='32px 0 0 0'
                alignItems='center'
                justifyContent='flex-end'
                gap='12px'
              >
                <Button
                  text='Cancel'
                  variant='secondary'
                  onClick={() => closeModalHandler()}
                />
                <Button
                  text='Save'
                  disabled={!values.title || isSaveDisabled}
                  type='submit'
                />
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
