import { LeadOpportunitiesParams } from './useLeadOpportunutiesQuery';
import { LeadOpportunitiesStatsParams } from './useLeadOpportunutiesStatsQuery';

export const leadOpportunitiesKeys = {
  all: () => ['lead-opportunities'] as const,
  list: (params?: LeadOpportunitiesParams) =>
    ['lead-opportunities list', { ...params }] as const,
  filters: () => ['lead-opportunities filters'] as const, // list of items with specific filters,
  stats: (params?: LeadOpportunitiesStatsParams) =>
    ['lead-opportunities stats', { ...params }] as const, // list of items with specific filters,
};
