import { QuoteItem } from 'lib/api/repairOrders/types';

export type SendData = {
  videoId: string;
  repairOrderNumber: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  emailSubject?: string;
  emailText?: string;
  smsText?: string;
  sendEmail: boolean;
  sendSms: boolean;
  sendQuote: boolean;
  sendVehicle: boolean;
  quoteItems?: QuoteItem[];
  vin?: string;
  user?: any;
  advisor?: any;
  status?: string;
  multipleRecipients?: Recipient[];
  expirationDate: Date | null;
};

export enum LandingPageType {
  VIDMAILS = 'vidmails',
  AUTOMOTIVE = 'automotive',
}

export const SALES_PAGE_ID = -2;

export type LandingPageOption = {
  value: string | number;
  label: string;
  typeId?: number;
  landingPageType: LandingPageType;
};

export type Option = {
  value: string;
  label: string;
};

export enum RecipientTypeEnum {
  EMAIL = 'Email',
  SMS = 'SMS',
  CONTACT = 'Contact',
}

export enum MultipleRecipientType {
  CREATE_NEW,
  CONTACT,
  LEAD,
  GROUP,
}

export type Recipient = {
  id: string | number;
  type: MultipleRecipientType | RecipientTypeEnum;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  multipleRecipients?: Recipient[];
};

export type AdditionalRecipient = {
  id: string | number;
  type: MultipleRecipientType | RecipientTypeEnum;
  label?: string;
  firstName?: string;
};

export type Tab = {
  value: string;
  isHidden: boolean;
  isLocked: boolean;
  isLive: boolean;
  isDisabled: boolean;
};

export type Tabs = {
  [id: string]: Tab;
};

export enum EmailBuilderElementType {
  TEXT,
  IMAGE,
  IMAGE_EMPTY,
  SIGNATURE,
  VIDEO_URL,
  VIDEO_THUMB,
  HTML,
}

export type EmailBuilderElement = {
  type: EmailBuilderElementType;
  html: string;
  id: string;
  sizeOption?: string;
  thumbnailOption?: string;
  alignmentOption?: string;
  isBorderRounded?: boolean;
  customImageUrl?: string;
  maxWidth?: number;
  isPortrait?: boolean;
};

export type EmailContent = {
  subject: string;
  body: string;
  elements?: EmailBuilderElement[];
  signature?: EmailBuilderElement;
};
