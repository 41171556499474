import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import { NewModal } from 'lib/components';
import { MultipleDropdownWithCheckbox } from '../MultipleDropdownWithCheckbox';
import { Filter } from 'lib/api/leadOpportunities/useLeadOpportunutiesFiltersQuery';
import { Flex } from 'lib/components/styles/layout';

type Props = {
  handleModalClose: () => void;
  onSubmitFiltersHandler: (values: {
    [key: string]: {
      value: string;
      label: string;
    }[];
  }) => void;
  filters: Filter[] | undefined;
  defaultValues: {
    [key: string]: {
      value: string;
      label: string;
    }[];
  };
};

export const ModalFilterLeads = ({
  handleModalClose,
  filters,
  onSubmitFiltersHandler,
  defaultValues,
}: Props) => {
  const [selectedValuesByDropdown, setSelectedValuesByDropdown] = useState<{
    [key: string]: { value: string; label: string }[];
  }>(defaultValues);

  const handleDropdownChange = (
    key: string,
    selectedOption: { value: string; label: string }[],
    options: {
      value: string;
      label: string;
    }[]
  ) => {
    setSelectedValuesByDropdown(prevState => {
      if (selectedOption?.[0]?.value === '') {
        return { ...prevState, [key]: options };
      }
      return { ...prevState, [key]: selectedOption };
    });
  };
  return (
    <NewModal
      headerText={'Filter Leads'}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      headerMargin='0 0 16px 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          maxWidth: '420px',
          minWidth: '420px',
        },
      }}
    >
      <Flex gap='12px'>
        <Flex gap='12px' margin='0 0 16px 0'>
          {filters?.map(item => {
            const options = item.options.filter(item => item.value !== '');
            const headerOption = item.options.find(item => item.value === '');

            return (
              <>
                <MultipleDropdownWithCheckbox
                  key={item.key}
                  onChange={value =>
                    handleDropdownChange(item.key, value, options)
                  }
                  placeholder={item.name}
                  value={selectedValuesByDropdown[item.key]}
                  options={options}
                  headerOption={headerOption}
                  label={item.name}
                  menuShouldBlockScroll={false}
                />
              </>
            );
          })}
        </Flex>
        <Flex
          gap='12px'
          flexDirection='row'
          justifyContent='flex-end'
          width='100%'
        >
          <Button
            variant='secondary'
            text={'Clear all'}
            onClick={() => {
              onSubmitFiltersHandler({});
              handleModalClose();
            }}
          />
          <Button
            text={'Apply Filters'}
            onClick={() => {
              onSubmitFiltersHandler(selectedValuesByDropdown);
              handleModalClose();
            }}
          />
        </Flex>
      </Flex>
    </NewModal>
  );
};
