import { FieldMetaProps } from 'formik';
import { ParagraphExtraSmall } from 'lib/components/styles/typography';
import React, { CSSProperties } from 'react';
import { useCovideoTheme } from 'react-covideo-common';
interface IProps {
  errorWrapper?: CSSProperties | undefined;
}

export const FormikErrorMessage = ({
  meta,
  extendStyles,
}: {
  meta: FieldMetaProps<any>;
  extendStyles?: IProps;
}) => {
  const themes = useCovideoTheme();
  return (
    <div style={{ height: 16, ...extendStyles?.errorWrapper }}>
      {meta.error && meta.touched ? (
        <ParagraphExtraSmall color={themes.colors.danger[80]}>
          {meta.error}
        </ParagraphExtraSmall>
      ) : null}
    </div>
  );
};
