import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useTableContext } from 'lib/components';
import { MdChevronRight, MdChevronLeft, MdArrowDropUp } from 'react-icons/md';

type Props = {
  disabled?: boolean;
};

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationText = styled.span`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4e5461;
  margin-right: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
  padding: 8px;
`;

const PageButton = styled.button<{
  disabled?: boolean;
  isFullWidth?: boolean;
}>`
  ${theme.fontBold700}
  border-radius: 5px;
  box-sizing: border-box;
  width: ${props => (props.isFullWidth ? '100%' : '40px')};
  height: 40px;
  font-size: 24px;
  align-items: center;
  background-color: ${props => (props.disabled ? '#fff' : '#eeeff2')};
  border: 0;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: inline-flex;
  justify-content: center;
  &:focus {
    outline: 0;
  }
`;

const ArrowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 32px;
  margin: 0 12px 0 4px;
  svg {
    opacity: 0.2;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
    margin: 0 0 -4px 0;
    &:nth-of-type(2) {
      margin: -4px 0 0 0;
      transform: rotate(180deg);
    }
  }
`;

const PaginationLimitSelect = styled.input.attrs({ type: 'number' })`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.md};
  border: 0;
  color: black;
  display: flex;
  opacity: ${props => props.disabled && '.6'};
  width: 100%;
  margin: 0 0 0 16px;
  text-align: center;
  &::-webkit-inner-spin-button {
    display: none;
  }
  -moz-appearance: textfield;
  &:focus {
    outline: 0;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  margin-right: 24px;
`;

export const TablePaginationNew = ({ disabled = false }: Props) => {
  const { count, size, page, lastPage, setPage } = useTableContext();
  const firstElement = page * size + 1;
  let lastElement = (page + 1) * size;
  if (lastElement > count) {
    lastElement = count;
  }

  const handleIncreasePage = () =>
    !disabled && setPage(page + 1 < lastPage ? page + 1 : page);
  const handleDecreasePage = () =>
    !disabled && setPage(page > 0 ? page - 1 : page);

  return (
    <Pagination>
      <PaginationText>Go to:</PaginationText>
      <SelectWrapper>
        <PaginationLimitSelect
          value={page + 1}
          min={1}
          max={lastPage}
          step={1}
          onClick={(e: any) => {
            e.currentTarget.select();
          }}
          onChange={(e: any) => {
            if (Number(e.currentTarget.value) < 1 || !e.currentTarget.value) {
              return;
            }

            let newPage = e.currentTarget.value;
            if (newPage > lastPage) {
              newPage = lastPage;
            }
            setPage(newPage - 1);
          }}
        />
        <ArrowsWrapper>
          <MdArrowDropUp
            onClick={handleIncreasePage}
            color={'black'}
            width={'16px'}
            height={'16px'}
          />
          <MdArrowDropUp onClick={handleDecreasePage} color={'black'} />
        </ArrowsWrapper>
      </SelectWrapper>
      <PaginationText>
        {firstElement} - {lastElement} of {count}
      </PaginationText>
      <InputWrapper>
        <PageButton
          disabled={page <= 0 || disabled}
          onClick={handleDecreasePage}
        >
          <MdChevronLeft />
        </PageButton>
        <PageButton
          style={{ marginLeft: 8 }}
          onClick={handleIncreasePage}
          disabled={page + 1 >= lastPage || disabled}
        >
          <MdChevronRight />
        </PageButton>
      </InputWrapper>
    </Pagination>
  );
};
