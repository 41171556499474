import { usePageContext } from 'app/PageContext';
import dayjs from 'dayjs';
import { Viewer } from 'lib/api/recipients/types';
import {
  LoadingIndicator,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Tooltip,
} from 'lib/components';
import { theme } from 'lib/style';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MdAutoDelete, MdBlock } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';
import { CheckboxInputNotify } from './CheckboxInputNotify';
import { ModalEmailPreview } from './ModalEmailPreview';
import {
  Filters,
  StatusOptionsValues,
  getStatusColors,
  statusOptions,
} from './const';
import { CardStatsViewersQuickFilters } from './quickFilters/CardStatsViewers';

import { AnalyticsReportModal } from 'app/pages/reports/components/AnalyticsReportModal';
import { blockEmailRecepient } from 'lib/api/emailRecepientApi';
import { useEmailReceiverMutation } from 'lib/api/receivers/updateEmailReceiver';
import { useVideoRecipients } from 'lib/api/recipients/getVideosRecipients';
import { ModalVideoExpiration } from 'lib/components/modal/ModalVideoExpiration';
import { blockToast } from 'lib/components/toasts/block';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';
import { DATETIME_MERIDIEM } from 'lib/utils/datetime';
import { ModalBlockRecepient } from './ModalBlockRecepient';
import { useParams } from 'react-router';

const CardStatsLayout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  min-height: 300px;
`;

const NoStats = styled.div`
  margin-top: 34px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 2px 8px;
`;

const StatusParagraph = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`;

const CellFontStyles = css`
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
`;

const CellHoverStyles = css`
  position: absolute;
  ${CellFontStyles}
  font-size: 16px;
  text-align: center;
  left: -90px;
  width: 180px;
  padding: 8px 12px;
  background-color: ${theme.palette.white};
  box-shadow: 0px 8px 32px 0px rgba(29, 30, 36, 0.08);
  z-index: 2;
  top: -48px;
`;

const EmailPhoneCell = styled.div`
  cursor: pointer;
  color: rgba(39, 42, 50, 1);
  ${CellFontStyles}
`;

const BlockIconTableCell = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover:after {
    content: 'Block this recipient';
    ${CellHoverStyles}
  }
  :hover svg {
    color: rgba(232, 76, 61, 0.8) !important;
  }
`;

const DateSentExpiration = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

const TableCell = styled.span<{ count?: boolean; hoverContent?: string }>`
  color: rgba(78, 84, 97, 1);
  ${CellFontStyles}
  position:relative;

  ${({ count }) =>
    count &&
    css`
      color: rgba(208, 211, 217, 1);
    `}
  ${({ hoverContent }) =>
    !!hoverContent &&
    css`
      cursor: pointer;
      :hover:after {
        content: '${hoverContent}';
        ${CellHoverStyles}
      }
    `}
`;

const TableNameCell = styled.span`
  color: rgba(78, 84, 97, 1);
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  ${CellFontStyles}
  position:relative;
`;

const LastViewedHeader = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;

const ClickToEdit = styled.div`
  color: #272a32;
  font-weight: 600;
  margin-top: 10px;
`;

const TooltipWrapper = styled.div<{ hidden?: boolean }>`
  margin-left: 8px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  visibility: ${props => (!props.hidden ? 'hidden' : 'block')};
  &:hover {
    cursor: pointer;
    figcaption {
      z-index: 2;
      opacity: 1;
      width: auto;
      position: absolute;
    }
  }
`;

type Props = {
  videoId: string;
  setDisableNotificationButtons: React.Dispatch<React.SetStateAction<boolean>>;
  allowVideoResend?: boolean;
};

type Order = 'asc' | 'desc' | null;
type Modals = 'report' | 'email_preview' | null;

const StatusCell = ({ status, onClick }: { status: string; onClick: any }) => {
  const { label, value } = statusOptions.find(
    ({ value }) => value.toLowerCase() === status.toLowerCase()
  ) || {
    label: 'Missing status',
    value: 'Missing status',
  };

  return (
    <StatusWrapper
      onClick={onClick}
      style={getStatusColors(value as StatusOptionsValues)?.wrapper}
    >
      <StatusParagraph
        style={getStatusColors(value as StatusOptionsValues)?.paragraph}
      >
        {label}
      </StatusParagraph>
    </StatusWrapper>
  );
};

export const CardStatsViewers = (props: Props) => {
  const { userId: targetUserId } = useParams() as {
    userId: string;
  };
  const { folder } = usePageContext();
  const {
    videoId,
    setDisableNotificationButtons,
    allowVideoResend = true,
  } = props;
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const [selectedViewer, setSelectedViewer] = useState<Viewer>();
  const [data, setData] = useState<Viewer[]>([]);
  const [filteredData, setFilteredData] = useState<Viewer[]>([]);
  const [filters, setFilters] = useState<Filters | {}>({});
  const [order, setOrder] = useState<Order>(null);
  const [modals, setModal] = useState<Modals>(null);

  const [blockRecepient, setBlockRecepient] = useState(false);
  const [selectedRecepient, setSelectedRecepient] = useState<Viewer>();

  const [customDate, setCustomDate] = useState<Date | null>(null);
  const [customOpened, setCustomOpened] = useState<boolean>(false);

  const isCompany = folder?.name == 'Company' ? 1 : 0;
  const dateFormat = STANDARD_DATE_FORMAT;

  const { mutateAsync } = useEmailReceiverMutation();

  const {
    data: response,
    isLoading,
    isError,
    refetch,
  } = useVideoRecipients(videoId, {
    isCompany,
    size: 1000,
    targetUserId,
  });

  const openEmailPreview = (x: Viewer) => {
    setSelectedViewer(x);
    setModal('email_preview');
  };

  const openBlockReceiverModal = async (x: Viewer) => {
    setSelectedRecepient(x);
    setBlockRecepient(true);
  };

  const blockReceiver = async (x: Viewer) => {
    try {
      const result = await blockEmailRecepient(
        `emailreceiver/${x.receiverId}/block`
      );
      if (result) {
        const start = page * size;
        const filteredViewers = response?.viewers.filter(
          (e: any) => e.receiverId !== x.receiverId
        );
        blockToast({
          title: 'Recipient is blocked successfully!',
          additionalInfo: `${selectedRecepient?.email} (${selectedRecepient?.name}) can’t view this video any more.`,
        });
        setFilteredData([...filteredViewers.slice(start, start + size)]);
        setCount(filteredViewers.length);
        setData(filteredViewers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortByLastViewDate = (order: 'asc' | 'desc') => {
    const sorted = orderBy(data, 'lastViewDate', order);
    const start = page * size;
    setFilteredData([...sorted.slice(start, start + size)]);
    setData(sorted);
    setOrder(order);
  };

  const formatTableDate = (date: string) =>
    dayjs(date).isValid() ? dayjs(new Date(date)).format(dateFormat) : '-';

  const findItems = (i: Viewer) =>
    Object.entries(filters).every(([key, val]) => {
      // @ts-ignore
      if (key === 'search') {
        const valLowerCase = val?.toLowerCase();
        const nameExist = i['name']?.toLowerCase().includes(valLowerCase);
        const emailExist = i['email']?.toLowerCase().includes(valLowerCase);
        return nameExist || emailExist;
      } else if (key === 'SMS') {
        // @ts-ignore
        return i[key] === val;
      } else {
        //@ts-ignore
        return i[key] && i[key].toLowerCase() === val;
      }
    });

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    if (page !== newPage || size !== newSize) {
      const start = newPage * newSize;
      setPage(newPage);
      setSize(newSize);
      setFilteredData([...data.slice(start, start + size)]);
    }
  };

  //calculate data on change
  useEffect(() => {
    const start = 0 * size;
    const filteredData = data.filter(findItems);
    setCount(filteredData.length);
    setPage(0);
    setFilteredData([...filteredData.slice(start, start + size)]);
  }, [filters, size]);

  //grab initial data
  useEffect(() => {
    if (response) {
      const start = page * size;
      setFilteredData([...response.viewers.slice(start, start + size)]);
      setCount(response.viewers.length);
      setData(response.viewers);
      setDisableNotificationButtons(!response.viewers.length);
    }
  }, [response]);

  const openCustomLinkExpirationModal = (date: Date, viewer: Viewer) => {
    setSelectedViewer(viewer);
    setCustomDate(date);
    setCustomOpened(true);
  };

  const closeCustomLinkExpirationModal = () => {
    setCustomOpened(false);
  };

  const setLinkExpiration = async (receiverId?: number) => {
    if (receiverId) {
      await mutateAsync({
        receiverId: receiverId.toString(),
        expirationDate: customDate,
      });
      refetch();
      setCustomOpened(false);
    }
  };

  return (
    <>
      {customOpened && (
        <ModalVideoExpiration
          handleModalClose={closeCustomLinkExpirationModal}
          handleSubmit={() => setLinkExpiration(selectedViewer?.receiverId)}
          expiration={customDate}
          setExpiration={setCustomDate}
          buttonText='Update Link Expiration'
        />
      )}
      <CardStatsLayout>
        {!!data.length && (
          <CardStatsViewersQuickFilters
            setModal={setModal}
            filter={{ setFilters, filters }}
            videoId={videoId}
          />
        )}
        {isLoading ? (
          <LoadingIndicator isLoading={isLoading} />
        ) : (!isLoading && filteredData.length == 0) || isError ? (
          <>
            <NoStats>No data to show.</NoStats>
          </>
        ) : (
          <TableContextProvider
            total={count}
            initSize={size}
            initPage={page}
            onChange={onPaginationChange}
          >
            <Table
              headers={[
                '',
                'Name',
                '',
                'Email Address / Phone',
                'Date Sent',
                'Status',
                'Views',
                /* TO_DO -> MISSING RESPONSE 'Watched', */
                <LastViewedHeader>
                  <span>Last Viewed</span>
                  <HiArrowNarrowUp
                    onClick={() => sortByLastViewDate('asc')}
                    color={order === 'asc' ? '#001B53' : 'rgba(0, 27, 83, 0.2)'}
                    width={8}
                    height={12}
                  />
                  <HiArrowNarrowDown
                    onClick={() => sortByLastViewDate('desc')}
                    color={
                      order === 'desc' ? '#001B53' : 'rgba(0, 27, 83, 0.2)'
                    }
                    width={8}
                    height={12}
                  />
                </LastViewedHeader>,
                'Notify',
                '',
              ]}
              hoverable={false}
              rows={filteredData.map((x: Viewer, index: number) => ({
                key: index,
                columns: [
                  '',
                  <TableNameCell>
                    {!x.name || x.name.trim() === '' ? '-' : x.name}
                  </TableNameCell>,
                  <BlockIconTableCell>
                    <MdBlock
                      onClick={() => openBlockReceiverModal(x)}
                      color='rgba(208, 211, 217, 1)'
                    />
                  </BlockIconTableCell>,
                  <EmailPhoneCell
                    onClick={() => openEmailPreview(x)}
                    title='Preview sent email'
                  >
                    {x.email}
                  </EmailPhoneCell>,
                  <DateSentExpiration>
                    <TableCell>{formatTableDate(x.date)}</TableCell>
                    <TooltipWrapper hidden={!!x.expirationDate}>
                      <MdAutoDelete
                        onClick={() =>
                          openCustomLinkExpirationModal(
                            new Date(x.expirationDate),
                            x
                          )
                        }
                        color={theme.palette.red80}
                        cursor={'pointer'}
                      />
                      <Tooltip
                        text={'Will Expire Soon'}
                        subtext={dayjs(x.expirationDate).format(
                          DATETIME_MERIDIEM
                        )}
                        topPixels={-80}
                        hideTriangle
                        textTransform='normal'
                        button={
                          <ClickToEdit
                            onClick={() =>
                              openCustomLinkExpirationModal(
                                new Date(x.expirationDate),
                                x
                              )
                            }
                          >
                            Click to edit
                          </ClickToEdit>
                        }
                      />
                    </TooltipWrapper>
                  </DateSentExpiration>,
                  <StatusCell
                    status={x.emailStatus}
                    onClick={() =>
                      setFilters(prevState => {
                        return {
                          ...prevState,
                          emailStatus: x.emailStatus?.toLocaleLowerCase(),
                        };
                      })
                    }
                  />,
                  <TableCell count={x.viewCount === 0}>
                    {x.viewCount}
                  </TableCell>,
                  /* TO_DO -> MISSING RESPONSE <TableCell>72% Missing</TableCell>, */
                  <TableCell
                    hoverContent={
                      dayjs(x.lastViewDate).isValid() ? x.lastViewDate : ''
                    }
                  >
                    {formatTableDate(x.lastViewDate)}
                  </TableCell>,
                  <CheckboxInputNotify
                    showTooltip={true}
                    videoId={videoId}
                    viewer={x}
                  />,
                  '',
                ],
                onClick: () => {},
              }))}
              isLoading={isLoading}
              error={isError}
            />
            {count > 0 && (
              <TableFooter>
                <TablePaginationNew />
                <TablePaginationSizeNew onSizeChange={() => setPage(0)} />
              </TableFooter>
            )}
          </TableContextProvider>
        )}
      </CardStatsLayout>

      {modals === 'report' && (
        <AnalyticsReportModal
          handleModalClose={() => {
            setModal(null);
          }}
          videoId={videoId}
          params={filters}
        />
      )}

      {selectedViewer !== undefined && (
        <ModalEmailPreview
          videoId={videoId}
          receiverID={selectedViewer.receiverId}
          isVisible={modals === 'email_preview'}
          handleModalClose={() => setModal(null)}
          isSms={!!selectedViewer.SMS}
          allowVideoResend={allowVideoResend}
        />
      )}
      {blockRecepient && selectedRecepient && (
        <ModalBlockRecepient
          recepient={selectedRecepient}
          handleModalClose={block => {
            if (block) {
              blockReceiver(selectedRecepient);
            }
            setBlockRecepient(false);
          }}
        />
      )}
    </>
  );
};
