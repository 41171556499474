import React from 'react';
import { MdCircle, MdMarkEmailRead } from 'react-icons/md';
import { IoMdOptions } from 'react-icons/io';
import { useTheme } from 'styled-components/macro';
import {
  Dropdown,
  LoadingIndicator,
  MainContainer,
  Search,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { useAuth } from 'lib/context';
import { useHistory } from 'react-router-dom';
import {
  ISingleNotification,
  useNotificationsQuery,
} from 'lib/api/notifications/getNotifications';
import { capitalizeFirstLetter, getViewBtnText } from '../utils';
import { NOTIFICATION_TYPES } from '../types';
import {
  MainContainerWrapper,
  Text,
  FiltersContainer,
  FilterGroup,
  Filter,
  Customize,
  CustomizeDisable,
  ListBody,
  Item,
  HoverElement,
  Container,
} from '../styles';
import { getNotificationFilterOptions } from '../const';
import { useReadNotificationMutation } from 'lib/api/notifications/readNotification';
import { useReadAllNotificationMutation } from 'lib/api/notifications/readAllNotifications';
import { calculateDiff } from 'lib/utils/time';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { Button, Tooltip } from 'react-covideo-common';
import { useUnreadNotificationQuery } from 'lib/api/notifications/getUnreadNotificationCount';

export const ListNotifications = () => {
  const { userData } = useAuth();
  const { data: notificationCount } = useUnreadNotificationQuery(userData);

  const notificationFilterOptions = getNotificationFilterOptions(userData);

  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [notificationFilter, setNotificationFilter] = React.useState('ALL');

  const { data, refetch, isLoading } = useNotificationsQuery({
    notificationType: notificationFilter,
    limit: size,
    start: page * size,
    search: searchQuery,
  });

  const { mutateAsync: readNotification } = useReadNotificationMutation();
  const { mutateAsync: readAllNotifications } =
    useReadAllNotificationMutation();

  const history = useHistory();
  const themes = useTheme();

  const performAction = async (notification: any) => {
    if (!notification.isRead) {
      await readNotification(notification.notificationId);
    }
    const hasFilesAndBoards = checkIfFeatureIsEnabled(
      userData,
      productFeature.FILES_AND_BOARDS
    );
    const hasAnalytics = checkIfFeatureIsEnabled(
      userData,
      productFeature.HOTSPOTS_AND_ANALYTICS
    );
    const hasCtas = checkIfFeatureIsEnabled(userData, productFeature.CTAS);
    const hasVideoReply = checkIfFeatureIsEnabled(
      userData,
      productFeature.VIDEO_REPLY
    );
    const hasReactions = checkIfFeatureIsEnabled(
      userData,
      productFeature.VIDEO_REPLY
    );
    const status = notification?.meta?.status?.toLowerCase();

    switch (notification.type.toUpperCase()) {
      case NOTIFICATION_TYPES.FILES:
        if (!hasFilesAndBoards) {
          history.push('/upgrade-plan');
          return;
        }
        history.push(`/files/${notification.actionId}`);
        return;
      case NOTIFICATION_TYPES.BOARDS:
        if (!hasFilesAndBoards) {
          history.push('/upgrade-plan');
          return;
        }
        history.push(`/boards/${notification.actionId}/shared`);
        return;
      case NOTIFICATION_TYPES.EMAIL_NOT_DELIVERED:
      case NOTIFICATION_TYPES.VIEW:
        if (!hasAnalytics) {
          history.push('/upgrade-plan');
          return;
        }
        history.push(`/classic/edit-video/${notification.actionId}/analytics`);
        return;
      case NOTIFICATION_TYPES.CTA:
        if (!hasCtas) {
          history.push('/upgrade-plan');
          return;
        }
        history.push(
          `/classic/edit-video/${notification.actionId}/analytics?tab=2`
        );
        return;
      case NOTIFICATION_TYPES.REPLY:
        if (!hasVideoReply) {
          history.push('/upgrade-plan');
          return;
        }
        history.push(`/classic/edit-video/${notification.actionId}/details`);
        return;
      case NOTIFICATION_TYPES.QUOTES:
        history.push(`/repair-orders/${notification.actionId}`);
        return;
      case NOTIFICATION_TYPES.SALES:
        window.open(notification.actionUrl, '_blank');
        return;
      case NOTIFICATION_TYPES.REACTIONS_COMMENTS:
        if (!hasReactions) {
          history.push('/upgrade-plan');
          return;
        }
        history.push(
          `/classic/edit-video/${notification.actionId}/reactions${
            notification?.meta?.reactionType === '1' ? '?tab=1' : ''
          }`
        );
        return;
      case NOTIFICATION_TYPES.PUSHED_TO_VDP:
        history.push(`/vdps?vin=${notification?.meta?.vin}`);
        return;
      case NOTIFICATION_TYPES.LIVE:
        window.open(notification.actionUrl, '_blank');
        return;
      case NOTIFICATION_TYPES.VIDEO_DELETE:
        history.push(`/profile/Profile_VideoDeletion`);
        return;
      case NOTIFICATION_TYPES.REPORTS:
        window.open(notification.actionUrl, '_blank');
        return;
      case NOTIFICATION_TYPES.EMAIL_OPENED:
        if (!hasAnalytics) {
          history.push('/upgrade-plan');
          return;
        }
        history.push(`/classic/edit-video/${notification.actionId}/analytics`);
        return;
      case NOTIFICATION_TYPES.VIDEO_REQUEST:
        history.push(`/video-requests/${notification.actionId}`);
        return;
      case NOTIFICATION_TYPES.VIDEO_REQUEST_RESPONSE:
        history.push(`/home?status=${status}`);
        return;

      default:
        break;
    }
  };

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setPage(0);
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  React.useEffect(() => {
    refetch();
  }, [page, size, notificationFilter, searchQuery]);

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} height='500px' />;
  }

  return (
    <Container>
      <MainContainer>
        <MainContainerWrapper>
          <FiltersContainer>
            <FilterGroup>
              <Filter>
                <Dropdown
                  extendStyles={{
                    valueContainer: {
                      overflow: 'hidden',
                      height: '40px',
                    },
                  }}
                  options={notificationFilterOptions}
                  menuPortalTarget={document.body}
                  menuPlacement={'bottom'}
                  zIndexProp={1}
                  value={notificationFilterOptions.find((o: any) => {
                    return o.value === notificationFilter;
                  })}
                  onChange={(option: any) => {
                    setPage(0);
                    setNotificationFilter(option.value);
                  }}
                  creatable={false}
                />
              </Filter>
              <Filter style={{ marginLeft: '10px', width: 'fit-content' }}>
                <Search
                  placeholder='Search notifications...'
                  onSearch={onSearch}
                />
              </Filter>
            </FilterGroup>
            <Customize>
              <Tooltip
                position={'top' as any}
                hoverable={true}
                extendStyles={{
                  popup: {
                    width: '200px',
                    left: '50%',
                    textAlign: 'center',
                  },
                }}
                popup={<div>Change Preferences</div>}
              >
                <Button
                  icon={<IoMdOptions size={18} />}
                  onClick={() => {
                    history.push(
                      '/profile/Profile_EditUserInfo?tab=preferences'
                    );
                  }}
                  variant='secondary'
                />
              </Tooltip>
              {/* SUS-453 changes */}
              {data?.notifications?.length && notificationCount ? (
                <Button
                  text='Mark All as Read'
                  icon={<MdMarkEmailRead size={22} />}
                  onClick={() => readAllNotifications()}
                />
              ) : (
                <CustomizeDisable>
                  <MdMarkEmailRead size='24px' />
                  Mark All as Read
                </CustomizeDisable>
              )}
            </Customize>
          </FiltersContainer>
        </MainContainerWrapper>
        <TableContextProvider
          total={data?.count}
          onChange={onPaginationChange}
          initPage={page}
          initSize={size}
        >
          {data?.notifications?.length ? (
            <ListBody>
              {data.notifications.map((item: ISingleNotification) => {
                return (
                  <Item
                    isRead={item.isRead}
                    key={`notification-item-${item.notificationId}`}
                  >
                    <div className='item--text'>
                      <div className='item--text-title'>
                        <b>{item.fullNotificationText}</b>
                      </div>
                      <div className='item--text--2nd-div'>
                        <Button
                          text={capitalizeFirstLetter(item.type)}
                          size='small'
                          onClick={() => {
                            setPage(0);
                            setNotificationFilter(item.type);
                          }}
                        />
                        <div className='item--text--2nd-div-time'>
                          &#x2022; {calculateDiff(item.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div className='item--btn'>
                      {!item.isRead && (
                        <div className='item--btn-circle'>
                          <MdCircle
                            size='8px'
                            color={themes.colors.primary[100]}
                          />
                        </div>
                      )}
                      <div className='item--btn-createBtn'>
                        {!item?.meta?.isVideoDeleted &&
                          item.type?.toUpperCase() !==
                            NOTIFICATION_TYPES.MESSAGES &&
                          item.type?.toUpperCase() !==
                            NOTIFICATION_TYPES.SALES && (
                            <Button
                              variant='secondary'
                              text={getViewBtnText(
                                item.type,
                                item.meta,
                                userData
                              )}
                              onClick={() => {
                                performAction(item);
                              }}
                            />
                          )}
                        {item.isRead ? null : (
                          <HoverElement
                            padding='12px'
                            top='-50px'
                            className='hover--div'
                          >
                            <MdMarkEmailRead
                              size='24px'
                              color={themes.colors.primary[100]}
                              onClick={() => {
                                readNotification(`${item.notificationId}`);
                              }}
                            />
                            <div className='hover-content'>
                              <strong>Mark as Read</strong>
                            </div>
                          </HoverElement>
                        )}
                      </div>
                    </div>
                  </Item>
                );
              })}
              {data.count > 0 && (
                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew text='Show notifications:' />
                </TableFooter>
              )}
            </ListBody>
          ) : (
            <Text>No matching results found.</Text>
          )}
        </TableContextProvider>
      </MainContainer>
    </Container>
  );
};
