import { EXPRESS_API } from 'configs/api/expressApi';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { saveAs } from 'file-saver';
import { useToastError } from 'lib/hooks';
import { AxiosError } from 'axios';

const DATE_FORMAT = 'YYYY-MM-DD';

const downloadCustomReportData = async ({
  reportId,
  name,
  from,
  to,
}: {
  reportId: string;
  name: string;
  from: Date;
  to: Date;
}): Promise<void> => {
  const response = await EXPRESS_API.get(
    `/custom-reports/${reportId}/download`,
    {
      params: {
        to: dayjs(to).format(DATE_FORMAT),
        from: dayjs(from).format(DATE_FORMAT),
      },
      responseType: 'blob',
    }
  );

  saveAs(new Blob([response.data]), name);
};

export const useDownloadCustomReportMutation = () => {
  const { showError } = useToastError();

  return useMutation(downloadCustomReportData, {
    onError: (err: AxiosError) => showError(err),
  });
};
