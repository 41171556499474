import { FormColorPicker } from 'app/pages/video/videoDetails/main/videoAnnotations/components/FormColorPicker';
import { useFormikContext } from 'formik';

import { Gap } from 'lib/components/styles/layout';
import {
  FontFamilyDropdown,
  FontSizeDropdown,
  TextEditor,
} from 'lib/components/textEditorAutomotive';
import React from 'react';
import { Section, SectionTitle } from '../index.styled';
import { SalesPagesValues } from './types';
import { UploadImage } from './UploadImage';

export const emailEditorToolbar = {
  options: ['fontFamily', 'fontSize', 'inline', 'remove', 'link'],
  fontFamily: {
    options: ['Work Sans', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Verdana'],
    component: FontFamilyDropdown,
  },
  fontSize: {
    options: [10, 12, 14, 16, 18, 24, 30, 48, 72, 96],
    component: FontSizeDropdown,
  },
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: {
      className: 'toolbar-custom-icon toolbar-bold-icon large-icon',
    },
    italic: {
      className: 'toolbar-custom-icon toolbar-italic-icon large-icon',
    },
    underline: {
      className: 'toolbar-custom-icon toolbar-underline-icon large-icon',
    },
  },
  remove: {
    className: 'toolbar-custom-icon toolbar-remove-icon medium-icon',
  },
  link: {
    inDropdown: false,
    className: 'toolbar-custom-icon toolbar-link-icon small-icon',
    options: ['link'],
    link: {
      className: 'toolbar-custom-icon add-content-link toolbar-link-icon',
    },
  },
};

export const SalesPagesFooterForm = () => {
  const { initialValues, setFieldValue, values } =
    useFormikContext<SalesPagesValues>();

  return (
    <Section>
      <SectionTitle>Footer </SectionTitle>
      <Gap>
        <FormColorPicker
          label='Background Color'
          name='footerSectionBgColor'
          menuPlacement='bottom'
          defaultColor={initialValues?.footerSectionBgColor || '#FFF'}
          isClearable={true}
        />
        <Gap
          flexDirection='column'
          gap='8px'
          width='100%'
          alignItems='flex-start'
        >
          <UploadImage name='footerImage' label='Footer image' />
        </Gap>
        <Gap>
          <TextEditor
            height={'160px'}
            onTextEditorChange={text => {
              setFieldValue('footerAdditionalText', text);
            }}
            initialContent={values.footerAdditionalText}
            placeholder={'Enter your message...'}
            toolbar={emailEditorToolbar}
            hideAddVariableButton={true}
          />
        </Gap>
        <FormColorPicker
          label='Text Color'
          name='footerSectionTextColor'
          menuPlacement='bottom'
          defaultColor={initialValues?.footerSectionTextColor || '#FFF'}
          isClearable={true}
        />
      </Gap>
    </Section>
  );
};
