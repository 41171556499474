import dayjs from 'dayjs';

export const validateExpiry = (value: string): boolean => {
  if (!value?.length) return false;

  // Format current year as two digits
  const currentYearStr = dayjs().format('YY');
  const currentYear = parseInt(currentYearStr, 10);
  const currentMonth = dayjs().month() + 1;

  // Reformat expiry date into MM/YY format
  const expDateFormatter =
    value.replace(/\//g, '').substring(0, 2) +
    (value.length > 2 ? '/' : '') +
    value.replace(/\//g, '').substring(2, 4);

  // Validate format: MM/YY where MM is 01-12 and YY is within valid range
  const isValidFormat = new RegExp(
    `^(0[1-9]|1[0-2])/([${currentYearStr[0]}-9][0-9])$`
  ).test(expDateFormatter);

  if (!isValidFormat) return false;

  // Extract month and year
  const [monthStr, yearStr] = expDateFormatter.split('/');
  const month = !!monthStr?.length ? parseInt(monthStr, 10) : 0;
  const year = !!yearStr?.length ? parseInt(yearStr, 10) : 0;

  // Check if expiry date is not in the past
  const isNotExpired =
    year > currentYear || (year === currentYear && month >= currentMonth);

  return isNotExpired;
};

export const formatExpiry = (value: string) => {
  if (!value?.length) return '';

  return (
    value.replace(/\//g, '').substring(0, 2) +
    (value.length > 2 ? '/' : '') +
    value.replace(/\//g, '').substring(2, 4)
  );
};
