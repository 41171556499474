import { ResponseType } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';

export type CouponData = {
  id: string;
  amountOff?: number;
  percentOff?: number;
};

export const getCouponDetails = async (coupon: string) => {
  const response = await EXPRESS_API.get(`/register/payment/coupons`, {
    params: { code: coupon },
  });
  return response.data;
};

export const getInvoiceData = async (previewUrl: string) => {
  const response = await EXPRESS_API.get(previewUrl, {
    responseType: 'arraybuffer' as ResponseType,
  });
  return response.data;
};
