export enum USER_INVITES_SORY_KEY {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  INVITE_SENT = 'INVITE_SENT',
}

export enum USER_INVITES_STATUS_FILTER {
  ALL = 'ALL',
  NOT_INVITED_YET = 'NOT_INVITED_YET',
  SCHEDULED = 'SCHEDULED',
  INVITED = 'INVITED',
}

export enum USER_INVITES_ROLES_FILTER {
  ALL = 'ALL',
  EXCLUDE_MLM = 'EXCLUDE_MLM',
  EXCLUDE_ADMIN_AND_SUPERVISORS = 'EXCLUDE_ADMIN_AND_SUPERVISORS',
  EXCLUDE_MLM_ADMIN_AND_SUPERVISORS = 'EXCLUDE_MLM_ADMIN_AND_SUPERVISORS',
}

export const userInvitesOptions = [
  { value: USER_INVITES_STATUS_FILTER.ALL, label: 'All' },
  {
    value: USER_INVITES_STATUS_FILTER.NOT_INVITED_YET,
    label: 'Not invited yet',
  },
  { value: USER_INVITES_STATUS_FILTER.SCHEDULED, label: 'Scheduled' },
  { value: USER_INVITES_STATUS_FILTER.INVITED, label: 'Invited' },
];

export const userInvitesRolesOptions = [
  { value: USER_INVITES_ROLES_FILTER.ALL, label: 'All Roles' },
  { value: USER_INVITES_ROLES_FILTER.EXCLUDE_MLM, label: 'Exclude MLM Users' },
  {
    value: USER_INVITES_ROLES_FILTER.EXCLUDE_ADMIN_AND_SUPERVISORS,
    label: 'Exclude Admin & Supervisors',
  },
  {
    value: USER_INVITES_ROLES_FILTER.EXCLUDE_MLM_ADMIN_AND_SUPERVISORS,
    label: 'Exclude MLM, Admin & Supervisors',
  },
];

export enum PAGINATION_CONSTANTS {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum USER_INVITE_SENT_STATUS {
  SCHEDULED = 'scheduled',
  SENT = 'sent',
  FAILED = 'failed',
}

export interface IOnPaginationChange {
  page: number;
  size: number;
}

export enum MANAGE_INVITES_COLUMNS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ACCESS = 'access',
  CONTACT = 'contact',
  INVITE_SENT = 'inviteSent',
  ACTIONS = 'actions',
  BOUNCED = 'bounced',
}

export enum MANAGE_INVITES_MODAL {
  CANCEL = 'CANCEL',
  INVITE = 'INVITE',
}

export enum MANAGE_INVITES_TYPE {
  IMMEDIATELY = 'immediately',
  SCHEDULED = 'schedule',
}
