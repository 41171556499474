import { useQuery } from 'react-query';
import { TeleprompterParams } from './types';
import { teleprompterKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getTeleprompterScripts = async (params?: TeleprompterParams) => {
  const response = await EXPRESS_API.get(`/teleprompter`, {
    params: { ...params, field: 'createdAt', order: 'desc' },
  });
  return response.data;
};

export const useGetTeleprompterScripts = (params?: TeleprompterParams) => {
  return useQuery(teleprompterKeys.list(params?.scope || ''), () =>
    getTeleprompterScripts(params)
  );
};
