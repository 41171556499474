import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
import { useMutation, useQueryClient } from 'react-query';
import { usersKeys } from './queryKeys';
import { IUserDataRequest } from './types';

interface UpdateUserParams {
  data: Partial<IUserDataRequest>;
  changeOrigin?: CHANGE_ORIGIN;
  userId: string;
}

export const editUser = async ({
  data,
  changeOrigin,
  userId,
}: UpdateUserParams) => {
  const response = await EXPRESS_API.put(`/users/${userId}`, data, {
    params: { changeOrigin },
    withCredentials: true,
  });

  return response.data;
};

export const useEditUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(editUser, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(usersKeys.user_profile(variables.userId));
    },

    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Failed to edit user';
      errorToast({ title: errorMessage });
    },
  });
};
