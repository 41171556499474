import * as React from 'react';
import { ColorResult } from 'react-color';
import styled, { useTheme } from 'styled-components/macro';
import { VideoPlayer } from '../../videoPlayer';
import { InfoQuestionMark, RadioInput } from 'lib/components';
import { VideoAppearanceData, VideoData } from 'lib/hooks';
import { EnhancedColorPicker } from '../components';
import Select from 'react-select';
import RouteLeavingGuard from './RouteLeavingGuard';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { Heading } from 'lib/components/styles/typography';
import {
  FormGroup,
  InputLabel,
  InputWrapper,
  ItemContainer,
  ItemsWrapper,
} from '../styles/layout';

import { updatePlayerDesign, updateUserPlayerDesign } from 'lib/api';
import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { useQueryClient } from 'react-query';
import { guidesKeyes } from 'lib/api/guides/guidesKeyes';
import { Button } from 'react-covideo-common';
import { MdSave } from 'react-icons/md';

type Props = {
  playerBackgroundColor: string;
  handleChangePlayerBackgroundColor: (color: ColorResult) => void;
  handleChangeIconColor: (color: string) => void;
  playerIconsColor: string;
  handleChangeThumbnail: (media: string) => void;
  playButtonPosition: string;
  handlePlayerSettings: (position: string) => void;
  personalThumbnail: string;
  videoId: string;
  videoAppearanceData: VideoAppearanceData;
  videoSource: string;
  videoTitle: string;
  videoRef: React.Ref<HTMLVideoElement>;
  isDirty: boolean;
  resetForm: Function;
  onSave: Function;
  isGuide: boolean;
  setVideoData: (video: VideoData) => void;
};

const SelectInput = styled(Select)`
  border-radius: 4px;
  max-width: 384px;
`;

const options = [
  {
    value: 'center',
    label: 'center',
  },
  {
    value: 'top-left',
    label: 'top left',
  },
  {
    value: 'bottom-left',
    label: 'bottom left',
  },
  {
    value: 'bottom-right',
    label: 'bottom right',
  },
  {
    value: 'top-right',
    label: 'top right',
  },
];

export const Appearance = (props: Props) => {
  const {
    playerBackgroundColor,
    handleChangePlayerBackgroundColor,
    playerIconsColor,
    handleChangeIconColor,
    playButtonPosition,
    handlePlayerSettings,
    personalThumbnail,
    videoId,
    videoAppearanceData,
    videoSource,
    videoRef,
    videoTitle,
    isDirty,
    resetForm,
    onSave,
    isGuide,
    setVideoData,
  } = props;
  const history = useHistory();
  const themes = useTheme();
  const [defaultAppearance, setDefaultAppearance] =
    React.useState(videoAppearanceData);
  const queryClient = useQueryClient();
  const [_, setIsDefaultAppearance] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (videoAppearanceData) {
      const isSame =
        defaultAppearance.playerBackgroundColor === playerBackgroundColor &&
        defaultAppearance.playerIconsAndTextColor === playerIconsColor &&
        defaultAppearance.playButtonPosition === playButtonPosition;

      setIsDefaultAppearance(isSame);
    }
  }, [
    videoAppearanceData,
    playerBackgroundColor,
    playerIconsColor,
    playButtonPosition,
  ]);

  const { userData } = useAuth();

  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id) {
        const isAppearanceAllowed = checkIfFeatureIsEnabled(
          userData,
          productFeature.VIDEO_PLAYER_APPEARANCE
        );
        if (!isAppearanceAllowed) {
          window.location.href = '/upgrade-plan';
        }
      }
    }
  }, []);

  React.useEffect(() => setIsTouched(isTouched || isDirty), [isDirty]);

  const handleSetDefault = async () => {
    setIsLoading(true);
    const obj = {
      playerBackgroundColor,
      playerIconsAndTextColor: playerIconsColor,
      playButtonPosition: playButtonPosition,
    };
    const design = await updateUserPlayerDesign(obj).catch(err => err);
    if (design instanceof Error) {
      errorToast({ title: 'Error setting defaults! Try again!' });
      setIsLoading(false);
      return;
    }

    const video: VideoData = await getVideo(videoId);
    onSave();
    setDefaultAppearance(obj);
    setVideoData(video);
    setIsDefaultAppearance(true);
    successToast({ title: 'Default set successfully.' });
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const design = await updatePlayerDesign(videoId, {
      thumbnail: personalThumbnail,
      playerBackgroundColor: playerBackgroundColor,
      playerIconsAndTextColor: playerIconsColor,
      playButtonPosition: playButtonPosition,
    }).catch(err => err);
    if (design instanceof Error) {
      errorToast({
        title: 'Whoops! Error saving changes, please try again later!',
      });
      setIsLoading(false);
      return;
    }
    isGuide &&
      (await queryClient.refetchQueries(guidesKeyes.details(Number(videoId))));

    const video: VideoData = await getVideo(videoId);
    onSave();
    setVideoData(video);
    successToast({ title: 'Changes saved successfully.' });
    setIsLoading(false);
    setIsTouched(false);
  };

  return (
    <>
      <RouteLeavingGuard
        when={true}
        navigate={path => history.push(path)}
        onConfirm={handleSubmit}
        onDiscard={resetForm}
        shouldBlockNavigation={() => {
          return isDirty;
        }}
      />
      <HeaderWrapper>
        <Heading>{videoTitle}</Heading>
        <Button
          text='Save Changes'
          icon={<MdSave size={18} />}
          onClick={() => handleSubmit()}
          disabled={!isTouched || isLoading}
        />
      </HeaderWrapper>
      <ItemsWrapper>
        <ItemContainer>
          <FormGroup direction='column'>
            <InputWrapper>
              <InputLabel>Main Color</InputLabel>
              <EnhancedColorPicker
                handleColorChange={handleChangePlayerBackgroundColor}
                playerColor={playerBackgroundColor}
                appearanceTab={true}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>Play Button Position</InputLabel>
              <SelectInput
                aria-label='Play Button Position'
                styles={{
                  control: (base: any) => ({ ...base, height: '40px' }),
                  indicatorSeparator: () => ({ display: 'none' }),
                }}
                options={options}
                value={options.find(o => o.value == playButtonPosition)}
                onChange={(option: any) => handlePlayerSettings(option.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>Icons and Text Color</InputLabel>
              <Gap>
                <RadioInput
                  name={'textAndIconColor'}
                  value={'#FFFFFF'}
                  text={'White'}
                  checkedColor={themes.colors.primary[100]}
                  checked={playerIconsColor === '#FFFFFF'}
                  onChange={() => handleChangeIconColor('#FFFFFF')}
                  onClick={() => handleChangeIconColor('#FFFFFF')}
                />
                <RadioInput
                  name={'textAndIconColor'}
                  value={'#000000'}
                  text={'Black'}
                  checkedColor={themes.colors.primary[100]}
                  checked={playerIconsColor === '#000000'}
                  onChange={() => handleChangeIconColor('#000000')}
                  onClick={() => handleChangeIconColor('#000000')}
                />
              </Gap>
            </InputWrapper>
            <Gap>
              <Button
                variant='secondary'
                text='Set As Default'
                disabled={isLoading}
                onClick={handleSetDefault}
              />
              <InfoQuestionMark />
            </Gap>
          </FormGroup>
        </ItemContainer>
        <ItemContainer>
          <VideoPlayer
            playerBackgroundColor={playerBackgroundColor}
            videoSource={videoSource}
            videoId={videoId}
            playButtonPosition={playButtonPosition}
            playerIconsColor={playerIconsColor}
            videoRef={videoRef}
            height='280px'
          />
        </ItemContainer>
      </ItemsWrapper>
    </>
  );
};
