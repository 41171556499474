import React from 'react';
import { SwitchButton, SwitchInput, SwitchLabel } from './styled';
import { Flex } from 'lib/components/styles/layout';
import { CustomSwitchField } from './types';
import { Label } from '../label';

export const BaseSwitch = ({
  name,
  label,
  checked = false,
  disabled = false,
  onChange,
  wrapper,
  ...props
}: CustomSwitchField) => {
  return (
    <Flex
      flexDirection='row'
      width='100%'
      justifyContent='space-between'
      alignItems='center'
      {...wrapper}
    >
      {label && <Label label={label} isRequired={false} />}
      <SwitchInput
        {...props}
        type='checkbox'
        id={`switch-${name}`}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <SwitchLabel
        htmlFor={`switch-${name}`}
        isOn={checked}
        disabled={disabled}
      >
        <SwitchButton isOn={checked} />
      </SwitchLabel>
    </Flex>
  );
};
