import React from 'react';
import { Tooltip } from 'react-covideo-common';
import { MdInfo } from 'react-icons/md';
import styled, { css, CSSProperties, useTheme } from 'styled-components/macro';

const CommonStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  display: flex;
  gap: 2px;
`;

const StyledLabel = styled.label<{ disabled?: boolean }>`
  ${CommonStyles}
  color: ${({ theme }) => theme.colors.neutral[60]};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const StyledParagraph = styled.p<{ disabled?: boolean }>`
  ${CommonStyles}
  color: ${({ theme }) => theme.colors.neutral[60]};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

interface IFormikLabelProps {
  name?: string;
  label: string;
  isRequired?: boolean;
  disabled?: boolean;
  info?: string | React.ReactNode;
  labelStyles?: CSSProperties | undefined;
}

const IconTooltip = ({ info }: { info: string | React.ReactNode }) => {
  const { colors } = useTheme();

  return (
    <TooltipContainer>
      <Tooltip
        popup={info}
        position='bottom'
        extendStyles={{
          popup: {
            width: '310px',
            fontSize: '14px',
            lineHeight: '24px',
            color: colors.neutral[80],
            padding: '8px 12px',
            zIndex: 9999,
          },
          element: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          },
        }}
      >
        <MdInfo size={18} style={{ display: 'inline' }} />
      </Tooltip>
    </TooltipContainer>
  );
};

export const FormikLabel: React.FC<IFormikLabelProps> = ({
  name,
  label,
  isRequired,
  disabled,
  info,
  labelStyles,
}) => {
  const themes = useTheme();

  if (name) {
    return (
      <StyledLabel htmlFor={name} disabled={disabled} style={labelStyles}>
        {label}{' '}
        {isRequired && (
          <span style={{ color: themes.colors.primary[100] }}>*</span>
        )}{' '}
        {info && <IconTooltip info={info} />}
      </StyledLabel>
    );
  }
  return (
    <StyledParagraph disabled={disabled} style={labelStyles}>
      {label}{' '}
      {isRequired && (
        <span style={{ color: themes.colors.primary[100] }}>*</span>
      )}{' '}
      {info && <IconTooltip info={info} />}
    </StyledParagraph>
  );
};
