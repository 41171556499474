import React from 'react';
import { checkUsername } from 'lib/api';
import {
  FieldLabel,
  FormProps,
  getInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from 'app/pages/admin/components/AdminFormComponents';
import { debounce } from 'lodash';
import { Red } from './ResellerSection';
import {
  EmailAndUsernameInput,
  USERNAME,
} from 'app/pages/admin/components/EmailAndUsernameInput';
import { NAPLETON_AUTOMOTIVE_RESELLER_ID } from 'lib/const/SuperAdminConstants';

export const BusinessSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, isNewCustomer, values } = props;
  const [isUsernameValid, setUsernameValid] = React.useState(true);

  const checkUserName = React.useCallback(
    debounce(async (username: string) => {
      const trimmedUserName = username.trim();
      const userValid = await checkUsername(trimmedUserName, '');
      setUsernameValid(!!trimmedUserName ? !userValid.userExist : true);
    }, 2500),
    []
  );

  return (
    <Section>
      <SectionTitle>Business info</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>Name</FieldLabel>
          {getInputField('business', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>
            First Name <Red>*</Red>
          </FieldLabel>
          {getInputField('firstName', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>
            Last Name{' '}
            {props.values.resellerId !== NAPLETON_AUTOMOTIVE_RESELLER_ID && (
              <Red>*</Red>
            )}
          </FieldLabel>
          {getInputField('lastName', false, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>
            Email <Red>*</Red>
          </FieldLabel>
          <EmailAndUsernameInput
            name='email'
            required={true}
            readOnly={false}
            isSubmitting={isSubmitting}
            onChange={() => {
              setFieldValue(USERNAME, values.email);
              checkUserName(values.email || '');
            }}
          />
        </Row>
        {isNewCustomer && (
          <Row>
            <FieldLabel>
              Username <Red>*</Red>
            </FieldLabel>
            <EmailAndUsernameInput
              name={USERNAME}
              required={true}
              readOnly={false}
              isSubmitting={isSubmitting}
              value={values.username}
              onChange={() => {
                checkUserName(values?.username?.trim() ?? '');
              }}
              isUsernameValid={isUsernameValid}
            />
          </Row>
        )}
        <Row>
          <FieldLabel>
            Phone 1 <Red>*</Red>
          </FieldLabel>
          {getInputField('phone1', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>Phone 2</FieldLabel>
          {getInputField('phone2', false, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>URL</FieldLabel>
          {getInputField('url', true, false, isSubmitting)}
        </Row>
      </RowWrapper>
    </Section>
  );
};
