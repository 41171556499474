import React, { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { SiLinkedin } from 'react-icons/si';
import { FaFacebookF, FaYoutube } from 'react-icons/fa';
import { VideoListItem } from 'lib/api';
import { ModalQuickShareMarkAsSent } from 'app/pages/library/videos/components';
import { VIDE0_ACTIVITY_TYPE } from 'lib/api/videoActivities/types';
import { useQuickShare } from 'lib/hooks/useQuickShare';
import {
  IQuickShareMarkAsSentParams,
  QuickShareTypes,
} from 'lib/api/quickshare/useQuickShareMarkAsSentMutation';
import { Button } from 'react-covideo-common';
import twitter from 'assets/images/socialIcons/twitter.webp';
import { GetQuickshareParams } from 'lib/api/quickshare/useGetQuickshareDetails';
import { SALES_PAGE_ID } from '../types';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type ShareButtonProps = {
  backgroundColor?: string;
  textColor?: string;
  disabled: boolean;
  margin?: string;
};

const ShareButton = styled.div<ShareButtonProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 12px 24px 12px 16px;
  width: 100%;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  margin: ${props => props.margin};
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.secondary['non_alpha']};
  color: ${props =>
    props.textColor ? props.textColor : theme.palette.primaryDarkBlue};
  opacity: ${props => (props.disabled ? '.4' : '1')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  > span {
    margin-right: auto;
    margin-left: auto;
  }
`;

type ButtonData = {
  backgroundColor: string;
  textColor: string;
  text: string;
  onClick: any;
  disabled: boolean;
  icon?: ReactNode;
};

type Props = {
  disableShare: boolean;
  video: VideoListItem;
  shareData: GetQuickshareParams;
  hideLegacyYoutubeIntegration?: boolean;
};

export const ShareButtons = ({
  disableShare,
  video,
  shareData,
  hideLegacyYoutubeIntegration,
}: Props) => {
  const [showModalQuickShareMarkAsSent, setShowModalQuickShareMarkAsSent] =
    useState(false);
  const [toastType, setToastType] = useState<QuickShareTypes>('');

  const markAsSentPayload: Omit<
    IQuickShareMarkAsSentParams,
    'markAsSentConfirmed'
  > = {
    videoId: video.id,
    linksetId: shareData?.linksetId,
    templateId: shareData?.templateId,
    overlayId: shareData?.overlayId,
    vin: shareData?.vin,
    allowReactions: shareData?.allowReactions,
    allowVideoReply: shareData?.allowVideoReply,
    notifyUser: shareData?.notifyUser,
    useAutomotive: !!shareData?.vin || shareData?.templateId === SALES_PAGE_ID,
    toastType,
    iconId: parseInt(shareData?.iconId?.toString() || '0'),
    expirationDate: shareData?.expirationDate,
  };

  const {
    isQuickshareLoading,
    onQuickShareButtonClick,
    onQuickShareMutationHandler,
  } = useQuickShare({
    setShowModalQuickShareMarkAsSent,
  });

  const copyUrlClick = async () => {
    setToastType('url');

    onQuickShareButtonClick({
      ...markAsSentPayload,
      videoActivityType: VIDE0_ACTIVITY_TYPE.QUICKSHARE,
      toastType: 'url',
    });
  };

  const copyCrmClick = async () => {
    setToastType('crm_code');
    onQuickShareButtonClick({
      videoActivityType: VIDE0_ACTIVITY_TYPE.CRM_SHARE,
      ...markAsSentPayload,
      toastType: 'crm_code',
    });
  };

  const copyRawHTMLClick = async () => {
    setToastType('html_code');

    onQuickShareButtonClick({
      videoActivityType: VIDE0_ACTIVITY_TYPE.QUICKSHARE,
      ...markAsSentPayload,
      toastType: 'html_code',
    });
  };

  const shareButtonClicked = async (
    toastType: 'faceboook' | 'twitter' | 'youtube' | 'linkedin'
  ) => {
    setToastType(toastType);
    await onQuickShareButtonClick({
      videoActivityType: VIDE0_ACTIVITY_TYPE.QUICKSHARE,
      ...markAsSentPayload,
      toastType,
    });
  };

  const buttons = [
    {
      backgroundColor: '#1877F2',
      textColor: theme.palette.white,
      text: 'Share on Facebook',
      onClick: () => shareButtonClicked('faceboook'),
      icon: <FaFacebookF />,
      disabled: disableShare,
    },
    {
      backgroundColor: '#1CA5D4',
      textColor: theme.palette.white,
      text: 'Share on Twitter',
      onClick: () => shareButtonClicked('twitter'),
      icon: (
        <img
          style={{ height: '1em', width: '1em' }}
          src={twitter}
          alt={'Twitter'}
        />
      ),
      disabled: disableShare,
    },
    {
      backgroundColor: '#EF3E37',
      textColor: theme.palette.white,
      text: 'Share on YouTube',
      onClick: () => shareButtonClicked('youtube'),
      icon: <FaYoutube />,
      disabled: disableShare,
    },
    {
      backgroundColor: '#007AB6',
      textColor: theme.palette.white,
      text: 'Share on LinkedIn',
      onClick: () => shareButtonClicked('linkedin'),
      icon: <SiLinkedin />,
      disabled: disableShare,
    },
  ];

  if (hideLegacyYoutubeIntegration) {
    buttons.splice(2, 1);
  }

  return (
    <>
      <ButtonsWrapper>
        <Button
          variant='secondary'
          text='Copy Video URL'
          disabled={disableShare || isQuickshareLoading}
          onClick={() => copyUrlClick()}
        />
        <Button
          variant='secondary'
          text='Copy CRM Code'
          disabled={disableShare || isQuickshareLoading}
          onClick={() => copyCrmClick()}
        />
        <Button
          variant='secondary'
          text='Copy Raw HTML'
          disabled={disableShare || isQuickshareLoading}
          onClick={() => copyRawHTMLClick()}
        />
        {buttons.map((buttonData: ButtonData, index: number) => (
          <ShareButton
            key={`soc-button-${index}`}
            textColor={buttonData.textColor}
            backgroundColor={buttonData.backgroundColor}
            disabled={buttonData.disabled || isQuickshareLoading}
            onClick={buttonData.onClick}
          >
            {buttonData.icon}
            <span>{buttonData.text}</span>
          </ShareButton>
        ))}
      </ButtonsWrapper>
      {showModalQuickShareMarkAsSent && (
        <ModalQuickShareMarkAsSent
          markAsSentPayload={{
            ...markAsSentPayload,
            videoActivityType:
              toastType === 'crm_code'
                ? VIDE0_ACTIVITY_TYPE.CRM_SHARE
                : VIDE0_ACTIVITY_TYPE.QUICKSHARE,
          }}
          isQuickShareLoading={isQuickshareLoading}
          quickShareMarkAsSentHandler={onQuickShareMutationHandler}
          handleModalClose={async () => {
            setShowModalQuickShareMarkAsSent(false);
          }}
        />
      )}
    </>
  );
};
