import styled from 'styled-components/macro';
import { theme } from 'lib/style';

interface TextAreaProps {
  onChange: any;
  value?: string;
}

export const Content = styled.div`
  width: 944px;
  margin-top: 32px;
`;

export const EditContent = styled.div`
  margin-top: 32px;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SelectionCountText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

export const Counter = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primary[100]};
  margin-left: 16px;
`;

export const UsersList = styled.div`
  margin-top: 16px;
  height: 312px;
  box-sizing: border-box;
  background: #f6f7f9;
  border-radius: 12px;
  padding: 12px;
  position: relative;
  overflow-y: auto;
  .infinite-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const UsersEditList = styled.div`
  margin-top: 12px;
  height: 300px;
  background: #f6f7f9;
  border-radius: 12px;
  padding: 12px;
  max-height: 296px;
  position: relative;
`;

export const ScrollAbleUserArea = styled.div`
  overflow: scroll;
  height: 280px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const UserEditItem = styled.div<{ removed?: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 7px 4px 16px;
  background: #fff;
  margin-bottom: 8px;
  border-radius: 8px;
  opacity: ${props => (props.removed ? 0.5 : 1)};
  transition: opacity 550ms ease;
`;

export const UserItem = styled.div<{ disabled?: boolean; marginLeft?: string }>`
  display: flex;
  align-items: center;
  padding: 16px 0;
  opacity: 0.3;
  opacity: ${props => (props.disabled ? '0.3' : '1')};
  margin-left: ${props => props.marginLeft || '0px'};
`;

export const UserTitle = styled.div<{ width?: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${theme.palette.neutral100};
  margin-left: 8px;
  width: ${props => props.width || '272px'};
`;

export const TitleChip = styled.div`
  display: flex;
  gap: 8px;
`;

export const UserEmail = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${theme.palette.neutral80};
  width: 282px;
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

export const UserEditActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Footer = styled.div<{ marginTop?: string }>`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: ${props => props.marginTop || '32px'};
`;

export const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 12px;
`;

export const SelectAllText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.neutral100};
`;

export const SelectAndCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 624px;
  padding-top: 16px;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 624px;
`;

export const ListPrivacyWrapper = styled.div`
  display: flex;
  gap: 16px;
  height: 312px;
`;

export const Left = styled.div`
  width: 624px;
`;
export const Right = styled.div``;
export const PrivacyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 10px;
`;
export const PrivacyLevelText = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.neutral100};
`;

export const DropdownLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;
export const LabelText = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.covideoBlue100};
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const Label = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.neutral60};
`;

export const Textarea = styled.textarea<TextAreaProps>`
  border: 1px solid rgba(0, 27, 83, 0.2);
  border-radius: 6px;
  background: ${theme.palette.white};
  height: 170px;
  resize: none;
  outline: none;
`;

export const CustomerSelectionCountText = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9297a2;
  margin-left: 8px;
`;

export const CustomerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 0;
  > div {
    margin-left: 16px;
  }
`;

export const MyCompanyLabel = styled.div`
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;
  background-color: #eeeff2;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: #4e5461;
  margin-left: 8px;
`;

export const CustomerItemTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const EmptyText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ShareToWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 37px;
  width: 100%;
`;

export const SharedWith = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.covideoBlue100};
`;

export const dropdownStyles = {
  control: (base: any, state: any) => ({
    ...base,
    '&:hover': { borderColor: '1px solid #001B53' },
    boxShadow: 'none',
    border: state.isFocused && '1px solid #001B53',
    cursor: 'pointer',
    width: '304px',
  }),
  menu: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
  }),
  option: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    background: 'transparent',
    '&:hover': {
      background: theme.palette.secondaryBlue10Percent,
    },
    '&:active': {
      background: 'transparent',
    },
  }),
};

export const dropdownStylesEdit = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 1000,
  }),
  container: (base: any) => ({
    ...base,
  }),
  control: (base: any, state: any) => ({
    ...base,
    boxShadow: 'none',
    border: 'none',
    cursor: 'pointer',
    width: '245px',
    backgroundColor: state.isDisabled && 'white',
  }),
  menu: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    overflow: 'visible',
    zIndex: 500,
  }),
  option: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    background: 'white',
    '&:hover': {
      background: theme.palette.secondaryBlue10Percent,
    },
    '&:active': {
      background: 'white',
    },
  }),
};
