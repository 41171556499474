import { APP_ENVIROMENT, CurrentEnvProps } from './types';

const isEnvDevelopment =
  process.env.REACT_APP_ENV === APP_ENVIROMENT.DEVELOPMENT ||
  !process.env.REACT_APP_ENV;
const isEnvStaging = process.env.REACT_APP_ENV === APP_ENVIROMENT.STAGING;
const isEnvSandbox = process.env.REACT_APP_ENV === APP_ENVIROMENT.SANDBOX;
const isEnvProduction = process.env.REACT_APP_ENV === APP_ENVIROMENT.PRODUCTION;

const getCurrentEnvUrl = ({
  defaultValue,
  ...envOptions
}: CurrentEnvProps): string => {
  const env = process.env.REACT_APP_ENV;
  const envValue = envOptions[env as keyof typeof envOptions];
  return envValue !== undefined ? envValue : defaultValue;
};

export {
  isEnvDevelopment,
  isEnvStaging,
  isEnvSandbox,
  isEnvProduction,
  getCurrentEnvUrl,
};
