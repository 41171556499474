import { EXPRESS_API } from 'configs/api/expressApi';
import { AttributeListItem } from '../types';
import { useMutation, useQueryClient } from 'react-query';
import { videoAttributesKeys } from './videoAttributesKeys';

export const updateOrderAttributes = async (data: AttributeListItem[]) => {
  const response = await EXPRESS_API.put('/video-attributes', data);

  return response.data;
};

export const useUpdateOrderAttributes = () => {
  const queryClient = useQueryClient();
  return useMutation(updateOrderAttributes, {
    onSuccess: () => {
      queryClient.refetchQueries(videoAttributesKeys.attributes());
    },
  });
};
