import { Form, Formik } from 'formik';
import { PaymentCardType } from 'lib/api/billing/useAddCustomerCardMutation';

import {
  initialValues,
  PaymentCardParams,
} from 'lib/components/add-card/constants';
import {
  formatExpiry,
  validateExpiry,
} from 'lib/components/add-card/functions';
import { FormikDropdownField } from 'lib/components/formik/FormikDropdown';
import { FormikInputField } from 'lib/components/formik/FormikInputField';
import { Gap } from 'lib/components/styles/layout';
import { Country } from 'lib/const';
import { subscriptionDataType } from 'lib/const/Subscription';
import { theme } from 'lib/style';
import * as React from 'react';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { IoMdCalendar } from 'react-icons/io';
import { MdKeyboardBackspace } from 'react-icons/md';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

type Props = {
  handleModalClose?: (shouldRefresh?: boolean) => void;
  onSubmit: (cardDetails: PaymentCardType) => Promise<void>;
  signupPage?: boolean;
  subscriptionData: subscriptionDataType;
  onBackButtonPress: () => void;
};

interface ContentHeaderProps {
  textAlign?: string;
}

interface ContentProps {
  minWidth?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.white[100]};;
  box-shadow: ${({ theme }) => `
    0px 0px 4px ${theme.colors.black[10]},
    0px 12px 20px ${theme.colors.black[5]};`}
  border-radius: 16px;
  min-height: 392px;
  height: auto;
  box-sizing: border-box;
  margin-top: 25px;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '100%')};
  @media screen and (max-width: 480px) {
    min-width: 100%;
  }
`;
const ContentHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    align-self: center;
  }
`;

const ContentHeader = styled.div<ContentHeaderProps>`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.xl};
  color: ${({ theme }) => theme.colors.black[100]};

  font-weight: 800;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  width: 100%;
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${({ theme }) => theme.colors.black[80]}
  overflow-wrap: break-word;
  overflow: none;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  @media screen and (max-width: 480px) {
    align-self: center;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral[60]};
  text-align: center;
  margin-top: 30px;
`;

const GoBackText = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${({ theme }) => theme.colors.black[100]};
  display: flex;
  margin: 25px auto 0 auto;
  cursor: pointer;
`;

const validationSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .test(
      'valid-card-number',
      'Invalid card number',
      value =>
        !!value &&
        /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})|(62[0-9]{14,17})|((5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]{8,15}))$/.test(
          value
        )
    )
    .required('Card Number is required'),
  expiry: Yup.string()
    .test('valid-expiry', 'Invalid expiry date', validateExpiry)
    .required('Expiry date is required'),
  cvc: Yup.string()
    .matches(/^[0-9]{3,4}$/, 'Invalid CVC')
    .required('CVC is required'),
  holderName: Yup.string().required('Holder Name is required'),
  country: Yup.string().required('Country is required'),
  postCode: Yup.string().required('Postal Code is required'),
});

export const AddCard = ({
  onSubmit,
  subscriptionData,
  onBackButtonPress,
}: Props) => {
  const { isProPackage } = subscriptionData;
  const { colors } = useCovideoTheme();

  const handleCardAdd = async (values: PaymentCardParams) => {
    try {
      const { holderName, postCode, country, cardNumber, expiry, cvc } = values;
      const payload = {
        cardNumber,
        expMonth: expiry.split('/')[0],
        expYear: '20' + expiry.split('/')[1],
        cvc: cvc.toString(),
        billingCountry: country,
        billingPostalCode: postCode,
        fullName: holderName,
      };
      await onSubmit(payload);
    } catch (error) {}
  };

  return (
    <Container>
      <ContentHeaderWrap>
        <ContentHeader textAlign={isProPackage ? 'center' : 'left'}>
          {'Enter payment details'}
        </ContentHeader>
      </ContentHeaderWrap>
      {isProPackage && (
        <Description>
          <div
            style={{ color: colors.primary[100], fontWeight: 600 }}
          >{`Credit card will be charged ${subscriptionData.amount}`}</div>
          <div style={{ marginTop: 10, color: colors.neutral[60] }}>{`${
            subscriptionData.annual ? 'Annual' : 'Monthly'
          } subscription renews automatically. Cancel any time.`}</div>
        </Description>
      )}
      <Content minWidth={isProPackage ? '100%' : '592px'}>
        <ContentBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCardAdd}
            validateOnMount={true}
          >
            {({ setFieldValue, isValid, isSubmitting }) => (
              <Form>
                <FormikInputField
                  name={'holderName'}
                  label={'Holder Name'}
                  isRequired={true}
                  placeholder='Holder Name'
                  extendStyles={{
                    mainWrapper: {
                      justifyContent: 'space-between',
                      width: '100%',
                      flexWrap: 'nowrap',
                      margin: '20px 0 0 0',
                    },
                  }}
                />

                <FormikInputField
                  name='cardNumber'
                  label='Card Number'
                  placeholder='Card Number'
                  isRequired={true}
                  extendStyles={{
                    mainWrapper: {
                      justifyContent: 'space-between',
                      width: '100%',
                      flexWrap: 'nowrap',
                      margin: '20px 0 0 0',
                    },
                  }}
                />
                <Gap
                  gap='40px'
                  justifyContent='left'
                  width='100%'
                  flexWrap='nowrap'
                  m='20px 0 0 0'
                >
                  <div style={{ position: 'relative', display: 'flex' }}>
                    <IoMdCalendar
                      style={{ position: 'absolute', top: 34, left: 10 }}
                      size={18}
                    />
                    <FormikInputField
                      name='expiry'
                      placeholder='MM/YY'
                      isRequired={true}
                      label='Expiry'
                      style={{ paddingLeft: '40px' }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const formattedValue = formatExpiry(event.target.value);
                        setFieldValue('expiry', formattedValue);
                      }}
                      extendStyles={{
                        mainWrapper: {
                          width: '120px',
                        },
                      }}
                    />
                  </div>
                  <FormikInputField
                    name={'cvc'}
                    label={'CVC'}
                    placeholder='CVC'
                    isRequired={true}
                    extendStyles={{
                      mainWrapper: {
                        width: '120px',
                      },
                    }}
                  />
                </Gap>
                <Gap
                  gap='40px'
                  justifyContent='space-between'
                  width='100%'
                  flexWrap='nowrap'
                  m='20px 0 0 0'
                >
                  <FormikDropdownField
                    name={`country`}
                    label={'Country or a Region'}
                    isRequired={true}
                    disabled={false}
                    options={
                      Country.map(country => ({
                        value: country.countryShortCode,
                        label: country.countryName,
                      })) || []
                    }
                  />
                  <FormikInputField
                    name={'postCode'}
                    label={'ZIP'}
                    placeholder='ZIP'
                    isRequired={true}
                  />
                </Gap>
                <Gap
                  gap='40px'
                  justifyContent='space-between'
                  width='100%'
                  flexWrap='nowrap'
                  m='20px 0 0 0'
                >
                  <Button
                    type='submit'
                    text={'Finish Checkout'}
                    disabled={!isValid || isSubmitting}
                  />
                </Gap>
              </Form>
            )}
          </Formik>
        </ContentBody>
      </Content>
      <ButtonWrapper>
        {isProPackage ? (
          <GoBackText onClick={onBackButtonPress}>Go Back to Plans</GoBackText>
        ) : (
          <Button
            icon={<MdKeyboardBackspace />}
            variant='secondary'
            text={`Back to Plans`}
            onClick={onBackButtonPress}
          />
        )}
      </ButtonWrapper>
    </Container>
  );
};
