import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components/macro';
import {
  MdEmail,
  MdFileDownload,
  MdArrowUpward,
  MdArrowDownward,
  MdLaunch,
  MdRemoveRedEye,
} from 'react-icons/md';
import {
  Field,
  ModalCustomTableFields,
  TABLE_NAME_PREFIX,
} from 'lib/components/modal';
import { AccessRole, reportData, reportTypes } from 'lib/const';
import {
  Dropdown,
  LoadingIndicator,
  Search,
  Spinner,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { downloadReportData } from 'lib/api';
import {
  addThousandCommaSeparator,
  formatSecondsToTime,
  getDisplayName,
} from 'lib/utils/functions';
import { useAuth } from 'lib/context';

import {
  DateRangeSelector,
  timeRangeConstants,
  ReportCard,
  SendReportModal,
  LoginAsModal,
  calculateDatesForRange,
} from '../../components';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import queryString from 'query-string';
import { getDateRangeFromLocalstorage } from '../../components/DateRangeSelector/DateRangeSelector';
import { Button } from 'react-covideo-common';
import { IoMdOptions } from 'react-icons/io';
import {
  MetricsUser,
  useGetUsersMetrics,
} from 'lib/api/metrics/getUserMetrics';
import { ReportTypes } from 'lib/const/ReportData';
import { useToastError } from 'lib/hooks';
import { useDepartmentsQuery } from 'lib/api/superadmin/departments';
import { IoKey } from 'react-icons/io5';
import { useLoginAsUser } from 'lib/api/users/useLoginasUser';

const order = {
  ASC: 'asc',
  DESC: 'desc',
};
type TableFieldType = {
  value: string;
  label: string;
  type: string;
  defaultOrder: string;
  formatter?: (value?: string | number) => string;
};

const fieldValues = {
  NAME: 'name',
  RECORDED: 'recorded',
  SENT_EMAILS: 'sentEmails',
  VIEWS: ReportTypes.VIEWS,
  CLICKS: ReportTypes.CTA,
  LAST_NAME: 'lastName',
  LAST_LOGIN: 'loginDate',
  LOGIN_AS: 'loginAs',
  ENGAGEMENT: ReportTypes.ENGAGEMENT,
  OPENED: ReportTypes.OPENED,
  DELIVERED: ReportTypes.DELIVERED,
  DEFERRED: ReportTypes.DEFERRED,
  SPAM: ReportTypes.SPAM,
  BOUNCED: ReportTypes.BOUNCED,
  DROPPED: ReportTypes.DROPPED,
  UNSUBSCRIBED: ReportTypes.UNSUBSCRIBED,
  AVERAGE_VIDEO_DURATION: ReportTypes.AVERAGE_VIDEO_DURATION,
  TIME_WATCHED: ReportTypes.TIME_WATCHED,
  WATCH_RATE: ReportTypes.WATCH_RATE,
  USER_COUNT: ReportTypes.USER_COUNT,
  TOTAL_SEATS: ReportTypes.TOTAL_SEATS,
};

const fixedTableFields = {
  name: {
    value: fieldValues.NAME,
    label: 'Name',
    type: 'string',
    orderKey: fieldValues.LAST_NAME,
    defaultOrder: order.ASC,
  },
  loginAs: {
    value: fieldValues.LOGIN_AS,
    label: '',
  },
};

const allDynamicTableFields: TableFieldType[] = [
  {
    value: fieldValues.RECORDED,
    label: 'Recorded',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.SENT_EMAILS,
    label: 'Sent',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.VIEWS,
    label: 'Views',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.CLICKS,
    label: 'CTA Clicks',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.LAST_LOGIN,
    label: 'Last Login',
    type: 'string',
    defaultOrder: order.ASC,
  },
  {
    value: fieldValues.ENGAGEMENT,
    label: 'Engagement',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.OPENED,
    label: 'Opened',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.DELIVERED,
    label: 'Delivered',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.DEFERRED,
    label: 'Deferred',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.SPAM,
    label: 'Spam',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.BOUNCED,
    label: 'Bounced',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.DROPPED,
    label: 'Dropped',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.UNSUBSCRIBED,
    label: 'Unsubscribed',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.AVERAGE_VIDEO_DURATION,
    label: 'Avg. Video Duration',
    type: 'number',
    defaultOrder: order.DESC,
    formatter: value => formatSecondsToTime(parseInt(value?.toString() || '0')),
  },
  {
    value: fieldValues.TIME_WATCHED,
    label: 'Time Watched',
    type: 'number',
    defaultOrder: order.DESC,
    formatter: value => formatSecondsToTime(parseInt(value?.toString() || '0')),
  },
  {
    value: fieldValues.WATCH_RATE,
    label: 'Watch Rate',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.USER_COUNT,
    label: 'Active User Count',
    type: 'number',
    defaultOrder: order.DESC,
  },
  {
    value: fieldValues.TOTAL_SEATS,
    label: 'Total Seats',
    type: 'number',
    defaultOrder: order.DESC,
  },
];

// fix report and custom column mismatch
const tableFieldToReportTypeMapper = {
  [fieldValues.RECORDED]: ReportTypes.RECORDED,
  [fieldValues.SENT_EMAILS]: ReportTypes.SENT,
  [fieldValues.LAST_LOGIN]: ReportTypes.LAST_LOGIN,
};

const CardWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 32px 0 32px;
`;

const TableCell = styled.div`
  padding-left: 24px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .CustomersSelect {
    .Select-menu-outer {
      line-height: 40px;
      min-height: 40px;
    }
    width: 200px;
    height: 40px;
    line-height: 40px;
    .Select-control {
      height: 40px;
    }
    .Select-input {
      height: 40px;
    }
  }
`;
const MyTeamWrapper = styled.div`
  .dropdown-container {
    margin: 0;
  }
`;

const CUSTOM_TABLE_COLUMNS_KEY = 'MyTeamReportCustomTableFields';

const queryDateFormat = STANDARD_DATE_FORMAT;

const ALL_USERS = ['all'];
const DEFAULT_REPORTS = [
  reportTypes.RECORDED,
  reportTypes.SENT,
  reportTypes.VIEWS,
  reportTypes.CTA,
  reportTypes.LAST_LOGIN,
];

const sortUsers = (
  filteredTableFields: TableFieldType[],
  users: MetricsUser[],
  field: string,
  sortOrder: string
) => {
  const fieldDetails = filteredTableFields.find(
    tableField => tableField.value === field
  );
  // fix sort breaking when dynamic field removed
  if (!fieldDetails && field !== fixedTableFields.name.orderKey) {
    return users;
  }
  const sortUsers = [...users];
  const sorting = sortOrder === order.ASC ? 1 : -1;

  return sortUsers.sort((userA: any, userB: any) => {
    let valueA = userA[field].toString();
    let valueB = userB[field].toString();
    if (field === fieldValues.LAST_LOGIN) {
      valueA = dayjs(valueA, 'MMM DD YYYY').unix();
      valueB = dayjs(valueB, 'MMM DD YYYY').unix();
      if (isNaN(valueA)) {
        valueA = 0;
      }
      if (isNaN(valueB)) {
        valueB = 0;
      }
    } else {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
    }
    let result = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    if (fieldDetails && fieldDetails.type === 'number') {
      valueA = parseInt(valueA, 10);
      valueB = parseInt(valueB, 10);

      result = valueA - valueB;
    }

    return result * sorting;
  });
};

export const List = () => {
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const storedDateRange = getDateRangeFromLocalstorage();
  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );
  const { showError } = useToastError();
  const {
    userData: { customerId, isAutomotive },
  } = useAuth();

  const allReports = useMemo(() => {
    return Object.keys(reportData)
      .filter(
        reportValue =>
          !reportData[reportValue].isAutomotive ||
          (!!isAutomotive && !!reportData[reportValue].isAutomotive)
      )
      .filter(report =>
        allDynamicTableFields.some(field => {
          const fieldReportValueKey =
            tableFieldToReportTypeMapper[field.value] || field.value;
          return fieldReportValueKey === report;
        })
      )
      .map(reportValue => ({
        value: reportValue,
        label: reportData[reportValue].label,
        description: reportData[reportValue].description || '',
      }));
  }, [reportData, isAutomotive, allDynamicTableFields]);

  const defaultReports = useMemo(() => {
    return allReports.filter(report => DEFAULT_REPORTS.includes(report.value));
  }, [allReports, DEFAULT_REPORTS]);

  const [showCustomizeTableFieldsModal, setShowCustomizeTableFieldsModal] =
    useState(false);
  const [reports, setReports] = useState<Field[]>([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState<
    number | null
  >(null);
  const [startDate, setStartDate] = useState<Date>(start);
  const [endDate, setEndDate] = useState<Date>(end);
  const [dateRange, setDateRange] = useState(initRange);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSendReportModal, setShowSendReportModal] = useState(false);
  const [sortField, setSortField] = useState(fixedTableFields.name.orderKey);
  const [sortOrder, setSortOrder] = useState(order.ASC);
  const [showLoginAsModal, setShowLoginAsModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const history = useHistory();
  const { mutateAsync: loginAsUser, isLoading: loginAsLoading } =
    useLoginAsUser();

  const customReportTypes = (reports || []).map(
    report => report.value
  ) as ReportTypes[];

  const { data: departments, isLoading: isLoadingDepartments } =
    useDepartmentsQuery({ customerId });

  const departmentsOptions =
    departments?.data?.map(department => ({
      value: department.id,
      label: department.name,
    })) || [];

  const { data, isLoading } = useGetUsersMetrics(
    {
      from: startDate,
      to: endDate,
      search: searchQuery,
      reports: customReportTypes,
      customerId,
      start: 0,
      limit: 1000,
      departmentId: selectedDepartment,
    },
    !!customReportTypes?.length
  );

  useEffect(() => {
    try {
      const selectedReports = localStorage.getItem(
        TABLE_NAME_PREFIX + CUSTOM_TABLE_COLUMNS_KEY
      );
      if (!selectedReports) {
        setReports(defaultReports);
        return;
      }
      const localStorageReports: Field[] = JSON.parse(selectedReports) || [];
      setReports(
        localStorageReports.filter((report: Field) =>
          allReports.some(r => r.value === report.value)
        )
      );
    } catch (error) {
      setReports(defaultReports);
    }
  }, [allReports]);

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };

  const onSearch = (query: string) => {
    query = query.toLowerCase();
    setPage(0);
    setSearchQuery(query.toLowerCase());
  };

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };

  const handleUserCellClick = (userId: number | string) => {
    history.push(
      `/reports/users/${userId}?start=${dayjs(startDate).format(
        queryDateFormat
      )}&end=${dayjs(endDate).format(queryDateFormat)}&range=${dateRange}`
    );
  };

  const handleSortClick = (newSortField: string, defaultOrder: string) => {
    setSortField(newSortField);
    if (sortField !== newSortField) {
      setSortOrder(defaultOrder);
    } else {
      setSortOrder(sortOrder === order.ASC ? order.DESC : order.ASC);
    }
  };

  const handleLoginAsClick = async (user: MetricsUser) => {
    if (loginAsLoading) {
      return;
    }
    try {
      await loginAsUser({
        userId: user.id?.toString(),
        timestamp: user.timestamp,
        key: user.key,
      });
      setShowLoginAsModal(true);
    } catch (error) {
      showError(error);
      window.location.reload();
    }
  };

  const downloadUsersList = async () => {
    try {
      setIsDownloading(true);
      const response = await downloadReportData(
        `/metrics/users/download`,
        'users.csv',
        startDate,
        endDate,
        undefined,
        undefined,
        undefined,
        { departmentId: selectedDepartment },
        undefined,
        undefined,
        customReportTypes
      );
      setIsDownloading(false);
      return response;
    } catch (error) {
      setIsDownloading(false);
      showError(error);
    }
  };

  const onSaveCustomTableFields = (fields: Field[]) => {
    localStorage.setItem(
      `${TABLE_NAME_PREFIX + CUSTOM_TABLE_COLUMNS_KEY}`,
      JSON.stringify(fields)
    );
    setReports(fields);
    setShowCustomizeTableFieldsModal(false);
  };

  const onCustomerDropdownChangeHandler = (value: {
    label: string;
    value: number;
  }) => {
    setSelectedDepartment(value.value);
  };

  const users = useMemo(() => {
    return (data?.users || []).map((user: MetricsUser) => ({
      ...user,
      [fixedTableFields.name.value]: getDisplayName([
        user.firstName,
        user.lastName,
      ]),
    }));
  }, [data?.users]);

  const filteredTableFields: TableFieldType[] = useMemo(() => {
    const filteredFields: TableFieldType[] = [];
    reports.forEach(report => {
      const tableField = allDynamicTableFields.find(field => {
        const fieldReportValueKey =
          tableFieldToReportTypeMapper[field.value] || field.value;
        return fieldReportValueKey === report.value;
      });
      if (tableField) {
        filteredFields.push(tableField);
      }
    });
    return filteredFields;
  }, [allDynamicTableFields, reports]);

  const filteredUsers = useMemo(() => {
    const start = page * size;
    return sortUsers(filteredTableFields, users, sortField, sortOrder).slice(
      start,
      start + size
    );
  }, [filteredTableFields, users, sortField, sortOrder, page, size]);

  return (
    <MyTeamWrapper>
      <HelpDesk name={EHelpDesk.MY_TEAM} />
      <Heading m='0 0 32px 0'>My Team</Heading>
      <HeaderWrapper>
        <DateRangeSelector
          initialValue={dateRange}
          onDateRangeChange={onDateRangeChange}
        />
        <Gap gap='12px'>
          <Button
            text='Send'
            icon={<MdEmail size={20} />}
            onClick={() => setShowSendReportModal(true)}
          />
          <Button
            text='Download'
            icon={
              isDownloading ? (
                <Spinner size={8} />
              ) : (
                <MdFileDownload size={20} />
              )
            }
            onClick={() => downloadUsersList()}
            disabled={isDownloading}
          />
        </Gap>
      </HeaderWrapper>
      <CardWrapper>
        <ReportCard
          reportType={reportTypes.RECORDED}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
        />
        <ReportCard
          reportType={reportTypes.SENT}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
        />
        <ReportCard
          reportType={reportTypes.VIEWS}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
        />
        <ReportCard
          reportType={reportTypes.CTA}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
        />
      </CardWrapper>
      <SearchWrapper>
        <Search
          width='300px'
          placeholder='Search users...'
          onSearch={onSearch}
        />
        <Gap>
          <Dropdown
            disabled={isLoadingDepartments || isLoading}
            creatable={false}
            onChange={value => onCustomerDropdownChangeHandler?.(value)}
            options={[
              { value: null, label: 'All' },
              ...(departmentsOptions || []),
            ]}
            value={departmentsOptions?.find(
              dept => dept.value === selectedDepartment
            )}
            extendStyles={{ container: { width: '200px' } }}
            placeholder='All Departments'
            className='dropdown'
          />
          <Button
            icon={<IoMdOptions size={20} />}
            onClick={() => setShowCustomizeTableFieldsModal(true)}
          />
        </Gap>
      </SearchWrapper>
      <div style={{ marginTop: '32px' }}>
        {isLoading && <LoadingIndicator isLoading={isLoading} height='300px' />}
        {!isLoading && (
          <>
            {!!users.length && (
              <TableContextProvider
                total={users.length}
                onChange={onPaginationChange}
                initPage={page}
                initSize={size}
              >
                <div style={{ overflowX: 'auto' }}>
                  <Table
                    compact={true}
                    headers={[
                      <TableCell
                        style={{ paddingLeft: 0 }}
                        onClick={() =>
                          handleSortClick(
                            fixedTableFields.name.orderKey,
                            fixedTableFields.name.defaultOrder
                          )
                        }
                      >
                        {sortField === fixedTableFields.name.orderKey && (
                          <div>
                            {sortOrder === order.ASC && (
                              <MdArrowUpward size={16} />
                            )}
                            {sortOrder === order.DESC && (
                              <MdArrowDownward size={16} />
                            )}
                          </div>
                        )}
                        <div style={{ marginLeft: '4px' }}>
                          {fixedTableFields.name.label}
                        </div>
                      </TableCell>,
                      ...filteredTableFields.map(item => {
                        return (
                          <TableCell
                            onClick={() =>
                              handleSortClick(item.value, item.defaultOrder)
                            }
                          >
                            {sortField === item.value && (
                              <div>
                                {sortOrder === order.ASC && (
                                  <MdArrowUpward size={16} />
                                )}
                                {sortOrder === order.DESC && (
                                  <MdArrowDownward size={16} />
                                )}
                              </div>
                            )}
                            <div style={{ marginLeft: '4px' }}>
                              {item.label}
                            </div>
                          </TableCell>
                        );
                      }),
                      <TableCell>{fixedTableFields.loginAs.label}</TableCell>,
                    ]}
                    hoverable={false}
                    rows={filteredUsers.map((user: any, index: number) => ({
                      key: index,
                      columns: [
                        <TableCell style={{ paddingLeft: 0 }}>
                          <Gap
                            onClick={() => handleUserCellClick(user.id)}
                            style={{ flexGrow: 1 }}
                            gap={'4px'}
                            flexWrap='nowrap'
                          >
                            {user[fixedTableFields.name.value] || '-'}
                            {user.access.toString() ===
                              AccessRole.SUPERVISOR && (
                              <MdRemoveRedEye size={12} title='Supervisor' />
                            )}
                            {user.access.toString() === AccessRole.ADMIN && (
                              <IoKey height={15} title='Admin' />
                            )}
                          </Gap>
                        </TableCell>,
                        ...filteredTableFields.map(item => {
                          if (item.formatter) {
                            return (
                              <TableCell
                                onClick={() => handleUserCellClick(user.id)}
                              >
                                {item.formatter(user[item.value] || 0)}
                              </TableCell>
                            );
                          }
                          if (item.type === 'number') {
                            return (
                              <TableCell
                                onClick={() => handleUserCellClick(user.id)}
                              >
                                {addThousandCommaSeparator(
                                  user[item.value] || 0
                                )}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              onClick={() => handleUserCellClick(user.id)}
                            >
                              {user[item.value] || '-'}
                            </TableCell>
                          );
                        }),
                        <TableCell>
                          <Button
                            variant='transparent'
                            title='Log in as user'
                            icon={<MdLaunch color='#9297A2' size={18} />}
                            onClick={() => handleLoginAsClick(user)}
                            style={{ marginLeft: 'auto' }}
                            disabled={loginAsLoading}
                          />
                        </TableCell>,
                      ],
                    }))}
                  />
                </div>
                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew globalName='users_list' />
                </TableFooter>
              </TableContextProvider>
            )}
            {(!users.length || !filteredTableFields.length) && (
              <div style={{ textAlign: 'center' }}>No data to show.</div>
            )}
          </>
        )}
      </div>

      {showCustomizeTableFieldsModal && (
        <ModalCustomTableFields
          title='Customize Overview users'
          onClose={() => setShowCustomizeTableFieldsModal(false)}
          allFields={allReports}
          initialFields={reports}
          actionButtons={[
            {
              onClick: () => setShowCustomizeTableFieldsModal(false),
              text: 'Back',
              variant: 'secondary',
            },
            {
              onClick: onSaveCustomTableFields,
              text: 'Update',
            },
          ]}
        />
      )}

      {showSendReportModal && (
        <SendReportModal
          handleModalClose={() => {
            setShowSendReportModal(false);
          }}
          startDate={startDate}
          endDate={endDate}
          range={dateRange}
          reportUrl='/metrics/users'
          reports={customReportTypes}
          extraParams={{ departmentId: selectedDepartment }}
        />
      )}

      {showLoginAsModal && (
        <LoginAsModal
          handleModalClose={() => {
            setShowLoginAsModal(false);
            window.location.reload();
          }}
        />
      )}
    </MyTeamWrapper>
  );
};
