import { ICombinedUserData } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { NOTIFICATION_TYPES } from './types';

export const capitalizeFirstLetter = (btnTxt: any) => {
  if (btnTxt === NOTIFICATION_TYPES.EMAIL_NOT_DELIVERED) {
    return 'Email not Delivered';
  }
  if (btnTxt === NOTIFICATION_TYPES.SALES) {
    return 'Test Drive Schedule';
  }
  if (btnTxt === NOTIFICATION_TYPES.REPLY) {
    return 'Video Replies';
  }
  if (btnTxt === NOTIFICATION_TYPES.REACTIONS_COMMENTS) {
    return 'Comments and Reactions';
  }
  if (btnTxt === NOTIFICATION_TYPES.PUSHED_TO_VDP) {
    return 'Video Pushed to VDP';
  }
  if (btnTxt === NOTIFICATION_TYPES.VIDEO_DELETE) {
    return 'Video Delete';
  }
  if (btnTxt === NOTIFICATION_TYPES.LIVE) {
    return 'Meetings';
  }
  if (btnTxt === NOTIFICATION_TYPES.EMAIL_OPENED) {
    return 'Email Opened';
  }
  if (btnTxt === NOTIFICATION_TYPES.VIDEO_REQUEST) {
    return 'Video Request';
  }
  if (btnTxt === NOTIFICATION_TYPES.VIDEO_REQUEST_RESPONSE) {
    return 'Video Request Response';
  }
  if (btnTxt === NOTIFICATION_TYPES.VIEW) {
    return 'Video Viewed';
  }
  if (btnTxt === NOTIFICATION_TYPES.CTA) {
    return 'CTA Clicked';
  }
  if (btnTxt === NOTIFICATION_TYPES.QUOTES) {
    return 'Quote Updates';
  }
  if (btnTxt === NOTIFICATION_TYPES.FILES) {
    return 'File Shared';
  }
  if (btnTxt === NOTIFICATION_TYPES.BOARDS) {
    return 'Board Shared';
  }
  return btnTxt.charAt(0).toUpperCase() + btnTxt.slice(1).toLowerCase();
};

export const getViewBtnText = (
  type: any,
  meta: any,
  userData: ICombinedUserData
) => {
  const notificationType = type.toUpperCase();

  if (
    notificationType === NOTIFICATION_TYPES.EMAIL_NOT_DELIVERED ||
    notificationType === NOTIFICATION_TYPES.VIEW
  ) {
    const hasAnalytics = checkIfFeatureIsEnabled(
      userData,
      productFeature.HOTSPOTS_AND_ANALYTICS
    );
    return hasAnalytics ? 'View Analytics' : 'Upgrade to Pro';
  }
  if (notificationType === NOTIFICATION_TYPES.CTA) {
    const hasCtas = checkIfFeatureIsEnabled(userData, productFeature.CTAS);
    return hasCtas ? 'View Analytics' : 'Upgrade to Pro';
  }

  if (notificationType === NOTIFICATION_TYPES.SALES) {
    return 'View Drive Info';
  }
  if (notificationType === NOTIFICATION_TYPES.REPLY) {
    return 'View Video Reply';
  }
  if (notificationType === NOTIFICATION_TYPES.REACTIONS_COMMENTS) {
    // get button text based on reaction type
    switch (meta.reactionType) {
      case '1':
        return 'View Comments';
      case '2':
        return 'View Reactions';
      case '3':
        return 'View Comments & Reactions';
      default:
        return '';
    }
  }
  if (notificationType === NOTIFICATION_TYPES.PUSHED_TO_VDP) {
    return 'View pushed to VDP page';
  }

  if (notificationType === NOTIFICATION_TYPES.LIVE) {
    return 'Join Meeting';
  }

  if (notificationType === NOTIFICATION_TYPES.REPORTS) {
    return 'Download Report';
  }

  if (notificationType === NOTIFICATION_TYPES.VIDEO_DELETE) {
    return 'Review request';
  }

  if (notificationType === NOTIFICATION_TYPES.EMAIL_OPENED) {
    return 'View Email Opened';
  }

  if (notificationType === NOTIFICATION_TYPES.VIDEO_REQUEST) {
    return 'Review Request';
  }

  if (notificationType === NOTIFICATION_TYPES.VIDEO_REQUEST_RESPONSE) {
    return 'View Video';
  }

  let btnTxt = 'View';
  let appendTxt = capitalizeFirstLetter(type);
  return btnTxt + ' ' + appendTxt.slice(0, -1);
};
