import { getCurrentEnvUrl } from 'configs/api/enviroments';
import {
  COVIDEO_APP_URL,
  LANDING_PAGE_URL,
  LIVE_PAGE_URL,
} from 'configs/domains/types';
import { useAuth } from 'lib/context';

export const URL_MODE = {
  LIVE: 'LIVE',
  LANDING_PAGE: 'LANDING_PAGE',
  COVIDEO: 'COVIDEO',
} as const;

type UrlMode = keyof typeof URL_MODE;

const covideoAppDefaultUrl = process.env.REACT_APP_APPURL;

const buildUrlConfig = (urlMode: UrlMode, whiteLabelURL?: string) => {
  switch (urlMode) {
    case URL_MODE.LIVE:
      return {
        defaultValue: LIVE_PAGE_URL.DEVELOPMENT,
        development: LIVE_PAGE_URL.DEVELOPMENT,
        sandbox: LIVE_PAGE_URL.SANDBOX,
        staging: whiteLabelURL ? `${whiteLabelURL}/` : LIVE_PAGE_URL.STAGING,
        production: whiteLabelURL
          ? `${whiteLabelURL}/`
          : LIVE_PAGE_URL.PRODUCTION,
      };

    case URL_MODE.LANDING_PAGE: {
      return {
        defaultValue: LANDING_PAGE_URL.DEVELOPMENT,
        development: LANDING_PAGE_URL.DEVELOPMENT,
        sandbox: LANDING_PAGE_URL.SANDBOX,
        sustaining: LANDING_PAGE_URL.SUSTAINING,
        staging: whiteLabelURL || LANDING_PAGE_URL.STAGING,
        production: whiteLabelURL || LANDING_PAGE_URL.PRODUCTION,
      };
    }

    case URL_MODE.COVIDEO:
      return {
        defaultValue: covideoAppDefaultUrl || COVIDEO_APP_URL.PRODUCTION,
        development: '',
        staging: covideoAppDefaultUrl || COVIDEO_APP_URL.STAGING,
        sandbox: covideoAppDefaultUrl || COVIDEO_APP_URL.SANDBOX,
        sustaining: covideoAppDefaultUrl || COVIDEO_APP_URL.SUSTAINING,
        production: covideoAppDefaultUrl || COVIDEO_APP_URL.PRODUCTION,
      };

    default:
      throw new Error(`Unsupported environment type: ${urlMode}`);
  }
};

const getCurrentLiveUrl = (liveURL?: string): string => {
  const config = buildUrlConfig(URL_MODE.LIVE, liveURL);
  return getCurrentEnvUrl(config);
};

const getCurrentCovideoUrl = (): string => {
  const config = buildUrlConfig(URL_MODE.COVIDEO);
  return getCurrentEnvUrl(config);
};

const getCurrentLandingPageUrl = (landingPageURL?: string): string => {
  const config = buildUrlConfig(URL_MODE.LANDING_PAGE, landingPageURL);
  return getCurrentEnvUrl(config);
};

export const useCurrentAppUrl = () => {
  const {
    whitelabel: { liveURL, landingPageURL },
  } = useAuth();

  return {
    livePageUrl: getCurrentLiveUrl(liveURL),
    covideoAppUrl: getCurrentCovideoUrl(),
    landingPageUrl: getCurrentLandingPageUrl(landingPageURL),
  };
};
