import {
  IGroups,
  useLeadGroupsQuery,
} from 'lib/api/leadGroups/useLeadGroupsQuery';
import { LoadingIndicator, Search } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import { MdAdd } from 'react-icons/md';
import styled from 'styled-components/macro';
import { FixedCounterHeader } from '../components/FixedCounterHeader';
import { GroupTable } from '../components/GroupTable';
import { ModalDeleteGroupLeads } from '../components/ModalDeleteGroupLeads';
import { ModalGroupForm } from '../components/ModalGroupForm';

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 530px;
`;

const DEFAULT_PAGE = 0;
const DEFAULT_SIZE = 10;

export const CustomerLeadsGroupsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isGroupModalVisible, setIsGroupModalVisible] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [editGroup, setEditGroup] = useState<null | IGroups>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [leadGroupIds, setLeadGroupIds] = useState<number[]>([]);

  const params = {
    start: page * DEFAULT_SIZE,
    limit: DEFAULT_SIZE,
    page,
    search: searchTerm,
  };
  const { data, isLoading } = useLeadGroupsQuery(params);

  const resetPage = () => {
    setPage(0);
  };

  const onSearchChangeHandler = async (search: string = '') => {
    setSearchTerm(search);
    resetPage();
  };

  const onPaginationChangeHandler = debounce(
    async ({ page: newPage }: { page: number }) => {
      if (page !== newPage) {
        setPage(newPage);
      }
    },
    300
  );

  const onSelectedGroupHandler = (group: IGroups) => {
    setEditGroup(group);
    setIsGroupModalVisible(true);
  };
  const onModalCloseHandler = () => {
    setIsGroupModalVisible(false);
    setIsDeleteModalVisible(false);
  };

  const onSuccessCallback = () => {
    setEditGroup(null);
    setLeadGroupIds([]);
  };

  const onDeleteGroupHandler = () => {
    setIsDeleteModalVisible(true);
  };

  const handleBackNavigation = () => {
    if (page === 0) {
      return;
    }
    if (data?.groups?.length === leadGroupIds.length) {
      return setPage(prevState => prevState - 1);
    }
  };

  const groupsExist = data && data?.groups?.length > 0;

  return (
    <>
      <FixedCounterHeader
        selectedIds={leadGroupIds}
        setSelectedIds={setLeadGroupIds}
        groupsArray={data?.groups.map(id => id.leadGroupId) || []}
        onDeleteGroupHandler={onDeleteGroupHandler}
        label='Groups selected'
      />
      <Gap m='0 0 20px auto' justifyContent='space-between' width='100%'>
        <Search
          placeholder='Search groups...'
          onSearch={onSearchChangeHandler}
          width='300px'
        />
        <Button
          text={'Create'}
          type='button'
          onClick={() => setIsGroupModalVisible(true)}
          icon={<MdAdd />}
        />
      </Gap>
      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={isLoading} />
        </div>
      ) : (
        <>
          {!groupsExist ? (
            <>
              {searchTerm !== '' ? (
                <EmptySearch>
                  <ParagraphSmallBold color={theme.palette.blue60}>
                    No groups match your current filter
                  </ParagraphSmallBold>
                  <ParagraphSmallBold color={theme.palette.blue60}>
                    Try adjusting your filters to find groups
                  </ParagraphSmallBold>
                </EmptySearch>
              ) : (
                <EmptySearch>
                  <ParagraphSmallBold color={theme.palette.blue60}>
                    Unfortunately, there aren't any groups available at the
                    moment.
                  </ParagraphSmallBold>
                </EmptySearch>
              )}
            </>
          ) : (
            <GroupTable
              count={data?.count || 0}
              page={page}
              size={DEFAULT_SIZE}
              groups={data?.groups || []}
              onPaginationChangeHandler={onPaginationChangeHandler}
              onSelectedGroupHandler={onSelectedGroupHandler}
              setLeadGroupIds={setLeadGroupIds}
              leadGroupIds={leadGroupIds}
            />
          )}
        </>
      )}
      {isGroupModalVisible && (
        <ModalGroupForm
          onModalClose={onModalCloseHandler}
          editGroup={editGroup}
          onSuccessCallback={onSuccessCallback}
        />
      )}
      {isDeleteModalVisible && (
        <ModalDeleteGroupLeads
          count={leadGroupIds.length}
          onModalClose={onModalCloseHandler}
          leadGroupIds={leadGroupIds}
          onSuccessCallback={onSuccessCallback}
          handleBackNavigation={handleBackNavigation}
        />
      )}
    </>
  );
};
