import React, { useEffect, useState } from 'react';
import { Button } from 'react-covideo-common';
import {
  CheckboxInput,
  LoadingIndicator,
  NewModal,
  Search,
} from 'lib/components';
import {
  fetchCustomerUsers,
  getAllDepartmentsForCustomer,
  getProfileData,
} from 'lib/api';
import { useAuth } from 'lib/context';
import { DepartmentsCheckboxDropdown } from 'app/pages/reports/components/DepartmentsCheckboxDropdown';
import {
  dropdownStylesEdit,
  EditContent,
  Footer,
  Menu,
  ScrollAbleUserArea,
  SharedWith,
  ShareToWrapper,
  UserDetails,
  UserEditActionsWrapper,
  UserEditItem,
  UsersEditList,
  UserTitle,
} from './style';
import { dropdownAccessData } from './data';
import Select from 'react-select/';
import { MdSupervisorAccount } from 'react-icons/md';
import {
  ACCESS_RIGHTS,
  FolderAccessResponse,
} from 'lib/api/folderAccess/types';
import { useUpdateFolderAccess } from 'lib/api/folderAccess/updateFolderAccess';
import { ShareFoldersModal } from './ShareFoldersModal';
import { IUsersCustomersResponse } from 'lib/api/users/getUsersCustomers';
import { DepartmentsData, DepartmentsResponse } from './types';
import { successToast } from 'lib/components/toasts/success';
import { Folder } from 'lib/api/folders/getFolders';
import { Gap } from '../styles/layout';
import { CheckboxLabel } from '../styles/typography';
import { SHARE_FOLDER_NOTIFY_CHECKBOX_LABEL } from 'lib/const/Folders';

type Option = {
  value: string;
  label: string;
};

type Props = {
  initUsers?: string[];
  handleModalClose: (folderId?: string) => void;
  accessUsers: FolderAccessResponse[];
  folderId: string;
  isResellerAdmin: boolean;
  folder?: Folder;
};

type Department = {
  label: string;
  value: string;
};

export const ShareFoldersEditModal = (props: Props) => {
  const {
    userData: { customerId, isResellerAdmin },
  } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const [sendNotification, setSendNotification] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<IUsersCustomersResponse[]>(
    []
  );
  const [tempFilteredUsers, setTempFilteredUsers] = useState<
    IUsersCustomersResponse[]
  >([]);
  const [accessUsers, setAccessUsers] = useState<IUsersCustomersResponse[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [showShareFoldersUsers, setShowShareFoldersUsers] =
    useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(
    !!props.folder?.includeNewUsers
  );
  const [folderAccessPermission, setGlobalPermission] = useState<string>(
    props.folder?.folderAccessPermission || 'view'
  );
  const [searchQuery, setSearchQuery] = useState('');
  const {
    mutateAsync: updateFolderAccess,
    isLoading: isLoadingUpdateFolderAccess,
  } = useUpdateFolderAccess();

  const { handleModalClose } = props;

  const expandedOnModalClose = async () => {
    setNewUsers([]);
    await fetchUsers();
    handleModalClose();
  };
  const [newUsers, setNewUsers] = useState<IUsersCustomersResponse[]>([]);
  const [initialUsers, setInitialUsers] = useState<IUsersCustomersResponse[]>(
    []
  );
  useEffect(() => {
    onSearch();
  }, [searchQuery, selectedDepartments]);

  const fetch = async (customerId: string) => {
    const departmentResponse: DepartmentsResponse =
      await getAllDepartmentsForCustomer(customerId);
    const options = [] as Option[];
    (departmentResponse.data || []).forEach((department: DepartmentsData) => {
      if (department.id && department.name.trim()) {
        options.push({
          value: department.id.toString(),
          label: department.name,
        });
      }
    });
    options.sort((a, b) => a.label.localeCompare(b.label));
    setDepartments(options);
  };

  useEffect(() => {
    if (customerId) {
      fetch(customerId);
    }
  }, [customerId]);

  const fetchUsers = async () => {
    setLoading(true);
    const accessIds = props.accessUsers.map(access => access.userId);

    let users: IUsersCustomersResponse[] = [];
    // fetch user data - can be from different customers
    if (isResellerAdmin) {
      await Promise.allSettled(
        accessIds.map(async (userId: number) => {
          const userObject = await getProfileData(userId);
          if (userObject) users.push(userObject);
        })
      );
    } else {
      users = await fetchCustomerUsers(customerId, true);
    }
    users.sort(
      (userA: IUsersCustomersResponse, userB: IUsersCustomersResponse) => {
        if (userA.firstName < userB.firstName) {
          return -1;
        }
        if (userA.firstName > userB.firstName) {
          return 1;
        }
        return 0;
      }
    );

    const arr = users
      .filter(
        (item: IUsersCustomersResponse) => accessIds.indexOf(item.id) !== -1
      )
      .map(accessItem => ({
        ...accessItem,
        access: props.accessUsers.find(
          accessUser => accessUser.userId === accessItem.id
        )?.access,
      }));

    setFilteredUsers(arr.map(user => ({ ...user, removed: false })));
    setTempFilteredUsers(arr.map(user => ({ ...user, removed: false })));
    setAccessUsers(arr);
    setInitialUsers(arr.map(user => ({ ...user, removed: false })));
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const onSearch = () => {
    const search = searchQuery.toLowerCase();
    let filtered = [...tempFilteredUsers];

    const shouldFilterBySearchTerm = !!searchQuery.length;
    let shouldFilterByDepartments = true;

    if (
      !selectedDepartments.length ||
      selectedDepartments.length === departments.length
    )
      shouldFilterByDepartments = false;

    // filter departments
    if (shouldFilterByDepartments) {
      filtered = filtered.filter(user =>
        selectedDepartments.includes(user.DeptId?.toString() || '')
      );
    }
    // filter search
    if (shouldFilterBySearchTerm) {
      filtered = filtered.filter(user =>
        `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(
          search
        )
      );
    }

    setFilteredUsers(filtered);
    setInitialUsers(filtered);
  };

  const sharedWithText = `Shared with ${
    tempFilteredUsers.filter(user => !user.removed).length
  } ${accessUsers.length === 1 ? 'user' : 'users'}`;

  const handleAccessDetailsChange = (e: any, userId: number) => {
    const updatedFilteredAccess = filteredUsers.map(
      (filter: IUsersCustomersResponse) => {
        if (filter.id === userId) {
          return { ...filter, access: e.value };
        }
        return filter;
      }
    );
    const updatedFilteredTempAccess = tempFilteredUsers.map(
      (filter: IUsersCustomersResponse) => {
        if (filter.id === userId) {
          return { ...filter, access: e.value };
        }
        return filter;
      }
    );

    setFilteredUsers(updatedFilteredAccess);
    setTempFilteredUsers(updatedFilteredTempAccess);
    setInitialUsers(updatedFilteredAccess);
  };

  const handleUpdateFolderAccess = async () => {
    const accessObject = {
      folderId: parseInt(props.folderId),
      users: tempFilteredUsers
        .filter(u => !u.removed)
        .map((user: IUsersCustomersResponse) => ({
          userId: user.id,
          access: user.access as ACCESS_RIGHTS,
        })),
      isSelectAll,
      folderAccessPermission,
      sendNotification,
    };

    const access = await updateFolderAccess(accessObject);
    if (access) {
      successToast({ title: 'Successfully Edited Access Rights' });
      await expandedOnModalClose();
    }
  };

  const handleRemoveOrUndoUser = (userId: number, status: boolean) => {
    const removed = filteredUsers.map((filter: IUsersCustomersResponse) => {
      if (filter.id === userId) {
        return { ...filter, removed: status };
      }
      return filter;
    });
    const removedTemp = tempFilteredUsers.map(
      (filter: IUsersCustomersResponse) => {
        if (filter.id === userId) {
          return { ...filter, removed: status };
        }
        return filter;
      }
    );
    setFilteredUsers(removed);
    setInitialUsers(removed);
    setTempFilteredUsers(removedTemp);
  };

  const handleOpenModal = () => {
    setShowShareFoldersUsers(true);
  };

  useEffect(() => {
    if (!showShareFoldersUsers) {
      // add to accessUsers and to fitered users
      setAccessUsers([...newUsers]);
      setFilteredUsers([...newUsers]);
      setTempFilteredUsers([...newUsers]);
      setInitialUsers([...newUsers]);
    }
    setIsSelectAll(isSelectAll);
    setGlobalPermission(folderAccessPermission);
  }, [newUsers, showShareFoldersUsers]);

  if (showShareFoldersUsers) {
    return (
      <ShareFoldersModal
        handleModalClose={() => setShowShareFoldersUsers(false)}
        viewers={filteredUsers.map((user: IUsersCustomersResponse) => user.id)}
        folderId={props.folderId}
        setSelection={setNewUsers}
        skipCreateOnSave
        folder={props.folder}
        setIsSelectAll={setIsSelectAll}
        setGlobalPermission={setGlobalPermission}
        initialUsers={initialUsers}
        noSelectedUserAllowed={true}
      />
    );
  }

  const isLoading = loading || isLoadingUpdateFolderAccess;

  return (
    <NewModal
      headerText='Edit Folder Access'
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={expandedOnModalClose}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minHeight: '592px',
          paddingLeft: '32px',
          boxSizing: 'border-box',
        },
      }}
    >
      <div>
        {isLoading ? (
          <LoadingIndicator isLoading={true} />
        ) : (
          <>
            <ShareToWrapper>
              <SharedWith>{sharedWithText}</SharedWith>
              <Button
                variant='secondary'
                size='small'
                icon={<MdSupervisorAccount size={24} />}
                text={'Share to...'}
                onClick={handleOpenModal}
              />
            </ShareToWrapper>

            <EditContent>
              <>
                <Menu>
                  <div style={{ width: '296px' }}>
                    <Search
                      placeholder='Search users...'
                      onSearch={(searchTerm: string) =>
                        setSearchQuery(searchTerm)
                      }
                      bgColor='#fffff'
                    />
                  </div>
                  <DepartmentsCheckboxDropdown
                    departments={departments}
                    onDepartmentsSelected={departments => {
                      setSelectedDepartments(departments);
                    }}
                    selectInputWidth='224px'
                    menuShouldBlockScroll={false}
                  />
                </Menu>
                <div style={{ overflow: 'hidden' }}>
                  <UsersEditList>
                    <ScrollAbleUserArea
                      key={customerId}
                      style={{ height: 295 }}
                      id='scrollContainer'
                    >
                      {filteredUsers.map((user: IUsersCustomersResponse) => (
                        <UserEditItem key={user.id} removed={user.removed}>
                          <UserDetails>
                            <UserTitle width='140px'>
                              {`${user.firstName} ${user.lastName}`}
                            </UserTitle>
                            <UserEditActionsWrapper>
                              <Select
                                menuShouldBlockScroll={true}
                                closeMenuOnScroll={(event: any) => {
                                  // Dropdown remains fixed in place and does not scroll with parent container so this function is added to close menu on scroll so it's a great workaround since there is no valid solution available
                                  return event.target?.id === 'scrollContainer';
                                }}
                                isDisabled={user.removed}
                                menuPosition='absolute'
                                menuPortalTarget={document.body}
                                menuPlacement='bottom'
                                isSearchable={false}
                                placeholder='Select Option'
                                value={dropdownAccessData.find(
                                  item => item.value === user.access
                                )}
                                options={dropdownAccessData}
                                onChange={e =>
                                  handleAccessDetailsChange(e, user.id)
                                }
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                styles={dropdownStylesEdit}
                              />
                              {!user.removed && (
                                <Button
                                  text='Remove'
                                  variant='destructive'
                                  size='small'
                                  onClick={() =>
                                    handleRemoveOrUndoUser(user.id, true)
                                  }
                                />
                              )}
                              {user.removed && (
                                <Button
                                  text='Undo'
                                  size='small'
                                  variant='secondary'
                                  onClick={() =>
                                    handleRemoveOrUndoUser(user.id, false)
                                  }
                                />
                              )}
                            </UserEditActionsWrapper>
                          </UserDetails>
                        </UserEditItem>
                      ))}
                    </ScrollAbleUserArea>
                  </UsersEditList>
                </div>
                <Footer marginTop='12px'>
                  <Gap gap='12px'>
                    <CheckboxInput
                      id='toggleSendNotification'
                      disabled={false}
                      checked={sendNotification}
                      onChange={() => setSendNotification(prev => !prev)}
                    />
                    <CheckboxLabel htmlFor='toggleSendNotification'>
                      {SHARE_FOLDER_NOTIFY_CHECKBOX_LABEL}
                    </CheckboxLabel>
                  </Gap>
                  <Button
                    text='Cancel'
                    variant='secondary'
                    onClick={expandedOnModalClose}
                  />
                  <Button
                    text='Save changes'
                    variant='primary'
                    onClick={handleUpdateFolderAccess}
                  />
                </Footer>
              </>
            </EditContent>
          </>
        )}
      </div>
    </NewModal>
  );
};
