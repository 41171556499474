import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { leadOpportunitiesKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface LeadOpportunitiesFiltersResponse {
  filters: Filter[];
}

export interface Filter {
  key: string;
  type: string;
  label: string;
  name: string;
  options: { value: string; label: string }[];
}

export interface Option {
  label: string;
  value: string;
}

const getLeadOpportunuties =
  async (): Promise<LeadOpportunitiesFiltersResponse> => {
    return (await EXPRESS_API.get(`/lead-opportunities/filters`)).data;
  };

export const useLeadOpportunutiesFiltersQuery = () => {
  const { showError } = useToastError();

  return useQuery(
    leadOpportunitiesKeys.filters(),
    () => getLeadOpportunuties(),
    {
      onError: showError,
      refetchOnMount: true,
    }
  );
};
