import {
  FormProps,
  RowWrapper,
  Section,
  SectionTitle,
} from 'app/pages/admin/components/AdminFormComponents';
import { FormikSwitchField } from 'lib/components/formik/FormikSwitchField';
import React from 'react';

export const VdpSection = (props: FormProps) => {
  const { setFieldValue, values } = props;
  return (
    <Section>
      <SectionTitle>VDP</SectionTitle>
      <RowWrapper>
        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='vdpHomeNetEnabled'
          label='HomeNet'
          onChange={() => {
            setFieldValue(
              'vdpHomeNetEnabled',
              values.vdpHomeNetEnabled ? 0 : 1
            );
          }}
        />
        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='vdpIPacketEnabled'
          label='IPacket'
          onChange={() => {
            setFieldValue(
              'vdpIPacketEnabled',
              values.vdpIPacketEnabled ? 0 : 1
            );
          }}
        />
        <FormikSwitchField
          wrapper={{ margin: '0 0 16px 0' }}
          name='jdPowerVDPEnabled'
          label='JDPower'
          onChange={() => {
            setFieldValue(
              'jdPowerVDPEnabled',
              values.jdPowerVDPEnabled ? 0 : 1
            );
          }}
        />
      </RowWrapper>
    </Section>
  );
};
