import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button } from 'react-covideo-common';
import {
  CheckboxInput,
  LoadingIndicator,
  NewModal,
  Search,
} from 'lib/components';
import { theme } from 'lib/style';
import { getAllDepartmentsForCustomer } from 'lib/api';
import { useAuth } from 'lib/context';
import { DepartmentsCheckboxDropdown } from 'app/pages/reports/components/DepartmentsCheckboxDropdown';
import {
  Content,
  Counter,
  dropdownStyles,
  Footer,
  FormWrapper,
  Label,
  Left,
  ListPrivacyWrapper,
  ListWrapper,
  Menu,
  PrivacyLevelText,
  PrivacyWrapper,
  Right,
  SelectAllText,
  SelectAllWrapper,
  SelectAndCount,
  SelectionCountText,
  Textarea,
  TitleChip,
  UserDetails,
  UserEmail,
  UserItem,
  UsersList,
  UserTitle,
} from './style';
import Select from 'react-select';
import { dropdownAccessData } from './data';
import { useCreateFolderAccess } from 'lib/api/folderAccess/createFolderAccess';
import { ACCESS_RIGHTS } from 'lib/api/folderAccess/types';
import { Chip } from 'app/pages/account/userAccount/socialProfiles/style';
import {
  IUsersCustomersResponse,
  useGetUsersCustomerQuery,
} from 'lib/api/users/getUsersCustomers';
import { DepartmentsData, DepartmentsResponse } from './types';
import { successToast } from 'lib/components/toasts/success';
import { Folder } from 'lib/api/folders/getFolders';
import { Gap } from '../styles/layout';
import { CheckboxLabel } from '../styles/typography';
import { SHARE_FOLDER_NOTIFY_CHECKBOX_LABEL } from 'lib/const/Folders';

type Option = {
  value: string;
  label: string;
};

type Props = {
  initUsers?: string[];
  handleModalClose: (folderId?: string) => void;
  folderId: string;
  viewers?: number[];
  setSelection?: (arg: IUsersCustomersResponse[]) => void;
  skipCreateOnSave?: boolean;
  folder?: Folder;
  setIsSelectAll?: (value: boolean) => void;
  setGlobalPermission?: (value: string) => void;
  initialUsers?: IUsersCustomersResponse[];
  noSelectedUserAllowed?: boolean;
};

type Selection = {
  [key: string]: boolean;
};

export type Department = {
  label: string;
  value: string;
};

export const ShareFoldersModal = (props: Props) => {
  const {
    userData: { customerId, userId },
  } = useAuth();
  const {
    mutateAsync: createFolderAccess,
    isLoading: isLoadingCreateFolderAccess,
  } = useCreateFolderAccess();

  const [users, setUsers] = useState<IUsersCustomersResponse[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUsersCustomersResponse[]>(
    []
  );
  const [selectedUsers, setSelectedUsers] = useState({} as Selection);

  const [departments, setDepartments] = useState([] as Department[]);
  const [selectedDepartments, setSelectedDepartments] = useState(
    [] as string[]
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAccess, setSelectedAccess] = useState(dropdownAccessData[0]);
  const [message, setMessage] = useState<string>('');
  const [selectAll, setSelectAll] = React.useState(false);
  const [isFutureUsersIncluded, setIsFutureUsersIncluded] = useState(
    !!props.folder?.includeNewUsers
  );
  const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false);
  const [sendNotification, setSendNotification] = useState<boolean>(false);

  const { handleModalClose, initUsers = [], viewers = [] } = props;

  const handleCreateFolderAccess = async () => {
    const userIds = Object.keys(selectedUsers);
    const accessObject = {
      folderId: parseInt(props.folderId),
      users: userIds.map(id => ({
        userId: parseInt(id),
        access: selectedAccess.value as ACCESS_RIGHTS,
      })),
      message,
      isSelectAll: isFutureUsersIncluded,
      folderAccessPermission: selectedAccess.value,
      sendNotification,
    };

    const access = await createFolderAccess(accessObject);
    if (access) {
      successToast({ title: 'Successfully shared folder!' });
      handleModalClose();
    }
  };

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const isViewer = (userId: number) => {
    const exists = viewers.find(user => user === userId);
    return exists ? true : false;
  };

  useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  useEffect(() => {
    const selected = { ...selectedUsers };

    if (initUsers.length) {
      initUsers.forEach((userId: string) => {
        selected[userId] = true;
      });

      setSelectedUsers(selected);
    }
  }, [initUsers]);

  useEffect(() => {
    onSearch();
  }, [searchQuery, selectedDepartments]);

  useEffect(() => {
    const selected = { ...selectedUsers };
    if (viewers.length) {
      viewers.forEach((userId: number) => {
        selected[userId] = true;
      });

      setSelectedUsers(selected);
    }
    if (props.setIsSelectAll) {
      props.setIsSelectAll(isFutureUsersIncluded);
    }
  }, []);

  useEffect(() => {
    if (props.setSelection) {
      const userIds = Object.keys(selectedUsers);
      const userObjects = users
        .filter(user => userIds.includes(user.id.toString()))
        .map(user => ({ ...user, access: selectedAccess.value }));

      props.setSelection(userObjects);
    }
  }, [selectedUsers, selectedAccess, users]);

  const fetch = async (customerId: string) => {
    const departmentResponse: DepartmentsResponse =
      await getAllDepartmentsForCustomer(customerId);
    const options = [] as Option[];
    (departmentResponse.data || []).forEach((department: DepartmentsData) => {
      if (department.id && department.name.trim()) {
        options.push({
          value: department.id.toString(),
          label: department.name,
        });
      }
    });
    options.sort((a, b) => a.label.localeCompare(b.label));
    setDepartments(options);
  };

  useEffect(() => {
    if (customerId && !departments.length) {
      fetch(customerId);
    }
  }, [customerId]);

  const {
    data: usersCustomer,
    isLoading: loadingUsers,
    refetch: refetchUsers,
  } = useGetUsersCustomerQuery({ customerId });

  useEffect(() => {
    refetchUsers();
  }, []);

  useEffect(() => {
    if (usersCustomer && usersCustomer.length > 0) {
      const filterUsers = usersCustomer?.sort(
        (userA: IUsersCustomersResponse, userB: IUsersCustomersResponse) => {
          if (userA.firstName < userB.firstName) {
            return -1;
          }
          if (userA.firstName > userB.firstName) {
            return 1;
          }
          return 0;
        }
      );
      // Remove logged user in order to avoid sharing a folder with yourself
      const withoutLoggedUser = filterUsers.filter(
        (item: { id: number }) => item.id !== parseInt(userId)
      );
      setUsers(withoutLoggedUser);
      setFilteredUsers(withoutLoggedUser);
    }
  }, [usersCustomer]);

  const onSearch = () => {
    const search = searchQuery.toLowerCase();
    let filtered = [...users];

    const shouldFilterBySearchTerm = !!searchQuery.length;
    let shouldFilterByDepartments = true;

    if (
      !selectedDepartments.length ||
      selectedDepartments.length === departments.length
    )
      shouldFilterByDepartments = false;

    // filter departments
    if (shouldFilterByDepartments) {
      filtered = filtered.filter(user =>
        selectedDepartments.includes(user.DeptId?.toString() || '')
      );
    }
    // filter search
    if (shouldFilterBySearchTerm) {
      filtered = filtered.filter(user =>
        `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(
          search
        )
      );
    }

    setFilteredUsers(filtered);
  };

  const toggleUser = (userId: string) => {
    const selected = { ...selectedUsers };

    if (selected[userId]) {
      delete selected[userId];
    } else {
      selected[userId] = true;
    }
    setSelectedUsers(selected);
  };

  const count = Object.keys(selectedUsers).length;

  const checkIfAllFilteredUsersAreSelected = () => {
    return Object.keys(selectedUsers).length + 1 === usersCustomer?.length;
  };

  const handleChange = (e: any) => {
    setSelectedAccess(e);
    if (props.setGlobalPermission) {
      props.setGlobalPermission(e.value);
    }
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const toggleSelectAll = () => {
    const value = !selectAll;
    const selected = { ...selectedUsers };
    if (value) {
      filteredUsers.forEach((user: IUsersCustomersResponse) => {
        selected[user.id] = true;
      });
    } else {
      filteredUsers.forEach((user: IUsersCustomersResponse) => {
        delete selected[user.id];
      });
    }
    setSelectAll(value);
    setSelectedUsers(selected);
  };

  const toggleFutureUsers = () => {
    const value = !isFutureUsersIncluded;
    setIsFutureUsersIncluded(value);
    if (props.setIsSelectAll) {
      props.setIsSelectAll(value);
    }
  };

  useEffect(() => {
    checkIfAllFilteredUsersAreSelected()
      ? setSelectAll(true)
      : setSelectAll(false);
  }, [selectedUsers, filteredUsers]);

  const isLoading = loadingUsers || isLoadingCreateFolderAccess;

  return (
    <NewModal
      headerText='Share folder'
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Content>
        {isLoading && <LoadingIndicator isLoading={true} height='300px' />}
        {!isLoading && (
          <>
            <Menu>
              <div style={{ width: '392px' }}>
                <Search
                  placeholder='Search users...'
                  onSearch={(searchTerm: string) => setSearchQuery(searchTerm)}
                  bgColor='#fffff'
                />
              </div>
              <DepartmentsCheckboxDropdown
                departments={departments}
                onDepartmentsSelected={departments => {
                  setSelectedDepartments(departments);
                }}
                showMenu={showDepartmentDropdown}
                setShowMenu={setShowDepartmentDropdown}
                menuShouldBlockScroll={false}
              />
            </Menu>
            <SelectAndCount>
              <SelectAllWrapper>
                <CheckboxInput
                  disabled={false}
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
                <SelectAllText>Select all</SelectAllText>
                <CheckboxInput
                  disabled={false}
                  checked={isFutureUsersIncluded}
                  onChange={toggleFutureUsers}
                />
                <SelectAllText>Include New Users</SelectAllText>
              </SelectAllWrapper>
              <SelectionCountText>
                <Counter>{count} selected</Counter>
              </SelectionCountText>
            </SelectAndCount>
            <ListPrivacyWrapper>
              <Left>
                <ListWrapper>
                  <UsersList>
                    {filteredUsers.map((user: IUsersCustomersResponse) => (
                      <UserItem key={user.id} disabled={false}>
                        <CheckboxInput
                          checked={!!selectedUsers[user.id]}
                          onChange={() => {
                            toggleUser(user.id.toString());
                          }}
                          disabled={false}
                        />
                        <UserDetails>
                          <TitleChip>
                            <UserTitle
                              onClick={() => {
                                toggleUser(user.id.toString());
                              }}
                              width='fit-content'
                            >
                              {`${user.firstName} ${user.lastName}`}
                            </UserTitle>
                            {isViewer(user.id) && (
                              <Chip
                                bgColor={'#E6E8EE'}
                                fontColor={theme.palette.covideoBlue80}
                              >
                                Viewer
                              </Chip>
                            )}
                          </TitleChip>
                          <UserEmail>{user.email}</UserEmail>
                        </UserDetails>
                      </UserItem>
                    ))}
                  </UsersList>
                </ListWrapper>
              </Left>
              <Right>
                <PrivacyWrapper>
                  <PrivacyLevelText>
                    Set privacy level for selected users
                  </PrivacyLevelText>
                  <FormWrapper>
                    <Label>Access</Label>
                    <Select
                      isSearchable={false}
                      placeholder='Select Option'
                      value={selectedAccess}
                      options={dropdownAccessData}
                      onChange={handleChange}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={dropdownStyles}
                    />
                  </FormWrapper>
                  <FormWrapper>
                    <Label>Add Message (optional)</Label>
                    <Textarea
                      value={message}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleTextChange(e);
                      }}
                    />
                  </FormWrapper>
                </PrivacyWrapper>
              </Right>
            </ListPrivacyWrapper>
            <Footer>
              <Gap gap='12px'>
                <CheckboxInput
                  id='toggleSendNotification'
                  disabled={isLoadingCreateFolderAccess}
                  checked={sendNotification}
                  onChange={() => setSendNotification(prev => !prev)}
                />
                <CheckboxLabel htmlFor='toggleSendNotification'>
                  {SHARE_FOLDER_NOTIFY_CHECKBOX_LABEL}
                </CheckboxLabel>
              </Gap>
              <Button
                text='Cancel'
                variant='secondary'
                onClick={() => {
                  handleModalClose();
                  if (props.setSelection)
                    props.setSelection(props.initialUsers || []);
                }}
                disabled={isLoadingCreateFolderAccess}
              />
              <Button
                text='Share with Selected Users'
                onClick={() => {
                  if (!props.skipCreateOnSave) {
                    return handleCreateFolderAccess();
                  }
                  handleModalClose();
                }}
                disabled={
                  isLoadingCreateFolderAccess ||
                  (!count && !props.noSelectedUserAllowed)
                }
              />
            </Footer>
          </>
        )}
      </Content>
    </NewModal>
  );
};
