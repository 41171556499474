import dayjs from 'dayjs';
import { deleteUserAccount, getDepartments, inviteUser } from 'lib/api';
import {
  useManagedUsersQuery,
  UserList,
} from 'lib/api/users/useManagedUsersQuery';
import {
  HoverPopup,
  ListDropdownMenu,
  LoadingIndicator,
  Search,
  SelectionHeaderPopup,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import {
  ModalCustomTableFields,
  TABLE_NAME_PREFIX,
} from 'lib/components/modal';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { getAutomotiveRoleAsString } from 'lib/utils/automotiveRolePermissionChecks';
import {
  capitalizeWords,
  exportCSVFile,
  setCommonPropertyForSelectedUsers,
} from 'lib/utils/functions';
import { getUserAccessAsString } from 'lib/utils/userRoles';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-covideo-common';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FaRedo } from 'react-icons/fa';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import {
  MdCheck,
  MdCreate,
  MdLaunch,
  MdLocalPhone,
  MdOutlineClose,
} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import styled from 'styled-components/macro';
import { successToast } from '../../../../lib/components/toasts/success';
import { useOnClickOutside } from '../../../../lib/utils';
import { LoginAsModal } from '../../reports/components';
import { AssignDepartmentModal } from './AssignDepartmentModal';
import { ChangeStatusModal } from './ChangeStatusModal';
import { ImportUserModal, ModalType } from './ImportUserModal';
import { exportHeaders } from './ListManager';
import { ModalVideoApproval } from './ModalVideoApproval';
import { NoContent } from './NoContent';
import { RemoveUserFromDepartmentModal } from './RemoveUserFromDepartmentModal';
import { generateLoginAsDetails } from 'lib/utils/superAdmin';
import { ChangeAutomotiveRoleModal } from './ChangeAutomotiveRoleModal';
import { USER_DATE_FORMAT } from 'lib/const/DateFormat';
import { ChangeUsersAccessRoleModal } from './ChangeUsersAccessRoleModal';
import { BULK_IMPORT_TEXT } from './importUserModalComponents/Constants';
import { useUpdateUsersAccessRoleMutation } from 'lib/api/users/useUpdateUsersAccessRoleMutation';
import { AccessRole } from 'lib/const';
import { useLoginAsUser } from 'lib/api/users/useLoginasUser';
import BouncedEmail from 'lib/components/bounced-email/BouncedEmail';
import { Flex } from 'lib/components/styles/layout';

const filterType = [
  {
    value: 'ALL',
    label: 'All Users',
  },
  {
    value: '1',
    label: 'Only Active',
  },
  {
    value: '0',
    label: 'Only Inactive',
  },
  // Commented due to prod issues.

  // {
  //   value: 'PENDING',
  //   label: 'Pending Invitation',
  // },
];

const ListContactLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;

  .scroll--container {
    ::-webkit-scrollbar {
      height: 16px;
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      /* should match background, can't be transparent */
      background-color: rgba(0, 0, 0, 0.5);
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
  }

  ${theme.mediaQueryMinWidth.mb} {
    width: 100%;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Filter = styled.div`
  float: right;
  ${theme.mediaQueryMinWidth.sm} {
    width: 205px;
  }
`;

const Customise = styled.div`
  float: right;
  color: #001b53;
  ${theme.mediaQueryMinWidth.sm} {
    width: 205px;
  }
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
`;

const TableCell = styled.div<{ width: number; overflow?: string }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  white-space: nowrap;
  overflow: ${props => (props.overflow ? props.overflow : 'hidden')};
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
  padding-right: 10px; /** SUS-1294 changes **/
  .login--column {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hover--div {
    cursor: pointer;
  }
  .alignDropdownMenu {
    margin-left: 20px;
  }
`;

const SortCell = styled.div`
  padding-top: 2px;
  padding-left: 10px;
  .active {
    fill: black;
  }
`;

const CheckboxSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;

const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;

const TickWrapper = styled.div`
  background: rgba(0, 27, 83, 0.05);
  width: 20px;
  height: 20px;
  line-height: 1.5;
  padding: 4px 3px 4px 5px;
  border-radius: 4px;
`;

const FilterGroup = styled.div`
  display: flex;
  div {
    margin-left: 6px;
  }
`;

const Inactive = styled.div`
  background: rgba(0, 27, 83, 0.05);
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  border-radius: 5px;
  color: ${theme.palette.primaryDarkBlue};
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Badge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background: ${theme.palette.gray100};
  border-radius: 6px;
`;

const BadgeText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.white};
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const AutomotiveRoleBadge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  background: ${({ theme }) => theme.colors.secondary['non_alpha']};
  border-radius: 6px;
`;

const AutomotiveRoleBadgeText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.primaryDarkBlue};
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const BadgeHoverPopup = styled(HoverPopup)`
  cursor: default;
`;

const BadgeHoverPopupText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.primaryDarkBlue};
  flex: none;
  flex-grow: 0;
  z-index: 1;
`;

type HoverElementProps = {
  padding?: string;
  top?: string;
  right?: string;
  disabled?: boolean;
};

const HoverElement = styled.div<HoverElementProps>`
  padding-left: 0px;
  pointer-events: ${props => (props.disabled ? 'none' : 'normal')};
  .hover-content {
    display: none;
  }
  &.padding--25 {
    padding-left: 25px;
  }
  &.padding--25--right {
    padding-left: 25px;
    padding-right: 25px;
  }
  &:hover {
    .hover-content {
      top: ${props => props.top || '40px'};
      right: ${props => props.right || '0px'};
      border: 1px solid #f7f8fa;
      background: white;
      padding: ${props => props.padding || '10px 10px 10px 10px'};
      display: block;
      position: absolute;
      border-radius: 5px;
      box-shadow: 0px 8px 32px rgba(29, 30, 36, 0.08);
      z-index: 10;
      .sub-content {
        display: flex;
        justify-content: center;
        padding-top: 5px;
      }
    }
  }
  svg {
    transform: scale(1.3);
  }
`;

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  cursor: pointer;
  &:hover {
    background: ${theme.palette.blue05};
    border-radius: 5px;
  }
`;

const BarButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const EmailWrapper = styled.div`
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 100px;
`;

const BouncedWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 7px;
`;

const allTableFields = [
  {
    value: 'firstName',
    label: 'Name',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'phone1',
    label: 'Phone',
  },
  {
    value: 'loginDate',
    label: 'Last login',
  },
  // {
  //   value: 'mobileSMSAccess',
  //   label: 'SMS',
  // },
  {
    value: 'transcriptionAccess',
    label: 'Captions',
  },
  {
    value: 'departmentName',
    label: 'Department',
  },
  {
    value: 'droplrAccess',
    label: 'Files',
  },
  {
    value: 'username',
    label: 'Username',
  },
  {
    value: 'signupDate',
    label: 'Sign Up Date',
  },
];

const initialTableFields = [
  allTableFields[0],
  allTableFields[1],
  allTableFields[7],
  allTableFields[3],
  allTableFields[2],
];

type UserListProps = {
  setFunctionUserObj?: React.Dispatch<React.SetStateAction<any>>;
  setDeptObj?: React.Dispatch<React.SetStateAction<any>>;
  deptId?: string | number;
  config?: any;
  setUserActiveStatus?: Function;
  userActiveStatus?: string;
  setDepartment?: Function;
  sendOnboardinginvite?(userId: number): void;
  showOnboardingInviteOption?: boolean;
  setUsersCount?: React.Dispatch<React.SetStateAction<number>>;
};

const EMPTY_ARRAY = [] as UserList[];
export const UserListing = (props: UserListProps) => {
  const {
    setFunctionUserObj,
    setDeptObj,
    deptId,
    config,
    userActiveStatus,
    setUserActiveStatus,
    setDepartment,
    // sendOnboardinginvite,
    setUsersCount,
    showOnboardingInviteOption = false,
  } = props;
  const tableName = 'Users';

  const [selectedUser, setSelectedUser] = useState<any>([]);

  const selectedFields = localStorage.getItem(TABLE_NAME_PREFIX + tableName);

  const INITIAL_FIELDS =
    selectedFields === null
      ? initialTableFields
      : JSON.parse(selectedFields).filter(
          (e: { value: string; label: string }) => e.value != 'mobileSMSAccess'
        );

  const [tableFields, seTableFields] = useState(initialTableFields);
  const [deptOption, setDeptOption] = useState([
    { label: 'All Departments', value: 0 },
  ]);
  const [isCustomizeTableModel, setIsCustomizeTableModel] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDept, setSelectedDept] = useState(deptId || 0);
  const [defaultDept, setDefaultDept] = useState<any>(0);
  const [sort, setSort] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [showDeptPopup, setShowDeptPopup] = useState(false);
  const [showModalVideoApproval, setShowModalVideoApproval] = useState(false);
  const [showModalChangeAutomotiveRole, setShowModalChangeAutomotiveRole] =
    useState(false);
  const [showEditUserTypeModal, setShowEditUserTypeModal] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [deptLabel, setDeptLabel] = useState('');
  const [showUserDeptRemovePopup, setShowUserDeptRemovePopup] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showLoginAsModal, setShowLoginAsModal] = useState(false);
  const [menuOpened, setMenuOpened] = useState(0);
  const [commonUserAutomotiveRole, setCommonUserAutomotiveRole] = useState(0);
  const [commonUserType, setCommonUserType] = useState(
    AccessRole.USER as string
  );
  const { ref } = useOnClickOutside(() => setMenuOpened(0));
  const { userData } = useAuth();
  // add more when more options added to modal
  const videoApprovalModalEnabled = userData.isVdpEnabled;

  const {
    data,
    isLoading,
    refetch: refreshUsers,
  } = useManagedUsersQuery({
    customerId: userData.customerId,
    page: page,
    size: size,
    sort: sort,
    search: searchQuery,
    userActiveStatus,
    departmentId: selectedDept,
  });

  const items = data?.userList || EMPTY_ARRAY;

  // const isLoadingSendInvite = useIsMutating();
  const columnWidths: any = [];
  const history = useHistory();
  const { mutateAsync: updateAccessRole, isLoading: isUpdating } =
    useUpdateUsersAccessRoleMutation();
  const { mutateAsync: loginAsUser, isLoading: loginAsLoading } =
    useLoginAsUser();

  useEffect(() => {
    if (!selectedUser.length) {
      return;
    }
    setCommonPropertyForSelectedUsers(
      selectedUser,
      'access',
      setCommonUserType
    );
  }, [selectedUser]);

  const handleUpdateUsersAccessRole = async (
    userIds: number[],
    accessRole: number
  ) => {
    await updateAccessRole({
      userIds,
      accessRole,
    });
  };
  const actionItems = [
    {
      title: 'Cancel Invitation',
      icon: <MdOutlineClose color={theme.palette.covideoBlue40} size={20} />,
      onClick: async (user: any) => {
        // delete user - canceling the invitation
        await deleteUserAccount(user.userId);
        await refreshUsers();
        successToast({
          title: `Invitation cancelled successfully for user ${user.username}`,
        });
      },
    },
    {
      title: 'Resend Invitation',
      icon: (
        <FaRedo color={theme.palette.covideoBlue40} width={20} height={20} />
      ),
      onClick: async (user: any) => {
        const invite = {
          id: user.userId,
          email: user.email,
          firstName: user.firstName,
          username: user.username,
        };
        await inviteUser(invite);
        successToast({
          title: `User ${user.username} invited successfully !`,
        });
      },
    },
  ];

  const onSaveCustomTableFields = (selectedFields: any) => {
    localStorage.setItem(
      TABLE_NAME_PREFIX + tableName,
      JSON.stringify(selectedFields)
    );
    seTableFields(selectedFields);
    setIsCustomizeTableModel(false);
  };
  const createUser = () => {
    history.push('/users/entry/');
  };
  const loginUser = async (user: any) => {
    if (loginAsLoading) {
      return;
    }
    const { timestamp, hexKey } = generateLoginAsDetails(
      Number(user.userId),
      user.username
    );
    await loginAsUser({
      userId: user.userId,
      timestamp,
      key: hexKey,
    });
    setShowLoginAsModal(true);
  };

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setPage(0);
  };
  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
    if (page !== newPage) {
      setSelectedUser([]);
    }
  };
  const sortElement = (sortParam: any) => {
    if (!['firstName', 'loginDate', 'departmentName'].includes(sortParam))
      return;
    if (sort.length && sort.includes(sortParam)) {
      const newSort = sort.includes('DESC')
        ? sort.replace('DESC', 'ASC')
        : sort.replace('ASC', 'DESC');
      setSort(newSort);
    } else if (!sort.includes(sortParam)) {
      const newSort = `${sortParam}-DESC`;
      setSort(newSort);
    }
  };
  const copyPhone = (phone: any) => {
    const cb = navigator.clipboard;
    cb.writeText(phone || '').then(() => {
      successToast({ title: 'Phone # copied successfully' });
    });
  };

  const loadTableFields = () => {
    if (selectedFields) {
      const finalSelectedFields = JSON.parse(selectedFields).filter(
        (e: any) => e.value != 'mobileSMSAccess'
      );
      localStorage.setItem(
        TABLE_NAME_PREFIX + tableName,
        JSON.stringify(finalSelectedFields)
      );
      seTableFields(finalSelectedFields);
    } else {
      localStorage.setItem(
        TABLE_NAME_PREFIX + tableName,
        JSON.stringify(tableFields)
      );
    }
  };

  const refreshState = async () => {
    try {
      const dept = config?.deptData
        ? config.deptData
        : await getDepartments(userData.customerId, {});
      if (dept.data && dept.data.length) {
        const mapArr: any = config?.mapArr
          ? config.mapArr
          : [{ label: 'All Departments', value: 0 }];
        if (!config?.mapArr) {
          dept.data.forEach((e: any) => {
            mapArr.push({ label: e.Name, value: e.DepartmentID });
          });
        }
        const defaultDept = dept.data.filter((e: any) => e.IsDefault == 1);
        if (defaultDept.length && !deptId) {
          const id = defaultDept[0].DepartmentID;
          setDefaultDept(id);
          setDeptLabel(defaultDept[0].Name);
        }
        setDeptOption(mapArr);
        if (deptId) {
          const index = config?.index
            ? config.index
            : mapArr.findIndex((e: any) => {
                return e.value == deptId;
              });
          if (index != -1) {
            if (setDeptObj)
              setDeptObj({
                department: mapArr[index].label,
                reload: () => {
                  refreshState();
                  refreshUsers();
                },
              });
            setDeptLabel(mapArr[index].label);
          }
        }
      }
      loadTableFields();
    } catch (error) {
      setDefaultDept(0);
      setDeptLabel('');
    }
  };

  useEffect(() => {
    refreshUsers();
  }, [page, size, searchQuery, userActiveStatus, sort, selectedDept]);
  useEffect(() => {
    if (setFunctionUserObj && !isLoading) {
      setUsersCount?.(data?.totalCount || 0);
      setFunctionUserObj({
        user: {
          create: createUser,
          usersValue: {
            total: data?.totalAllowedUsers || 0,
            active: data?.totalActiveUsers || 0,
          },
          import: () => setShowImportModal(true),
        },
      });
    }
  }, [isLoading, data]);

  useEffect(() => {
    refreshState();
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const { isAutomotive } = userData;
    if (isAutomotive && selectedUser.length && items.length) {
      const selectedUsers = items.filter((item: UserList) =>
        selectedUser.includes(item.userId)
      );

      if (!selectedUsers.length) {
        setCommonUserAutomotiveRole(0);
        return;
      }

      const automotiveRole = selectedUsers[0].automotiveRole || 0;
      const isSameAutomotiveRole = selectedUsers.every(
        (user: UserList) => user.automotiveRole === automotiveRole
      );
      if (isSameAutomotiveRole) {
        setCommonUserAutomotiveRole(automotiveRole);
      } else {
        setCommonUserAutomotiveRole(0);
      }
    }
  }, [userData, selectedUser, items, isLoading]);

  const exportData = () => {
    const exportData = [] as any;
    selectedUser.forEach((id: number) => {
      const user: any = items.find(item => item.userId === id);
      if (user) {
        exportData.push({
          ...user,
          loginDate:
            user.loginDate && user.verified
              ? dayjs(new Date(user.loginDate * 1000)).format('MMM DD YYYY')
              : '',
          access: getUserAccessAsString(user.access),
          ...(userData.isAutomotive && {
            automotiveRole: getAutomotiveRoleAsString(user.automotiveRole),
          }),
        });
      }
    });
    exportCSVFile(
      {
        ...exportHeaders,
        ...(userData.isAutomotive && { automotiveRole: 'Automotive Role' }),
      },
      exportData,
      'managed_users'
    );
  };

  // const handleInviteEmailSend = async (user: { userId: number }) => {
  //   if (!!sendOnboardinginvite) {
  //     await sendOnboardinginvite(user.userId);
  //     await refreshUsers();
  //   }
  // };
  const usersValue = {
    total: data?.totalAllowedUsers || 0,
    active: data?.totalActiveUsers || 0,
  };

  return (
    <>
      {!!selectedUser.length && (
        <SelectionHeaderPopup left='208px'>
          <CheckboxSelectionWrapper>
            <CheckboxInput
              blueCheck={true}
              checkGroupIndicator={
                selectedUser.length && items.length != selectedUser.length
              }
              checked={items.length == selectedUser.length}
              ignoreGrayForAllSelect={true}
              grayCheck={true}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                let { checked } = event.target as HTMLInputElement;
                if (checked) setSelectedUser(items.map((e: any) => e.userId));
                else setSelectedUser([]);
              }}
            />
            <SelectionCountWrap>
              <SelectionCountText>Users selected:</SelectionCountText>
              <b>{selectedUser.length}</b>
            </SelectionCountWrap>
          </CheckboxSelectionWrapper>
          <BarButtonContainer>
            <Button
              onClick={exportData}
              text='Export'
              variant='secondary'
            ></Button>
            <Button
              onClick={() => {
                setShowStatusPopup(true);
              }}
              text='Change Status'
              variant='secondary'
            ></Button>
            <Button
              onClick={() => {
                setShowDeptPopup(true);
              }}
              text='Add/Change Department'
              variant='secondary'
            ></Button>
            {userData.isAutomotive && (
              <Button
                onClick={() => {
                  setShowModalChangeAutomotiveRole(true);
                }}
                text='Edit Role'
                variant='secondary'
              />
            )}
            <Button
              onClick={() => {
                setShowEditUserTypeModal(true);
              }}
              text='Edit User Type'
              variant='secondary'
            />
            {userData.isVdpEnabled && (
              <Button
                onClick={() => {
                  setShowModalVideoApproval(true);
                }}
                text='Change Video Approval'
                variant='secondary'
              />
            )}
            {deptId && (
              <Button
                onClick={() => {
                  setShowUserDeptRemovePopup(true);
                }}
                text='Remove from Department'
                variant='destructive'
              ></Button>
            )}
          </BarButtonContainer>
        </SelectionHeaderPopup>
      )}
      {/* <ContactHeader selectedContacts={selectedContacts} onDelete={onDelete} /> */}
      <ListContactLayout>
        <FiltersContainer>
          <FilterGroup>
            <Filter style={{ marginLeft: '-10px' }}>
              <Search placeholder='Search users' onSearch={onSearch} />
            </Filter>
            <Filter>
              <Select
                styles={{
                  control: (base: any) => ({ ...base, height: '40px' }),
                  indicatorSeparator: () => ({ display: 'none' }),
                  menuPortal: provided => ({
                    ...provided,
                    zIndex: 5,
                  }),
                }}
                options={filterType}
                menuPortalTarget={document.body}
                menuPlacement={'bottom'}
                value={filterType.find(o => {
                  return o.value === userActiveStatus;
                })}
                onChange={(option: any) => {
                  setPage(0);
                  setUserActiveStatus?.(option.value);
                  // handleChangeBackgroundPosition(option.value);
                }}
                isSearchable={false}
              />
            </Filter>
            {!deptId && (
              <Filter>
                <Select
                  styles={{
                    control: (base: any) => ({
                      ...base,
                      height: '40px',
                      width: '220px',
                    }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    singleValue: (base, state) => ({
                      ...base,
                      opacity: state.selectProps.menuIsOpen ? 0.1 : 1,
                    }),
                  }}
                  formatOptionLabel={(option: any) => (
                    <label
                      style={{
                        textTransform:
                          option.value == defaultDept && defaultDept != 0
                            ? 'uppercase'
                            : 'capitalize',
                      }}
                    >{`${option.label}`}</label>
                  )}
                  options={deptOption}
                  menuPortalTarget={document.body}
                  menuPlacement={'bottom'}
                  value={deptOption.find((o: any) => {
                    return o.value == selectedDept;
                  })}
                  onChange={(option: any) => {
                    setPage(0);
                    if (option.value != 0) {
                      setDeptLabel(option.label);
                    }
                    setSelectedDept(option.value);
                    setDepartment?.(option.value);
                  }}
                />
              </Filter>
            )}
          </FilterGroup>
          <Customise onClick={() => setIsCustomizeTableModel(true)}>
            Customize table
          </Customise>
        </FiltersContainer>
        {items.length && !isLoading ? (
          <TableContextProvider
            total={data?.totalCount || 0}
            onChange={onPaginationChange}
            initPage={page}
            initSize={size}
          >
            <div
              className='scroll--container'
              style={{ overflowX: 'auto', paddingBottom: '20px' }}
            >
              <Table
                compact={true}
                relative={true}
                headers={[
                  '',
                  ...tableFields.map((item, index) => {
                    return (
                      <TableCell
                        width={columnWidths[index + 1]}
                        style={{
                          minHeight: '20px',
                        }}
                        onClick={() => sortElement(item.value)}
                      >
                        {item.label}
                        {item.value == 'firstName' ||
                        item.value == 'loginDate' ||
                        item.value == 'departmentName' ? (
                          <SortCell>
                            <IoMdArrowRoundUp
                              className={
                                sort == `${item.value}-ASC` ? 'active' : ''
                              }
                            />
                            <IoMdArrowRoundDown
                              className={
                                sort == `${item.value}-DESC` ? 'active' : ''
                              }
                            />
                          </SortCell>
                        ) : null}
                      </TableCell>
                    );
                  }),
                ]}
                hoverable={false}
                columnWidths={columnWidths}
                fixColumnIndex={[0]}
                rows={items.map((user: any, index: number) => {
                  return {
                    key: index,
                    columns: [
                      <TableCell
                        width={columnWidths[tableFields.length]}
                        style={{ alignItems: 'center' }}
                        overflow='visible'
                      >
                        <CheckboxInput
                          width='24px'
                          blueCheck={true}
                          checked={selectedUser.includes(user.userId)}
                          onChange={(e: React.SyntheticEvent) => {
                            e.stopPropagation();
                            let { checked } = e.target as HTMLInputElement;
                            if (checked)
                              setSelectedUser([...selectedUser, user.userId]);
                            else
                              setSelectedUser(
                                selectedUser.filter(
                                  (e: any) => e != user.userId
                                )
                              );
                          }}
                        />
                        <HoverElement
                          className='hover--div padding--25'
                          top={'5px'}
                          right={'-40px'}
                        >
                          <MdCreate
                            onClick={() => {
                              history.push(`/users/entry/${user.userId}`);
                            }}
                          />
                          <div className='hover-content'>Edit user</div>
                        </HoverElement>
                        {user.isPending ? (
                          <>
                            <ButtonWrapper>
                              <BiDotsVerticalRounded
                                onClick={() => setMenuOpened(user.userId)}
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '18px',
                                }}
                                size={22}
                              />
                            </ButtonWrapper>

                            <ListDropdownMenu
                              className={'alignDropdownMenu'}
                              reference={ref}
                              isMenuOpen={menuOpened === user.userId}
                              items={actionItems}
                              id={user}
                              setIsMenuOpen={setMenuOpened}
                            />
                          </>
                        ) : (
                          <HoverElement
                            top={'5px'}
                            right={'-120px'}
                            className='hover--div padding--25--right'
                            disabled={loginAsLoading}
                            onClick={() => loginUser(user)}
                          >
                            <div className='hover-content'>Log in as user</div>
                            <MdLaunch
                              color={
                                loginAsLoading
                                  ? theme.palette.gray60
                                  : theme.palette.themeDark
                              }
                            />
                          </HoverElement>
                        )}
                        <BadgeWrapper>
                          {userData.isAutomotive &&
                            getAutomotiveRoleAsString(user.automotiveRole) !==
                              '' && (
                              <AutomotiveRoleBadge>
                                <AutomotiveRoleBadgeText>
                                  {capitalizeWords(
                                    getAutomotiveRoleAsString(
                                      user.automotiveRole
                                    )
                                  )}
                                </AutomotiveRoleBadgeText>
                              </AutomotiveRoleBadge>
                            )}
                          {user.access === 3 && (
                            <BadgeHoverPopup
                              width='auto'
                              position='above'
                              padding='8px 12px'
                              showTriangle={true}
                              hoverable={
                                <Badge className='admin'>
                                  <BadgeText>A</BadgeText>
                                </Badge>
                              }
                              popup={
                                <BadgeHoverPopupText>Admin</BadgeHoverPopupText>
                              }
                            />
                          )}
                          {user.access === 4 && (
                            <BadgeHoverPopup
                              width='auto'
                              position='above'
                              padding='8px 12px'
                              showTriangle={true}
                              hoverable={
                                <Badge className='supervisor'>
                                  <BadgeText>S</BadgeText>
                                </Badge>
                              }
                              popup={
                                <BadgeHoverPopupText>
                                  Supervisor
                                </BadgeHoverPopupText>
                              }
                            />
                          )}
                          {!!user.mlAutoCreated && (
                            <BadgeHoverPopup
                              width='auto'
                              position='above'
                              padding='8px 12px'
                              showTriangle={true}
                              hoverable={
                                <Badge className='supervisor'>
                                  <BadgeText>MLM</BadgeText>
                                </Badge>
                              }
                              popup={
                                <BadgeHoverPopupText>
                                  Multilocation
                                </BadgeHoverPopupText>
                              }
                            />
                          )}
                          {/* {!!user.isPending && (
                            <Badge className="pending">PENDING</Badge>
                          )} */}
                        </BadgeWrapper>
                      </TableCell>,

                      ...tableFields.map((item, index) => {
                        return (
                          <TableCell
                            width={columnWidths[index + 1]}
                            onClick={() => {}}
                          >
                            {
                              // @ts-ignore
                              item.value == 'transcriptionAccess' ||
                              item.value == 'droplrAccess' ||
                              item.value == 'mobileSMSAccess' ? (
                                <>
                                  {user[item?.value]?.toString()?.trim() !==
                                  '0' ? (
                                    <TickWrapper>
                                      <MdCheck />
                                    </TickWrapper>
                                  ) : (
                                    '-'
                                  )}
                                </>
                              ) : item.value == 'phone1' ? (
                                !!user[item.value]?.trim() && (
                                  <HoverElement
                                    padding='10px'
                                    top='-60px'
                                    className='hover--div'
                                  >
                                    <MdLocalPhone
                                      onClick={() => {
                                        copyPhone(user[item.value]);
                                      }}
                                    />
                                    <div className='hover-content'>
                                      <strong>Click to copy:</strong>
                                      <br />
                                      <div className='sub-content'>
                                        {user[item.value]}
                                      </div>
                                    </div>
                                  </HoverElement>
                                )
                              ) : item.value == 'loginDate' ? (
                                !user.loginDate &&
                                !!showOnboardingInviteOption &&
                                !!user.verified ? (
                                  user.inviteSent ? (
                                    <></>
                                  ) : (
                                    // <Button
                                    //   disabled={!!isLoadingSendInvite}
                                    //   text='Resend'
                                    //   variant='secondary'
                                    //   onClick={() => handleInviteEmailSend(user)}
                                    // />
                                    <></>
                                    // <Button
                                    //   disabled={!!isLoadingSendInvite}
                                    //   text='Send'
                                    //   variant='secondary'
                                    //   onClick={() => handleInviteEmailSend(user)}
                                    // />
                                  )
                                ) : (
                                  <div className={'login--column'}>
                                    {!!user[item.value]
                                      ? dayjs(
                                          new Date(user[item.value] * 1000)
                                        ).format(USER_DATE_FORMAT)
                                      : 'Date: N/A'}
                                    {/* {!user.verified && <Inactive>INACTIVE</Inactive>} */}
                                    {!user.verified ? (
                                      <HoverElement
                                        padding='10px'
                                        top='-40px'
                                        right='70px'
                                        className='hover--div'
                                      >
                                        <Inactive>INACTIVE</Inactive>
                                        <div className='hover-content'>
                                          {user[item.value]
                                            ? 'Date: ' +
                                              dayjs(
                                                new Date(
                                                  user[item.value] * 1000
                                                )
                                              ).format(USER_DATE_FORMAT)
                                            : 'Date: N/A'}
                                        </div>
                                      </HoverElement>
                                    ) : null}
                                  </div>
                                )
                              ) : item.value == 'signupDate' ? (
                                dayjs(new Date(user[item.value])).format(
                                  USER_DATE_FORMAT
                                )
                              ) : item.value === 'email' ? (
                                <Flex>
                                  <EmailWrapper>
                                    {user[item.value]}
                                  </EmailWrapper>

                                  {!!user.bouncedEmail &&
                                  user.bouncedEmail === user.email ? (
                                    <BouncedWrapper>
                                      <BouncedEmail
                                        email={user.email}
                                        bouncedEmail={user.bouncedEmail}
                                        tooltipPosition='right'
                                      />
                                    </BouncedWrapper>
                                  ) : (
                                    <></>
                                  )}
                                </Flex>
                              ) : (
                                <div>
                                  <div
                                    style={
                                      item.value === 'firstName' ||
                                      item.value === 'username'
                                        ? {
                                            width: 250,
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                          }
                                        : {}
                                    }
                                  >
                                    {item.value == 'departmentName' &&
                                    selectedDept != 0
                                      ? deptLabel
                                      : item.value == 'firstName'
                                        ? `${user[item.value]} ${user['lastName']}`
                                        : user[item.value]}
                                  </div>
                                </div>
                              )
                            }
                          </TableCell>
                        );
                      }),
                    ],
                    onClick: () => {},
                  };
                })}
              />
            </div>
            <TableFooter>
              <TablePaginationNew />
              <TablePaginationSizeNew text='Show users:' />
            </TableFooter>
          </TableContextProvider>
        ) : !items.length && !isLoading ? (
          <NoContent
            onImport={() => {
              setShowImportModal(true);
            }}
            createUser={createUser}
            selectedDept={selectedDept}
            usersValue={usersValue}
            title={
              selectedDept != 0
                ? `This Department doesn’t have any ${
                    userActiveStatus == '1'
                      ? 'active'
                      : userActiveStatus == '0'
                        ? 'inactive'
                        : ''
                  } users associated.`
                : 'No matching results found.'
            }
          />
        ) : (
          <LoadingIndicator isLoading={isLoading} height='500px' />
        )}
      </ListContactLayout>
      {isCustomizeTableModel && (
        <ModalCustomTableFields
          title='Customize User Table'
          onClose={() => setIsCustomizeTableModel(false)}
          allFields={allTableFields}
          initialFields={INITIAL_FIELDS}
          maxFieldConfig={{ count: 6 }}
          actionButtons={[
            {
              onClick: onSaveCustomTableFields,
              text: 'Update',
            },
          ]}
        />
      )}
      {showDeptPopup && (
        <AssignDepartmentModal
          selectedUser={selectedUser}
          handleModalClose={shouldRefresh => {
            setShowDeptPopup(false);
            setSelectedUser([]);
            if (shouldRefresh) refreshUsers();
          }}
        />
      )}
      {showStatusPopup && (
        <ChangeStatusModal
          selectedUser={selectedUser}
          handleModalClose={shouldRefresh => {
            setShowStatusPopup(false);
            setSelectedUser([]);
            if (shouldRefresh) refreshUsers();
          }}
        />
      )}
      {showModalVideoApproval && !!selectedUser.length && (
        <ModalVideoApproval
          selectedUser={selectedUser}
          handleModalClose={shouldRefresh => {
            setShowModalVideoApproval(false);
            setSelectedUser([]);
            if (shouldRefresh) refreshUsers();
          }}
        />
      )}
      {showImportModal && (
        <ImportUserModal
          modalType={ModalType.BULK}
          title='Import users '
          text={BULK_IMPORT_TEXT}
          handleModalClose={shouldRefresh => {
            if (shouldRefresh && videoApprovalModalEnabled) {
              setShowModalVideoApproval(true);
            }
            if (shouldRefresh) {
              refreshUsers();
            }
            setShowImportModal(false);
          }}
          handleSubmit={ids =>
            videoApprovalModalEnabled && setSelectedUser(ids)
          }
        />
      )}
      {showUserDeptRemovePopup && deptId && (
        <RemoveUserFromDepartmentModal
          selectedUser={selectedUser}
          handleModalClose={shouldRefresh => {
            setShowUserDeptRemovePopup(false);
            setSelectedUser([]);
            if (shouldRefresh) refreshUsers();
          }}
          departmentId={[deptId]}
          customerId={userData.customerId}
        />
      )}
      {showLoginAsModal && (
        <LoginAsModal
          handleModalClose={() => {
            setShowLoginAsModal(false);
            window.location.reload();
          }}
        />
      )}
      {showModalChangeAutomotiveRole && (
        <ChangeAutomotiveRoleModal
          selectedUsers={selectedUser}
          selectedAutomotiveRole={commonUserAutomotiveRole}
          handleModalClose={shouldRefresh => {
            setShowModalChangeAutomotiveRole(false);
            setSelectedUser([]);
            if (shouldRefresh) refreshUsers();
          }}
        />
      )}
      {showEditUserTypeModal && (
        <ChangeUsersAccessRoleModal
          selectedUsers={selectedUser}
          isLoading={isUpdating}
          commonUserType={parseInt(commonUserType, 10)}
          handleModalClose={() => {
            setShowEditUserTypeModal(false);
            setSelectedUser([]);
          }}
          onPrimaryButtonClick={handleUpdateUsersAccessRole}
        />
      )}
    </>
  );
};
