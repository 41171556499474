import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { LeadItem } from 'lib/context/types';

export interface CreateLeadParams extends Partial<LeadItem> {
  disableToast?: boolean;
}

export const createLead = async (data: CreateLeadParams) => {
  const response = await EXPRESS_API.post(`/leads`, data);
  return response.data;
};

export const useAddLeadMutation = () => {
  return useMutation(createLead, {
    onSuccess: (_, options) => {
      if (!options.disableToast) {
        successToast({
          title: 'You created a new customer!',
        });
      }
    },
    onError: () => {
      errorToast({ title: 'An error occurred while creating lead.' });
    },
  });
};
