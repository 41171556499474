import { ModalVideoListVideo } from 'lib/components';
import { theme } from 'lib/style';
import React, { useState } from 'react';
import { BsPinAngleFill } from 'react-icons/bs';
import { MdBarChart } from 'react-icons/md';
import styled, { useTheme } from 'styled-components/macro';
import { VideoItemThumbnail } from '../';
import { useAuth, VideoListAutomotiveItem, VideoRequest } from 'lib/context';
import { AutomotiveRole } from 'lib/const/AutomotiveRole';

import { VideoItemCompanyActions } from './VideoItemCompanyActions';
import { FOLDER_ACCESS } from '../../videoList/constants';
import { Folder } from 'lib/api/folders/getFolders';

const Row = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  padding: 16px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid ${theme.palette.secondaryButtonBlueBg};
`;
const Left = styled.div`
  width: 130px;
  flex-shrink: 0;
`;
const Center = styled.div`
  width: 100%;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Right = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Info = styled.div`
  color: ${theme.palette.gray80};
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  > b {
    font-weight: 500;
  }
`;
const RONumber = styled.div`
  font-size: 13px;
  line-height: 24px;
  color: ${theme.palette.gray80};
  > b {
    font-weight: 500;
  }
`;
const ViewCounter = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 24px;
  color: ${theme.palette.gray80};
  cursor: pointer;
  > span {
    margin-left: 2px;
  }
`;

const Title = styled.h3`
  color: ${theme.palette.gray100};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const Tag = styled.div`
  background: ${theme.palette.covideoSecondaryButtonBg};
  color: ${theme.palette.blue60};
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  padding: 6px;
  margin: 0 8px 0px 0;
  border-radius: 5px;
`;
const ShowMoreTags = styled(Tag)`
  cursor: pointer;
`;
const Bull = styled.span`
  :after {
    content: '•';
    margin: 0 6px;
    color: ${theme.palette.gray80};
  }
`;
type UserAdvisor = {
  firstName: string;
  lastName: string;
};

type Props = {
  recordedBy: UserAdvisor;
  video: VideoListAutomotiveItem;
  videoRequest: VideoRequest;
  handleDownload: (downloadData: { videoId: string; title: string }[]) => void;
  access?: string;
  handleDeleteSubmit?: (videoIds: string[]) => void;
  disablePinOption?: boolean;
  currentFolder?: Folder | null;
  isFolderShared?: boolean;
  refreshVideoList?: () => void;
};
export const VideoItemCompany = ({
  recordedBy,
  video,
  videoRequest,
  handleDownload,
  access,
  handleDeleteSubmit,
  disablePinOption,
  currentFolder = null,
  isFolderShared = false,
  refreshVideoList,
}: Props) => {
  const [showMoreTags, setShowMoreTags] = useState(false);
  const [showModalPreviewVideo, setShowModalPreviewVideo] = useState(false);
  const { userData } = useAuth();
  const { colors } = useTheme();

  const handleVideoThumbnailClick = () => {
    setShowModalPreviewVideo(true);
  };

  const isTechnician = userData.automotiveRole === AutomotiveRole.TECHNICIAN;

  const recordedInfo = (
    <>
      Recorded by{' '}
      <b>
        {recordedBy ? recordedBy.firstName + ' ' + recordedBy.lastName : ''}
      </b>
    </>
  );

  const showBull =
    (userData.isAutomotiveSalesRole && videoRequest.vin) ||
    (userData.isAutomotiveServiceRole && videoRequest.repairOrderNumber);

  return (
    <>
      <Row>
        <Left>
          <VideoItemThumbnail
            handleClick={() => handleVideoThumbnailClick()}
            videoId={video.id}
            thumbnail={video.thumbnail}
            duration={video.videoLength || 0}
            processing={video.processing}
          />
        </Left>
        <Center>
          <Title>
            {!!video.pinnedVideo?.pinnedAt && (
              <>
                <BsPinAngleFill color={colors.primary[100]} size={18} /> &nbsp;
              </>
            )}
            {video.title}
          </Title>
          <Info>
            {recordedInfo}
            <Bull />
            {video.recordDate}
          </Info>
          <Footer>
            {userData.isAutomotiveSalesRole && videoRequest.vin && (
              <>
                <RONumber>
                  <b>VIN:</b> {videoRequest.vin}
                </RONumber>
              </>
            )}
            {userData.isAutomotiveServiceRole &&
              videoRequest.repairOrderNumber && (
                <>
                  <RONumber>
                    <b>RO#:</b> {videoRequest.repairOrderNumber}
                  </RONumber>
                </>
              )}
            {!isTechnician && (
              <>
                {showBull && <Bull />}
                <ViewCounter>
                  <MdBarChart color={theme.palette.gray60} size={18} />
                  <span>
                    {`${video.views} view${
                      video.views.toString() === '1' ? '' : 's'
                    }`}
                  </span>
                </ViewCounter>
              </>
            )}
            {video.tags && !!video.tags.length && (
              <>
                <Bull />
                {video.tags.slice(0, 2).map((t: any, index) => (
                  <Tag key={'videotag-' + index}># {t.tag}</Tag>
                ))}
                {!showMoreTags && video.tags.length > 2 && (
                  <ShowMoreTags onClick={() => setShowMoreTags(true)}>
                    +{video.tags.length - 2}
                  </ShowMoreTags>
                )}
                {showMoreTags &&
                  video.tags.length > 2 &&
                  video.tags
                    .slice(2)
                    .map((t: any, index) => (
                      <Tag key={'videotag-' + index}># {t.tag}</Tag>
                    ))}
              </>
            )}
          </Footer>
        </Center>
        {currentFolder?.access !== FOLDER_ACCESS.VIEW && (
          <Right>
            <VideoItemCompanyActions
              video={video}
              handleDownload={handleDownload}
              access={access}
              videoRequest={videoRequest}
              handleDeleteSubmit={handleDeleteSubmit}
              disablePinOption={disablePinOption}
              currentFolder={currentFolder}
              isFolderShared={isFolderShared}
              refreshVideoList={refreshVideoList}
            />
          </Right>
        )}
      </Row>
      {showModalPreviewVideo && (
        <ModalVideoListVideo
          videoId={video.id}
          handleModalClose={() => setShowModalPreviewVideo(false)}
        />
      )}
    </>
  );
};
