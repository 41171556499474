import { Field, FieldProps, Form, Formik } from 'formik';
import { useRegenerateTokenMutation } from 'lib/api/auth/useRegenerateTokenMutation';
import { useAddCustomerCardMutation } from 'lib/api/billing/useAddCustomerCardMutation';
import { useUpdateCustomerPaymentMutation } from 'lib/api/billing/useUpdateCustomerPaymentMutation';
import { LoadingIndicator, Modal } from 'lib/components';
import {
  initialValues,
  PaymentCardParams,
} from 'lib/components/add-card/constants';
import {
  formatExpiry,
  validateExpiry,
} from 'lib/components/add-card/functions';
import { FormikDropdownField } from 'lib/components/formik/FormikDropdown';
import { FormikInputField } from 'lib/components/formik/FormikInputField';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import { Gap } from 'lib/components/styles/layout';
import { successToast } from 'lib/components/toasts/success';
import { Country, States } from 'lib/const';
import { StateType } from 'lib/const/Country';
import { PackageWithAdditionalInfo } from 'lib/const/PackageAdditionalInfo';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import { theme } from 'lib/style';
import { Dictionary } from 'lodash';
import * as React from 'react';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { IoMdCalendar, IoMdClose } from 'react-icons/io';
import { MdCreditCard } from 'react-icons/md';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  onSubmit?: () => void | undefined;
  billingCycle?: string;
  packageId?: number;
  packageQuantity?: number;
  packagesDictionary?: Dictionary<PackageWithAdditionalInfo>;
};

const ModalItem = styled.div`
  background-color: ${({ theme }) => theme.colors.white[100]};
  margin: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContentHeaderWrap = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.black[100]};
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${({ theme }) => theme.colors.black[80]};
  overflow-wrap: break-word;
  max-width: 100%;
  overflow: none;
`;

const StripeUI = styled.div`
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.neutral[10]};
  padding: 20px 24px 8px 20px;
  margin-top: 20px;
`;

const CloseButtonWrap = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.black[100]};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  .default {
    display: flex;
    align-items: center;
  }
`;

const validationSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .test(
      'valid-card-number',
      'Invalid card number',
      value =>
        !!value &&
        /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})|(62[0-9]{14,17})|((5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]{8,15}))$/.test(
          value
        )
    )
    .required('Card Number is required'),
  expiry: Yup.string()
    .test('valid-expiry', 'Invalid expiry date', validateExpiry)
    .required('Expiry date is required'),
  cvc: Yup.string()
    .matches(/^[0-9]{3,4}$/, 'Invalid CVC')
    .required('CVC is required'),
  holderName: Yup.string().required('Holder Name is required'),
  billingAddress: Yup.string().required('Billing Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  country: Yup.string().required('Country is required'),
  postCode: Yup.string().required('Postal Code is required'),
});

export const AddCardModal = ({
  handleModalClose,
  onSubmit = undefined,
  billingCycle = '',
  packageId = 0,
  packageQuantity = 1,
  packagesDictionary = undefined,
}: Props) => {
  const { mutateAsync: regenerateJwtToken } = useRegenerateTokenMutation();
  const { showError } = useToastError();
  const { setJwtAndUpdateToken } = useAuth();
  const { colors } = useCovideoTheme();
  const { mutateAsync: updateCustomerSubscription } =
    useUpdateCustomerPaymentMutation();
  const { mutateAsync: addNewCard, isLoading: addCardLoading } =
    useAddCustomerCardMutation();
  const {
    mutateAsync: updateSubscription,
    isLoading: updateSubscriptionLoading,
  } = useUpdateCustomerPaymentMutation();

  const handleAddCardAndPay = async (values: PaymentCardParams) => {
    try {
      const {
        holderName,
        billingAddress,
        postCode,
        city,
        state,
        country,
        cardNumber,
        expiry,
        cvc,
        makeDefaultCard,
      } = values;
      const payload = {
        cardNumber,
        expMonth: expiry.split('/')[0],
        expYear: '20' + expiry.split('/')[1],
        cvc: cvc.toString(),
        billingCountry: country,
        billingPostalCode: postCode,
        billingAddress,
        billingCity: city,
        billingState: state,
        fullName: holderName,
      };
      const response = await addNewCard(payload);
      const subscriptionPayload = {
        defaultMethod: response.paymentId,
        interval: billingCycle,
        quantity: packageQuantity,
        packageId,
        makeDefaultCard,
      };
      await updateSubscription(subscriptionPayload);
      const tokens = await regenerateJwtToken();
      setJwtAndUpdateToken(tokens);
      if (response) {
        successToast({
          title: packagesDictionary
            ? `Card added and ${packagesDictionary[packageId].title} is successfully activated!`
            : `Card added and plan is successfully activated!`,
        });
        handleModalClose(true);
      }
    } catch (error) {
      showError(error);
    }
  };

  const handleCardAdd = async (values: PaymentCardParams) => {
    try {
      const {
        holderName,
        billingAddress,
        postCode,
        city,
        state,
        country,
        cardNumber,
        expiry,
        cvc,
        makeDefaultCard,
      } = values;
      const payload = {
        cardNumber,
        expMonth: expiry.split('/')[0],
        expYear: '20' + expiry.split('/')[1],
        cvc: cvc.toString(),
        billingCountry: country,
        billingPostalCode: postCode,
        billingAddress,
        billingCity: city,
        billingState: state,
        fullName: holderName,
      };
      const response = await addNewCard(payload);
      if (response.paymentId && makeDefaultCard) {
        await updateCustomerSubscription({ defaultMethod: response.paymentId });
      }
      if (response) {
        handleModalClose(true);
      }
    } catch (error) {}
  };

  const handleSubmit = async (values: PaymentCardParams) => {
    if (!!onSubmit) {
      return handleAddCardAndPay(values);
    }

    handleCardAdd(values);
  };

  const AddCard = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader>Add New Payment Method</ContentHeader>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              onClick={() => handleModalClose()}
              size={24}
              color={colors.neutral[60]}
            />
          </CloseButtonWrap>
        </ContentHeaderWrap>
        <ContentBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnMount={true}
          >
            {({ values, setFieldValue, isValid, isSubmitting }) => (
              <Form>
                <StripeUI>
                  <Gap
                    gap='20px'
                    justifyContent='space-between'
                    width='100%'
                    flexWrap='nowrap'
                  >
                    <FormikInputField
                      name='cardNumber'
                      label='Card Number'
                      placeholder='Card Number'
                      isRequired={true}
                      extendStyles={{
                        mainWrapper: {
                          width: '320px',
                        },
                      }}
                    />
                    <div style={{ position: 'relative', display: 'flex' }}>
                      <IoMdCalendar
                        style={{ position: 'absolute', top: 34, left: 10 }}
                        size={18}
                      />
                      <FormikInputField
                        name='expiry'
                        placeholder='MM/YY'
                        isRequired={true}
                        label='Expiry'
                        style={{ paddingLeft: '40px' }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const formattedValue = formatExpiry(
                            event.target.value
                          );
                          setFieldValue('expiry', formattedValue);
                        }}
                        extendStyles={{
                          mainWrapper: {
                            width: '120px',
                          },
                        }}
                      />
                    </div>
                    <FormikInputField
                      name={'cvc'}
                      label={'CVC'}
                      placeholder='CVC'
                      isRequired={true}
                      extendStyles={{
                        mainWrapper: {
                          width: '120px',
                        },
                      }}
                    />
                  </Gap>
                </StripeUI>
                <Gap
                  gap='40px'
                  justifyContent='space-between'
                  width='100%'
                  flexWrap='nowrap'
                  m='20px 0 0 0'
                >
                  <FormikInputField
                    name={'holderName'}
                    label={'Holder Name'}
                    isRequired={true}
                  />
                  <FormikInputField
                    name={'billingAddress'}
                    label={'Billing Address'}
                    isRequired={true}
                  />
                </Gap>
                <Gap
                  gap='40px'
                  justifyContent='space-between'
                  width='100%'
                  flexWrap='nowrap'
                  m='20px 0 0 0'
                >
                  <FormikInputField
                    name={'city'}
                    label={'City'}
                    isRequired={true}
                  />
                  <FormikDropdownField
                    name={`state`}
                    label={'State'}
                    isRequired={true}
                    disabled={false}
                    options={
                      States[values.country]?.map((state: StateType) => ({
                        value: state.code,
                        label: state.name,
                      })) || []
                    }
                  />
                </Gap>
                <Gap
                  gap='40px'
                  justifyContent='space-between'
                  width='100%'
                  flexWrap='nowrap'
                  m='20px 0 0 0'
                >
                  <FormikDropdownField
                    name={`country`}
                    label={'Select Country'}
                    isRequired={true}
                    disabled={false}
                    options={
                      Country.map(country => ({
                        value: country.countryShortCode,
                        label: country.countryName,
                      })) || []
                    }
                    onChange={option => {
                      setFieldValue('country', option?.value);
                      setFieldValue('state', '');
                    }}
                  />
                  <FormikInputField
                    name={'postCode'}
                    label={'Postal Code'}
                    isRequired={true}
                  />
                </Gap>
                <Gap
                  gap='40px'
                  justifyContent='space-between'
                  width='100%'
                  flexWrap='nowrap'
                  m='20px 0 0 0'
                >
                  <ButtonContainer>
                    <div className='default'>
                      <Field name='makeDefaultCard'>
                        {({ field }: FieldProps) => (
                          <CheckboxInput
                            {...field}
                            blueCheck={true}
                            checkGroupIndicator={false}
                            checked={values.makeDefaultCard}
                            onClick={(
                              event: React.MouseEvent<HTMLInputElement>
                            ) => {
                              event.stopPropagation();
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              event.stopPropagation();
                              const { checked } = event.target;
                              setFieldValue('makeDefaultCard', checked);
                            }}
                          />
                        )}
                      </Field>
                      &nbsp;&nbsp;&nbsp;
                      <div>Set as Default</div>
                    </div>
                    <Button
                      type='submit'
                      icon={
                        !!onSubmit ? (
                          <MdCreditCard color={colors.white[100]} />
                        ) : undefined
                      }
                      text={!!onSubmit ? 'Pay Now' : `Add Payment Method`}
                      disabled={!isValid || isSubmitting}
                    />
                  </ButtonContainer>
                </Gap>
              </Form>
            )}
          </Formik>
        </ContentBody>
      </Content>
    </ModalItem>
  );

  const LoadingView = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader></ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          <LoadingIndicator isLoading={true} text='Adding card...' />
        </ContentBody>
      </Content>
    </ModalItem>
  );

  const isLoading = addCardLoading || updateSubscriptionLoading;
  return <Modal>{isLoading ? LoadingView : AddCard}</Modal>;
};
