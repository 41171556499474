import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  MdArchive,
  MdAssessment,
  MdOutlineRestore,
  MdPerson,
  MdSupervisorAccount,
} from 'react-icons/md';
import { SidebarNavigation } from 'lib/components/SidebarNavigation';

import { useAuth } from 'lib/context';
import { Overview } from './overview';
import { Users } from './users';
import { Companies } from './companies';
import { productFeature } from 'lib/utils/productFeature';
import { Dashboard } from './dashboard';
import VideoAttributeDetails from './overview/main/VideoAttributeDetails/VideoAttributeDetails';
import VideoAttributeValueDetails from './overview/main/VideoAttributeDetails/VideoAttributeValueDetails';
import { NotFoundTemplate } from '../notFound';
import { IoMdBriefcase } from 'react-icons/io';
import { KeyMetricsPage } from './keyMetrics/KeyMetricsPage';
import { BiWorld } from 'react-icons/bi';
import { OrganizationPickerPage } from './companies/main';
import { SharedReports } from './sharedReports';
import { Activity } from './activity/Activity';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { BsFillGridFill } from 'react-icons/bs';
import { Custom } from './custom/Custom';
import { USERS_IDS_WITH_NO_ALL_TEAMS } from 'lib/const/Users';
import { LEGACY_REPORT_RESELLERS } from 'lib/const/Resellers';
import { LeadReports } from './leads/LeadsReports';
import { PiChartLineFill } from 'react-icons/pi';

export const ReportRoutes = () => {
  const { userData, whitelabel } = useAuth();
  const {
    isCompanyAdmin,
    trialAccount,
    isResellerAdmin,
    isOrganizationAdmin,
    userOrganizationAccess,
    user,
    customerAccess,
    reseller,
    id: userId,
  } = userData;
  const organizationIds = (userOrganizationAccess || []).map(
    userOrganizationAccess => userOrganizationAccess.organizationId
  );

  const hasLeadsReports =
    userData.customer.leadsNotificationsAccess === '1' && isCompanyAdmin;

  const packageDetails = user?.packageDetails;
  let isReportsDisabled = false;
  if (packageDetails && packageDetails.id && packageDetails.products) {
    isReportsDisabled = !packageDetails.products.includes(
      productFeature.REPORTS
    );
  }

  const iconSize = '24px';
  const items = [
    {
      title: 'My Stats',
      icon: <MdPerson size={iconSize} />,
      path: `/reports/overview`,
    },
    {
      title: 'Custom Reports',
      icon: <MdAssessment size={iconSize} />,
      path: `/reports/custom`,
    },
    ...(hasLeadsReports && false
      ? [
          {
            title: 'Leads Report',
            icon: <PiChartLineFill size={iconSize} />,
            path: `/reports/leads`,
          },
        ]
      : []),
    {
      title: 'Activity Feed',
      icon: <MdOutlineRestore size={iconSize} />,
      path: '/reports/activity-feed',
    },
    {
      title: 'Dashboard',
      icon: <BsFillGridFill size={iconSize} />,
      path: `/reports/dashboard`,
    },
  ];

  // hide reports dashboard if user is not company admin
  if (!isCompanyAdmin) {
    items.pop();
  }

  // show reports users link for company admin users
  if (isCompanyAdmin) {
    items.splice(0, 0, {
      title: 'My Team',
      icon: <MdSupervisorAccount size={iconSize} />,
      path: `/reports/users`,
    });
  }

  // show reports companies link for company resellers
  const showAllTeams =
    (isResellerAdmin || customerAccess) &&
    !USERS_IDS_WITH_NO_ALL_TEAMS[parseInt(userId, 10)];
  if (showAllTeams) {
    items.splice(0, 0, {
      title: 'All Teams',
      icon: <IoMdBriefcase size={iconSize} />,
      path: `/reports/companies`,
    });
  }

  const legacyReportsWhitelabels = [
    WHITELABEL_NAME.APP_2_VID,
    WHITELABEL_NAME.PRO_VIDEO_NOW,
  ].includes(whitelabel.name);
  const resellerIds = LEGACY_REPORT_RESELLERS.includes(
    reseller.resellerId.toString()
  );

  const showLegacyReports =
    isCompanyAdmin &&
    !trialAccount &&
    (legacyReportsWhitelabels || resellerIds);

  // remove from standard or trial users legacy report link
  if (showLegacyReports) {
    items.push({
      title: 'Legacy Reports',
      icon: <MdArchive size={iconSize} />,
      path: `/reports/legacy`,
    });
  }

  if (isOrganizationAdmin && organizationIds.length === 1) {
    items.splice(4, 0, {
      title: 'Organization Report',
      icon: <BiWorld size={iconSize} />,
      path: `/reports/organization/${organizationIds[0]}/companies`,
    });
  }
  if (isOrganizationAdmin && organizationIds.length > 1) {
    items.splice(4, 0, {
      title: 'Organizations Report',
      icon: <BiWorld size={iconSize} />,
      path: `/reports/organization/`,
    });
  }

  return (
    <>
      <SidebarNavigation items={items} hasBack={false} />

      <Switch>
        {isCompanyAdmin && (
          <Route path='/reports/dashboard' component={Dashboard} />
        )}
        <Route
          path='/reports/overview'
          component={
            isReportsDisabled
              ? () => <Redirect to={'/upgrade-plan'} />
              : Overview
          }
        />
        <Route
          path='/reports/overview/video-attributes/:videoAttributeId'
          component={
            isReportsDisabled
              ? () => <Redirect to={'/upgrade-plan'} />
              : VideoAttributeDetails
          }
        />

        <Route
          path='/reports/overview/video-attributes/:videoAttributeId/:valueId'
          component={
            isReportsDisabled
              ? () => <Redirect to={'/upgrade-plan'} />
              : VideoAttributeValueDetails
          }
        />

        {isCompanyAdmin && (
          <Route
            path='/reports/users'
            component={
              isReportsDisabled
                ? () => <Redirect to={'/upgrade-plan'} />
                : Users
            }
          />
        )}
        {showAllTeams && (
          <Route
            path='/reports/companies'
            component={
              isReportsDisabled
                ? () => <Redirect to={'/upgrade-plan'} />
                : Companies
            }
          />
        )}
        {isOrganizationAdmin && (
          <Route
            path='/reports/organization/:organizationId/companies'
            component={
              isReportsDisabled
                ? () => <Redirect to={'/upgrade-plan'} />
                : Companies
            }
          />
        )}
        {isOrganizationAdmin && (
          <Route
            path='/reports/organization'
            component={
              isReportsDisabled
                ? () => <Redirect to={'/upgrade-plan'} />
                : OrganizationPickerPage
            }
          />
        )}
        <Route
          path='/reports/activity-feed'
          component={
            isReportsDisabled
              ? () => <Redirect to={'/upgrade-plan'} />
              : Activity
          }
        />
        <Route
          path='/reports/custom'
          component={
            isReportsDisabled ? () => <Redirect to={'/upgrade-plan'} /> : Custom
          }
        />
        <Route
          path='/reports/shared'
          component={
            isReportsDisabled
              ? () => <Redirect to={'/upgrade-plan'} />
              : SharedReports
          }
        />
        {hasLeadsReports && (
          <Route path='/reports/leads' component={LeadReports} />
        )}
        <Route
          path='/reports/key-metrics'
          component={
            isReportsDisabled
              ? () => <Redirect to={'/upgrade-plan'} />
              : KeyMetricsPage
          }
        />
        {showLegacyReports && (
          <Route
            path='/reports/legacy'
            component={() => {
              if (isReportsDisabled) {
                return <Redirect to={'/upgrade-plan'} />;
              }

              window.location.href = `https://${whitelabel.domainV2}/backoffice/Email_Reports.php`;
              return null;
            }}
          />
        )}

        <Route path='/reports' exact>
          <Redirect to='/reports/users' />
        </Route>
        <Route path='*' component={NotFoundTemplate} />
      </Switch>
    </>
  );
};
