import React from 'react';
import { useTheme } from 'styled-components/macro';
import { StyledLabel, StyledParagraph } from './styled';
import { ILabelProps } from './types';

export const Label: React.FC<ILabelProps> = ({
  name,
  label,
  isRequired,
  disabled,
}) => {
  const themes = useTheme();

  if (name) {
    return (
      <StyledLabel htmlFor={name} disabled={disabled}>
        {label}{' '}
        {isRequired && (
          <span style={{ color: themes.colors.primary[100] }}>*</span>
        )}
      </StyledLabel>
    );
  }
  return (
    <StyledParagraph disabled={disabled}>
      {label}{' '}
      {isRequired && (
        <span style={{ color: themes.colors.primary[100] }}>*</span>
      )}
    </StyledParagraph>
  );
};
