import React from 'react';
import { MdDownload, MdEmail } from 'react-icons/md';
import styled from 'styled-components/macro';
import { SendReportModal } from './SendReportModal';
import { downloadXLSXAnalyticsReport } from 'lib/api/reportsApi';
import { isAdminSession } from 'lib/utils/auth';
import { useAuth } from 'lib/context';
import { DateRangeSelector } from '../../components';
import { Button } from 'react-covideo-common';

type ContainerProps = {
  padding?: string;
};

const HeaderWrapper = styled.div<ContainerProps>`
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  position: fixed;
  width: calc(100% - 208px);
  top: 64px;
  z-index: 8;
  background: #ffffff;
  margin: 0 -112px 16px -112px;
  padding: ${props => props.padding || '16px 112px'};
  box-shadow:
    inset 0px 0px 0px rgb(66 79 104 / 8%),
    0px 6px 12px rgb(66 79 104 / 6%);
`;
const Container = styled.div`
  margin: 0 auto;
  max-width: 1008px;

  @media screen and (min-width: 1280px) {
    max-width: 1600px;
  }
`;

const ItemsWrapper = styled(Container)<{ mb?: string }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mb }) => mb || '25px'};
  > div > div:first-child {
    margin-left: 0;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-family: Work Sans;
  font-size: 24px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
`;

const Header = ({ timeStamp, setTimeStamp, dataForExport, widgets }: any) => {
  const [openSendModal, setOpenSendModal] = React.useState(false);
  const { userData } = useAuth();

  const onChangeCurrentTimestamp = (start: Date, end: Date, range: string) => {
    setTimeStamp((prevState: any) => {
      return {
        ...prevState,
        current: { start, end, range },
      };
    });
  };

  const downloadReport = async () => {
    // download only active widgets
    let widgetsExportData = dataForExport.filter(
      (data: any) =>
        widgets.find((widget: any) => data.title === widget.label) &&
        data.data.length
    );
    await downloadXLSXAnalyticsReport(widgetsExportData);
  };

  return (
    <>
      <HeaderWrapper
        padding={
          isAdminSession() || userData.trialAccount
            ? '80px 112px 16px 112px'
            : '16px 112px'
        }
      >
        <ItemsWrapper>
          <Title>Dashboard</Title>
          <BtnWrapper>
            <Button
              variant='secondary'
              text='Send'
              icon={<MdEmail size={24} />}
              disabled={false}
              onClick={() => setOpenSendModal(true)}
              title='Send Report'
            />

            <Button
              variant='secondary'
              onClick={async () => await downloadReport()}
              text={'Download'}
              disabled={false}
              icon={<MdDownload size={24} />}
              title='Download Report'
            />
          </BtnWrapper>
        </ItemsWrapper>
        <ItemsWrapper mb='0px'>
          <DateRangeSelector
            dropdownWidth='280px'
            onDateRangeChange={onChangeCurrentTimestamp}
            title='Timeframe'
            initialValue={timeStamp.current.range}
          />
        </ItemsWrapper>
      </HeaderWrapper>
      {openSendModal && (
        <SendReportModal
          handleModalClose={() => {
            setOpenSendModal(false);
          }}
          videoId={''}
          data={dataForExport}
          widgets={widgets}
        />
      )}
    </>
  );
};
export default Header;
