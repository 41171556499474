type ContainerProps = {
  width: string;
};

export const menuWidth = '240px';
export const iconSizeM = '24px';
export const iconSizeS = '16px';
export const padding24 = '24px';
export const padding16 = '16px';
export const modalWidthPx = 684;
export const systemFontStack = 'var(--font-family-secondary)';

export const container = ({ width }: ContainerProps) => ({
  display: 'grid',
  'grid-template-columns': `minmax(0, ${width})`,
  'justify-content': 'center',
});

const fontCommon = {
  fontFamily: systemFontStack,
};

export const fontNormal400 = { ...fontCommon, fontWeight: 400 };
export const fontNormal500 = { ...fontCommon, fontWeight: 500 };
export const fontNormal600 = { ...fontCommon, fontWeight: 600 };
export const fontBold700 = { ...fontCommon, fontWeight: 700 };
export const fontBold800 = { ...fontCommon, fontWeight: 800 };

export const screenSizes = {
  mb: 420,
  xs: 640,
  sm: 768,
  md: 1024,
  lg: 1200,
  xlg: 1368,
  xxlg: 1750,
  // breaking points from covideo v1 (c is for covideo)
  cmd: 812,
};

export const mediaQueryMinWidth = {
  mb: `@media (min-width: ${screenSizes.mb}px)`,
  xs: `@media (min-width: ${screenSizes.xs}px)`,
  sm: `@media (min-width: ${screenSizes.sm}px)`,
  md: `@media (min-width: ${screenSizes.md}px)`,
  lg: `@media (min-width: ${screenSizes.lg}px)`,
  xlg: `@media (min-width: ${screenSizes.xlg}px)`,
  xxlg: `@media (min-width: ${screenSizes.xxlg}px)`,
  // breaking points from covideo v1 (c is for covideo)
  cmd: `@media (min-width: ${screenSizes.cmd}px)`,
};

export const mediaQueryMaxWidth = {
  mb: `@media (max-width: ${screenSizes.mb}px)`,
  xs: `@media (max-width: ${screenSizes.xs}px)`,
  sm: `@media (max-width: ${screenSizes.sm}px)`,
  md: `@media (max-width: ${screenSizes.md}px)`,
  lg: `@media (max-width: ${screenSizes.lg}px)`,
  xlg: `@media (max-width: ${screenSizes.xlg}px)`,
  xxlg: `@media (max-width: ${screenSizes.xxlg}px)`,
  // breaking points from covideo v1 (c is for covideo)
  cmd: `@media (max-width: ${screenSizes.cmd}px)`,
};

export const fontSizes = {
  xxs: '12px',
  xs: '13px',
  sm: '14px',
  m: '15px',
  md: '16px',
  lg: '18px',
  xl: '24px',
  xxl: '32px',
};

export const hotspotPalette = [
  'rgba(39, 42, 50, 0.05)',
  '#9cd4b8',
  '#fff0a9',
  '#ffbb7d',
  '#e26f64',
];

/*
Dark Blue
CMYK: 100% 98% 30% 23%
RGB: 41 38 91
HEX: #212D56

Magenta
CMYK: 1% 97% 4% 0%
RGB: 229 08 126
HEX: #E5087E
*/

export const palette = {
  buttonAmber: '#FF8B21',
  themeDark: '#343A40',
  themeDark75Percent: '#676D73',
  themeDark50Percent: '#B3B9BF',
  black_1_100: 'var(--black)',
  coal: '#272a32',
  primaryDarkBlue: 'var(--primary)',
  primaryDarkBlue75Percent: 'var(--primary75)',
  primaryDarkBlue50Percent: 'var(--primary50)',
  primaryDarkBlue25Percent: 'var(--primary25)',
  primaryDarkBlue10Percent: 'var(--primary10)',
  primaryColor: '#ff8b22',
  primaryBlack40Percent: 'rgba(36, 39, 46, 0.4)',
  primaryBlack25Percent: 'rgba(36, 39, 46, 0.25)',
  primaryBlack5Percent: 'rgba(36, 39, 46, 0.05)',
  primaryBlack2Percent: 'rgba(36, 39, 46, 0.02)',
  primaryBlack3Percent: 'rgba(36, 39, 46, 0.03)',
  primaryBlack1Percent: 'rgba(36, 39, 46, 0.01)',
  black03: 'rgba(39, 42, 50, 0.03)',
  black40: 'rgba(39, 42, 50, 0.40)',
  midGrey: '#9297a2',
  secondaryBlue: '#007BFF',
  secondaryBlue5Percent: 'rgba(0, 123, 255, 0.05)',
  secondaryBlue10Percent: 'rgba(0, 123, 255, 0.1)',
  secondaryGrey: '#ccd1dd',
  secondaryGray20Percent: 'rgba(204, 209, 221, 0.2)',
  secondaryGrayParagraph: '#5D6180',

  tertiaryBlue: '#1C6ACF',
  primaryThemeColor: 'var(--secondary)',

  primaryThemeColor0Percent: 'var(--secondary0)',
  primaryThemeColor5Percent: 'var(--secondary5)',
  primaryThemeColor10Percent: 'var(--secondary10)',
  primaryThemeColor20Percent: 'var(--secondary20)',
  primaryThemeColor80Percent: 'var(--secondary80)',

  primaryRedDanger: '#EE4842',
  primaryRedDanger10Percent: 'rgba(232, 76, 61, 0.1)',
  primaryRedDanger20Percent: 'rgba(232, 76, 61, 0.2)',
  primaryRed50Percent: '#FFC7C1',
  secondaryRedDanger: 'rgba(238, 72, 66, 0.1)',

  primaryTmBlue50Percent: 'rgba(41, 38, 91, 0.5)',
  primaryTmBlue70Percent: 'rgba(41, 38, 91, 0.7)',
  primaryDarkGray: '#3E3F41',
  buttonDanger: '#DC3545',
  buttonDelete: '#e84c3d',
  tomatoRed: '#e84c3d',
  buttonDangerHover: '#C82333',

  buttonPrimaryHover: 'var(--primary-button-hover)',

  secondaryButtonBlueBg: '#F2F4F6',

  title: 'var(--black)',
  label: '#9297a2',

  // hamburgerMenu
  hamburgerMenu: '#6c757d',
  hamburgerMenuHover: '#5a6268',

  //menu
  templateBackground: '#f8f9fa',
  grayBorder: '#dfe3e6',

  //videoList
  searchIconBg: 'rgba(223, 227, 230, 0.5)',
  lightTurquoise: '#17a2b8',
  lightBlue: '#02a3e4',
  tableHeaderBorder: '#dee2e8',

  // customColors
  black: '#000',
  white: '#fff',
  gray: '#f7f8fa',
  gray10: '#F6F7F9',
  gray20: '#EEEFF2',
  gray40: '#D0D3D9',
  gray60: '#9297A2',
  gray80: '#4E5461',
  gray90: '#d1d1d1',
  gray100: '#272A32',
  lightgray: 'rgba(213,214,217,1)',
  lightgray20: 'rgba(242,244,246,1)',
  lightgray40: 'rgba(213,214,217,0.4)',
  whiteRgb25: 'rgba(255,255,255, .1)',
  whiteRgb75: 'rgba(255,255,255, .85)',
  blackRgb75: 'rgba(52,58,64, .75)',
  blackRgb60: 'rgba(52,58,64, .6)',
  blackRgba75: 'rgba(0,0,0, .75)',
  blue100: '#001B53',
  blue80: 'rgba(0, 27, 83, 0.8)',
  blue60: 'rgba(0, 27, 83, 0.6)',
  blue40: 'rgba(0, 27, 83, 0.4)',
  blue20: 'rgba(0, 27, 83, 0.2)',
  blue05: 'rgba(0, 27, 83, 0.05)',
  blue10: 'rgba(0, 27, 83, 0.10)',
  blue02: '#FAFAFC',
  red02: 'rgba(232, 76, 61, 0.02)',
  red02NonAlpha: '#FEF6F5',
  red05: 'rgba(232, 76, 61, 0.05)',
  red60: 'rgba(232, 76, 61, 0.6)',
  red80: 'rgba(232, 76, 61, 0.8)',
  red40: 'rgba(232, 76, 61, 0.4)',
  red20: 'rgba(232, 76, 61, 0.20)',
  red100: '#E84C3D',
  green05: 'rgba(30, 164, 95, 0.05)',
  green02: '#FBFDFC',
  green02NonAlpha: '#FBFDFC',
  green80: 'rgba(30, 164, 95, 0.8)',
  destructiveBg: '#FEF6F5',
  covideoBlue02NonAlpha: '#F2F4F6',
  covideoBlue10: 'rgba(0, 27, 83, 0.1)',
  covideoBlue20: 'rgba(0, 27, 83, 0.2)',
  covideoBlue40: 'rgba(0, 27, 83, 0.4)',
  covideoBlue60: 'rgba(0, 27, 83, 0.6)',
  covideoBlue80: 'rgba(0, 27, 83, 0.8)',
  covideoBlue100: '#001B53',
  covideoSecondaryButtonBg: '#F2F4F6',
  red10: 'rgba(232, 76, 61, 0.1)',
  green50: 'rgba(30, 163, 95, 0.05)',
  green100: '#1EA45F',
  babyBlue100: '#B6E0EC',
  babyBlue40: 'rgba(182, 224, 236, 0.4)',
  babyBlue10: 'rgba(182, 224, 236, 0.1)',
  orange60: 'rgba(255, 139, 34, 0.6)',
  magic100: '#8D31D6',

  // play button icons
  icons: '#9099a0',

  // CardShare
  dottedBorder: '#c1c9ce',

  //new_Figma_colors
  error: 'rgba(232, 76, 61, 1)',
  transparent: 'transparent',
  covideoGray10: 'rgba(208, 211, 217, 0.1)',
  covideoGray20: '#EEEFF2',
  covideoGray40: 'rgba(208, 211, 217, 1)',
  covideoGray50: 'rgba(39, 42, 50, 0.20)',
  covideoOrange05: 'rgba(255, 139, 34, 0.05)',
  covideoOrange80: 'rgba(255, 139, 34, 0.8)',
  covideoOrange100: 'rgba(255, 139, 34, 1)',
  covideoOrange20NonAlpha: 'rgba(255, 139, 34, 0.20)',
  covideoGray100: 'rgba(39, 42, 50, 1)',
  covideoGray80: 'rgba(78, 84, 97, 1)',
  covideoGray60: 'rgba(146, 151, 162, 1)',
  covideoGray70: 'rgba(208, 211, 217, 1)',

  covideoBlack05: 'rgba(39, 42, 50, 0.05)',
  newCovideoGray20: 'rgba(39, 42, 50, 0.2)',
  boxShadow: 'rgba(29, 30, 36, 0.04)',
  //modal
  modalBg: 'rgba(19, 34, 66, 0.6)',
  neutral10: '#F6F7F9',
  neutral20: '#EEEFF2',
  neutral40: '#D0D3D9',
  neutral60: '#9297A2',
  neutral80: '#4E5461',
  neutral100: '#272A32',
  darkGreen: '#021513',
  lightGreen500: '#11a694',
  lightGreen300: '#88d3ca',
};
